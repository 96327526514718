export enum ContaTipo {
  Nenhum = '',
  Sintetica = 'S',
  Analitica = 'A',
}

export const ContaTipoLabels = new Map<string, string>([
  [ContaTipo.Sintetica, 'SINTÉTICA'],
  [ContaTipo.Analitica, 'ANALÍTICA'],
]);
