import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
})
export class RightSidebarComponent {
  @Input() mostrarMenuDireito: boolean;
  @Output() aoFecharMenuDireito = new EventEmitter();

  constructor() {}
  fecharMenuDireito() {
    this.aoFecharMenuDireito.emit();
  }
}
