export class UnidadePorcaoConst {
  constructor(_codigo: number, _descricao: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
  }
  codigo: number;
  descricao: string;
}

export const UnidadesPorcao = [
  new UnidadePorcaoConst(0, 'UNIDADE DE PORCAO EM GRAMAS (G)'),
  new UnidadePorcaoConst(1, 'UNIDADE DE PORCAO EM MILILITROS (ML)'),
  new UnidadePorcaoConst(2, 'UNIDADE DE PORCAO EM UNIDADES (UN)'),
];
