export class ProdutoVariacaoEstoque {
  dataUltimoInventario: Date;
  areaEstoqueId: string;
  atual: number;
  congelado: number;
  digitado: number;
  sku: number;
  maximo: number;
  minimo: number;
  percentualPerda: number;
  id: string;
  variacaoId: string;

  static criarProdutoVariacaoEstoque(value: any) {
    const produtoVariacaoEstoque = new ProdutoVariacaoEstoque();
    produtoVariacaoEstoque.dataUltimoInventario = value.dataUltimoInventario;
    produtoVariacaoEstoque.areaEstoqueId = value.areaEstoqueId;
    produtoVariacaoEstoque.atual = value.atual;
    produtoVariacaoEstoque.congelado = value.congelado;
    produtoVariacaoEstoque.digitado = value.digitado;
    produtoVariacaoEstoque.maximo = value.maximo;
    produtoVariacaoEstoque.minimo = value.minimo;
    produtoVariacaoEstoque.percentualPerda = value.percentualPerda;
    produtoVariacaoEstoque.id = value.id;
    produtoVariacaoEstoque.variacaoId = value.variacaoId;
    produtoVariacaoEstoque.sku = value.sku;
    return produtoVariacaoEstoque;
  }
  prepararProdutoVariacaoEstoque() {
    this.atual = Number(this.atual);
    this.maximo = Number(this.maximo);
    this.minimo = Number(this.minimo);
    this.percentualPerda = Number(this.percentualPerda);
    this.congelado = Number(this.congelado);
    this.digitado = Number(this.digitado);
  }
}
