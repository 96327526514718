<p-dialog
  header="Lista de produtos"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="true"
  (onShow)="buscarProdutos()"
  (onHide)="fecharModal()"
  contentStyleClass="overlay-y-hidden"
>
  <ng-template #produtoTipoTemp let-value="value">
    <span>{{ value | enumLabel: produtoTipoLabels }}</span>
  </ng-template>
  <ng-template #valorDecimalTemp let-value="value">
    <span>{{ value | customDecimal : valorDecimais }}</span>
  </ng-template>
  <ng-template #marcaTemp let-value="value">
    <span>{{ value | marca }}</span>
  </ng-template>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
    (scrolled)="buscarProdutosProximaPagina()"
  >
    <div class="grid" *ngIf="mostrarBotaoCadastro">
      <div class="col">
        <p-button
          type="button"
          styleClass="btn-five"
          label="Novo produto"
          tooltipPosition="bottom"
          pTooltip="Novo produto"
          icon="pi pi-plus"
          (click)="abrirProdutoCadastroRapidoModal()"
        ></p-button>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6">
        <app-text-input-ngmodel
          placeholder="Descrição ou Código de barras"
          (modelChange)="filtrarComTimeout()"
          [(model)]="filtro.descricao"
        ></app-text-input-ngmodel>
      </div>
    </div>
    <app-table
      [columns]="colunas"
      [rows]="dados"
      [selectable]="!multiSelect"
      [checkboxable]="multiSelect"
      [selectionMode]="multiSelect ? 'multiple' : 'single'"
      [(selecteds)]="itensTabelaSelecionados"
    >
    </app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      [disabled]="desabilitarBotaoSelecionar"
      styleClass="btn-five"
    >
    </p-button>
  </ng-template>
</p-dialog>

<ng-template #situacaoTemp let-value="value">
  <p-tag
    *ngIf="value"
    styleClass="mr-2"
    severity="success"
    value="Ativo"
  ></p-tag>
  <p-tag
    *ngIf="!value"
    styleClass="mr-2"
    severity="danger"
    value="Inativo"
  ></p-tag>
</ng-template>

<app-produto-cadastro-rapido-modal
  *ngIf="mostrarBotaoCadastro"
  [mostrarModal]="mostrarProdutoCadastroRapidoModal"
  [produto]="produto"
  (aoCancelar)="fecharProdutoCadastroRapidoModal()"
  (aoSalvar)="filtrar()"
></app-produto-cadastro-rapido-modal>
