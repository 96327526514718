import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {ProdutoVariacao} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao.entity';
import {Produto} from '@app/abstraction/domain/entities/produtos/produto/produto.entity';
import {ProdutoVariacaoCorFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-cor/produto-variacao-cor.facade';
import {ProdutoVariacaoReferenciaFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-referencia/produto-variacao-referencia.facade';
import {ProdutoVariacaoTamanhoFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-tamanho/produto-variacao-tamanho.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {ProdutoTipoLabels} from '@app/shared/enums/produto-tipo.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-produto-variacao-busca-rapida',
  templateUrl: './produto-variacao-busca-rapida.component.html',
})
export class ProdutoVariacaoBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('tamanhoTemp', {static: true}) tamanhoTemp: TemplateRef<any>;
  @ViewChild('corTemp', {static: true}) corTemp: TemplateRef<any>;
  @ViewChild('referenciaTemp', {static: true}) referenciaTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Input() produtoRecebido: Produto;
  @Input() produtoVinculoSelecionado: Produto;
  @Input() mostrarBotaoCadastroVariacao: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  itemSelecionado: ProdutoVariacao;
  mostrarProdutoVariacaoCadastroRapidoModal: boolean;

  get dados() {
    return this.produtoVinculoSelecionado?.variacoes;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get produtoTipoLabels() {
    return ProdutoTipoLabels;
  }
  get produtoRecebidoVariacao() {
    if (this.produtoRecebido) {
      const variacoes = this.produtoRecebido.variacoes.first();
      variacoes.produtoId = this.produtoVinculoSelecionado?.id;
      return variacoes;
    } else {
      return null;
    }
  }
  constructor(
      private produtoVariacaoCorFacade: ProdutoVariacaoCorFacade,
      private produtoVariacaoReferenciaFacade: ProdutoVariacaoReferenciaFacade,
      private produtoVariacaoTamanhoFacade: ProdutoVariacaoTamanhoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'codigoBarras',
        name: 'Código de barras',
      },
      {
        field: 'tamanhoId',
        name: 'Tamanho',
        cellTemplate: this.tamanhoTemp,
      },
      {
        field: 'corId',
        name: 'Cor',
        cellTemplate: this.corTemp,
      },
      {
        field: 'referenciaId',
        name: 'Referência',
        cellTemplate: this.referenciaTemp,
      },
      {
        field: 'ativo',
        name: 'Situação',
        headerClass: 'text-center',
        cellClass: 'text-center',
        type: 'boolean',

        filterable: true,
        cellTemplate: this.situacaoTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
    ];
  }
  async selecionar() {
    if (this.itemSelecionado) {
      this.aoSelecionar.emit(this.itemSelecionado);
      this.itemSelecionado = null;
    }
  }
  async aoAbrir() {
    await this.produtoVariacaoReferenciaFacade
        .buscarProdutoVariacaoReferencias();
    await this.produtoVariacaoCorFacade.buscarProdutoVariacaoCores();
    await this.produtoVariacaoTamanhoFacade.buscarProdutoVariacaoTamanhos();
  }
  abrirProdutoVariacaoCadastroRapidoModal() {
    this.mostrarProdutoVariacaoCadastroRapidoModal = true;
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
  }
  fecharProdutoVariacaoCadastroRapidoModal() {
    this.mostrarProdutoVariacaoCadastroRapidoModal = false;
  }
  ngOnInit() {
    this.montarColunas();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
  selecionarProdutoVariacaoSalvo(variacao: ProdutoVariacao) {
    this.itemSelecionado = variacao;
    this.selecionar();
  }
}
