import {Injectable} from '@angular/core';
import {ClienteSituacaoService} from '@app/abstraction/data/services/cadastros/cliente-situacao.service';
import {ClienteSituacaoStore} from '@app/abstraction/data/stores/cadastros/cliente-situacao.store';
import {ClienteSituacao} from '@app/abstraction/domain/entities/cadastros/cliente-situacao/cliente-situacao.entity';
import {ClienteSituacaoFiltro} from '@app/abstraction/domain/interfaces/cadastros/cliente-situacao/cliente-situacao-filtro.interface';

@Injectable({providedIn: 'root'})
export class ClienteSituacaoFacade {
  get clienteSituacoes() {
    return this.store.clienteSituacoes;
  }
  get clienteSituacoes$() {
    return this.store.clienteSituacoes$;
  }
  constructor(
      private store: ClienteSituacaoStore,
      private service: ClienteSituacaoService) {}
  async adicionar(clienteSituacao: ClienteSituacao) {
    const novaClienteSituacao = await this.service.adicionar(clienteSituacao);
    if (novaClienteSituacao) {
      this.store.adicionar(novaClienteSituacao.toListagem());
    }
    return novaClienteSituacao;
  }
  async atualizarSituacao(clienteSituacaoId: string) {
    const clienteSituacao =
        this.clienteSituacoes.find((p) => p.id === clienteSituacaoId);
    const status = await this.service.atualizarSituacao(
        clienteSituacaoId, !clienteSituacao.ativo);
    clienteSituacao.ativo = status;
    return status;
  }
  async buscarClienteSituacoes(filtro?: ClienteSituacaoFiltro) {
    const clienteSituacoes = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(clienteSituacoes);
  }
  alterar(clienteSituacao: ClienteSituacao) {
    return this.service.alterar(clienteSituacao, clienteSituacao.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaClienteSituacoes() {
    this.store.clienteSituacoes = null;
  }
}
