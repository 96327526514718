import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'enumLabel',
})
export class EnumLabelPipe implements PipeTransform {
  transform(valor: any, labels: Map<any, string>): string {
    if (valor != null && valor != undefined && labels) {
      return labels.get(valor);
    }
    return '';
  }
}
