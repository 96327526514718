import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModuloService} from '@app/abstraction/data/services/auth/modulo.service';
import {EmpresaService} from '@app/abstraction/data/services/cadastros/empresa.service';
import {AuthService} from '@app/abstraction/data/services/shared/auth.service';
import {AuthStore} from '@app/abstraction/data/stores/shared/auth.store';
import {Empresa} from '@app/abstraction/domain/entities/cadastros/empresa/empresa.entity';
import {GrupoEconomico} from '@app/abstraction/domain/entities/shared/auth/grupo-economico.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {LocalConfigFacade} from '@app/abstraction/domain/facades/shared/local-config/local-config.facade';
import {NotificacaoFacade} from '@app/abstraction/domain/facades/shared/notificacao/notificacao.facade';
import {UsuarioFavorito} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-favorito.interface';
import {RegistroSimples} from '@app/abstraction/domain/interfaces/shared/registro-simples.interface';
import {Temas} from '@app/shared/constants/temas.constant';
import {FavoritoTipo} from '@app/shared/enums/favorito-tipo.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';

@Injectable({providedIn: 'root'})
export class AuthFacade {
  get empresaLogada() {
    return this.store.empresaLogada;
  }
  get empresaUnica() {
    return this.grupoLogado.empresas.length == 1;
  }
  get multiEmpresa() {
    return this.usuarioLogado.empresas.length > 1 ||
        this.usuarioLogado?.empresas?.some(e => e.empresas.length > 1);
  }
  get enderecoEmpresaLogada() {
    return this.empresaLogada.enderecos?.find((e) => e.principal);
  }
  get grupoLogado() {
    return this.store.grupoLogado;
  }
  get empresasId() {
    return this.grupoLogado.empresas.map((empresa) => {return empresa.id});
  }
  get loggedIn() {
    return this.buscarTokenDoUsuario() && this.store.usuarioLogado;
  }
  get usuarioLogado() {
    return this.store.usuarioLogado;
  }
  get usuarioLogado$() {
    return this.store.usuarioLogado$;
  }
  get usuarioConfig() {
    return this.store.usuarioConfig;
  }
  get usuarioConfig$() {
    return this.store.usuarioConfig$;
  }
  get usuarioFavoritos() {
    return this.usuarioConfig.favoritos;
  }
  get usuarioTema() {
    if (this.usuarioConfig) {
      return Temas.find((t) => t.id === this.usuarioConfig.temaId);
    } else {
      return Temas.find((t) => t.id === '1');
    }
  }
  get favoritosTipoMenuDashboard() {
    if (this.usuarioFavoritos) {
      return this.usuarioFavoritos.filter(
          (f: UsuarioFavorito) => f.tipo === FavoritoTipo.MenuDashboard);
    }
    return null;
  }
  get isSuperUsuario() {
    return this.grupoLogado?.empresas
        ?.filter(p => p.id == this.empresaLogada.id)
        ?.first()
        ?.superUsuario;
  }
  constructor(
      private empresaService: EmpresaService,
      private permissoesService: ModuloService,
      private parametroFacade: ParametroFacade,
      private store: AuthStore,
      private service: AuthService,
      private empresaFacade: EmpresaFacade,
      private router: Router,
      private route: ActivatedRoute,
      private localConfigFacade: LocalConfigFacade,
      private notificacaoFacade: NotificacaoFacade,
  ) {}
  private async navigateToDashboard() {
    const returnUrl =
        this.route.snapshot.queryParams['returnUrl'] ?? '/dashboard';
    await this.router.navigateByUrl(returnUrl);
  }
  private async redirecionarParaSeletorEmpresa() {
    if (!this.empresaLogada) {
      await this.router.navigateByUrl('/selecionar-empresa');
    } else {
      this.buscarConfiguracaoLocaisDoUsuario();
      await this.buscarModulosDoUsuario();
      await this.parametroFacade.buscarParametrosEmpresaLogada(
          this.empresaLogada.id);
      await this.notificacaoFacade.buscarNotificacoes().then(() => {
        this.notificacaoFacade.atualizarNotificacoesAntigas();
      });
      await this.navigateToDashboard();
      // this.setarTema(this.usuarioTema.id);
    }
  }
  private async redirecionarSenhaPrimeiroAcesso() {
    await this.router.navigateByUrl(`/auth/primeiro-acesso?id=${
        this.usuarioLogado.id}&email=${this.usuarioLogado.email}`);
  }
  private async setarEmpresaUnicaComoSelecionada() {
    const temEmpresa =
        this.usuarioLogado?.empresas?.some(p => p.empresas.length > 0);

    if (temEmpresa) {
      const grupoLogado = this.usuarioLogado?.empresas?.first();
      const primeiraEmpresa = grupoLogado?.empresas.first() as any;
      const empresa = await this.empresaService.buscarPorIdNoManager(
          primeiraEmpresa.id ?? primeiraEmpresa.empresaId);

      this.setarEmpresaSelecionada(empresa);
      this.setarGrupoLogado(grupoLogado);
    }
  }
  private buscarTokenDoUsuario() {
    return localStorage.getItem('access_token');
  }
  private buscarEstadoColunas(
      stateKey: string,
      colunas: any[],
      colunasOcultasSelecionadas: any[],
      ): any {
    if (!stateKey) return;
    const colunasSalvas = this.usuarioConfig.colunas[stateKey];
    if (colunasSalvas) {
      colunasOcultasSelecionadas = [];
      colunas.forEach((coluna) => {
        if (colunasSalvas.includes(coluna.field)) {
          coluna.hidden = false;
          colunasOcultasSelecionadas.push(coluna);
        }
      });
    }
    return colunasOcultasSelecionadas;
  }
  private salvarEstadoColunas(evento: any[], stateKey: string) {
    if (!stateKey) return;
    const map = evento.map((e: TableColumn) => e.field);
    this.usuarioConfig.colunas[stateKey] = map;
    this.salvarConfiguracoesDoUsuario();
  }
  async redirecionarParaDashboard() {
    await this.setarEmpresaUnicaComoSelecionada();
    this.buscarConfiguracaoLocaisDoUsuario();
    await this.buscarModulosDoUsuario();
    await this.navigateToDashboard();
    // this.setarTema(this.usuarioTema.id);
  }
  async buscarUsuarioLogado() {
    this.store.usuarioLogado = await this.service.buscarUsuarioLogado();
  }
  async buscarModulosDoUsuario() {
    const permissoes = await this.permissoesService.buscarPermissoesUsuario();
    this.usuarioLogado.modulos = permissoes.modulos;
    this.usuarioLogado.dashboards = permissoes.dashboards;
    this.store.usuarioLogado = this.store.usuarioLogado;
  }
  async finalizarFluxoLogin() {
    await this.buscarUsuarioLogado();
    await this.redirecionarUsuario();
  }
  async redirecionarUsuario() {
    if (!this.usuarioLogado.senhaDefinida) {
      await this.redirecionarSenhaPrimeiroAcesso();
    } else {
      if (this.multiEmpresa) {
        await this.redirecionarParaSeletorEmpresa();
      } else {
        await this.redirecionarParaDashboard();
      }
    }
  }
  async definirSenhaPrimeiroAcesso(id: string, senha: string) {
    const retorno = await this.service.definirSenhaPrimeiroAcesso(id, senha);
    return retorno;
  }
  async buscarDadosCompletosDaEmpresa(empresaId: string) {
    const empresaCompleta = await this.empresaFacade.buscarPorId(empresaId);
    this.setarEmpresaSelecionada(empresaCompleta);
  }
  adicionarFavorito(favorito: UsuarioFavorito) {
    if (!this.usuarioConfig.favoritos) this.usuarioConfig.favoritos = [];
    this.usuarioConfig.favoritos.push(favorito);
    this.usuarioConfig.favoritos.orderBy(f => f.tela);
    this.store.usuarioConfig = this.store.usuarioConfig;
    this.salvarConfiguracoesDoUsuario();
  }
  buscarConfiguracaoLocaisDoUsuario() {
    this.store.usuarioConfig =
        this.localConfigFacade.buscarConfiguracoesDoUsuario(
            this.usuarioLogado.id, this.empresaLogada?.id);
  }
  buscarEmpresasPorUsuario() {
    return this.empresaFacade.buscarEmpresasUsuarioLogado(
        this.empresaLogada.matrizId);
  }
  buscarEstadoFiltro(filtro: any, stateKey: string) {
    if (!stateKey) return;
    const filtroSalvo = this.usuarioConfig.filtros[stateKey];
    if (filtroSalvo) Object.assignWithDate(filtro, filtroSalvo);
  }
  cadastrar(registro: RegistroSimples) {
    return this.service.cadastrar(registro);
  }
  montarColunasOcultas({
    stateKey = '',
    colunas = <any[]>[],
    colunasOcultas = <any[]>[],
    colunasOcultasSelecionadas = <any[]>[]
  } = {}) {
    colunasOcultas = colunas.filter((e) => e.hidden);
    colunasOcultasSelecionadas = this.buscarEstadoColunas(
        stateKey,
        colunas,
        colunasOcultasSelecionadas,
    );
    return {colunasOcultas, colunasOcultasSelecionadas};
  }
  mostrarOcultarColunas(evento: any[], {
    stateKey = '',
    colunas = <TableColumn[]>[],
    colunasOcultas = <TableColumn[]>[],
    colunasOcultasSelecionadas = <TableColumn[]>[]
  } = {}) {
    if (evento.length === 0) {
      colunasOcultas.forEach((coluna: TableColumn) => {
        colunas.find((c) => c.field === coluna.field).hidden = true;
      });
    } else {
      colunasOcultas.forEach((coluna) => {
        colunas.find((c) => c.field === coluna.field).hidden = true;
      });
      evento.forEach((coluna) => {
        colunas.find((c) => c.field === coluna.field).hidden = false;
      });
    }

    colunasOcultasSelecionadas = evento;
    this.salvarEstadoColunas(evento, stateKey);
    return colunasOcultasSelecionadas;
  }
  removerFavorito(favorito: UsuarioFavorito) {
    if (!this.usuarioConfig.favoritos) this.usuarioConfig.favoritos = [];
    this.usuarioConfig.favoritos.remove(favorito);
    this.usuarioConfig.favoritos.orderBy(f => f.tela);
    this.store.usuarioConfig = this.store.usuarioConfig;
    this.salvarConfiguracoesDoUsuario();
  }
  removerEmpresaSelecionada() {
    localStorage.removeItem('selected_company');
    this.store.empresaLogada = null;
    this.store.grupoLogado = null;
  }
  salvarConfiguracoesDoUsuario() {
    this.localConfigFacade.salvarConfiguracoesDoUsuario(
        this.usuarioLogado.id,
        this.empresaLogada.id,
        this.usuarioConfig,
    );
  }
  // setarTema(temaId: string) {
  //   this.temaFacade.remover();
  //   this.temaFacade.adicionar(
  //       Temas.find((t: TemaConst) => t.id === temaId).descricao);
  //   this.usuarioConfig.temaId = temaId;
  //   this.salvarConfiguracoesDoUsuario();
  // }
  // setarTemaPadrao() {
  //   this.temaFacade.remover();
  // }
  setarEmpresaSelecionada(empresa: Empresa, cadastro?: boolean) {
    localStorage.setItem('selected_company', JSON.stringify(empresa));
    this.store.empresaLogada = empresa;
    if (!cadastro) {
      this.parametroFacade.buscarParametrosEmpresaLogada(this.empresaLogada.id);
      this.notificacaoFacade.buscarNotificacoes().then(() => {
        this.notificacaoFacade.atualizarNotificacoesAntigas();
      });
    }
  }
  setarEmpresaLogada(empresa: Empresa) {
    this.store.empresaLogada = empresa;
  }
  setarGrupoLogado(grupo: GrupoEconomico) {
    this.store.grupoLogado = grupo;
  }
  salvarEstadoFiltro(filtro: any, stateKey: string) {
    if (!stateKey) return;
    this.usuarioConfig.filtros[stateKey] = filtro;
    this.salvarConfiguracoesDoUsuario();
  }
}
