import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProdutoVariacaoReferencia} from '@app/abstraction/domain/entities/produtos/produto-variacao-referencia/produto-variacao-referencia.entity';
import {ProdutoVariacaoReferenciaFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-referencia/produto-variacao-referencia.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ProdutoVariacaoReferenciaForm} from '@app/abstraction/domain/forms/produtos/produto/produto-variacao-referencia.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-produto-variacao-referencia-cadastro-rapido-modal',
  templateUrl:
      './produto-variacao-referencia-cadastro-rapido-modal.component.html',
})
export class ProdutoVariacaoReferenciaCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<ProdutoVariacaoReferencia>();
  @Output() aoCancelar = new EventEmitter();

  referenciaForm: ProdutoVariacaoReferenciaForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }

  constructor(
      private produtoVariacaoReferenciaFacade: ProdutoVariacaoReferenciaFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarProdutoVariacaoReferencia(
      produtoVariacaoReferencia: ProdutoVariacaoReferencia) {
    return this.produtoVariacaoReferenciaFacade
        .adicionar(produtoVariacaoReferencia)
        .catch((e: any) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private criarForm() {
    this.referenciaForm =
        new ProdutoVariacaoReferenciaForm(this.errorMessagesFacade);
    this.referenciaForm.criarForm();
    this.referenciaForm.setarTipoFormulario();
  }
  private preencherObjeto() {
    return ProdutoVariacaoReferencia.from(this.referenciaForm.form.value);
  }
  private mostrarSucessoAoSalvar(produtoVariacaoReferencia:
                                     ProdutoVariacaoReferencia) {
    this.toastrService.success(`Referência do produto cadastrada com sucesso`);
    this.aoSalvar.emit(produtoVariacaoReferencia);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.referenciaForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const referencia = this.preencherObjeto();
      const resultado =
          await this.adicionarProdutoVariacaoReferencia(referencia)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar referência do produto.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  fecharModal() {
    this.referenciaForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
  }
}
