import {Pipe, PipeTransform} from '@angular/core';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {EnderecoTipoListagem} from '@app/abstraction/domain/interfaces/shared/endereco-tipo-listagem.interface';

@Pipe({
  name: 'enderecoTipo',
})
export class EnderecoTipoPipe implements PipeTransform {
  constructor(private enderecoTipoFacade: EnderecoTipoFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const enderecoTipo = this.enderecoTipoFacade.enderecoTipos?.find(
                               (enderecoTipo: EnderecoTipoListagem) =>
                                   enderecoTipo.enderecoTipoId == id) as any;
      return enderecoTipo?.[campo];
    }
    return '';
  }
}
