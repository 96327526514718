import {FormControl, FormGroup} from '@angular/forms';
import {ProdutoVariacaoDimensionamento} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-dimensionamento.entity';

export interface IProdutoVariacaoDimensionamentoForm {
  altura: FormControl<number>;
  largura: FormControl<number>;
  profundidade: FormControl<number>;
  volumes: FormControl<string>;
  pesoLiquido: FormControl<number>;
  pesoBruto: FormControl<number>;
}
export class ProdutoVariacaoDimensionamentoForm {
  static criarForm(dimensionamento: ProdutoVariacaoDimensionamento) {
    return new FormGroup<IProdutoVariacaoDimensionamentoForm>({
      altura: new FormControl(dimensionamento?.altura ?? null),
      largura: new FormControl(dimensionamento?.largura ?? null),
      profundidade: new FormControl(dimensionamento?.profundidade ?? null),
      volumes: new FormControl(dimensionamento?.volumes ?? null),
      pesoLiquido: new FormControl(dimensionamento?.pesoLiquido ?? null),
      pesoBruto: new FormControl(dimensionamento?.pesoBruto ?? null),
    });
  }
}
