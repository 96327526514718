import {Injectable} from '@angular/core';
import {ProdutoVariacaoTamanhoService} from '@app/abstraction/data/services/produtos/produto-variacao-tamanho.service';
import {ProdutoVariacaoTamanhoStore} from '@app/abstraction/data/stores/produtos/produto-variacao-tamanho.store';
import {ProdutoVariacaoTamanho} from '@app/abstraction/domain/entities/produtos/produto-variacao-tamanho/produto-variacao-tamanho.entity';
import {ProdutoVariacaoTamanhoFiltro} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-tamanho/produto-variacao-tamanho-filtro.interface';

@Injectable({providedIn: 'root'})
export class ProdutoVariacaoTamanhoFacade {
  get produtoVariacaoTamanhos() {
    return this.store.produtoVariacaoTamanhos;
  }
  get produtoVariacaoTamanhos$() {
    return this.store.produtoVariacaoTamanhos$;
  }
  constructor(
      private store: ProdutoVariacaoTamanhoStore,
      private service: ProdutoVariacaoTamanhoService) {}
  async adicionar(produtoVariacaoTamanho: ProdutoVariacaoTamanho) {
    const novoProdutoVariacaoTamanho =
        await this.service.adicionar(produtoVariacaoTamanho);
    if (novoProdutoVariacaoTamanho) {
      this.store.adicionar(novoProdutoVariacaoTamanho.toListagem());
    }
    return novoProdutoVariacaoTamanho;
  }
  async buscarProdutoVariacaoTamanhos(filtro?: ProdutoVariacaoTamanhoFiltro) {
    const produtoVariacaoTamanhos = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(produtoVariacaoTamanhos);
  }
  async atualizarSituacao(tamanhoId: string) {
    const produtoVariacaoTamanho =
        this.produtoVariacaoTamanhos.find((p) => p.id === tamanhoId);
    const status = await this.service.atualizarSituacao(
        tamanhoId, !produtoVariacaoTamanho.ativo);
    produtoVariacaoTamanho.ativo = status;
    return status;
  }
  alterar(produtoVariacaoTamanho: ProdutoVariacaoTamanho) {
    return this.service.alterar(
        produtoVariacaoTamanho, produtoVariacaoTamanho.id);
  }
  buscarPorId(tamanhoId: string) {
    return this.service.buscarPorId(tamanhoId);
  }
  limparListaProdutoVariacaoTamanhos() {
    this.store.produtoVariacaoTamanhos = null;
  }
}
