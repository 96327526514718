import '@app/shared/extensions/moment.extension';

import {SetorListagem} from '@app/abstraction/domain/interfaces/cadastros/setor/setor-listagem.interface';


export class Setor {
  id: string;
  descricao: string;
  impressoraId: string;
  exibirValor: boolean;
  quantidadeVia: number;
  quantidadeCasaDecimal: number;
  codigoExterno: string;
  ativo: boolean;

  static from(json: any = {}): Setor {
    const setor = new Setor();
    setor.id = json.id;
    setor.descricao = json.descricao;
    setor.impressoraId = json.impressoraId;
    setor.exibirValor = json.exibirValor;
    setor.quantidadeVia = json.quantidadeVia;
    setor.quantidadeCasaDecimal = json.quantidadeCasaDecimal;
    setor.codigoExterno = json.codigoExterno;
    setor.ativo = json.ativo;
    return setor;
  }
  toListagem(): SetorListagem {
    return <SetorListagem>{
      id: this.id,
      descricao: this.descricao,
      ativo: this.ativo,
    };
  }
}