export enum CertificadoTipo {
  Nenhum,
  A1,
  A3,
}

export const CertificadoTipoLabels = new Map<number, string>([
  [CertificadoTipo.A1, 'A1'],
  [CertificadoTipo.A3, 'A3'],
]);
