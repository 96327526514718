import {Pipe, PipeTransform} from '@angular/core';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {PlanoContasListagem} from '@app/abstraction/domain/interfaces/financeiro/plano-contas/plano-contas-listagem.interface';

@Pipe({name: 'planoContas3'})
export class PlanoContas3Pipe implements PipeTransform {
  constructor(private planoContasfacade: PlanoContasFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const planoContas = this.planoContasfacade.planosContasNivel3?.find(
                              (planoContas: PlanoContasListagem) =>
                                  planoContas.id == id) as any;
      return planoContas?.[campo];
    }
    return '';
  }
}
