<p-dialog
  [header]="titulo"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
    (scrolled)="buscarLancamentoParcelasProximaPagina()"
  >
    <div class="formgrid grid mt-1">
      <div class="field col-12 md:col-3">
        <app-text-input-ngmodel
          placeholder="Buscar por nome/razão social"
          (inputKeydown)="filtrarComTimeout()"
          [(model)]="filtro.nome"
        ></app-text-input-ngmodel>
      </div>
      <div class="field col-12 md:col-3">
        <app-numeric-input-ngmodel
          placeholder="Valor"
          (inputKeydown)="filtrarComTimeout()"
          [(model)]="filtro.valor"
          [options]="valorMask"
        ></app-numeric-input-ngmodel>
      </div>
      <p-button
        styleClass="mr-2 btn-two"
        (onClick)="toggleBuscaAvancada()"
        icon="pi pi-filter-fill"
      ></p-button>
    </div>
    <app-table
      [columns]="colunas"
      [rows]="dados$ | async"
      [selectable]="!multiSelect"
      [checkboxable]="multiSelect"
      [selectionMode]="multiSelect ? 'multiple' : 'single'"
      [(selecteds)]="itensTabelaSelecionados"
    >
    </app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button (click)="fecharModal()" label="Cancelar" [text]="true"></p-button>
    <p-button
      [disabled]="!itensTabelaSelecionados?.length"
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
  <ng-template #situacaoLancamentoTemp let-value="value" let-rowData="rowData">
    <p-tag
      [value]="buscarDescricaoSituacaoLancamento(rowData)"
      [severity]="buscarCorSituacaoLancamento(rowData)"
    ></p-tag>
  </ng-template>
  <p-sidebar
    [(visible)]="mostrarBuscaAvancada"
    [modal]="false"
    [closeOnEscape]="false"
    styleClass="h-30rem"
    position="bottom"
  >
    <ng-template pTemplate="header">Busca Avançada</ng-template>
    <ng-template pTemplate="content">
      <div class="grid mt-4" style="z-index: 1">
        <div class="col-12 lg:col-8">
          <div class="formgrid grid">
            <div class="field col-6 xl:col-9 lg:col-9 md:col-6 sm:col-6">
              <app-custom-label texto="Empresas"></app-custom-label>
              <app-multiselect-input-ngmodel
                optionLabel="nomeFantasia"
                optionValue="id"
                [options]="empresas"
                [showClear]="true"
                [(model)]="filtro.empresas"
              ></app-multiselect-input-ngmodel>
            </div>
            <div class="field col-6 xl:col-3 lg:col-3 md:col-6 sm:col-6">
              <app-custom-label texto="CPF/CNPJ"></app-custom-label>
              <app-text-input-ngmodel
                textAlign="right"
                [mask]="mascaraDocumento"
                [(model)]="filtro.pessoaDocumento"
              ></app-text-input-ngmodel>
            </div>
            <div class="field col-6 xl:col-4 lg:col-4 md:col-6 sm:col-6">
              <app-custom-label texto="Nº do documento"></app-custom-label>
              <app-text-input-ngmodel
                textAlign="right"
                [(model)]="filtro.numeroDocumento"
              ></app-text-input-ngmodel>
            </div>
            <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
              <app-custom-label texto="Situação"></app-custom-label>
              <app-dropdown-input-ngmodel
                [options]="situacoesLancamento"
                [(model)]="filtro.situacao"
                [showClear]="true"
              ></app-dropdown-input-ngmodel>
            </div>
            <div class="field col-12 xl:col-5 lg:col-5 md:col-6 sm:col-6">
              <app-custom-label texto="Forma de pagamento"></app-custom-label>
              <app-dropdown-input-ngmodel
                optionLabel="descricao"
                optionValue="id"
                [options]="formasPagamento$ | async"
                [(model)]="filtro.formaPagamentoId"
                [showClear]="true"
              ></app-dropdown-input-ngmodel>
            </div>
            <div class="field col-12 xl:col-7 lg:col-7 md:col-6 sm:col-6">
              <app-custom-label texto="Plano de contas"></app-custom-label>
              <app-dropdown-input-ngmodel
                optionLabel="label"
                optionValue="value"
                [options]="planosContas"
                [group]="true"
                [showClear]="true"
                [(model)]="filtro.planoContaId"
              ></app-dropdown-input-ngmodel>
            </div>
            <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
              <app-custom-label texto="Valor (R$)"></app-custom-label>
              <app-numeric-input-ngmodel
                placeholder="0,00"
                [options]="valorMask"
                [(model)]="filtro.valor"
              ></app-numeric-input-ngmodel>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-4 bloco-datas">
          <div class="card m-2">
            <div class="formgrid grid">
              <div class="field col-12">
                <app-custom-label texto="Período"></app-custom-label>
                <app-dropdown-input-ngmodel
                  [options]="consultaDataPeriodos"
                  [(model)]="filtro.consultaDataPeriodo"
                  (modelChange)="setarDatas($event)"
                ></app-dropdown-input-ngmodel>
              </div>
              <div class="field col-6">
                <app-custom-label texto="Data Início"></app-custom-label>
                <app-calendar-input-ngmodel
                  [showIcon]="liberarDatas"
                  [readonly]="!liberarDatas"
                  (modelChange)="setarValorDataInicio($event)"
                  [(model)]="filtro.dataInicio"
                ></app-calendar-input-ngmodel>
              </div>
              <div class="field col-6">
                <app-custom-label texto="Data Fim"></app-custom-label>
                <app-calendar-input-ngmodel
                  [showIcon]="liberarDatas"
                  [readonly]="!liberarDatas"
                  (modelChange)="setarValorDataFim($event)"
                  [(model)]="filtro.dataFim"
                ></app-calendar-input-ngmodel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="grid">
        <div class="col-12 text-right">
          <p-button styleClass="m-1 btn-two" (onClick)="limparCampos()">
            Limpar campos
          </p-button>
          <p-button styleClass="m-1 btn-one" (onClick)="buscarComFiltro()">
            Buscar
          </p-button>
        </div>
      </div>
    </ng-template>
  </p-sidebar>
</p-dialog>

<ng-template #dataTemp let-value="value">
  <span>{{ value | date : dataFormatoPt }}</span>
</ng-template>

<ng-template #valorTemp let-value="value">
  <span>{{ value | customCurrency : valorDecimais }}</span>
</ng-template>
