import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'booleano',
})
export class BooleanoPipe implements PipeTransform {
  transform(value: boolean): string {
    if (value) {
      return 'Sim';
    }
    return 'Não';
  }
}
