import {Pipe, PipeTransform} from '@angular/core';
import {ProdutoVariacaoTamanhoFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-tamanho/produto-variacao-tamanho.facade';
import {ProdutoVariacaoTamanhoListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-tamanho/produto-variacao-tamanho-listagem.interface';

@Pipe({
  name: 'tamanho',
})
export class TamanhoPipe implements PipeTransform {
  constructor(private produtoVariacaoTamanhoFacade:
                  ProdutoVariacaoTamanhoFacade) {}
  transform(tamanhoId: string, campo: string = 'descricao'): any {
    if (tamanhoId) {
      const produtoVariacaoTamanho =
          this.produtoVariacaoTamanhoFacade.produtoVariacaoTamanhos?.find(
              (produtoVariacaoTamanho: ProdutoVariacaoTamanhoListagem) =>
                  produtoVariacaoTamanho.id == tamanhoId) as any;
      return produtoVariacaoTamanho?.[campo];
    }
    return '';
  }
}
