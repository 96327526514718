export class ProdutoServicoConst {
  constructor(_codigo: string, _descricao: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
  }
  codigo: string;
  descricao: string;
}

export const ProdutosServicos = [
  new ProdutoServicoConst('00', 'MERCADORIA PARA REVENDA'),
  new ProdutoServicoConst('01', 'MATERIA - PRIMA'),
  new ProdutoServicoConst('02', 'EMBALAGEM'),
  new ProdutoServicoConst('03', 'PRODUTO EM PROCESSO'),
  new ProdutoServicoConst('04', 'PRODUTO ACABADO'),
  new ProdutoServicoConst('05', 'SUBPRODUTO'),
  new ProdutoServicoConst('07', 'MATERIAL DE USO E CONSUMO'),
  new ProdutoServicoConst('08', 'ATIVO IMOBILIZADO'),
  new ProdutoServicoConst('10', 'OUTROS INSUMOS'),
  new ProdutoServicoConst('99', 'OUTRAS'),
];
