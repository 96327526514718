export class EmpresaUsuario {
  id: string;
  matrizId: string;
  nomeFantasia: string;
  documento: string;
  razaoSocial: string;
  superUsuario?: boolean|string;
  suporte: boolean;
  ativo: boolean;
  get nomeEmpresa() {
    return this.nomeFantasia ?? this.razaoSocial;
  }
  get matriz() {
    return this.matrizId === this.id;
  }

  static from(json: any = {}): EmpresaUsuario {
    const empresa = new EmpresaUsuario();
    empresa.id = json.id;
    empresa.matrizId = json.matrizId;
    empresa.nomeFantasia = json.nomeFantasia;
    empresa.documento = json.documento;
    empresa.razaoSocial = json.razaoSocial;
    empresa.superUsuario = json.superUsuario;
    empresa.suporte = json.suporte;
    empresa.ativo = json.ativo;
    return empresa;
  }
}
