import {NFeModalidadeFrete} from '@app/shared/enums/nfe-modalidade-frete.enum';

import {NotaFiscalVeiculo} from './veiculo.entity';
import {NotaFiscalVolume} from './volume.entity';

export class NotaFiscalFrete {
  modalidade: NFeModalidadeFrete;
  documento: string;
  ie: string;
  nome: string;
  endereco: string;
  municipio: string;
  uf: string;
  valorServico: number;
  baseCalculo: number;
  aliquota: number;
  valorIcmsRetido: number;
  cfop: string;
  municipioIbge: string;
  vagao: string;
  balsa: string;
  veiculo: NotaFiscalVeiculo;
  reboques: NotaFiscalVeiculo[];
  volumes: NotaFiscalVolume[];

  static from(json: any = {}): NotaFiscalFrete {
    const frete = new NotaFiscalFrete();
    frete.modalidade = json.modalidade;
    frete.documento = json.documento;
    frete.ie = json.ie;
    frete.nome = json.nome;
    frete.endereco = json.endereco;
    frete.municipio = json.municipio;
    frete.uf = json.uf;
    frete.valorServico = json.valorServico;
    frete.baseCalculo = json.baseCalculo;
    frete.aliquota = json.aliquota;
    frete.valorIcmsRetido = json.valorIcmsRetido;
    frete.cfop = json.cfop;
    frete.municipioIbge = json.municipioIbge;
    frete.vagao = json.vagao;
    frete.balsa = json.balsa;
    if (json.veiculo) frete.veiculo = NotaFiscalVeiculo.from(json.veiculo);
    frete.reboques = json.reboques?.map(
                         (v: NotaFiscalVeiculo) => NotaFiscalVeiculo.from(v)) ??
        [];
    frete.volumes =
        json.volumes?.map((v: NotaFiscalVolume) => NotaFiscalVolume.from(v)) ??
        [];

    return frete;
  }
}