import '@app/shared/extensions/map.extension';

import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {NotaFiscalFacade} from '@app/abstraction/domain/facades/fiscal/nota-fiscal/nota-fiscal.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {NotaFiscalListagem} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-listagem.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {NFeFinalidade, NFeFinalidadeLabels,} from '@app/shared/enums/nfe-finalidade.enum';
import {NFeSaidaStatusLabels, NFeStatus} from '@app/shared/enums/nfe-status.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-nota-fiscal-saida-busca-rapida',
  templateUrl: './nota-fiscal-saida-busca-rapida.component.html',
})
export class NotaFiscalSaidaBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('documentoTemp', {static: true}) documentoTemp: TemplateRef<any>;
  @ViewChild('nfeFinalidadeTemp', {static: true})
  nfeFinalidadeTemp: TemplateRef<any>;
  @ViewChild('statusNotaFiscalTemp', {static: true})
  statusNotaFiscalTemp: TemplateRef<any>;

  @Input() mostrarModal: boolean;
  @Input() finalidade: NFeFinalidade;
  @Input() situacaoBusca: NFeStatus;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  filtro: string;
  itensSelecionados: any;

  get modoSelecao() {
    return this.finalidade == NFeFinalidade.Devolucao ? 'multiple' : 'single';
  }
  get selectable() {
    return this.finalidade != NFeFinalidade.Devolucao;
  }
  get checkboxable() {
    return this.finalidade == NFeFinalidade.Devolucao;
  }
  get dados() {
    return this.notaFiscalFacade.notasFiscaisSaida?.filter(
        (notaFiscal: NotaFiscalListagem) =>
            notaFiscal.situacao == this.situacaoBusca);
  }
  get nfeFinalidadeLabels() {
    return NFeFinalidadeLabels;
  }
  get nfeSaidaSituacaoLabels() {
    return NFeSaidaStatusLabels;
  }
  constructor(
      private notaFiscalFacade: NotaFiscalFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarNotasFiscaisSaidaComFiltro() {
    this.notaFiscalFacade.buscarNotasFiscaisSaida({
      pagina: this.pagina,
      limite: this.limite,
      finalidade: NFeFinalidade.Normal,
      destinatarioDocumento: this.filtro,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'numeroNotaFiscal',
        name: 'Número',
        cellClass: 'text-right',
        headerClass: 'text-right',
      },
      {
        field: 'serie',
        name: 'Série',
        cellClass: 'text-right',
        headerClass: 'text-right',
      },
      {
        field: 'destinatarioRazaoSocial',
        name: 'Nome/Razão social do destinatário',
      },
      {
        field: 'destinatarioDocumento',
        name: 'Documento do destinatário',
        cellTemplate: this.documentoTemp,
      },
      {
        field: 'chaveEletronica',
        name: 'Chave eletrônica',
      },
      {
        field: 'finalidade',
        name: 'Finalidade',
        cellTemplate: this.nfeFinalidadeTemp,
      },
      {
        field: 'situacao',
        name: 'Situação',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.statusNotaFiscalTemp,
      },
    ];
  }
  async abrirModal() {
    this.iniciarPaginacao();
    this.filtro = null;
    this.notaFiscalFacade.limparListaNotasFiscaisSaida();
    this.buscarNotasFiscaisSaidaComFiltro();
  }
  buscarNotasFiscaisSaidaProximaPagina() {
    this.pagina++;
    this.buscarNotasFiscaisSaidaComFiltro();
  }
  buscarStyleTagStatus(situacao: NFeStatus) {
    switch (situacao) {
      case NFeStatus.Emitida:
        return 'success';
      case NFeStatus.Cancelada:
        return 'danger';
      default:
        return 'warning';
    }
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.notaFiscalFacade.limparListaNotasFiscaisSaida();
    this.buscarNotasFiscaisSaidaComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itensSelecionados = null;
    this.notaFiscalFacade.limparListaNotasFiscaisSaida();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    if (this.itensSelecionados) {
      const notas = this.itensSelecionados.length ? this.itensSelecionados :
                                                    [this.itensSelecionados];
      this.aoSelecionar.emit(notas);
      this.itensSelecionados = null;
      this.limparPaginacao();
    }
  }
  setarItemSelecionado(evento: any) {
    this.itensSelecionados = evento;
  }
}
