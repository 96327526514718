import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {NotaFiscalFacade} from '@app/abstraction/domain/facades/fiscal/nota-fiscal/nota-fiscal.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {NotaFiscalResumoFiltro} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-resumo-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {CodigosManifestacao} from '@app/shared/enums/codigos-manifestacao.enum';
import {ConsultaDataPeriodo} from '@app/shared/enums/consulta-data-tipo.enum';
import {NFeConsultaPorData} from '@app/shared/enums/nfe-consulta-por-data.enum';
import {NFeEntradaStatusLabels, NFeStatus} from '@app/shared/enums/nfe-status.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-manifestador-busca-rapida',
  templateUrl: './manifestador-busca-rapida.component.html',
})
export class ManisfestadorBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('dataTemp', {static: true}) dataTemp: TemplateRef<any>;
  @ViewChild('decimalTemp', {static: true}) decimalTemp: TemplateRef<any>;
  @ViewChild('documentoTemp', {static: true}) documentoTemp: TemplateRef<any>;
  @ViewChild('situacaoManifestacaoTemp', {static: true})
  situacaoManifestacaoTemp: TemplateRef<any>;
  @ViewChild('situacaoNFeTemp', {static: true})
  situacaoNFeTemp: TemplateRef<any>;

  @Input() mostrarModal: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  filtro = <NotaFiscalResumoFiltro>{};
  itensSelecionados: any;
  mostrarBuscaAvancada: any;
  inicio: Date|string;
  fim: Date|string;
  get dados() {
    return this.notaFiscalFacade.notasFiscaisResumos?.filter((e) => {
      return !e.situacao;
    });
  }
  get nfeEntradaSituacaoLabels() {
    return NFeEntradaStatusLabels;
  }
  get empresas() {
    return this.authFacade.grupoLogado?.empresas;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get valorPlaceholder() {
    return this.mascaraService.placeholder(this.valorDecimais);
  }
  get liberarDatas() {
    return this.filtro.consultaDataPeriodo == ConsultaDataPeriodo.Personalizado;
  }
  constructor(
      private notaFiscalFacade: NotaFiscalFacade,
      private parametroFacade: ParametroFacade,
      private authFacade: AuthFacade,
      private mascaraService: MascaraService,
      errorMessagesFacade: ErrorMessageFacade,
      toastrService: ToastrService,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private retornarIntervalosDataFiltro() {
    const inicio = this.filtro.dataInicio ?
        moment(this.inicio).startOf('day').toDate() :
        null;
    const fim =
        this.filtro.dataFim ? moment(this.fim).endOf('day').toDate() : null;
    return {inicio, fim};
  }
  private buscarNotasFiscaisResumosComFiltro() {
    const {inicio, fim} = this.retornarIntervalosDataFiltro();

    this.notaFiscalFacade.buscarNotasFiscaisResumos({
      pagina: this.pagina,
      limite: this.limite,
      chaveEletronica: this.filtro.chaveEletronica,
      codigoManifestacao: this.filtro.codigoManifestacao,
      dataEmissaoInicio:
          this.filtro.consultaPorData == NFeConsultaPorData.Emissao ? inicio :
                                                                      null,
      dataEmissaoFim:
          this.filtro.consultaPorData == NFeConsultaPorData.Emissao ? fim :
                                                                      null,
      dataCriacaoInicio:
          this.filtro.consultaPorData == NFeConsultaPorData.Criacao ? inicio :
                                                                      null,
      dataCriacaoFim:
          this.filtro.consultaPorData == NFeConsultaPorData.Criacao ? fim :
                                                                      null,
      valor: this.filtro.valor,
      nome: this.filtro.nome,
      documento: this.filtro.documento,
      empresas: this.filtro.empresas,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'documento',
        name: 'Documento',
        cellTemplate: this.documentoTemp,
      },
      {
        field: 'nome',
        name: 'Emitente',
      },
      {
        field: 'chaveEletronica',
        name: 'Chave eletrônica',
      },
      {
        field: 'numeroNotaFiscal',
        name: 'Número NF-e',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: 'dataEmissao',
        name: 'Data emissão',
        cellTemplate: this.dataTemp,
      },
      {
        field: 'valor',
        name: 'Valor (R$)',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'codigoManifestacao',
        name: 'Situação da Manifestação',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.situacaoManifestacaoTemp,
        width: 200,
      },
      {
        field: 'situacao',
        name: 'Situação da Entrada',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.situacaoNFeTemp,
        width: 200,
      },
    ];
  }
  private setarFiltroInicial() {
    this.filtro = <NotaFiscalResumoFiltro>{
      consultaDataPeriodo:
          this.filtro.consultaDataPeriodo ?? ConsultaDataPeriodo.MesAtual,
      consultaPorData: NFeConsultaPorData.Criacao,
      dataInicio: this.filtro.dataInicio ?? moment().startOf('month').toDate(),
      dataFim: this.filtro.dataFim ?? moment().endOf('month').toDate(),
      empresas: [this.authFacade.empresaLogada.id],
      chaveEletronica: this.filtro.chaveEletronica,
      codigoManifestacao: this.filtro.codigoManifestacao,
      valor: this.filtro.valor,
      nome: this.filtro.nome,
      documento: this.filtro.documento,
      dataCriacaoFim: this.filtro.dataCriacaoFim,
      dataCriacaoInicio: this.filtro.dataCriacaoInicio,
      dataEmissaoFim: this.filtro.dataEmissaoFim,
      dataEmissaoInicio: this.filtro.dataEmissaoInicio,
      dataRecebimentoFim: this.filtro.dataRecebimentoFim,
      dataRecebimentoInicio: this.filtro.dataRecebimentoInicio,
      direcao: this.filtro.direcao,
      propriedadeOrdem: this.filtro.propriedadeOrdem,
    };

    this.setarValoresDatas(this.filtro.dataInicio, this.filtro.dataFim);
  }
  async abrirModal() {
    this.iniciarPaginacao();
    this.notaFiscalFacade.limparListaNotasFiscaisResumos();
    this.buscarDadosIniciaisManifestador();
  }
  async buscarDadosIniciaisManifestador() {
    await this.notaFiscalFacade.consultarTempoRestante()
        .then(async (e: any) => {
          if (e?.ultimaAtualizacao)
            this.notaFiscalFacade.salvarUltimaAtualizacao(e.ultimaAtualizacao);
          await this.notaFiscalFacade.consultarNotasFiscaisManifestador();
        })
        .catch(() => null);
    this.buscarNotasFiscaisResumosComFiltro();
  }
  buscarNotasFiscaisResumosProximaPagina() {
    this.pagina++;
    this.buscarNotasFiscaisResumosComFiltro();
  }
  buscarDescricaoTagSituacaoNotaFiscal(codigoManifestacao: number) {
    if (codigoManifestacao === CodigosManifestacao.Confirmar) {
      return 'Confirmada';
    } else if (codigoManifestacao === CodigosManifestacao.DarCiencia) {
      return 'Dado Ciência';
    } else if (codigoManifestacao === CodigosManifestacao.Desconhecer) {
      return 'Desconhecida';
    } else if (
        codigoManifestacao === CodigosManifestacao.OperacaoNaoRealizada) {
      return 'Oper. Não Realizada';
    } else {
      return 'Não Manifestada';
    }
  }
  buscarStyleTagStatusNFe(situacao: NFeStatus) {
    switch (situacao) {
      case NFeStatus.Lancada:
        return 'success';
      case NFeStatus.Cancelada:
        return 'danger';
      default:
        return 'warning';
    }
  }
  buscarCorTagSituacaoNotaFiscal(codigoManifestacao: number) {
    if (codigoManifestacao === CodigosManifestacao.Confirmar) {
      return 'success';
    } else if (codigoManifestacao === CodigosManifestacao.DarCiencia) {
      return 'warning';
    } else if (codigoManifestacao === CodigosManifestacao.Desconhecer) {
      return 'danger';
    } else if (
        codigoManifestacao === CodigosManifestacao.OperacaoNaoRealizada) {
      return 'info';
    } else {
      return 'secondary';
    }
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.notaFiscalFacade.limparListaNotasFiscaisResumos();
    this.buscarNotasFiscaisResumosComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itensSelecionados = null;
    this.notaFiscalFacade.limparListaNotasFiscaisResumos();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
    this.montarDropdownNFeConsultaPorData();
    this.montarDropdownConsultaDataPeriodos();
    this.setarFiltroInicial();
  }
  setarValorDataInicio(value: any) {
    this.inicio = !value ? null : moment(value).toLocaleString();
  }
  setarValorDataFim(value: any) {
    this.fim = !value ? null : moment(value).toLocaleString();
  }
  setarValoresDatas(inicio: any, fim: any) {
    this.setarValorDataInicio(inicio);
    this.setarValorDataFim(fim);
  }
  selecionar() {
    if (this.itensSelecionados) {
      this.aoSelecionar.emit(this.itensSelecionados);
      this.itensSelecionados = null;
      this.limparPaginacao();
    }
  }
  setarItemSelecionado(evento: any) {
    this.itensSelecionados = evento;
  }
  toggleBuscaAvancada() {
    this.mostrarBuscaAvancada = !this.mostrarBuscaAvancada;
  }
  limparCampos() {
    this.iniciarPaginacao();
    this.notaFiscalFacade.limparListaNotasFiscaisEntrada();
    this.limparFiltro(this.filtro);
    this.setarFiltroInicial();
    this.buscarComFiltro();
  }
  setarDatas(evento: ConsultaDataPeriodo = ConsultaDataPeriodo.MesAtual) {
    const datas = this.setarPeriodoConsultaFiltro(evento);
    this.filtro.dataInicio = datas.inicio;
    this.filtro.dataFim = datas.fim;

    this.setarValoresDatas(datas.inicio, datas.fim);
  }
  buscarComFiltro() {
    this.filtrar();
    this.toggleBuscaAvancada();
  }
}
