import {Component, Input, OnInit} from '@angular/core';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';

@Component({
  selector: 'app-table-skeleton',
  templateUrl: './table-skeleton.component.html',
})
export class TableSkeletonComponent implements OnInit {
  @Input() checkboxable: boolean;
  @Input() expandable: boolean;
  @Input() selectable: boolean;
  @Input() rowsCount: number;
  columns: TableColumn[];

  get value() {
    return Array.from(Array(this.rowsCount).keys());
  }

  ngOnInit() {
    this.montarColunasTabela();
  }
  private montarColunasTabela() {
    this.columns = [
      {
        field: '1',
        name: '1',
      },
      {
        field: '2',
        name: '2',
      },
      {
        field: '3',
        name: '3',
      },
      {
        field: '4',
        name: '4',
        width: 150,
      },
    ];
  }
}
