import {inject, Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Empresa} from '@app/abstraction/domain/entities/cadastros/empresa/empresa.entity';
import {EmpresaUsuario} from '@app/abstraction/domain/entities/shared/auth/empresa-usuario.entity';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {OAuthFacade} from '@app/abstraction/domain/facades/shared/o-auth/o-auth.facade';

@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(
      private oauthFacade: OAuthFacade,
      private authFacade: AuthFacade,
      private router: Router,
      private activatedRoute: ActivatedRoute,
  ) {}
  async checarUsuarioNaoLogado(url: string): Promise<boolean|UrlTree> {
    if (this.oauthFacade.isLoggedIn) return false;
    this.activatedRoute.snapshot.queryParams = {returnUrl: url};
    return true;
  }
  async checarUsuarioLogado(url: string): Promise<boolean|UrlTree> {
    this.activatedRoute.snapshot.queryParams = {returnUrl: url};
    if (!this.oauthFacade.isLoggedIn) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    if (this.authFacade.usuarioLogado) return true;
    await this.authFacade.finalizarFluxoLogin();
    return true;
  }
  async checarEmpresaSelecionada(url: string): Promise<boolean|UrlTree> {
    this.activatedRoute.snapshot.queryParams = {returnUrl: url};
    if (!this.oauthFacade.isLoggedIn) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    const empresaSelecionada =
        JSON.parse(localStorage.getItem('selected_company'));

    if (empresaSelecionada) {
      this.authFacade.setarEmpresaLogada(Empresa.from(empresaSelecionada));
      this.setarGrupoLogado(empresaSelecionada);
      await this.authFacade.buscarDadosCompletosDaEmpresa(
          empresaSelecionada.id);
      return true;
    }
    await this.authFacade.finalizarFluxoLogin();
    return true;
  }
  private setarGrupoLogado(empresaSelecionada: EmpresaUsuario) {
    const grupo = this.authFacade.usuarioLogado?.empresas?.find(
        (g) => g.empresas.some(e => e.id == empresaSelecionada.id));
    if (!grupo) return;
    grupo.empresas = grupo.empresas.orderByDescending(e => e.matriz);
    this.authFacade.setarGrupoLogado(grupo);
  }
}

export const usuarioNaoLogado: CanActivateFn =
    (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(AuthGuard).checarUsuarioNaoLogado(state.url);
    };
export const usuarioLogado: CanActivateFn =
    (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(AuthGuard).checarUsuarioLogado(state.url);
    };
export const empresaSelecionada: CanActivateFn =
    (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(AuthGuard).checarEmpresaSelecionada(state.url);
    };
