declare interface Map<K, V> {
  getByValue(value: V): K;
}

Map.prototype.getByValue = function (value: any) {
  for (const [key, val] of this) {
    if (val === value) {
      return key;
    }
  }
  return null;
};
