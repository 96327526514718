export class Dashboard {
  id: string;
  descricao: string;
  icone: string;
  ativo: boolean;

  static from(json: any = {}): Dashboard {
    const dashboard = new Dashboard();
    dashboard.id = json.id;
    dashboard.descricao = json.descricao;
    dashboard.icone = json.icone;
    dashboard.ativo = json.ativo;
    return dashboard;
  }
}