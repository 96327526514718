import {Injectable} from '@angular/core';
import {LocalConfigService} from '@app/abstraction/data/services/shared/local-config.service';
import {UsuarioConfig} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-config.interface';

@Injectable({providedIn: 'root'})
export class LocalConfigFacade {
  constructor(
      private service: LocalConfigService,
  ) {}

  buscarConfiguracoesDoUsuario(usuarioId: string, empresaId: string) {
    let configs = this.service.buscarConfig(usuarioId);

    if (!configs) configs = this.service.salvarConfig(usuarioId, {});

    if (!configs[empresaId]) {
      const config = <UsuarioConfig>{
        menuRecolhido: false,
        temaId: '1',
        favoritos: [],
        filtros: {},
        colunas: {},
      };
      configs[empresaId] = config;
      this.service.salvarConfig(usuarioId, configs);
    }
    return configs[empresaId];
  }
  salvarConfiguracoesDoUsuario(
      usuarioId: string,
      empresaId: string,
      config: UsuarioConfig,
  ) {
    let configs = this.service.buscarConfig(usuarioId);
    configs[empresaId] = config;
    this.service.salvarConfig(usuarioId, configs);
  }
}