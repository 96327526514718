import {GrupoEconomico} from '@app/abstraction/domain/entities/shared/auth/grupo-economico.entity';
import {Dashboard} from '@app/abstraction/domain/entities/shared/auth/modulo/dashboard.entity';
import {Modulo} from '@app/abstraction/domain/entities/shared/auth/modulo/modulo.entity';

export class UsuarioAuth {
  id: string;
  nome: string;
  email: string;
  senha: string;
  senhaDefinida: boolean;
  ativo: boolean;
  oAuthId: string;
  empresas: GrupoEconomico[];
  modulos: Modulo[];
  dashboards: Dashboard[];

  static from(json: any = {}): UsuarioAuth {
    const usuario = new UsuarioAuth();
    usuario.id = json.id;
    usuario.nome = json.nome;
    usuario.email = json.email;
    usuario.senha = json.senha;
    usuario.senhaDefinida = json.senhaDefinida;
    usuario.ativo = json.ativo;
    usuario.oAuthId = json.oAuthId;
    usuario.empresas = json.empresas?.map(GrupoEconomico.from);
    usuario.modulos = json.modulos?.map(Modulo.from);
    usuario.dashboards = json.dashboards?.map(Dashboard.from);
    return usuario;
  }
}
