<p-dialog
  header="Novo plano de contas"
  [style]="{ width: isDesktop ? '50%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="planoContasForm.form"
  (onHide)="fecharModal()"
  (onShow)="this.aoAbrirModal()"
>
  <p-tabView [scrollable]="true" [formGroup]="planoContasForm.form">
    <p-tabPanel header="Dados Cadastrais" [selected]="true">
      <div class="formgrid grid">
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6">
          <app-custom-label texto="Nível do plano"></app-custom-label>
          <app-dropdown-input-ngmodel
            [options]="niveisPlanoContas"
            [readonly]="planoContasForm.operacaoFormEditar"
            [modelOptions]="{ standalone: true }"
            [ngClass]="{ readonly: planoContasForm.operacaoFormEditar }"
            (modelChange)="buscarPlanosContasPorNivel()"
            [(model)]="planoContasForm.nivelPlanoContas"
          ></app-dropdown-input-ngmodel>
        </div>
      </div>
      <div class="formgrid grid">
        <!-- #region NIVEL 1 -->
        <div
          class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6"
          *ngIf="planoContasForm.nivel2Selecionado"
        >
          <app-custom-label texto="Nível 1" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            optionLabel="descricao"
            optionValue="id"
            [options]="planosContasNivel1$ | async"
            [control]="planoContasForm.form.controls.planoConta1Id"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region NIVEL 2 -->
        <div
          class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6"
          *ngIf="planoContasForm.nivel3Selecionado"
        >
          <app-custom-label texto="Nível 2" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            optionLabel="descricao"
            optionValue="id"
            [options]="planosContasNivel2$ | async"
            [control]="planoContasForm.form.controls.planoConta2Id"
          >
          </app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region DESCRICAO -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6">
          <app-custom-label texto="Descrição" [obrigatorio]="true">
          </app-custom-label>
          <app-text-input
            [control]="planoContasForm.form.controls.descricao"
            [maxlength]="100"
          ></app-text-input>
        </div>
        <!-- #endregion -->

        <!-- #region CODIGO DA CONTA -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6">
          <app-custom-label texto="Código da conta" [obrigatorio]="true">
          </app-custom-label>
          <app-numeric-input
            [control]="planoContasForm.form.controls.contaCodigo"
            [options]="inteiroMask"
          ></app-numeric-input>
        </div>
        <!-- #endregion -->

        <!-- #region CONTA CONTABIL -->
        <div
          class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6"
          *ngIf="planoContasForm.nivel3Selecionado"
        >
          <app-custom-label texto="Conta contábil" [obrigatorio]="true">
          </app-custom-label>
          <app-text-input
            [control]="planoContasForm.form.controls.contaContabil"
            [maxlength]="25"
          ></app-text-input>
        </div>
        <!-- #endregion -->

        <!-- #region CODIGO NATUREZA -->
        <div
          class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6"
          *ngIf="planoContasForm.nivel3Selecionado"
        >
          <app-custom-label texto="Código Natureza"></app-custom-label>
          <app-dropdown-input
            [options]="codigoNaturezas"
            [control]="planoContasForm.form.controls.codigoNatureza"
            [showClear]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region GRUPO DA CONTA/DRE -->
        <div
          class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6"
          *ngIf="planoContasForm.nivel3Selecionado"
        >
          <app-custom-label texto="Grupo de Conta/DRE"></app-custom-label>
          <app-dropdown-input
            optionLabel="descricao"
            optionValue="id"
            [options]="planoContaGrupos$ | async"
            [control]="planoContasForm.form.controls.planoContaGrupoId"
            [showClear]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region TIPO DE CONTA -->
        <div
          class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6"
          *ngIf="planoContasForm.nivel3Selecionado"
        >
          <app-custom-label texto="Tipo de Conta"></app-custom-label>
          <app-dropdown-input
            [options]="contaTipos"
            [control]="planoContasForm.form.controls.tipoConta"
            [showClear]="true"
          >
          </app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region DESPESA -->
        <div
          class="col-12 xl:col-2 lg:col-2 md:col-4 sm:col-6 mt-0 xl:mt-5 lg:mt-5 md:mt-0 sm:mt-5"
          *ngIf="planoContasForm.nivel3Selecionado"
        >
          <app-checkbox-input
            label="Despesa?"
            controlName="despesa"
            [control]="planoContasForm.form.controls.despesa"
          >
          </app-checkbox-input>
        </div>
        <!-- #endregion -->
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
