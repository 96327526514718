<p-dialog
  [header]="header"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
    (scrolled)="buscarFuncionariosProximaPagina()"
  >
    <div class="formgrid grid mt-1">
      <div class="field col-12 md:col-6">
        <app-text-input-ngmodel
          placeholder="Buscar por nome/razão social"
          (inputKeydown)="filtrarComTimeout()"
          [(model)]="filtro.nome"
        ></app-text-input-ngmodel>
      </div>
    </div>
    <app-table
      [columns]="colunas"
      [rows]="dados"
      [selectable]="!multiSelect"
      [checkboxable]="multiSelect"
      [selectionMode]="multiSelect ? 'multiple' : 'single'"
      [(selecteds)]="itensTabelaSelecionados"
    ></app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
      [disabled]="desabilitarBotaoSelecionar"
    ></p-button>
  </ng-template>
</p-dialog>
<ng-template #documentoTemp let-value="value">
  <span>{{ value | documento }}</span>
</ng-template>
<ng-template #situacaoTemp let-value="value">
  <p-tag
    *ngIf="value"
    styleClass="mr-2"
    severity="success"
    value="Ativo"
  ></p-tag>
  <p-tag
    *ngIf="!value"
    styleClass="mr-2"
    severity="danger"
    value="Inativo"
  ></p-tag>
</ng-template>
