import {Injectable} from '@angular/core';
import {PlanoContasListagem} from '@app/abstraction/domain/interfaces/financeiro/plano-contas/plano-contas-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PlanoContasStore {
  private readonly _planosContasNivel1 =
      new BehaviorSubject<PlanoContasListagem[]>(null);
  private readonly _planosContasNivel2 =
      new BehaviorSubject<PlanoContasListagem[]>(null);
  private readonly _planosContasNivel3 =
      new BehaviorSubject<PlanoContasListagem[]>(null);

  get planosContasNivel1(): PlanoContasListagem[] {
    return this._planosContasNivel1.getValue();
  }
  set planosContasNivel1(planosContasNivel1: PlanoContasListagem[]) {
    this._planosContasNivel1.next(planosContasNivel1);
  }
  get planosContasNivel1$() {
    return this._planosContasNivel1.asObservable();
  }

  get planosContasNivel2(): PlanoContasListagem[] {
    return this._planosContasNivel2.getValue();
  }
  set planosContasNivel2(planosContasNivel2: PlanoContasListagem[]) {
    this._planosContasNivel2.next(planosContasNivel2);
  }
  get planosContasNivel2$() {
    return this._planosContasNivel2.asObservable();
  }

  get planosContasNivel3(): PlanoContasListagem[] {
    return this._planosContasNivel3.getValue();
  }
  set planosContasNivel3(planosContasNivel3: PlanoContasListagem[]) {
    this._planosContasNivel3.next(planosContasNivel3);
  }
  get planosContasNivel3$() {
    return this._planosContasNivel3.asObservable();
  }

  adicionarPlanoContasNivel1(planoContas: PlanoContasListagem) {
    if (!this.planosContasNivel1) {
      this.planosContasNivel1 = [];
    }
    this.planosContasNivel1.push(planoContas);
  }
  adicionarPlanoContasNivel2(planoContas: PlanoContasListagem) {
    if (!this.planosContasNivel2) {
      this.planosContasNivel2 = [];
    }
    this.planosContasNivel2.push(planoContas);
  }
  adicionarPlanoContasNivel3(planoContas: PlanoContasListagem) {
    if (!this.planosContasNivel3) {
      this.planosContasNivel3 = [];
    }
    this.planosContasNivel3.push(planoContas);
  }
  adicionarRangeNivel1(planosContasNivel1: PlanoContasListagem[]) {
    if (!this.planosContasNivel1) {
      this.planosContasNivel1 = [];
    }
    planosContasNivel1.forEach((planoContasNivel1) => {
      if (!this.planosContasNivel1.some((c) => c.id === planoContasNivel1.id)) {
        this.planosContasNivel1.push(planoContasNivel1);
      }
    });
  }
  adicionarRangeNivel2(planosContasNivel2: PlanoContasListagem[]) {
    if (!this.planosContasNivel2) {
      this.planosContasNivel2 = [];
    }
    planosContasNivel2.forEach((planoContasNivel2) => {
      if (!this.planosContasNivel2.some((c) => c.id === planoContasNivel2.id)) {
        this.planosContasNivel2.push(planoContasNivel2);
      }
    });
  }
  adicionarRangeNivel3(planosContasNivel3: PlanoContasListagem[]) {
    if (!this.planosContasNivel3) {
      this.planosContasNivel3 = [];
    }
    planosContasNivel3.forEach((planoContasNivel3) => {
      if (!this.planosContasNivel3.some((c) => c.id === planoContasNivel3.id)) {
        this.planosContasNivel3.push(planoContasNivel3);
      }
    });
  }
}
