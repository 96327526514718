import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContaBancariaBoletoCarteira} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria-boleto-carteira.entity';
import {CnabLabels} from '@app/shared/enums/cnab.enum';

@Component({
  selector: 'app-cartao-conta-boleto-carteira',
  templateUrl: './cartao-conta-boleto-carteira.component.html',
  styleUrls: ['./cartao-conta-boleto-carteira.component.scss'],
})
export class CartaoContaBoletoCarteiraComponent {
  @Input() contaBoletoCarteira: ContaBancariaBoletoCarteira;
  @Output() aoClicarEmEditar = new EventEmitter();
  @Output() aoClicarEmExcluir = new EventEmitter();

  get cnabLabels() {
    return CnabLabels;
  }

  editarClicado() {
    this.aoClicarEmEditar.emit();
  }

  excluirClicado() {
    this.aoClicarEmExcluir.emit();
  }
}
