import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PlanoContaGruposListagem} from '@app/abstraction/domain/interfaces/financeiro/plano-conta-grupos/plano-conta-grupos-listagem.entity';
import {API} from '@app/shared/constants/api.constant';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PlanoContaGruposService {
  constructor(private client: HttpClient) {}
  buscarTodos() {
    const planoContaGrupos$ = this.client.get<PlanoContaGruposListagem[]>(
        `${API.FINANCEIRO_PLANO_CONTA_GRUPO}`);
    return lastValueFrom(planoContaGrupos$);
  }
}
