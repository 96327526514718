export class Acao {
  id: string;
  descricao: string;

  static from(json: any = {}): Acao {
    const acao = new Acao();
    acao.id = json.id;
    acao.descricao = json.descricao;
    return acao;
  }
}
