import {Pipe, PipeTransform} from '@angular/core';
import {ProdutoFacade} from '@app/abstraction/domain/facades/produtos/produto/produto.facade';
import {ProdutoSimplificadoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-simplificado-listagem.interface';

@Pipe({
  name: 'produto',
})
export class ProdutoPipe implements PipeTransform {
  constructor(private produtoFacade: ProdutoFacade) {}
  transform(produtoId: string, campo: string = 'descricao'): any {
    if (produtoId) {
      const produto = this.produtoFacade.produtosSimplificados.find(
                          (produto: ProdutoSimplificadoListagem) =>
                              produto.id == produtoId) as any;
      return produto?.[campo];
    }
    return '';
  }
}
