import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LancamentoPagamento} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-pagamento.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ILancamentoPagamentoForm} from '@app/abstraction/domain/forms/financeiro/lancamento/lancamento-pagamento.form';
import {LancamentoParcelaListagem} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-listagem.interface';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-contas-lista-pagamento-parcial',
  templateUrl: './contas-lista-pagamento-parcial.component.html',
  styleUrls: ['./contas-lista-pagamento-parcial.component.scss']
})
export class ContasListaPagamentoParcialComponent extends FormBaseComponent
    implements OnInit {
  @ViewChild('decimalTemp', {static: true}) decimalTemp: TemplateRef<any>;
  @ViewChild('dataTemp', {static: true}) dataTemp: TemplateRef<any>;
  @ViewChild('formaPagamentoDescricaoTemp', {static: true})
  formaPagamentoDescricaoTemp: TemplateRef<any>;
  @ViewChild('acoesPagamentosTemp', {static: true})
  acoesPagamentosTemp: TemplateRef<any>;

  @Input() mostrarModal: boolean;
  @Input() parcela: LancamentoParcelaListagem;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSalvar = new EventEmitter<LancamentoPagamento[]>();

  mostrarLancamentoPagamentoModal: boolean;
  colunasPagamentos: TableColumn[];
  pagamentos: LancamentoPagamento[] = [];
  indexPagamentoEditado: number;
  pagamentoSelecionado: LancamentoPagamento;
  operacaoForm: OperacaoForm;

  get titulo() {
    return 'Novos Pagamentos';
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get parcelaSelecionada() {
    return <LancamentoParcelaListagem>{
      ...this.parcela,
      valorPendente: this.parcela?.valorPendente - this.totalPago
    };
  }
  get totalPago() {
    return this.pagamentos.sum(p => p.valorTotal);
  }
  get totalPendente() {
    return this.parcelaSelecionada.valorPendente < 0 ?
        0 :
        this.parcelaSelecionada.valorPendente;
  }
  constructor(
      private deviceDetectorFacade: DeviceDetectorFacade,
      private parametroFacade: ParametroFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private montarColunasLancamentoPagamentosTabela() {
    this.colunasPagamentos = [
      {
        field: 'formaPagamentoId',
        name: 'Forma de pagamento',
        cellTemplate: this.formaPagamentoDescricaoTemp,
      },
      {
        field: 'valor',
        name: 'Valor (R$)',
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'valorOutrosAcrescimos',
        name: 'Acréscimos (R$)',
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: true,
      },
      {
        field: 'valorMulta',
        name: 'Multa (R$)',
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: true,
      },
      {
        field: 'valorJuros',
        name: 'Juros (R$)',
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: true,
      },
      {
        field: 'valorDesconto',
        name: 'Descontos (R$)',
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: true,
      },
      {
        field: 'valorTotal',
        name: 'Valor total pago (R$)',
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'dataPagamento',
        name: 'Data do pagamento',
        cellTemplate: this.dataTemp,
      },
      {
        field: 'observacao',
        name: 'Observações',
        hidden: true,
      },
      {
        field: 'id',
        name: '',

        width: 150,
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.acoesPagamentosTemp,
      },
    ];
  }
  aoAbrir() {
    this.pagamentos = [];
  }
  abrirConfirmacaoExcluisaoPagamento(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.excluirPagamento(index);
      },
    });
  }
  abrirLancamentoPagamentoModal(
      pagamento?: LancamentoPagamento, index?: number) {
    this.indexPagamentoEditado = index;
    this.pagamentoSelecionado = pagamento;
    if (pagamento) {
      this.operacaoForm = OperacaoForm.Editar;
    } else {
      if (this.totalPendente === 0) {
        this.toastrService.warning(
            'Não é possível adicionar mais pagamentos, pois o valor total já foi pago');
        return;
      }
      this.operacaoForm = OperacaoForm.Adicionar;
    }
    this.mostrarLancamentoPagamentoModal = true;
  }
  excluirPagamento(index: number) {
    this.pagamentos.removeAt(index);
  }
  fecharLancamentoPagamentoModal() {
    this.mostrarLancamentoPagamentoModal = false;
  }
  fecharModal() {
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.montarColunasLancamentoPagamentosTabela();
  }
  salvar() {
    if (this.pagamentos.length === 0) {
      this.toastrService.warning('Adicione pelo menos um pagamento.');
      return;
    }
    this.aoSalvar.emit(this.pagamentos);
  }
  salvarPagamento(pagamentoForm: FormGroup<ILancamentoPagamentoForm>) {
    const pagamento = LancamentoPagamento.from(pagamentoForm.value);
    if (this.operacaoForm === OperacaoForm.Adicionar) {
      this.pagamentos.push(pagamento);
    } else {
      this.pagamentos.removeAt(this.indexPagamentoEditado);
      this.pagamentos.insertAt(this.indexPagamentoEditado, pagamento);
    }
    this.fecharLancamentoPagamentoModal();
  }
}
