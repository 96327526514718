import {DatePipe} from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {VersaoFacade} from '@app/abstraction/domain/facades/configuracoes/versao/versao.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {OAuthFacade} from '@app/abstraction/domain/facades/shared/o-auth/o-auth.facade';
import {UsuarioFavorito} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-favorito.interface';
import {VersaoListagem} from '@app/abstraction/domain/interfaces/configuracoes/versao/versao-listagem.interface';
import {FavoritoTipo} from '@app/shared/enums/favorito-tipo.enum';
import {MegaMenuItem, MenuItem} from 'primeng/api';

import {LayoutService} from './service/app.layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
  items: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
  @ViewChild('topbarmenu') menu!: ElementRef;

  favoritos: MegaMenuItem[];
  versao: VersaoListagem;

  get empresaLogada() {
    return this.authFacade.empresaLogada;
  }

  constructor(
      private authFacade: AuthFacade,
      private datePipe: DatePipe,
      private oauthFacade: OAuthFacade,
      private router: Router,
      private versaoFacade: VersaoFacade,
      public layoutService: LayoutService,
  ) {
    this.monitorarUsuarioLogado();
    this.monitorarUsuarioFavoritos();
  }
  private monitorarUsuarioFavoritos() {
    this.authFacade.usuarioConfig$.subscribe(() => {
      this.montarFavoritos();
    });
  }
  private montarFavoritos() {
    if (this.authFacade.usuarioFavoritos.length) {
      this.favoritos = <MegaMenuItem[]>[
        {
          label: 'Favoritos',
          icon: 'pi pi-fw pi-star-fill',
          items: [],
        },
      ];

      const favoritosUsuarioLogado = this.buscarFavoritosUsuarioLogado();
      const modulos = this.agruparFavoritosPorModulos(favoritosUsuarioLogado);
      const modulosChaves = Object.keys(modulos);

      modulosChaves.forEach((chave: any) => {
        const telas = modulos[chave];
        const items = <MenuItem[]>[];

        telas.forEach((tela: any) => {
          items.push(<MenuItem>{
            url: `/#${tela.link}`,
            label: tela.tela,
            target: '_self',
            icon: 'pi pi-angle-right',
          });
        });

        const modulo = <MenuItem[]>[
          {
            label: chave,
            items: items,
          },
        ];
        this.favoritos.first().items.push(modulo);
      });
    } else {
      this.favoritos = [];
    }
  }
  private monitorarUsuarioLogado() {
    this.authFacade.usuarioLogado$.subscribe(() => {
      this.criarMenuDrodown();
    });
  }
  private buscarFavoritosUsuarioLogado() {
    return this.authFacade.usuarioFavoritos.filter(
        (e) => e.tipo === FavoritoTipo.BarraFavoritos);
  }
  private agruparFavoritosPorModulos(favoritosBarra: UsuarioFavorito[]) {
    return favoritosBarra.reduce(function(r, a) {
      r[a.modulo] = r[a.modulo] || [];
      r[a.modulo].push(a);
      return r;
    }, Object.create(null));
  }
  private criarMenuDrodown() {
    this.items = [
      {
        label: 'Sair',
        icon: 'pi pi-fw pi-power-off',
        command: () => this.sair(),
      },
      {
        separator: true,
      },
      {
        label: `
                <span>${this.authFacade?.usuarioLogado.nome}</span></br>
                ${
            this.authFacade?.isSuperUsuario ? '<span>(SUPER USUÁRIO)</span>' :
                                              ''}`,
        styleClass: 'flex justify-content-center text-center',
      },
      {
        label: `<span>Versão ${this.versao?.versaoCompleta}</span></br>
                <span class="data-atualizacao">(${
            this.datePipe.transform(
                this.versao?.dataLancamento, 'dd/MM/yyyy')})</span>`,
        disabled: true,
        styleClass: 'flex justify-content-center text-center',
      },
    ];

    this.adicionaBotaoAlterarEmpresa();
  }
  private adicionaBotaoAlterarEmpresa() {
    if (this.authFacade.multiEmpresa)
      this.items.unshift({
        label: 'Alterar empresa',
        icon: 'pi pi-fw pi-sort-alt',
        command: () => this.redirecionarSelecionarEmpresa(),
      });
  }
  private redirecionarSelecionarEmpresa() {
    this.router.navigateByUrl('/selecionar-empresa');
  }
  async buscarUltimaVersao() {
    this.versao = await this.versaoFacade.buscarUltimaVersao();
  }
  async ngOnInit() {
    await this.buscarUltimaVersao();
    this.criarMenuDrodown();
  }
  sair() {
    this.oauthFacade.logout(true);
  }
}
