<ng-container *ngIf="control">
  <p-calendar
    #contentInput
    appendTo="body"
    yearRange="1950:2050"
    dateFormat="dd/mm/yy"
    styleClass=" inputfield w-full"
    appCalendarMask
    [placeholder]="placeholder"
    [formControl]="control"
    [showOnFocus]="false"
    [yearNavigator]="true"
    [keepInvalid]="true"
    [monthNavigator]="true"
    [showSeconds]="showSeconds"
    [showTime]="showTime"
    [showIcon]="showIcon"
    [readonlyInput]="readonly"
    [ngClass]="{
      readonly: readonly,
    }"
  >
  </p-calendar>
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</ng-container>
