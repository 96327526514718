import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {OutputFormat} from 'ngx-color-picker';

import {BaseInput} from '../../form-inputs/base/base-input';

@Component({
  selector: 'app-textarea-input-ngmodel',
  templateUrl: './textarea-input-ngmodel.component.html',
  styleUrls: ['./textarea-input-ngmodel.component.scss'],
})
export class TextAreaInputNgModelComponent extends BaseInput {
  @Input() upperCase: boolean = true;
  @Input() autoFocus: boolean;
  @Input() maxlengthCount: number = 0;
  @Input() foneMask: boolean;
  @Input() lengthOnlyNumber: boolean;
  @Input() textAlign: string = 'left';  // left, right, center
  @Input() fullWidth: boolean;          // left, right, center
  @Input() model: any;
  @Input() mask: string;
  @Input() placeholder: string = '';
  @Input() rows: number = 10;
  @Input() readonly: boolean;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() showButton: boolean;
  @Input() iconButton: string;
  @Input() disabledButton: boolean;
  @Input() showColorPicker: boolean;
  @Input() cpOutputFormat: OutputFormat = 'hex';
  @Input() cpPositionOffset: string = '50%';
  @Input() cpPositionRelativeToArrow: boolean = true;
  @Input() buttonTooltipText: string;
  @Input() buttonTooltipPosition: string;
  @Output() inputBlur = new EventEmitter();
  @Output() inputKeydown = new EventEmitter();
  @Output() modelChange = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
  get contador() {
    return this.maxlengthCount > 0 ? this.maxlengthCount : this.maxlength;
  }
  get controlLength() {
    return this.lengthOnlyNumber ?
        this.model?.toString().replace(/[^0-9]/g, '').length :
        this.model?.toString().length;
  }
  get hasErrors() {
    return this.model?.errors && this.model?.touched;
  }
  onBlur(event: any) {
    this.inputBlur.emit(event);
  }
  onKeyDown(event: any) {
    this.inputKeydown.emit(event);
  }
  onNgModelChange(event: any) {
    this.modelChange.emit(event);
  }
}
