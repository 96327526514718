import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {NaturezaReceita} from '@app/abstraction/domain/entities/cadastros/natureza-receita/natureza-receita.entity';
import {Cest} from '@app/abstraction/domain/entities/fiscal/cest/cest.entity';
import {NcmIcms} from '@app/abstraction/domain/entities/fiscal/ncm/ncm-icms.entity';
import {NcmUf} from '@app/abstraction/domain/entities/fiscal/ncm/ncm-uf.entity';
import {Ncm} from '@app/abstraction/domain/entities/fiscal/ncm/ncm.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {NcmIcmsForm} from '@app/abstraction/domain/forms/fiscal/ncm/ncm-icms.form';
import {NcmUfForm} from '@app/abstraction/domain/forms/fiscal/ncm/ncm-uf.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';
import {ToastrService} from 'ngx-toastr';

import {INcmIcmsForm} from './ncm-icms.form';
import {INcmPisCofinsIpiForm, NcmPisCofinsIpiForm} from './ncm-pis-cofins-ipi.form';
import {INcmUfForm} from './ncm-uf.form';

export interface INcmForm {
  ativo: FormControl<boolean>;
  ncmCodigo: FormControl<string>;
  ncmExcecaoTipi: FormControl<string|number>;
  ncmIcms: FormArray<FormGroup<INcmIcmsForm>>;
  ncmPisCofinsIpi: FormGroup<INcmPisCofinsIpiForm>;
  ncmUf: FormArray<FormGroup<INcmUfForm>>;
}

export class NcmForm extends BaseForm {
  form: FormGroup<INcmForm>;
  mostrarCestBuscaRapidaModal: boolean;
  mostrarNaturezaReceitaBuscaRapidaModal: boolean;
  mostrarNaturezaReceitaCadastroRapidoModal: boolean;
  mostrarNcmIcmsModal: boolean;
  mostrarNcmUfModal: boolean;
  ncmIcmsEditado: any;
  ncmIcmsForm: FormGroup<INcmIcmsForm>;
  ncmIcmsModalTitulo: string;
  ncmUfEditado: any;
  ncmUfForm: FormGroup<INcmUfForm>;
  ncmUfModalTitulo: string;
  operacaoForm: OperacaoForm;
  operacaoFormNcmIcms: OperacaoForm;
  operacaoFormNcmUf: OperacaoForm;
  get titulo() {
    return this.operacaoFormAdicionar ? 'Novo NCM' : 'Editar NCM';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  get operacaoFormEditar() {
    return this.operacaoForm === OperacaoForm.Editar;
  }
  get ncmUf() {
    return this.form.controls.ncmUf.value as NcmUf[];
  }
  get ncmUfFormArray() {
    return this.form.controls.ncmUf as FormArray<FormGroup<INcmUfForm>>;
  }
  get ncmIcmsFormArray() {
    return this.form.controls.ncmIcms as FormArray<FormGroup<INcmIcmsForm>>;
  }
  get ncmIcms() {
    return this.form.controls.ncmIcms.value as NcmIcms[];
  }
  get ncmCodigo() {
    return this.form.controls.ncmCodigo.value as string;
  }
  constructor(
      private errorMessagesFacade: ErrorMessageFacade,
      private toastrService: ToastrService,
  ) {
    super();
  }
  private criarNcmUfFormGroup(ncmUf?: NcmUf, index?: number) {
    this.operacaoFormNcmUf = OperacaoForm.Editar;
    this.ncmUfEditado = {
      index: index,
      control: this.ncmUfFormArray.controls[index],
    };
    return NcmUfForm.criarForm(ncmUf);
  }
  private criarNcmIcmsFormGroup(ncmIcms?: NcmIcms, index?: number) {
    this.operacaoFormNcmIcms = OperacaoForm.Editar;
    this.ncmIcmsEditado = {
      index: index,
      control: this.ncmIcmsFormArray.controls[index],
    };
    return NcmIcmsForm.criarForm(ncmIcms);
  }
  private preencherFormNcm(ncm: Ncm) {
    if (ncm.ncmUf) {
      ncm.ncmUf.forEach((ncmUf) => {
        this.adicionarNcmUfFormGroup(ncmUf);
      });
    }
    if (ncm.ncmIcms) {
      ncm.ncmIcms.forEach((ncmIcms) => {
        this.adicionarNcmIcmsFormGroup(ncmIcms);
      });
    }
    ncm.ncmExcecaoTipi = ncm.ncmExcecaoTipi.toString().padLeft('0', 2);
    this.form.patchValue(ncm);
  }
  private preencherObjetoNcmUf() {
    const ncmUf = NcmUf.from(this.ncmUfForm.value);
    ncmUf.toAPI();
    return ncmUf;
  }
  private preencherObjetoNcmIcms() {
    const ncmIcms = NcmIcms.from(this.ncmIcmsForm.value);
    ncmIcms.toAPI();
    return ncmIcms;
  }
  adicionarNcmUfFormGroup(ncmUf?: NcmUf) {
    this.ncmUfFormArray.push(this.criarNcmUfFormGroup(ncmUf));
  }
  adicionarNcmIcmsFormGroup(ncmIcms?: NcmIcms) {
    this.ncmIcmsFormArray.push(this.criarNcmIcmsFormGroup(ncmIcms));
  }
  abrirNcmUfModal(ncmUf?: NcmUf, index?: number) {
    this.ncmUfForm = this.criarNcmUfFormGroup(ncmUf, index);
    if (ncmUf) {
      this.ncmUfModalTitulo = 'Editar tributação';
      this.operacaoFormNcmUf = OperacaoForm.Editar;
    } else {
      this.ncmUfModalTitulo = 'Nova tributação';
      this.operacaoFormNcmUf = OperacaoForm.Adicionar;
    }
    this.mostrarNcmUfModal = true;
  }
  abrirNcmIcmsModal(ncmIcms?: NcmIcms, index?: number) {
    this.ncmIcmsForm = this.criarNcmIcmsFormGroup(ncmIcms, index);

    if (ncmIcms) {
      this.ncmIcmsModalTitulo = 'Editar tributação de ICMS';
      this.operacaoFormNcmIcms = OperacaoForm.Editar;
    } else {
      this.ncmIcmsModalTitulo = 'Nova tributação de ICMS';
      this.operacaoFormNcmIcms = OperacaoForm.Adicionar;
    }
    this.mostrarNcmIcmsModal = true;
  }
  abrirCestBuscaRapidaModal() {
    this.mostrarCestBuscaRapidaModal = true;
  }
  abrirNaturezaReceitaBuscaRapidaModal() {
    this.mostrarNaturezaReceitaBuscaRapidaModal = true;
  }
  abrirNaturezaReceitaCadastroRapidoModal() {
    this.mostrarNaturezaReceitaCadastroRapidoModal = true;
  }
  criarForm() {
    this.form = new FormGroup<INcmForm>({
      ncmCodigo: new FormControl(
          null,
          CustomValidators.required('Código NCM é obrigatório'),
          ),
      ncmExcecaoTipi: new FormControl(
          null,
          CustomValidators.required('Exceção Tipi é obrigatório'),
          ),
      ncmPisCofinsIpi: NcmPisCofinsIpiForm.criarForm(),
      ncmUf: new FormArray<FormGroup<INcmUfForm>>(
          [],
          CustomValidators.minLengthArray(
              1, 'Tributação por estado deve conter pelo menos um item'),
          ),
      ncmIcms: new FormArray<FormGroup<INcmIcmsForm>>(
          [],
          CustomValidators.minLengthArray(
              1, 'Tributação de ICMS deve conter pelo menos um item'),
          ),
      ativo: new FormControl(true),
    });
  }
  excluirNcmIcms(index: number) {
    this.ncmIcmsFormArray.removeAt(index);
  }
  excluirNcmUf(index: number) {
    this.ncmUfFormArray.removeAt(index);
  }
  fecharNcmUfModal() {
    this.mostrarNcmUfModal = false;
  }
  fecharNcmIcmsModal() {
    this.mostrarNcmIcmsModal = false;
  }
  fecharCestBuscaRapidaModal() {
    this.mostrarCestBuscaRapidaModal = false;
  }
  fecharNaturezaReceitaBuscaRapidaModal() {
    this.mostrarNaturezaReceitaBuscaRapidaModal = false;
  }
  fecharNaturezaReceitaCadastroRapidoModal() {
    this.mostrarNaturezaReceitaCadastroRapidoModal = false;
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  salvarNcmUf() {
    if (this.ncmUfForm.valid) {
      if (this.operacaoFormNcmUf === OperacaoForm.Adicionar) {
        const ncmUf = this.preencherObjetoNcmUf();

        if (this.ncmUf.some((e) => e.uf === ncmUf.uf)) {
          this.toastrService.error('Tributação já cadastrada para este estado');
          return;
        }

        this.adicionarNcmUfFormGroup(ncmUf);
      } else {
        const index = this.ncmUfEditado.index;
        const ncmUf = NcmUf.from(this.ncmUfForm.value);
        this.ncmUfFormArray.removeAt(index);
        this.ncmUfFormArray.insert(index, this.criarNcmUfFormGroup(ncmUf));
      }
      this.fecharNcmUfModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.ncmUfForm);
    }
  }
  salvarNcmIcms() {
    if (this.ncmIcmsForm.valid) {
      const ncmIcms = this.preencherObjetoNcmIcms();
      if (this.operacaoFormNcmIcms === OperacaoForm.Adicionar) {
        if (this.ncmIcms.some((e) => e.uf === ncmIcms.uf)) {
          this.toastrService.error('Tributação já cadastrada para este estado');
          return;
        }

        this.adicionarNcmIcmsFormGroup(ncmIcms);
      } else {
        const index = this.ncmIcmsEditado.index;
        this.ncmIcmsFormArray.removeAt(index);
        this.ncmIcmsFormArray.insert(
            index, this.criarNcmIcmsFormGroup(ncmIcms));
      }
      this.fecharNcmIcmsModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.ncmIcmsForm);
    }
  }
  setarCestSelecionado(cestSelecionado: Cest) {
    if (cestSelecionado) {
      this.ncmIcmsForm.controls.cestCodigo.setValue(cestSelecionado.cestCodigo);
      this.fecharCestBuscaRapidaModal();
    }
  }
  setarNaturezaReceitaSelecionado(naturezaReceitaSelecionado: NaturezaReceita) {
    if (naturezaReceitaSelecionado) {
      this.form.controls.ncmPisCofinsIpi.controls.naturezaReceitaCodigo
          .setValue(naturezaReceitaSelecionado.naturezaReceitaCodigo);
      this.fecharNaturezaReceitaBuscaRapidaModal();
    }
  }
  setarTipoFormulario(ncm?: Ncm) {
    if (ncm) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherFormNcm(ncm);
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
}
