import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Endereco } from '@app/abstraction/domain/entities/shared/endereco.entity';

@Component({
  selector: 'app-cartao-endereco',
  templateUrl: './cartao-endereco.component.html',
  styleUrls: ['./cartao-endereco.component.scss'],
})
export class CartaoEnderecoComponent {
  get temLogradouroNumero() {
    return (
      this.endereco?.logradouro?.length > 0 && this.endereco?.numero?.length > 0
    );
  }
  get temCidadeUf() {
    return this.endereco?.cidade?.length > 0 && this.endereco?.uf?.length > 0;
  }
  @Input() endereco: Endereco;
  @Output() aoCliquarEmEditar = new EventEmitter();
  editarClicado() {
    this.aoCliquarEmEditar.emit();
  }
}
