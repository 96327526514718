import {Injectable} from '@angular/core';
import {ProdutoVariacaoReferenciaListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-referencia/produto-variacao-referencia-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProdutoVariacaoReferenciaStore {
  private readonly _produtoVariacaoReferencias =
      new BehaviorSubject<ProdutoVariacaoReferenciaListagem[]>(null);
  get produtoVariacaoReferencias(): ProdutoVariacaoReferenciaListagem[] {
    return this._produtoVariacaoReferencias.getValue();
  }
  set produtoVariacaoReferencias(produtoVariacaoReferencias:
                                     ProdutoVariacaoReferenciaListagem[]) {
    this._produtoVariacaoReferencias.next(produtoVariacaoReferencias);
  }
  get produtoVariacaoReferencias$() {
    return this._produtoVariacaoReferencias.asObservable();
  }
  adicionar(produtoVariacaoReferencia: ProdutoVariacaoReferenciaListagem) {
    if (!this.produtoVariacaoReferencias) {
      this.produtoVariacaoReferencias = [];
    }
    this.produtoVariacaoReferencias.push(produtoVariacaoReferencia);
  }
  adicionarRange(produtoVariacaoReferencias:
                     ProdutoVariacaoReferenciaListagem[]) {
    if (!this.produtoVariacaoReferencias) {
      this.produtoVariacaoReferencias = [];
    }
    produtoVariacaoReferencias.forEach((produtoVariacaoReferencia) => {
      if (!this.produtoVariacaoReferencias.some(
              (c) => c.id === produtoVariacaoReferencia.id)) {
        this.produtoVariacaoReferencias.push(produtoVariacaoReferencia);
      }
    });
  }
}
