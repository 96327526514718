export class ProdutoCstPisCofinsConst {
  constructor(_codigo: string, _descricao: string, _tipoCst: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
    this.tipoCst = _tipoCst;
  }
  codigo: string;
  tipoCst: string;
  descricao: string;
}

export const ProdutoCstsPisCofins = [
  new ProdutoCstPisCofinsConst(
    '01',
    'OPERACAO TRIBUTAVEL COM ALIQUOTA BASICA',
    'S'
  ),
  new ProdutoCstPisCofinsConst(
    '02',
    'OPERACAO TRIBUTAVEL COM ALIQUOTA DIFERENCIADA',
    'S'
  ),
  new ProdutoCstPisCofinsConst(
    '03',
    'OPERACAO TRIBUTAVEL COM ALIQUOTA POR UNIDADE DE MEDIDA DE PRODUTO',
    'S'
  ),
  new ProdutoCstPisCofinsConst(
    '04',
    'OPERACAO TRIBUTAVEL MONOFASICA - REVENDA A ALIQUOTA ZERO',
    'S'
  ),
  new ProdutoCstPisCofinsConst(
    '05',
    'OPERACAO TRIBUTAVEL POR SUBSTITUICAO TRIBUTARIA',
    'S'
  ),
  new ProdutoCstPisCofinsConst(
    '06',
    'OPERACAO TRIBUTAVEL A ALIQUOTA ZERO',
    'S'
  ),
  new ProdutoCstPisCofinsConst('07', 'OPERACAO ISENTA DA CONTRIBUICAO', 'S'),
  new ProdutoCstPisCofinsConst(
    '08',
    'OPERACAO SEM INCIDENCIA DA CONTRIBUICAO',
    'S'
  ),
  new ProdutoCstPisCofinsConst(
    '09',
    'OPERACAO COM SUSPENSAO DA CONTRIBUICAO',
    'S'
  ),
  new ProdutoCstPisCofinsConst('49', 'OUTRAS OPERACOES DE SAIDA', 'S'),
  new ProdutoCstPisCofinsConst(
    '50',
    'OPERACAO COM DIREITO A CREDITO - VINCULADA EXCLUSIVAMENTE A RECEITA TRIBUTADA NO MERCADO INTERNO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '51',
    'OPERACAO COM DIREITO A CREDITO – VINCULADA EXCLUSIVAMENTE A RECEITA NAO TRIBUTADA NO MERCADO INTERNO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '52',
    'OPERACAO COM DIREITO A CREDITO - VINCULADA EXCLUSIVAMENTE A RECEITA DE EXPORTACAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '53',
    'OPERACAO COM DIREITO A CREDITO - VINCULADA A RECEITAS TRIBUTADAS E NAO-TRIBUTADAS NO MERCADO INTERNO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '54',
    'OPERACAO COM DIREITO A CREDITO - VINCULADA A RECEITAS TRIBUTADAS NO MERCADO INTERNO E DE EXPORTACAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '55',
    'OPERACAO COM DIREITO A CREDITO - VINCULADA A RECEITAS NAO-TRIBUTADAS NO MERCADO INTERNO E DE EXPORTACAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '56',
    'OPERACAO COM DIREITO A CREDITO - VINCULADA A RECEITAS TRIBUTADAS E NAO-TRIBUTADAS NO MERCADO INTERNO, E DE EXPORTACAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '60',
    'CREDITO PRESUMIDO - OPERACAO DE AQUISICAO VINCULADA EXCLUSIVAMENTE A RECEITA TRIBUTADA NO MERCADO INTERNO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '61',
    'CREDITO PRESUMIDO - OPERACAO DE AQUISICAO VINCULADA EXCLUSIVAMENTE A RECEITA NAO-TRIBUTADA NO MERCADO INTERNO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '62',
    'CREDITO PRESUMIDO - OPERACAO DE AQUISICAO VINCULADA EXCLUSIVAMENTE A RECEITA DE EXPORTACAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '63',
    'CREDITO PRESUMIDO - OPERACAO DE AQUISICAO VINCULADA A RECEITAS TRIBUTADAS E NAO-TRIBUTADAS NO MERCADO INTERNO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '64',
    'CREDITO PRESUMIDO - OPERACAO DE AQUISICAO VINCULADA A RECEITAS TRIBUTADAS NO MERCADO INTERNO E DE EXPORTACAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '65',
    'CREDITO PRESUMIDO - OPERACAO DE AQUISICAO VINCULADA A RECEITAS NAO-TRIBUTADAS NO MERCADO INTERNO E DE EXPORTACAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '66',
    'CREDITO PRESUMIDO - OPERACAO DE AQUISICAO VINCULADA A RECEITAS TRIBUTADAS E NAO-TRIBUTADAS NO MERCADO INTERNO, E DE EXPORTACAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '67',
    'CREDITO PRESUMIDO - OUTRAS OPERACOES',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '70',
    'OPERACAO DE AQUISICAO SEM DIREITO A CREDITO',
    'E'
  ),
  new ProdutoCstPisCofinsConst('71', 'OPERACAO DE AQUISICAO COM ISENCAO', 'E'),
  new ProdutoCstPisCofinsConst(
    '72',
    'OPERACAO DE AQUISICAO COM SUSPENSAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '73',
    'OPERACAO DE AQUISICAO A ALIQUOTA ZERO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '74',
    'OPERACAO DE AQUISICAO SEM INCIDENCIA DA CONTRIBUICAO',
    'E'
  ),
  new ProdutoCstPisCofinsConst(
    '75',
    'OPERACAO DE AQUISICAO POR SUBSTITUICAO TRIBUTARIA',
    'E'
  ),
  new ProdutoCstPisCofinsConst('98', 'OUTRAS OPERACOES DE ENTRADA', 'E'),
  new ProdutoCstPisCofinsConst('99', 'OUTRAS OPERACOES', 'E'),
];
