<ng-template #documentoTemp let-value="value">
  <span>{{ value | documento }}</span>
</ng-template>
<ng-template #nfeFinalidadeTemp let-value="value">
  <span>{{ value | enumLabel : nfeFinalidadeLabels }}</span>
</ng-template>
<ng-template #dataHoraTemp let-value="value">
  <span>{{ value | date : dataHoraFormatoPt }}</span>
</ng-template>
<ng-template #valorDecimalTemp let-value="value">
  <span>{{ value | customDecimal : valorDecimais }}</span>
</ng-template>
<ng-template #statusNotaFiscalTemp let-rowData="rowData">
  <p-tag
    styleClass="mr-2"
    [severity]="buscarStyleTagStatus(rowData.situacao)"
    [value]="rowData.situacao | enumLabel : nfeEntradaSituacaoLabels"
    [rounded]="true"
  ></p-tag>
</ng-template>

<p-dialog
  header="Lista de notas fiscais de entrada"
  [style]="{ width: '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
    (scrolled)="buscarNotasFiscaisEntradaProximaPagina()"
  >
    <div class="formgrid grid mt-1">
      <div class="field col-12 md:col-4">
        <app-text-input-ngmodel
          iconButton="pi pi-search"
          placeholder="Buscar por Emitente"
          (modelChange)="filtrarComTimeout($event)"
          [(model)]="filtro.emitente"
        ></app-text-input-ngmodel>
      </div>
    </div>
    <app-table
      (aoSelecionar)="setarItemSelecionado($event)"
      [columns]="colunas"
      [rows]="dados"
      [selectable]="selectable"
      [selectionMode]="modoSelecao"
      [checkboxable]="checkboxable"
      [selecteds]="itensSelecionados"
    >
    </app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      label="Cancelar"
      [text]="true"
      (onClick)="fecharModal()"
    ></p-button>
    <p-button
      label="Selecionar"
      styleClass="btn-five"
      [disabled]="!itensSelecionados && !itensSelecionados?.length"
      (onClick)="selecionar()"
    ></p-button>
  </ng-template>
</p-dialog>
<ng-template #situacaoTemp let-value="value">
  <p-tag
    *ngIf="value"
    styleClass="mr-2"
    severity="success"
    value="Ativo"
  ></p-tag>
  <p-tag
    *ngIf="!value"
    styleClass="mr-2"
    severity="danger"
    value="Inativo"
  ></p-tag>
</ng-template>
