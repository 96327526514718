<p-dialog
  header="Nova natureza da receita"
  [style]="{ width: isDesktop ? '60%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="naturezaReceitaForm.form"
  (onHide)="fecharModal()"
  (onShow)="this.aoAbrirModal()"
>
  <p-tabView [scrollable]="true" [formGroup]="naturezaReceitaForm.form">
    <p-tabPanel header="Dados da natureza da receita" [selected]="true">
      <div class="formgrid grid">
        <!-- #region DESCRIÇÃO -->
        <div class="field col-12 xl:col-5 lg:col-5 md:col-4 sm:col-12">
          <app-custom-label texto="Descrição" [obrigatorio]="true">
          </app-custom-label>
          <app-text-input
            [autoFocus]="naturezaReceitaForm.operacaoFormAdicionar"
            [control]="naturezaReceitaForm.form.controls?.descricao"
            [maxlength]="60"
          ></app-text-input>
        </div>
        <!-- #endregion -->
        <!-- #region CÓDIGO -->
        <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-12">
          <app-custom-label texto="Código" [obrigatorio]="true">
          </app-custom-label>
          <app-numeric-input
            [control]="naturezaReceitaForm.form.controls?.naturezaReceitaCodigo"
            [options]="inteiroMask"
            [maxlength]="9"
          >
          </app-numeric-input>
        </div>
        <!-- #endregion -->
        <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-12">
          <app-custom-label texto="CST PIS/COFINS Saída" [obrigatorio]="true">
          </app-custom-label>
          <!-- #region CST PIS/COFINS SAIDA -->
          <app-dropdown-input
            [options]="produtoCstsPisCofins"
            [control]="naturezaReceitaForm.form.controls?.cstPisCofinsSaida"
            [showFilter]="true"
          >
          </app-dropdown-input>
          <!-- #endregion -->
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
