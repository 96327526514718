<p-tabView
  *ngIf="fornecedorForm && fornecedorForm?.form"
  [scrollable]="true"
  data-cy="tabview-dados-fornecedor"
>
  <p-tabPanel header="Dados do fornecedor" [selected]="true">
    <div class="formgrid grid">
      <!-- #region TIPO DE PESSOA -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Tipo de pessoa" [obrigatorio]="true">
        </app-custom-label>
        <app-dropdown-input
          [options]="pessoaTipos"
          [control]="fornecedorForm.form.controls.pessoaTipo"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region CPF/CNPJ -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label
          [texto]="fornecedorForm.labelCpfCnpj"
          [obrigatorio]="documentoObrigatorio"
        >
        </app-custom-label>
        <app-text-input
          [maxlength]="maxlength"
          [maxlengthCount]="maxlengthCount"
          [lengthOnlyNumber]="true"
          [showButton]="true"
          textAlign="right"
          iconButton="pi pi-search"
          [control]="fornecedorForm.form.controls?.documento"
          [autoFocus]="fornecedorForm.operacaoFormAdicionar"
          [mask]="mascaraDocumento"
          (keypress)="consultarDocumentoValidoComEnter($event)"
          (buttonOnClick)="consultarDocumentoValido()"
          (inputBlur)="consultarDocumentoValido()"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region NOME -->
      <div
        class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12"
        *ngIf="!pessoaJuridica"
      >
        <app-custom-label
          [texto]="fornecedorForm.labelNomeRazaoSocial"
          [obrigatorio]="true"
        >
        </app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.nomeRazaoSocial"
          [maxlength]="60"
        ></app-text-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid" *ngIf="pessoaJuridica">
      <!-- #region RAZÃO SOCIAL -->
      <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
        <app-custom-label
          [texto]="fornecedorForm.labelNomeRazaoSocial"
          [obrigatorio]="true"
        >
        </app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.nomeRazaoSocial"
          (inputBlur)="preencherNomeFantasia($event)"
          [maxlength]="60"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region NOME FANTASIA -->
      <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
        <app-custom-label texto="Nome fantasia" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.nomeFantasia"
          [maxlength]="60"
        ></app-text-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid">
      <!-- #region REGIME TRIBUTÁRIO -->
      <div
        class="field col-12 xl:col-3 lg:col-4 md:col-4 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="Regime tributário"></app-custom-label>
        <app-dropdown-input
          [options]="regimesTributarios"
          [control]="fornecedorForm.form.controls.regimeTributario"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region TIPO DE ATIVIDADE -->
      <div
        class="field col-12 xl:col-3 lg:col-4 md:col-4 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="Tipo de atividade"></app-custom-label>
        <app-dropdown-input
          [options]="atividadeTipos"
          [control]="fornecedorForm.form.controls.atividadeTipo"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region IE INDICADOR -->
      <div
        class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="IE indicador"></app-custom-label>
        <app-dropdown-input
          [options]="ieIndicadores"
          [control]="fornecedorForm.form.controls.ieIndicador"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region RG/IE -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label [texto]="fornecedorForm.labelRgIe">
        </app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.rgIe"
          [maxlength]="20"
          textAlign="right"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region PLANO CONTA -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label
          texto="Plano de contas padrão"
          [cadastroRapido]="true"
          (aoClicarAdicionar)="abrirPlanoContasCadastroRapidoModal()"
        ></app-custom-label>
        <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
          [control]="fornecedorForm.form.controls?.planoConta3Id"
          [options]="planosContas3"
          [showFilter]="true"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region VENDEDOR PADRÃO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Vendedor Padrão"></app-custom-label>
        <app-dropdown-input
          optionLabel="nomeFantasia"
          optionValue="id"
          [control]="fornecedorForm.form.controls?.vendedorPadrao"
          [options]="funcionarios"
          [showFilter]="true"
          [showClear]="true"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region EMAIL GERAL -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Email Geral"></app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.emailGeral"
          [maxlength]="60"
          [upperCase]="false"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region EMAIL FATURAMENTO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Email de Faturamento"></app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.emailFaturamento"
          [maxlength]="60"
          [upperCase]="false"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region WHATSAPP -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6">
        <app-custom-label texto="WhatsApp"></app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.whatsApp"
          [foneMask]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region TELEFONE FIXO -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6">
        <app-custom-label texto="Telefone"></app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.telefoneFixo"
          [foneMask]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region ATIVO -->
      <div class="col-6 xl:col-2 md:col-3 mt-0 sm:mt-5">
        <app-checkbox-input
          label="Ativo"
          controlName="ativo"
          [control]="fornecedorForm?.form.controls.ativo"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid">
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        [ngClass]="{
          'xl:col-offset-6 lg:col-offset-6': contemUsuarioCriadoPor,
          'xl:col-offset-8 lg:col-offset-8': !contemUsuarioCriadoPor
        }"
        *ngIf="mostrarDataCriacao"
      >
        <app-custom-label texto="Data de criação"></app-custom-label>
        <app-calendar-input
          [control]="fornecedorForm.form.controls?.createdOn"
          [readonly]="true"
        ></app-calendar-input>
      </div>
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        *ngIf="mostrarDataAlteracao"
      >
        <app-custom-label texto="Data de alteração"></app-custom-label>
        <app-calendar-input
          [control]="fornecedorForm.form.controls?.lastModifiedOn"
          [readonly]="true"
        ></app-calendar-input>
      </div>
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        *ngIf="mostrarCriadoPor"
      >
        <app-custom-label texto="Criado por"></app-custom-label>
        <app-text-input
          [control]="fornecedorForm.form.controls?.nomeCreatedBy"
          [readonly]="true"
        >
        </app-text-input>
      </div>
    </div>
    <p-tabView
      [scrollable]="true"
      data-cy="tabview-subdados-fornecedor"
      (onChange)="recuperaDadosTab($event)"
    >
      <p-tabPanel header="Endereços">
        <div class="grid">
          <div class="col">
            <p-button
              type="button"
              styleClass="btn-five"
              label="Novo endereço"
              tooltipPosition="bottom"
              pTooltip="Novo endereço"
              icon="pi pi-plus"
              (onClick)="abrirEnderecoModal()"
            ></p-button>
          </div>
        </div>
        <div class="grid">
          <div *ngIf="enderecos.length <= 0" class="col-12 text-center">
            <span>Nenhum endereço cadastrado</span>
          </div>
          <div
            class="col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
            *ngFor="let endereco of enderecos; let i = index"
          >
            <app-cartao-endereco
              [endereco]="endereco"
              (aoCliquarEmEditar)="abrirEnderecoModal(endereco, i)"
            >
            </app-cartao-endereco>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Contatos">
        <div class="grid">
          <div class="col">
            <p-button
              type="button"
              styleClass="btn-five"
              label="Novo contato"
              tooltipPosition="bottom"
              pTooltip="Novo contato"
              icon="pi pi-plus"
              (onClick)="abrirContatoModal()"
            ></p-button>
          </div>
        </div>
        <div class="grid">
          <div *ngIf="contatos.length <= 0" class="col-12 text-center">
            <span>Nenhum contato cadastrado</span>
          </div>
          <div
            class="col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
            *ngFor="let contato of contatos; let i = index"
          >
            <app-cartao-contato
              [contato]="contato"
              (aoCliquarEmEditar)="abrirContatoModal(contato, i)"
            >
            </app-cartao-contato>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Produtos">
        <div class="grid">
          <div class="col">
            <p-button
              type="button"
              styleClass="btn-five"
              label="Vincular novo produto"
              tooltipPosition="bottom"
              pTooltip="Vincular novo produto"
              icon="pi pi-plus"
              (onClick)="abrirFornecedorProdutoModal()"
            ></p-button>
          </div>
        </div>
        <div class="borda-tabela">
          <app-table [columns]="colunasProdutos" [rows]="produtos">
            <ng-template
              #acoesTemp
              pTemplate="body"
              let-rowData="rowData"
              let-rowIndex="rowIndex"
            >
              <p-button
                tooltipPosition="bottom"
                pTooltip="Editar"
                icon="pi pi-pencil"
                [text]="true"
                [plain]="true"
                (onClick)="abrirFornecedorProdutoModal(rowData, rowIndex)"
              ></p-button>
              <p-button
                tooltipPosition="bottom"
                pTooltip="Excluir"
                icon="pi pi-trash"
                [text]="true"
                [plain]="true"
                (onClick)="abrirConfirmacaoExcluisaoFornecedorProduto(rowIndex)"
              ></p-button>
            </ng-template>
          </app-table>
        </div>
      </p-tabPanel>
      <p-tabPanel
        [header]="tituloTabPanelRegistrosFinanceiros"
        *ngIf="fornecedorForm.operacaoFormEditar"
      >
        <div class="formgrid grid">
          <div class="field col-12 xl:col-12 lg:col-12 md:col-12">
            <div class="borda-tabela">
              <app-table
                [skeletonRowsCount]="4"
                [columns]="colunasTabelaLancamentoParcela"
                [rows]="fornecedorForm.lancamentosParcela"
                [paginator]="true"
                dataKey="id"
              ></app-table>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Dados adicionais">
        <div class="formgrid grid">
          <!-- #region VALOR MIN. COMPRA -->
          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label texto="Valor mín. de compra (R$)">
            </app-custom-label>
            <app-numeric-input
              [control]="fornecedorForm.form.controls.compraValorMinimo"
              [placeholder]="valorPlaceholder"
              [options]="valorMask"
            >
            </app-numeric-input>
          </div>
          <!-- #endregion -->
          <!-- #region AUTORIZAR COMPRA -->
          <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-12 mt-0 lg:mt-5">
            <app-checkbox-input
              label="Autorizar compra"
              controlName="compraAutorizar"
              [control]="fornecedorForm.form.controls.compraAutorizar"
            >
            </app-checkbox-input>
          </div>
          <!-- #endregion -->
          <div class="field col-12 xl:col-12 lg:col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12">
                <app-custom-label texto="Observações"> </app-custom-label>
                <app-textarea-input
                  [rows]="10"
                  [control]="fornecedorForm.form.controls.observacao"
                  [maxlength]="255"
                ></app-textarea-input>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </p-tabPanel>

  <ng-template #despesaTemp let-value="value">
    <span *ngIf="value">DESPESA</span>
    <span *ngIf="!value">RECEITA</span>
  </ng-template>
  <ng-template #dataTemp let-value="value">
    <span>{{ value | date : dataFormatoPt }}</span>
  </ng-template>
  <ng-template #valorTemp let-value="value">
    <span>R${{ value | customDecimal : valorDecimais }}</span>
  </ng-template>
  <ng-template #situacaoLancamentoParcelaTemp let-value="value">
    <span>{{ value | enumLabel : situacaoLancamentoLabels }}</span>
  </ng-template>
  <ng-template
    #lancamentosParcelaAcoesTemp
    let-rowData="rowData"
    let-rowIndex="rowIndex"
  >
    <p-button
      tooltipPosition="bottom"
      pTooltip="Visualizar Parcela"
      icon="pi pi-pencil"
      [text]="true"
      [plain]="true"
      (onClick)="verLancamentoParcela(rowData)"
    ></p-button>
  </ng-template>

  <app-contato-modal
    [form]="fornecedorForm.contatoForm"
    [mostrarModal]="fornecedorForm.mostrarContatoModal"
    [titulo]="fornecedorForm.contatoModalTitulo"
    (aoCancelar)="fecharContatoModal()"
    (aoSalvar)="salvarContato($event)"
  ></app-contato-modal>

  <app-endereco-modal
    [form]="fornecedorForm.enderecoForm"
    [mostrarModal]="fornecedorForm.mostrarEnderecoModal"
    [mostrarItemTodosEnderecoTipos]="operacaoFormEnderecoAdicionar"
    [titulo]="fornecedorForm.enderecoModalTitulo"
    (aoCancelar)="fecharEnderecoModal()"
    (aoSalvar)="salvarEndereco($event)"
  ></app-endereco-modal>

  <app-produto-busca-rapida
    *ngIf="fornecedorForm.mostrarProdutoBuscaRapidaModal"
    [mostrarModal]="fornecedorForm.mostrarProdutoBuscaRapidaModal"
    [mostrarBotaoCadastro]="true"
    (aoCancelar)="fecharProdutoBuscaRapidaModal()"
    (aoSelecionar)="setarProdutoSelecionado($event)"
  ></app-produto-busca-rapida>

  <p-dialog
    header="Vincular produto"
    [style]="{ width: isDesktop ? '50%' : '90%' }"
    [(visible)]="fornecedorForm.mostrarProdutoVinculoModal"
    [modal]="true"
    [baseZIndex]="1"
    [draggable]="false"
    [resizable]="true"
    (onHide)="fecharFornecedorProdutoModal()"
    contentStyleClass="overlay-y-hidden"
  >
    <div
      class="formgrid grid"
      [formGroup]="fornecedorForm.fornecedorProdutoForm"
      *ngIf="fornecedorForm.fornecedorProdutoForm"
    >
      <!-- #region CODIGO VARIAÇÃO PRODUTO -->
      <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
        <app-custom-label texto="Produto" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          iconButton="pi pi-search"
          [control]="
            fornecedorForm.fornecedorProdutoForm.controls?.produtoDescricao
          "
          [readonly]="true"
          [showButton]="true"
          (buttonOnClick)="abrirProdutoBuscaRapidaModal()"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region REFERENCIA DO FORNECEDOR -->
      <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
        <app-custom-label texto="Referência do fornecedor"></app-custom-label>
        <app-text-input
          [control]="
            fornecedorForm.fornecedorProdutoForm.controls?.codigoReferencia
          "
          [maxlength]="30"
        ></app-text-input>
      </div>
      <!-- #endregion -->
    </div>
    <ng-template pTemplate="footer">
      <p-button
        (onClick)="fecharFornecedorProdutoModal()"
        label="Cancelar"
        [text]="true"
      ></p-button>
      <p-button
        (onClick)="salvarFornecedorProduto()"
        label="Salvar"
        styleClass="btn-five"
      ></p-button>
    </ng-template>
  </p-dialog>

  <p-confirmDialog
    rejectButtonStyleClass="p-button-text"
    key="confirmacao-fornecedor-form"
    [style]="{ width: '45%' }"
  ></p-confirmDialog>

  <app-plano-contas-cadastro-rapido-modal
    [mostrarModal]="fornecedorForm.mostrarPlanoContasModal"
    (aoCancelar)="fecharPlanoContasCadastroRapidoModal()"
    (aoSalvar)="setarPlanoContasCadastrado($event)"
  ></app-plano-contas-cadastro-rapido-modal>
</p-tabView>
