import {Injectable} from '@angular/core';
import {ClienteSituacaoListagem} from '@app/abstraction/domain/interfaces/cadastros/cliente-situacao/cliente-situacao-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ClienteSituacaoStore {
  private readonly _clienteSituacoes =
      new BehaviorSubject<ClienteSituacaoListagem[]>(null);
  get clienteSituacoes(): ClienteSituacaoListagem[] {
    return this._clienteSituacoes.getValue();
  }
  set clienteSituacoes(clienteSituacoes: ClienteSituacaoListagem[]) {
    this._clienteSituacoes.next(clienteSituacoes);
  }
  get clienteSituacoes$() {
    return this._clienteSituacoes.asObservable();
  }
  adicionar(clienteSituacao: ClienteSituacaoListagem) {
    if (!this.clienteSituacoes) {
      this.clienteSituacoes = [];
    }
    this.clienteSituacoes.push(clienteSituacao);
  }
  adicionarRange(clienteSituacoes: ClienteSituacaoListagem[]) {
    if (!this.clienteSituacoes) {
      this.clienteSituacoes = [];
    }
    clienteSituacoes.forEach((cliente) => {
      if (!this.clienteSituacoes.some((c) => c.id === cliente.id)) {
        this.clienteSituacoes.push(cliente);
      }
    });
  }
}
