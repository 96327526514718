<div class="p-input-icon-right mt-4 w-full">
  <i class="pi pi-search"></i>
  <app-text-input-ngmodel
    placeholder="Buscar"
    [upperCase]="false"
    [(model)]="filtro"
    (modelChange)="filtrarItems()"
  ></app-text-input-ngmodel>
</div>

<ul class="layout-menu">
  <ng-container *ngFor="let item of menuFiltrado; let i = index">
    <li
      app-menuitem
      *ngIf="!item.separator"
      [item]="item"
      [index]="i"
      [root]="true"
    ></li>
    <li *ngIf="item.separator" class="menu-separator"></li>
  </ng-container>

  <li class="mt-4 text-center">
    <span class="font-bold">
      {{ empresaLogada.nomeFantasia }}
    </span>
    <br />
    <small class="text-primary font-bold">
      {{ empresaLogada.documento | documento }}
    </small>
  </li>
  <li class="text-center">
    <small class="text-gray-500 font-semibold">{{ versao?.versaoCompleta }}</small>
  </li>
  <li class="text-center">
    <small class="text-gray-500 font-semibold">{{ versao?.dataLancamento | date }}</small>
  </li>

  <li class="mt-4 text-center">
    <p-tag *ngIf="homologacao" value="Homologação"></p-tag>
  </li>
</ul>
