export class NotaFiscalVeiculo {
  placa: string;
  uf: string;
  rntc: string;

  static from(json: any = {}): NotaFiscalVeiculo {
    const veiculo = new NotaFiscalVeiculo();
    veiculo.placa = json.placa;
    veiculo.uf = json.uf;
    veiculo.rntc = json.rntc;
    return veiculo;
  }
}