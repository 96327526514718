import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProdutoVariacaoTamanho} from '@app/abstraction/domain/entities/produtos/produto-variacao-tamanho/produto-variacao-tamanho.entity';
import {ProdutoVariacaoTamanhoFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-tamanho/produto-variacao-tamanho.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ProdutoVariacaoTamanhoForm} from '@app/abstraction/domain/forms/produtos/produto/produto-variacao-tamanho.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-produto-variacao-tamanho-cadastro-rapido-modal',
  templateUrl:
      './produto-variacao-tamanho-cadastro-rapido-modal.component.html',
})
export class ProdutoVariacaoTamanhoCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<ProdutoVariacaoTamanho>();
  @Output() aoCancelar = new EventEmitter();

  tamanhoForm: ProdutoVariacaoTamanhoForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private produtoVariacaoTamanhoFacade: ProdutoVariacaoTamanhoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarProdutoVariacaoTamanho(produtoVariacaoTamanho:
                                                    ProdutoVariacaoTamanho) {
    return this.produtoVariacaoTamanhoFacade.adicionar(produtoVariacaoTamanho)
        .catch((e: any) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private criarForm() {
    this.tamanhoForm = new ProdutoVariacaoTamanhoForm(this.errorMessagesFacade);
    this.tamanhoForm.criarForm();
    this.tamanhoForm.setarTipoFormulario();
  }
  private mostrarSucessoAoSalvar(produtoVariacaoTamanho:
                                     ProdutoVariacaoTamanho) {
    this.toastrService.success(`Tamanho do produto cadastrado com sucesso`);
    this.aoSalvar.emit(produtoVariacaoTamanho);
    this.fecharModal();
    this.saveLoading = false;
  }
  private preencherObjeto() {
    return ProdutoVariacaoTamanho.from(this.tamanhoForm.form.value);
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.tamanhoForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const tamanho = this.preencherObjeto();
      const resultado = await this.adicionarProdutoVariacaoTamanho(tamanho)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar tamanho do produto.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  fecharModal() {
    this.tamanhoForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
  }
}
