
export class Endereco {
  ativo: boolean;
  bairro: string;
  cep: string;
  cidade: string;
  cidadeIbge: number;
  complemento: string;
  descricao: string;
  id: string;
  principal: boolean;
  enderecoTipoId: string;
  ie: string;
  ieIndicador: number;
  logradouro: string;
  numero: string;
  pais: string;
  paisIbge: number;
  pessoaId: string;
  uf: string;
  ufIbge: number;

  static from(json: any = {}) {
    const endereco = new Endereco();
    endereco.ativo = json.ativo;
    endereco.bairro = json.bairro?.trim();
    endereco.cep = json.cep?.somenteNumeros()?.trim();
    endereco.cidade = json.cidade?.trim();
    endereco.cidadeIbge = json.cidadeIbge;
    endereco.complemento = json.complemento?.trim();
    endereco.descricao = json.descricao?.trim();
    endereco.id = json.id;
    endereco.principal = json.principal;
    endereco.enderecoTipoId = json.enderecoTipoId;
    endereco.ie = json.ie?.trim();
    endereco.ieIndicador = json.ieIndicador;
    endereco.logradouro = json.logradouro?.trim();
    endereco.numero = json.numero?.trim();
    endereco.pais = json.pais?.trim();
    endereco.paisIbge = json.paisIbge;
    endereco.pessoaId = json.pessoaId;
    endereco.uf = json.uf?.trim();
    endereco.ufIbge = json.ufIbge;
    return endereco;
  }
}
