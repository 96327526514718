import {FormArray, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {Pessoa} from '@app/abstraction/domain/entities/cadastros/pessoa/pessoa.entity';
import {Transportadora} from '@app/abstraction/domain/entities/cadastros/transportadora/transportadora.entity';
import {LancamentoParcela} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import {Contato} from '@app/abstraction/domain/entities/shared/contato.entity';
import {Endereco} from '@app/abstraction/domain/entities/shared/endereco.entity';
import {TransportadoraFacade} from '@app/abstraction/domain/facades/cadastros/transportadora/transportadora.facade';
import {UsuarioFacade} from '@app/abstraction/domain/facades/cadastros/usuario/usuario.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {ApiServicesFacade} from '@app/abstraction/domain/facades/shared/api-services/api-services.facade';
import {CepFacade} from '@app/abstraction/domain/facades/shared/cep/cep.facade';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {ContatoForm, IContatoForm,} from '@app/abstraction/domain/forms/shared/contato.form';
import {EnderecoForm, IEnderecoForm,} from '@app/abstraction/domain/forms/shared/endereco.form';
import {Estados} from '@app/shared/constants/estados.constant';
import {AtividadeTipo} from '@app/shared/enums/atividade-tipo.enum';
import {IeIndicador} from '@app/shared/enums/ie-indicador.enum';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {PessoaTipo} from '@app/shared/enums/pessoa-tipo.enum';
import {RegimeTributario} from '@app/shared/enums/regimes-tributarios.enum';
import {DadosCnpj} from '@app/shared/models/cnpj-hub.entity';
import {DadosCpf} from '@app/shared/models/cpf-hub.entity';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

export interface ITransportadoraForm {
  atividadeTipo: FormControl<AtividadeTipo>;
  documento: FormControl<string>;
  ieIndicador: FormControl<IeIndicador>;
  nascimento: FormControl<Date|string>;
  nomeFantasia: FormControl<string>;
  nomeRazaoSocial: FormControl<string>;
  pessoaTipo: FormControl<PessoaTipo>;
  regimeTributario: FormControl<RegimeTributario>;
  rgIe: FormControl<string>;
  ativo: FormControl<boolean>;
  id: FormControl<string>;
  contatos: FormArray<FormGroup<IContatoForm>>;
  enderecos: FormArray<FormGroup<IEnderecoForm>>;
  agencia: FormControl<string>;
  banco: FormControl<string>;
  condicaoPagamento: FormControl<string>;
  contaCorrente: FormControl<string>;
  transportadoraId: FormControl<string>;
  observacao: FormControl<string>;
  createdOn: FormControl<Date>;
  lastModifiedOn: FormControl<Date>;
  createdBy: FormControl<string>;
  nomeCreatedBy: FormControl<string>;
  planoConta3Id: FormControl<string>;
  whatsApp: FormControl<string>;
  telefoneFixo: FormControl<string>;
  vendedorPadrao: FormControl<string>;
  emailGeral: FormControl<string>;
  emailFaturamento: FormControl<string>;
}

export class TransportadoraForm extends BaseForm {
  labelCpfCnpj: string = 'CNPJ';
  labelRgIe: string = 'Inscrição estadual';
  labelNomeRazaoSocial: string = 'Razão social';
  labelNascimento: string = 'Data de abertura';
  form: FormGroup<ITransportadoraForm>;
  contatoForm: FormGroup<IContatoForm>;
  enderecoForm: FormGroup<IEnderecoForm>;
  operacaoForm: OperacaoForm;
  contatoEditado: any;
  contatoModalTitulo: string;
  enderecoEditado: any;
  enderecoModalTitulo: string;
  mostrarContatoModal: boolean;
  mostrarEnderecoModal: boolean;
  mostrarPlanoContasModal: boolean;
  operacaoFormContato: OperacaoForm;
  operacaoFormEndereco: OperacaoForm;
  pessoaTipoSelecionada: PessoaTipo;
  pessoaId: string;
  transportadoraCpfCnpj: string;
  cpfReadonly: boolean;
  rgReadonly: boolean;
  nascimentoReadonly: boolean;
  pessoaTipo: number;
  lancamentosParcela: LancamentoParcela[];

  get pessoaJuridica() {
    return this.pessoaTipoSelecionada === PessoaTipo.Juridica;
  }
  get contatosFormArray() {
    return this.form.controls.contatos as FormArray<FormGroup<IContatoForm>>;
  }
  get enderecosFormArray() {
    return this.form.controls.enderecos as FormArray<FormGroup<IEnderecoForm>>;
  }
  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova transportadora' :
                                        'Editar transportadora';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  get operacaoFormEditar() {
    return this.operacaoForm === OperacaoForm.Editar;
  }
  get operacaoFormEnderecoAdicionar() {
    return this.operacaoFormEndereco === OperacaoForm.Adicionar;
  }
  get contatos() {
    return this.form.controls.contatos.value as Contato[];
  }
  get enderecos() {
    return this.form.controls.enderecos.value as Endereco[];
  }
  get mostrarDataCriacao() {
    return this.form.controls?.createdOn?.value;
  }
  get mostrarDataAlteracao() {
    return this.form.controls?.lastModifiedOn?.value;
  }
  get mostrarCriadoPor() {
    return this.form.controls?.createdBy?.value;
  }
  get maxlength() {
    return this.pessoaJuridica ? 18 : 14;
  }
  get maxlengthCount() {
    return this.pessoaJuridica ? 14 : 11;
  }
  get isDirty() {
    return this.form.dirty;
  }
  constructor(
      private apiServicesCoreFacade: ApiServicesFacade,
      private enderecoTipoFacade: EnderecoTipoFacade,
      private errorMessagesFacade: ErrorMessageFacade,
      private pessoaFacade: PessoaFacade,
      private parametroFacade: ParametroFacade,
      private transportadoraFacade: TransportadoraFacade,
      private usuarioFacade: UsuarioFacade,
      private toastrService: ToastrService, private cepFacade: CepFacade) {
    super();
  }
  private async buscarDadosPorCnpj(documento: string) {
    const nomeRazaoSocial = this.form.controls.nomeRazaoSocial;
    const fantasia = this.form.controls.nomeFantasia;
    const nascimento = this.form.controls.nascimento;

    const dadosCnpj = DadosCnpj.criarDadosCnpj(
        await this.apiServicesCoreFacade.buscarCnpj(documento));

    if (dadosCnpj?.status) {
      dadosCnpj.result.nome =
          dadosCnpj.result.nome?.substring(0, 60).toUpperCase();
      dadosCnpj.result.fantasia =
          dadosCnpj.result.fantasia?.substring(0, 60).toUpperCase();

      nomeRazaoSocial.setValue(dadosCnpj.result.nome);
      fantasia.setValue(
          dadosCnpj.result.fantasia ? dadosCnpj.result.fantasia?.toUpperCase() :
                                      dadosCnpj.result.nome?.toUpperCase());
      nascimento.setValue(dadosCnpj.retornarDataAbertura());

      this.contatosFormArray.clear();

      let telefone = dadosCnpj.result.telefone?.somenteNumeros();

      if (telefone && telefone.length <= 11) {
        this.adicionarContatoFormGroup(<Contato>{
          email: dadosCnpj.result.email.toLowerCase(),
          ativo: true,
          principal: this.contatosFormArray.length <= 0,
          fone1: telefone,
          nome: dadosCnpj.result.nome.split(' ')[0],
        });
      }
      const endereco = await this.cepFacade.consultarCep(
          dadosCnpj.result.cep.somenteNumeros());

      this.enderecosFormArray.clear();
      this.enderecoTipoFacade.enderecoTipos.forEach((enderecoTipo) => {
        const ufIbge =
            Estados.find((e) => e.ufSigla === dadosCnpj.result.uf).ufIbge;

        this.adicionarEnderecoFormGroup(<any>{
          enderecoTipoId: enderecoTipo.enderecoTipoId,
          cep: dadosCnpj.result.cep?.somenteNumeros(),
          logradouro: dadosCnpj.result.logradouro?.toUpperCase(),
          numero: dadosCnpj.result.numero,
          bairro: dadosCnpj.result.bairro?.toUpperCase(),
          cidade: dadosCnpj.result.municipio?.toUpperCase(),
          cidadeIbge: Number(endereco.ibge),
          ufIbge: ufIbge,
          uf: dadosCnpj.result.uf,
          ativo: true,
          principal: this.enderecosFormArray.length <= 0,
        });
      });
    } else {
      this.toastrService.error('Informe um CNPJ válido');
    }
  }
  private async buscarDadosPorCpf(documento: string) {
    const nomeRazaoSocial = this.form.controls.nomeRazaoSocial;
    const nascimento = this.form.controls.nascimento;
    const dadosCpf = DadosCpf.criarDadosCpf(
        await this.apiServicesCoreFacade.buscarCpf(documento));
    if (dadosCpf?.status) {
      nomeRazaoSocial.setValue(dadosCpf.result.nome_da_pf?.toUpperCase());
      nascimento.setValue(dadosCpf.retornarDataNascimento());
      this.contatosFormArray.clear();
      this.enderecosFormArray.clear();
    } else {
      this.toastrService.error('Informe um CPF válido');
    }
  }
  private async preencherForm(transportadora: Transportadora) {
    if (transportadora) {
      this.transportadoraCpfCnpj = transportadora.documento;
      if (transportadora.enderecos) {
        this.enderecosFormArray.clear();
        transportadora.enderecos.orderByDescending((p) => p.principal);
        transportadora.enderecos.forEach((e) => {
          this.adicionarEnderecoFormGroup(e);
        });
      }
      if (transportadora.contatos) {
        this.contatosFormArray.clear();
        transportadora.contatos.orderByDescending((p) => p.principal);
        transportadora.contatos.forEach((c) => {
          this.adicionarContatoFormGroup(c);
        });
      }

      if (transportadora.nascimento) {
        transportadora.nascimento = moment(transportadora.nascimento).toDate();

        this.nascimentoReadonly = true;
      }

      if (transportadora.createdBy) {
        let usuario =
            await this.usuarioFacade.buscarPorChave(transportadora.createdBy);
        transportadora.nomeCreatedBy = usuario.nome;
      }

      this.lancamentosParcela = transportadora.lancamentosParcela;
      this.rgReadonly = transportadora.rgIe?.length > 0;
      this.cpfReadonly = transportadora.documento?.length > 0;
      if (transportadora.agencia == '') transportadora.agencia = null;
      if (transportadora.contaCorrente == '')
        transportadora.contaCorrente = null;

      this.form.patchValue(transportadora);
    }
  }
  private adicionarContatoFormGroup(contato?: Contato) {
    this.contatosFormArray.push(this.criarContatoFormGroup(contato));
  }
  private adicionarEnderecoFormGroup(endereco?: Endereco) {
    this.enderecosFormArray.push(this.criarEnderecoFormGroup(endereco));
  }
  private aplicarValidacoesPessoaFisica() {
    let documentoValidators = <ValidatorFn[]>[];

    if (this.parametroFacade.validarDocumento) {
      documentoValidators.push(CustomValidators.documentoTamanhoMinimo(
          'Tamanho do documento é inválido'));
      documentoValidators.push(
          CustomValidators.documentoValido('Documento inválido'));
    }
    if (this.parametroFacade.documentoObrigatorio) {
      documentoValidators.push(
          CustomValidators.required('Documento é obrigatório'));
    }

    super.adicionarValidator('documento', documentoValidators, this.form);
    super.removerValidator('nomeFantasia', this.form);
  }
  private aplicarValidacoesPessoaJuridica() {
    let documentoValidators = <ValidatorFn[]>[];
    let nomeFantasiaValidators = <ValidatorFn[]>[
      CustomValidators.required('Nome fantasia é obrigatório'),
    ];

    if (this.parametroFacade.validarDocumento) {
      documentoValidators.push(CustomValidators.documentoTamanhoMinimo(
          'Tamanho do documento é inválido'));
      documentoValidators.push(
          CustomValidators.documentoValido('Documento inválido'));
    }
    if (this.parametroFacade.documentoObrigatorio) {
      documentoValidators.push(
          CustomValidators.required('Documento é obrigatório'));
    }

    super.adicionarValidator('documento', documentoValidators, this.form);
    super.adicionarValidator('nomeFantasia', nomeFantasiaValidators, this.form);
  }
  private criarContatoFormGroup(contato?: Contato, index?: number) {
    if (index >= 0) {
      this.operacaoFormContato = OperacaoForm.Editar;
      this.contatoEditado = {
        index: index,
        control: this.contatosFormArray.controls[index],
      };
    }
    return ContatoForm.criarForm(contato);
  }
  private criarEnderecoFormGroup(endereco?: Endereco, index?: number) {
    if (index >= 0) {
      this.operacaoFormEndereco = OperacaoForm.Editar;
      this.enderecoEditado = {
        index: index,
        control: this.enderecosFormArray.controls[index],
      };
    }
    return EnderecoForm.from(endereco);
  }
  private mostrarMensagemDocumentoInvalido() {
    this.toastrService.error('Informe um documento válido.');
  }
  private mostrarMensagemTransportadoraCadastrada() {
    this.toastrService.error('Transportadora já cadastrada com este documento');
  }
  private preencherPessoaExistente(pessoa: Pessoa) {
    if (pessoa.enderecos) {
      pessoa.enderecos.orderByDescending((p) => p.principal);
      pessoa.enderecos.forEach((e) => {
        this.adicionarEnderecoFormGroup(e);
      });
    }
    if (pessoa.contatos) {
      pessoa.contatos.orderByDescending((p) => p.principal);
      pessoa.contatos.forEach((c) => {
        this.adicionarContatoFormGroup(c);
      });
    }
    if (pessoa.nascimento) {
      pessoa.nascimento = moment(pessoa.nascimento).toDate();
    }
    this.pessoaId = pessoa.id;

    this.form.patchValue(pessoa);
  }
  private setarPessoaJuridica() {
    this.form.controls.pessoaTipo.setValue(PessoaTipo.Juridica);
  }
  private verificarDocumentoExiste(documento: string, pessoaTipo: PessoaTipo) {
    return pessoaTipo === PessoaTipo.Fisica ?
        this.apiServicesCoreFacade.buscarCpf(documento) :
        this.apiServicesCoreFacade.buscarCnpj(documento);
  }
  async buscarDadosPorDocumento(documento: string) {
    if (documento.length == 11) {
      this.buscarDadosPorCpf(documento);
    } else if (documento.length == 14) {
      this.buscarDadosPorCnpj(documento);
    }
  }
  async consultarDocumentoValido() {
    const documento = this.form.controls.documento.value?.somenteNumeros();

    if (!documento) return;
    if (documento.length != 11 && documento.length != 14) return;

    const transportadora =
        await this.transportadoraFacade.buscarPorDocumento(documento);

    if (transportadora) {
      this.toastrService.error(
          'Transportadora já cadastrada com este documento');
      return;
    }

    if (this.operacaoFormAdicionar) {
      const pessoa =
          await this.pessoaFacade.buscarPorDocumento(documento).catch(
              () => null);
      if (pessoa) {
        this.preencherPessoaExistente(Pessoa.from(pessoa));
        return;
      }
    }

    this.buscarDadosPorDocumento(documento);
  }
  async isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }

    if (this.form.controls.documento.value) {
      const documento = this.form.controls.documento.value?.somenteNumeros();

      const documentoExiste = await this.verificarDocumentoExiste(
          documento, this.form.controls.pessoaTipo.value);

      if (!documentoExiste) {
        this.mostrarMensagemDocumentoInvalido();
        return false;
      }

      const transportadoraCadastrado =
          await this.transportadoraFacade.buscarPorDocumento(documento);

      if (transportadoraCadastrado &&
          documento !== this.transportadoraCpfCnpj?.somenteNumeros()) {
        this.mostrarMensagemTransportadoraCadastrada();
        return false;
      }
    }
    return true;
  }
  async buscarRegistrosFinanceiros() {
    if (this?.lancamentosParcela?.length > 0) return;

    var registrosFinanceiros =
        await this.transportadoraFacade.buscarRegistrosFinanceiros(
            this.form.controls.id.value);
    this.lancamentosParcela = registrosFinanceiros;
  }
  async setarPlanoContasCadastrado(id: string) {
    this.form.controls.planoConta3Id.setValue(id);
  }
  aplicarValidacoesPorParametros(documentoObrigatorio: boolean) {
    if (documentoObrigatorio) {
      this.adicionarValidator(
          'documento', CustomValidators.required('Documento é obrigatório'),
          this.form);
    }
  }
  abrirContatoModal(contato?: Contato, index?: number) {
    this.contatoForm = this.criarContatoFormGroup(contato, index);
    if (contato) {
      this.contatoModalTitulo = 'Editar contato';
      this.operacaoFormContato = OperacaoForm.Editar;
    } else {
      this.contatoModalTitulo = 'Novo contato';
      this.operacaoFormContato = OperacaoForm.Adicionar;
    }
    this.mostrarContatoModal = true;
  }
  abrirEnderecoModal(endereco?: Endereco, index?: number) {
    this.enderecoForm = this.criarEnderecoFormGroup(endereco, index);
    if (endereco) {
      this.enderecoModalTitulo = 'Editar endereço';
      this.operacaoFormEndereco = OperacaoForm.Editar;
    } else {
      this.enderecoModalTitulo = 'Novo endereço';
      this.operacaoFormEndereco = OperacaoForm.Adicionar;
    }
    this.mostrarEnderecoModal = true;
  }
  abrirPlanoContasCadastroRapidoModal() {
    this.mostrarPlanoContasModal = true;
  }
  criarForm() {
    this.form = new FormGroup<ITransportadoraForm>({
      nascimento: new FormControl(
          null,
          [
            CustomValidators.data(
                'dd/MM/yyyy', 'Inserir data no formato: "dd/mm/aaaa"'),
          ]),
      nomeRazaoSocial: new FormControl(
          null, CustomValidators.required('Nome/Razão Social é obrigatório')),
      pessoaTipo: new FormControl(
          null, CustomValidators.required('Tipo de pessoa é obrigatório')),
      nomeFantasia: new FormControl(
          null, CustomValidators.required('Nome fantasia é obrigatório')),
      agencia: new FormControl(null),
      contatos: new FormArray([]),
      contaCorrente: new FormControl(null),
      banco: new FormControl(null),
      condicaoPagamento: new FormControl(null),
      documento: new FormControl(null),
      enderecos: new FormArray([]),
      regimeTributario: new FormControl(null),
      atividadeTipo: new FormControl(null),
      ieIndicador: new FormControl(null),
      rgIe: new FormControl(null),
      id: new FormControl(null),
      ativo: new FormControl(true),
      observacao: new FormControl(null),
      transportadoraId: new FormControl(null),
      createdOn: new FormControl(null),
      lastModifiedOn: new FormControl(null),
      createdBy: new FormControl(null),
      nomeCreatedBy: new FormControl(null),
      planoConta3Id: new FormControl(null),
      whatsApp: new FormControl(null),
      telefoneFixo: new FormControl(null),
      vendedorPadrao: new FormControl(null),
      emailGeral: new FormControl(null),
      emailFaturamento: new FormControl(null),
    });
  }
  fecharPlanoContasCadastroRapidoModal() {
    this.mostrarPlanoContasModal = false;
  }
  fecharContatoModal() {
    this.mostrarContatoModal = false;
  }
  fecharEnderecoModal() {
    this.mostrarEnderecoModal = false;
  }
  monitorarPessoaTipo() {
    this.form.controls.pessoaTipo.valueChanges.subscribe((novoValor) => {
      if (this.pessoaTipo >= 0 &&
          this.form.controls?.pessoaTipo?.value != this.pessoaTipo) {
        this.form.controls.documento.setValue('');
      }
      if (novoValor === PessoaTipo.Juridica) {
        this.pessoaTipoSelecionada = PessoaTipo.Juridica;
        this.labelCpfCnpj = 'CNPJ';
        this.labelRgIe = 'Inscrição estadual';
        this.labelNomeRazaoSocial = 'Razão social';
        this.labelNascimento = 'Data de abertura';
        this.form.controls.rgIe.setValue(null);
        this.aplicarValidacoesPessoaJuridica();
      } else {
        this.pessoaTipoSelecionada = PessoaTipo.Fisica;
        this.labelCpfCnpj = 'CPF';
        this.labelRgIe = 'RG';
        this.labelNomeRazaoSocial = 'Nome';
        this.labelNascimento = 'Nascimento';
        this.form.controls.rgIe.setValue(null);
        this.form.controls.regimeTributario.setValue(null);
        this.form.controls.atividadeTipo.setValue(null);
        this.form.controls.ieIndicador.setValue(null);
        this.aplicarValidacoesPessoaFisica();
      }
      this.pessoaTipo = novoValor;
    });
  }
  monitorarRegimeTributario() {
    this.form.controls.regimeTributario.valueChanges.subscribe(
        (item: number) => {
          if (item === 1) {
            this.form.controls.ieIndicador.setValue(
                IeIndicador.NaoContribuinte);
          }
        });
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  preencherNomeFantasia(nomeFantasia: string) {
    if (this.pessoaJuridica)
      this.form.controls.nomeFantasia.setValue(nomeFantasia);
  }
  setarTipoFormulario(transportadora: Transportadora) {
    if (transportadora) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(Transportadora.from(transportadora));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
      this.pessoaTipoSelecionada = PessoaTipo.Juridica;
      this.setarPessoaJuridica();
    }
  }
  salvarContato(form: FormGroup<IContatoForm>) {
    if (form.valid) {
      const contato = Contato.from(form.value);
      if (this.contatosFormArray.length == 0 ||
          this.contatoEditado?.index == 0) {
        contato.principal = true;
      }

      if (this.operacaoFormContato === OperacaoForm.Adicionar) {
        this.adicionarContatoFormGroup(contato);
      } else {
        const index = this.contatoEditado.index;
        this.contatosFormArray.removeAt(index);
        this.contatosFormArray.insert(
            index, this.criarContatoFormGroup(contato));
      }
      this.form.markAsDirty();
      this.fecharContatoModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(form);
    }
  }
  salvarEndereco(form: FormGroup<IEnderecoForm>) {
    const endereco = Endereco.from(form.value);

    if ((endereco.cep.length == 0 && endereco.logradouro.length == 0 &&
         endereco.numero.length == 0 && endereco.bairro.length == 0 &&
         endereco.cidade.length == 0 && !endereco.ufIbge) ||
        !endereco.enderecoTipoId)
      this.toastrService.warning(
          'Selecione o tipo de endereço e preencha algum outro campo para salvar!');
    else {
      if (this.enderecosFormArray.length == 0 ||
          this.enderecoEditado?.index == 0) {
        endereco.principal = true;
      }

      if (endereco.ufIbge)
        endereco.uf = Estados.find((p) => p.ufIbge === endereco.ufIbge).ufSigla;

      if (this.operacaoFormEndereco === OperacaoForm.Adicionar) {
        if (this.enderecoTipoFacade.enderecoTipos
                .filter((p) => p.enderecoTipoId === endereco.enderecoTipoId)
                .length <= 0) {
          this.enderecoTipoFacade.enderecoTipos.forEach(
              (enderecoTipo, index) => {
                endereco.enderecoTipoId = enderecoTipo.enderecoTipoId;
                if (index > 0) {
                  endereco.principal = false;
                }
                this.adicionarEnderecoFormGroup(endereco);
              });
        } else {
          this.adicionarEnderecoFormGroup(endereco);
        }
      } else {
        const index = this.enderecoEditado.index;
        this.enderecosFormArray.removeAt(index);
        this.enderecosFormArray.insert(
            index, this.criarEnderecoFormGroup(endereco));
      }
      this.form.markAsDirty();
      this.fecharEnderecoModal();
    }
  }
}
