import {Pipe, PipeTransform} from '@angular/core';
import {ContaBancariaFacade} from '@app/abstraction/domain/facades/financeiro/conta-bancaria/conta-bancaria.facade';
import {ContaBancariaListagem} from '@app/abstraction/domain/interfaces/financeiro/conta-bancaria/conta-bancaria-listagem.interface';

@Pipe({
  name: 'contaBancaria',
})
export class ContaBancariaPipe implements PipeTransform {
  constructor(private contaBancariafacade: ContaBancariaFacade) {}
  transform(id: string, campo: string = 'nome'): any {
    if (id) {
      const contaBancaria = this.contaBancariafacade.contasBancarias?.find(
                                (contaBancaria: ContaBancariaListagem) =>
                                    contaBancaria.id == id) as any;
      return contaBancaria?.[campo];
    }
    return '';
  }
}
