import {Pipe, PipeTransform} from '@angular/core';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {PessoaListagem} from '@app/abstraction/domain/interfaces/cadastros/pessoa/pessoa-listagem.interface';

@Pipe({
  name: 'pessoa',
})
export class PessoaPipe implements PipeTransform {
  constructor(private pessoafacade: PessoaFacade) {}
  transform(documento: string, campo: string = 'nomeRazaoSocial'): any {
    if (documento) {
      const pessoa =
          this.pessoafacade.pessoas?.find(
              (pessoa: PessoaListagem) => pessoa.documento == documento) as any;
      return pessoa?.[campo];
    }
    return '';
  }
}
