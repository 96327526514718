import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {ParametroListagem} from '@app/abstraction/domain/interfaces/configuracoes/parametro/parametro-listagem.interface';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';

@Component({
  selector: 'app-tabela-parametros',
  templateUrl: './tabela-parametros.component.html',
  styleUrls: ['./tabela-parametros.component.scss'],
})
export class TabelaParametrosComponent {
  @ContentChild('rowExpanded') rowExpanded: TemplateRef<any>;
  @ContentChild('dropdownParametroTemp')
  dropdownParametroTemp: TemplateRef<any>;
  @Input() columns: TableColumn[];
  @Input() expandable: boolean;
  @Input() dataKey: string;
  @Input() rows: ParametroListagem[];
  @Input() editMode: 'cell'|'row' = 'row';
  @Input() emptyMessage = 'Nenhum item encontrado';
  @Output() aoSalvarLinha = new EventEmitter<ParametroListagem>();
  @Output() aoCancelarEdicao = new EventEmitter();

  editingItems: {[s: string]: ParametroListagem} = {};
  get colspan() {
    let count = this.columns?.filter((e) => !e.hidden).length;
    if (this.expandable) {
      count++;
    }
    return count;
  }
  constructor() {}
  compararTipoCampo(tipoCampo: string, tipo: string, lista?: boolean) {
    if (lista) return false;

    return tipoCampo.toLowerCase() === tipo;
  }
  onRowEditInit(parametro: ParametroListagem) {
    this.editingItems[parametro.chave] = {...parametro} as any;
  }
  onRowEditSave(parametro: ParametroListagem) {
    this.aoSalvarLinha.emit(parametro);
  }
  onRowEditCancel(row: ParametroListagem, index: number) {
    let editingItem = this.editingItems[row.chave];

    this.rows[index].valor = editingItem.valor;
    delete this.editingItems[row.chave];
    this.aoCancelarEdicao.emit();
  }
}
