export enum ClienteTipo {
  Nenhum,
  Preferencial,
  Fidelidade,
  Vip,
}

export const ClienteTipoLabels = new Map<number, string>([
  [ClienteTipo.Preferencial, 'PREFERENCIAL'],
  [ClienteTipo.Fidelidade, 'FIDELIDADE'],
  [ClienteTipo.Vip, 'VIP'],
]);
