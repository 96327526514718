import {FormControl, FormGroup} from '@angular/forms';
import {NcmUf} from '@app/abstraction/domain/entities/fiscal/ncm/ncm-uf.entity';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface INcmUfForm {
  estadual: FormControl<number>;
  importadosFederal: FormControl<number>;
  municipal: FormControl<number>;
  nacionalFederal: FormControl<number>;
  ncmUfId: FormControl<string>;
  uf: FormControl<string>;
}

export class NcmUfForm {
  static criarForm(ncmUf: NcmUf) {
    return new FormGroup<INcmUfForm>({
      estadual: new FormControl(ncmUf?.estadual),
      importadosFederal: new FormControl(ncmUf?.importadosFederal),
      municipal: new FormControl(ncmUf?.municipal),
      nacionalFederal: new FormControl(ncmUf?.nacionalFederal),
      ncmUfId: new FormControl(ncmUf?.ncmUfId),
      uf: new FormControl(
          ncmUf?.uf,
          CustomValidators.required('Estado é obrigatório'),
          ),
    });
  }
}
