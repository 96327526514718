<div class="grid flex" *ngIf="!widgets">
  <div
    class="col-12 xl:col-3 md:col-6"
    *ngFor="let widget of [].constructor(skeletonCount)"
  >
    <p-skeleton height="7rem"></p-skeleton>
  </div>
</div>

<div class="grid flex" *ngIf="widgets">
  <!-- <div class="widget widget-stats" [style.background]="widget.cor">
      <a
        [routerLink]="widget.link"
        (click)="abrirLinkNovaAba(widget)"
        [ngClass]="{ mudarCursor: widget.abrirLinkNovaAba }"
      >
        <div
          class="stats-icon"
          [ngClass]="{ 'stats-icon-lg': widget.iconeGrande }"
        >
          <i [ngClass]="[widget.icone]"></i>
        </div>
        <div class="stats-content">
          <div class="grid flex">
            <div
              class="col-8 xl:col-8 md:col-8 stats-title left-title text-left"
            >
              {{ widget.tituloEsquerdo }}
            </div>
            <div
              class="col-4 xl:col-4 md:col-4 stats-title right-title text-right"
            >
              {{ widget.tituloDireito }}
            </div>
          </div>
          <div class="flex">
            <div class="stats-number" pTooltip="Total">
              <p-tag *ngIf="widget.ativos || widget.inativos">
                {{ widget.valor }}
                <i class="pi pi-circle-off icon-ativo" style="color: white"></i>
              </p-tag>
            </div>
            <div
              class="stats-number quantidade-ativo"
              *ngIf="widget.ativos"
              pTooltip="Ativos"
            >
              <p-tag severity="success">
                {{ widget.ativos }}
                <i
                  class="pi pi-check-circle icon-ativo"
                  style="color: white"
                ></i>
              </p-tag>
            </div>
            <div
              class="stats-number quantidade-ativo"
              *ngIf="widget.inativos"
              pTooltip="Inativos"
            >
              <p-tag severity="danger">
                {{ widget.inativos }}
                <i
                  class="pi pi-times-circle icon-ativo"
                  style="color: white"
                ></i>
              </p-tag>
            </div>
            <div
              class="stats-number espacamento-top-5"
              *ngIf="!widget.ativos && !widget.inativos"
            >
              {{ widget.valor }}
            </div>
          </div>
          <div
            class="stats-progress progress"
            [ngClass]="{ invisible: !widget.progresso }"
          >
            <div
              class="progress-bar"
              [style.width]="widget.progresso + '%'"
            ></div>
          </div>
          <div
            class="stats-desc-1"
            [ngClass]="{
              'espacamento-top-11': !widget.mostrarBotaoPrimario,
              'espacamento-top-24':
                !widget.info2 && !widget.mostrarBotaoPrimario
            }"
          >
            {{ widget.info1 }}
          </div>
          <div class="stats-desc-2" *ngIf="!widget.mostrarBotaoPrimario">
            {{ widget.info2 }}
          </div>
          <div *ngIf="widget.mostrarBotaoPrimario">
            <div class="grid flex">
              <div class="col-7 xl:col-7 md:col-7 text-left col-stats-button">
                <p-button
                  type="button"
                  [innerText]="widget.textoBotaoPrimario"
                  class="stats-button primary-button"
                  [style.color]="widget.cor"
                  [routerLink]="widget.linkBotaoPrimario"
                >
                </p-button>
              </div>
              <div
                *ngIf="widget.mostrarBotaoSecundario"
                class="col-5 xl:col-5 md:col-5 text-right col-stats-button"
              >
                <button
                  type="button"
                  [innerText]="widget.textoBotaoSecundario"
                  class="stats-button secundary-button"
                  [routerLink]="widget.linkBotaoSecundario"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div> -->
  <div class="col-12 lg:col-6 xl:col-3" *ngFor="let widget of widgets">
    <div class="card mb-0">
      <div class="flex justify-content-between">
        <!-- TEXTOS -->
        <div>
          <span class="block text-500 font-medium mb-3">{{
            widget.tituloEsquerdo
          }}</span>
          <div class="text-900 font-medium text-xl">{{ widget.valor }}</div>
          <span class="text-500" *ngIf="widget.info1">{{ widget.info1 }}</span>
          <br />
          <span class="text-500" *ngIf="widget.info2">{{ widget.info2 }}</span>
          <br />
        </div>
        <!-- ICONE -->
        <div
          class="flex align-items-center justify-content-center border-round"
          [ngClass]="widget.corStyleClass"
          [ngStyle]="{ width: '2.5rem', height: '2.5rem' }"
        >
          <i class="text-xl" [ngClass]="[widget.iconeCor, widget.icone]"></i>
        </div>
      </div>
    </div>
  </div>
</div>
