import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {NcmService} from '@app/abstraction/data/services/fiscal/ncm.service';
import {NcmStore} from '@app/abstraction/data/stores/fiscal/ncm.store';
import {Ncm} from '@app/abstraction/domain/entities/fiscal/ncm/ncm.entity';
import {NcmFiltro} from '@app/abstraction/domain/interfaces/fiscal/ncm/ncm-filtro.interface';

@Injectable({providedIn: 'root'})
export class NcmFacade {
  get ncms() {
    return this.store.ncms;
  }
  get ncms$() {
    return this.store.ncms$;
  }
  constructor(private store: NcmStore, private service: NcmService) {}
  async adicionar(ncm: Ncm) {
    const novoNCM = await this.service.adicionar(ncm);
    if (novoNCM) {
      this.store.adicionar(novoNCM.toListagem());
    }
    return novoNCM;
  }
  async alterar(ncm: Ncm) {
    return this.service.alterar(ncm, ncm.ncmCodigo);
  }
  async atualizarTributacaoProdutos(ncmCodigo: string, ncmExcecao: string) {
    return this.service.atualizarTributacaoProdutos(ncmCodigo, ncmExcecao);
  }
  async buscarNcms(filtro?: NcmFiltro) {
    const ncms = await this.service.buscarTodos(filtro);
    this.store.adicionarRangeNcms(ncms);
    return ncms;
  }
  async buscarPorNcmCodigo(
      ncmCodigo: string, uf: string, ncmExcecaoTipi?: number|string) {
    return await this.service.buscarPorNcmCodigoUf(
        ncmCodigo, uf, ncmExcecaoTipi);
  }
  limparListaNcms() {
    this.store.ncms = null;
  }
}
