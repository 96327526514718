export class NcmIcms {
  cestCodigo: string;
  icmsAliquotaEntrada: string|number;
  icmsAliquotaEntradaIva: string|number;
  icmsAliquotaEntradaReducao: string|number;
  icmsAliquotaSaida: string|number;
  icmsAliquotaSaidaFinal: string|number;
  icmsAliquotaSaidaReducao: string|number;
  icmsCstCsosnEntrada: string;
  icmsCstCsosnSaida: string;
  ncmCodigo: string;
  ncmExcecaoTipi: number|string;
  ncmIcmsId: string;
  origem: string;
  produtoServico: string;
  uf: string;
  static from(value: any) {
    const ncmIcms = new NcmIcms();
    ncmIcms.cestCodigo = value.cestCodigo;
    ncmIcms.icmsAliquotaEntrada = value.icmsAliquotaEntrada;
    ncmIcms.icmsAliquotaEntradaIva = value.icmsAliquotaEntradaIva;
    ncmIcms.icmsAliquotaEntradaReducao = value.icmsAliquotaEntradaReducao;
    ncmIcms.icmsAliquotaSaida = value.icmsAliquotaSaida;
    ncmIcms.icmsAliquotaSaidaFinal = value.icmsAliquotaSaidaFinal;
    ncmIcms.icmsAliquotaSaidaReducao = value.icmsAliquotaSaidaReducao;
    ncmIcms.icmsCstCsosnEntrada = value.icmsCstCsosnEntrada;
    ncmIcms.icmsCstCsosnSaida = value.icmsCstCsosnSaida;
    ncmIcms.ncmCodigo = value.ncmCodigo;
    ncmIcms.ncmExcecaoTipi = value.ncmExcecaoTipi;
    ncmIcms.ncmIcmsId = value.ncmIcmsId;
    ncmIcms.origem = value.origem;
    ncmIcms.produtoServico = value.produtoServico;
    ncmIcms.uf = value.uf;
    return ncmIcms;
  }
  toAPI() {
    this.ncmExcecaoTipi = Number(this.ncmExcecaoTipi);
    this.icmsAliquotaEntrada = Number(this.icmsAliquotaEntrada);
    this.icmsAliquotaEntradaReducao = Number(this.icmsAliquotaEntradaReducao);
    this.icmsAliquotaEntradaIva = Number(this.icmsAliquotaEntradaIva);
    this.icmsAliquotaSaida = Number(this.icmsAliquotaSaida);
    this.icmsAliquotaSaidaReducao = Number(this.icmsAliquotaSaidaReducao);
    this.icmsAliquotaSaidaFinal = Number(this.icmsAliquotaSaidaFinal);
  }
}
