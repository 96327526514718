export enum Molde {
  Diario = 1,
  Mensal = 2,
  Anual = 3,
}

export const MoldeLabels = new Map<number, string>([
  [Molde.Diario, 'DIÁRIO'],
  [Molde.Mensal, 'MENSAL'],
  [Molde.Anual, 'ANUAL'],
]);
