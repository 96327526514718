<div class="cartao-conta-boleto-carteira" [ngClass]="{ principal: contaBoletoCarteira.padrao }">
  <div class="icone"><i class="pi pi-wallet"></i></div>
  <div class="info">
    <h3>{{ contaBoletoCarteira.convenio }}{{ contaBoletoCarteira.digitoConvenio ? "-" + contaBoletoCarteira.digitoConvenio : "" }}</h3>
    <h4>{{ contaBoletoCarteira.codigoCarteira | carteira }}</h4>
    <h4>CNAB: {{ contaBoletoCarteira.cnab | enumLabel: cnabLabels }}</h4>
    <h4>Variação: {{ contaBoletoCarteira.variacaoCarteira }}</h4>
    <h4>Taxa: {{ contaBoletoCarteira.taxa }}%</h4>
    <h4>Somar Taxa: {{ contaBoletoCarteira.somarTaxa | booleano }}</h4>
  </div>
  <div class="barra-opcoes cursor-pointer w-full text-right">
    <i
      class="pi pi-star"
      *ngIf="contaBoletoCarteira.padrao"
      tooltipPosition="top"
      pTooltip="Carteira Padrão"
    ></i>
    <i
      class="pi pi-pencil ml-2"
      tooltipPosition="top"
      pTooltip="Editar Carteira"
      (click)="editarClicado()"
    ></i>
    <i
    class="pi pi-times ml-2"
    tooltipPosition="top"
    pTooltip="Excluir Carteira"
    (click)="excluirClicado()"
  ></i>
  </div>
</div>
