import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {Arquivo} from '@app/abstraction/domain/interfaces/shared/arquivo';
import {API} from '@app/shared/constants/api.constant';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import * as moment from 'moment';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FileService {
  constructor(
      private client: HttpClient,
      private errorMessagesFacade: ErrorMessageFacade,
  ) {}
  async abrirArquivo(caminho: string) {
    let response: Blob = await this.buscarArquivo(caminho).catch(() => {
      return this.errorMessagesFacade.mostrarMensagens(
          'Arquivo não encontrado.');
    });
    if (response) window.open(`${API.ERP_BASE_URL}/${caminho}`, '_blank');
  }
  buscarArquivo(caminho: string) {
    const response$ = this.client.get(`${caminho}`, {
      responseType: 'blob',
      headers: {'Access-Control-Allow-Origin': '*'},
    });
    return lastValueFrom(response$);
  }
  blobToBase64 = (file: Blob): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let result = reader.result.toString();
          const indexOf = 'base64,';
          const index = result.indexOf(indexOf);
          result = result.substring(index + indexOf.length);
          resolve(result);
        };
        reader.onerror = (error) => reject(error);
      });
  imagemParaBase64 = (file: Blob): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let result = reader.result.toString();
          resolve(result);
        };
        reader.onerror = (error) => reject(error);
      });
  salvarArquivo(blob: Blob, nomeArquivo: string) {
    const file = new Blob([blob], {
      type: blob.type,
    });
    const objectUrl = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = nomeArquivo;
    link.click();
    window.URL.revokeObjectURL(objectUrl);
    link.remove();
  }
  salvarZip(arquivos: Arquivo[]) {
    if (arquivos.length > 0) {
      let zipFile: JSZip = new JSZip();
      arquivos.forEach((arquivo) => zipFile.file(arquivo.nome, arquivo.blob));
      zipFile.generateAsync({type: 'blob'}).then((content) => {
        const zipName = moment.now();
        FileSaver.saveAs(content, `${zipName}.zip`);
      });
    }
  }
}