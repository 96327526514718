<p-multiSelect
  selectedItemsLabel="{0} itens selecionadas"
  appendTo="body"
  styleClass=" inputfield w-full"
  [options]="options"
  [placeholder]="placeholder"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [emptyMessage]="emptyMessage"
  [emptyFilterMessage]="emptyFilterMessage"
  [readonly]="readonly"
  [showClear]="showClear"
  [filter]="showFilter"
  [display]="display"
  [ngClass]="{ readonly: readonly }"
  [ngModelOptions]="modelOptions"
  (ngModelChange)="onNgModelChange($event)"
  [(ngModel)]="model"
>
  <ng-template let-cor pTemplate="item" *ngIf="templateCor">
    <div class="flex justify-content-between w-full">
      <span>
        {{ cor[optionLabel] }}
      </span>
      <div
        class="caixa-cor"
        [pTooltip]="cor.corCodigo"
        tooltipPosition="top"
        [ngStyle]="{ background: cor.corCodigo }"
      ></div>
    </div>
  </ng-template>
</p-multiSelect>
