import {NotaFiscalDuplicata} from './duplicata.entity';

export class NotaFiscalPagamento {
  observacao: string;
  id: string;
  formaPagamentoId: string;
  formaParcelamentoId: string;
  duplicatas: NotaFiscalDuplicata[];
  tipoPagamento: string;
  valor: number;

  static from(json: any = {}): NotaFiscalPagamento {
    const pagamento = new NotaFiscalPagamento();
    pagamento.observacao = json.observacao?.trim();
    pagamento.id = json.id;
    pagamento.formaPagamentoId = json.formaPagamentoId;
    pagamento.formaParcelamentoId = json.formaParcelamentoId;
    pagamento.duplicatas =
        json.duplicatas?.map(
            (d: NotaFiscalDuplicata) => NotaFiscalDuplicata.from(d)) ??
        [];
    pagamento.tipoPagamento = json.tipoPagamento?.trim();
    pagamento.valor = json.valor;

    return pagamento;
  }
}
