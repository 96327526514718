import {ProdutoVariacaoCorListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-cor/produto-variacao-cor-listagem.interface';
export class ProdutoVariacaoCor {
  ativo: boolean;
  corCodigo: string;
  descricao: string;
  id: string;
  static from(json: any = {}) {
    const produtoVariacaoCor = new ProdutoVariacaoCor();
    produtoVariacaoCor.id = json.id;
    produtoVariacaoCor.descricao = json.descricao?.trim();
    produtoVariacaoCor.corCodigo = json.corCodigo?.trim();
    produtoVariacaoCor.ativo = json.ativo;
    return produtoVariacaoCor;
  }
  toListagem() {
    return <ProdutoVariacaoCorListagem>{
      descricao: this.descricao,
      id: this.id,
      corCodigo: this.corCodigo,
      ativo: this.ativo,
    };
  }
}
