import {Injectable} from '@angular/core';
import {PerfilService} from '@app/abstraction/data/services/cadastros/perfil.service';
import {PerfilStore} from '@app/abstraction/data/stores/cadastros/perfil.store';
import {Perfil} from '@app/abstraction/domain/entities/cadastros/perfil/perfil';
import {PerfilFiltro} from '@app/abstraction/domain/interfaces/cadastros/perfil/perfil-filtro.interface';

@Injectable({providedIn: 'root'})
export class PerfilFacade {
  get perfis() {
    return this.store.perfis;
  }
  get perfis$() {
    return this.store.perfis$;
  }
  constructor(private store: PerfilStore, private service: PerfilService) {}
  async buscarPerfis(filtro?: PerfilFiltro) {
    const perfis = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(perfis);
  }
  async atualizarSituacao(id: string) {
    const perfil = this.perfis.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !perfil.ativo);
    perfil.ativo = status;
    return status;
  }
  adicionar(perfil: Perfil) {
    return this.service.adicionar(perfil);
  }
  alterar(perfil: Perfil, id: string) {
    return this.service.alterar(perfil, id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaPerfis() {
    this.store.perfis = null;
  }
}
