import {Pipe, PipeTransform} from '@angular/core';
import {SecaoFacade} from '@app/abstraction/domain/facades/produtos/secao/secao.facade';
import {SecaoListagem} from '@app/abstraction/domain/interfaces/produtos/secao/secao-listagem.interface';

@Pipe({
  name: 'secao',
})
export class SecaoPipe implements PipeTransform {
  constructor(private secaoFacade: SecaoFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const secao = this.secaoFacade.secoes?.find(
                        (secao: SecaoListagem) => secao.id == id) as any;
      return secao?.[campo];
    }
    return '';
  }
}
