import {FormControl, FormGroup} from '@angular/forms';
import {TipoAjuste} from '@app/abstraction/domain/entities/estoque/tipo-ajuste/tipo-ajuste.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface ITipoAjuste {
  id: FormControl<string>;
  descricao: FormControl<string>;
  ativo: FormControl<boolean>;
  movimentaEstoque: FormControl<boolean>;
  movimentaComposicao: FormControl<boolean>;
}

export class TipoAjusteForm extends BaseForm {
  form: FormGroup<ITipoAjuste>;
  operacaoForm: OperacaoForm;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Novo tipo de ajuste' :
                                        'Editar tipo de ajuste';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(
      private errorMessagesFacade: ErrorMessageFacade,
  ) {
    super();
  }
  private preencherForm(tipoAjuste: TipoAjuste) {
    this.form.patchValue(tipoAjuste);
  }
  criarForm() {
    this.form = new FormGroup({
      id: new FormControl(null),
      descricao: new FormControl(
          null, CustomValidators.required('Descrição é obrigatória')),
      movimentaEstoque: new FormControl(true),
      movimentaComposicao: new FormControl(false),
      ativo: new FormControl(true),
    });
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(TipoAjuste.from(value));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
}
