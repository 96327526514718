import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stringPad',
})
export class StringPadPipe implements PipeTransform {
  transform(
      input: string|number, length: number = 0, character: string = ' ',
      type: string = 'start') {
    if (input?.toString()?.length) {
      if (type === 'end') {
        return endPad(input, length, character);
      } else {
        return startPad(input, length, character);
      }
    }
    return '';
  }
}
function endPad(input: string|number, length: number, character: string) {
  return input?.toString()?.padEnd(length, character);
}

function startPad(input: string|number, length: number, character: string) {
  return input?.toString()?.padStart(length, character);
}
