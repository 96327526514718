import {Injectable} from '@angular/core';
import {Parametro} from '@app/abstraction/domain/interfaces/configuracoes/parametro/parametro.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ParametroStore {
  private readonly _parametros = new BehaviorSubject<Parametro[]>(null);

  get parametros(): Parametro[] {
    return this._parametros.getValue();
  }
  set parametros(parametros: Parametro[]) {
    this._parametros.next(parametros);
  }
  get parametros$() {
    return this._parametros.asObservable();
  }
}