import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {ClienteSituacao} from '@app/abstraction/domain/entities/cadastros/cliente-situacao/cliente-situacao.entity';
import {Cliente} from '@app/abstraction/domain/entities/cadastros/cliente/cliente.entity';
import {PlanoContasNivel3} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-3.entity';
import {Contato} from '@app/abstraction/domain/entities/shared/contato.entity';
import {Endereco} from '@app/abstraction/domain/entities/shared/endereco.entity';
import {ClienteSituacaoFacade} from '@app/abstraction/domain/facades/cadastros/cliente-situacao/cliente-situacao.facade';
import {ClienteFacade} from '@app/abstraction/domain/facades/cadastros/cliente/cliente.facade';
import {FuncionarioFacade} from '@app/abstraction/domain/facades/cadastros/funcionario/funcionario.facade';
import {UsuarioFacade} from '@app/abstraction/domain/facades/cadastros/usuario/usuario.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {ApiServicesFacade} from '@app/abstraction/domain/facades/shared/api-services/api-services.facade';
import {CepFacade} from '@app/abstraction/domain/facades/shared/cep/cep.facade';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {ClienteForm} from '@app/abstraction/domain/forms/cadastros/cliente.form';
import {IContatoForm} from '@app/abstraction/domain/forms/shared/contato.form';
import {IEnderecoForm} from '@app/abstraction/domain/forms/shared/endereco.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {PedidoSituacaoLabels} from '@app/shared/enums/pedido-situacao.enum';
import {SituacaoLancamentoLabels} from '@app/shared/enums/situacao-lancamento.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-cliente-shared-form',
  templateUrl: './cliente-shared-form.component.html',
  styleUrls: ['./cliente-shared-form.component.scss']
})
export class ClienteSharedFormComponent extends FormBaseComponent implements
    OnInit {
  @ViewChild('situacaoPedidoTemp', {static: true})
  situacaoPedidoTemp: TemplateRef<any>;
  @ViewChild('situacaoLancamentoParcelaTemp', {static: true})
  situacaoLancamentoParcelaTemp: TemplateRef<any>;
  @ViewChild('pedidosAcoesTemp', {static: true})
  pedidosAcoesTemp: TemplateRef<any>;
  @ViewChild('lancamentosParcelaAcoesTemp', {static: true})
  lancamentosParcelaAcoesTemp: TemplateRef<any>;
  @ViewChild('dataTemp', {static: true}) dataTemp: TemplateRef<any>;
  @ViewChild('despesaTemp', {static: true}) despesaTemp: TemplateRef<any>;
  @ViewChild('valorTemp', {static: true}) valorTemp: TemplateRef<any>;
  clienteForm: ClienteForm;
  colunasTabelaPedido: TableColumn[];
  colunasTabelaLancamentoParcela: TableColumn[];

  get planosContas3() {
    return this.planosContasFacade.planosContasNivel3;
  }
  get pessoaJuridica() {
    return this.clienteForm.pessoaJuridica;
  }
  get maxlength() {
    return this.clienteForm.maxlength;
  }
  get maxlengthCount() {
    return this.clienteForm.maxlengthCount;
  }
  get clienteSituacoes$() {
    return this.clienteSituacaoFacade.clienteSituacoes$;
  }
  get contatos() {
    return this.clienteForm.contatos;
  }
  get enderecos() {
    return this.clienteForm.enderecos;
  }
  get operacaoFormEnderecoAdicionar() {
    return this.clienteForm?.operacaoFormEnderecoAdicionar;
  }
  get documentoObrigatorio() {
    return this.parametroFacade.documentoObrigatorio;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get valorPlaceholder() {
    return this.mascaraService.placeholder(this.valorDecimais);
  }
  get pedidoSituacaoLabels() {
    return PedidoSituacaoLabels;
  }
  get situacaoLancamentoLabels() {
    return SituacaoLancamentoLabels;
  }
  get funcionarios() {
    return this.funcionarioFacade.funcionarios;
  }
  get tituloTabPanelPedidos() {
    return 'Pedidos';
  }
  get tituloTabPanelRegistrosFinanceiros() {
    return 'Registros Financeiros';
  }
  constructor(
      private apiServicesCoreFacade: ApiServicesFacade,
      private router: Router,
      private clienteFacade: ClienteFacade,
      private funcionarioFacade: FuncionarioFacade,
      private pessoaFacade: PessoaFacade,
      private enderecoTipoFacade: EnderecoTipoFacade,
      private clienteSituacaoFacade: ClienteSituacaoFacade,
      private planosContasFacade: PlanoContasFacade,
      private usuarioFacade: UsuarioFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      private cepFacade: CepFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private montarColunasTabelaPedido() {
    this.colunasTabelaPedido = [
      {
        field: 'sequencia',
        name: 'Nº Pedido',
      },
      {
        field: 'totalLiquido',
        name: 'Total Líquido',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.valorTemp,
      },
      {
        field: 'situacao',
        name: 'Situação',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.situacaoPedidoTemp,
      },
      {
        field: 'dataHoraEmissao',
        name: 'Data de Emissão',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.dataTemp,
      },
      {
        field: '',
        name: '',
        cellClass: 'text-center',
        width: 150,
        cellTemplate: this.pedidosAcoesTemp,
      },
    ];
  }
  private montarColunasLancamentoParcela() {
    this.colunasTabelaLancamentoParcela = [
      {
        field: 'sequencia',
        name: 'Código',
      },
      {
        field: 'despesa',
        name: 'Tipo',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.despesaTemp,
      },
      {
        field: 'dataVencimento',
        name: 'Vencimento',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.dataTemp,
      },
      {
        field: 'valorTotal',
        name: 'Previsto',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.valorTemp,
      },
      {
        field: 'valorTotalPago',
        name: 'Realizado',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.valorTemp,
      },
      {
        field: 'situacao',
        name: 'Situação',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.situacaoLancamentoParcelaTemp,
      },
      {
        field: 'planoConta',
        name: 'Plano de Conta',
      },
      {
        field: 'numeroDocumento',
        name: 'Documento',
      },
      {
        field: 'contaBancaria',
        name: 'Conta Bancária',
      },
      {
        field: '',
        name: '',
        cellClass: 'text-center',
        width: 80,
        cellTemplate: this.lancamentosParcelaAcoesTemp,
      },
    ];
  }
  async setarPlanoContasCadastrado(planoConta3: PlanoContasNivel3) {
    if (planoConta3.id && planoConta3.planoConta2Id) {
      this.clienteForm.setarPlanoContasCadastrado(planoConta3.id);
    }
  }
  preencherObjeto() {
    const cliente = Cliente.from(this.clienteForm.form.value);
    cliente.toAPI();
    cliente.id = this.clienteForm.operacaoFormAdicionar ?
        this.clienteForm.pessoaId :
        cliente.id;
    return cliente;
  }
  abrirContatoModal(contato?: Contato, index?: number) {
    this.clienteForm.abrirContatoModal(contato, index);
  }
  abrirEnderecoModal(endereco?: Endereco, index?: number) {
    this.clienteForm.abrirEnderecoModal(endereco, index);
  }
  abrirPlanoContasCadastroRapidoModal() {
    this.clienteForm.abrirPlanoContasCadastroRapidoModal();
  }
  abrirSituacaoModal() {
    this.clienteForm.abrirSituacaoModal();
  }
  consultarDocumentoValidoComEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.consultarDocumentoValido();
    }
  }
  consultarDocumentoValido() {
    this.clienteForm.consultarDocumentoValido();
  }
  criarForm() {
    this.clienteForm = new ClienteForm(
        this.apiServicesCoreFacade,
        this.clienteFacade,
        this.clienteSituacaoFacade,
        this.enderecoTipoFacade,
        this.errorMessagesFacade,
        this.pessoaFacade,
        this.parametroFacade,
        this.usuarioFacade,
        this.toastrService,
        this.cepFacade,
    );
    this.clienteForm.criarForm();
    this.clienteForm.monitorarRegimeTributario();
    this.clienteForm.monitorarPessoaTipo();
    this.clienteForm.monitorarFormulario();
  }
  fecharContatoModal() {
    this.clienteForm.fecharContatoModal();
  }
  fecharEnderecoModal() {
    this.clienteForm.fecharEnderecoModal();
  }
  fecharSituacaoModal() {
    this.clienteForm.fecharSituacaoModal();
  }
  fecharPlanoContasCadastroRapidoModal() {
    this.clienteForm.fecharPlanoContasCadastroRapidoModal();
  }
  ngOnInit() {
    this.montarDropdownClienteTipo();
    this.montarDropdownCertificadoTipos();
    this.montarDropdownRegimeTributario();
    this.montarDropdownAtividadeTipo();
    this.montarDropdownIeIndicador();
    this.montarDropdownEstadosIbge();
    this.montarDropdownPessoaTipo();
    this.montarDropdownNumerico(31);
    this.montarColunasTabelaPedido();
    this.montarColunasLancamentoParcela();
    this.criarForm();
  }
  preencherNomeFantasia(event: any) {
    this.clienteForm.preencherNomeFantasia(event.target.value as string);
  }
  recuperaDadosTab(event: any) {
    if (this.clienteForm.operacaoFormEditar) {
      if (event.originalEvent.currentTarget.textContent ===
          this.tituloTabPanelPedidos) {
        this.clienteForm.buscarPedidos();
      } else if (
          event.originalEvent.currentTarget.textContent ===
          this.tituloTabPanelRegistrosFinanceiros) {
        this.clienteForm.buscarRegistrosFinanceiros();
      }
    }
  }
  salvarContato(form: FormGroup<IContatoForm>) {
    this.clienteForm.salvarContato(form);
  }
  salvarEndereco(form: FormGroup<IEnderecoForm>) {
    this.clienteForm.salvarEndereco(form);
  }
  setarClienteSituacaoCadastrada(clienteSituacao: ClienteSituacao) {
    this.clienteForm.setarClienteSituacaoCadastrada(clienteSituacao.id);
  }
  verPedido(row: any) {
    this.router.navigate(['pedidos-vendas/editar', row.id]);
  }
  verLancamentoParcela(row: any) {
    if (row.despesa)
      this.router.navigate(['contas-pagar/editar', row.lancamentoParcelaId]);
    else
      this.router.navigate(['contas-receber/editar', row.lancamentoParcelaId]);
  }
}
