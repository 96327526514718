import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {PessoaFiltro} from '@app/abstraction/domain/interfaces/cadastros/pessoa/pessoa-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-pessoa-busca-rapida',
  templateUrl: './pessoa-busca-rapida.component.html',
})
export class PessoaBuscaRapidaComponent extends ListagemBaseComponent implements
    OnInit {
  @ViewChild('documentoTemp', {static: true}) documentoTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();
  @Output() aoCadastrarPessoa = new EventEmitter<any>();

  mostrarTipoPessoaCadastroModal: boolean;
  mostrarClienteCadastroRapido: boolean;
  mostrarFornecedorCadastroRapido: boolean;
  colunas: TableColumn[];
  filtro = <PessoaFiltro>{};
  itemSelecionado: any;
  get dados$() {
    return this.pessoaFacade.pessoas$;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private pessoaFacade: PessoaFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarPessoasComFiltro() {
    this.pessoaFacade.buscarPessoas({
      limite: this.limite,
      pagina: this.pagina,
      nome: this.filtro?.nome,
      propriedadeOrdem: 'nomeRazaoSocial',
      direcao: 'asc',
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'nomeRazaoSocial',
        name: 'Nome/Razão Social',
      },
      {
        field: 'nomeFantasia',
        name: 'Nome fantasia',
      },
      {
        field: 'documento',
        name: 'Documento',
        cellTemplate: this.documentoTemp,
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro = {};
    this.pessoaFacade.limparListaPessoas();
    this.buscarPessoasComFiltro();
  }
  buscarPessoasProximaPagina() {
    this.pagina++;
    this.buscarPessoasComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.pessoaFacade.limparListaPessoas();
    this.buscarPessoasComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
    this.pessoaFacade.limparListaPessoas();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
  abrirFornecedorCadastroRapidoModal() {
    this.mostrarFornecedorCadastroRapido = true;
  }
  fecharFornecedorCadastroRapidoModal() {
    this.mostrarFornecedorCadastroRapido = false;
  }
  abrirClienteCadastroRapidoModal() {
    this.mostrarClienteCadastroRapido = true;
  }
  fecharClienteCadastroRapidoModal() {
    this.mostrarClienteCadastroRapido = false;
  }
  abrirTipoPessoaCadastroModal() {
    this.mostrarTipoPessoaCadastroModal = true;
  }
  fecharTipoPessoaCadastroModal() {
    this.mostrarTipoPessoaCadastroModal = false;
  }
  setarPessoaCadastrada(event: any) {
    this.aoCadastrarPessoa.emit(event);
    this.fecharTipoPessoaCadastroModal();
  }
  abrirCadastroPessoaSelecionada(tipoPessoa: string) {
    switch (tipoPessoa) {
      case 'cliente':
        this.abrirClienteCadastroRapidoModal();
        break;
      case 'fornecedor':
        this.abrirFornecedorCadastroRapidoModal();
        break;
    }
  }
}
