export class NotaFiscalDuplicata {
  formaPagamentoId: string;
  formaParcelamentoId: string;
  dataVencimento: Date|string;
  numeroOrdem: string;
  valor: number;

  static from(json: any = {}): NotaFiscalDuplicata {
    const duplicata = new NotaFiscalDuplicata();
    duplicata.formaPagamentoId = json.formaPagamentoId;
    duplicata.formaParcelamentoId = json.formaParcelamentoId;
    duplicata.dataVencimento = json.dataVencimento;
    duplicata.numeroOrdem = json.numeroOrdem?.trim();
    duplicata.valor = json.valor;

    return duplicata;
  }
}
