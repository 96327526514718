import {Tela} from './tela.entity';

export class Modulo {
  ativo: boolean;
  descricao: string;
  icone: string;
  id: string;
  rota: string;
  telas: Tela[];

  static from(json: any = {}): Modulo {
    const modulo = new Modulo();
    modulo.ativo = json.ativo;
    modulo.descricao = json.descricao;
    modulo.icone = json.icone;
    modulo.id = json.id;
    modulo.rota = json.rota;
    modulo.telas = json.telas?.map(Tela.from);
    return modulo;
  }
}
