<p-multiSelect
  *ngIf="mostrarMultiSelect"
  appendTo="body"
  styleClass=" inputfield w-full ml-auto"
  optionLabel="name"
  selectedItemsLabel="{0} itens selecionados"
  placeholder="Mostrar colunas"
  emptyMessage="Nenhum item encontrado"
  emptyFilterMessage="Nenhum item encontrado"
  [options]="options"
  [maxSelectedLabels]="1"
  (ngModelChange)="emitirChange($event)"
  [(ngModel)]="model"
>
</p-multiSelect>
