import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RegraFiscal} from '@app/abstraction/domain/entities/fiscal/regra-fiscal/regra-fiscal.entity';
import {RegraFiscalFiltro} from '@app/abstraction/domain/interfaces/fiscal/regra-fiscal/regra-fiscal-filtro.interface';
import {RegraFiscalListagem} from '@app/abstraction/domain/interfaces/fiscal/regra-fiscal/regra-fiscal-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RegraFiscalService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: RegraFiscal) {
    const response$ =
        this.client.post<RegraFiscal>(API.FISCAL_REGRA_FISCAL, dados)
            .pipe(map(
                (json: RegraFiscal) => json ? RegraFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: RegraFiscal, id: string) {
    const response$ =
        this.client.put<RegraFiscal>(`${API.FISCAL_REGRA_FISCAL}/${id}`, dados)
            .pipe(map(
                (json: RegraFiscal) => json ? RegraFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<RegraFiscal>(`${API.FISCAL_REGRA_FISCAL}/${id}`)
            .pipe(map(
                (json: RegraFiscal) => json ? RegraFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: RegraFiscalFiltro) {
    const response$ = this.client.get<RegraFiscalListagem[]>(
        this.geradorQueryString.gerar(`${API.FISCAL_REGRA_FISCAL}`, filtro));
    return lastValueFrom(response$);
  }
}
