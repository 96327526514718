import {NotaFiscalDuplicata} from './duplicata.entity';

export class NotaFiscalCobranca {
  duplicatas: NotaFiscalDuplicata[];
  numeroFaturamento: string;
  valorDesconto: number;
  valorLiquido: number;
  valorOriginal: number;

  static from(json: any = {}): NotaFiscalCobranca {
    const cobranca = new NotaFiscalCobranca();
    cobranca.duplicatas =
        json.duplicatas?.map(
            (d: NotaFiscalDuplicata) => NotaFiscalDuplicata.from(d)) ??
        [];
    cobranca.numeroFaturamento = json.numeroFaturamento?.trim();
    cobranca.valorDesconto = json.valorDesconto;
    cobranca.valorLiquido = json.valorLiquido;
    cobranca.valorOriginal = json.valorOriginal;

    return cobranca;
  }
}
