import {Modulo} from '@app/abstraction/domain/entities/shared/auth/modulo/modulo.entity';

import {Dashboard} from './dashboard.entity';

export class Permissao {
  modulos: Modulo[];
  dashboards: Dashboard[];

  static from(json: any = {}): Permissao {
    const acao = new Permissao();
    acao.modulos = json.modulos?.map(Modulo.from);
    acao.dashboards = json.dashboards?.map(Dashboard.from);
    return acao;
  }
}