import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NaturezaReceita} from '@app/abstraction/domain/entities/cadastros/natureza-receita/natureza-receita.entity';
import {NaturezaReceitaFacade} from '@app/abstraction/domain/facades/cadastros/natureza-receita/natureza-receita.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {NaturezaReceitaForm} from '@app/abstraction/domain/forms/cadastros/natureza-receita.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-natureza-receita-cadastro-rapido-modal',
  templateUrl: './natureza-receita-cadastro-rapido-modal.component.html',
})
export class NaturezaReceitaCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<NaturezaReceita>();
  @Output() aoCancelar = new EventEmitter();

  naturezaReceitaForm: NaturezaReceitaForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get inteiroMask() {
    return this.mascaraService.inteiro();
  }
  constructor(
      private unidadeComercialFacade: NaturezaReceitaFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private mascaraService: MascaraService,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarNaturezaReceita(naturezaReceita: NaturezaReceita) {
    naturezaReceita =
        await this.unidadeComercialFacade.adicionar(naturezaReceita)
            .catch((e: any) => {
              this.saveLoading = false;
              return this.errorMessagesFacade.mostrarMensagens(e);
            });
    return naturezaReceita;
  }
  private criarForm() {
    this.naturezaReceitaForm =
        new NaturezaReceitaForm(this.errorMessagesFacade);
    this.naturezaReceitaForm.criarForm();
    this.naturezaReceitaForm.setarTipoFormulario();
  }
  private preencherObjeto() {
    return NaturezaReceita.from(this.naturezaReceitaForm.form.value);
  }
  private mostrarSucessoAoSalvar(naturezaReceita: NaturezaReceita) {
    this.toastrService.success(`Natureza da receita cadastrada com sucesso`);
    this.aoSalvar.emit(naturezaReceita);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.naturezaReceitaForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const naturezaReceita = this.preencherObjeto();
      const resultado = await this.adicionarNaturezaReceita(naturezaReceita);

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar natureza da receita.');
    } finally {
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  fecharModal() {
    this.naturezaReceitaForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
    this.montarDropdownCstsPisCofins();
  }
}
