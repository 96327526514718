import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Fornecedor} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor.entity';
import {LancamentoParcela} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import {FornecedorFiltro} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-filtro.interface';
import {FornecedorListagem} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FornecedorService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Fornecedor) {
    const response$ =
        this.client.post<Fornecedor>(API.CADASTRO_FORNECEDOR, dados)
            .pipe(map(
                (json: Fornecedor) => (json ? Fornecedor.from(json) : null)));
    return lastValueFrom(response$);
  }
  alterar(dados: Fornecedor, id: string) {
    const response$ =
        this.client.put<Fornecedor>(`${API.CADASTRO_FORNECEDOR}/${id}`, dados)
            .pipe(map(
                (json: Fornecedor) => (json ? Fornecedor.from(json) : null)));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.CADASTRO_FORNECEDOR}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Fornecedor>(`${API.CADASTRO_FORNECEDOR}/${id}`)
            .pipe(map(
                (json: Fornecedor) => (json ? Fornecedor.from(json) : null)));
    return lastValueFrom(response$);
  }
  buscarPorDocumento(documento: any) {
    const response$ =
        this.client
            .get<Fornecedor>(
                `${API.CADASTRO_FORNECEDOR_DOCUMENTO}/${documento}`)
            .pipe(map(
                (json: Fornecedor) => (json ? Fornecedor.from(json) : null)));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: FornecedorFiltro) {
    const response$ = this.client.get<FornecedorListagem[]>(
        this.geradorQueryString.gerar(`${API.CADASTRO_FORNECEDOR}`, filtro));
    return lastValueFrom(response$);
  }
  buscarRegistrosFinanceiros(id: string) {
    const response$ = this.client.get<LancamentoParcela[]>(
        `${API.CADASTRO_FORNECEDOR}/${id}/registros-financeiros`);
    return lastValueFrom(response$);
  }
  excluir(id: string) {
    const response$ =
        this.client.delete<boolean>(`${API.CADASTRO_FORNECEDOR}/${id}`);
    return lastValueFrom(response$);
  }
  vincularProdutoAoFornecedor(
      documento: any, variacaoId: string, codigoReferencia: any) {
    const response$ = this.client.post<FornecedorListagem>(
        `${API.CADASTRO_FORNECEDOR}/${documento}/produto`,
        [{produtoVariacaoId: variacaoId, codigoReferencia}]);
    return lastValueFrom(response$);
  }
}
