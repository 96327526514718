<div class="cartao-endereco" [ngClass]="{ principal: endereco.principal }">
  <div class="icone"><i class="pi pi-map-marker"></i></div>
  <div class="info">
    <div class="flex justify-content-end">
      <p-tag
        [value]="endereco.ativo ? 'Ativo' : 'Inativo'"
        [severity]="endereco.ativo ? 'success' : 'danger'"
      ></p-tag>
    </div>
    <div class="flex flex-column">
      <span class="mb-1">
        Logradouro/Nº: {{ endereco.logradouro }}{{ temLogradouroNumero ? " - " : ""
        }}{{ endereco.numero }}
      </span>
      <span class="mb-1">Bairro: {{ endereco.bairro }}</span>
      <span class="mb-1">
        Cidade/UF: {{ endereco.cidade }}{{ temCidadeUf ? " - " : ""
        }}{{ endereco.ufIbge | estado }}
      </span>
      <span class="mb-1">Cep: {{ endereco.cep | cep }}</span>
    </div>
  </div>
  <div class="flex justify-content-between barra-opcoes cursor-pointer w-full">
    <span>{{ endereco.enderecoTipoId | enderecoTipo }}</span>
    <div>
      <i
        class="pi pi-pencil ml-2 mr-2 float-right"
        tooltipPosition="top"
        pTooltip="Editar endereço"
        (click)="editarClicado()"
      ></i>
      <i
        class="pi pi-star float-right"
        *ngIf="endereco.principal"
        tooltipPosition="top"
        pTooltip="Endereço principal"
      ></i>
    </div>
  </div>
</div>
