<ng-template #decimalTemp let-value="value">
  <span>{{ value | customDecimal : valorDecimais }}</span>
</ng-template>
<ng-template #dataTemp let-value="value">
  <span>{{ value | date : dataFormatoPt }}</span>
</ng-template>

<p-dialog
  header="Contas à renegociar"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharModal()"
>
  <div class="grid">
    <div class="info-grid col-12 xl:col-9 lg:col-9 md:col-12 sm:col-12">
      <span class="titulo">Pessoa</span>
      <span class="texto text-color-tema">{{ pessoaId | pessoaPorId }}</span>
    </div>
  </div>
  <div class="grid">
    <div class="info-grid col-6 xl:col-3 lg:col-3 md:col-4 sm:col-6">
      <span class="titulo">Total pendente</span>
      <span class="texto text-color-tema">{{
        totalPendente | customCurrency : valorDecimais
      }}</span>
    </div>
    <div class="info-grid col-6 xl:col-3 lg:col-3 md:col-4 sm:col-6">
      <span class="titulo">Total pago</span>
      <span class="texto text-color-tema">{{
        totalPago | customCurrency : valorDecimais
      }}</span>
    </div>
    <div class="info-grid col-6 xl:col-3 lg:col-3 md:col-4 sm:col-6">
      <span class="titulo">Total selecionado</span>
      <span class="texto text-color-tema">{{
        totalSelecionado | customCurrency : valorDecimais
      }}</span>
    </div>
  </div>
  <p-button
    label="Renegociar contas"
    (onClick)="abrirCalcularParcelasModal()"
    styleClass="btn-five my-3"
  ></p-button>
  <app-table [columns]="colunasContas" [rows]="contasSelecionadas"> </app-table>
  <ng-template #acoesTemp let-value="value" let-rowIndex="rowIndex">
    <p-button
      tooltipPosition="bottom"
      pTooltip="Remover conta"
      icon="pi pi-trash"
      [text]="true"
      [plain]="true"
      (onClick)="abrirConfirmacaoRemoverConta(rowIndex)"
    ></p-button>
  </ng-template>
  <p-confirmDialog
    rejectButtonStyleClass="p-button-text"
    key="confirmacao-modal"
  >
  </p-confirmDialog>
</p-dialog>

<!-- <p-dialog
  header="Calcular parcelas"
  [style]="{ width: isDesktop ? '75%' : '90%', height: '85%' }"
  [(visible)]="mostrarCalculoParcelasModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharCalcularParcelasModal()"
  (onShow)="aoAbrirCalculoParcelasModal()"
  [formGroup]="form"
>
  <div class="formgrid grid">
    <div class="field col-12 xl:col-3 lg:col-2 md:col-4 sm:col-6">
      <app-custom-label
        texto="Forma de parcelamento"
        [cadastroRapido]="true"
        [obrigatorio]="true"
        (aoClicarAdicionar)="abrirFormaParcelamentoCadastroRapidoModal()"
      ></app-custom-label>
      <p-dropdown
        appendTo="body"
        [options]="formasParcelamento"
        placeholder="Selecione"
        optionLabel="descricao"
        optionValue="id"
        emptyMessage="Nenhum item encontrado"
        emptyFilterMessage="Nenhum item encontrado"
        [showClear]="true"
        [filter]="true"
        styleClass=" inputfield w-full"
        formControlName="formaParcelamentoId"
        [ngClass]="{
          'ng-invalid ng-dirty': verificarCampoInvalido(
            form,
            'formaParcelamentoId'
          )
        }"
      >
      </p-dropdown>
      <small
        *ngIf="verificarCampoInvalido(form, 'formaParcelamentoId', 'required')"
        class="p-error"
        >{{ buscarMensagemErro(form, "formaParcelamentoId", "required") }}<br
      /></small>
    </div>
    <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6">
      <app-custom-label texto="Taxa de juros (%)" [obrigatorio]="false">
      </app-custom-label>
      <input
        type="text"
        class=" inputfield w-full"
        pInputText
        formControlName="taxaPercentual"
        currencyMask
        [options]="valorMask"
        [placeholder]="valorPlaceholder"
      />
    </div>
    <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6">
      <app-custom-label texto="Data de vencimento" [obrigatorio]="true">
      </app-custom-label>
      <p-calendar
        appendTo="body"
        appCalendarMask
        yearRange="1950:2050"
        dateFormat="dd/mm/yy"
        styleClass=" inputfield w-full"
        formControlName="dataVencimento"
        [showOnFocus]="false"
        [keepInvalid]="true"
        [yearNavigator]="true"
        [monthNavigator]="true"
        [showIcon]="true"
        [ngClass]="{
          'ng-invalid ng-dirty': verificarCampoInvalido(form, 'dataVencimento')
        }"
      >
      </p-calendar>
      <small
        *ngIf="verificarCampoInvalido(form, 'dataVencimento', 'required')"
        class="p-error"
        >{{ buscarMensagemErro(form, "dataVencimento", "required") }}<br
      /></small>
      <small
        *ngIf="verificarCampoInvalido(form, 'dataVencimento', 'dataInvalida')"
        class="p-error"
        >{{ buscarMensagemErro(form, "dataVencimento", "dataInvalida") }}<br
      /></small>
    </div>
    <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6">
      <app-custom-label texto="Nº do documento" [obrigatorio]="true">
      </app-custom-label>
      <input
        type="text"
        class=" inputfield w-full"
        pInputText
        formControlName="numeroDocumento"
        currencyMask
        [options]="inteiroMask"
        [ngClass]="{
          'ng-invalid ng-dirty': verificarCampoInvalido(form, 'numeroDocumento')
        }"
      />
      <small
        *ngIf="verificarCampoInvalido(form, 'numeroDocumento', 'required')"
        class="p-error"
        >{{ buscarMensagemErro(form, "numeroDocumento", "required") }}<br
      /></small>
    </div>
  </div>
  <div class="formgroup-inline">
    <div class="field-checkbox">
      <p-checkbox
        formControlName="valorCorrigidoDia"
        inputId="valorCorrigidoDia"
        [binary]="true"
      ></p-checkbox>
      <label for="valorCorrigidoDia">Valor corrigido do dia</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox
        formControlName="jurosSobreJuros"
        inputId="jurosSobreJuros"
        [binary]="true"
      ></p-checkbox>
      <label for="jurosSobreJuros">Juros sobre juros</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox
        formControlName="valorParcelasIguais"
        inputId="valorParcelasIguais"
        [binary]="true"
      ></p-checkbox>
      <label for="valorParcelasIguais">Igualar valor parcelas</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox
        formControlName="dataVencimentoFixo"
        inputId="dataVencimentoFixo"
        [binary]="true"
      ></p-checkbox>
      <label for="dataVencimentoFixo">Vencimento fixo (Dia)</label>
    </div>
  </div>
  <div class="flex justify-content-end my-2">
    <div class="info-grid mx-4">
      <span class="titulo">Total dívida ant. (Pendente)</span>
      <span class="texto text-color-tema">{{
        totalDividaAnterior | customCurrency: valorDecimais
      }}</span>
    </div>
    <div class="info-grid mx-4">
      <span class="titulo">Total dívida atual</span>
      <span class="texto text-color-tema">{{
        totalParcelas | customCurrency: valorDecimais
      }}</span>
    </div>
  </div>
  <app-table [columns]="colunasParcelas" [rows]="parcelas"> </app-table>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharCalcularParcelasModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="carregandoRenegociacao"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Renegociar"
      styleClass="btn-five"
      [loading]="carregandoRenegociacao"
    >
    </p-button>
  </ng-template>
</p-dialog> -->

<app-forma-parcelamento-cadastro-rapido-modal
  [mostrarModal]="mostarFormaParcelamentoCadastroRapidoModal"
  (aoCancelar)="fecharFormaParcelamentoCadastroRapidoModal()"
  (aoSalvar)="setarFormaParcelamentoCadastrada($event)"
></app-forma-parcelamento-cadastro-rapido-modal>
