export class ProdutoOrigemConst {
  constructor(_codigo: string, _descricao: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
  }
  codigo: string;
  descricao: string;
}

export const ProdutoOrigens = [
  new ProdutoOrigemConst('0', 'NACIONAL'),
  new ProdutoOrigemConst('1', 'ESTRANGEIRO - IMPORTACAO DIRETO'),
  new ProdutoOrigemConst('2', 'ESTRANGEIRO - ADQ. MERCADO INTERNO'),
  new ProdutoOrigemConst(
    '3',
    'NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%'
  ),
  new ProdutoOrigemConst(
    '4',
    'NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS DE QUE TRATAM O DECRETO-LEI Nº 288/67, E AS LEIS NºS 8.248/91, 8.387/91, 10.176/01 E 11.484/07'
  ),
  new ProdutoOrigemConst(
    '5',
    'NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%'
  ),
  new ProdutoOrigemConst(
    '6',
    'ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL, CONSTANTE EM LISTA DE RESOLUCAO CAMEX'
  ),
  new ProdutoOrigemConst(
    '7',
    'ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL, CONSTANTE EM LISTA DE RESOLUCAO CAMEX'
  ),
];
