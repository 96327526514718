import {AuthConfig} from 'angular-oauth2-oidc';

import {Environment} from './interface/enviroment.interface';

export const environment: Environment = {
  hmr: false,
  version: (window as any).env.version,
  production: (window as any).env.production === 'true',
  apiBaseUrl: (window as any).env.apiBaseUrl,
  authBaseUrl: (window as any).env.authBaseUrl,
  fiscalBaseUrl: (window as any).env.fiscalBaseUrl,
  dataAtualizacao: (window as any).env.dataAtualizacao,
  authConfig: <AuthConfig>{
    clientId: (window as any).env.authConfig.clientId,
    postLogoutRedirectUri: (window as any).env.authConfig.postLogoutRedirectUri,
    redirectUri: (window as any).env.authConfig.redirectUri,
    responseType: (window as any).env.authConfig.responseType,
    scope: (window as any).env.authConfig.scope,
    oidc: (window as any).env.authConfig.oidc === 'true',
    showDebugInformation:
        (window as any).env.authConfig.showDebugInformation === 'true',
    strictDiscoveryDocumentValidation:
        (window as any).env.authConfig.strictDiscoveryDocumentValidation ===
        'true',
    tokenEndpoint: (window as any).env.authConfig.tokenEndpoint,
  },
};
