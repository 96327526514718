import {FormControl, FormGroup} from '@angular/forms';
import {Endereco} from '@app/abstraction/domain/entities/shared/endereco.entity';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface IEnderecoForm {
  ativo: FormControl<boolean>;
  bairro: FormControl<string>;
  cep: FormControl<string>;
  cidade: FormControl<string>;
  cidadeIbge: FormControl<number>;
  complemento: FormControl<string>;
  principal: FormControl<boolean>;
  enderecoTipoId: FormControl<string>;
  ie: FormControl<string>;
  ieIndicador: FormControl<number>;
  logradouro: FormControl<string>;
  numero: FormControl<string>;
  pais: FormControl<string>;
  paisIbge: FormControl<number>;
  uf: FormControl<string>;
  ufIbge: FormControl<number>;
}

export class EnderecoForm {
  static from(endereco?: Endereco) {
    return new FormGroup<IEnderecoForm>({
      principal: new FormControl(endereco?.principal ?? false),
      enderecoTipoId: new FormControl(endereco?.enderecoTipoId),
      cep: new FormControl(
          endereco?.cep ?? '',
          [
            CustomValidators.minLength(8, 'CEP inválido'),
          ]),
      logradouro: new FormControl(endereco?.logradouro ?? ''),
      numero: new FormControl(endereco?.numero ?? ''),
      bairro: new FormControl(endereco?.bairro ?? ''),
      cidade: new FormControl(endereco?.cidade ?? ''),
      cidadeIbge: new FormControl(endereco?.cidadeIbge ?? 0),
      uf: new FormControl(endereco?.uf ?? ''),
      ufIbge: new FormControl(endereco?.ufIbge),
      pais: new FormControl(endereco?.pais ?? ''),
      paisIbge: new FormControl(endereco?.paisIbge ?? 1058),
      ie: new FormControl(endereco?.ie ?? ''),
      ieIndicador: new FormControl(endereco?.ieIndicador ?? 0),
      complemento: new FormControl(endereco?.complemento ?? ''),
      ativo: new FormControl(endereco?.ativo ?? true),
    });
  }
}
