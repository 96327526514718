import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ClienteFacade} from '@app/abstraction/domain/facades/cadastros/cliente/cliente.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ClienteFiltro} from '@app/abstraction/domain/interfaces/cadastros/cliente/cliente-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cliente-busca-rapida',
  templateUrl: './cliente-busca-rapida.component.html',
})
export class ClienteBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('documentoTemp', {static: true}) documentoTemp: TemplateRef<any>;
  @ViewChild('foneTemp', {static: true}) foneTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Input() filtro = <ClienteFiltro>{};
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  itemSelecionado: any;
  mostrarClienteCadastroRapidoModal: boolean;
  get dados$() {
    return this.clienteFacade.clientes$;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private clienteFacade: ClienteFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarClientesComFiltro() {
    this.clienteFacade.buscarClientes({
      pagina: this.pagina,
      limite: this.limite,
      nome: this.filtro.nome,
      ativo: this.filtro.ativo,
      numeroTelefone: this.filtro.numeroTelefone,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'nomeRazaoSocial',
        name: 'Nome/Razão Social',
      },
      {
        field: 'nomeFantasia',
        name: 'Nome fantasia',
      },
      {
        field: 'documento',
        name: 'Documento',
        cellTemplate: this.documentoTemp,
      },
      {
        field: 'whatsApp',
        name: 'WhatsApp',
        cellTemplate: this.foneTemp,
      },
      {
        field: 'telefoneFixo',
        name: 'Telefone',
        cellTemplate: this.foneTemp,
      },
      {
        field: 'ativo',
        name: 'Situação',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.situacaoTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro.nome = null;
    this.clienteFacade.limparListaClientes();
    this.buscarClientesComFiltro();
  }
  abrirClienteCadastroRapidoModal() {
    this.mostrarClienteCadastroRapidoModal = true;
  }
  buscarClientesProximaPagina() {
    this.pagina++;
    this.buscarClientesComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.clienteFacade.limparListaClientes();
    this.buscarClientesComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
    this.clienteFacade.limparListaClientes();
    this.limparPaginacao();
  }
  fecharClienteCadastroRapidoModal() {
    this.mostrarClienteCadastroRapidoModal = false;
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
  selecionarClienteCadastrado(evento: any) {
    this.setarItemSelecionado(evento);
    this.selecionar();
  }
}
