import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Produto} from '@app/abstraction/domain/entities/produtos/produto/produto.entity';
import {MovimentacaoListagem} from '@app/abstraction/domain/interfaces/estoque/movimentacao/movimentacao-listagem.interface';
import {ProdutoFiltro} from '@app/abstraction/domain/interfaces/produtos/produto/produto-filtro.interface';
import {ProdutoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-listagem.interface';
import {ProdutoQuantidade} from '@app/abstraction/domain/interfaces/produtos/produto/produto-quantidade.interface';
import {ProdutoSimplificadoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-simplificado-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProdutoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Produto) {
    const response$ =
        this.client.post<Produto>(API.PRODUTO, dados)
            .pipe(map((json: Produto) => (json ? Produto.from(json) : null)));
    return lastValueFrom(response$);
  }
  alterar(dados: Produto, id: string) {
    const response$ =
        this.client.put<Produto>(`${API.PRODUTO}/${id}`, dados)
            .pipe(map((json: Produto) => (json ? Produto.from(json) : null)));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.PRODUTO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Produto>(`${API.PRODUTO}/${id}`)
            .pipe(map((json: Produto) => (json ? Produto.from(json) : null)));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: ProdutoFiltro) {
    const response$ = this.client.get<ProdutoListagem[]>(
        this.geradorQueryString.gerar(`${API.PRODUTO}`, filtro));
    return lastValueFrom(response$);
  }
  buscarMovimentacoes(id: string) {
    const response$ = this.client.get<MovimentacaoListagem[]>(
        `${API.PRODUTO}/${id}/movimentacoes`);
    return lastValueFrom(response$);
  }
  buscarTodosSimplificados(filtro?: ProdutoFiltro) {
    const response$ = this.client.get<ProdutoSimplificadoListagem[]>(
        this.geradorQueryString.gerar(`${API.PRODUTO}/simplificados`, filtro));
    return lastValueFrom(response$);
  }
  buscarProdutoPorProdutoVariacaoId(variacaoId: string) {
    const response$ =
        this.client.get<Produto[]>(`${API.PRODUTO_VARIACAO}/${variacaoId}`);
    return lastValueFrom(response$);
  }
  buscarEanDisponivel(quantidade: number) {
    const response$ = this.client.get<string[]>(
        `${API.PRODUTO_EAN_DISPONIVEL}/${quantidade}`);
    return lastValueFrom(response$);
  }
  buscarEanDisponivelProdutoPesado() {
    const response$ =
        this.client.get<string[]>(`${API.PRODUTO_PESADO_EAN_DISPONIVEL}`);
    return lastValueFrom(response$);
  }
  buscarTotalProdutos() {
    const response$ =
        this.client.get<ProdutoQuantidade>(`${API.PRODUTO_QUANTIDADE}`);
    return lastValueFrom(response$);
  }
  copiarEstoques(empresaOrigemId: string, empresaDestinoId: string) {
    const response$ = this.client.put<Produto[]>(`${API.ESTOQUE_COPIAR}`, {
      empresaOrigemId,
      empresaDestinoId,
    });
    return lastValueFrom(response$);
  }
  copiarPrecos(empresaOrigemId: string, empresaDestinoId: string) {
    const response$ = this.client.put<Produto[]>(`${API.PRECO_COPIAR}`, {
      empresaOrigemId,
      empresaDestinoId,
    });
    return lastValueFrom(response$);
  }
  excluirProduto(id: string) {
    const response$ = this.client.delete<Produto>(`${API.PRODUTO}/${id}`);
    return lastValueFrom(response$);
  }
  downloadPlanilha() {
    const response$ = this.client.get(`${API.PRODUTO_DOWNLOAD_PLANILHA}`, {
      responseType: 'blob',
    });
    return lastValueFrom(response$);
  }
  importarPlanilha(fileBase64: string) {
    const response$ = this.client.post<ProdutoListagem[]>(
        `${API.PRODUTO_IMPORTAR_PLANILHA}`, fileBase64);
    return lastValueFrom(response$);
  }
  gerarCodigosSku(quantidade: number, descricao: string) {
    const response$ = this.client.get<string[]>(
        `${API.PRODUTO_SKU_DISPONIVEL}/${quantidade}/${descricao}`);
    return lastValueFrom(response$);
  }
}
