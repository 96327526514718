export enum ProdutoVendidoPor {
  Nenhum,
  Unidade,
  Peso,
}

export const ProdutoVendidoPorLabels = new Map<number, string>([
  [ProdutoVendidoPor.Unidade, 'Venda por unidade'],
  [ProdutoVendidoPor.Peso, 'Venda por peso'],
]);
