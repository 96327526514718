import {Pipe, PipeTransform} from '@angular/core';
import {MarcaFacade} from '@app/abstraction/domain/facades/produtos/marca/marca.facade';
import {MarcaListagem} from '@app/abstraction/domain/interfaces/produtos/marca/marca-listagem.interface';

@Pipe({
  name: 'marca',
})
export class MarcaPipe implements PipeTransform {
  constructor(private marcaFacade: MarcaFacade) {}
  transform(marcaId: string, campo: string = 'descricao'): any {
    if (marcaId) {
      const marca = this.marcaFacade.marcas?.find(
                        (marca: MarcaListagem) => marca.id == marcaId) as any;
      return marca?.[campo];
    }
    return '';
  }
}
