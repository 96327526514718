import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CarteiraListagem} from '@app/abstraction/domain/interfaces/financeiro/carteira/carteira-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom} from 'rxjs';


@Injectable({providedIn: 'root'})
export class CarteiraService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  buscarTodas() {
    const response$ = this.client.get<CarteiraListagem[]>(
        this.geradorQueryString.gerar(`${API.FINANCEIRO_CARTEIRA}`, null));
    return lastValueFrom(response$);
  }
}
