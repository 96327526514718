<ng-template #documentoTemp let-value="value">
  <span>{{ value | documento }}</span>
</ng-template>
<ng-template #ordemTemp let-value="value">
  <p-tag
    *ngIf="verificarPrimeiraImagem(value)"
    value="Capa"
    severity="success"
  ></p-tag>
  <span *ngIf="!verificarPrimeiraImagem(value)">{{ value }}</span>
</ng-template>
<ng-template #variacaoTemp let-rowData="rowData">
  <div class="flex justify-content-between">
    <span *ngIf="rowData.tamanhoId">{{ rowData.tamanhoId | tamanho }}</span>
    <span *ngIf="rowData.corId">{{ rowData.corId | cor : "descricao" }}</span>
    <span *ngIf="rowData.referenciaId">{{
      rowData.referenciaId | referencia
    }}</span>
    <div
      *ngIf="rowData.corId"
      class="caixa-cor"
      [style.background]="rowData.corId | cor"
      [pTooltip]="rowData.corId | cor"
      tooltipPosition="bottom"
    ></div>
  </div>
</ng-template>
<ng-template #valorVendaTemp let-rowIndex="rowIndex">
  <div class="flex justify-content-between">
    <span>{{
      buscarValorVendaVariacao(rowIndex) | customCurrency : valorDecimais
    }}</span>
  </div>
</ng-template>
<ng-template #dataTemp let-value="value">
  <span>{{ value | date : dataFormatoPt }}</span>
</ng-template>
<ng-template
  #acoesFornecedorProdutoTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Editar"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="abrirFornecedorProdutoModal(rowData, rowIndex)"
  ></p-button>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Excluir"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="abrirConfirmacaoExcluisaoFornecedorProduto(rowIndex)"
  ></p-button>
</ng-template>
<ng-template
  #acoesProdutoVariacaoTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Editar"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="abrirVariacaoModal(rowData, rowIndex)"
  ></p-button>
  <p-button
    *ngIf="!rowData?.id"
    tooltipPosition="bottom"
    pTooltip="Excluir"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="abrirConfirmacaoExcluisaoProdutoVariacao(rowIndex)"
  ></p-button>
</ng-template>
<ng-template
  #acoesProdutoVariacaoPrecoTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Editar"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="abrirPrecoModal(rowData, rowIndex)"
  ></p-button>
  <p-button
    *ngIf="!rowData?.id"
    tooltipPosition="bottom"
    pTooltip="Excluir"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="abrirConfirmacaoExclusaoProdutoVariacaoPreco(rowIndex)"
  ></p-button>
</ng-template>
<ng-template
  #acoesProdutoVariacaoEstoqueTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Editar"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="abrirEstoqueModal(rowData, rowIndex)"
  ></p-button>
  <p-button
    *ngIf="!rowData?.id"
    tooltipPosition="bottom"
    pTooltip="Excluir"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="
      abrirConfirmacaoExcluisaoProdutoVariacaoEstoque(rowData, rowIndex)
    "
  ></p-button>
</ng-template>
<ng-template
  #acoesProdutoVariacaoFotoTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Excluir"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="abrirConfirmacaoExcluisaoProdutoVariacaoFoto(rowIndex)"
  ></p-button>
</ng-template>
<ng-template #porcentagemDecimalTemp let-value="value">
  <span>{{ value | customDecimal : porcentagemDecimais }}</span>
</ng-template>
<ng-template #quantidadeDecimalTemp let-value="value">
  <span>{{ value | customDecimal : quantidadeDecimais }}</span>
</ng-template>
<ng-template #valorDecimalTemp let-value="value">
  <span>{{ value | customDecimal : valorDecimais }}</span>
</ng-template>
<ng-template #empresaTemp let-value="value">
  <span>{{ value | empresa : "nomeFantasia" : true }}</span>
</ng-template>
<ng-template #areaEstoqueTemp let-value="value">
  <span>{{ value | areaEstoque }}</span>
</ng-template>
<ng-template #fotoTemp let-value="value" let-rowIndex="rowIndex">
  <div class="item">
    <div class="image-container">
      <img [src]="buscarFoto(rowIndex)" class="image" />
    </div>
  </div>
</ng-template>

<p-tabView [scrollable]="true" data-cy="tabview-dados-cadastrais">
  <p-tabPanel header="Dados Cadastrais" *ngIf="produtoForm && produtoForm.form">
    <div class="formgrid grid">
      <div class="field col-12 col-12 xl:col-5 lg:col-5 md:col-4 sm:col-">
        <app-custom-label texto="Nome do produto" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [autoFocus]="true"
          [control]="produtoForm?.form.controls.descricao"
          [maxlength]="60"
          (inputBlur)="preencherDescricaoReduzida($event)"
        >
        </app-text-input>
      </div>

      <div class="field col-12 col-12 xl:col-4 lg:col-4 md:col-4 sm:col-">
        <app-custom-label
          texto="Nome do produto reduzido"
          [obrigatorio]="true"
          info="Nome reduzido é utilizado na impressão de cupom 80mm, SAT e NFC-e."
        >
        </app-custom-label>
        <app-text-input
          [control]="produtoForm?.form.controls.descricaoReduzida"
          [maxlength]="35"
          (inputBlur)="gerarCodigosSku()"
        ></app-text-input>
      </div>

      <div class="field col-12 col-12 xl:col-3 lg:col-3 md:col-4 sm:col-">
        <app-custom-label texto="Tipo de produto" [obrigatorio]="true">
        </app-custom-label>
        <app-dropdown-input
          placeholder="Selecione"
          [control]="produtoForm?.form.controls.produtoTipo"
          [options]="produtoVariacaoTipos"
        ></app-dropdown-input>
      </div>

      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6"
        *ngIf="produtoTipoSimples"
      >
        <app-custom-label texto="Código de barras"></app-custom-label>
        <app-text-input
          [maxlength]="20"
          [mask]="maskCodigoBarras"
          [control]="
            produtoForm?.form.controls.produtoVariacaoSimples.controls
              .codigoBarras
          "
        ></app-text-input>
      </div>

      <!-- #region SKU -->
      <div
        class="field col-12 xl:col-3 lg:col-3 md:col-6"
        *ngIf="produtoTipoSimples"
      >
        <app-custom-label texto="SKU"></app-custom-label>
        <app-text-input
          [control]="
            produtoForm?.form.controls.produtoVariacaoSimples.controls.sku
          "
          [maxlength]="10"
        ></app-text-input>
      </div>

      <div class="field col-12 col-12 xl:col-3 lg:col-3 md:col-4 sm:col-4">
        <app-custom-label
          texto="Produto vendido por?"
          info="Este campo será utilizado na exportação da carga para balança."
        ></app-custom-label>
        <app-dropdown-input
          [control]="produtoForm?.form.controls.produtoVendidoPor"
          [options]="produtoVendidoPor"
        ></app-dropdown-input>
      </div>

      <div class="field col-12 col-12 xl:col-2 lg:col-2 md:col-4 sm:col-4">
        <app-custom-label
          texto="Código sistema"
          info="Este código é gerado automaticamente pelo sistema, é o identificador interno de localização."
        ></app-custom-label>
        <app-text-input
          [control]="produtoForm?.form.controls.codigoSistema"
          [readonly]="true"
        ></app-text-input>
      </div>

      <!-- #region CODIGO RAPIDO -->
      <div class="field col-12 col-12 xl:col-2 lg:col-2 md:col-4 sm:col-4">
        <app-custom-label
          texto="Código rápido"
          info="Este código é utilizado para buscas rápidas no PDV off-line e online."
        ></app-custom-label>
        <app-text-input
          [control]="produtoForm?.form.controls.codigoRapido"
          [maxlength]="20"
        ></app-text-input>
      </div>

      <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-12">
        <app-custom-label
          texto="Marca"
          [cadastroRapido]="true"
          (aoClicarAdicionar)="abrirMarcaCadastroRapidoModal()"
        >
        </app-custom-label>
        <app-dropdown-input
          optionLabel="descricao"
          optionValue="id"
          [control]="produtoForm?.form.controls.marcaId"
          [options]="marcas$ | async"
          [showFilter]="true"
          [showClear]="true"
        ></app-dropdown-input>
      </div>

      <div class="field col-12 col-12 xl:col-3 lg:col-3 md:col-4 sm:col-4">
        <app-custom-label texto="Modelo"></app-custom-label>
        <app-text-input
          [control]="produtoForm?.form.controls.modelo"
          [maxlength]="50"
        ></app-text-input>
      </div>
    </div>

    <div class="formgrid grid">
      <div class="col-6 xl:col-2 md:col-3 mt-0">
        <app-checkbox-input
          label="Ativo"
          controlName="ativo"
          [control]="produtoForm?.form.controls.ativo"
        >
        </app-checkbox-input>
      </div>
    </div>

    <div class="formgrid grid justify-content-end">
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        *ngIf="mostrarDataCriacao"
      >
        <app-custom-label texto="Data de Criação"></app-custom-label>
        <app-calendar-input
          [control]="produtoForm.form.controls?.createdOn"
          [showTime]="true"
          [showSeconds]="true"
          [readonly]="true"
        ></app-calendar-input>
      </div>
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        *ngIf="mostrarDataAlteracao"
      >
        <app-custom-label texto="Data de Alteração"></app-custom-label>
        <app-calendar-input
          [control]="produtoForm.form.controls?.lastModifiedOn"
          [showTime]="true"
          [showSeconds]="true"
          [readonly]="true"
        ></app-calendar-input>
      </div>
    </div>

    <p-tabView
      [scrollable]="true"
      data-cy="tabview-dados-produto"
      (onChange)="recuperaDadosTab($event)"
    >
      <p-tabPanel header="Preços" *ngIf="produtoTipoSimples">
        <div class="flex justify-content-between mb-2">
          <p-button
            styleClass="btn-five"
            label="Novo preço"
            tooltipPosition="bottom"
            pTooltip="Novo preço"
            icon="pi pi-plus"
            data-cy="btn-novo-preco"
            (onClick)="abrirPrecoModal()"
          >
          </p-button>
        </div>
        <div class="borda-tabela">
          <app-table
            [columns]="colunasTabelaProdutoVariacaoPreco"
            [rows]="produtoVariacaoSimplesPreco"
          >
            <ng-container
              *ngTemplateOutlet="acoesProdutoVariacaoPrecoTemp"
            ></ng-container>
          </app-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Estoques" *ngIf="produtoTipoSimples">
        <p-accordion>
          <p-accordionTab header="Estoques" [selected]="true">
            <div class="flex justify-content-between mb-2">
              <p-button
                styleClass="btn-five"
                label="Novo estoque"
                tooltipPosition="bottom"
                pTooltip="Novo estoque"
                icon="pi pi-plus"
                data-cy="btn-novo-estoque"
                (onClick)="abrirEstoqueModal()"
              >
              </p-button>
            </div>
            <div class="borda-tabela">
              <app-table
                [columns]="colunasTabelaVariacaoEstoque"
                [rows]="produtoVariacaoSimplesEstoque"
              ></app-table>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Pesos e dimensões">
            <div class="grid mt-3">
              <div class="col-12 lg:col-3">
                <div class="formgrid grid">
                  <div
                    class="field flex-column col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12"
                  >
                    <img
                      src="assets/imagens/caixa.png"
                      alt="caixa_com_dimensoes"
                      [width]="200"
                      [height]="150"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 lg:col-9">
                <div class="formgrid grid">
                  <div
                    class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                  >
                    <app-custom-label texto="Altura"></app-custom-label>
                    <app-textgroup-input
                      [control]="
                        produtoVariacaoSimplesDimensionamentoForm.controls
                          .altura
                      "
                      addon="cm"
                      [placeholder]="quantidadePlaceholder"
                      [options]="quantidadeMask"
                    ></app-textgroup-input>
                  </div>
                  <div
                    class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                  >
                    <app-custom-label texto="Largura"></app-custom-label>
                    <app-textgroup-input
                      [control]="
                        produtoVariacaoSimplesDimensionamentoForm.controls
                          .largura
                      "
                      addon="cm"
                      [placeholder]="quantidadePlaceholder"
                      [options]="quantidadeMask"
                    ></app-textgroup-input>
                  </div>
                  <div
                    class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                  >
                    <app-custom-label texto="Profundidade"></app-custom-label>
                    <app-textgroup-input
                      [control]="
                        produtoVariacaoSimplesDimensionamentoForm.controls
                          .profundidade
                      "
                      addon="cm"
                      [placeholder]="quantidadePlaceholder"
                      [options]="quantidadeMask"
                    ></app-textgroup-input>
                  </div>
                  <div
                    class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                  >
                    <app-custom-label texto="Volumes"></app-custom-label>
                    <app-text-input
                      [control]="
                        produtoVariacaoSimplesDimensionamentoForm.controls
                          .volumes
                      "
                    ></app-text-input>
                  </div>
                  <div
                    class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                  >
                    <app-custom-label texto="Peso Líquido"></app-custom-label>
                    <app-textgroup-input
                      [control]="
                        produtoVariacaoSimplesDimensionamentoForm.controls
                          .pesoLiquido
                      "
                      addon="kg"
                      [placeholder]="quantidadePlaceholder"
                      [options]="quantidadeMask"
                    ></app-textgroup-input>
                  </div>
                  <div
                    class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                  >
                    <app-custom-label texto="Peso Bruto"></app-custom-label>
                    <app-textgroup-input
                      [control]="
                        produtoVariacaoSimplesDimensionamentoForm.controls
                          .pesoBruto
                      "
                      addon="kg"
                      [placeholder]="quantidadePlaceholder"
                      [options]="quantidadeMask"
                    ></app-textgroup-input>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </p-tabPanel>
      <p-tabPanel header="Variações" *ngIf="produtoTipoVariacao">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-3 lg:col-6 md:col-6 sm:col-12">
            <app-custom-label
              texto="Tamanhos"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirTamanhoCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-multiselect-input-ngmodel
              optionLabel="descricao"
              optionValue="id"
              display="chip"
              [options]="produtoVariacaoTamanhos$ | async"
              [showClear]="true"
              [(model)]="produtoForm.tamanhos"
            ></app-multiselect-input-ngmodel>
          </div>
          <div class="field col-12 xl:col-3 lg:col-6 md:col-6 sm:col-12">
            <app-custom-label
              texto="Cores"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirCorCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-multiselect-input-ngmodel
              optionLabel="descricao"
              optionValue="id"
              display="chip"
              [options]="produtoVariacaoCores$ | async"
              [showClear]="true"
              [(model)]="produtoForm.cores"
              [templateCor]="true"
            ></app-multiselect-input-ngmodel>
          </div>
          <div class="field col-12 xl:col-3 lg:col-6 md:col-6 sm:col-12">
            <app-custom-label
              texto="Referências"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirReferenciaCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-multiselect-input-ngmodel
              optionLabel="descricao"
              optionValue="id"
              display="chip"
              [options]="produtoVariacaoReferencias$ | async"
              [showClear]="true"
              [(model)]="produtoForm.referencias"
            ></app-multiselect-input-ngmodel>
          </div>
          <div class="field col-12 xl:col-3 lg:col-6 md:col-6 sm:col-12">
            <app-custom-label texto="Valor de Venda (R$)"></app-custom-label>
            <app-numeric-input-ngmodel
              [(model)]="this.produtoForm.precoVenda"
              [options]="valorMask"
            ></app-numeric-input-ngmodel>
          </div>
        </div>
        <p-accordion>
          <p-accordionTab header="Mais Opções">
            <p-tabView>
              <p-tabPanel header="Pesos e dimensões">
                <div class="grid mt-3">
                  <div class="col-12 lg:col-3">
                    <div class="formgrid grid">
                      <div
                        class="field flex-column col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12"
                      >
                        <img
                          src="assets/imagens/caixa.png"
                          alt="caixa_com_dimensoes"
                          [width]="200"
                          [height]="150"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 lg:col-9">
                    <div class="formgrid grid">
                      <div
                        class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                      >
                        <app-custom-label texto="Altura"></app-custom-label>
                        <div class="p-inputgroup">
                          <app-numeric-input-ngmodel
                            [(model)]="this.produtoForm.dimensionamento.altura"
                            [placeholder]="quantidadePlaceholder"
                            [options]="quantidadeMask"
                            class="w-full textgroup-input"
                          />
                          <span class="p-inputgroup-addon">cm</span>
                        </div>
                      </div>
                      <div
                        class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                      >
                        <app-custom-label texto="Largura"></app-custom-label>
                        <div class="p-inputgroup">
                          <app-numeric-input-ngmodel
                            [(model)]="this.produtoForm.dimensionamento.largura"
                            [placeholder]="quantidadePlaceholder"
                            [options]="quantidadeMask"
                            class="w-full textgroup-input"
                          />
                          <span class="p-inputgroup-addon">cm</span>
                        </div>
                      </div>
                      <div
                        class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                      >
                        <app-custom-label
                          texto="Profundidade"
                        ></app-custom-label>
                        <div class="p-inputgroup">
                          <app-numeric-input-ngmodel
                            [(model)]="
                              this.produtoForm.dimensionamento.profundidade
                            "
                            [placeholder]="quantidadePlaceholder"
                            [options]="quantidadeMask"
                            class="w-full textgroup-input"
                          />
                          <span class="p-inputgroup-addon">cm</span>
                        </div>
                      </div>
                      <div
                        class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                      >
                        <app-custom-label texto="Volumes"></app-custom-label>
                        <app-text-input-ngmodel
                          [(model)]="this.produtoForm.dimensionamento.volumes"
                        ></app-text-input-ngmodel>
                      </div>
                      <div
                        class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                      >
                        <app-custom-label
                          texto="Peso Líquido"
                        ></app-custom-label>
                        <div class="p-inputgroup">
                          <app-numeric-input-ngmodel
                            [(model)]="
                              this.produtoForm.dimensionamento.pesoLiquido
                            "
                            [placeholder]="quantidadePlaceholder"
                            [options]="quantidadeMask"
                            class="w-full textgroup-input"
                          />
                          <span class="p-inputgroup-addon">cm</span>
                        </div>
                      </div>
                      <div
                        class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                      >
                        <app-custom-label texto="Peso Bruto"></app-custom-label>
                        <div class="p-inputgroup">
                          <app-numeric-input-ngmodel
                            [(model)]="
                              this.produtoForm.dimensionamento.pesoBruto
                            "
                            [placeholder]="quantidadePlaceholder"
                            [options]="quantidadeMask"
                            class="w-full textgroup-input"
                          />
                          <span class="p-inputgroup-addon">cm</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Informações Adicionais">
                <app-textarea-input-ngmodel
                  [rows]="5"
                  [(model)]="this.produtoForm.informacaoAdicional"
                  [maxlength]="255"
                ></app-textarea-input-ngmodel>
              </p-tabPanel>
            </p-tabView>
          </p-accordionTab>
        </p-accordion>
        <div>
          <div class="flex justify-content-between mb-3 mt-3">
            <p-button
              [text]="true"
              label="Limpar variações"
              tooltipPosition="bottom"
              pTooltip="Limpar variações"
              icon="pi pi-trash"
              data-cy="btn-gerar-variacao"
              (onClick)="abrirConfirmacaoLimparVariacoes()"
            ></p-button>
            <p-button
              styleClass="btn-five"
              label="Adicionar variações"
              tooltipPosition="bottom"
              pTooltip="Adicionar variações"
              icon="pi pi-plus"
              data-cy="btn-gerar-variacao"
              (onClick)="adicionarVariacoes()"
            ></p-button>
          </div>
          <div class="borda-tabela">
            <app-table
              [columns]="colunasTabelaProdutoVariacao"
              [rows]="variacoes"
            ></app-table>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Árvore Mercadológica">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label
              texto="Seção"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirSecaoCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-dropdown-input
              optionLabel="descricao"
              optionValue="id"
              [control]="produtoForm?.form.controls.secaoId"
              [options]="secoes$ | async"
              [showFilter]="true"
              [showClear]="true"
            ></app-dropdown-input>
          </div>

          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label
              texto="Grupo"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirGrupoCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-dropdown-input
              optionLabel="descricao"
              optionValue="id"
              [control]="produtoForm?.form.controls.grupoId"
              [options]="grupos$ | async"
              [showFilter]="true"
              [showClear]="true"
            ></app-dropdown-input>
          </div>

          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label
              texto="Subgrupo"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirSubgrupoCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-dropdown-input
              optionLabel="descricao"
              optionValue="id"
              [control]="produtoForm?.form.controls.subGrupoId"
              [options]="subgrupos$ | async"
              [showFilter]="true"
              [showClear]="true"
            ></app-dropdown-input>
          </div>

          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label
              texto="Família"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirFamiliaCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-dropdown-input
              optionLabel="descricao"
              optionValue="id"
              [control]="produtoForm?.form.controls.familiaId"
              [options]="familias$ | async"
              [showFilter]="true"
              [showClear]="true"
            ></app-dropdown-input>
          </div>
        </div>
        <div class="formgrid grid">
          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label
              texto="Unidade comercial entrada"
              [cadastroRapido]="true"
              [obrigatorio]="true"
              (aoClicarAdicionar)="abrirUnidadeComercialCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-dropdown-input
              optionLabel="descricao"
              optionValue="sigla"
              [control]="produtoForm?.form.controls.unidadeComercialEntrada"
              [options]="unidadesComerciais$ | async"
              [showFilter]="true"
              [descricaoSigla]="true"
            ></app-dropdown-input>
          </div>

          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label texto="Quantidade entrada" [obrigatorio]="true">
            </app-custom-label>
            <app-numeric-input
              [control]="produtoForm?.form.controls.quantidadeEntrada"
              [placeholder]="quantidadePlaceholder"
              [options]="quantidadeMask"
            ></app-numeric-input>
          </div>

          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label
              texto="Unidade comercial saída"
              [cadastroRapido]="true"
              [obrigatorio]="true"
              (aoClicarAdicionar)="abrirUnidadeComercialCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-dropdown-input
              optionLabel="descricao"
              optionValue="sigla"
              [control]="produtoForm?.form.controls.unidadeComercialSaida"
              [options]="unidadesComerciais$ | async"
              [showFilter]="true"
              [descricaoSigla]="true"
            ></app-dropdown-input>
          </div>

          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label texto="Quantidade saída" [obrigatorio]="true">
            </app-custom-label>
            <app-numeric-input
              [control]="produtoForm?.form.controls.quantidadeSaida"
              [placeholder]="quantidadePlaceholder"
              [options]="quantidadeMask"
            ></app-numeric-input>
          </div>

          <div
            class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12"
            *ngIf="produtoPorPeso"
          >
            <app-custom-label texto="Dias de Validade"></app-custom-label>
            <app-numeric-input
              [control]="produtoForm?.form.controls.diasDeValidade"
              [options]="inteiroMask"
            ></app-numeric-input>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Food">
        <div class="formgroup-inline">
          <!-- #region EMPRESAS -->
          <div class="field col-12 xl:col-3 lg:col-6 md:col-6 sm:col-12">
            <app-custom-label
              texto="Setores"
              [obrigatorio]="true"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirSetorCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-multiselect-input
              optionLabel="descricao"
              optionValue="id"
              [options]="setores$ | async"
              [showFilter]="true"
              [control]="produtoForm.form.controls?.setores"
            ></app-multiselect-input>
          </div>
          <!-- #endregion -->
        </div>
        <div class="formgroup-inline">
          <div class="formgroup-inline">
            <div class="col-6 xl:col-2 md:col-3">
              <app-checkbox-input
                label="Taxa de Serviço"
                controlName="taxaServico"
                [control]="
                  produtoForm?.form?.controls?.food?.controls?.taxaServico
                "
              ></app-checkbox-input>
            </div>

            <div class="col-6 xl:col-2 md:col-3">
              <app-checkbox-input
                label="Rodízio"
                controlName="rodizio"
                [control]="produtoForm?.form?.controls?.food?.controls?.rodizio"
              ></app-checkbox-input>
            </div>

            <div class="col-6 xl:col-2 md:col-3">
              <app-checkbox-input
                label="Pesado"
                controlName="pesado"
                [control]="produtoForm?.form?.controls?.food?.controls?.pesado"
              ></app-checkbox-input>
            </div>

            <div class="col-6 xl:col-2 md:col-3">
              <app-checkbox-input
                label="Combo"
                controlName="combo"
                [control]="produtoForm?.form?.controls?.food?.controls?.combo"
              ></app-checkbox-input>
            </div>

            <div class="col-6 xl:col-2 md:col-3">
              <app-checkbox-input
                label="Complemento"
                controlName="complemento"
                [control]="
                  produtoForm?.form?.controls?.food?.controls?.complemento
                "
              ></app-checkbox-input>
            </div>

            <div class="col-6 xl:col-2 md:col-3">
              <app-checkbox-input
                label="Controlado"
                controlName="controlado"
                [control]="
                  produtoForm?.form?.controls?.food?.controls?.controlado
                "
              ></app-checkbox-input>
            </div>

            <div class="col-6 xl:col-2 md:col-3">
              <app-checkbox-input
                label="Sem Valor"
                controlName="semValor"
                [control]="
                  produtoForm?.form?.controls?.food?.controls?.semValor
                "
              ></app-checkbox-input>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Tributação">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-2 lg:col-4 md:col-4 sm:col-12">
            <app-custom-label texto="Código NCM" [obrigatorio]="false">
            </app-custom-label>
            <app-auto-complete-input
              [control]="produtoForm?.form.controls.ncmCodigo"
              [sugestoes]="sugestoesNcm"
              [maxlength]="250"
              (completeMethod)="buscarSugestoesNcms($event)"
              (onSelect)="setarNcmSelecionado($event)"
            >
              <ng-template #template let-item>
                <span>{{ item.ncmCodigo }} - {{ item.descricao }}</span>
              </ng-template>
            </app-auto-complete-input>
          </div>

          <div class="field col-12 xl:col-2 lg:col-4 md:col-4 sm:col-12">
            <app-custom-label texto="NCM Exceção TIPI" [obrigatorio]="false">
            </app-custom-label>
            <app-text-input
              mask="00"
              textAlign="right"
              [maxlength]="2"
              [control]="produtoForm?.form.controls.ncmExcecao"
            ></app-text-input>
          </div>

          <div class="field col-12 xl:col-2 lg:col-4 md:col-4 sm:col-12">
            <app-custom-label texto="CEST"></app-custom-label>
            <app-text-input
              mask="0000000"
              iconButton="pi pi-search"
              textAlign="right"
              [maxlength]="7"
              [control]="produtoForm?.form.controls.cestCodigo"
              [showButton]="true"
              (buttonOnClick)="abrirCestBuscaRapidaModal()"
            ></app-text-input>
          </div>
        </div>
        <p-tabView
          [scrollable]="true"
          data-cy="tabview-tributacao"
          *ngIf="produtoForm?.tributacaoForm"
        >
          <p-tabPanel header="Tributação">
            <div class="formgrid grid">
              <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                <app-custom-label texto="Importados (%)"></app-custom-label>
                <app-numeric-input
                  [control]="
                    produtoForm?.tributacaoForm.controls.importadosFederal
                  "
                  [placeholder]="porcentagemPlaceholder"
                  [options]="porcentagemMask"
                ></app-numeric-input>
              </div>

              <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                <app-custom-label texto="Nacional (%)"></app-custom-label>
                <app-numeric-input
                  [control]="
                    produtoForm?.tributacaoForm.controls.nacionalFederal
                  "
                  [placeholder]="porcentagemPlaceholder"
                  [options]="porcentagemMask"
                ></app-numeric-input>
              </div>

              <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                <app-custom-label texto="Estadual (%)"></app-custom-label>
                <app-numeric-input
                  [control]="produtoForm?.tributacaoForm.controls.estadual"
                  [placeholder]="porcentagemPlaceholder"
                  [options]="porcentagemMask"
                ></app-numeric-input>
              </div>

              <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                <app-custom-label texto="Municipal (%)"></app-custom-label>
                <app-numeric-input
                  [control]="produtoForm?.tributacaoForm.controls.municipal"
                  [placeholder]="porcentagemPlaceholder"
                  [options]="porcentagemMask"
                ></app-numeric-input>
              </div>
            </div>
          </p-tabPanel>

          <p-tabPanel header="ICMS">
            <p-accordion [multiple]="true">
              <p-accordionTab header="CST/CSOSN ICMS" [selected]="true">
                <div class="formgrid grid">
                  <div class="field col-12 xl:col-6 lg:col-6">
                    <app-custom-label texto="Origem Produto">
                    </app-custom-label>
                    <app-dropdown-input
                      [options]="produtoOrigens"
                      [control]="produtoForm?.tributacaoForm.controls.origem"
                      [showClear]="true"
                      [cortarTexto]="true"
                    ></app-dropdown-input>
                  </div>

                  <div class="field col-12 xl:col-6 lg:col-6">
                    <app-custom-label texto="Produto/Serviço">
                    </app-custom-label>
                    <app-dropdown-input
                      [options]="produtosServicos"
                      [control]="
                        produtoForm?.tributacaoForm.controls.produtoServico
                      "
                      [showClear]="true"
                      [showFilter]="true"
                      [cortarTexto]="true"
                    ></app-dropdown-input>
                  </div>

                  <div class="field col-12 xl:col-6 lg:col-6">
                    <app-custom-label texto="CST/CSOSN Entrada ICMS">
                    </app-custom-label>
                    <app-dropdown-input
                      [options]="produtoCsts"
                      [control]="
                        produtoForm?.tributacaoForm.controls.icmsCstCsosnEntrada
                      "
                      [showClear]="true"
                      [showFilter]="true"
                      [cortarTexto]="true"
                    ></app-dropdown-input>
                  </div>

                  <div class="field col-12 xl:col-6 lg:col-6">
                    <app-custom-label texto="CST/CSOSN Saída ICMS">
                    </app-custom-label>
                    <app-dropdown-input
                      [options]="produtoCsts"
                      [control]="
                        produtoForm?.tributacaoForm.controls.icmsCstCsosnSaida
                      "
                      [showClear]="true"
                      [showFilter]="true"
                      [cortarTexto]="true"
                    ></app-dropdown-input>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab header="ICMS Venda (Saída)" [selected]="true">
                <div class="formgrid grid">
                  <div
                    class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
                  >
                    <app-custom-label texto="Alíquota ICMS (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls.icmsAliquotaSaida
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>

                  <div
                    class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
                  >
                    <app-custom-label texto="Redução ICMS (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls
                          .icmsAliquotaSaidaReducao
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>

                  <div
                    class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
                  >
                    <app-custom-label texto="Alíquota Final (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls
                          .icmsAliquotaSaidaFinal
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>
                </div>
              </p-accordionTab>

              <p-accordionTab header="ICMS Compra (Entrada)" [selected]="true">
                <div class="formgrid grid">
                  <div
                    class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
                  >
                    <app-custom-label texto="Alíquota ICMS (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls.icmsAliquotaEntrada
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>

                  <div
                    class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
                  >
                    <app-custom-label texto="Redução ICMS (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls
                          .icmsAliquotaEntradaReducao
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>

                  <div
                    class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
                  >
                    <app-custom-label texto="Alíquota IVA (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls
                          .icmsAliquotaEntradaIva
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </p-tabPanel>

          <p-tabPanel header="PIS/COFINS/IPI">
            <p-accordion [multiple]="true" data-cy="accordion-pis-cofins-ipi">
              <p-accordionTab
                header="PIS/COFINS (Entrada - Saída)"
                [selected]="true"
              >
                <div class="formgrid grid">
                  <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                    <app-custom-label texto="Tipo PIS/COFINS">
                    </app-custom-label>
                    <app-dropdown-input
                      [options]="produtoTiposPisCofins"
                      [control]="
                        produtoForm?.tributacaoForm.controls.pisCofinsTipo
                      "
                      [showClear]="true"
                      [showFilter]="true"
                    ></app-dropdown-input>
                  </div>

                  <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                    <app-custom-label texto="PIS Alíquota (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls.pisAliquota
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>

                  <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                    <app-custom-label texto="COFINS Alíquota (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls.cofinsAliquota
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>

                  <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                    <app-custom-label
                      texto="Natureza da receita"
                      [cadastroRapido]="true"
                      (aoClicarAdicionar)="
                        abrirNaturezaReceitaCadastroRapidoModal()
                      "
                    >
                    </app-custom-label>
                    <app-text-input
                      mask="0000000000"
                      iconButton="pi pi-search"
                      [control]="
                        produtoForm?.tributacaoForm.controls
                          .naturezaReceitaCodigo
                      "
                      [showButton]="true"
                    ></app-text-input>
                  </div>

                  <div class="field col-12">
                    <app-custom-label texto="CST PIS/COFINS Entrada">
                    </app-custom-label>
                    <app-dropdown-input
                      [options]="produtoCstsPisCofinsEntrada"
                      [control]="
                        produtoForm?.tributacaoForm.controls.pisCofinsCstEntrada
                      "
                      [showClear]="true"
                      [showFilter]="true"
                    ></app-dropdown-input>
                  </div>

                  <div class="field col-12">
                    <app-custom-label texto="CST PIS/COFINS Saida">
                    </app-custom-label>
                    <app-dropdown-input
                      [options]="produtoCstsPisCofinsSaida"
                      [control]="
                        produtoForm?.tributacaoForm.controls.pisCofinsCstSaida
                      "
                      [showClear]="true"
                      [showFilter]="true"
                    ></app-dropdown-input>
                  </div>

                  <div class="field col-12">
                    <app-custom-label texto="CCS Apurada"></app-custom-label>
                    <app-dropdown-input
                      [options]="produtoCcsApuradas"
                      [control]="
                        produtoForm?.tributacaoForm.controls.ccsApurada
                      "
                      [showClear]="true"
                      [showFilter]="true"
                    ></app-dropdown-input>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab header="IPI" [selected]="true">
                <div class="formgrid grid">
                  <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6">
                    <app-custom-label texto="Alíquota IPI (%)">
                    </app-custom-label>
                    <app-numeric-input
                      [control]="
                        produtoForm?.tributacaoForm.controls.ipiAliquota
                      "
                      [placeholder]="porcentagemPlaceholder"
                      [options]="porcentagemMask"
                    ></app-numeric-input>
                  </div>
                </div>
                <div class="formgrid grid">
                  <div class="field col-12">
                    <app-custom-label texto="CST Entrada IPI">
                    </app-custom-label>
                    <app-dropdown-input
                      [options]="produtoCstsIpiEntrada"
                      [control]="
                        produtoForm?.tributacaoForm.controls.ipiCstEntrada
                      "
                      [showClear]="true"
                      [showFilter]="true"
                    ></app-dropdown-input>
                  </div>

                  <div class="field col-12">
                    <app-custom-label texto="CST Saida IPI"></app-custom-label>
                    <app-dropdown-input
                      [options]="produtoCstsIpiSaida"
                      [control]="
                        produtoForm?.tributacaoForm.controls.ipiCstSaida
                      "
                      [showClear]="true"
                      [showFilter]="true"
                    ></app-dropdown-input>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </p-tabPanel>
        </p-tabView>
      </p-tabPanel>

      <p-tabPanel header="Produto Associado" *ngIf="permitirProdutoAssociado">
        <div class="formgrid grid">
          <div class="field col-6 xl:col-3 lg:col-3 md:col-4 sm:col-6">
            <app-custom-label texto="Código do produto associado">
            </app-custom-label>
            <app-text-input
              mask="0000000000"
              iconButton="pi pi-search"
              [control]="produtoForm?.form.controls.produtoIdAssociado"
              [showButton]="true"
            ></app-text-input>
          </div>

          <div class="field col-6 xl:col-3 lg:col-3 md:col-4 sm:col-6">
            <app-custom-label texto="Quantidade saída"></app-custom-label>
            <app-numeric-input
              [control]="
                produtoForm?.form.controls.quantidadeSaidaProdutoAssociado
              "
              [placeholder]="quantidadePlaceholder"
              [options]="quantidadeMask"
            ></app-numeric-input>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Tabela Nutricional" *ngIf="permitirTabelaNutricional">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label
              texto="Tabela nutricional"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirTabelaNutricionalCadastroRapidoModal()"
            >
            </app-custom-label>
            <app-dropdown-input
              optionLabel="porcaoDe"
              optionValue="id"
              [options]="tabelasNutricionais$ | async"
              [control]="produtoForm?.form.controls.tabelaNutricionalId"
              [showClear]="true"
              [showFilter]="true"
            ></app-dropdown-input>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Parâmetros">
        <div class="formgroup-inline">
          <div class="col-6 xl:col-2 md:col-3">
            <app-checkbox-input
              label="Movimentar estoque"
              controlName="movimentarEstoque"
              [control]="produtoForm?.form.controls.movimentarEstoque"
            ></app-checkbox-input>
          </div>

          <div class="col-6 xl:col-2 md:col-3">
            <app-checkbox-input
              label="Permitir tabela nutricional"
              controlName="permitirTabelaNutricional"
              [control]="produtoForm?.form.controls.permitirTabelaNutricional"
            ></app-checkbox-input>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Imagens" *ngIf="produtoTipoSimples">
        <div class="flex justify-content-between mb-2">
          <p-button
            styleClass="btn-five"
            label="Procurar"
            icon="pi pi-plus"
            (onClick)="imagem.click()"
          ></p-button>
          <input
            #imagem
            type="file"
            accept="image/*"
            [multiple]="true"
            class="input-file"
            (change)="salvarProdutoVariacaoFoto($event)"
          />
        </div>
        <div class="borda-tabela">
          <app-table
            [reorderableRows]="true"
            (aoReordenar)="reordenarProdutoVariacaoFoto()"
            [columns]="colunasTabelaProdutoVariacaoFoto"
            [rows]="produtoVariacaoSimplesFoto"
            dataKey="ordem"
          ></app-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Fornecedores" *ngIf="produtoTipoSimples">
        <div class="flex justify-content-between mb-2">
          <p-button
            styleClass="btn-five"
            label="Novo Fornecedor"
            tooltipPosition="bottom"
            pTooltip="Novo Fornecedor"
            icon="pi pi-plus"
            data-cy="btn-novo-fornecedor"
            (onClick)="abrirFornecedorProdutoModal()"
          >
          </p-button>
        </div>
        <div class="borda-tabela">
          <app-table
            [columns]="colunasTabelaFornecedorProduto"
            [rows]="produtoVariacaoSimplesFornecedores"
          ></app-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="EANs Relacionados" *ngIf="produtoTipoSimples">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-12">
            <app-custom-label texto="Código de EAN" [obrigatorio]="false">
            </app-custom-label>
            <app-text-input-ngmodel
              iconButton="pi pi-plus"
              [maxlength]="20"
              [(model)]="produtoForm.eanRelacionado"
              (inputKeydown)="adicionarEanRelacionadoComEnter($event)"
            ></app-text-input-ngmodel>
          </div>

          <div
            class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-12 mt-0 md:mt-4"
          >
            <p-button
              styleClass="btn-five"
              label="Adicionar"
              tooltipPosition="bottom"
              pTooltip="Adicionar"
              icon="pi pi-plus"
              (onClick)="adicionarEanRelacionado()"
            ></p-button>
          </div>
        </div>
        <div class="grid">
          <div class="col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <app-custom-label texto="EANs Relacionados"></app-custom-label>
            <p-listbox
              emptyFilterMessage="Nenhuma ean relacionado"
              emptyMessage="Nenhuma ean relacionado"
              [options]="eanRelacionados"
            >
              <ng-template let-item let-index="index" pTemplate="item">
                <div
                  class="flex w-full justify-content-between align-items-center"
                >
                  <span>{{ item }}</span>
                  <p-button
                    tooltipPosition="bottom"
                    pTooltip="Excluir"
                    icon="pi pi-trash"
                    appendTo="body"
                    [style]="{ 'z-index': 1 }"
                    (onClick)="abrirConfirmacaoExcluisaoEanRelacionado(index)"
                    [text]="true"
                    [plain]="true"
                  ></p-button>
                </div>
              </ng-template>
            </p-listbox>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Informações Adicionais" *ngIf="produtoTipoSimples">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12">
            <app-custom-label texto="Informação Adicional"></app-custom-label>
            <app-textarea-input
              [rows]="5"
              [control]="
                produtoVariacaoSimplesFormGroup.controls?.informacaoAdicional
              "
              [maxlength]="255"
            ></app-textarea-input>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel
        [header]="tituloTabPanelMovimentacoes"
        *ngIf="produtoForm.operacaoFormEditar"
      >
        <div class="flex justify-content-between mb-2">
          <app-custom-label
            texto="Top 30 Registros de Movimentações"
            class="mt-2"
          ></app-custom-label>
          <p-button
            styleClass="btn-five"
            [label]="textoBotaoMovimentacoes"
            (onClick)="redirecionarParaMovimentacoes()"
          ></p-button>
        </div>
        <div class="borda-tabela">
          <app-table
            [skeletonRowsCount]="5"
            [columns]="colunasTabelaMovimentacoes"
            [rows]="produtoForm.movimentacoes"
            [paginator]="true"
            dataKey="id"
          ></app-table>
        </div>
      </p-tabPanel>
    </p-tabView>
  </p-tabPanel>
</p-tabView>

<app-produto-variacao-referencia-cadastro-rapido-modal
  [mostrarModal]="
    produtoForm?.mostrarProdutoVariacaoReferenciaCadastroRapidoModal
  "
  (aoCancelar)="fecharReferenciaCadastroRapidoModal()"
  (aoSalvar)="setarReferenciaCadastrada($event)"
></app-produto-variacao-referencia-cadastro-rapido-modal>
<app-produto-variacao-cor-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarProdutoVariacaoCorCadastroRapidoModal"
  (aoCancelar)="fecharCorCadastroRapidoModal()"
  (aoSalvar)="setarProdutoVariacaoCorCadastrada($event)"
></app-produto-variacao-cor-cadastro-rapido-modal>
<app-produto-variacao-tamanho-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarProdutoVariacaoTamanhoCadastroRapidoModal"
  (aoCancelar)="fecharTamanhoCadastroRapidoModal()"
  (aoSalvar)="setarTamanhoCadastrada($event)"
></app-produto-variacao-tamanho-cadastro-rapido-modal>
<app-secao-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarSecaoCadastroRapidoModal"
  (aoCancelar)="fecharSecaoCadastroRapidoModal()"
  (aoSalvar)="setarSecaoCadastrada($event)"
></app-secao-cadastro-rapido-modal>
<app-grupo-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarGrupoCadastroRapidoModal"
  (aoCancelar)="fecharGrupoCadastroRapidoModal()"
  (aoSalvar)="setarGrupoCadastrado($event)"
></app-grupo-cadastro-rapido-modal>
<app-subgrupo-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarSubgrupoCadastroRapidoModal"
  (aoCancelar)="fecharSubgrupoCadastroRapidoModal()"
  (aoSalvar)="setarSubGrupoCadastrado($event)"
></app-subgrupo-cadastro-rapido-modal>
<app-familia-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarFamiliaCadastroRapidoModal"
  (aoCancelar)="fecharFamiliaCadastroRapidoModal()"
  (aoSalvar)="setarFamiliaCadastrada($event)"
></app-familia-cadastro-rapido-modal>
<app-marca-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarMarcaCadastroRapidoModal"
  (aoCancelar)="fecharMarcaCadastroRapidoModal()"
  (aoSalvar)="setarMarcaCadastrada($event)"
></app-marca-cadastro-rapido-modal>
<app-unidade-comercial-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarUnidadeComercialCadastroRapidoModal"
  (aoCancelar)="fecharUnidadeComercialCadastroRapidoModal()"
></app-unidade-comercial-cadastro-rapido-modal>
<app-tabela-nutricional-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarTabelaNutricionalCadastroRapidoModal"
  (aoCancelar)="fecharTabelaNutricionalCadastroRapidoModal()"
></app-tabela-nutricional-cadastro-rapido-modal>
<app-natureza-receita-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarNaturezaReceitaCadastroRapidoModal"
  (aoCancelar)="fecharNaturezaReceitaCadastroRapidoModal()"
></app-natureza-receita-cadastro-rapido-modal>
<app-produto-busca-rapida
  [mostrarModal]="produtoForm?.mostrarProdutoBuscaRapidaModal"
  (aoCancelar)="fecharProdutoBuscaRapidaModal()"
  (aoSelecionar)="setarProdutoSelecionado($event)"
></app-produto-busca-rapida>
<app-cest-busca-rapida
  [ncmCodigo]="ncmCodigo"
  [mostrarModal]="produtoForm?.mostrarCestBuscaRapidaModal"
  (aoCancelar)="this.fecharCestBuscaRapidaModal()"
  (aoSelecionar)="this.setarCestSelecionado($event)"
></app-cest-busca-rapida>
<app-ncm-busca-rapida
  [mostrarModal]="produtoForm?.mostrarNcmBuscaRapidaModal"
  (aoCancelar)="this.fecharNcmBuscaRapidaModal()"
  (aoSelecionar)="this.setarNcmSelecionado($event)"
></app-ncm-busca-rapida>
<app-fornecedor-busca-rapida
  [mostrarModal]="produtoForm?.mostrarFornecedorBuscaRapidaModal"
  (aoCancelar)="fecharFornecedorBuscaRapidaModal()"
  (aoSelecionar)="setarFornecedorSelecionado($event)"
></app-fornecedor-busca-rapida>

<app-setor-cadastro-rapido-modal
  [mostrarModal]="produtoForm?.mostrarSetorCadastroRapidoModal"
  (aoCancelar)="fecharSetorCadastroRapidoModal()"
></app-setor-cadastro-rapido-modal>

<p-dialog
  *ngIf="produtoForm?.variacaoForm"
  [header]="produtoForm?.produtoVariacaoModalTitulo"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="produtoForm.mostrarVariacaoModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharProdutoVariacaoModal()"
>
  <div class="formgrid grid">
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label texto="Código de barras"></app-custom-label>
      <app-text-input
        [maxlength]="20"
        [mask]="maskCodigoBarras"
        [control]="produtoForm?.variacaoForm.controls.codigoBarras"
      ></app-text-input>
    </div>

    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label
        texto="Tamanho"
        [cadastroRapido]="true"
        (aoClicarAdicionar)="abrirTamanhoCadastroRapidoModal()"
      >
      </app-custom-label>
      <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
        [control]="produtoForm?.variacaoForm.controls.tamanhoId"
        [options]="produtoVariacaoTamanhos$ | async"
        [showFilter]="true"
        [showClear]="true"
      ></app-dropdown-input>
    </div>

    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label
        texto="Cor"
        [cadastroRapido]="true"
        (aoClicarAdicionar)="abrirCorCadastroRapidoModal()"
      >
      </app-custom-label>
      <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
        [control]="produtoForm?.variacaoForm.controls.corId"
        [options]="produtoVariacaoCores$ | async"
        [showFilter]="true"
        [showClear]="true"
        [templateCor]="true"
      ></app-dropdown-input>
    </div>

    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label
        texto="Referência"
        [cadastroRapido]="true"
        (aoClicarAdicionar)="abrirReferenciaCadastroRapidoModal()"
      >
      </app-custom-label>
      <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
        [control]="produtoForm?.variacaoForm.controls.referenciaId"
        [options]="produtoVariacaoReferencias$ | async"
        [showFilter]="true"
        [showClear]="true"
      ></app-dropdown-input>
    </div>

    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label texto="SKU"></app-custom-label>
      <app-text-input
        [control]="produtoForm?.variacaoForm.controls.sku"
        [maxlength]="10"
      ></app-text-input>
    </div>
  </div>
  <p-tabView [scrollable]="true" data-cy="tabview-dados-produto-tipo-variacao">
    <p-tabPanel header="Preços">
      <div class="flex justify-content-between mb-2">
        <p-button
          styleClass="btn-five"
          label="Novo preço"
          tooltipPosition="bottom"
          pTooltip="Novo preço"
          icon="pi pi-plus"
          data-cy="btn-nova-precificacao"
          (onClick)="abrirPrecoModal()"
        ></p-button>
      </div>

      <div class="borda-tabela">
        <app-table
          [columns]="colunasTabelaProdutoVariacaoPreco"
          [rows]="produtoVariacaoPreco"
        >
          <ng-container
            *ngTemplateOutlet="acoesProdutoVariacaoPrecoTemp"
          ></ng-container>
        </app-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Estoques">
      <p-accordion>
        <p-accordionTab header="Estoques" [selected]="true">
          <div class="flex justify-content-between mb-2">
            <p-button
              styleClass="btn-five"
              label="Novo estoque"
              tooltipPosition="bottom"
              pTooltip="Novo estoque"
              icon="pi pi-plus"
              data-cy="btn-novo-estoque"
              (onClick)="abrirEstoqueModal()"
            ></p-button>
          </div>
          <div class="borda-tabela">
            <app-table
              [columns]="colunasTabelaVariacaoEstoque"
              [rows]="produtoVariacaoEstoque"
            ></app-table>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Pesos e dimensões">
          <div class="grid mt-3">
            <div class="col-12 lg:col-3">
              <div class="formgrid grid">
                <div
                  class="field flex-column col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12"
                >
                  <img
                    src="assets/imagens/caixa.png"
                    alt="caixa_com_dimensoes"
                    [width]="200"
                    [height]="150"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-9">
              <div class="formgrid grid">
                <div
                  class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                >
                  <app-custom-label texto="Altura"></app-custom-label>
                  <app-textgroup-input
                    [control]="produtoVariacaoDimensionamento?.controls?.altura"
                    addon="cm"
                    [placeholder]="quantidadePlaceholder"
                    [options]="quantidadeMask"
                  ></app-textgroup-input>
                </div>
                <div
                  class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                >
                  <app-custom-label texto="Largura"></app-custom-label>
                  <app-textgroup-input
                    [control]="
                      produtoVariacaoDimensionamento?.controls?.largura
                    "
                    addon="cm"
                    [placeholder]="quantidadePlaceholder"
                    [options]="quantidadeMask"
                  ></app-textgroup-input>
                </div>
                <div
                  class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                >
                  <app-custom-label texto="Profundidade"></app-custom-label>
                  <app-textgroup-input
                    [control]="
                      produtoVariacaoDimensionamento?.controls?.profundidade
                    "
                    addon="cm"
                    [placeholder]="quantidadePlaceholder"
                    [options]="quantidadeMask"
                  ></app-textgroup-input>
                </div>
                <div
                  class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                >
                  <app-custom-label texto="Volumes"></app-custom-label>
                  <app-text-input
                    [control]="
                      produtoVariacaoDimensionamento?.controls?.volumes
                    "
                  ></app-text-input>
                </div>
                <div
                  class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                >
                  <app-custom-label texto="Peso Líquido"></app-custom-label>
                  <app-textgroup-input
                    [control]="
                      produtoVariacaoDimensionamento?.controls?.pesoLiquido
                    "
                    addon="kg"
                    [placeholder]="quantidadePlaceholder"
                    [options]="quantidadeMask"
                  ></app-textgroup-input>
                </div>
                <div
                  class="field flex-column col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                >
                  <app-custom-label texto="Peso Bruto"></app-custom-label>
                  <app-textgroup-input
                    [control]="
                      produtoVariacaoDimensionamento?.controls?.pesoBruto
                    "
                    addon="kg"
                    [placeholder]="quantidadePlaceholder"
                    [options]="quantidadeMask"
                  ></app-textgroup-input>
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </p-tabPanel>
    <p-tabPanel header="Imagens">
      <div class="flex justify-content-between mb-2">
        <p-button
          styleClass="btn-five"
          label="Procurar"
          icon="pi pi-plus"
          (onClick)="imagem.click()"
        ></p-button>
        <input
          #imagem
          type="file"
          accept="image/*"
          [multiple]="true"
          class="input-file"
          (change)="salvarProdutoVariacaoFoto($event)"
        />
      </div>
      <div class="borda-tabela">
        <app-table
          [reorderableRows]="true"
          (aoReordenar)="reordenarProdutoVariacaoFoto()"
          [columns]="colunasTabelaProdutoVariacaoFoto"
          [rows]="produtoVariacaoFoto"
          dataKey="ordem"
        ></app-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Fornecedores">
      <div class="flex justify-content-between mb-2">
        <p-button
          styleClass="btn-five"
          label="Novo Fornecedor"
          tooltipPosition="bottom"
          pTooltip="Novo Fornecedor"
          icon="pi pi-plus"
          data-cy="btn-novo-fornecedor"
          (onClick)="abrirFornecedorProdutoModal()"
        >
        </p-button>
      </div>
      <div class="borda-tabela">
        <app-table
          [columns]="colunasTabelaFornecedorProduto"
          [rows]="produtoVariacaoFornecedor"
        ></app-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="EANs Relacionados">
      <div class="formgrid grid">
        <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-12">
          <app-custom-label texto="Código de EAN" [obrigatorio]="false">
          </app-custom-label>
          <app-text-input-ngmodel
            iconButton="pi pi-plus"
            [maxlength]="20"
            [(model)]="produtoForm.eanRelacionado"
            (inputKeydown)="adicionarEanRelacionadoComEnter($event)"
          ></app-text-input-ngmodel>
        </div>

        <div
          class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-12 mt-0 md:mt-4"
        >
          <p-button
            styleClass="btn-five"
            label="Adicionar"
            tooltipPosition="bottom"
            pTooltip="Adicionar"
            icon="pi pi-plus"
            (onClick)="adicionarEanRelacionado()"
          ></p-button>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 xl:col-3 lg:col-3 md:col-6 sm:col-12">
          <app-custom-label texto="EANs Relacionados"></app-custom-label>
          <p-listbox
            emptyFilterMessage="Nenhuma ean relacionado"
            emptyMessage="Nenhuma ean relacionado"
            [options]="eanRelacionados"
          >
            <ng-template let-item let-index="index" pTemplate="item">
              <div
                class="flex w-full justify-content-between align-items-center"
              >
                <span>{{ item }}</span>
                <p-button
                  tooltipPosition="bottom"
                  pTooltip="Excluir"
                  icon="pi pi-trash"
                  [text]="true"
                  [plain]="true"
                  appendTo="body"
                  [style]="{ 'z-index': 1 }"
                  (onClick)="abrirConfirmacaoExcluisaoEanRelacionado(index)"
                ></p-button>
              </div>
            </ng-template>
          </p-listbox>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Informações Adicionais">
      <div class="formgrid grid">
        <div class="field col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12">
          <app-custom-label texto="Informação Adicional"></app-custom-label>
          <app-textarea-input
            [rows]="5"
            [control]="produtoForm.variacaoForm?.controls?.informacaoAdicional"
            [maxlength]="255"
          ></app-textarea-input>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="fecharProdutoVariacaoModal()"
      label="Cancelar"
      data-cy="btn-variacao-cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (onClick)="salvarVariacao()"
      label="Salvar"
      styleClass="btn-five"
      data-cy="btn-variacao-salvar"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf="produtoForm?.precoForm"
  [header]="produtoForm?.produtoVariacaoPrecoModalTitulo"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="produtoForm.mostrarPrecoModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharPrecoModal()"
>
  <div class="formgrid grid">
    <div class="field col-12">
      <app-custom-label texto="Empresa" [obrigatorio]="true">
      </app-custom-label>
      <app-dropdown-input
        optionLabel="nomeFantasia"
        optionValue="id"
        [control]="produtoForm?.precoForm.controls.empresaId"
        [options]="empresas"
        [showFilter]="true"
        [showClear]="false"
        [readonly]="empresaUnica"
      ></app-dropdown-input>
    </div>
  </div>
  <div class="formgrid grid">
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3">
      <app-custom-label texto="Valor de custo (R$)"></app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoForm?.precoForm.controls.valorCusto"
        (inputKeydown)="calcularValores('valorCusto')"
      ></app-numeric-input>
    </div>

    <div class="field col-12 xl:col-3 lg:col-3 md:col-3">
      <app-custom-label texto="Margem praticada (%)"></app-custom-label>
      <app-numeric-input
        [placeholder]="porcentagemPlaceholder"
        [options]="porcentagemMask"
        [control]="produtoForm?.precoForm.controls.margem1Praticada"
        (inputKeydown)="calcularValores('margem1Praticada')"
      ></app-numeric-input>
    </div>

    <div class="field col-12 xl:col-3 lg:col-3 md:col-3">
      <app-custom-label texto="Valor de venda 1 (R$)" [obrigatorio]="true">
      </app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoForm?.precoForm.controls.valorVenda1"
        (inputKeydown)="calcularValores('valorVenda1')"
      ></app-numeric-input>
    </div>

    <div class="field col-12 xl:col-3 lg:col-3 md:col-3">
      <app-custom-label texto="Valor de venda 2 (R$)"></app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoForm?.precoForm.controls.valorVenda2"
      ></app-numeric-input>
    </div>
  </div>
  <div class="formgrid grid">
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3">
      <app-custom-label texto="Valor de custo anterior (R$)">
      </app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoForm?.precoForm.controls.valorCustoAnterior"
        [readonly]="true"
      ></app-numeric-input>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="fecharPrecoModal()"
      label="Cancelar"
      [text]="true"
      data-cy="btn-preco-cancelar"
    >
    </p-button>
    <p-button
      (onClick)="salvarPreco()"
      label="Salvar"
      styleClass="btn-five"
      data-cy="btn-preco-salvar"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf="produtoForm?.estoqueForm"
  [header]="produtoForm?.variacaoEstoqueModalTitulo"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="produtoForm.mostrarEstoqueModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharEstoqueModal()"
>
  <div class="formgrid grid">
    <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
      <app-custom-label texto="Estoque mínimo"></app-custom-label>
      <app-numeric-input
        [placeholder]="quantidadePlaceholder"
        [options]="quantidadeMask"
        [control]="produtoForm?.estoqueForm.controls.minimo"
      ></app-numeric-input>
    </div>

    <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
      <app-custom-label texto="Estoque atual"></app-custom-label>
      <app-numeric-input
        [placeholder]="quantidadePlaceholder"
        [options]="quantidadeMask"
        [control]="produtoForm?.estoqueForm.controls.atual"
      ></app-numeric-input>
    </div>

    <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
      <app-custom-label texto="Estoque máximo"></app-custom-label>
      <app-numeric-input
        [placeholder]="quantidadePlaceholder"
        [options]="quantidadeMask"
        [control]="produtoForm?.estoqueForm.controls.maximo"
      ></app-numeric-input>
    </div>

    <div class="field col-12 xl:col-4 lg:col-4 md:col-6">
      <app-custom-label texto="Área de estoque" [obrigatorio]="true">
      </app-custom-label>
      <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
        [control]="produtoForm?.estoqueForm.controls.areaEstoqueId"
        [options]="areasEstoque"
        [showFilter]="true"
      ></app-dropdown-input>
    </div>
  </div>
  <div class="formgrid grid">
    <div class="col-12">
      <app-custom-label texto="Empresas do usuário"></app-custom-label>
      <p-listbox
        optionLabel="nomeFantasia"
        emptyFilterMessage="Nenhuma empresa vinculada"
        emptyMessage="Nenhuma empresa vinculada"
        [options]="empresasUsuarioLogado"
      ></p-listbox>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="fecharEstoqueModal()"
      label="Cancelar"
      [text]="true"
      data-cy="btn-estoque-cancelar"
    >
    </p-button>
    <p-button
      (onClick)="salvarEstoque()"
      label="Salvar"
      styleClass="btn-five"
      data-cy="btn-estoque-salvar"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf="produtoForm"
  header="Gerador de código de barras"
  [(visible)]="produtoForm.mostrarGeradorEanModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [closable]="!saveLoading"
  (onHide)="fecharGeradorEanModal()"
>
  <div class="grid">
    <div class="info-grid col-12 mt-2">
      <p-button
        (onClick)="gerarCodigoBarras()"
        label="Gerar código de barras"
        styleClass="btn-five w-full"
        data-cy="btn-gerar-ean-salvar"
        [loading]="saveLoading"
      ></p-button>
    </div>
    <div
      class="info-grid col-12 text-center mt-3"
      *ngIf="listaEanGerados && produtoTipoSimples"
    >
      <span class="titulo">Código de barras gerado:</span>
      <span class="texto text-color-tema mt-1">{{
        listaEanGerados.first()
      }}</span>
    </div>
    <div
      class="info-grid col-12 text-center mt-3"
      *ngIf="listaEanGerados && produtoTipoVariacao"
    >
      <app-table
        [columns]="colunasTabelaCodigosBarras"
        [rows]="dadosTabelaVariacoesCodigosBarras"
      >
      </app-table>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="fecharGeradorEanModal()"
      label="Cancelar"
      [text]="true"
      data-cy="btn-gerar-ean-cancelar"
      [disabled]="saveLoading"
    >
    </p-button>
    <p-button
      (onClick)="salvarProdutoAoGerarCodigoBarras()"
      [disabled]="!listaEanGerados"
      [loading]="saveLoading"
      label="Salvar"
      styleClass="btn-five"
      data-cy="btn-gerar-ean-salvar"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf="produtoForm?.fornecedorProdutoForm"
  [header]="produtoForm.fornecedorProdutoModalTitulo"
  [style]="{ width: isDesktop ? '50%' : '90%' }"
  [(visible)]="produtoForm.mostrarFornecedorProdutoModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="true"
  (onHide)="fecharFornecedorProdutoModal()"
  contentStyleClass="overlay-y-hidden"
>
  <div class="formgrid grid" *ngIf="produtoForm.fornecedorProdutoForm">
    <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
      <app-custom-label texto="Fornecedor" [obrigatorio]="true">
      </app-custom-label>
      <app-text-input
        iconButton="pi pi-search"
        [control]="produtoForm.fornecedorProdutoForm.controls?.fornecedorNome"
        [readonly]="true"
        [showButton]="true"
        (buttonOnClick)="abrirFornecedorBuscaRapidaModal()"
      ></app-text-input>
    </div>

    <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
      <app-custom-label texto="Referência do fornecedor"></app-custom-label>
      <app-text-input
        [control]="produtoForm.fornecedorProdutoForm.controls?.codigoReferencia"
        [maxlength]="30"
      ></app-text-input>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="fecharFornecedorProdutoModal()"
      label="Cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (onClick)="salvarFornecedorProduto()"
      label="Salvar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>
