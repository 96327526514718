import {Injectable} from '@angular/core';
import {ImpressoraListagem} from '@app/abstraction/domain/interfaces/cadastros/impressora/impressora-listagem.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ImpressoraStore {
  private readonly _clientes = new BehaviorSubject<ImpressoraListagem[]>(null);

  get impressoras(): ImpressoraListagem[] {
    return this._clientes.getValue();
  }
  set impressoras(impressoras: ImpressoraListagem[]) {
    this._clientes.next(impressoras);
  }
  get impressoras$(): Observable<ImpressoraListagem[]> {
    return this._clientes.asObservable();
  }
  adicionar(novoImpressora: ImpressoraListagem) {
    if (!this.impressoras) {
      this.impressoras = [];
    }
    this.impressoras.push(novoImpressora);
  }
  adicionarRange(impressoras: ImpressoraListagem[]) {
    if (!this.impressoras) {
      this.impressoras = [];
    }
    impressoras.forEach((cliente) => {
      if (!this.impressoras.some((c) => c.id === cliente.id)) {
        this.impressoras.push(cliente);
      }
    });
  }
}
