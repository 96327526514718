export class ProdutoCstIpiConst {
  constructor(_codigo: string, _descricao: string, _tipoCst: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
    this.tipoCst = _tipoCst;
  }
  codigo: string;
  tipoCst: string;
  descricao: string;
}

export const ProdutoCstsIpi = [
  new ProdutoCstIpiConst('00', 'ENTRADA COM RECUPERACAO DE CREDITO', 'E'),
  new ProdutoCstIpiConst('01', 'ENTRADA TRIBUTAVEL COM ALIQUOTA ZERO', 'E'),
  new ProdutoCstIpiConst('02', 'ENTRADA ISENTA', 'E'),
  new ProdutoCstIpiConst('03', 'ENTRADA NAO TRIBUTADA', 'E'),
  new ProdutoCstIpiConst('04', 'ENTRADA IMUNE', 'E'),
  new ProdutoCstIpiConst('05', 'ENTRADA COM SUSPENSAO', 'E'),
  new ProdutoCstIpiConst('49', 'OUTRAS ENTRADAS', 'E'),
  new ProdutoCstIpiConst('50', 'SAIDA TRIBUTADA', 'S'),
  new ProdutoCstIpiConst('51', 'SAIDA TRIBUTAVEL COM ALIQUOTA ZERO', 'S'),
  new ProdutoCstIpiConst('52', 'SAIDA ISENTA', 'S'),
  new ProdutoCstIpiConst('53', 'SAIDA NAO TRIBUTADA', 'S'),
  new ProdutoCstIpiConst('54', 'SAIDA IMUNE', 'S'),
  new ProdutoCstIpiConst('55', 'SAIDA COM SUSPENSAO', 'S'),
  new ProdutoCstIpiConst('99', 'OUTRAS SAIDAS', 'S'),
];
