export interface TemaConst {
  id: string;
  descricao: string;
}

export const Temas: TemaConst[] = [
  {id: '1', descricao: 'gray-theme'},
  {id: '2', descricao: 'blue-theme'},
  {id: '3', descricao: 'light-theme'},
  {id: '4', descricao: 'dark-theme'},
];
