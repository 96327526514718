<p-dialog
  *ngIf="form"
  [header]="titulo"
  [style]="{ width: isDesktop ? '45%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="form"
  (onHide)="fecharModal()"
>
  <div class="formgrid grid">
    <!-- #region NOME -->
    <div class="field col-6 xl:col-6 lg:col-6 md:col-6 sm:col-6">
      <app-custom-label texto="Nome" [obrigatorio]="true"></app-custom-label>
      <app-text-input
        [control]="form.controls.nome"
        [maxlength]="60"
      ></app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region EMAIL -->
    <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
      <app-custom-label texto="E-mail" [obrigatorio]="emailObrigatorio">
      </app-custom-label>
      <app-text-input
        [control]="form.controls.email"
        [maxlength]="60"
        [upperCase]="false"
      ></app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region TELEFONE 1 -->
    <div class="field col-3 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Telefone 1" [obrigatorio]="true">
      </app-custom-label>
      <app-text-input
        [foneMask]="true"
        [control]="form.controls.fone1"
        [maxlength]="15"
        [maxlengthCount]="11"
        [lengthOnlyNumber]="true"
      ></app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region TELEFONE 2 -->
    <div class="field col-3 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Telefone 2"></app-custom-label>
      <app-text-input
        [foneMask]="true"
        [control]="form.controls.fone2"
        [maxlength]="15"
        [maxlengthCount]="11"
        [lengthOnlyNumber]="true"
      ></app-text-input>
    </div>
    <!-- #endregion -->
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
