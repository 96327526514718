<h3 class="flex align-items-center flex-column-reverse md:flex-row" [ngClass]="styleClass">
  <div class="mr-auto">
    {{ this.titulo }}
    <ng-container>
      <i
        class="pi pi-star text-primary cursor-pointer fav"
        (click)="adicionar()"
        *ngIf="!selecionado"
      ></i>
      <i
        class="pi pi-star-fill text-primary cursor-pointer fav"
        (click)="remover()"
        *ngIf="selecionado"
      ></i>
    </ng-container>
  </div>
  <div class="mr-2">
    <p-breadcrumb *ngIf="breadcrumbs?.length" [model]="breadcrumbs"></p-breadcrumb>
  </div>
</h3>

<!-- <p-dialog
  header="Adicionar aos favoritos"
  position="top"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="form"
  contentStyleClass=" py-0"
>
  <div class="formgrid">
    <div class="field">
      <div class="formgroup-inline mt-1">
        <div class="field-checkbox">
          <p-radioButton
            inputId="barraFavoritos"
            value="1"
            formControlName="tipo"
          ></p-radioButton>
          <label for="barraFavoritos" class="cursor-pointer"
            >Barra Favoritos</label
          >
        </div>
        <div class="field-checkbox">
          <p-radioButton
            inputId="menuDashboard"
            value="0"
            formControlName="tipo"
          ></p-radioButton>
          <label for="menuDashboard" class="cursor-pointer"
            >Menu Dashboard</label
          >
        </div>
      </div>
    </div>
    <div
      class="field col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12"
      *ngIf="tipoMenuDashboard"
    >
      <app-custom-label texto="Ícone"></app-custom-label>
      <app-icon-picker
        [myFormGroup]="form"
        myFormControlName="icone"
        [options]="iconPickerOpcoes"
      ></app-icon-picker>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="mostrarModal = false"
      label="Fechar"
      [text]="true"
    ></p-button>
    <p-button
      (click)="adicionar()"
      label="Ok"
      styleClass="p-button-text btn-five"
    ></p-button>
  </ng-template>
</p-dialog> -->
