import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CestFacade} from '@app/abstraction/domain/facades/fiscal/cest/cest.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cest-busca-rapida',
  templateUrl: './cest-busca-rapida.component.html',
})
export class CestBuscaRapidaComponent extends ListagemBaseComponent implements
    OnInit {
  @Input() mostrarModal: boolean;
  @Input() ncmCodigo: string;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  filtro: string;
  itemSelecionado: any;
  get dados$() {
    return this.cestFacade.cests$;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private cestFacade: CestFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarCestsComFiltro() {
    this.cestFacade.buscarCests({
      pagina: this.pagina,
      limite: this.limite,
      cestInicio: this.filtro,
      ncmCodigo: this.ncmCodigo,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'cestCodigo',
        name: 'Código CEST',
      },
      {
        field: 'descricao',
        name: 'Descrição',
      },
      {
        field: 'ativo',
        name: 'Situação',
        headerClass: 'text-center',
        cellClass: 'text-center',

        cellTemplate: this.situacaoTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro = null;
    this.cestFacade.limparListaCests();
    this.buscarCestsComFiltro();
  }
  buscarCestsProximaPagina() {
    this.pagina++;
    this.buscarCestsComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.cestFacade.limparListaCests();
    this.buscarCestsComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
    this.cestFacade.limparListaCests();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
}
