import {FormControl, FormGroup} from '@angular/forms';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

import {Contato} from '../../entities/shared/contato.entity';

export interface IContatoForm {
  ativo: FormControl<boolean>;
  email: FormControl<string>;
  fone1: FormControl<string>;
  fone2: FormControl<string>;
  fone3: FormControl<string>;
  id: FormControl<string>;
  nome: FormControl<string>;
  principal: FormControl<boolean>;
}

export class ContatoForm {
  static criarForm(contato?: Contato) {
    return new FormGroup<IContatoForm>({
      id: new FormControl(contato?.id),
      principal: new FormControl(contato?.principal ?? false),
      nome: new FormControl(
          contato?.nome,
          CustomValidators.required('Nome do contato é obrigatório')),
      email: new FormControl(
          contato?.email, CustomValidators.email('Formato de e-mail inválido')),
      fone1: new FormControl(
          contato?.fone1,
          [
            CustomValidators.required('Telefone 1 é obrigatório'),
            CustomValidators.minLength(10, 'Informe um telefone válido'),
          ]),
      fone2: new FormControl(contato?.fone2),
      fone3: new FormControl(contato?.fone3),
      ativo: new FormControl(contato?.ativo ?? true),
    });
  }
}
