
import '@app/shared/extensions/object.extension';

import {Component, TemplateRef, ViewChild} from '@angular/core';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ConsultaDataPeriodo} from '@app/shared/enums/consulta-data-tipo.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

import {BaseComponent} from './base.component';

@Component({template: ''})
export class ListagemBaseComponent extends BaseComponent {
  @ViewChild('acoesTemp', {static: true}) acoesTemp: TemplateRef<any>;
  @ViewChild('situacaoTemp', {static: true}) situacaoTemp: TemplateRef<any>;
  @ViewChild('booleanoTemp', {static: true}) booleanoTemp: TemplateRef<any>;
  @ViewChild('colunasOcultasTemp', {static: true})
  colunasOcultasTemp: TemplateRef<any>;
  colunas: TableColumn[];
  colunasOcultas: TableColumn[];
  limite: number;
  pagina: number;
  itensTabelaSelecionados: any[];
  timerFiltro: any;
  timeoutFiltro = 500;
  get tabelaComItensSelecionados() {
    return this.itensTabelaSelecionados?.length > 0;
  }
  constructor(
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  iniciarPaginacao(pagina?: number, limite?: number) {
    this.pagina = pagina ?? 1;
    this.limite = limite ?? 50;
  }
  limparPaginacao() {
    this.pagina = 0;
    this.limite = 0;
  }
  limparFiltro(filtro: any) {
    const keys = Object.keys(filtro);
    keys.forEach((key) => {
      filtro[key] = null;
    });
  }
  buscarValorPelaProp(
      listaFiltrada: any[], valorParaConsulta: string, campoRetornado: string,
      propriedade: string) {
    return listaFiltrada.find(
        (v) => v[propriedade] === valorParaConsulta)[campoRetornado];
  }
  onSelect(event: any) {
    this.itensTabelaSelecionados = event;
  }
  setarPeriodoConsultaFiltro(evento: ConsultaDataPeriodo) {
    let inicio;
    let fim;
    switch (evento) {
      case ConsultaDataPeriodo.Personalizado:
        inicio = moment().toDate();
        fim = moment().toDate();
        break;
      case ConsultaDataPeriodo.Hoje:
        inicio = moment().toDate();
        fim = moment().toDate();
        break;
      case ConsultaDataPeriodo.Ontem:
        inicio = moment().subtract(1, 'day').toDate();
        fim = moment().subtract(1, 'day').toDate();
        break;
      case ConsultaDataPeriodo.Amanha:
        inicio = moment().add(1, 'day').toDate();
        fim = moment().add(1, 'day').toDate();
        break;
      case ConsultaDataPeriodo.EstaSemana:
        inicio = moment().startOf('week').toDate();
        fim = moment().endOf('week').toDate();
        break;
      case ConsultaDataPeriodo.MesPassado:
        inicio = moment().subtract(1, 'month').startOf('month').toDate();
        fim = moment().subtract(1, 'month').endOf('month').toDate();
        break;
      case ConsultaDataPeriodo.MesAtual:
        inicio = moment().startOf('month').toDate();
        fim = moment().endOf('month').toDate();
        break;
      case ConsultaDataPeriodo.ProximoMes:
        inicio = moment().add(1, 'month').startOf('month').toDate();
        fim = moment().add(1, 'month').endOf('month').toDate();
        break;
    }
    return {inicio, fim};
  }
  limparItensSelecionados() {
    this.itensTabelaSelecionados = [];
  }
}
