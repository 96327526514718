import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {FormaParcelamento} from '@app/abstraction/domain/entities/financeiro/forma-parcelamento/forma-parcelamento.entity';
import {LancamentoParcela} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {FormaParcelamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-parcelamento/forma-parcelamento.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-contas-renegociacao-modal',
  templateUrl: './contas-renegociacao-modal.component.html',
})
export class ContasRenegociacaoModalComponent extends FormBaseComponent
    implements OnInit {
  @ViewChild('decimalTemp', {static: true}) decimalTemp: TemplateRef<any>;
  @ViewChild('dataTemp', {static: true}) dataTemp: TemplateRef<any>;
  @ViewChild('acoesTemp', {static: true}) acoesTemp: TemplateRef<any>;

  @Input() contasSelecionadas: any[];
  @Input() mostrarCalculoParcelasModal: boolean;
  @Input() mostrarModal: boolean;
  @Input() carregandoRenegociacao: boolean;
  @Input() parcelas: LancamentoParcela[] = [];
  @Output() aoAbrirCalculoParcelas = new EventEmitter();
  @Output() aoClicarCalcular = new EventEmitter();
  @Output() aoExcluir = new EventEmitter<any[]>();
  @Output() aoCancelar = new EventEmitter();
  @Output() aoFecharCalculoParcelas = new EventEmitter();
  @Output() aoSalvar = new EventEmitter();
  colunasContas: TableColumn[];
  colunasParcelas: TableColumn[];
  numeroDocumentoAntigo: number;
  mostarFormaParcelamentoCadastroRapidoModal: boolean;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get totalSelecionado() {
    if (this.contasSelecionadas?.length > 0) {
      return this.contasSelecionadas.sum((conta) => conta.valorTotal);
    }
    return 0;
  }
  get totalPago() {
    if (this.contasSelecionadas?.length > 0) {
      return this.contasSelecionadas.sum((conta) => conta.valorTotalPago);
    }
    return 0;
  }
  get totalPendente() {
    if (this.contasSelecionadas?.length > 0) {
      return this.contasSelecionadas.sum((conta) => conta.valorPendente);
    }
    return 0;
  }
  get totalDividaAnterior() {
    // if (this.contasSelecionadas?.length > 0) {
    //   return this.valorCorrigidoDia ?
    //       this.contasSelecionadas?.sum((conta) => conta.valorPendente) :
    //       this.contasSelecionadas?.sum(
    //           (conta) => conta.valorDocumento - conta.valorTotalPago);
    // }
    return 0;
  }
  get pessoaId() {
    if (this.contasSelecionadas?.length > 0) {
      return this.contasSelecionadas.first().pessoaId;
    }
    return '';
  }
  get totalParcelas() {
    if (this.parcelas?.length > 0) {
      return this.parcelas.sum((parcela) => parcela.valorDocumento);
    }
    return 0;
  }
  get formasParcelamento() {
    return this.formaParcelamentoFacade.formasParcelamento?.orderBy(
        (e) => e.quantidadeParcela);
  }
  // get valorCorrigidoDia() {
  //   return this.form.controls.valorCorrigidoDia.value;
  // }
  // get formaParcelamentoId() {
  //   return this.form.controls.formaParcelamentoId.value;
  // }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get valorPlaceholder() {
    return this.mascaraService.placeholder(this.valorDecimais);
  }
  get inteiroMask() {
    return this.mascaraService.inteiro();
  }
  constructor(
      private deviceDetectorFacade: DeviceDetectorFacade,
      private formaParcelamentoFacade: FormaParcelamentoFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private criarParcelasForm() {
    // this.form = this.fb.group({
    //   contasId: [null],
    //   dataVencimento: [
    //     new Date(),
    //     [
    //       CustomValidators.required('Data de vencimento é obrigatória'),
    //       CustomValidators.data(
    //           this.dataFormatoPt, 'Inserir data no formato: "dd/mm/aaaa"'),
    //     ],
    //   ],
    //   dataVencimentoFixo: [false],
    //   formaParcelamentoId: [
    //     null,
    //     CustomValidators.required('Forma de parcelamento é obrigatória'),
    //   ],
    //   jurosSobreJuros: [false],
    //   numeroDocumento: [
    //     null,
    //     CustomValidators.required('Nº do documento é obrigatório'),
    //   ],
    //   taxaPercentual: [null],
    //   valorCorrigidoDia: [true],
    //   valorParcelasIguais: [{value: false, disabled: true}],
    // });
    this.monitorarJurosCompostos();
    this.monitorarForm();
  }
  private montarColunasTabela() {
    this.colunasContas = [
      {
        field: 'valorPendente',
        name: 'Valor pendente (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right font-bold',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'valorTotalPago',
        name: 'Valor pago (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right font-bold',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'valorTotal',
        name: 'Valor total (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right font-bold',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'dataVencimento',
        name: 'Data de vencimento',
        cellTemplate: this.dataTemp,
      },
      {
        field: 'numeroDocumento',
        name: 'Nº do documento/Parcela',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: '',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesTemp,
        width: 100,
      },
    ];
  }
  private montarColunasTabelaParcelas() {
    this.colunasParcelas = [
      {
        field: 'numeroParcela',
        name: 'Nº da parcela',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: 'dataVencimento',
        name: 'Data de vencimento',
        cellTemplate: this.dataTemp,
      },
      {
        field: 'valorTotal',
        name: 'Valor parcela (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right font-bold',
        cellTemplate: this.decimalTemp,
      },
    ];
  }
  private monitorarJurosCompostos() {
    // this.form.controls.jurosSobreJuros.valueChanges.subscribe((valor) => {
    //   const control = this.form.controls.valorParcelasIguais;
    //   if (valor) {
    //     control.enable();
    //   } else {
    //     control.disable();
    //     control.setValue(false);
    //   }
    // });
  }
  private monitorarForm() {
    // this.form.valueChanges.subscribe((form) => {
    //   if (this.numeroDocumentoAntigo == form.numeroDocumento &&
    //       form.formaParcelamentoId &&
    //       moment(form.dataVencimento, this.dataFormatoPt, true).isValid()) {
    //     this.calcularParcelas();
    //   } else {
    //     this.numeroDocumentoAntigo = form.numeroDocumento;
    //   }
    // });
  }
  // private preencherDadosRenegociacao() {
  //   const dados = Object.assign({}, this.form.value as any);
  //   dados.taxaPercentual = Number(dados.taxaPercentual);
  //   return dados;
  // }
  async salvar() {
    // if (this.form.valid) {
    //   const dadosRenegociacao = this.preencherDadosRenegociacao();
    //   this.aoSalvar.emit(dadosRenegociacao);
    // } else {
    //   this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
    // }
  }
  abrirConfirmacaoRemoverConta(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-modal',
      message: 'Deseja realmente remover este item?',
      header: 'Atenção',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.removerItemListaSelecionados(index);
      },
    });
  }
  abrirCalcularParcelasModal() {
    this.aoAbrirCalculoParcelas.emit();
    this.criarParcelasForm();
    this.parcelas = [];
  }
  aoAbrirCalculoParcelasModal() {
    this.criarParcelasForm();
    this.setarDadosForm();
  }
  abrirFormaParcelamentoCadastroRapidoModal() {
    this.mostarFormaParcelamentoCadastroRapidoModal = true;
  }
  calcularParcelas() {
    // const dadosRenegociacao = this.preencherDadosRenegociacao();
    // this.aoClicarCalcular.emit(dadosRenegociacao);
  }
  fecharCalcularParcelasModal() {
    this.aoFecharCalculoParcelas.emit();
  }
  fecharFormaParcelamentoCadastroRapidoModal() {
    this.mostarFormaParcelamentoCadastroRapidoModal = false;
  }
  fecharModal() {
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.montarColunasTabela();
    this.montarColunasTabelaParcelas();
    this.criarParcelasForm();
    this.formaParcelamentoFacade.buscarFormasParcelamento({ativo: true});
  }
  removerItemListaSelecionados(index: number) {
    this.contasSelecionadas.splice(index, 1);
    this.aoExcluir.emit(this.contasSelecionadas);
  }
  setarDadosForm() {
    // const contasId = this.contasSelecionadas.map((conta) => conta.id);
    // this.form.controls.contasId.setValue(contasId);
  }
  setarFormaParcelamentoCadastrada(_formaParcelamento: FormaParcelamento) {
    // this.form.controls.formaParcelamentoId.setValue(formaParcelamento.id);
  }
}
