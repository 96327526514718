export enum ProdutoTipo {
  Nenhum,
  Simples,
  Variacao
}

export const ProdutoTipoLabels = new Map<number, string>([
  [ProdutoTipo.Simples, 'SIMPLES'],
  [ProdutoTipo.Variacao, 'VARIAÇÃO'],
]);
