<p-dialog
  [header]="titulo"
  [style]="{ width: isDesktop ? '60%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="pagamentoForm.form"
  (onHide)="fecharModal()"
  (onShow)="this.aoAbrirModal()"
>
  <div class="formgrid grid">
    <!-- #region VALOR PAGO -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
      <app-custom-label texto="Valor pago (R$)" [obrigatorio]="true">
      </app-custom-label>
      <app-numeric-input
        #valor
        iconButton="pi pi-sliders-v"
        [control]="pagamentoForm.form.controls?.valor"
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [showButton]="true"
        (buttonOnClick)="abrirAcrescimosDescontosModal()"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->

    <!-- #region FORMA DE PAGAMENTO -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
      <app-custom-label
        texto="Forma de pagamento"
        [cadastroRapido]="true"
        [obrigatorio]="true"
        (aoClicarAdicionar)="abrirFormaPagamentoCadastroRapidoModal()"
      >
      </app-custom-label>
      <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
        [control]="pagamentoForm.form.controls?.formaPagamentoId"
        [options]="formasPagamento"
        [showFilter]="true"
      ></app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region DATA DO PAGAMENTO -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
      <app-custom-label texto="Data do pagamento" [obrigatorio]="true">
      </app-custom-label>
      <app-calendar-input
        [control]="pagamentoForm.form.controls?.dataPagamento"
        [showIcon]="true"
      ></app-calendar-input>
    </div>
    <!-- #endregion -->

    <!-- #region CONTA BANCÁRIA -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6">
      <app-custom-label
        texto="Conta bancária"
        [cadastroRapido]="true"
        [obrigatorio]="true"
        (aoClicarAdicionar)="abrirContaBancariaCadastroRapidoModal()"
      >
      </app-custom-label>
      <app-dropdown-input
        optionLabel="nome"
        optionValue="id"
        [control]="pagamentoForm.form.controls.contaBancariaId"
        [options]="contasBancarias"
        [showFilter]="true"
      >
      </app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region VALOR TOTAL -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
      <app-custom-label texto="Valor total (R$)" [obrigatorio]="false">
      </app-custom-label>
      <app-numeric-input
        [control]="pagamentoForm.form.controls?.valorTotal"
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [readonly]="true"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->
  </div>
  <div class="formgrid grid">
    <div class="field col-12 xl:col-12 lg:col-12">
      <app-custom-label texto="Observação" [obrigatorio]="false">
      </app-custom-label>
      <textarea
        pInputTextarea
        [rows]="6"
        formControlName="observacao"
        class=" inputfield w-full"
      ></textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Multa/Juros/Acréscimos/Descontos"
  [style]="{ width: isDesktop ? '50%' : '90%' }"
  [(visible)]="pagamentoForm.mostrarAcrescimosDescontosModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [closable]="!saveLoading"
  (onHide)="fecharAcrescimosDescontosModal()"
>
  <div class="formgrid grid" [formGroup]="pagamentoForm.form">
    <!-- #region MULTA -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Multa (R$)" [obrigatorio]="false">
      </app-custom-label>
      <app-numeric-input
        [control]="pagamentoForm.form.controls.valorMulta"
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->
    <!-- #region JUROS -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Juros (R$)" [obrigatorio]="false">
      </app-custom-label>
      <app-numeric-input
        [control]="pagamentoForm.form.controls.valorJuros"
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->
    <!-- #region ACRESCIMOS -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Acréscimos (R$)" [obrigatorio]="false">
      </app-custom-label>
      <app-numeric-input
        [control]="pagamentoForm.form.controls.valorOutrosAcrescimos"
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->
    <!-- #region DESCONTOS -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Descontos (R$)" [obrigatorio]="false">
      </app-custom-label>
      <app-numeric-input
        [control]="pagamentoForm.form.controls.valorDesconto"
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="fecharAcrescimosDescontosModal()"
      label="Cancelar"
      [text]="true"
      data-cy="btn-gerar-ean-cancelar"
      [disabled]="saveLoading"
    >
    </p-button>
    <p-button
      (onClick)="salvarAcrescimosDescontosModal()"
      label="Ok"
      styleClass="btn-five"
      data-cy="btn-gerar-ean-salvar"
    ></p-button>
  </ng-template>
</p-dialog>

<app-conta-bancaria-cadastro-rapido-modal
  [mostrarModal]="pagamentoForm.mostrarContaBancariaCadastroRapidoModal"
  (aoCancelar)="fecharContaBancariaCadastroRapidoModal()"
  (aoSalvar)="setarContaBancariaCadastrada($event)"
></app-conta-bancaria-cadastro-rapido-modal>

<app-forma-pagamento-cadastro-rapido-modal
  [mostrarModal]="pagamentoForm.mostarFormaPagamentoCadastroRapidoModal"
  (aoCancelar)="fecharFormaPagamentoCadastroRapidoModal()"
  (aoSalvar)="setarFormaPagamentoCadastrada($event)"
></app-forma-pagamento-cadastro-rapido-modal>
