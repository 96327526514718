import {Pipe, PipeTransform} from '@angular/core';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {PessoaListagem} from '@app/abstraction/domain/interfaces/cadastros/pessoa/pessoa-listagem.interface';

@Pipe({
  name: 'pessoaPorId',
})
export class PessoaPorIdPipe implements PipeTransform {
  constructor(private pessoafacade: PessoaFacade) {}
  transform(id: string, campo: string = 'nomeRazaoSocial'): any {
    if (id) {
      const pessoa = this.pessoafacade.pessoas?.find(
                         (pessoa: PessoaListagem) => pessoa.id == id) as any;
      return pessoa?.[campo];
    }
    return '';
  }
}
