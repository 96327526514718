import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ProdutoVariacao} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao.entity';
import {IFornecedorProdutoForm} from '@app/abstraction/domain/forms/cadastros/fornecedor-produto.form';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

import {IProdutoVariacaoDimensionamentoForm, ProdutoVariacaoDimensionamentoForm} from './produto-variacao-dimensionamento.form';
import {IProdutoVariacaoEstoqueForm} from './produto-variacao-estoque.form';
import {IProdutoVariacaoFotoForm} from './produto-variacao-foto.form';
import {IProdutoVariacaoPrecoForm} from './produto-variacao-preco.form';

export interface IProdutoVariacaoForm {
  ativo: FormControl<boolean>;
  codigoBarras: FormControl<string>;
  corId: FormControl<string>;
  id: FormControl<string>;
  produtoId: FormControl<string>;
  referenciaId: FormControl<string>;
  tamanhoId: FormControl<string>;
  sku: FormControl<string>;
  estoques: FormArray<FormGroup<IProdutoVariacaoEstoqueForm>>;
  fotos: FormArray<FormGroup<IProdutoVariacaoFotoForm>>;
  precos: FormArray<FormGroup<IProdutoVariacaoPrecoForm>>;
  fornecedores: FormArray<FormGroup<IFornecedorProdutoForm>>;
  dimensionamento: FormGroup<IProdutoVariacaoDimensionamentoForm>;
  eanRelacionados: FormArray<FormControl<string>>;
  informacaoAdicional: FormControl<string>;
}

export class ProdutoVariacaoForm {
  static criarForm(variacao?: ProdutoVariacao) {
    return new FormGroup<IProdutoVariacaoForm>({
      id: new FormControl(variacao?.id ?? null),
      referenciaId: new FormControl(variacao?.referenciaId ?? null),
      tamanhoId: new FormControl(variacao?.tamanhoId ?? null),
      sku: new FormControl(variacao?.sku ?? null),
      corId: new FormControl(variacao?.corId ?? null),
      codigoBarras: new FormControl(variacao?.codigoBarras ?? null),
      ativo: new FormControl(variacao?.ativo ?? true),
      produtoId: new FormControl(variacao?.produtoId ?? null),
      precos: new FormArray(
          [],
          CustomValidators.minLengthArray(1, 'Informe pelo menos um preço'),
          ),
      estoques: new FormArray([]),
      fotos: new FormArray([]),
      fornecedores: new FormArray([]),
      eanRelacionados: new FormArray([]),
      dimensionamento: ProdutoVariacaoDimensionamentoForm.criarForm(
          variacao?.dimensionamento ?? null),
      informacaoAdicional:
          new FormControl(variacao?.informacaoAdicional ?? null)
    });
  }
}
