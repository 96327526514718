import {Injectable} from '@angular/core';
import {AreaEstoqueService} from '@app/abstraction/data/services/estoque/area-estoque.service';
import {AreaEstoqueStore} from '@app/abstraction/data/stores/estoque/area-estoque.store';
import {AreaEstoque} from '@app/abstraction/domain/entities/estoque/area-estoque/area-estoque.entity';
import {AreaEstoqueFiltro} from '@app/abstraction/domain/interfaces/estoque/area-estoque/area-estoque-filtro.interface';

@Injectable({providedIn: 'root'})
export class AreaEstoqueFacade {
  get areasEstoque() {
    return this.store.areasEstoque;
  }
  get areasEstoque$() {
    return this.store.areasEstoque$;
  }
  constructor(
      private store: AreaEstoqueStore,
      private service: AreaEstoqueService,
  ) {}
  async atualizarSituacao(id: string) {
    const areaEstoque = this.areasEstoque.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !areaEstoque.ativo);
    areaEstoque.ativo = status;
    return status;
  }
  async adicionar(areaEstoque: AreaEstoque) {
    const novaAreaEstoque = await this.service.adicionar(areaEstoque);
    if (novaAreaEstoque) {
      this.store.adicionar(novaAreaEstoque.toListagem());
    }
    return novaAreaEstoque;
  }
  async buscarAreasEstoque(filtro?: AreaEstoqueFiltro) {
    const areaEstoques = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(areaEstoques);
  }
  alterar(areaEstoque: AreaEstoque) {
    return this.service.alterar(areaEstoque, areaEstoque.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaAreasEstoque() {
    this.store.areasEstoque = null;
  }
}
