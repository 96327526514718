import {Injectable} from '@angular/core';
import {FuncionarioService} from '@app/abstraction/data/services/cadastros/funcionario.service';
import {FuncionarioStore} from '@app/abstraction/data/stores/cadastros/funcionario.store';
import {Funcionario} from '@app/abstraction/domain/entities/cadastros/funcionario/funcionario.entity';
import {FuncionarioFiltro} from '@app/abstraction/domain/interfaces/cadastros/funcionario/funcionario-filtro.interface';
import {FileService} from '@app/shared/services/file/file.service';

@Injectable({providedIn: 'root'})
export class FuncionarioFacade {
  get funcionarios() {
    return this.store.funcionarios;
  }
  get funcionarios$() {
    return this.store.funcionarios$;
  }
  constructor(
      private store: FuncionarioStore,
      private service: FuncionarioService,
      private fileService: FileService,
  ) {}
  async atualizarSituacao(id: string) {
    const funcionario = this.funcionarios.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !funcionario.ativo);
    funcionario.ativo = status;
    return status;
  }
  async buscarFuncionarios(filtro?: FuncionarioFiltro) {
    const funcionarios = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(funcionarios);
  }
  async downloadPlanilha() {
    const planilha = await this.service.downloadPlanilha();
    this.fileService.salvarArquivo(
        planilha, 'funcionarios-planilha-modelo.xlsx');
  }
  adicionar(funcionario: Funcionario) {
    return this.service.adicionar(funcionario);
  }
  alterar(funcionario: Funcionario, id: string) {
    return this.service.alterar(funcionario, id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  buscarPorDocumento(documento: any) {
    return this.service.buscarPorDocumento(documento);
  }
  buscarPorUsuarioId(chave: string) {
    return this.service.buscarPorUsuarioId(chave);
  }
  importarPlanilha(fileBase64: string) {
    return this.service.importarPlanilha(fileBase64);
  }
  limparListaFuncionarios() {
    this.store.funcionarios = null;
  }
}
