import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';



@Injectable({providedIn: 'root'})
export class AnalyticsService implements ErrorHandler {
  constructor(
      // private authFacade: UsuarioAuthFacade,
      // private client: HttpClient,
  ) {}
  // private sendError(errorHandled: any, titulo: string) {
  //   var data = <AnalyticsError>{
  //     dataHora: new Date(),
  //     empresaId: this.authFacade.empresaSelecionada?.id ?? '',
  //     usuarioChave: this.authFacade.usuarioLogado?.id ?? '',
  //     titulo: titulo,
  //   };

  //   if (errorHandled instanceof Error) {
  //     data.erro = errorHandled;
  //   } else {
  //     data.erro = JSON.stringify(errorHandled);
  //   }

  //   console.log(data);
  //   var jsonError = JSON.stringify(data);
  //   const response$ = this.client.post(`${API.ERROR}`, jsonError);
  //   lastValueFrom(response$);
  // }
  handleError(erro: any) {
    if (!(erro instanceof HttpErrorResponse) && erro.rejection) {
      erro = erro.rejection;
    }

    // if (environment.production) {
    //   this.sendError(erro, 'Internal Error:');
    // }
  }
}
