import {AdministradoraListagem} from '@app/abstraction/domain/interfaces/financeiro/administradora/administradora-listagem.interface';

export class Administradora {
  id: string;
  nome: string;
  taxa: number;
  diaCorte: string;
  operacao: string;
  diaFixoVencimento: number;
  condicaoPagamento: number;
  contaBancariaId: string;
  ativo: boolean;
  bandeiras: string[];
  formaPagamentos: string[];
  empresas: string[];

  constructor(
      id: string,
      nome: string,
      taxa: number,
      diaCorte: string,
      operacao: string,
      diaFixoVencimento: number,
      condicaoPagamento: number,
      contaBancariaId: string,
      ativo: boolean,
      bandeiras: string[],
      formaPagamentos: string[],
      empresas: string[],
  ) {
    this.id = id;
    this.nome = nome?.trim();
    this.taxa = taxa;
    this.diaCorte = diaCorte?.trim();
    this.operacao = operacao?.trim();
    this.diaFixoVencimento = diaFixoVencimento;
    this.condicaoPagamento = condicaoPagamento;
    this.contaBancariaId = contaBancariaId;
    this.ativo = ativo;
    this.bandeiras = bandeiras;
    this.formaPagamentos = formaPagamentos;
    this.empresas = empresas;
  }

  static from(json: any = {}) {
    return new Administradora(
        json.id,
        json.nome?.trim(),
        json.taxa,
        json.diaCorte?.trim(),
        json.operacao?.trim(),
        json.diaFixoVencimento,
        json.condicaoPagamento,
        json.contaBancariaId,
        json.ativo,
        json.bandeiras,
        json.formaPagamentos,
        json.empresas,
    );
  }
  toListagem() {
    return <AdministradoraListagem>{
      id: this.id,
      nome: this.nome,
      taxa: this.taxa,
      diaCorte: this.diaCorte,
      operacao: this.operacao,
      diaFixoVencimento: this.diaFixoVencimento,
      condicaoPagamento: this.condicaoPagamento,
      contaBancariaId: this.contaBancariaId,
      ativo: this.ativo,
    };
  }
}
