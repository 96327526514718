import {Component, OnInit} from '@angular/core';
import {OAuthFacade} from '@app/abstraction/domain/facades/shared/o-auth/o-auth.facade';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {PrimeNGConfig} from 'primeng/api';

import {pt_br} from './shared/i18n/pt-BR.locale';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
      private oauthFacade: OAuthFacade,
      private loadingBar: LoadingBarService,
      private primengConfig: PrimeNGConfig,
  ) {
    this.loadingBar.useRef();
  }
  private configurarPrimeNg() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(pt_br);
  }
  async ngOnInit() {
    this.configurarPrimeNg();
    await this.oauthFacade.iniciarSSO().catch(() => null);
  }
}
