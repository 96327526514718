export enum IeIndicador {
  Nenhum,
  ContribuinteIcms,
  Isento,
  NaoContribuinte = 9,
}

export const IeIndicadorLabels = new Map<IeIndicador, string>([
  [IeIndicador.ContribuinteIcms, 'CONTRIBUINTE ICMS'],
  [
    IeIndicador.Isento,
    'CONTRIBUINTE ISENTO DE INSCRIÇÃO NO CADASTRO DE CONTRIBUINTES',
  ],
  [IeIndicador.NaoContribuinte, 'NÃO CONTRIBUINTE'],
]);
