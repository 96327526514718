import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Permissao} from '@app/abstraction/domain/entities/shared/auth/modulo/permissao.entity';
import {API} from '@app/shared/constants/api.constant';
import {lastValueFrom, map} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModuloService {
  constructor(private client: HttpClient) {}

  buscarPermissoesUsuario() {
    const response$ =
        this.client.get<Permissao>(`${API.PERMISSAO}/usuarios`)
            .pipe(map((json: Permissao) => json ? Permissao.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPermissoesEmpresa() {
    const response$ =
        this.client.get<Permissao>(API.PERMISSAO)
            .pipe(map((json: Permissao) => json ? Permissao.from(json) : null));
    ;
    return lastValueFrom(response$);
  }
}
