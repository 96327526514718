import { Pipe, PipeTransform } from '@angular/core';
import { ProdutoCsts } from '@app/shared/constants/produto-csts.constant';

@Pipe({
  name: 'produtoCstDescricao',
})
export class ProdutoCstDescricaoPipe implements PipeTransform {
  transform(valor: any): any {
    if (valor) {
      const cst = ProdutoCsts.find((e) =>
        e.codigo.startsWith(valor.toString())
      );
      if (cst) {
        return `${cst.codigo} - ${cst.descricao}`;
      }
    }
    return '';
  }
}
