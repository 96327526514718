import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {ProdutoVariacaoEstoque} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-estoque.entity';
import {ProdutoVariacaoFoto} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-foto.entity';
import {ProdutoVariacaoPreco} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-preco.entity';
import {ProdutoVariacao} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao.entity';
import {Produto} from '@app/abstraction/domain/entities/produtos/produto/produto.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {AreaEstoqueFacade} from '@app/abstraction/domain/facades/estoque/area-estoque/area-estoque.facade';
import {ProdutoVariacaoCorFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-cor/produto-variacao-cor.facade';
import {ProdutoVariacaoReferenciaFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-referencia/produto-variacao-referencia.facade';
import {ProdutoVariacaoTamanhoFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-tamanho/produto-variacao-tamanho.facade';
import {ProdutoFacade} from '@app/abstraction/domain/facades/produtos/produto/produto.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {IProdutoVariacaoEstoqueForm, ProdutoVariacaoEstoqueForm} from '@app/abstraction/domain/forms/produtos/produto/produto-variacao-estoque.form';
import {IProdutoVariacaoFotoForm, ProdutoVariacaoFotoForm} from '@app/abstraction/domain/forms/produtos/produto/produto-variacao-foto.form';
import {IProdutoVariacaoPrecoForm, ProdutoVariacaoPrecoForm} from '@app/abstraction/domain/forms/produtos/produto/produto-variacao-preco.form';
import {IProdutoVariacaoForm, ProdutoVariacaoForm} from '@app/abstraction/domain/forms/produtos/produto/produto-variacao.form';
import {EmpresaListagem} from '@app/abstraction/domain/interfaces/cadastros/empresa/empresa-listagem.interface';
import {AreaEstoqueListagem} from '@app/abstraction/domain/interfaces/estoque/area-estoque/area-estoque-listagem.interface';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {API} from '@app/shared/constants/api.constant';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {Guid} from 'guid-typescript';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-produto-variacao-cadastro-rapido-modal',
  templateUrl: './produto-variacao-cadastro-rapido-modal.component.html',
  styleUrls: ['./produto-variacao-cadastro-rapido-modal.component.scss'],
})
export class ProdutoVariacaoCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Input() variacao: ProdutoVariacao;
  @Output() aoSalvar = new EventEmitter<ProdutoVariacao>();
  @Output() aoCancelar = new EventEmitter();

  @ViewChild('acoesProdutoVariacaoPrecoTemp', {static: true})
  acoesProdutoVariacaoPrecoTemp: TemplateRef<any>;
  @ViewChild('acoesProdutoVariacaoEstoqueTemp', {static: true})
  acoesProdutoVariacaoEstoqueTemp: TemplateRef<any>;
  @ViewChild('acoesProdutoVariacaoFotoTemp', {static: true})
  acoesProdutoVariacaoFotoTemp: TemplateRef<any>;
  @ViewChild('decimalTemp', {static: true}) decimalTemp: TemplateRef<any>;
  @ViewChild('empresaTemp', {static: true}) empresaTemp: TemplateRef<any>;
  @ViewChild('corTemp', {static: true}) corTemp: TemplateRef<any>;
  @ViewChild('corDescricaoTemp', {static: true})
  corDescricaoTemp: TemplateRef<any>;
  @ViewChild('tamanhoTemp', {static: true}) tamanhoTemp: TemplateRef<any>;
  @ViewChild('referenciaTemp', {static: true}) referenciaTemp: TemplateRef<any>;
  @ViewChild('fotoTemp', {static: true}) fotoTemp: TemplateRef<any>;
  @ViewChild('areaEstoqueTemp', {static: true})
  areaEstoqueTemp: TemplateRef<any>;
  @ViewChild('quantidadeDecimalTemp', {static: true})
  quantidadeDecimalTemp: TemplateRef<any>;

  form: FormGroup<IProdutoVariacaoForm>;
  produtoVariacaoPrecoForm: FormGroup<IProdutoVariacaoPrecoForm>;
  produtoVariacaoEstoqueForm: FormGroup<IProdutoVariacaoEstoqueForm>;
  colunasOcultasTabelaProdutoVariacaoEstoque: TableColumn[];
  colunasOcultasTabelaProdutoVariacaoPreco: TableColumn[];
  colunasTabelaProdutoVariacaoEstoque: TableColumn[];
  colunasTabelaProdutoVariacaoAreaEstoque: TableColumn[];
  colunasTabelaProdutoVariacaoFoto: TableColumn[];
  colunasTabelaProdutoVariacaoPreco: TableColumn[];
  mostrarProdutoVariacaoCorCadastroRapidoModal: boolean;
  mostrarProdutoVariacaoEstoqueModal: boolean;
  mostrarProdutoVariacaoPrecoModal: boolean;
  mostrarProdutoVariacaoReferenciaCadastroRapidoModal: boolean;
  mostrarProdutoVariacaoTamanhoCadastroRapidoModal: boolean;
  operacaoFormProdutoVariacaoEstoque: OperacaoForm;
  operacaoFormProdutoVariacaoPreco: OperacaoForm;
  produtoVariacaoEstoqueEditado: any;
  produtoVariacaoEstoqueModalTitulo: string;
  produtoVariacaoPrecoEditado: any;
  produtoVariacaoPrecoModalTitulo: string;
  colunasTabelaVariacaoEstoque: TableColumn[];
  empresaPadraoDropdown: any = {
    id: Guid.create().toString(),
    nomeFantasia: 'TODAS',
    nomeRazaoSocial: 'TODAS',
  };
  areaEstoquePadraoDropdown: any = {
    id: Guid.create().toString(),
    descricao: 'TODAS',
  };
  get precos() {
    return this.form.controls.precos.value as ProdutoVariacaoPreco[];
  }
  get estoques() {
    return this.form.controls.estoques.value as ProdutoVariacaoEstoque[];
  }
  get estoquesMapeados() {
    const produtoVariacaoEstoque =
        this.form.controls.estoques.value as ProdutoVariacaoEstoque[];

    let empresas = produtoVariacaoEstoque.groupBy(
                       (e: ProdutoVariacaoEstoque) => e.areaEstoqueId) as any;
    for (const empresa of empresas) {
      empresa.totalEstoqueMinimo =
          empresa.items.sum((item: ProdutoVariacaoEstoque) => item.minimo);
      empresa.totalEstoqueAtual =
          empresa.items.sum((item: ProdutoVariacaoEstoque) => item.atual);
      empresa.totalEstoqueMaximo =
          empresa.items.sum((item: ProdutoVariacaoEstoque) => item.maximo);
    }

    return empresas;
  }
  get fotos() {
    return this.form.controls.fotos.value as ProdutoVariacaoFoto[];
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get produtoVariacaoTamanhos$() {
    return this.produtoVariacaoTamanhoFacade.produtoVariacaoTamanhos$;
  }
  get produtoVariacaoCores$() {
    return this.produtoVariacaoCorFacade.produtoVariacaoCores$;
  }
  get produtoVariacaoReferencias$() {
    return this.produtoVariacaoReferenciaFacade.produtoVariacaoReferencias$;
  }
  get empresas() {
    return <EmpresaListagem[]>[
      this.empresaPadraoDropdown,
      ...this.empresaFacade.empresas,
    ];
  }
  get empresaUnica() {
    return this.authFacade.empresaUnica;
  }
  get precosFormArray() {
    return this.form.controls.precos as
        FormArray<FormGroup<IProdutoVariacaoPrecoForm>>;
  }
  get estoquesFormArray() {
    return this.form.controls.estoques as
        FormArray<FormGroup<IProdutoVariacaoEstoqueForm>>;
  }
  get fotosFormArray() {
    return this.form.controls.fotos as
        FormArray<FormGroup<IProdutoVariacaoFotoForm>>;
  }
  get areasEstoque$() {
    return this.areaEstoqueFacade.areasEstoque$;
  }
  get porcentagemDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposPorcentagem;
  }
  get quantidadeDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposQuantidade;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get porcentagemMask() {
    return this.mascaraService.decimal(this.porcentagemDecimais);
  }
  get quantidadeMask() {
    return this.mascaraService.decimal(this.quantidadeDecimais);
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get porcentagemPlaceholder() {
    return this.mascaraService.placeholder(this.porcentagemDecimais);
  }
  get quantidadePlaceholder() {
    return this.mascaraService.placeholder(this.quantidadeDecimais);
  }
  get valorPlaceholder() {
    return this.mascaraService.placeholder(this.valorDecimais);
  }
  get areasEstoque() {
    return <AreaEstoqueListagem[]>[
      this.areaEstoquePadraoDropdown,
      ...this.areaEstoqueFacade.areasEstoque,
    ];
  }
  constructor(
      private authFacade: AuthFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private empresaFacade: EmpresaFacade,
      private produtoFacade: ProdutoFacade,
      private produtoVariacaoCorFacade: ProdutoVariacaoCorFacade,
      private produtoVariacaoReferenciaFacade: ProdutoVariacaoReferenciaFacade,
      private produtoVariacaoTamanhoFacade: ProdutoVariacaoTamanhoFacade,
      private areaEstoqueFacade: AreaEstoqueFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarProdutoVariacao(produto: Produto) {
    produto = await this.produtoFacade.alterar(produto, produto.id)
                  .catch((e: any) => {
                    this.saveLoading = false;
                    return this.errorMessagesFacade.mostrarMensagens(e);
                  });
    return produto;
  }
  private criarForm() {
    this.form = ProdutoVariacaoForm.criarForm();
  }
  private adicionarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco?:
                                                     ProdutoVariacaoPreco) {
    this.precosFormArray.push(
        this.criarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco));
  }
  private adicionarProdutoVariacaoEstoqueFormGroup(produtoVariacaoEstoque?:
                                                       ProdutoVariacaoEstoque) {
    this.estoquesFormArray.push(
        this.criarProdutoVariacaoEstoqueFormGroup(produtoVariacaoEstoque));
  }
  private adicionarProdutoVariacaoFotoFormGroup(produtoVariacaoFoto?:
                                                    ProdutoVariacaoFoto) {
    this.fotosFormArray.push(
        this.criarProdutoVariacaoFotoFormGroup(produtoVariacaoFoto));
  }
  private criarProdutoVariacaoPrecoFormGroup(
      produtoVariacaoPreco?: ProdutoVariacaoPreco, index?: number) {
    if (index >= 0) {
      this.operacaoFormProdutoVariacaoPreco = OperacaoForm.Editar;

      this.produtoVariacaoPrecoEditado = {
        index: index,
        control: this.precosFormArray.controls[index],
      };
    }
    return ProdutoVariacaoPrecoForm.criarForm(produtoVariacaoPreco);
  }
  private criarProdutoVariacaoEstoqueFormGroup(
      produtoVariacaoEstoque?: ProdutoVariacaoEstoque, index?: number) {
    if (index >= 0) {
      this.operacaoFormProdutoVariacaoEstoque = OperacaoForm.Editar;

      this.produtoVariacaoEstoqueEditado = {
        index: index,
        control: this.estoquesFormArray.controls[index],
      };
    }
    // produtoVariacaoEstoque?.empresaId
    return ProdutoVariacaoEstoqueForm.criarForm(
        produtoVariacaoEstoque, produtoVariacaoEstoque.areaEstoqueId);
  }
  private criarProdutoVariacaoFotoFormGroup(produtoVariacaoFoto?:
                                                ProdutoVariacaoFoto) {
    return ProdutoVariacaoFotoForm.criarForm(produtoVariacaoFoto);
  }
  private calcularValorVenda(margem = 0, valorCusto = 0) {
    if (margem >= 0 && valorCusto >= 0) {
      let valorVenda = valorCusto + valorCusto * (margem / 100);
      valorVenda = Number.isNaN(valorVenda) ? 0 : valorVenda;
      this.produtoVariacaoPrecoForm.get('valorVenda1').setValue(valorVenda);
    }
  }
  private calcularMargemFixa(valorVenda: number = 0, valorCusto: number = 0) {
    if (valorVenda >= 0.0 && valorCusto >= 0.0) {
      let margemFixa = ((valorVenda - valorCusto) / valorCusto) * 100;
      margemFixa = Number.isNaN(margemFixa) ? 0 : margemFixa;
      this.produtoVariacaoPrecoForm.get('margem1Fixa').setValue(margemFixa);
    }
  }
  private excluirProdutoVariacaoPreco(index: number) {
    this.precosFormArray.removeAt(index);
  }
  private excluirProdutoVariacaoEstoque(
      estoque: ProdutoVariacaoEstoque, index: number) {
    let itemRemovido = this.estoques.filter(
        (item: ProdutoVariacaoEstoque) =>
            item.areaEstoqueId == estoque.areaEstoqueId)[index];
    let indexItemRemovido = this.estoques.indexOf(itemRemovido);
    this.estoquesFormArray.removeAt(indexItemRemovido);
  }
  private excluirProdutoVariacaoFoto(index: number) {
    this.fotosFormArray.removeAt(index);
    this.reordenarProdutoVariacaoFoto();
  }
  private montarColunasOcultasTabelaProdutoVariacaoPreco() {
    this.colunasOcultasTabelaProdutoVariacaoPreco =
        this.colunasTabelaProdutoVariacaoPreco.filter((e) => e.hidden);
  }
  private montarColunasOcultasTabelaProdutoVariacaoEstoque() {
    this.colunasOcultasTabelaProdutoVariacaoEstoque =
        this.colunasTabelaProdutoVariacaoEstoque.filter((e) => e.hidden);
  }
  private montarColunasTabelaVariacaoEstoque() {
    this.colunasTabelaVariacaoEstoque = [
      {
        field: 'key',  // 'key' == 'empresaId' (getter mapeado)
        name: 'Empresa',
        cellTemplate: this.empresaTemp,
      },
      {
        field: 'totalEstoqueMinimo',
        name: 'Estoque mínimo (Total)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.quantidadeDecimalTemp,
      },
      {
        field: 'totalEstoqueAtual',
        name: 'Estoque atual (Total)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.quantidadeDecimalTemp,
      },
      {
        field: 'totalEstoqueMaximo',
        name: 'Estoque máximo (Total)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.quantidadeDecimalTemp,
      },
    ];
    this.montarColunasOcultasTabelaProdutoVariacaoEstoque();
  }
  private montarColunasTabelaProdutoVariacaoPreco() {
    this.colunasTabelaProdutoVariacaoPreco = [
      {
        field: 'empresa',
        name: 'Empresa',
        cellTemplate: this.empresaTemp,
      },
      // {
      //   field: 'valorCompra',
      //   name: 'Valor de compra (R$)',
      //   headerClass: 'text-right',
      //   cellClass: 'text-right',
      //   cellTemplate: this.decimalTemp,
      // },
      {
        field: 'valorCusto',
        name: 'Valor de custo (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'valorVenda1',
        name: 'Valor de venda 1 (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'id',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesProdutoVariacaoPrecoTemp,

        width: 150,
      },
    ];
    this.montarColunasOcultasTabelaProdutoVariacaoPreco();
  }
  private montarColunasTabelaProdutoVariacaoEstoque() {
    this.colunasTabelaProdutoVariacaoEstoque = [
      {
        field: 'key',
        name: 'Empresa',
        cellTemplate: this.empresaTemp,
      },
      {
        field: 'totalEstoqueMinimo',
        name: 'Estoque mínimo (Total)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'totalEstoqueAtual',
        name: 'Estoque atual (Total)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'totalEstoqueMaximo',
        name: 'Estoque máximo (Total)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
    ];
    this.montarColunasOcultasTabelaProdutoVariacaoEstoque();
  }
  private montarColunasTabelaProdutoVariacaoAreaEstoque() {
    this.colunasTabelaProdutoVariacaoAreaEstoque = [
      {
        field: 'areaEstoqueId',
        name: 'Área de Estoque',
        cellTemplate: this.areaEstoqueTemp,
      },
      {
        field: 'minimo',
        name: 'Estoque mínimo',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'atual',
        name: 'Estoque atual',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'maximo',
        name: 'Estoque máximo',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
      },
      {
        field: 'id',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesProdutoVariacaoEstoqueTemp,

        width: 150,
      },
    ];
    this.montarColunasOcultasTabelaProdutoVariacaoEstoque();
  }
  private preencherObjetoProdutoVariacao() {
    return ProdutoVariacao.criarProdutoVariacao(this.form.value);
  }
  private preencherObjetoProdutoVariacaoPreco() {
    const produtoVariacaoPreco = ProdutoVariacaoPreco.criarProdutoVariacaoPreco(
        this.produtoVariacaoPrecoForm.value);
    produtoVariacaoPreco.prepararProdutoVariacaoPreco();
    return produtoVariacaoPreco;
  }
  private preencherObjetoProdutoVariacaoEstoque() {
    const produtoVariacaoEstoque =
        ProdutoVariacaoEstoque.criarProdutoVariacaoEstoque(
            this.produtoVariacaoEstoqueForm.value);
    produtoVariacaoEstoque.prepararProdutoVariacaoEstoque();
    return produtoVariacaoEstoque;
  }
  private preencherForm() {
    if (this.variacao?.precos) {
      this.variacao.precos.forEach((preco) => {
        this.precosFormArray.push(
            this.criarProdutoVariacaoPrecoFormGroup(preco));
      });
    }
    if (this.variacao?.estoques) {
      this.variacao.estoques.forEach((estoque) => {
        this.estoquesFormArray.push(
            this.criarProdutoVariacaoEstoqueFormGroup(estoque));
      });
    }
    if (this.variacao?.fotos) {
      this.variacao.fotos.forEach((foto) => {
        this.fotosFormArray.push(this.criarProdutoVariacaoFotoFormGroup(foto));
      });
    }
    this.form.patchValue(this.variacao);
  }
  private verificarEmpresaJaCadastradaNosPrecos(produtoVariacaoPreco:
                                                    ProdutoVariacaoPreco) {
    return this.precos.some(
        (e, index) => e.empresaId === produtoVariacaoPreco.empresaId &&
            index != this.produtoVariacaoPrecoEditado.index);
  }
  private verificarEmpresaJaCadastradaNosEstoques(produtoVariacaoEstoque:
                                                      ProdutoVariacaoEstoque) {
    return this.estoques.some(
        (e, index) => e.areaEstoqueId == produtoVariacaoEstoque.areaEstoqueId &&
            index != this.produtoVariacaoEstoqueEditado?.index);
  }
  private mostrarSucessoAoSalvar(variacao: ProdutoVariacao) {
    this.toastrService.success(`Variação do produto cadastrada com sucesso`);
    this.aoSalvar.emit(variacao);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    if (this.form.valid) {
      this.saveLoading = true;
      let novoProdutoVariacao = this.preencherObjetoProdutoVariacao();

      let produto =
          (await this.produtoFacade.buscarPorId(this.variacao.produtoId)) as
          Produto;

      produto.variacoes.push(novoProdutoVariacao);
      produto = await this.adicionarProdutoVariacao(produto);
      if (produto) {
        this.mostrarSucessoAoSalvar(produto.variacoes.last());
      }
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
    }
  }
  aoAbrirModal() {
    this.criarForm();
    this.preencherForm();
  }
  abrirProdutoVariacaoPrecoModal(
      produtoVariacaoPreco?: ProdutoVariacaoPreco, index?: number) {
    this.produtoVariacaoPrecoForm =
        this.criarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco, index);
    if (produtoVariacaoPreco) {
      this.produtoVariacaoPrecoModalTitulo = 'Editar precificação';
      this.operacaoFormProdutoVariacaoPreco = OperacaoForm.Editar;
    } else {
      this.produtoVariacaoPrecoModalTitulo = 'Nova precificação';
      this.operacaoFormProdutoVariacaoPreco = OperacaoForm.Adicionar;
    }
    this.verificarEmpresaUnica(this.produtoVariacaoPrecoForm);
    this.mostrarProdutoVariacaoPrecoModal = true;
  }
  abrirProdutoVariacaoEstoqueModal(
      produtoVariacaoEstoque?: ProdutoVariacaoEstoque, index?: number) {
    this.produtoVariacaoEstoqueForm = this.criarProdutoVariacaoEstoqueFormGroup(
        produtoVariacaoEstoque, index);
    if (produtoVariacaoEstoque) {
      this.produtoVariacaoEstoqueModalTitulo = 'Editar estoque';
      this.operacaoFormProdutoVariacaoEstoque = OperacaoForm.Editar;
    } else {
      this.produtoVariacaoEstoqueModalTitulo = 'Novo estoque';
      this.operacaoFormProdutoVariacaoEstoque = OperacaoForm.Adicionar;
    }
    this.verificarEmpresaUnica(this.produtoVariacaoEstoqueForm);
    this.mostrarProdutoVariacaoEstoqueModal = true;
  }
  abrirProdutoVariacaoReferenciaCadastroRapidoModal() {
    this.mostrarProdutoVariacaoReferenciaCadastroRapidoModal = true;
  }
  abrirProdutoVariacaoCorCadastroRapidoModal() {
    this.mostrarProdutoVariacaoCorCadastroRapidoModal = true;
  }
  abrirProdutoVariacaoTamanhoCadastroRapidoModal() {
    this.mostrarProdutoVariacaoTamanhoCadastroRapidoModal = true;
  }
  abrirConfirmacaoExcluisaoProdutoVariacaoPreco(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.excluirProdutoVariacaoPreco(index);
      },
    });
  }
  abrirConfirmacaoExcluisaoProdutoVariacaoEstoque(
      estoque: ProdutoVariacaoEstoque, index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.excluirProdutoVariacaoEstoque(estoque, index);
      },
    });
  }
  abrirConfirmacaoExcluisaoProdutoVariacaoFoto(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.excluirProdutoVariacaoFoto(index);
      },
    });
  }
  calcularValores(campo: string) {
    setTimeout(() => {
      let valorCusto = this.produtoVariacaoPrecoForm.get('valorCusto').value;
      let valorVenda = this.produtoVariacaoPrecoForm.get('valorVenda1').value;
      let margemFixa = this.produtoVariacaoPrecoForm.get('margem1Fixa').value;
      let margemPraticada =
          this.produtoVariacaoPrecoForm.get('margem1Praticada').value;

      if (campo == 'valorVenda1') {
        this.calcularMargemFixa(valorVenda, valorCusto);
      } else if (campo == 'valorCusto') {
        if (margemPraticada) {
          this.calcularValorVenda(margemPraticada, valorCusto);
        } else if (margemFixa) {
          this.calcularValorVenda(margemFixa, valorCusto);
        } else {
          this.calcularValorVenda(0, valorCusto);
        }
      } else if (campo == 'margem1Praticada') {
        if (margemPraticada) {
          this.calcularValorVenda(margemPraticada, valorCusto);
        } else if (margemFixa) {
          this.calcularValorVenda(margemFixa, valorCusto);
        }
      } else if (campo == 'margem1Fixa') {
        if (margemFixa) {
          this.calcularValorVenda(margemFixa, valorCusto);
        } else if (margemPraticada) {
          this.calcularValorVenda(margemPraticada, valorCusto);
        }
      }
    }, 100);
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.criarForm();
  }
  fecharProdutoVariacaoPrecoModal() {
    this.mostrarProdutoVariacaoPrecoModal = false;
  }
  fecharProdutoVariacaoEstoqueModal() {
    this.mostrarProdutoVariacaoEstoqueModal = false;
  }
  fecharProdutoVariacaoReferenciaCadastroRapidoModal() {
    this.mostrarProdutoVariacaoReferenciaCadastroRapidoModal = false;
  }
  fecharProdutoVariacaoCorCadastroRapidoModal() {
    this.mostrarProdutoVariacaoCorCadastroRapidoModal = false;
  }
  fecharProdutoVariacaoTamanhoCadastroRapidoModal() {
    this.mostrarProdutoVariacaoTamanhoCadastroRapidoModal = false;
  }
  buscarProdutoVariacaoFoto(index: number) {
    if (index.toString().length) {
      const produtoVariacaoFoto = this.fotos[index];

      if (produtoVariacaoFoto.caminho) {
        return `${API.ERP_BASE_URL}/${produtoVariacaoFoto.caminho}`;
      } else if (produtoVariacaoFoto.fotoBase64) {
        return produtoVariacaoFoto.fotoBase64;
      }
    }
    return '';
  }
  ngOnInit() {
    this.criarForm();
    this.montarColunasTabelaProdutoVariacaoPreco();
    this.montarColunasTabelaProdutoVariacaoEstoque();
    this.montarColunasTabelaProdutoVariacaoAreaEstoque();
    this.montarColunasTabelaVariacaoEstoque();
    this.produtoVariacaoReferenciaFacade.buscarProdutoVariacaoReferencias(
        {ativo: true});
    this.produtoVariacaoCorFacade.buscarProdutoVariacaoCores({ativo: true});
    this.produtoVariacaoTamanhoFacade.buscarProdutoVariacaoTamanhos(
        {ativo: true});
    this.empresaFacade.buscarEmpresas();
    this.areaEstoqueFacade.buscarAreasEstoque({ativo: true});
  }
  reordenarProdutoVariacaoFoto() {
    this.fotos.forEach((e: any, index: number) => {
      e.ordem = index;
    });
  }
  salvarProdutoVariacaoPreco() {
    if (this.produtoVariacaoPrecoForm.valid) {
      const produtoVariacaoPreco = this.preencherObjetoProdutoVariacaoPreco();

      if (this.verificarEmpresaJaCadastradaNosPrecos(produtoVariacaoPreco)) {
        this.toastrService.error(
            'Precificação já cadastrada para esta empresa.');
        return;
      }

      if (this.operacaoFormProdutoVariacaoPreco === OperacaoForm.Adicionar) {
        this.adicionarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco);
      } else {
        const index = this.produtoVariacaoPrecoEditado.index;

        this.precosFormArray.removeAt(index);
        this.precosFormArray.insert(
            index,
            this.criarProdutoVariacaoPrecoFormGroup(produtoVariacaoPreco));
      }
      this.fecharProdutoVariacaoPrecoModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(
          this.produtoVariacaoPrecoForm);
    }
  }
  salvarProdutoVariacaoEstoque() {
    if (this.produtoVariacaoEstoqueForm.valid) {
      const produtoVariacaoEstoque =
          this.preencherObjetoProdutoVariacaoEstoque();

      if (this.verificarEmpresaJaCadastradaNosEstoques(
              produtoVariacaoEstoque)) {
        this.errorMessagesFacade.mostrarMensagens(
            'Estoque já cadastrado para esta área da empresa.');
        return;
      }
      if (this.operacaoFormProdutoVariacaoEstoque === OperacaoForm.Adicionar) {
        this.adicionarProdutoVariacaoEstoqueFormGroup(produtoVariacaoEstoque);
      } else {
        const index = this.produtoVariacaoEstoqueEditado.index;

        this.estoquesFormArray.removeAt(index);
        this.estoquesFormArray.insert(
            index,
            this.criarProdutoVariacaoEstoqueFormGroup(produtoVariacaoEstoque));
      }
      this.fecharProdutoVariacaoEstoqueModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(
          this.produtoVariacaoEstoqueForm);
    }
  }
  salvarProdutoVariacaoFoto(evento: any) {
    if (evento.target.files.length > 0) {
      const files = evento.target.files as FileList;
      const keys = Object.keys(files);
      keys.forEach(async (key) => {
        const file = files[Number(key)];
        const fotoBase64 = await this.imagemParaBase64(file);

        const ordem = this.fotos.length;

        const novaImagem = <ProdutoVariacaoFoto>{
          ativo: true,
          ordem: ordem,
          fotoBase64: fotoBase64,
          fotoNome: file.name,
        };
        this.adicionarProdutoVariacaoFotoFormGroup(novaImagem);
      });
    }
  }
  verificarEmpresaUnica(form: FormGroup) {
    if (this.empresaUnica) {
      form.controls.empresa.setValue(this.authFacade.empresaLogada.id);
    }
  }
}
