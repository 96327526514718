import {Injectable} from '@angular/core';
import {PlanoContasFinanceiroService} from '@app/abstraction/data/services/financeiro/plano-contas.service';
import {PlanoContasStore} from '@app/abstraction/data/stores/financeiro/plano-contas.store';
import {PlanoContasNivel1} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-1.entity';
import {PlanoContasNivel2} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-2.entity';
import {PlanoContasNivel3} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-3.entity';
import {PlanoContasFiltro} from '@app/abstraction/domain/interfaces/financeiro/plano-contas/plano-contas-filtro.interface';

@Injectable({providedIn: 'root'})
export class PlanoContasFacade {
  /**
   * @description
   * Returns the planosContasNivel1 from the store.
   *
   * @returns {PlanosContasNivel1[]}
   */
  get planosContasNivel1() {
    return this.store.planosContasNivel1;
  }

  get planosContasNivel1$() {
    return this.store.planosContasNivel1$;
  }

  get planosContasNivel2() {
    return this.store.planosContasNivel2;
  }

  get planosContasNivel2$() {
    return this.store.planosContasNivel2$;
  }

  /**
   * Return the list of PlanosContasNivel3 from the store
   */
  get planosContasNivel3() {
    return this.store.planosContasNivel3;
  }

  /**
   * A getter for the planosContasNivel3$ observable.
   * @returns {Observable<ContabilPlanoConta[]>} The planosContasNivel3$
   *     observable.
   */
  get planosContasNivel3$() {
    return this.store.planosContasNivel3$;
  }

  constructor(
      private store: PlanoContasStore,
      private service: PlanoContasFinanceiroService,
  ) {}

  async adicionarPlanoContasNivel1(planoContasNivel1: PlanoContasNivel1) {
    const planoContas =
        await this.service.adicionarPlanoContasNivel1(planoContasNivel1);
    if (planoContas) {
      this.store.adicionarPlanoContasNivel1(planoContas.toListagem());
    }
    return planoContas;
  }

  async adicionarPlanoContasNivel2(planoContasNivel2: PlanoContasNivel2) {
    const planoContas =
        await this.service.adicionarPlanoContasNivel2(planoContasNivel2);
    if (planoContas) {
      this.store.adicionarPlanoContasNivel2(planoContas.toListagem());
    }
    return planoContas;
  }

  /**
   * This function adds a new level 3 account to the system.
   * @param planoContasNivel3 The new level 3 account to be added.
   * @returns The new level 3 account.
   */
  async adicionarPlanoContasNivel3(planoContasNivel3: PlanoContasNivel3) {
    const planoContas =
        await this.service.adicionarPlanoContasNivel3(planoContasNivel3);
    if (planoContas) {
      this.store.adicionarPlanoContasNivel3(planoContas.toListagem());
    }
    return planoContas;
  }

  async buscarPlanosContasNivel1() {
    const planosContasNivel1 = await this.service.buscarTodosNivel1();
    this.store.adicionarRangeNivel1(planosContasNivel1);
  }

  async buscarPlanosContasNivel2(filtro?: PlanoContasFiltro) {
    const planosContasNivel2 = await this.service.buscarTodosNivel2(filtro);
    this.store.adicionarRangeNivel2(planosContasNivel2);
  }

  async buscarPlanosContasNivel3(filtro?: PlanoContasFiltro) {
    const planosContasNivel3 = await this.service.buscarTodosNivel3(filtro);
    this.store.adicionarRangeNivel3(planosContasNivel3);
  }

  alterarPlanoContasNivel1(planoContas: PlanoContasNivel1) {
    return this.service.alterarPlanoContasNivel1(planoContas, planoContas.id);
  }

  alterarPlanoContasNivel2(planoContas: PlanoContasNivel2) {
    return this.service.alterarPlanoContasNivel2(planoContas, planoContas.id);
  }

  alterarPlanoContasNivel3(planoContas: PlanoContasNivel3) {
    return this.service.alterarPlanoContasNivel3(planoContas, planoContas.id);
  }

  buscarNivel1PorId(id: string) {
    return this.service.buscarNivel1PorId(id);
  }

  buscarNivel2PorId(id: string) {
    return this.service.buscarNivel2PorId(id);
  }

  buscarNivel3PorId(id: string) {
    return this.service.buscarNivel3PorId(id);
  }

  limparListaPlanosContasNivel1() {
    this.store.planosContasNivel1 = null;
  }

  limparListaPlanosContasNivel2() {
    this.store.planosContasNivel2 = null;
  }

  limparListaPlanosContasNivel3() {
    this.store.planosContasNivel3 = null;
  }
}
