import {FormControl, FormGroup} from '@angular/forms';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {ImpressoraTipoConexao} from '@app/shared/enums/impressora-tipo-conexao.enum';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

import {Impressora} from '../../entities/cadastros/impressora/impressora.entity';

export interface IImpressoraForm {
  id: FormControl<string>;
  descricao: FormControl<string>;
  tipoConexao: FormControl<number>;
  url: FormControl<string>;
  porta: FormControl<string>;
  ativo: FormControl<boolean>;
}

export class ImpressoraForm extends BaseForm {
  form: FormGroup<IImpressoraForm>;
  operacaoForm: OperacaoForm;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova Impressora' : 'Editar Impressora';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  get tipoConexaoRede() {
    return this.form.controls.tipoConexao.value === ImpressoraTipoConexao.Rede;
  }
  constructor(private errorMessagesFacade: ErrorMessageFacade) {
    super();
  }
  async isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }

    return true;
  }
  async preencherForm(impressora: Impressora) {
    this.form.patchValue(impressora);
  }
  criarForm() {
    this.form = new FormGroup<IImpressoraForm>({
      id: new FormControl<string>(null),
      descricao: new FormControl<string>(
          null, [CustomValidators.required('Descrição é obrigatória')]),
      tipoConexao:
          new FormControl<ImpressoraTipoConexao>(ImpressoraTipoConexao.USB),
      porta: new FormControl<string>('9100'),
      url: new FormControl<string>(null),
      ativo: new FormControl<boolean>(true),
    });
  }
  setarValoresPadroes() {
    // this.form.controls.pessoaTipo.setValue(PessoaTipo.Fisica);
    // this.form.controls.impressoraTipo.setValue(ImpressoraTipo.Fidelidade);
    // if (this.impressoraSituacaoFacade.impressoraSituacoes?.length > 0) {
    //   this.form.controls.impressoraSituacaoId.setValue(
    //       this.impressoraSituacaoFacade.impressoraSituacoes.first().id);
    // }
  }
  setarTipoFormulario(impressora?: Impressora) {
    if (impressora) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(Impressora.from(impressora));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
      this.setarValoresPadroes();
    }
  }
}
