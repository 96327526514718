import {Injectable} from '@angular/core';
import {RegraFiscalListagem} from '@app/abstraction/domain/interfaces/fiscal/regra-fiscal/regra-fiscal-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RegraFiscalStore {
  private readonly _regrasFiscais =
      new BehaviorSubject<RegraFiscalListagem[]>(null);
  private readonly _regrasFiscaisFiltrados =
      new BehaviorSubject<RegraFiscalListagem[]>(null);
  get regrasFiscais(): RegraFiscalListagem[] {
    return this._regrasFiscais.getValue();
  }
  set regrasFiscais(regrasFiscais: RegraFiscalListagem[]) {
    this._regrasFiscais.next(regrasFiscais);
  }
  get regrasFiscais$() {
    return this._regrasFiscais.asObservable();
  }
  get regrasFiscaisFiltrados(): RegraFiscalListagem[] {
    return this._regrasFiscaisFiltrados.getValue();
  }
  set regrasFiscaisFiltrados(regrasFiscaisFiltrados: RegraFiscalListagem[]) {
    this._regrasFiscaisFiltrados.next(regrasFiscaisFiltrados);
  }
  get regrasFiscaisFiltrados$() {
    return this._regrasFiscaisFiltrados.asObservable();
  }
  adicionar(regraFiscal: RegraFiscalListagem) {
    if (!this.regrasFiscais) {
      this.regrasFiscais = [];
    }
    this.regrasFiscais.push(regraFiscal);
  }
  adicionarRange(regrasFiscais: RegraFiscalListagem[]) {
    if (!this.regrasFiscais) {
      this.regrasFiscais = [];
    }
    regrasFiscais.forEach((regraFiscal) => {
      if (!this.regrasFiscais.some((c) => c.id === regraFiscal.id)) {
        this.regrasFiscais.push(regraFiscal);
      }
    });
  }
  filtrar(filtro: string, devolucao: boolean) {
    if (filtro) {
      this.regrasFiscaisFiltrados =
          this.regrasFiscais.filter((c: RegraFiscalListagem) => {
            return (
                (c.descricao.toLowerCase().startsWith(filtro?.toLowerCase()) ||
                 c.cfopTributado?.toLowerCase().startsWith(
                     filtro?.toLowerCase()) ||
                 c.cfopSt?.toLowerCase().startsWith(filtro?.toLowerCase())) &&
                c.devolucao == devolucao);
          });
    } else {
      this.regrasFiscaisFiltrados =
          this.regrasFiscais.filter((c) => c.devolucao == devolucao);
    }
  }
}
