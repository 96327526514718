import {FormControl, FormGroup} from '@angular/forms';

export interface IProdutoTributacaoForm {
  ncmCodigo: FormControl<string>;
  ncmExcecao: FormControl<number>;
  cestCodigo: FormControl<string>;
  nacionalFederal: FormControl<number>;
  importadosFederal: FormControl<number>;
  estadual: FormControl<number>;
  municipal: FormControl<number>;
  pisCofinsTipo: FormControl<string>;
  pisCofinsCstEntrada: FormControl<string>;
  pisCofinsCstSaida: FormControl<string>;
  ccsApurada: FormControl<string>;
  naturezaReceitaCodigo: FormControl<number>;
  pisAliquota: FormControl<number>;
  cofinsAliquota: FormControl<number>;
  ipiCstEntrada: FormControl<string>;
  ipiCstSaida: FormControl<string>;
  ipiAliquota: FormControl<number>;
  produtoServico: FormControl<string>;
  origem: FormControl<string>;
  icmsCstCsosnEntrada: FormControl<string>;
  icmsCstCsosnSaida: FormControl<string>;
  icmsTipoEntrada: FormControl<string>;
  icmsTipoSaida: FormControl<string>;
  icmsAliquotaEntrada: FormControl<number>;
  icmsAliquotaEntradaReducao: FormControl<number>;
  icmsAliquotaEntradaIva: FormControl<number>;
  icmsAliquotaSaida: FormControl<number>;
  icmsAliquotaSaidaReducao: FormControl<number>;
  icmsAliquotaSaidaFinal: FormControl<number>;
  cfop: FormControl<string>;
}

export class ProdutoTributacaoForm {
  static criarForm() {
    return new FormGroup<IProdutoTributacaoForm>({
      ncmCodigo: new FormControl(null),
      ncmExcecao: new FormControl(null),
      cestCodigo: new FormControl(null),
      nacionalFederal: new FormControl(null),
      importadosFederal: new FormControl(null),
      estadual: new FormControl(null),
      municipal: new FormControl(null),
      pisCofinsTipo: new FormControl(null),
      pisCofinsCstEntrada: new FormControl(null),
      pisCofinsCstSaida: new FormControl(null),
      ccsApurada: new FormControl(null),
      naturezaReceitaCodigo: new FormControl(null),
      pisAliquota: new FormControl(null),
      cofinsAliquota: new FormControl(null),
      ipiCstEntrada: new FormControl(null),
      ipiCstSaida: new FormControl(null),
      ipiAliquota: new FormControl(null),
      produtoServico: new FormControl(null),
      origem: new FormControl(null),
      icmsCstCsosnEntrada: new FormControl(null),
      icmsCstCsosnSaida: new FormControl(null),
      icmsTipoEntrada: new FormControl(''),
      icmsTipoSaida: new FormControl(''),
      icmsAliquotaEntrada: new FormControl(null),
      icmsAliquotaEntradaReducao: new FormControl(null),
      icmsAliquotaEntradaIva: new FormControl(null),
      icmsAliquotaSaida: new FormControl(null),
      icmsAliquotaSaidaReducao: new FormControl(null),
      icmsAliquotaSaidaFinal: new FormControl(null),
      cfop: new FormControl(null),
    });
  }
}
