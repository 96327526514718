import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'localeDate'})
export class LocaleDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const dataFormato = 'DD/MM/yyyy [às] HH:mm[h]';
      return moment(value).parseZone().format(args ?? dataFormato);
    }
  }
}
