import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'customCurrency'})
export class CustomCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
      value: any,
      casasDecimais: string|number = 2,
      currencyCode: string = 'R$',
      ): any {
    return super.transform(
        value, currencyCode, 'code', `1.${casasDecimais}-${casasDecimais}`);
  }
}
