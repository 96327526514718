export class Estado {
  constructor(_ufIbge: number, _ufDescricao: string, _ufSigla: string) {
    this.ufSigla = _ufSigla;
    this.ufDescricao = _ufDescricao;
    this.ufIbge = _ufIbge;
  }
  ufSigla: string;
  ufDescricao: string;
  ufIbge: number;

  static buscarSigla(ufIbge: number) {
    return Estados.find((estado: Estado) => estado.ufIbge == ufIbge).ufSigla;
  }
  static buscarUfIge(ufSigla: string) {
    return Estados.find((estado: Estado) => estado.ufSigla == ufSigla).ufIbge;
  }
}

export const Estados = [
  new Estado(11, 'RONDONIA', 'RO'),
  new Estado(12, 'ACRE', 'AC'),
  new Estado(13, 'AMAZONAS', 'AM'),
  new Estado(14, 'RORAIMA', 'RR'),
  new Estado(15, 'PARA', 'PA'),
  new Estado(16, 'AMAPA', 'AP'),
  new Estado(17, 'TOCANTINS', 'TO'),
  new Estado(21, 'MARANHAO', 'MA'),
  new Estado(22, 'PIAUI', 'PI'),
  new Estado(23, 'CEARA', 'CE'),
  new Estado(24, 'RIO GRANDE DO NORTE', 'RN'),
  new Estado(25, 'PARAIBA', 'PB'),
  new Estado(26, 'PERNAMBUCO', 'PE'),
  new Estado(27, 'ALAGOAS', 'AL'),
  new Estado(28, 'SERGIPE', 'SE'),
  new Estado(29, 'BAHIA', 'BA'),
  new Estado(31, 'MINAS GERAIS', 'MG'),
  new Estado(32, 'ESPIRITO SANTO', 'ES'),
  new Estado(33, 'RIO DE JANEIRO', 'RJ'),
  new Estado(35, 'SAO PAULO', 'SP'),
  new Estado(41, 'PARANA', 'PR'),
  new Estado(42, 'SANTA CATARINA', 'SC'),
  new Estado(43, 'RIO GRANDE DO SUL', 'RS'),
  new Estado(50, 'MATO GROSSO DO SUL', 'MS'),
  new Estado(51, 'MATO GROSSO', 'MT'),
  new Estado(52, 'GOIAS', 'GO'),
  new Estado(53, 'DISTRITO FEDERAL', 'DF'),
];
