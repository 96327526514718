export enum PedidoSituacao {
  Aberto = 1,
  Cancelado = 2,
  Finalizado = 3,
  Consignado = 4,
}

export const PedidoSituacaoLabels = new Map<number, string>([
  [PedidoSituacao.Aberto, 'Aberto'],
  [PedidoSituacao.Cancelado, 'Cancelado'],
  [PedidoSituacao.Finalizado, 'Finalizado'],
  [PedidoSituacao.Consignado, 'Consignado'],
]);
