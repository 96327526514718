export enum RegimeTributario {
  Nenhum,
  SimplesNacional,
  SimplesNacionalExcessoSublimite,
  RegimeNormal,
  Mei,
}

export const RegimeTributarioLabels = new Map<number, string>([
  [RegimeTributario.SimplesNacional, 'SIMPLES NACIONAL'],
  [
    RegimeTributario.SimplesNacionalExcessoSublimite,
    'SIMPLES NACIONAL EXCESSO SUBLIMITE DE RECEITA BRUTA',
  ],
  [RegimeTributario.RegimeNormal, 'REGIME NORMAL'],
  [RegimeTributario.Mei, 'MEI'],
]);
