export enum AtividadeTipo {
  Nenhum,
  ComercioServicos,
  Atacado,
  Industria,
  Importador,
  ProdutorRural,
}

export const AtividadeTipoLabels = new Map<number, string>([
  [AtividadeTipo.ComercioServicos, 'COMÉRCIO E SERVIÇOS'],
  [AtividadeTipo.Atacado, 'ATACADO'],
  [AtividadeTipo.Industria, 'INDÚSTRIA'],
  [AtividadeTipo.Importador, 'IMPORTADOR'],
  [AtividadeTipo.ProdutorRural, 'PRODUTOR RURAL'],
]);
