import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {FormaPagamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-pagamento/forma-pagamento.facade';
import {FormaParcelamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-parcelamento/forma-parcelamento.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormaPagamentoForm} from '@app/abstraction/domain/forms/financeiro/forma-pagamento.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-forma-pagamento-cadastro-rapido-modal',
  templateUrl: './forma-pagamento-cadastro-rapido-modal.component.html',
})
export class FormaPagamentoCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<FormaPagamento>();
  @Output() aoCancelar = new EventEmitter();

  formaPagamentoForm: FormaPagamentoForm;
  planosContas = <any[]>[];

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get formasParcelamento() {
    return this.formaParcelamentoFacade.formasParcelamento;
  }
  get inteiroMask() {
    return this.mascaraService.inteiro();
  }
  constructor(
      private formaPagamentoFacade: FormaPagamentoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private formaParcelamentoFacade: FormaParcelamentoFacade,
      private planoContasFacade: PlanoContasFacade,
      private authFacade: AuthFacade,
      private parametroFacade: ParametroFacade,
      private mascaraService: MascaraService,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarFormaPagamento(formaPagamento: FormaPagamento) {
    return this.formaPagamentoFacade.adicionar(formaPagamento)
        .catch((e: any) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private criarForm() {
    this.formaPagamentoForm = new FormaPagamentoForm(
        this.errorMessagesFacade,
        this.authFacade,
        this.formaParcelamentoFacade,
        this.parametroFacade,
    );
    this.formaPagamentoForm.criarForm();
    this.formaPagamentoForm.setarTipoFormulario();
  }
  private preencherObjeto() {
    return FormaPagamento.from(this.formaPagamentoForm.form.value);
  }
  private mostrarSucessoAoSalvar(formaPagamento: FormaPagamento) {
    this.toastrService.success(`Forma de pagamento cadastrada com sucesso`);
    this.aoSalvar.emit(formaPagamento);
    this.fecharModal();
    this.saveLoading = false;
  }
  private montarDropdownPlanosConta() {
    this.planoContasFacade.planosContasNivel2?.forEach(nivel2 => {
      const niveis3 = this.planoContasFacade.planosContasNivel3.filter(
          nivel3 => nivel3.planoConta2Id === nivel2.id && nivel3.despesa);
      if (niveis3.length === 0) return;
      this.planosContas.push({
        label: nivel2.descricao,
        value: nivel2.id,
        items: niveis3?.map(nivel3 => {
          return {
            label: `${nivel3.contaContabil} - ${nivel3.descricao}`,
            value: nivel3.id,
          };
        })
      });
    });
  }
  async salvar() {
    try {
      this.saveLoading = true;

      const formaPagamento = this.preencherObjeto();

      if (!this.formaPagamentoForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const resultado = await this.adicionarFormaPagamento(formaPagamento)

      if (resultado) this.mostrarSucessoAoSalvar(formaPagamento);

    } catch (error) {
      this.toastrService.error('Erro ao salvar forma de pagamento.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
    this.montarDropdownPlanosConta();
    this.montarDropdownTiposOperacaoRegraFiscal();
    this.montarDropdownTiposPagamentoNFeNumber();
  }
  abrirFormaParcelamentoCadastroRapidoModal() {
    this.formaPagamentoForm.abrirFormaParcelamentoCadastroRapidoModal();
  }
  fecharModal() {
    this.formaPagamentoForm.form.reset();
    this.aoCancelar.emit();
  }
  fecharFormaParcelamentoCadastroRapidoModal() {
    this.formaPagamentoForm.fecharFormaParcelamentoCadastroRapidoModal();
  }
  ngOnInit() {
    this.criarForm();
    this.formaParcelamentoFacade.buscarFormasParcelamento({ativo: true});
    this.montarDropdownPlanosConta();
    this.montarDropdownTiposOperacaoRegraFiscal();
    this.montarDropdownTiposPagamentoNFeNumber();
  }
}
