export class ProdutoTributacao {
  ncmCodigo: string;
  ncmExcecao: number;
  cestCodigo: string;
  nacionalFederal: number;
  importadosFederal: number;
  estadual: number;
  municipal: number;
  pisCofinsTipo: string;
  pisCofinsCstEntrada: string;
  pisCofinsCstSaida: string;
  ccsApurada: string;
  naturezaReceitaCodigo: number;
  pisAliquota: number;
  cofinsAliquota: number;
  ipiCstEntrada: string;
  ipiCstSaida: string;
  ipiAliquota: number;
  produtoServico: string;
  origem: string;
  icmsCstCsosnEntrada: string;
  icmsCstCsosnSaida: string;
  icmsTipoEntrada: string;
  icmsTipoSaida: string;
  icmsAliquotaEntrada: number;
  icmsAliquotaEntradaReducao: number;
  icmsAliquotaEntradaIva: number;
  icmsAliquotaSaida: number;
  icmsAliquotaSaidaReducao: number;
  icmsAliquotaSaidaFinal: number;
  cfop: string;

  static from(json: any = {}) {
    const produtoTributacao = new ProdutoTributacao();
    produtoTributacao.ncmCodigo = json.ncmCodigo?.trim();
    produtoTributacao.ncmExcecao = json.ncmExcecao;
    produtoTributacao.cestCodigo = json.cestCodigo?.trim();
    produtoTributacao.nacionalFederal = json.nacionalFederal;
    produtoTributacao.importadosFederal = json.importadosFederal;
    produtoTributacao.estadual = json.estadual;
    produtoTributacao.municipal = json.municipal;
    produtoTributacao.pisCofinsTipo = json.pisCofinsTipo?.trim();
    produtoTributacao.pisCofinsCstEntrada = json.pisCofinsCstEntrada?.trim();
    produtoTributacao.pisCofinsCstSaida = json.pisCofinsCstSaida?.trim();
    produtoTributacao.ccsApurada = json.ccsApurada?.trim();
    produtoTributacao.naturezaReceitaCodigo = json.naturezaReceitaCodigo;
    produtoTributacao.pisAliquota = json.pisAliquota;
    produtoTributacao.cofinsAliquota = json.cofinsAliquota;
    produtoTributacao.ipiCstEntrada = json.ipiCstEntrada?.trim();
    produtoTributacao.ipiCstSaida = json.ipiCstSaida?.trim();
    produtoTributacao.ipiAliquota = json.ipiAliquota;
    produtoTributacao.produtoServico = json.produtoServico?.trim();
    produtoTributacao.origem = json.origem?.trim();
    produtoTributacao.icmsCstCsosnEntrada = json.icmsCstCsosnEntrada?.trim();
    produtoTributacao.icmsCstCsosnSaida = json.icmsCstCsosnSaida?.trim();
    produtoTributacao.icmsTipoEntrada = json.icmsTipoEntrada?.trim();
    produtoTributacao.icmsTipoSaida = json.icmsTipoSaida?.trim();
    produtoTributacao.icmsAliquotaEntrada = json.icmsAliquotaEntrada;
    produtoTributacao.icmsAliquotaEntradaReducao =
        json.icmsAliquotaEntradaReducao;
    produtoTributacao.icmsAliquotaEntradaIva = json.icmsAliquotaEntradaIva;
    produtoTributacao.icmsAliquotaSaida = json.icmsAliquotaSaida;
    produtoTributacao.icmsAliquotaSaidaReducao = json.icmsAliquotaSaidaReducao;
    produtoTributacao.icmsAliquotaSaidaFinal = json.icmsAliquotaSaidaFinal;
    produtoTributacao.cfop = json.cfop?.trim();
    return produtoTributacao;
  }
}
