import {Injectable} from '@angular/core';
import {SecaoService} from '@app/abstraction/data/services/produtos/secao.service';
import {SecaoStore} from '@app/abstraction/data/stores/produtos/secao.store';
import {Secao} from '@app/abstraction/domain/entities/produtos/secao/secao.entity';
import {SecaoFiltro} from '@app/abstraction/domain/interfaces/produtos/secao/secao-filtro.interface';

@Injectable({providedIn: 'root'})
export class SecaoFacade {
  get secaoPadrao() {
    return this.store.secoes?.find(
        (p) => p.descricao.toLowerCase() === 'geral');
  }
  get secoes() {
    return this.store.secoes;
  }
  get secoes$() {
    return this.store.secoes$;
  }
  constructor(private store: SecaoStore, private service: SecaoService) {}

  async adicionar(secao: Secao) {
    const novaSecao = await this.service.adicionar(secao);
    if (novaSecao) {
      this.store.adicionar(novaSecao.toListagem());
    }
    return novaSecao;
  }
  async atualizarSituacao(id: string) {
    const secao = this.secoes.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !secao.ativo);
    secao.ativo = status;
    return status;
  }
  async buscarSecoes(filtro?: SecaoFiltro) {
    const secoes = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(secoes);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  alterar(secao: Secao) {
    return this.service.alterar(secao, secao.id);
  }
  limparListaSecoes() {
    this.store.secoes = null;
  }
}
