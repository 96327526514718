import {PlanoContasListagem} from '@app/abstraction/domain/interfaces/financeiro/plano-contas/plano-contas-listagem.interface';
export class PlanoContasNivel2 {
  id: string;
  planoConta1Id: string;
  contaCodigo: number;
  descricao: string;
  ativo: boolean;
  static from(json: any = {}) {
    const planoContasNivel2 = new PlanoContasNivel2();
    planoContasNivel2.ativo = json.ativo;
    planoContasNivel2.contaCodigo = json.contaCodigo;
    planoContasNivel2.descricao = json.descricao;
    planoContasNivel2.planoConta1Id = json.planoConta1Id;
    planoContasNivel2.id = json.id;
    return planoContasNivel2;
  }
  toListagem() {
    return <PlanoContasListagem>{
      id: this.id,
      planoConta1Id: this.planoConta1Id,
      contaCodigo: this.contaCodigo,
      descricao: this.descricao?.trim(),
      ativo: this.ativo,
    };
  }
}
