import {Component, EventEmitter, Input, Output, Renderer2,} from '@angular/core';
import {NotificacaoFacade} from '@app/abstraction/domain/facades/shared/notificacao/notificacao.facade';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss'],
})
export class NotificacoesComponent {
  @Input() mostrarNotificacoes: boolean;
  @Output() aoFecharNotificacoes = new EventEmitter();
  get notificacoes() {
    if (!this.notificacaoFacade.notificacoesAntigas) return [];
    return this.notificacaoFacade.notificacoesAntigas?.groupBy(e => e.titulo)
        .map(e => {
          return {
            titulo: e.key,
            quantidade: e.items.length.toString(),
            texto: e.items.first().texto,
            dataHoraCriacao: e.items.first().dataHoraCriacao,
            dataHoraLeitura: e.items.first().dataHoraLeitura,
            show: e.items.length > 1,
          };
        })
        .orderBy(e => e.dataHoraCriacao)
        .reverse();
  }
  constructor(
      private renderer: Renderer2,
      private notificacaoFacade: NotificacaoFacade,
  ) {}
  adicionarTituloMenuNotificacoes() {
    const header = document.getElementsByClassName('p-sidebar-header')[0];
    const title = this.renderer.createElement('span') as HTMLElement;
    // title.innerHTML = 'Notificações';
    // title.classList.add('header-title');
    // title.classList.add('flex-row-reverse');
    header.appendChild(title);
    this.notificacaoFacade.marcarComoLidas();
  }
  async fecharNotificacoes() {
    this.aoFecharNotificacoes.emit();
    this.notificacaoFacade.atualizarNotificacoesAntigas();
  }
}
