import {ChangeDetectorRef, Directive, Host, OnInit, Optional, SimpleChange,} from '@angular/core';
import {NgControl} from '@angular/forms';
import {NgxMaskDirective} from 'ngx-mask';

@Directive({
  selector: '[appNcmMask]',
})
export class NcmMaskDirective implements OnInit {
  mascara = '0000.00.00';
  valorAtual = '';

  constructor(
      private cdr: ChangeDetectorRef,
      @Host() @Optional() private mask: NgxMaskDirective,
      private control: NgControl) {}

  ngOnInit(): void {
    this.mask.maskExpression = this.mascara;
    this.mask.ngOnChanges({
      maskExpression: new SimpleChange('', this.mascara, true),
    });
    this.mask.registerOnChange((ncm: string) => {
      if (ncm != null && this.valorAtual !== ncm) {
        this.valorAtual = ncm;
        this.processInputChange(ncm);
      }
    });
    this.cdr.detectChanges();
  }

  private processInputChange(ncm: string) {
    setTimeout(() => {
      this.mask.ngOnChanges({
        maskExpression: new SimpleChange(null, this.mascara, false),
      });
    }, 50);

    this.control.control.setValue(ncm);
  }
}
