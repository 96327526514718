export class ChaveIntegracao {
  descricao: string;
  expiracao: Date|string;

  static from(json: any = {}): ChaveIntegracao {
    const chaveIntegracao = new ChaveIntegracao();
    chaveIntegracao.descricao = json.descricao;
    chaveIntegracao.expiracao = json.expiracao;
    return chaveIntegracao;
  }
}