import {SecaoListagem} from '../../../interfaces/produtos/secao/secao-listagem.interface';

export class Secao {
  ativo: boolean;
  descricao: string;
  id: string;

  static from(value: any) {
    const secao = new Secao();
    secao.id = value.id;
    secao.descricao = value.descricao?.trim();
    secao.ativo = value.ativo;
    return secao;
  }
  toListagem() {
    return <SecaoListagem> {
      id: this.id, descricao: this.descricao, ativo: this.ativo
    }
  }
}
