import {Injectable} from '@angular/core';
import {PlanoContaGruposListagem} from '@app/abstraction/domain/interfaces/financeiro/plano-conta-grupos/plano-conta-grupos-listagem.entity';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PlanoContaGruposStore {
  private readonly _planoContaGrupos =
      new BehaviorSubject<PlanoContaGruposListagem[]>(null);

  get planoContaGrupos(): PlanoContaGruposListagem[] {
    return this._planoContaGrupos.getValue();
  }
  set planoContaGrupos(planoContaGrupos: PlanoContaGruposListagem[]) {
    this._planoContaGrupos.next(planoContaGrupos);
  }
  get planoContaGrupos$() {
    return this._planoContaGrupos.asObservable();
  }
  adicionarRange(planoContaGrupos: PlanoContaGruposListagem[]) {
    if (!this.planoContaGrupos) {
      this.planoContaGrupos = [];
    }
    planoContaGrupos.forEach((planoContaGrupos) => {
      if (!this.planoContaGrupos.some((c) => c.id === planoContaGrupos.id)) {
        this.planoContaGrupos.push(planoContaGrupos);
      }
    });
  }
}
