import {Injectable} from '@angular/core';
import {LancamentoService} from '@app/abstraction/data/services/financeiro/lancamento.service';
import {LancamentoStore} from '@app/abstraction/data/stores/financeiro/lancamento.store';
import {LancamentoPagamentoMassa} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-pagamento-massa.entity';
import {LancamentoPagamento} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-pagamento.entity';
import {LancamentoParcela} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import {Lancamento} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento.entity';
import {LancamentoParcelaEstornarMassa} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-estornar-massa.interface';
import {LancamentoParcelaFiltro} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-filtro.interface';
import {FileService} from '@app/shared/services/file/file.service';

@Injectable({providedIn: 'root'})
export class LancamentoFacade {
  get lancamentosPagar() {
    return this.store.lancamentosPagar;
  }
  get lancamentosPagar$() {
    return this.store.lancamentosPagar$;
  }
  get lancamentosReceber() {
    return this.store.lancamentosReceber;
  }
  get lancamentosReceber$() {
    return this.store.lancamentosReceber$;
  }
  constructor(
      private store: LancamentoStore,
      private service: LancamentoService,
      private fileService: FileService,
  ) {}
  async buscarParcelasPagar(filtro?: LancamentoParcelaFiltro) {
    filtro.despesa = true;
    const lancamentosPagar = await this.service.buscarTodos(filtro);
    this.store.adicionarRangePagar(lancamentosPagar);
  }
  async buscarParcelasReceber(filtro?: LancamentoParcelaFiltro) {
    filtro.despesa = false;
    const lancamentosReceber = await this.service.buscarTodos(filtro);
    this.store.adicionarRangeReceber(lancamentosReceber);
  }
  async downloadPlanilhaContasReceber() {
    const planilha = await this.service.downloadPlanilhaContasReceber();
    this.fileService.salvarArquivo(
        planilha, 'contas-receber-planilha-modelo.xlsx');
  }
  async downloadPlanilhaContasPagar() {
    const planilha = await this.service.downloadPlanilhaContasPagar();
    this.fileService.salvarArquivo(
        planilha, 'contas-pagar-planilha-modelo.xlsx');
  }
  adicionarLancamento(lancamento: Lancamento) {
    return this.service.adicionarLancamento(lancamento);
  }
  alterarParcela(parcela: LancamentoParcela) {
    return this.service.alterarParcela(parcela);
  }
  adicionarPagamento(
      parcelaId: string,
      pagamento: LancamentoPagamento,
  ) {
    return this.service.adicionarPagamento(parcelaId, pagamento);
  }
  adicionarListaLancamentos(
      parcelaId: string, pagamentos: LancamentoPagamento[]) {
    return this.service.adicionarListaLancamentos(parcelaId, pagamentos);
  }
  adicionarPagamentosMassa(pagamentoMassa: LancamentoPagamentoMassa) {
    return this.service.adicionarPagamentosMassa(pagamentoMassa);
  }
  buscarParcelaPorId(id: string) {
    return this.service.buscarParcelaPorId(id);
  }
  excluirParcela(parcelaId: string) {
    return this.service.excluirParcela(parcelaId);
  }
  estornarPagamentosParcelas(estorno: LancamentoParcelaEstornarMassa) {
    return this.service.estornarPagamentosParcelas(estorno);
  }
  importarPlanilhaContasReceber(fileBase64: string) {
    return this.service.importarPlanilhaContasReceber(fileBase64);
  }
  importarPlanilhaContasPagar(fileBase64: string) {
    return this.service.importarPlanilhaContasPagar(fileBase64);
  }
  limparListaLancamentosPagar() {
    this.store.lancamentosPagar = null;
  }
  limparListaLancamentosReceber() {
    this.store.lancamentosReceber = null;
  }
  verificarExisteBoletoPorParcelaId(parcelaId: string) {
    return this.service.verificarExisteBoletoPorParcelaId(parcelaId);
  }
}
