import {Injectable} from '@angular/core';
import {UsuarioService} from '@app/abstraction/data/services/cadastros/usuario.service';
import {UsuarioStore} from '@app/abstraction/data/stores/cadastros/usuario.store';
import {AuthStore} from '@app/abstraction/data/stores/shared/auth.store';
import {Usuario} from '@app/abstraction/domain/entities/cadastros/usuario/usuario.entity';
import {UsuarioFiltro} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-filtro.interface';
import {UsuarioListagem} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-listagem.interface';

@Injectable({providedIn: 'root'})
export class UsuarioFacade {
  get usuarios() {
    return this.store.usuarios;
  }
  get usuarios$() {
    return this.store.usuarios$;
  }
  constructor(
      private service: UsuarioService,
      private store: UsuarioStore,
      private authState: AuthStore,
  ) {}
  async atualizarSituacao(id: string) {
    const usuario: UsuarioListagem = this.usuarios.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(
        id,
        !usuario.empresas
             .find((empresa) => empresa.id == this.authState.empresaLogada.id)
             .ativo);
    usuario.empresas.forEach(
        (empresaUsuario) => (empresaUsuario.ativo = status));
    usuario.ativo = status;
    return status;
  }
  async buscarUsuarios(filtro?: UsuarioFiltro) {
    const usuarios = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(usuarios);
  }
  alterar(usuario: Usuario, chave: string) {
    return this.service.alterar(usuario, chave);
  }
  adicionar(usuario: Usuario) {
    return this.service.adicionar(usuario);
  }
  verificarSenhaDefinida(email: string) {
    return this.service.verificarSenhaDefinida(email);
  }
  buscarPorChave(chave: string) {
    return this.service.buscarPorChave(chave);
  }
  limparListaUsuarios() {
    this.store.usuarios = null;
  }
}
