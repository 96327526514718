import {Injectable} from '@angular/core';
import {PessoaTipo} from '@app/shared/enums/pessoa-tipo.enum';
import {NgxCurrencyConfig} from 'ngx-currency';

@Injectable({providedIn: 'root'})
export class MascaraService {
  decimal(decimals: number, allowNegative: boolean = true) {
    return <NgxCurrencyConfig>{
      align: 'right',
      allowNegative: allowNegative,
      allowZero: true,
      decimal: ',',
      precision: decimals,
      prefix: '',
      suffix: '',
      thousands: '.',
      nullable: true,
    };
  }
  inteiro() {
    return <NgxCurrencyConfig>{
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: 0,
      prefix: '',
      suffix: '',
      thousands: '.',
      nullable: true,
    };
  }
  percent(decimals: number, max?: number) {
    return <NgxCurrencyConfig>{
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: decimals,
      prefix: '',
      suffix: '',
      thousands: '.',
      nullable: true,
      max: max,
    };
  }
  documento(pessoaTipo: PessoaTipo) {
    return pessoaTipo === PessoaTipo.Juridica ? '00.000.000/0000-00' :
                                                '000.000.000-00';
  }
  placeholder(decimals: number) {
    return `0,${'0'.repeat(decimals)}`;
  }
}