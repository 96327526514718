<p-dialog
  header="Novo grupo"
  [style]="{ width: isDesktop ? '50%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="grupoForm.form"
  (onHide)="fecharModal()"
  (onShow)="aoAbrirModal()"
>
  <p-tabView
    [scrollable]="true"
    [formGroup]="grupoForm.form"
    data-cy="tabview-dados-cadastrais"
  >
    <p-tabPanel header="Dados do grupo" [selected]="true">
      <div class="formgrid grid">
        <!-- #region DESCRIÇÃO -->
        <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
          <app-custom-label texto="Descrição" [obrigatorio]="true">
          </app-custom-label>
          <app-text-input
            [control]="grupoForm.form.controls?.descricao"
            [autoFocus]="true"
            [maxlength]="60"
          >
          </app-text-input>
        </div>
        <!-- #endregion -->
        <!-- #region SEÇÃO -->
        <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
          <app-custom-label texto="Seção" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            optionLabel="descricao"
            optionValue="id"
            [control]="grupoForm.form.controls?.secaoId"
            [options]="secoes$ | async"
          >
          </app-dropdown-input>
        </div>
        <!-- #endregion -->
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
