import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {ParametroService} from '@app/abstraction/data/services/shared/parametro.service';
import {ParametroStore} from '@app/abstraction/data/stores/shared/parametro.store';
import {ParametroFiltro} from '@app/abstraction/domain/interfaces/configuracoes/parametro/parametro-filtro.interface';
import {ParametroListagem} from '@app/abstraction/domain/interfaces/configuracoes/parametro/parametro-listagem.interface';

@Injectable({providedIn: 'root'})
export class ParametroFacade {
  get parametros() {
    return this.store.parametros;
  }
  get documentoObrigatorio() {
    return this.buscarParametroLocal('DocumentoObrigatorio').valor as boolean;
  }
  get validarDocumento() {
    return this.buscarParametroLocal('ValidarDocumento').valor as boolean;
  }
  get prefixoCodigoBarra() {
    return this.buscarParametroLocal('PrefixoCodigoBarra').valor as number;
  }
  get quantidadeCasasDecimaisCamposValor() {
    return this.buscarParametroLocal('QuantidadeCasasDecimaisCamposValor')
               .valor as number;
  }
  get quantidadeCasasDecimaisCamposQuantidade() {
    return this.buscarParametroLocal('QuantidadeCasasDecimaisCamposQuantidade')
               .valor as number;
  }
  get quantidadeCasasDecimaisCamposPorcentagem() {
    return this.buscarParametroLocal('QuantidadeCasasDecimaisCamposPorcentagem')
               .valor as number;
  }
  get quantidadeCasasDecimaisCamposValorRelatorio() {
    return this.buscarParametroLocal(
                   'QuantidadeCasasDecimaisCamposValorRelatorio')
               .valor as number;
  }
  get quantidadeCasasDecimaisCamposQuantidadeRelatorio() {
    return this.buscarParametroLocal(
                   'QuantidadeCasasDecimaisCamposQuantidadeRelatorio')
               .valor as number;
  }
  get quantidadeCasasDecimaisCamposPorcentagemRelatorio() {
    return this.buscarParametroLocal(
                   'QuantidadeCasasDecimaisCamposPorcentagemRelatorio')
               .valor as number;
  }
  get vendedorModificarValorItemPedido() {
    return this.buscarParametroLocal('VendedorModificarValorItemPedido')
               .valor as boolean;
  }
  get vendedorOferecerDescontoPedido() {
    return this.buscarParametroLocal('VendedorOferecerDescontoPedido').valor as
        boolean;
  }
  get clienteObrigatorioPedido() {
    return this.buscarParametroLocal('ClienteObrigatorioPedido').valor as
        boolean;
  }
  get vendedorObrigatorioPedido() {
    return this.buscarParametroLocal('VendedorObrigatorioPedido').valor as
        boolean;
  }
  get movimentaEstoquePedido() {
    return this.buscarParametroLocal('MovimentaEstoquePedido').valor as boolean;
  }
  get areaEstoquePadraoPedido() {
    return this.buscarParametroLocal('AreaEstoquePadraoPedido').valor as string;
  }
  get codigoBarraSequencial() {
    return this.buscarParametroLocal('CodigoBarraSequencial').valor as boolean;
  }
  get consumidorFinalPadrao() {
    return this.buscarParametroLocal('ConsumidorFinalPadrao').valor as string;
  }
  get funcionarioPadrao() {
    return this.buscarParametroLocal('FuncionarioPadrao').valor as string;
  }
  get tipoCodigoBarras() {
    return this.buscarParametroLocal('TipoCodigoBarras').valor as string;
  }
  get contaBancariaPadrao() {
    return this.buscarParametroLocal('ContaBancariaPadrao').valor as string;
  }
  get planoContasPadrao() {
    return this.buscarParametroLocal('PlanoContas3Padrao').valor as string;
  }
  get utilizarDataVencimentoPrimeiraParcela() {
    return this.buscarParametroLocal('UtilizarDataVencimentoPrimeiraParcela')
               .valor as boolean;
  }
  get fusoHorario() {
    return this.buscarParametroLocal('FusoHorario').valor as string;
  }
  constructor(
      private parametroService: ParametroService,
      private store: ParametroStore,
  ) {}
  private converterParametro(param: ParametroListagem) {
    if (param.tipo == 'bool') {
      param.valor = param.valor?.toString() === 'true';
    } else if (param.tipo == 'int') {
      param.valor = Number(param.valor);
    }
  }
  async buscarParametrosEmpresaLogada(empresaSelecionadaId: string) {
    const parametros =
        await this.parametroService.buscarParametrosEmpresaLogada({
          chave: null,
          empresaId: empresaSelecionadaId,
        });
    for (const param of parametros) {
      this.converterParametro(param);
    }
    this.store.parametros = parametros;
  }
  async buscarParametros(filtro?: ParametroFiltro) {
    const parametros =
        await this.parametroService.buscarParametrosEmpresaLogada(filtro);
    for (const param of parametros) {
      this.converterParametro(param);
    }
    return parametros;
  }
  async salvarParametro(
      chave: string,
      valor: string,
      empresaId: string,
      empresaSelecionadaId: string,
  ) {
    await this.parametroService.salvarParametro(chave, valor, empresaId);
    let parametro = this.parametros.find((p) => p.chave == chave);

    if (empresaId == empresaSelecionadaId) {
      parametro.valor = valor;
      this.converterParametro(parametro as ParametroListagem);
    }
    return parametro;
  }
  buscarParametroLocal(chaveParametro?: string) {
    return this.parametros?.find(
        (p) => p.chave.toLowerCase() === chaveParametro.toLowerCase());
  }
}
