import {RegimeTributario} from '@app/shared/enums/regimes-tributarios.enum';
import {Guid} from 'guid-typescript';
import * as moment from 'moment';

import {UnidadeNotaFiscal} from './enum/unidade-nota-fiscal.enum';
import {NotaFiscalImposto} from './imposto.entity';

export class NotaFiscalItem {
  dataKey: string;
  cest: string;
  cfopEntrada: number;
  cfopNota: number;
  codigoBarras: string;
  codigoBarrasTributado: string;
  codigoProduto: string;
  descricao: string;
  excecaoTipi: string;
  imposto: NotaFiscalImposto;
  ncm: string;
  numeroItem: number;
  pedidoCompraItem: number;
  pedidoCompraNumero: string;
  variacaoId: string;
  quantidade: number;
  quantidadeTributada: number;
  unidade: UnidadeNotaFiscal;
  unidadeTributada: UnidadeNotaFiscal;
  valorDesconto: number;
  valorFrete: number;
  valorOutro: number;
  valorProduto: number;
  valorSeguro: number;
  valorUnitario: number;
  valorUnitarioTributado: number;
  areaEstoqueId: string;
  unidadeDefinidaEntrada: string;
  quantidadeDefinidaEntrada: number;
  quantidadeNota: number;
  margemCalculadaEntrada: number;
  valorCustoCalculadoEntrada: number;
  valorVendaCalculadoEntrada: number;
  valorVenda2: number;
  valorMargem2: number;
  cfopDefinidoEntrada: number;
  conciliado: boolean;
  estoqueAtual: number;
  dataValidade: Date;

  static from(json: any = {}): NotaFiscalItem {
    const item = new NotaFiscalItem();
    item.dataKey = json.dataKey ?? Guid.create().toString();
    item.cest = json.cest?.trim();
    item.cfopEntrada = json.cfopEntrada;
    item.cfopNota = json.cfopNota;
    item.codigoBarras = json.codigoBarras?.trim();
    item.codigoBarrasTributado = json.codigoBarrasTributado?.trim();
    item.codigoProduto = json.codigoProduto?.toString()?.trim();
    item.descricao = json.descricao?.trim();
    item.excecaoTipi = json.excecaoTipi?.trim();
    item.imposto = NotaFiscalImposto.from(json.imposto);
    item.ncm = json.ncm?.trim();
    item.numeroItem = json.numeroItem;
    item.pedidoCompraItem = json.pedidoCompraItem;
    item.pedidoCompraNumero = json.pedidoCompraNumero?.toString()?.trim();
    item.variacaoId = json.variacaoId;
    item.quantidade = json.quantidade;
    item.quantidadeTributada = json.quantidadeTributada;
    item.unidade = json.unidade?.trim();
    item.unidadeTributada = json.unidadeTributada?.trim();
    item.valorDesconto = json.valorDesconto;
    item.valorFrete = json.valorFrete;
    item.valorOutro = json.valorOutro;
    item.valorProduto = json.valorProduto;
    item.valorSeguro = json.valorSeguro;
    item.valorUnitario = json.valorUnitario;
    item.valorUnitarioTributado = json.valorUnitarioTributado;
    item.areaEstoqueId = json.areaEstoqueId;
    item.estoqueAtual = json.estoqueAtual;
    item.cfopDefinidoEntrada = json.cfopDefinidoEntrada
    item.quantidadeNota = json.quantidadeNota;

    item.unidadeDefinidaEntrada = json.unidadeDefinidaEntrada;
    item.quantidadeDefinidaEntrada = json.quantidadeDefinidaEntrada;
    item.margemCalculadaEntrada = json.margemCalculadaEntrada;
    item.valorCustoCalculadoEntrada = json.valorCustoCalculadoEntrada;
    item.valorVendaCalculadoEntrada = json.valorVendaCalculadoEntrada;
    item.valorVenda2 = json.valorVenda2;
    item.valorMargem2 = json.valorMargem2;
    item.conciliado = json.conciliado ?? false;

    item.dataValidade =
        json.dataValidade ? moment(json.dataValidade).toDate() : null;
    return item;
  }
  zerarImpostosParaConsumidorFinal(
      regimeTributario: RegimeTributario, consumidorFinal: boolean) {
    if (consumidorFinal &&
        (this.imposto.icmsCstCsosn == '10' ||
         this.imposto.icmsCstCsosn == '30' ||
         this.imposto.icmsCstCsosn == '70' ||
         this.imposto.icmsCstCsosn == '201' ||
         this.imposto.icmsCstCsosn == '202' ||
         this.imposto.icmsCstCsosn == '203')) {
      if (regimeTributario == RegimeTributario.RegimeNormal) {
        this.imposto.icmsCstCsosn = '60';
      } else {
        this.imposto.icmsCstCsosn = '500';
      }

      this.imposto.aliquotaCofins = 0;
      this.imposto.aliquotaDiferimento = 0;
      this.imposto.aliquotaIcms = 0;
      this.imposto.aliquotaIcmsFcp = 0;
      this.imposto.aliquotaIcmsST = 0;
      this.imposto.aliquotaIcmsSTFcp = 0;
      this.imposto.aliquotaIcmsSTFcpRetido = 0;
      this.imposto.aliquotaIpi = 0;
      this.imposto.aliquotaIvaMva = 0;
      this.imposto.aliquotaPis = 0;
      this.imposto.aliquotaReducaoIcms = 0;
      this.imposto.aliquotaReducaoIcmsST = 0;
      this.imposto.baseCalculoIcms = 0;
      this.imposto.baseCalculoIcmsFcp = 0;
      this.imposto.baseCalculoIcmsST = 0;
      this.imposto.baseCalculoIcmsSTFcp = 0;
      this.imposto.baseCalculoIcmsSTFcpRetido = 0;
      this.imposto.baseCalculoIcmsSTRetido = 0;
      this.imposto.baseCalculoIpi = 0;
      this.imposto.baseCalculoPisCofins = 0;
      this.imposto.modalidade = 0;
      this.imposto.modalidadeST = 0;
      this.imposto.valorCofins = 0;
      this.imposto.valorIcms = 0;
      this.imposto.valorIcmsDesonerado = 0;
      this.imposto.valorIcmsDiferido = 0;
      this.imposto.valorIcmsFcp = 0;
      this.imposto.valorIcmsOperacao = 0;
      this.imposto.valorIcmsST = 0;
      this.imposto.valorIcmsSTFcp = 0;
      this.imposto.valorIcmsSTFcpRetido = 0;
      this.imposto.valorIcmsSTRetido = 0;
      this.imposto.valorIpi = 0;
      this.imposto.valorPis = 0;
      this.imposto.valorTotalTributo = 0;
      this.valorUnitarioTributado = 0;
    }
  }
  calcularTotalProduto() {
    this.valorProduto = this.valorUnitario * this.quantidade;
  }
}
