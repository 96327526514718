import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Marca} from '@app/abstraction/domain/entities/produtos/marca/marca.entity';
import {MarcaFiltro} from '@app/abstraction/domain/interfaces/produtos/marca/marca-filtro.interface';
import {MarcaListagem} from '@app/abstraction/domain/interfaces/produtos/marca/marca-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MarcaService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Marca) {
    const response$ =
        this.client.post<Marca>(API.MARCA, dados)
            .pipe(map((json: Marca) => json ? Marca.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Marca, id: string) {
    const response$ =
        this.client.put<Marca>(`${API.MARCA}/${id}`, dados)
            .pipe(map((json: Marca) => json ? Marca.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ =
        this.client.put<boolean>(`${API.MARCA}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Marca>(`${API.MARCA}/${id}`)
            .pipe(map((json: Marca) => json ? Marca.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: MarcaFiltro) {
    const response$ = this.client.get<MarcaListagem[]>(
        this.geradorQueryString.gerar(`${API.MARCA}`, filtro));
    return lastValueFrom(response$);
  }
}
