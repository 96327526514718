<p-dropdown
  #contentInput
  appendTo="body"
  styleClass=" inputfield w-full"
  [options]="options"
  [placeholder]="placeholder"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [emptyMessage]="emptyMessage"
  [emptyFilterMessage]="emptyFilterMessage"
  [readonly]="readonly"
  [showClear]="showClear && !readonly"
  [group]="group"
  [filter]="showFilter"
  [ngClass]="{
    readonly: readonly
  }"
  [ngModelOptions]="modelOptions"
  (ngModelChange)="onNgModelChange($event)"
  [(ngModel)]="model"
>
  <ng-template let-group pTemplate="group" *ngIf="group">
    <strong>{{ group.label }}</strong>
  </ng-template>

  <ng-template let-item pTemplate="item" *ngIf="cortarTexto">
    <span>{{ cortarTextoDropdown(item.texto) }}</span>
  </ng-template>

  <ng-template let-item pTemplate="selectedItem" *ngIf="descricaoSigla">
    <div class="flex justify-content-between">
      <span>
        {{ item.descricao }}
      </span>
      <span>
        {{ item.sigla }}
      </span>
    </div>
  </ng-template>

  <ng-template let-item pTemplate="item" *ngIf="descricaoSigla">
    <div class="flex justify-content-between">
      <span>
        {{ item.descricao }}
      </span>
      <span>
        {{ item.sigla }}
      </span>
    </div>
  </ng-template>

  <ng-template let-corSelecionada pTemplate="selectedItem" *ngIf="templateCor">
    <div class="flex justify-content-between">
      <span>
        {{ corSelecionada.descricao }}
      </span>
      <div
        class="caixa-cor"
        [pTooltip]="corSelecionada.corCodigo"
        tooltipPosition="top"
        [ngStyle]="{ background: corSelecionada.corCodigo }"
      ></div>
    </div>
  </ng-template>
  
  <ng-template let-cor pTemplate="item" *ngIf="templateCor">
    <div class="flex justify-content-between">
      <span>
        {{ cor.descricao }}
      </span>
      <div
        class="caixa-cor"
        [pTooltip]="cor.corCodigo"
        tooltipPosition="top"
        [ngStyle]="{ background: cor.corCodigo }"
      ></div>
    </div>
  </ng-template>
</p-dropdown>
