import {Injectable} from '@angular/core';
import {ProdutoVariacaoReferenciaService} from '@app/abstraction/data/services/produtos/produto-variacao-referencia.service';
import {ProdutoVariacaoReferenciaStore} from '@app/abstraction/data/stores/produtos/produto-variacao-referencia.store';
import {ProdutoVariacaoReferencia} from '@app/abstraction/domain/entities/produtos/produto-variacao-referencia/produto-variacao-referencia.entity';
import {ProdutoVariacaoReferenciaFiltro} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-referencia/produto-variacao-referencia-filtro.interface';

@Injectable({providedIn: 'root'})
export class ProdutoVariacaoReferenciaFacade {
  get produtoVariacaoReferencias() {
    return this.store.produtoVariacaoReferencias;
  }
  get produtoVariacaoReferencias$() {
    return this.store.produtoVariacaoReferencias$;
  }
  constructor(
      private store: ProdutoVariacaoReferenciaStore,
      private service: ProdutoVariacaoReferenciaService) {}
  async adicionar(produtoVariacaoReferencia: ProdutoVariacaoReferencia) {
    const novoProdutoVariacaoReferencia =
        await this.service.adicionar(produtoVariacaoReferencia);
    if (novoProdutoVariacaoReferencia) {
      this.store.adicionar(novoProdutoVariacaoReferencia.toListagem());
    }
    return novoProdutoVariacaoReferencia;
  }
  async buscarProdutoVariacaoReferencias(filtro?:
                                             ProdutoVariacaoReferenciaFiltro) {
    const produtoVariacaoReferencias = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(produtoVariacaoReferencias);
  }
  async atualizarSituacao(referenciaId: string) {
    const produtoVariacaoReferencia =
        this.produtoVariacaoReferencias.find((p) => p.id === referenciaId);
    const status = await this.service.atualizarSituacao(
        referenciaId, !produtoVariacaoReferencia.ativo);
    produtoVariacaoReferencia.ativo = status;
    return status;
  }
  buscarPorId(referenciaId: string) {
    return this.service.buscarPorId(referenciaId);
  }
  alterar(produtoVariacaoReferencia: ProdutoVariacaoReferencia) {
    return this.service.alterar(
        produtoVariacaoReferencia, produtoVariacaoReferencia.id);
  }
  limparListaProdutoVariacaoReferencias() {
    this.store.produtoVariacaoReferencias = null;
  }
}
