export enum ModalidadeBaseCalculo {
  MargemValorAgregado,
  Pauta,
  PrecoTabeladoMaximo,
  ValorOperacao,
}

export const ModalidadeBaseCalculoLabels = new Map<number, string>([
  [ModalidadeBaseCalculo.MargemValorAgregado, 'MARGEM VALOR AGREGADO (%)'],
  [ModalidadeBaseCalculo.Pauta, 'PAUTA (R$)'],
  [ModalidadeBaseCalculo.PrecoTabeladoMaximo, 'PREÇO TABELADO MÁXIMO (R$)'],
  [ModalidadeBaseCalculo.ValorOperacao, 'VALOR DA OPERAÇÃO (R$)'],
]);
