import {Pipe, PipeTransform} from '@angular/core';
import {BancoFacade} from '@app/abstraction/domain/facades/financeiro/banco/banco.facade';
import {BancoListagem} from '@app/abstraction/domain/interfaces/financeiro/banco/banco-listagem.interface';

@Pipe({
  name: 'banco',
})
export class BancoPipe implements PipeTransform {
  constructor(private bancoFacade: BancoFacade) {}
  transform(codigo: string, campo: string = 'descricao'): any {
    if (codigo) {
      const banco =
          this.bancoFacade.bancos?.find(
              (banco: BancoListagem) => banco.codigo == codigo) as any;
      return banco?.[campo];
    }
    return '';
  }
}
