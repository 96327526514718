import '@app/shared/extensions/array.extension';

import { Injectable } from '@angular/core';
import { VersaoService } from '@app/abstraction/data/services/configuracoes/versao.service';
import { VersaoStore } from '@app/abstraction/data/stores/configuracoes/versao.store';

@Injectable({ providedIn: 'root' })
export class VersaoFacade {
  get versoes() {
    return this.store.versoes;
  }
  get versoes$() {
    return this.store.versoes$;
  }
  constructor(private service: VersaoService, private store: VersaoStore) {}
  async buscarVersoes() {
    const versoes = await this.service.buscarTodas();
    this.store.adicionarRange(versoes);
    return versoes;
  }

  async buscarUltimaVersao() {
    const versao = await this.service.buscarUltimaVersao();
    return versao;
  }

  limparListaVersoes() {
    this.store.versoes = null;
  }
}
