import {PlanoContasListagem} from '@app/abstraction/domain/interfaces/financeiro/plano-contas/plano-contas-listagem.interface';

export class PlanoContasNivel1 {
  id: string;
  contaCodigo: number;
  descricao: string;
  ativo: boolean;

  static from(json: any = {}) {
    const planoContasNivel1 = new PlanoContasNivel1();
    planoContasNivel1.ativo = json.ativo;
    planoContasNivel1.contaCodigo = json.contaCodigo;
    planoContasNivel1.descricao = json.descricao?.trim();
    planoContasNivel1.id = json.id;
    return planoContasNivel1;
  }
  toListagem() {
    return <PlanoContasListagem>{
      id: this.id,
      contaCodigo: this.contaCodigo,
      descricao: this.descricao,
      ativo: this.ativo,
    };
  }
}
