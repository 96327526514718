import {Injectable} from '@angular/core';
import {AdministradoraListagem} from '@app/abstraction/domain/interfaces/financeiro/administradora/administradora-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AdministradoraStore {
  private readonly _administradoras =
      new BehaviorSubject<AdministradoraListagem[]>(null);
  get administradoras(): AdministradoraListagem[] {
    return this._administradoras.getValue();
  }
  set administradoras(administradoras: AdministradoraListagem[]) {
    this._administradoras.next(administradoras);
  }
  get administradoras$() {
    return this._administradoras.asObservable();
  }

  adicionar(administradora: AdministradoraListagem) {
    if (!this.administradoras) {
      this.administradoras = [];
    }
    this.administradoras.push(administradora);
  }
  adicionarRange(administradoras: AdministradoraListagem[]) {
    if (!this.administradoras) {
      this.administradoras = [];
    }
    administradoras.forEach((administradora) => {
      if (!this.administradoras.some((c) => c.id === administradora.id)) {
        this.administradoras.push(administradora);
      }
    });
  }
}
