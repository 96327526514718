import {Injectable} from '@angular/core';
import {CarteiraListagem} from '@app/abstraction/domain/interfaces/financeiro/carteira/carteira-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CarteiraStore {
  private readonly _carteiras = new BehaviorSubject<CarteiraListagem[]>(null);
  get carteiras(): CarteiraListagem[] {
    return this._carteiras.getValue();
  }
  set carteiras(carteiras: CarteiraListagem[]) {
    this._carteiras.next(carteiras);
  }
  get carteiras$() {
    return this._carteiras.asObservable();
  }

  adicionar(carteira: CarteiraListagem) {
    if (!this.carteiras) {
      this.carteiras = [];
    }
    this.carteiras.push(carteira);
  }
  adicionarRange(carteiras: CarteiraListagem[]) {
    if (!this.carteiras) {
      this.carteiras = [];
    }
    carteiras.forEach((carteira) => {
      if (!this.carteiras.some((c) => c.id === carteira.id)) {
        this.carteiras.push(carteira);
      }
    });
  }
}
