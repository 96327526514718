<p-toolbar styleClass="header">
  <div class="p-toolbar-group-left">
    <a routerLink="" class="brand"
      ><img src="assets/imagens/g3-logo-light.png" /><strong>ERP</strong
      >Admin</a
    >
  </div>
  <div class="p-toolbar-group-right">
    <div
      class="inline-flex cursor-pointer dropdown"
      (click)="menu.toggle($event)"
    >
      <p-avatar
        image="assets/imagens/default-avatar.png"
        styleClass="mr-2"
        shape="circle"
      ></p-avatar>
      <div class="menu" [ngClass]="{ show: displayDropdown }">
        <span class="mr-1">{{ usuarioNome }}</span>
        <span class="caret"> <i class="pi pi-chevron-down"></i></span>
      </div>
    </div>
  </div>
</p-toolbar>
<p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
