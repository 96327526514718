export class ProdutoCcsApuradaConst {
  constructor(_codigo: string, _descricao: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
  }
  codigo: string;
  descricao: string;
}

export const ProdutoCcsApuradas = [
  new ProdutoCcsApuradaConst(
    '01',
    'CONTRIBUICAO NAO-CUMULATIVA APURADA A ALIQUOTA BASICA'
  ),
  new ProdutoCcsApuradaConst(
    '02',
    'CONTRIBUICAO NAO-CUMULATIVA APURADA A ALIQUOTAS DIFERENCIADAS'
  ),
  new ProdutoCcsApuradaConst(
    '03',
    'CONTRIBUICAO NAO-CUMULATIVA APURADA A ALIQUOTA POR UNIDADE DE MEDIDA DE PRODUTO'
  ),
  new ProdutoCcsApuradaConst(
    '04',
    'CONTRIBUICAO NAO-CUMULATIVA APURADA A ALIQUOTA BASICA – ATIVIDADE IMOBILIARIA'
  ),
  new ProdutoCcsApuradaConst(
    '31',
    'CONTRIBUICAO APURADA POR SUBSTITUICAO TRIBUTARIA'
  ),
  new ProdutoCcsApuradaConst(
    '32',
    'CONTRIBUICAO APURADA POR SUBSTITUICAO TRIBUTARIA – VENDAS A ZONA FRANCA DE MANAUS'
  ),
  new ProdutoCcsApuradaConst(
    '51',
    'CONTRIBUICAO CUMULATIVA APURADA A ALIQUOTA BASICA'
  ),
  new ProdutoCcsApuradaConst(
    '52',
    'CONTRIBUICAO CUMULATIVA APURADA A ALIQUOTAS DIFERENCIADAS'
  ),
  new ProdutoCcsApuradaConst(
    '53',
    'CONTRIBUICAO CUMULATIVA APURADA A ALIQUOTA POR UNIDADE DE MEDIDA DE PRODUTO'
  ),
  new ProdutoCcsApuradaConst(
    '54',
    'CONTRIBUICAO CUMULATIVA APURADA A ALIQUOTA BASICA – ATIVIDADE IMOBILIARIA'
  ),
  new ProdutoCcsApuradaConst(
    '70',
    'CONTRIBUICAO APURADA DA ATIVIDADE IMOBILIARIA - RET'
  ),
  new ProdutoCcsApuradaConst(
    '71',
    'CONTRIBUICAO APURADA DE SCP – INCIDENCIA NAO CUMULATIVA'
  ),
  new ProdutoCcsApuradaConst(
    '72',
    'CONTRIBUICAO APURADA DE SCP – INCIDENCIA CUMULATIVA'
  ),
  new ProdutoCcsApuradaConst(
    '99',
    'CONTRIBUICAO PARA O PIS/PASEP – FOLHA DE SALARIOS'
  ),
];
