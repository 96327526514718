
declare interface Object {
  isEmpty(): boolean;

  isNotEmpty(): boolean;

  _cloneDate(date: Date): Date;

  /**
   * Check if a string is a date.
   * @param date The string to check.
   * @return Whether the string is a date.
   */
  _isStringDate(date: any): boolean;

  /**
   * Creates a new object with the properties of the first object and the
   * properties of the second object. If both objects have properties with the
   * same name, the property of the second object is used.
   * @param {object} target The target object.
   * @param {object} sources The source object(s).
   * @returns {object} The new object.
   */
  assignWithDate(target: any, ...sources: any[]): any;
}

Object.prototype.isEmpty = function() {
  return this === undefined || this === null || this === '';
};

Object.prototype.isNotEmpty = function() {
  return !this.isEmpty();
};

Object.prototype._cloneDate = function(date) {
  return new Date(date);
};

Object.prototype._isStringDate = function(str: String) {
  try {
    return str.toString().includes('T') && str.toString().includes('Z');
  } catch (err) {
    return false;
  }
};


Object.prototype.assignWithDate = function(target, ...sources) {
  sources.forEach((source) => {
    Object.getOwnPropertyNames(source).forEach((prop) => {
      const descriptor = Object.getOwnPropertyDescriptor(source, prop);
      if (descriptor.enumerable) {
        if (descriptor.value && this._isStringDate(descriptor.value)) {
          target[prop] = this._cloneDate(descriptor.value);
        } else {
          Object.defineProperty(target, prop, descriptor);
        }
      }
    });
  });
  return target;
};