import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlanoContasNivel1} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-1.entity';
import {PlanoContasNivel2} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-2.entity';
import {PlanoContasNivel3} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-3.entity';
import {PlanoContaGruposFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas-grupo/plano-conta-grupos.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {PlanoContaForm} from '@app/abstraction/domain/forms/financeiro/plano-conta.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-plano-contas-cadastro-rapido-modal',
  templateUrl: './plano-contas-cadastro-rapido-modal.component.html',
})
export class PlanoContasCadastroRapidoModalComponent extends FormBaseComponent
    implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<any>();
  @Output() aoCancelar = new EventEmitter<any>();

  planoContasForm: PlanoContaForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get planosContasNivel1$() {
    return this.planoContasFacade.planosContasNivel1$;
  }
  get planosContasNivel2$() {
    return this.planoContasFacade.planosContasNivel2$;
  }
  get planoContaGrupos$() {
    return this.planoContaGruposFacade.planoContaGrupos$;
  }
  get inteiroMask() {
    return this.mascaraService.inteiro();
  }
  constructor(
      private planoContasFacade: PlanoContasFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private planoContaGruposFacade: PlanoContaGruposFacade,
      private mascaraService: MascaraService,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarPlanoContasNivel1(planoContas: any) {
    return this.planoContasFacade.adicionarPlanoContasNivel1(planoContas)
        .catch((e) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private async adicionarPlanoContasNivel3(planoContas: any) {
    return this.planoContasFacade.adicionarPlanoContasNivel3(planoContas)
        .catch((e) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private async adicionarPlanoContasNivel2(planoContas: any) {
    return this.planoContasFacade.adicionarPlanoContasNivel2(planoContas)
        .catch((e) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private criarForm() {
    this.planoContasForm = new PlanoContaForm(
        this.errorMessagesFacade,
        this.planoContasFacade,
    );
    this.planoContasForm.criarForm();
    this.planoContasForm.setarTipoFormulario();
  }

  private preencherObjeto() {
    let planoContas;

    switch (this.planoContasForm.nivelPlanoContas) {
      case 2:
        planoContas = PlanoContasNivel2.from(this.planoContasForm.form.value);
        break;
      case 3:
        planoContas = PlanoContasNivel3.from(this.planoContasForm.form.value);
        break;
      default:
        planoContas = PlanoContasNivel1.from(this.planoContasForm.form.value);
        break;
    }
    return planoContas;
  }
  private mostrarSucessoAoSalvar(planoContas: any) {
    this.toastrService.success(`Plano de contas cadastrado com sucesso`);
    this.aoSalvar.emit(planoContas);
    this.fecharModal();
    this.saveLoading = false;
  }
  private async adicionarPlanoContas(planoContas: any) {
    switch (this.planoContasForm.nivelPlanoContas) {
      case 2:
        planoContas = await this.adicionarPlanoContasNivel2(planoContas);
        break;
      case 3:
        planoContas = await this.adicionarPlanoContasNivel3(planoContas);
        break;
      default:
        planoContas = await this.adicionarPlanoContasNivel1(planoContas);
        break;
    }
    return planoContas;
  }
  async buscarPlanosContasPorNivel() {
    this.planoContasForm.buscarPlanosContasPorNivel();
  }
  async salvar() {
    try {
      this.saveLoading = true;

      let planoContas = this.preencherObjeto();

      const resultado = await this.adicionarPlanoContas(planoContas)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar plano de contas.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
    this.planoContasForm.nivelPlanoContas = 1;
  }
  fecharModal() {
    this.planoContasForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
    this.montarDropdownContaTipos();
    this.montarDropdownCodigoNaturezas();
    this.planoContaGruposFacade.buscarPlanoContaGrupos();
    this.planoContasFacade.buscarPlanosContasNivel1();
    this.planoContasFacade.buscarPlanosContasNivel2();
  }
}
