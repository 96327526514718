import {ContaBancariaBoletoCarteira} from './conta-bancaria-boleto-carteira.entity';

export class ContaBancariaBoleto {
  nossoNumeroInicial: number;
  codigoTransmissao: string;
  jurosMensais: number;
  multa: number;
  descontoVencimento: number;
  comprovanteRecebimento: boolean;
  instrucaoPagamentoVencimento: string;
  bancoProtesto: boolean;
  bancoDevolucao: boolean;
  contaBancariaBoletoCarteira: ContaBancariaBoletoCarteira[];

  static from(json: any = {}) {
    const contaBancariaBoleto = new ContaBancariaBoleto();
    contaBancariaBoleto.nossoNumeroInicial = json?.nossoNumeroInicial ?? 0;
    contaBancariaBoleto.codigoTransmissao = json.codigoTransmissao?.trim();
    contaBancariaBoleto.jurosMensais = json?.jurosMensais ?? 0;
    contaBancariaBoleto.multa = json?.multa ?? 0;
    contaBancariaBoleto.descontoVencimento = json?.descontoVencimento ?? 0;
    contaBancariaBoleto.comprovanteRecebimento = json.comprovanteRecebimento;
    contaBancariaBoleto.instrucaoPagamentoVencimento =
        json.instrucaoPagamentoVencimento?.trim();
    contaBancariaBoleto.bancoProtesto = json.bancoProtesto;
    contaBancariaBoleto.bancoDevolucao = json.bancoDevolucao;

    if (json.contaBancariaBoletoCarteira) {
      contaBancariaBoleto.contaBancariaBoletoCarteira =
          json.contaBancariaBoletoCarteira?.map(
              (cbbc: ContaBancariaBoletoCarteira) =>
                  ContaBancariaBoletoCarteira.from(cbbc)) ??
          [];
    }
    return contaBancariaBoleto;
  }
}
