import '@app/shared/extensions/string.extension';

import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Impressora} from '@app/abstraction/domain/entities/cadastros/impressora/impressora.entity';
import {ImpressoraFacade} from '@app/abstraction/domain/facades/cadastros/impressora/impressora.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ImpressoraSharedFormComponent} from '@app/shared/components/shared-forms/impressora-shared-form/impressora-shared-form.component';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-impressora-cadastro-rapido-modal',
  templateUrl: './impressora-cadastro-rapido-modal.component.html',
  styleUrls: ['./impressora-cadastro-rapido-modal.component.scss'],
})
export class ImpressoraCadastroRapidoModalComponent extends FormBaseComponent
    implements AfterViewInit {
  @ViewChild('formComponent') formComponent: ImpressoraSharedFormComponent;
  @Input() mostrarModal: boolean;
  @Input() impressora: Impressora;
  @Output() aoSalvar = new EventEmitter<Impressora>();
  @Output() aoCancelar = new EventEmitter();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private loadingService: NgxUiLoaderService,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private impressoraFacade: ImpressoraFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarImpressora(novoImpressora: Impressora) {
    return this.impressoraFacade.adicionar(novoImpressora).catch((e: any) => {
      this.loadingService.stop();
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(impressora: Impressora) {
    this.toastrService.success('Impressora cadastrada com sucesso');
    this.aoSalvar.emit(impressora);
    this.fecharModal();
    this.loadingService.stop();
  }
  async salvar() {
    try {
      this.loadingService.start();

      if (!this.formComponent.impressoraForm.isValid()) {
        this.loadingService.stop();
        return;
      }

      const impressora = this.formComponent.preencherObjeto();

      const resultado = await this.adicionarImpressora(impressora)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar impressora.');
      this.loadingService.stop();
    }
  }
  async aoAbrirModal() {
    this.formComponent.impressoraForm.criarForm();
    this.formComponent.impressoraForm.setarTipoFormulario(this.impressora);
  }
  fecharModal() {
    this.aoCancelar.emit();
  }
  ngAfterViewInit() {
    this.formComponent.criarForm();
  }
}
