import {Component, OnInit} from '@angular/core';
import {Modulo} from '@app/abstraction/domain/entities/shared/auth/modulo/modulo.entity';
import {Tela} from '@app/abstraction/domain/entities/shared/auth/modulo/tela.entity';
import {VersaoFacade} from '@app/abstraction/domain/facades/configuracoes/versao/versao.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {VersaoListagem} from '@app/abstraction/domain/interfaces/configuracoes/versao/versao-listagem.interface';
import {environment} from 'environments/environment';

import {LayoutService} from './service/app.layout.service';

@Component({selector: 'app-menu', templateUrl: './app.menu.component.html'})
export class AppMenuComponent implements OnInit {
  menu: any[] = [];
  menuFiltrado: any[] = [];
  filtro: string;
  versao: VersaoListagem;

  get empresaLogada() {
    return this.authFacade.empresaLogada;
  }
  get homologacao() {
    return !environment.production;
  }
  constructor(
      private authFacade: AuthFacade,
      public layoutService: LayoutService,
      private versaoFacade: VersaoFacade,
  ) {}

  private adicionarItemDashboard() {
    const dashboard = <any>{
      icon: 'pi pi-chart-pie',
      label: 'DASHBOARD',
      labelStyleClass: 'font-medium',
      routerLink: '/',
    };
    return dashboard;
  }
  private filtrarModulos(menu: any) {
    let modulos =
        menu.items
            .filter(
                (modulo: any) =>
                    modulo.label.toLowerCase().removeAcentos().includes(
                        this.filtro.toLowerCase().removeAcentos()) ||
                    modulo?.items?.some((tela: any) => {
                      return tela.label.toLowerCase().removeAcentos().includes(
                          this.filtro.toLowerCase().removeAcentos());
                    }))
            .map((modulo: any) => {
              return {
                icon: modulo.icon,
                label: modulo.label,
                labelStyleClass: modulo.labelStyleClass,
                items: modulo?.items?.some((tela: any) => {
                  return tela.label.toLowerCase().removeAcentos().includes(
                      this.filtro.toLowerCase().removeAcentos());
                }) ?
                    modulo?.items?.filter((tela: any) => {
                      return tela.label.toLowerCase().removeAcentos().includes(
                          this.filtro.toLowerCase().removeAcentos());
                    }) :
                    modulo?.items
              };
            });
    this.menuFiltrado.push({items: modulos});
  }
  private moduloMenu(modulo: Modulo) {
    return this.moduloItem(modulo);
  }
  private moduloItem(modulo: Modulo) {
    const moduloItem = <any>{
      icon: modulo.icone ?? 'pi pi-th-large',
      label: modulo.descricao.toUpperCase(),
      labelStyleClass: 'font-medium',
      routerLink: '',
      items: [],
    };
    modulo.telas.orderBy((tela: Tela) => tela.descricao).forEach((tela) => {
      moduloItem.items.push(<any>{
        label: tela.descricao,
        routerLink: `${tela.rota}`,
      });
    });

    return moduloItem;
  }
  private montarMenuLateral() {
    this.authFacade.usuarioLogado$.subscribe((usuario) => {
      if (usuario.modulos) {
        const model = <any>[
          {
            label: '',
            items: [
              this.adicionarItemDashboard(),
            ],
          },
        ];

        this.authFacade.usuarioLogado.modulos
            .orderBy((modulo: Modulo) => modulo.descricao)
            .forEach((m: Modulo) => {
              model.first().items.push(this.moduloMenu(m));
            });

        this.menu = model;
        this.menuFiltrado = model;
      }
    });
  }
  async buscarUltimaVersao() {
    this.versao = await this.versaoFacade.buscarUltimaVersao();
  }
  async ngOnInit() {
    await this.buscarUltimaVersao();
    this.montarMenuLateral();
  }
  filtrarItems() {
    let menuLista: any[] = [...this.menu];
    this.menuFiltrado = [];

    if (this.filtro === '') {
      this.menuFiltrado = [...this.menu];
      return;
    }

    menuLista.forEach((menu) => {
      if (menu.items.length) {
        this.filtrarModulos(menu);
        return;
      }

      if (menu.label.toLowerCase().removeAcentos().includes(
              this.filtro.toLowerCase().removeAcentos())) {
        this.menuFiltrado.push(menu);
      }
    });
  }
}
