import {Injectable} from '@angular/core';
import {NaturezaReceitaListagem} from '@app/abstraction/domain/interfaces/cadastros/natureza-receita/natureza-receita-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NaturezaReceitaStore {
  private readonly _naturezasReceita =
      new BehaviorSubject<NaturezaReceitaListagem[]>(null);
  get naturezasReceita(): NaturezaReceitaListagem[] {
    return this._naturezasReceita.getValue();
  }
  set naturezasReceita(naturezasReceita: NaturezaReceitaListagem[]) {
    this._naturezasReceita.next(naturezasReceita);
  }
  get naturezasReceita$() {
    return this._naturezasReceita.asObservable();
  }
  adicionar(naturezaReceita: NaturezaReceitaListagem) {
    if (!this.naturezasReceita) {
      this.naturezasReceita = [];
    }
    this.naturezasReceita.push(naturezaReceita);
  }
  adicionarRange(naturezasReceita: NaturezaReceitaListagem[]) {
    if (!this.naturezasReceita) {
      this.naturezasReceita = [];
    }
    naturezasReceita.forEach((naturezaReceita) => {
      if (!this.naturezasReceita.some((c) => c.id === naturezaReceita.id)) {
        this.naturezasReceita.push(naturezaReceita);
      }
    });
  }
}
