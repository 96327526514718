<p-dialog
  header="Nova tabela nutricional"
  [style]="{ width: isDesktop ? '80%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="tabelaNutricionalForm.form"
  (onHide)="fecharModal()"
  (onShow)="this.aoAbrirModal()"
>

<p-tabView [scrollable]="true" [formGroup]="tabelaNutricionalForm.form">
  <p-tabPanel header="Dados da tabela nutricional" [selected]="true">
    <div class="formgrid grid">
      <!-- #region PORÇAO DE -->
      <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12">
        <app-custom-label [obrigatorio]="true" texto="Porção de:">
        </app-custom-label>
        <app-text-input
          [autoFocus]="true"
          [control]="tabelaNutricionalForm.form.controls?.porcaoDe"
          [maxlength]="60"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region QUANTIDADE DA PORÇAO -->
      <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12">
        <app-custom-label
          [obrigatorio]="true"
          texto="Quantidade da porção"
        ></app-custom-label>
        <app-numeric-input
          [control]="tabelaNutricionalForm.form.controls?.porcaoQuantidade"
          [options]="quantidadeMask"
          [placeholder]="quantidadePlaceholder"
        ></app-numeric-input>
      </div>
      <!-- #endregion -->

      <!-- #region UNIDADE DA PORÇÃO -->
      <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12">
        <app-custom-label
          [obrigatorio]="true"
          texto="Unidade da porção"
        ></app-custom-label>
        <app-dropdown-input
          [control]="tabelaNutricionalForm.form.controls?.porcaoUnidade"
          [options]="unidadesPorcao"
          [showFilter]="true"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->
    </div>
    <p-accordion [multiple]="true" data-cy="accordion-quantidades">
      <div class="formgrid grid">
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Medida caseira">
            <div class="formgrid grid">
              <!-- #region MEDIDA CASEIRA -->
              <div
                class="field col-12 xl:col-12 lg:col-12 md:col-4 sm:col-12"
              >
                <app-custom-label texto="Parte inteira"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.medidaCaseiraParteInteira"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region PARCE DECIMAL -->
              <div
                class="field col-12 xl:col-12 lg:col-12 md:col-4 sm:col-12"
              >
                <app-custom-label texto="Parte decimal"></app-custom-label>
                <app-dropdown-input
                  [control]="tabelaNutricionalForm.form.controls?.medidaCaseiraParteDecimal"
                  [options]="medidasCaseirasParteDecimal"
                  [showClear]="true"
                  [showFilter]="true"
                ></app-dropdown-input>
              </div>
              <!-- #endregion -->

              <!-- #region UTILIZADA -->
              <div
                class="field col-12 xl:col-12 lg:col-12 md:col-4 sm:col-12"
              >
                <app-custom-label texto="Utilizada"></app-custom-label>
                <app-dropdown-input
                  [control]="tabelaNutricionalForm.form.controls?.medidaCaseiraUtilizada"
                  [options]="medidasCaseirasUtilizada"
                  [showClear]="true"
                  [showFilter]="true"
                ></app-dropdown-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Valor energético">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.valorEnergeticoQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.valorEnergeticoValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Proteína">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.proteinasQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.proteinasValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Gorduras saturadas">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.gordurasSaturadasQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.gordurasSaturadasValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Fibra alimentar">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.fibraAlimentarQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.fibraAlimentarValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Ferro">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.ferroQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.ferroValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Gordura trans">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.gorduraTransQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.gorduraTransValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Carboidratos">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.carboidratosQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.carboidratosValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Gorduras totais">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.gordurasTotaisQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.gordurasTotaisValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Colesterol">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.colesterolQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.colesterolValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Cálcio">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.calcioQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.calcioValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
        <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <p-accordionTab header="Sódio">
            <div class="formgrid grid">
              <!-- #region QUANTIDADE DA PORCAO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Quantidade da porção"
                ></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.sodioQuantidadePorcao"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region VALOR DIARIO -->
              <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
                <app-custom-label texto="Valor diário"></app-custom-label>
                <app-numeric-input
                  [control]="tabelaNutricionalForm.form.controls?.sodioValorDiario"
                  [options]="quantidadeMask"
                  [placeholder]="quantidadePlaceholder"
                ></app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </div>
      </div>
    </p-accordion>
  </p-tabPanel>
</p-tabView>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
