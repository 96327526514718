import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RegraFiscalFacade} from '@app/abstraction/domain/facades/fiscal/regra-fiscal/regra-fiscal.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {RegraFiscalFiltro} from '@app/abstraction/domain/interfaces/fiscal/regra-fiscal/regra-fiscal-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-regra-fiscal-busca-rapida',
  templateUrl: './regra-fiscal-busca-rapida.component.html',
})
export class RegraFiscalBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @Input() mostrarModal: boolean;
  @Input() devolucao: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  itemSelecionado: any;
  filtro = <RegraFiscalFiltro>{};
  get dados$() {
    return this.regraFiscalFacade.regrasFiscais$;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private regraFiscalFacade: RegraFiscalFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarRegraFiscalsComFiltro() {
    this.regraFiscalFacade.buscarRegrasFiscais({
      pagina: this.pagina,
      limite: this.limite,
      devolucao: this.devolucao,
      descricao: this.filtro.descricao,
      ativo: true,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'descricao',
        name: 'Descrição',
      },
      {
        field: 'cfopTributado',
        name: 'CFOP',
        cellClass: 'text-right',
        headerClass: 'text-right',
      },
      {
        field: 'cfopSt',
        name: 'CFOP ST',
        cellClass: 'text-right',
        headerClass: 'text-right',
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro = <RegraFiscalFiltro>{};
    this.regraFiscalFacade.limparListaRegrasFiscais();
    this.buscarRegraFiscalsComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.regraFiscalFacade.limparListaRegrasFiscais();
    this.buscarRegraFiscalsComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
}
