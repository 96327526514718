import {Injectable} from '@angular/core';
import {GrupoService} from '@app/abstraction/data/services/produtos/grupo.service';
import {GrupoStore} from '@app/abstraction/data/stores/produtos/grupo.store';
import {Grupo} from '@app/abstraction/domain/entities/produtos/grupo/grupo.entity';
import {GrupoFiltro} from '@app/abstraction/domain/interfaces/produtos/grupo/grupo-filtro.interface';

@Injectable({providedIn: 'root'})
export class GrupoFacade {
  get grupoPadrao() {
    return this.store.grupos?.find(
        (p) => p.descricao.toLowerCase() === 'geral');
  }
  get grupos() {
    return this.store.grupos;
  }
  get grupos$() {
    return this.store.grupos$;
  }
  constructor(private store: GrupoStore, private service: GrupoService) {}
  async adicionar(grupo: Grupo) {
    const novaGrupo = await this.service.adicionar(grupo);
    if (novaGrupo) {
      this.store.adicionar(novaGrupo.toListagem());
    }
    return novaGrupo;
  }
  async atualizarSituacao(id: string) {
    const grupo = this.grupos.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !grupo.ativo);
    grupo.ativo = status;
    return status;
  }
  async buscarPorSecaoId(filtro?: GrupoFiltro) {
    const grupos = await this.service.buscarTodos(filtro);
    if (grupos) {
      this.store.grupos = grupos;
    }
  }
  async buscarGrupos(filtro?: GrupoFiltro) {
    const grupos = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(grupos);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  alterar(grupo: Grupo) {
    return this.service.alterar(grupo, grupo.id);
  }
  limparListaGrupos() {
    this.store.grupos = null;
  }
}
