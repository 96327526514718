import {Injectable} from '@angular/core';
import {FamiliaService} from '@app/abstraction/data/services/produtos/familia.service';
import {FamiliaStore} from '@app/abstraction/data/stores/produtos/familia.store';
import {Familia} from '@app/abstraction/domain/entities/produtos/familia/familia.entity';
import {FamiliaFiltro} from '@app/abstraction/domain/interfaces/produtos/familia/familia-filtro.interface';

@Injectable({providedIn: 'root'})
export class FamiliaFacade {
  get familiaPadrao() {
    return this.store.familias?.find(
        (p) => p.descricao.toLowerCase() === 'geral');
  }
  get familias() {
    return this.store.familias;
  }
  get familias$() {
    return this.store.familias$;
  }
  constructor(private store: FamiliaStore, private service: FamiliaService) {}
  async adicionar(familia: Familia) {
    const novaFamilia = await this.service.adicionar(familia);
    if (novaFamilia) {
      this.store.adicionar(novaFamilia.toListagem());
    }
    return novaFamilia;
  }
  async atualizarSituacao(id: string) {
    const familia = this.familias.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !familia.ativo);
    familia.ativo = status;
    return status;
  }
  async buscarFamilias(filtro?: FamiliaFiltro) {
    const familias = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(familias);
  }
  alterar(familia: Familia) {
    return this.service.alterar(familia, familia.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaFamilias() {
    this.store.familias = null;
  }
}
