import {Injectable} from '@angular/core';
import {ClienteListagem} from '@app/abstraction/domain/interfaces/cadastros/cliente/cliente-listagem.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ClienteStore {
  private readonly _clientes = new BehaviorSubject<ClienteListagem[]>(null);

  get clientes(): ClienteListagem[] {
    return this._clientes.getValue();
  }
  set clientes(clientes: ClienteListagem[]) {
    this._clientes.next(clientes);
  }
  get clientes$(): Observable<ClienteListagem[]> {
    return this._clientes.asObservable();
  }
  adicionar(novoCliente: ClienteListagem) {
    if (!this.clientes) {
      this.clientes = [];
    }
    this.clientes.push(novoCliente);
  }
  adicionarRange(clientes: ClienteListagem[]) {
    if (!this.clientes) {
      this.clientes = [];
    }
    clientes.forEach((cliente) => {
      if (!this.clientes.some((c) => c.id === cliente.id)) {
        this.clientes.push(cliente);
      }
    });
  }
}
