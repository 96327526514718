import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Subgrupo} from '@app/abstraction/domain/entities/produtos/subgrupo/subgrupo.entity';
import {SubgrupoFiltro} from '@app/abstraction/domain/interfaces/produtos/subgrupo/subgrupo-filtro.interface';
import {SubgrupoListagem} from '@app/abstraction/domain/interfaces/produtos/subgrupo/subgrupo-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SubgrupoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Subgrupo) {
    const response$ =
        this.client.post<Subgrupo>(API.SUBGRUPO, dados)
            .pipe(map((json: Subgrupo) => json ? Subgrupo.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Subgrupo, id: string) {
    const response$ =
        this.client.put<Subgrupo>(`${API.SUBGRUPO}/${id}`, dados)
            .pipe(map((json: Subgrupo) => json ? Subgrupo.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.SUBGRUPO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Subgrupo>(`${API.SUBGRUPO}/${id}`)
            .pipe(map((json: Subgrupo) => json ? Subgrupo.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: SubgrupoFiltro) {
    const response$ = this.client.get<SubgrupoListagem[]>(
        this.geradorQueryString.gerar(API.SUBGRUPO, filtro));
    return lastValueFrom(response$);
  }
}
