import {PerfilListagem} from '@app/abstraction/domain/interfaces/cadastros/perfil/perfil-listagem.interface';

export class Perfil {
  ativo: boolean;
  descricao: string;
  id: string;
  modulos: string[];
  telas: string[];
  acoes: string[];
  dashboards: string[];

  constructor(value?: any) {
    this.ativo = value?.ativo;
    this.descricao = value?.descricao;
    this.modulos = value?.modulos;
    this.id = value?.id;
    this.telas = value?.telas;
    this.acoes = value?.acoes;
    this.dashboards = value?.dashboards;
  }
  static from(value: any) {
    const perfil = new Perfil();
    perfil.ativo = value.ativo;
    perfil.descricao = value.descricao?.trim();
    perfil.modulos = value.modulos;
    perfil.id = value.id;
    perfil.telas = value.telas;
    perfil.acoes = value.acoes;
    perfil.dashboards = value.dashboards;
    return perfil;
  }

  toAPI(
      telasSelecionadas: string[],
      modulosSelecionados: string[],
      dashboardsSelecionados: string[],
  ) {
    this.telas = telasSelecionadas;
    this.modulos = modulosSelecionados;
    this.dashboards = dashboardsSelecionados;
  }
  toListage() {
    return <PerfilListagem>{
      id: this.id,
      descricao: this.descricao,
      ativo: this.ativo,
    };
  }
}
