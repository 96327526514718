import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Transportadora } from '@app/abstraction/domain/entities/cadastros/transportadora/transportadora.entity';
import { LancamentoParcela } from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import { TransportadoraFiltro } from '@app/abstraction/domain/interfaces/cadastros/transportadora/transportadora-filtro.interface';
import { TransportadoraListagem } from '@app/abstraction/domain/interfaces/cadastros/transportadora/transportadora-listagem.interface';
import { API } from '@app/shared/constants/api.constant';
import { GeradorQueryStringHelper } from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import { lastValueFrom, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TransportadoraService {
  constructor(
    private geradorQueryString: GeradorQueryStringHelper,
    private client: HttpClient
  ) {}
  adicionar(dados: Transportadora) {
    const response$ = this.client
      .post<Transportadora>(API.CADASTRO_TRANSPORTADORA, dados)
      .pipe(
        map((json: Transportadora) => (json ? Transportadora.from(json) : null))
      );
    return lastValueFrom(response$);
  }
  alterar(dados: Transportadora, id: string) {
    const response$ = this.client
      .put<Transportadora>(`${API.CADASTRO_TRANSPORTADORA}/${id}`, dados)
      .pipe(
        map((json: Transportadora) => (json ? Transportadora.from(json) : null))
      );
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
      `${API.CADASTRO_TRANSPORTADORA}/${id}/${API.STATUS}`,
      situacao
    );
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ = this.client
      .get<Transportadora>(`${API.CADASTRO_TRANSPORTADORA}/${id}`)
      .pipe(
        map((json: Transportadora) => (json ? Transportadora.from(json) : null))
      );
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: TransportadoraFiltro) {
    const response$ = this.client.get<TransportadoraListagem[]>(
      this.geradorQueryString.gerar(`${API.CADASTRO_TRANSPORTADORA}`, filtro)
    );
    return lastValueFrom(response$);
  }
  buscarRegistrosFinanceiros(id: string) {
    const response$ = this.client.get<LancamentoParcela[]>(
      `${API.CADASTRO_TRANSPORTADORA}/${id}/registros-financeiros`
    );
    return lastValueFrom(response$);
  }
  excluir(id: string) {
    const response$ = this.client.delete<boolean>(
      `${API.CADASTRO_TRANSPORTADORA}/${id}`
    );
    return lastValueFrom(response$);
  }
}
