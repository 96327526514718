export enum TipoCodigoBarra {
  Ean8 = '0',
  Upca12 = '1',
  Ean13 = '2',
  Dun14 = '3',
  Code128 = '4',
}

export const TipoCodigoBarraLabels = new Map<string, string>([
  [TipoCodigoBarra.Ean8, 'Ean8'],
  [TipoCodigoBarra.Upca12, 'Upca12'],
  [TipoCodigoBarra.Ean13, 'Ean13'],
  [TipoCodigoBarra.Dun14, 'Dun14'],
  [TipoCodigoBarra.Code128, 'Code128'],
]);
