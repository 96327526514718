export enum Cnab {
  Nenhum,
  Cnab240,
  Cnab400,
}

export const CnabLabels = new Map<number, string>([
  [Cnab.Cnab240, '240'],
  [Cnab.Cnab400, '400'],
]);
