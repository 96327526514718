import {Injectable} from '@angular/core';
import {ProdutoService} from '@app/abstraction/data/services/produtos/produto.service';
import {ProdutoStore} from '@app/abstraction/data/stores/produtos/produto.store';
import {Produto} from '@app/abstraction/domain/entities/produtos/produto/produto.entity';
import {ProdutoFiltro} from '@app/abstraction/domain/interfaces/produtos/produto/produto-filtro.interface';
import {ProdutoSimplificadoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-simplificado-listagem.interface';
import {FileService} from '@app/shared/services/file/file.service';

@Injectable({providedIn: 'root'})
export class ProdutoFacade {
  get produtos() {
    return this.store.produtos;
  }
  get produtos$() {
    return this.store.produtos$;
  }
  get produtosSimplificados() {
    return this.store.produtosSimplificados;
  }
  get produtosSimplificados$() {
    return this.store.produtosSimplificados$;
  }
  constructor(
      private service: ProdutoService,
      private store: ProdutoStore,
      private fileService: FileService,
  ) {}
  async buscarProdutos(filtro?: ProdutoFiltro) {
    const produtos = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(produtos);
    return produtos;
  }

  async buscarProdutosSimplificados(filtro?: ProdutoFiltro) {
    const produtos = await this.service.buscarTodosSimplificados(filtro);
    this.store.adicionarRangeSimplificados(produtos);
    return produtos;
  }

  async buscarMovimentacoes(id: string) {
    var pedidos = await this.service.buscarMovimentacoes(id);
    return pedidos;
  }

  async atualizarSituacao(produtoId: string) {
    const produto = this.produtos.find((p) => p.id === produtoId);
    const status =
        await this.service.atualizarSituacao(produtoId, !produto.ativo);
    produto.ativo = status;
    return status;
  }
  async buscarProdutoPorProdutoVariacaoId(variacaoId: string) {
    const produtos =
        await this.service.buscarProdutoPorProdutoVariacaoId(variacaoId);
    return produtos.first();
  }
  async buscarPorCodigoBarras(codigoBarras: string):
      Promise<ProdutoSimplificadoListagem> {
    return (await this.buscarProdutosSimplificados({descricao: codigoBarras}))
        .first();
  }
  async downloadPlanilha() {
    const planilha = await this.service.downloadPlanilha();
    this.fileService.salvarArquivo(planilha, 'produtos-planilha-modelo.xlsx');
  }
  importarPlanilha(fileBase64: string) {
    return this.service.importarPlanilha(fileBase64);
  }
  adicionar(produto: Produto) {
    return this.service.adicionar(produto);
  }
  alterar(produto: Produto, produtoId: string) {
    return this.service.alterar(produto, produtoId);
  }
  buscarPorId(produtoId: string) {
    return this.service.buscarPorId(produtoId) as Promise<Produto>;
  }
  buscarEanDisponivel(quantidade: number) {
    return this.service.buscarEanDisponivel(quantidade);
  }
  buscarEanDisponivelProdutoPesado() {
    return this.service.buscarEanDisponivelProdutoPesado();
  }
  buscarTotalProdutos() {
    return this.service.buscarTotalProdutos();
  }
  copiarEstoques(empresaOrigemId: string, empresaDestinoId: string) {
    return this.service.copiarEstoques(empresaOrigemId, empresaDestinoId);
  }
  copiarPrecos(empresaOrigemId: string, empresaDestinoId: string) {
    return this.service.copiarPrecos(empresaOrigemId, empresaDestinoId);
  }
  excluirProduto(id: string) {
    return this.service.excluirProduto(id);
  }
  limparListaProdutos() {
    this.store.produtos = null;
  }
  limparListaProdutosSimplificados() {
    this.store.produtosSimplificados = null;
  }
  gerarCodigosSku(quantidade: number, descricao: string) {
    return this.service.gerarCodigosSku(quantidade, descricao);
  }
}
