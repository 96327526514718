import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ProdutoVariacaoCor} from '@app/abstraction/domain/entities/produtos/produto-variacao-cor/produto-variacao-cor.entity';
import {ProdutoVariacaoCorFiltro} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-cor/produto-variacao-cor-filtro.interface';
import {ProdutoVariacaoCorListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-cor/produto-variacao-cor-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProdutoVariacaoCorService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}

  adicionar(dados: ProdutoVariacaoCor) {
    const response$ =
        this.client.post<ProdutoVariacaoCor>(API.PRODUTO_VARIACAO_COR, dados)
            .pipe(
                map((json: ProdutoVariacaoCor) =>
                        json ? ProdutoVariacaoCor.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: ProdutoVariacaoCor, id: string) {
    const response$ =
        this.client
            .put<ProdutoVariacaoCor>(`${API.PRODUTO_VARIACAO_COR}/${id}`, dados)
            .pipe(
                map((json: ProdutoVariacaoCor) =>
                        json ? ProdutoVariacaoCor.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.PRODUTO_VARIACAO_COR}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<ProdutoVariacaoCor>(`${API.PRODUTO_VARIACAO_COR}/${id}`)
            .pipe(
                map((json: ProdutoVariacaoCor) =>
                        json ? ProdutoVariacaoCor.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: ProdutoVariacaoCorFiltro) {
    if (filtro?.corCodigo) {
      filtro.corCodigo = filtro.corCodigo?.replace('#', '%23');
    }
    const response$ = this.client.get<ProdutoVariacaoCorListagem[]>(
        this.geradorQueryString.gerar(API.PRODUTO_VARIACAO_COR, filtro));
    return lastValueFrom(response$);
  }
}
