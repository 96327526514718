import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClienteSituacao} from '@app/abstraction/domain/entities/cadastros/cliente-situacao/cliente-situacao.entity';
import {ClienteSituacaoFacade} from '@app/abstraction/domain/facades/cadastros/cliente-situacao/cliente-situacao.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ClienteSituacaoForm} from '@app/abstraction/domain/forms/cadastros/cliente-situacao.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-cliente-situacao-cadastro-rapido-modal',
  templateUrl: './cliente-situacao-cadastro-rapido-modal.component.html',
})
export class ClienteSituacaoCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<ClienteSituacao>();
  @Output() aoCancelar = new EventEmitter();

  clienteSituacaoForm: ClienteSituacaoForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private situacaoFacade: ClienteSituacaoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarClienteSituacao(clienteSituacao: ClienteSituacao) {
    return this.situacaoFacade.adicionar(clienteSituacao).catch((e: any) => {
      this.saveLoading = false;
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(clienteSituacao: ClienteSituacao) {
    this.toastrService.success(`Situação do cliente cadastrada com sucesso`);
    this.aoSalvar.emit(clienteSituacao);
    this.fecharModal();
    this.saveLoading = false;
  }
  private preencherObjeto() {
    const clienteSituacao =
        ClienteSituacao.from(this.clienteSituacaoForm.form.value);
    clienteSituacao.toAPI();
    return clienteSituacao;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.clienteSituacaoForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const clienteSituacao = this.preencherObjeto();
      const resultado = await this.adicionarClienteSituacao(clienteSituacao)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar situação do cliente.');
    } finally {
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  criarForm() {
    this.clienteSituacaoForm = new ClienteSituacaoForm(
        this.errorMessagesFacade,
    );
    this.clienteSituacaoForm.criarForm();
    this.clienteSituacaoForm.setarTipoFormulario();
  }
  fecharModal() {
    this.clienteSituacaoForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
  }
}
