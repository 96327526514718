<p-dialog
  header="Lista de regras fiscais"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
  >
    <div class="formgrid grid mt-1">
      <div class="field col-12 md:col-6">
        <app-text-input-ngmodel
          placeholder="Buscar por CFOP/Descrição"
          (modelChange)="filtrarComTimeout()"
          [(model)]="filtro.descricao"
        ></app-text-input-ngmodel>
      </div>
    </div>
    <app-table
      (aoSelecionar)="setarItemSelecionado($event)"
      [columns]="colunas"
      [rows]="dados$ | async"
      [selectable]="true"
      selectionMode="single"
      [selecteds]="itemSelecionado"
    >
    </app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>
<ng-template #situacaoTemp let-value="value">
  <p-tag
    *ngIf="value"
    styleClass="mr-2"
    severity="success"
    value="Ativo"
  ></p-tag>
  <p-tag
    *ngIf="!value"
    styleClass="mr-2"
    severity="danger"
    value="Inativo"
  ></p-tag>
</ng-template>
