import {Injectable} from '@angular/core';
import {LancamentoParcelaListagem} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LancamentoStore {
  private readonly _lancamentosPagar =
      new BehaviorSubject<LancamentoParcelaListagem[]>(null);

  private readonly _lancamentosReceber =
      new BehaviorSubject<LancamentoParcelaListagem[]>(null);

  get lancamentosPagar(): LancamentoParcelaListagem[] {
    return this._lancamentosPagar.getValue();
  }
  set lancamentosPagar(lancamentosPagar: LancamentoParcelaListagem[]) {
    this._lancamentosPagar.next(lancamentosPagar);
  }
  get lancamentosPagar$() {
    return this._lancamentosPagar.asObservable();
  }

  get lancamentosReceber(): LancamentoParcelaListagem[] {
    return this._lancamentosReceber.getValue();
  }
  set lancamentosReceber(lancamentosReceber: LancamentoParcelaListagem[]) {
    this._lancamentosReceber.next(lancamentosReceber);
  }
  get lancamentosReceber$() {
    return this._lancamentosReceber.asObservable();
  }

  adicionarPagar(lancamentoPagar: LancamentoParcelaListagem) {
    if (!this.lancamentosPagar) {
      this.lancamentosPagar = [];
    }
    this.lancamentosPagar.push(lancamentoPagar);
  }
  alterarPagar(lancamentoPagar: LancamentoParcelaListagem) {
    const index = this.lancamentosPagar.findIndex(
        (e) => e.parcelaId == lancamentoPagar.parcelaId);
    this.lancamentosPagar[index] = lancamentoPagar;
  }
  adicionarRangePagar(lancamentosPagar: LancamentoParcelaListagem[]) {
    if (!this.lancamentosPagar) {
      this.lancamentosPagar = [];
    }
    lancamentosPagar.forEach((lancamentoPagar) => {
      if (!this.lancamentosPagar.some(
              (c) => c.parcelaId === lancamentoPagar.parcelaId)) {
        this.lancamentosPagar.push(lancamentoPagar);
      }
    });
  }
  alterarRangePagar(lancamentosExtornadas: LancamentoParcelaListagem[]) {
    lancamentosExtornadas.forEach((lancamentoExtornada) => {
      const index = this.lancamentosPagar.findIndex(
          (e) => e.parcelaId == lancamentoExtornada.parcelaId);
      this.lancamentosPagar[index] = lancamentoExtornada;
    });
  }

  adicionarReceber(lancamentoReceber: LancamentoParcelaListagem) {
    if (!this.lancamentosReceber) {
      this.lancamentosReceber = [];
    }
    this.lancamentosReceber.push(lancamentoReceber);
  }
  alterarReceber(lancamentoReceber: LancamentoParcelaListagem) {
    const index = this.lancamentosReceber.findIndex(
        (e) => e.parcelaId == lancamentoReceber.parcelaId);
    this.lancamentosReceber[index] = lancamentoReceber;
  }
  adicionarRangeReceber(lancamentosReceber: LancamentoParcelaListagem[]) {
    if (!this.lancamentosReceber) {
      this.lancamentosReceber = [];
    }
    lancamentosReceber.forEach((lancamentoReceber) => {
      if (!this.lancamentosReceber.some(
              (c) => c.parcelaId === lancamentoReceber.parcelaId)) {
        this.lancamentosReceber.push(lancamentoReceber);
      }
    });
  }
  alterarRangeReceber(lancamentosExtornadas: LancamentoParcelaListagem[]) {
    lancamentosExtornadas.forEach((lancamentoExtornada) => {
      const index = this.lancamentosReceber.findIndex(
          (e) => e.parcelaId == lancamentoExtornada.parcelaId);
      this.lancamentosReceber[index] = lancamentoExtornada;
    });
  }
}
