export class ProdutoFood {
  taxaServico: boolean;
  rodizio: boolean;
  pesado: boolean;
  combo: boolean;
  complemento: boolean;
  controlado: boolean;
  semValor: boolean;

  static from(json: any = {}) {
    const produtoFood = new ProdutoFood();
    produtoFood.taxaServico = json.taxaServico;
    produtoFood.rodizio = json.rodizio;
    produtoFood.pesado = json.pesado;
    produtoFood.combo = json.combo;
    produtoFood.complemento = json.complemento;
    produtoFood.controlado = json.controlado;
    produtoFood.semValor = json.semValor;
    return produtoFood;
  }
}
