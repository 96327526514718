<div class="acoes-massa" [ngClass]="{ show: mostrarMenu }">
  <div class="lista-botoes">
    <p-button
      styleClass="ml-1 mb-1 btn-acoes-massa"
      [style]="{ 'min-height': 80, 'min-width': 80 }"
      *ngFor="let acao of acoesMassa"
      (onClick)="executarAcaoEmMassa(acao)"
      [disabled]="
        acao.carregando || (acao.bloquearBotao ? acao.bloquearBotao() : false)
      "
    >
      <div
        class="btn-content"
        [pTooltip]="acao.tooltip ?? acao.texto"
        tooltipPosition="top"
      >
        <span [ngClass]="acao.carregando ? iconeSpinner : acao.icone"></span>
        <span [ngClass]="[acao.textoStyleClass]">{{ acao.texto }}</span>
      </div>
    </p-button>
  </div>
</div>
