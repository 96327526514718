export class NotaFiscalVolume {
  quantidade: number;
  especie: string;
  marca: string;
  numeracao: string;
  pesoLiquido: number;
  pesoBruto: number;
  lacres: string;

  static from(json: any = {}): NotaFiscalVolume {
    const volume = new NotaFiscalVolume();
    volume.quantidade = json.quantidade;
    volume.especie = json.especie;
    volume.marca = json.marca;
    volume.numeracao = json.numeracao;
    volume.pesoLiquido = json.pesoLiquido;
    volume.pesoBruto = json.pesoBruto;
    volume.lacres = json.lacres;
    return volume;
  }
}