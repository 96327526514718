import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {Endereco} from '@app/abstraction/domain/entities/shared/endereco.entity';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-endereco-busca-rapida',
  templateUrl: './endereco-busca-rapida.component.html',
})
export class EnderecoBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('cepTemp', {static: true}) cepTemp: TemplateRef<any>;
  @ViewChild('enderecoTipoTemp', {static: true})
  enderecoTipoTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Input() enderecos: Endereco[];
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  filtro: string;
  itemSelecionado: any;

  get dados() {
    return this.enderecos;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'enderecoTipoId',
        name: 'Tipo de endereço',
        cellTemplate: this.enderecoTipoTemp,
      },
      {
        field: 'cep',
        name: 'CEP',
        cellTemplate: this.cepTemp,
      },
      {
        field: 'logradouro',
        name: 'Logradouro',
      },
      {
        field: 'numero',
        name: 'Número',
      },
      {
        field: 'bairro',
        name: 'Bairro',
      },
      {
        field: 'uf',
        name: 'UF',
      },
      {
        field: 'ativo',
        name: 'Situação',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.situacaoTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro = null;
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
}
