import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {NcmFacade} from '@app/abstraction/domain/facades/fiscal/ncm/ncm.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-ncm-busca-rapida',
  templateUrl: './ncm-busca-rapida.component.html',
})
export class NcmBuscaRapidaComponent extends ListagemBaseComponent implements
    OnInit {
  @ViewChild('ncmExcecaoTemp', {static: true}) ncmExcecaoTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  filtro: string;
  itemSelecionado: any;
  get dados$() {
    return this.ncmFacade.ncms$;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private ncmFacade: NcmFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarNcmsComFiltro() {
    this.ncmFacade.buscarNcms({
      pagina: this.pagina,
      limite: this.limite,
      descricao: this.filtro,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'ncmCodigo',
        name: 'Código NCM',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: 'ncmExcecaoTipi',
        name: 'Exceção TIPI',
        headerClass: 'text-right',
        cellClass: 'text-right',

        cellTemplate: this.ncmExcecaoTemp,
      },
      {
        field: 'descricao',
        name: 'Descrição',
      }
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro = null;
    this.ncmFacade.limparListaNcms();
    this.buscarNcmsComFiltro();
  }
  buscarNcmsProximaPagina() {
    this.pagina++;
    this.buscarNcmsComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.ncmFacade.limparListaNcms();
    this.buscarNcmsComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
    this.ncmFacade.limparListaNcms();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.ncmFacade.limparListaNcms();
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
}
