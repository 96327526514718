import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ClienteSituacao} from '@app/abstraction/domain/entities/cadastros/cliente-situacao/cliente-situacao.entity';
import {ClienteSituacaoFiltro} from '@app/abstraction/domain/interfaces/cadastros/cliente-situacao/cliente-situacao-filtro.interface';
import {ClienteSituacaoListagem} from '@app/abstraction/domain/interfaces/cadastros/cliente-situacao/cliente-situacao-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ClienteSituacaoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}
  adicionar(dados: ClienteSituacao) {
    const response$ =
        this.client.post<ClienteSituacao>(API.CLIENTE_SITUACAO, dados)
            .pipe(
                map((json: ClienteSituacao) =>
                        json ? ClienteSituacao.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: ClienteSituacao, id: string) {
    const response$ =
        this.client.put<ClienteSituacao>(`${API.CLIENTE_SITUACAO}/${id}`, dados)
            .pipe(
                map((json: ClienteSituacao) =>
                        json ? ClienteSituacao.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.CLIENTE_SITUACAO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<ClienteSituacao>(`${API.CLIENTE_SITUACAO}/${id}`)
            .pipe(
                map((json: ClienteSituacao) =>
                        json ? ClienteSituacao.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: ClienteSituacaoFiltro) {
    const response$ = this.client.get<ClienteSituacaoListagem[]>(
        this.geradorQueryString.gerar(API.CLIENTE_SITUACAO, filtro));
    return lastValueFrom(response$);
  }
}
