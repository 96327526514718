import {RegraFiscalListagem} from '@app/abstraction/domain/interfaces/fiscal/regra-fiscal/regra-fiscal-listagem.interface';
export class RegraFiscal {
  aplicarRegrasFiscais: boolean;
  ativo: boolean;
  calculaIcmsIpi: boolean;
  cfopSt?: string;
  cfopTributado?: string;
  descricao?: string;
  devolucao: boolean;
  estoque: boolean;
  financeiro: boolean;
  fiscal: boolean;
  geraCreditoDebitoIcms: boolean;
  geraCreditoDebitoIpi: boolean;
  id: string;
  incidePisCofins: boolean;
  operacao: number;

  static from(json: any = {}) {
    const codigoFiscal = new RegraFiscal();
    codigoFiscal.id = json.id;
    codigoFiscal.descricao = json.descricao?.trim();
    codigoFiscal.cfopSt = json.cfopSt?.toString().trim();
    codigoFiscal.cfopTributado = json.cfopTributado?.trim();
    codigoFiscal.operacao = json.operacao;
    codigoFiscal.devolucao = json.devolucao;
    codigoFiscal.incidePisCofins = json.incidePisCofins;
    codigoFiscal.geraCreditoDebitoIcms = json.geraCreditoDebitoIcms;
    codigoFiscal.geraCreditoDebitoIpi = json.geraCreditoDebitoIpi;
    codigoFiscal.calculaIcmsIpi = json.calculaIcmsIpi;
    codigoFiscal.financeiro = json.financeiro;
    codigoFiscal.fiscal = json.fiscal;
    codigoFiscal.estoque = json.estoque;
    codigoFiscal.aplicarRegrasFiscais = json.aplicarRegrasFiscais;
    return codigoFiscal;
  }
  toListagem() {
    return <RegraFiscalListagem>{
      aplicarRegrasFiscais: this.aplicarRegrasFiscais,
      ativo: this.ativo,
      calculaIcmsIpi: this.calculaIcmsIpi,
      cfopSt: this.cfopSt,
      cfopTributado: this.cfopTributado,
      descricao: this.descricao,
      devolucao: this.devolucao,
      financeiro: this.financeiro,
      geraCreditoDebitoIcms: this.geraCreditoDebitoIcms,
      geraCreditoDebitoIpi: this.geraCreditoDebitoIpi,
      id: this.id,
      incidePisCofins: this.incidePisCofins,
      operacao: this.operacao,
    };
  }
}
