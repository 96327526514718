import {PessoaTipo} from '@app/shared/enums/pessoa-tipo.enum';

import {Endereco} from './endereco.entity';

export class NotaFiscalDestinatario {
  pessoaTipo: PessoaTipo;
  documento: string;
  email: string;
  endereco: Endereco;
  ie: string;
  nomeFantasia: string;
  razaoSocial: string;

  static from(json: any = {}): NotaFiscalDestinatario {
    const destinatario = new NotaFiscalDestinatario();
    destinatario.pessoaTipo = json.pessoaTipo;
    destinatario.documento = json.documento?.somenteNumeros()?.trim();
    destinatario.email = json.email?.trim();
    destinatario.endereco = Endereco.from(json.endereco);
    destinatario.ie = json.ie?.trim();
    destinatario.nomeFantasia = json.nomeFantasia?.trim();
    destinatario.razaoSocial = json.razaoSocial?.trim();

    return destinatario;
  }
  preencherPessoaTipo() {
    this.pessoaTipo = this.documento.length <= 11 ? 1 : 2;
  }
}
