import {FormControl, FormGroup} from '@angular/forms';
import {NaturezaReceita} from '@app/abstraction/domain/entities/cadastros/natureza-receita/natureza-receita.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface INaturezaReceitaForm {
  cstPisCofinsSaida: FormControl<string>;
  descricao: FormControl<string>;
  naturezaReceitaCodigo: FormControl<number>;
  id: FormControl<string>;
}

export class NaturezaReceitaForm extends BaseForm {
  form: FormGroup<INaturezaReceitaForm>;
  operacaoForm: OperacaoForm;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova natureza da receita' :
                                        'Editar natureza da receita';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(private errorMessagesFacade: ErrorMessageFacade) {
    super();
  }
  private preencherForm(naturezaReceita: NaturezaReceita) {
    this.form.patchValue(naturezaReceita);
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  criarForm() {
    this.form = new FormGroup<INaturezaReceitaForm>({
      id: new FormControl(null),
      descricao: new FormControl(
          null, CustomValidators.required('Descrição é obrigatória')),
      naturezaReceitaCodigo: new FormControl(
          null, CustomValidators.required('Código é obrigatório')),
      cstPisCofinsSaida:
          new FormControl(null, CustomValidators.required('CST é obrigatório')),
    });
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(NaturezaReceita.from(value));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
}
