import {Pipe, PipeTransform} from '@angular/core';
import {GrupoFacade} from '@app/abstraction/domain/facades/produtos/grupo/grupo.facade';
import {GrupoListagem} from '@app/abstraction/domain/interfaces/produtos/grupo/grupo-listagem.interface';

@Pipe({
  name: 'grupo',
})
export class GrupoPipe implements PipeTransform {
  constructor(private grupoCadastrofacade: GrupoFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const grupo = this.grupoCadastrofacade.grupos.find(
                        (grupo: GrupoListagem) => grupo.id == id) as any;
      return grupo?.[campo];
    }
    return '';
  }
}
