<label class="custom-label" [ngClass]="{ 'label-tab': labelTab }">
  <span class="font-medium"
    >{{ texto }}<span class="p-error" *ngIf="obrigatorio">*</span
    ><span
      class="ml-1"
      *ngIf="textoSecundario"
      [ngStyle]="{ color: colorTextoSecundario }"
      >{{ textoSecundario }}</span
    ></span
  >
  <i
    *ngIf="cadastroRapido"
    class="pi pi-plus text-orange-500"
    (click)="adicionar()"
    pTooltip="Adicionar"
    tooltipPosition="bottom"
  ></i>
  <i
    *ngIf="info"
    class="pi pi-info-circle text-orange-500"
    [pTooltip]="info"
  ></i>
  <i
    *ngIf="showIcon"
    [class]="iconOptions?.icon"
    [pTooltip]="iconOptions?.tooltip"
    [tooltipPosition]="iconOptions?.tooltipPosition ?? 'right'"
    (click)="iconOptions?.onClick()"
  >
    <ng-template #tooltipContent> Teste </ng-template>
  </i>
</label>
