export class Contato {
  ativo: boolean;
  id: string;
  principal: boolean;
  email: string;
  fone1: string;
  fone2: string;
  fone3: string;
  nome: string;
  pessoaId: string;

  static from(json: any = {}): Contato {
    const contato = new Contato();
    contato.ativo = json.ativo;
    contato.id = json.id;
    contato.principal = json.principal;
    contato.email = json.email?.trim();
    contato.fone1 = json.fone1?.somenteNumeros()?.trim();
    contato.fone2 = json.fone2?.somenteNumeros()?.trim();
    contato.fone3 = json.fone3?.somenteNumeros()?.trim();
    contato.nome = json.nome?.trim();
    contato.pessoaId = json.pessoaId;
    return contato;
  }
}
