import {ClienteSituacaoListagem} from '@app/abstraction/domain/interfaces/cadastros/cliente-situacao/cliente-situacao-listagem.interface';
export class ClienteSituacao {
  ativo: boolean;
  id: string;
  descricao: string;
  situacao: string|number;

  static from(json: any = {}): ClienteSituacao {
    const clienteSituacao = new ClienteSituacao();
    clienteSituacao.ativo = json.ativo;
    clienteSituacao.id = json.id;
    clienteSituacao.descricao = json.descricao?.trim();
    clienteSituacao.situacao = json.situacao;
    return clienteSituacao;
  }
  toAPI() {
    this.situacao = Number(this.situacao);
  }
  toListagem() {
    return <ClienteSituacaoListagem>{
      ativo: this.ativo,
      id: this.id,
      descricao: this.descricao,
      situacao: this.situacao,
    };
  }
}
