import {NcmListagem} from '@app/abstraction/domain/interfaces/fiscal/ncm/ncm-listagem.interface';

import {NcmIcms} from './ncm-icms.entity';
import {NcmPisCofinsIpi} from './ncm-pis-cofins-ipi.entity';
import {NcmUf} from './ncm-uf.entity';

export class Ncm {
  ativo: boolean;
  ncmCodigo: string;
  ncmExcecaoTipi: number|string;
  descricao: string;
  ncmIcms: NcmIcms[];
  ncmPisCofinsIpi: NcmPisCofinsIpi;
  configurado: boolean;
  ncmUf: NcmUf[];

  private completarCamposDosNcmUf() {
    if (this.ncmUf) {
      this.ncmUf.forEach((ncmUf) => {
        ncmUf.ncmCodigo = this.ncmCodigo;
        ncmUf.ncmExcecaoTipi = this.ncmExcecaoTipi;
      });
    }
  }
  private completarCamposDosNcmIcms() {
    if (this.ncmIcms) {
      this.ncmIcms.forEach((ncmIcms) => {
        ncmIcms.ncmCodigo = this.ncmCodigo;
        ncmIcms.ncmExcecaoTipi = this.ncmExcecaoTipi;
      });
    }
  }
  private preencherCamposNcmPisCofinsIpi() {
    this.ncmPisCofinsIpi.pisAliquota = this.ncmPisCofinsIpi.pisAliquota;
    this.ncmPisCofinsIpi.cofinsAliquota = this.ncmPisCofinsIpi.cofinsAliquota;
    this.ncmPisCofinsIpi.ipiAliquota = this.ncmPisCofinsIpi.ipiAliquota;

    this.ncmPisCofinsIpi.naturezaReceitaCodigo =
        this.ncmPisCofinsIpi.naturezaReceitaCodigo ?
        Number(this.ncmPisCofinsIpi.naturezaReceitaCodigo) :
        null;
  }
  static from(value: any) {
    const ncm = new Ncm();
    ncm.ativo = value.ativo;
    ncm.ncmCodigo = value.ncmCodigo?.trim();
    ncm.ncmExcecaoTipi = value.ncmExcecaoTipi;
    ncm.ncmIcms = value.ncmIcms;
    ncm.ncmPisCofinsIpi = value.ncmPisCofinsIpi;
    ncm.ncmUf = value.ncmUf;
    return ncm;
  }
  toAPI() {
    this.ncmExcecaoTipi = Number(this.ncmExcecaoTipi);
    this.completarCamposDosNcmUf();
    this.completarCamposDosNcmIcms();
    this.preencherCamposNcmPisCofinsIpi();
  }
  toListagem() {
    return <NcmListagem>{
      ncmCodigo: this.ncmCodigo,
      ncmExcecaoTipi: this.ncmExcecaoTipi,
      descricao: this.descricao,
      ativo: this.ativo,
      configurado: this.configurado,
    };
  }
}
