<div class="p-inputgroup">
  <textarea
    #contentInput
    class="inputfield w-full"
    pInputTextarea
    appAutoFocus
    [placeholder]="placeholder"
    [autofocus]="autoFocus"
    [(ngModel)]="model"
    (ngModelChange)="onNgModelChange($event)"
    [readonly]="readonly"
    [rows]="rows"
    [maxlength]="maxlength"
    [ngClass]="{
      readonly: readonly,
      'ng-invalid ng-dirty': hasErrors,
      'text-right': textAlign === 'right',
      'text-center': textAlign === 'center',
      'text-left': textAlign === 'left',
      'w-full': fullWidth
    }"
    (blur)="onBlur($event)"
    (keydown)="onKeyDown($event)"
  ></textarea>
</div>
<small style="float: right" *ngIf="maxlength"
  >{{ controlLength ?? 0 }}/{{ contador.toString() }}</small
>
