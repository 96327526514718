import {Injectable} from '@angular/core';
import {MarcaService} from '@app/abstraction/data/services/produtos/marca.service';
import {MarcaStore} from '@app/abstraction/data/stores/produtos/marca.store';
import {Marca} from '@app/abstraction/domain/entities/produtos/marca/marca.entity';
import {MarcaFiltro} from '@app/abstraction/domain/interfaces/produtos/marca/marca-filtro.interface';

@Injectable({providedIn: 'root'})
export class MarcaFacade {
  get marcas() {
    return this.store.marcas;
  }
  get marcas$() {
    return this.store.marcas$;
  }
  constructor(private store: MarcaStore, private service: MarcaService) {}
  async adicionar(marca: Marca) {
    const novaMarca = await this.service.adicionar(marca);
    if (novaMarca) {
      this.store.adicionar(novaMarca.toListagem());
    }
    return novaMarca;
  }
  async atualizarSituacao(id: string) {
    const marca = this.marcas.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !marca.ativo);
    marca.ativo = status;
    return status;
  }
  async buscarPorSecaoId(filtro?: MarcaFiltro) {
    const marcas = await this.service.buscarTodos(filtro);
    if (marcas) {
      this.store.marcas = marcas;
    }
  }
  async buscarMarcas(filtro?: MarcaFiltro) {
    const marcas = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(marcas);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  alterar(marca: Marca) {
    return this.service.alterar(marca, marca.id);
  }
  limparListaMarcas() {
    this.store.marcas = null;
  }
}
