import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TabelaNutricional} from '@app/abstraction/domain/entities/produtos/tabela-nutricional/tabela-nutricional.entity';
import {TabelaNutricionalFiltro} from '@app/abstraction/domain/interfaces/produtos/tabela-nutricional/tabela-nutricional-filtro.interface';
import {TabelaNutricionalListagem} from '@app/abstraction/domain/interfaces/produtos/tabela-nutricional/tabela-nutricional-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TabelaNutricionalService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: TabelaNutricional) {
    const response$ =
        this.client.post<TabelaNutricional>(API.TABELA_NUTRICIONAL, dados)
            .pipe(
                map((json: TabelaNutricional) =>
                        json ? TabelaNutricional.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: TabelaNutricional, id: string) {
    const response$ =
        this.client
            .put<TabelaNutricional>(`${API.TABELA_NUTRICIONAL}/${id}`, dados)
            .pipe(
                map((json: TabelaNutricional) =>
                        json ? TabelaNutricional.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.TABELA_NUTRICIONAL}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<TabelaNutricional>(`${API.TABELA_NUTRICIONAL}/${id}`)
            .pipe(
                map((json: TabelaNutricional) =>
                        json ? TabelaNutricional.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: TabelaNutricionalFiltro) {
    const response$ = this.client.get<TabelaNutricionalListagem[]>(
        this.geradorQueryString.gerar(API.TABELA_NUTRICIONAL, filtro));
    return lastValueFrom(response$);
  }
}
