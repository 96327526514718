import {ProdutoVariacaoTamanhoListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-tamanho/produto-variacao-tamanho-listagem.interface';
export class ProdutoVariacaoTamanho {
  ativo: boolean;
  descricao: string;
  id: string;
  static from(json: any = {}) {
    const produtoVariacaoTamanho = new ProdutoVariacaoTamanho();
    produtoVariacaoTamanho.id = json.id;
    produtoVariacaoTamanho.descricao = json.descricao?.trim();
    produtoVariacaoTamanho.ativo = json.ativo;
    return produtoVariacaoTamanho;
  }
  toListagem() {
    return <ProdutoVariacaoTamanhoListagem>{
      descricao: this.descricao,
      id: this.id,
      ativo: this.ativo,
    };
  }
}
