export class ProdutoVariacaoPreco {
  empresaId: string;
  formulaCusto: number;
  formulaPmz: number;
  formulaVenda: number;
  valorVenda1Anterior: number;
  valorVenda2Anterior: number;
  valorVenda3Anterior: number;
  valorVenda4Anterior: number;
  valorVenda1: number;
  valorVenda2: number;
  valorVenda3: number;
  valorVenda4: number;
  margem1Fixa: number;
  margem2Fixa: number;
  margem3Fixa: number;
  margem4Fixa: number;
  margem1Praticada: number;
  margem2Praticada: number;
  margem3Praticada: number;
  margem4Praticada: number;
  valorCompra: number;
  valorCusto: number;
  variacaoId: string;
  id: string;
  valorCompraAnterior: number;
  valorCustoAnterior: number;
  valorPmz: number;

  static criarProdutoVariacaoPreco(value: any) {
    const produtoVariacaoPreco = new ProdutoVariacaoPreco();
    produtoVariacaoPreco.empresaId = value.empresaId;
    produtoVariacaoPreco.formulaCusto = value.formulaCusto;
    produtoVariacaoPreco.formulaPmz = value.formulaPmz;
    produtoVariacaoPreco.formulaVenda = value.formulaVenda;
    produtoVariacaoPreco.margem1Fixa = value.margem1Fixa;
    produtoVariacaoPreco.margem1Praticada = value.margem1Praticada;
    produtoVariacaoPreco.margem2Fixa = value.margem2Fixa;
    produtoVariacaoPreco.margem2Praticada = value.margem2Praticada;
    produtoVariacaoPreco.margem3Fixa = value.margem3Fixa;
    produtoVariacaoPreco.margem3Praticada = value.margem3Praticada;
    produtoVariacaoPreco.margem4Fixa = value.margem4Fixa;
    produtoVariacaoPreco.margem4Praticada = value.margem4Praticada;
    produtoVariacaoPreco.variacaoId = value.variacaoId;
    produtoVariacaoPreco.id = value.id;
    produtoVariacaoPreco.valorCompra = value.valorCompra;
    produtoVariacaoPreco.valorCompraAnterior = value.valorCompraAnterior;
    produtoVariacaoPreco.valorCusto = value.valorCusto;
    produtoVariacaoPreco.valorCustoAnterior = value.valorCustoAnterior;
    produtoVariacaoPreco.valorPmz = value.valorPmz;
    produtoVariacaoPreco.valorVenda1 = value.valorVenda1;
    produtoVariacaoPreco.valorVenda1Anterior = value.valorVenda1Anterior;
    produtoVariacaoPreco.valorVenda2 = value.valorVenda2;
    produtoVariacaoPreco.valorVenda2Anterior = value.valorVenda2Anterior;
    produtoVariacaoPreco.valorVenda3 = value.valorVenda3;
    produtoVariacaoPreco.valorVenda3Anterior = value.valorVenda3Anterior;
    produtoVariacaoPreco.valorVenda4 = value.valorVenda4;
    produtoVariacaoPreco.valorVenda4Anterior = value.valorVenda4Anterior;
    return produtoVariacaoPreco;
  }
  prepararProdutoVariacaoPreco() {
    this.valorCompra = Number(this.valorCompra);
    this.valorCusto = Number(this.valorCusto);
    this.formulaCusto = Number(this.formulaCusto);
    this.valorPmz = Number(this.valorPmz);
    this.formulaPmz = Number(this.formulaPmz);
    this.valorVenda1 = Number(this.valorVenda1);
    this.valorVenda2 = Number(this.valorVenda2);
    this.formulaVenda = Number(this.formulaVenda);
    this.valorCompraAnterior = Number(this.valorCompraAnterior);
    this.valorCustoAnterior = Number(this.valorCustoAnterior);
    this.margem1Fixa = Number(this.margem1Fixa);
    this.margem1Praticada = Number(this.margem1Praticada);
  }
}
