import {Injectable} from '@angular/core';
import {NaturezaReceitaService} from '@app/abstraction/data/services/cadastros/natureza-receita.service';
import {NaturezaReceitaStore} from '@app/abstraction/data/stores/cadastros/natureza-receita.store';
import {NaturezaReceita} from '@app/abstraction/domain/entities/cadastros/natureza-receita/natureza-receita.entity';
import {NaturezaReceitaFiltro} from '@app/abstraction/domain/interfaces/cadastros/natureza-receita/natureza-receita-filtro.interface';

@Injectable({providedIn: 'root'})
export class NaturezaReceitaFacade {
  get naturezasReceita() {
    return this.store.naturezasReceita;
  }
  get naturezasReceita$() {
    return this.store.naturezasReceita$;
  }
  constructor(
      private store: NaturezaReceitaStore,
      private service: NaturezaReceitaService) {}
  async adicionar(naturezaReceita: NaturezaReceita) {
    const novaNaturezaReceita = await this.service.adicionar(naturezaReceita);
    if (novaNaturezaReceita) {
      this.store.adicionar(novaNaturezaReceita.toListagem());
    }
    return novaNaturezaReceita;
  }
  async buscarNaturezasReceita(filtro?: NaturezaReceitaFiltro) {
    const naturezasReceita = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(naturezasReceita);
  }
  alterar(naturezaReceita: NaturezaReceita) {
    return this.service.alterar(naturezaReceita, naturezaReceita.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaNaturezasReceita() {
    this.store.naturezasReceita = null;
  }
}
