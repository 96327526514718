import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'porcentagem',
})
export class PorcentagemPipe implements PipeTransform {
  transform(value: string | number): string {
    if (value) {
      return `${value}%`;
    }
    return '';
  }
}
