import {Injectable} from '@angular/core';
import {SubgrupoListagem} from '@app/abstraction/domain/interfaces/produtos/subgrupo/subgrupo-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SubgrupoStore {
  private readonly _subgrupos = new BehaviorSubject<SubgrupoListagem[]>(null);
  get subgrupos(): SubgrupoListagem[] {
    return this._subgrupos.getValue();
  }
  set subgrupos(subgrupos: SubgrupoListagem[]) {
    this._subgrupos.next(subgrupos);
  }
  get subgrupos$() {
    return this._subgrupos.asObservable();
  }
  adicionar(grupo: SubgrupoListagem) {
    if (!this.subgrupos) {
      this.subgrupos = [];
    }
    this.subgrupos.push(grupo);
  }
  adicionarRange(subgrupos: SubgrupoListagem[]) {
    if (!this.subgrupos) {
      this.subgrupos = [];
    }
    subgrupos.forEach((subgrupo) => {
      if (!this.subgrupos.some((c) => c.id === subgrupo.id)) {
        this.subgrupos.push(subgrupo);
      }
    });
  }
}
