import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {FornecedorProduto} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor-produto.entity';
import {Fornecedor} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor.entity';
import {PlanoContasNivel3} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-3.entity';
import {Contato} from '@app/abstraction/domain/entities/shared/contato.entity';
import {Endereco} from '@app/abstraction/domain/entities/shared/endereco.entity';
import {FornecedorFacade} from '@app/abstraction/domain/facades/cadastros/fornecedor/fornecedor.facade';
import {FuncionarioFacade} from '@app/abstraction/domain/facades/cadastros/funcionario/funcionario.facade';
import {UsuarioFacade} from '@app/abstraction/domain/facades/cadastros/usuario/usuario.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {ProdutoFacade} from '@app/abstraction/domain/facades/produtos/produto/produto.facade';
import {ApiServicesFacade} from '@app/abstraction/domain/facades/shared/api-services/api-services.facade';
import {CepFacade} from '@app/abstraction/domain/facades/shared/cep/cep.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {FornecedorForm} from '@app/abstraction/domain/forms/cadastros/fornecedor.form';
import {IContatoForm} from '@app/abstraction/domain/forms/shared/contato.form';
import {IEnderecoForm} from '@app/abstraction/domain/forms/shared/endereco.form';
import {ProdutoSimplificadoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-simplificado-listagem.interface';
import {SituacaoLancamentoLabels} from '@app/shared/enums/situacao-lancamento.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

import {FormBaseComponent} from '../../base/form-base.component';

@Component({
  selector: 'app-fornecedor-shared-form',
  templateUrl: './fornecedor-shared-form.component.html',
  styleUrls: ['./fornecedor-shared-form.component.scss']
})
export class FornecedorSharedFormComponent extends FormBaseComponent implements
    OnInit {
  @ViewChild('acoesTemp', {static: true}) acoesTemp: TemplateRef<any>;
  @ViewChild('situacaoLancamentoParcelaTemp', {static: true})
  situacaoLancamentoParcelaTemp: TemplateRef<any>;
  @ViewChild('lancamentosParcelaAcoesTemp', {static: true})
  lancamentosParcelaAcoesTemp: TemplateRef<any>;
  @ViewChild('dataTemp', {static: true}) dataTemp: TemplateRef<any>;
  @ViewChild('despesaTemp', {static: true}) despesaTemp: TemplateRef<any>;
  @ViewChild('valorTemp', {static: true}) valorTemp: TemplateRef<any>;

  fornecedorForm: FornecedorForm;
  colunasProdutos: TableColumn[];
  colunasTabelaLancamentoParcela: TableColumn[];

  get planosContas3() {
    return this.planoContaFacade.planosContasNivel3;
  }
  get formModificado() {
    return this.fornecedorForm.formModificado;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get pessoaJuridica() {
    return this.fornecedorForm.pessoaJuridica;
  }
  get titulo() {
    return this.fornecedorForm.titulo;
  }
  get contatos() {
    return this.fornecedorForm.contatos;
  }
  get enderecos() {
    return this.fornecedorForm.enderecos;
  }
  get produtos() {
    return this.fornecedorForm.produtos;
  }
  get operacaoFormEnderecoAdicionar() {
    return this.fornecedorForm.operacaoFormEnderecoAdicionar;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get valorPlaceholder() {
    return `0,${'0'.repeat(this.valorDecimais)}`;
  }
  get mostrarDataCriacao() {
    return this.fornecedorForm.mostrarDataCriacao;
  }
  get mostrarDataAlteracao() {
    return this.fornecedorForm.mostrarDataAlteracao;
  }
  get mostrarCriadoPor() {
    return this.fornecedorForm.mostrarCriadoPor;
  }
  get contemUsuarioCriadoPor() {
    return this.fornecedorForm.contemUsuarioCriadoPor;
  }
  get maxlength() {
    return this.fornecedorForm.maxlength;
  }
  get maxlengthCount() {
    return this.fornecedorForm.maxlengthCount;
  }
  get documentoObrigatorio() {
    return this.parametroFacade.documentoObrigatorio;
  }
  get situacaoLancamentoLabels() {
    return SituacaoLancamentoLabels;
  }
  get funcionarios() {
    return this.funcionarioFacade.funcionarios;
  }
  get tituloTabPanelRegistrosFinanceiros() {
    return 'Registros Financeiros';
  }
  constructor(
      private apiServicesCoreFacade: ApiServicesFacade,
      private enderecoTipoFacade: EnderecoTipoFacade,
      private fornecedorFacade: FornecedorFacade,
      private pessoaFacade: PessoaFacade,
      private produtoFacade: ProdutoFacade,
      private router: Router,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private usuarioFacade: UsuarioFacade,
      private planoContaFacade: PlanoContasFacade,
      private funcionarioFacade: FuncionarioFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      private cepFacade: CepFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private montarColunasTabelaProdutos() {
    this.colunasProdutos = [
      {
        field: 'produtoDescricao',
        name: 'Produto',
      },
      {
        field: 'produtoCodigoBarras',
        name: 'Código de Barras',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: 'codigoReferencia',
        name: 'Código de Referência',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: '',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesTemp,
        width: 150,
      },
    ];
  }
  private montarColunasLancamentoParcela() {
    this.colunasTabelaLancamentoParcela = [
      {
        field: 'sequencia',
        name: 'Código',
      },
      {
        field: 'despesa',
        name: 'Tipo',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.despesaTemp,
      },
      {
        field: 'dataVencimento',
        name: 'Vencimento',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.dataTemp,
      },
      {
        field: 'valorTotal',
        name: 'Previsto',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.valorTemp,
      },
      {
        field: 'valorTotalPago',
        name: 'Realizado',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.valorTemp,
      },
      {
        field: 'situacao',
        name: 'Situação',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.situacaoLancamentoParcelaTemp,
      },
      {
        field: 'planoConta',
        name: 'Plano de Conta',
      },
      {
        field: 'numeroDocumento',
        name: 'Documento',
      },
      {
        field: 'contaBancaria',
        name: 'Conta Bancária',
      },
      {
        field: '',
        name: '',
        cellClass: 'text-center',
        width: 80,
        cellTemplate: this.lancamentosParcelaAcoesTemp,
      },
    ];
  }
  async setarPlanoContasCadastrado(planoConta3: PlanoContasNivel3) {
    if (planoConta3.id && planoConta3.planoConta2Id) {
      this.fornecedorForm.setarPlanoContasCadastrado(planoConta3.id);
    }
  }
  abrirContatoModal(contato?: Contato, index?: number) {
    this.fornecedorForm.abrirContatoModal(contato, index);
  }
  abrirEnderecoModal(endereco?: Endereco, index?: number) {
    this.fornecedorForm.abrirEnderecoModal(endereco, index);
  }
  abrirProdutoBuscaRapidaModal() {
    this.fornecedorForm.abrirProdutoBuscaRapidaModal();
  }
  abrirFornecedorProdutoModal(
      fornecedorProduto?: FornecedorProduto, index?: number) {
    this.fornecedorForm.abrirFornecedorProdutoModal(fornecedorProduto, index);
  }
  abrirConfirmacaoExcluisaoFornecedorProduto(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.fornecedorForm.excluirFornecedorProduto(index);
      },
    });
  }
  abrirPlanoContasCadastroRapidoModal() {
    this.fornecedorForm.abrirPlanoContasCadastroRapidoModal();
  }
  criarForm() {
    this.fornecedorForm = new FornecedorForm(
        this.apiServicesCoreFacade, this.enderecoTipoFacade,
        this.fornecedorFacade, this.pessoaFacade, this.produtoFacade,
        this.parametroFacade, this.usuarioFacade, this.toastrService,
        this.errorMessagesFacade, this.cepFacade);
    this.fornecedorForm.criarForm();
    this.fornecedorForm.monitorarRegimeTributario();
    this.fornecedorForm.monitorarPessoaTipo();
    this.fornecedorForm.monitorarFormulario();
  }
  consultarDocumentoValido() {
    this.fornecedorForm.consultarDocumentoValido();
  }
  consultarDocumentoValidoComEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.consultarDocumentoValido();
    }
  }
  fecharPlanoContasCadastroRapidoModal() {
    this.fornecedorForm.fecharPlanoContasCadastroRapidoModal();
  }
  fecharEnderecoModal() {
    this.fornecedorForm.fecharEnderecoModal();
  }
  fecharFornecedorProdutoModal() {
    this.fornecedorForm.fecharFornecedorProdutoModal();
  }
  fecharProdutoBuscaRapidaModal() {
    this.fornecedorForm.fecharProdutoBuscaRapidaModal();
  }
  fecharContatoModal() {
    this.fornecedorForm.fecharContatoModal();
  }
  ngOnInit() {
    this.montarDropdownRegimeTributario();
    this.montarDropdownAtividadeTipo();
    this.montarDropdownIeIndicador();
    this.montarDropdownPessoaTipo();
    this.montarDropdownEstadosIbge();
    this.montarDropdownNumerico(31);
    this.montarColunasTabelaProdutos();
    this.montarColunasLancamentoParcela();
    this.criarForm();
  }
  preencherNomeFantasia(event: any) {
    this.fornecedorForm.preencherNomeFantasia(event.target.value as string);
  }
  preencherObjeto() {
    const fornecedor = Fornecedor.from(this.fornecedorForm.form.value);
    fornecedor.toAPI();
    fornecedor.id = this.fornecedorForm.operacaoFormAdicionar ?
        this.fornecedorForm.pessoaId :
        fornecedor.id;
    return fornecedor;
  }
  salvarFornecedorProduto() {
    this.fornecedorForm.salvarFornecedorProduto();
  }
  recuperaDadosTab(event: any) {
    if (this.fornecedorForm.operacaoFormEditar) {
      if (event.originalEvent.currentTarget.textContent ===
          this.tituloTabPanelRegistrosFinanceiros) {
        this.fornecedorForm.buscarRegistrosFinanceiros();
      }
    }
  }
  salvarContato(form: FormGroup<IContatoForm>) {
    this.fornecedorForm.salvarContato(form);
  }
  salvarEndereco(form: FormGroup<IEnderecoForm>) {
    this.fornecedorForm.salvarEndereco(form);
  }
  setarProdutoSelecionado(produtoSelecionado: ProdutoSimplificadoListagem[]) {
    this.fornecedorForm.setarProdutoSelecionado(produtoSelecionado);
  }
  verLancamentoParcela(row: any) {
    if (row.despesa)
      this.router.navigate(['contas-pagar/editar', row.lancamentoParcelaId]);
    else
      this.router.navigate(['contas-receber/editar', row.lancamentoParcelaId]);
  }
}
