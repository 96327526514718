import {FormControl, FormGroup} from '@angular/forms';
import {FornecedorProduto} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor-produto.entity';


export interface IFornecedorProdutoForm {
  id: FormControl<string>;
  fornecedorId: FormControl<string>;
  codigoReferencia: FormControl<string>;
  produtoVariacaoId: FormControl<string>;
  produtoDescricao: FormControl<string>;
  produtoCodigoBarras: FormControl<string>;
  fornecedorNome: FormControl<string>;
  fornecedorDocumento: FormControl<string>;
}

export class FornecedorProdutoForm {
  static criarForm(fornecedorProduto: FornecedorProduto) {
    return new FormGroup<IFornecedorProdutoForm>({
      fornecedorId: new FormControl(fornecedorProduto?.fornecedorId),
      id: new FormControl(fornecedorProduto?.id),
      codigoReferencia: new FormControl(fornecedorProduto?.codigoReferencia),
      produtoVariacaoId: new FormControl(fornecedorProduto?.produtoVariacaoId),
      produtoDescricao: new FormControl(fornecedorProduto?.produtoDescricao),
      produtoCodigoBarras:
          new FormControl(fornecedorProduto?.produtoCodigoBarras),
      fornecedorNome: new FormControl(fornecedorProduto?.fornecedorNome),
      fornecedorDocumento:
          new FormControl(fornecedorProduto?.fornecedorDocumento),

    });
  }
}
