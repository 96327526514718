<p-dialog
  header="Nova variação"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="form"
  (onHide)="fecharModal()"
  (onShow)="aoAbrirModal()"
>
  <div class="formgrid grid">
    <!-- #region VARIAÇAO CODIGO BARRAS -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label texto="Código de barras"></app-custom-label>
      <app-text-input
        mask="'00000000000000000000'"
        [control]="form.controls.codigoBarras"
      ></app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region VARIAÇAO TAMANHO -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label
        texto="Tamanho"
        [cadastroRapido]="true"
        (aoClicarAdicionar)="abrirProdutoVariacaoTamanhoCadastroRapidoModal()"
      >
      </app-custom-label>
      <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
        [control]="form.controls.tamanhoId"
        [options]="produtoVariacaoTamanhos$ | async"
        [showFilter]="true"
        [showClear]="true"
      ></app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region VARIAÇAO COR -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label
        texto="Cor"
        [cadastroRapido]="true"
        (aoClicarAdicionar)="abrirProdutoVariacaoCorCadastroRapidoModal()"
      >
      </app-custom-label>
      <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
        [control]="form.controls.corId"
        [options]="produtoVariacaoCores$ | async"
        [showFilter]="true"
        [showClear]="true"
        [templateCor]="true"
      ></app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region VARIAÇAO REFERENCIA -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6">
      <app-custom-label
        texto="Referência"
        [cadastroRapido]="true"
        (aoClicarAdicionar)="
          abrirProdutoVariacaoReferenciaCadastroRapidoModal()
        "
      >
      </app-custom-label>
      <app-dropdown-input
        optionLabel="descricao"
        optionValue="id"
        [control]="form.controls.referenciaId"
        [options]="produtoVariacaoReferencias$ | async"
        [showFilter]="true"
        [showClear]="true"
      ></app-dropdown-input>
      <!-- #endregion -->
    </div>
  </div>
  <p-tabView [scrollable]="true" data-cy="tabview-dados-produto-tipo-variacao">
    <p-tabPanel header="Preços">
      <div class="flex justify-content-between mb-2">
        <p-button
          styleClass="btn-five"
          label="Novo preço"
          tooltipPosition="bottom"
          pTooltip="Novo preço"
          icon="pi pi-plus"
          data-cy="btn-nova-precificacao"
          (onClick)="abrirProdutoVariacaoPrecoModal()"
        >
        </p-button>
      </div>

      <div class="borda-tabela">
        <app-table
          [columns]="colunasTabelaProdutoVariacaoPreco"
          [rows]="precos"
        >
          <ng-container
            *ngTemplateOutlet="acoesProdutoVariacaoPrecoTemp"
          ></ng-container>
        </app-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Estoques">
      <div class="flex justify-content-between mb-2">
        <p-button
          styleClass="btn-five"
          label="Novo estoque"
          tooltipPosition="bottom"
          pTooltip="Novo estoque"
          icon="pi pi-plus"
          data-cy="btn-novo-estoque"
          (onClick)="abrirProdutoVariacaoEstoqueModal()"
        ></p-button>
      </div>
      <div class="borda-tabela">
        <app-table
          [columns]="colunasTabelaVariacaoEstoque"
          [rows]="estoques"
          [expandable]="true"
          dataKey="key"
        >
          <ng-template #rowExpanded let-rowData="rowData">
            <app-table
              [columns]="colunasTabelaProdutoVariacaoAreaEstoque"
              [rows]="rowData.items"
            >
            </app-table>
          </ng-template>
          <ng-container
            *ngTemplateOutlet="acoesProdutoVariacaoEstoqueTemp"
          ></ng-container>
        </app-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Imagens">
      <div class="flex justify-content-between mb-2">
        <p-button
          styleClass="btn-five"
          label="Procurar"
          icon="pi pi-plus"
          (onClick)="imagem.click()"
        >
        </p-button>
        <input
          #imagem
          type="file"
          accept="image/*"
          [multiple]="true"
          class="input-file"
          (change)="salvarProdutoVariacaoFoto($event)"
        />
      </div>
      <div class="borda-tabela">
        <app-table
          [reorderableRows]="true"
          (aoReordenar)="reordenarProdutoVariacaoFoto()"
          [columns]="colunasTabelaProdutoVariacaoFoto"
          [rows]="fotos"
          dataKey="ordem"
        >
        </app-table>
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>

<ng-template #quantidadeDecimalTemp let-value="value">
  <span>{{ value | customDecimal : quantidadeDecimais }}</span>
</ng-template>

<p-dialog
  *ngIf="produtoVariacaoPrecoForm"
  [header]="produtoVariacaoPrecoModalTitulo"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarProdutoVariacaoPrecoModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="produtoVariacaoPrecoForm"
  (onHide)="fecharProdutoVariacaoPrecoModal()"
>
  <div class="formgrid grid">
    <!-- #region EMPRESA PREÇO -->
    <div class="field col-12">
      <app-custom-label texto="Empresa" [obrigatorio]="true">
      </app-custom-label>
      <app-dropdown-input
        optionLabel="nomeFantasia"
        optionValue="id"
        [control]="produtoVariacaoPrecoForm.controls.empresaId"
        [options]="empresas"
        [showFilter]="true"
        [showClear]="false"
        [readonly]="empresaUnica"
      ></app-dropdown-input>
    </div>
    <!-- #endregion -->
  </div>
  <div class="formgrid grid">
    <!-- #region VALOR CUSTO -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4">
      <app-custom-label texto="Valor de custo (R$)"></app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoVariacaoPrecoForm.controls.valorCusto"
        (inputKeydown)="calcularValores('valorCusto')"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->

    <!-- #region MARGEM FIXA -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4">
      <app-custom-label texto="Margem fixa (%)"></app-custom-label>
      <app-numeric-input
        [placeholder]="porcentagemPlaceholder"
        [options]="porcentagemMask"
        [control]="produtoVariacaoPrecoForm.controls.margem1Praticada"
        (inputKeydown)="calcularValores('margem1Praticada')"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->

    <!-- #region VALOR DE VENDA 1 -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4">
      <app-custom-label texto="Valor de venda 1 (R$)" [obrigatorio]="true">
      </app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoVariacaoPrecoForm.controls.valorVenda1"
        (inputKeydown)="calcularValores('valorVenda1')"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->
  </div>
  <!-- <div class="formgrid grid">
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4">
      <app-custom-label texto="Valor de compra (R$)"></app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoVariacaoPrecoForm.controls.valorCompra"
      ></app-numeric-input>
    </div>

    <div class="field col-12 xl:col-4 lg:col-4 md:col-4">
      <app-custom-label texto="Margem praticada (%)"></app-custom-label>
      <app-numeric-input
        [placeholder]="porcentagemPlaceholder"
        [options]="porcentagemMask"
        [control]="produtoVariacaoPrecoForm.controls.margem1Praticada"
      ></app-numeric-input>
    </div>
  </div> -->
  <!-- <div class="formgrid grid">
    <div class="field col-12 xl:col-4 lg:col-4 md:col-4">
      <app-custom-label texto="Valor de compra anterior (R$)">
      </app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoVariacaoPrecoForm.controls.valorCompraAnterior"
        [readonly]="true"
      ></app-numeric-input>
    </div>

    <div class="field col-12 xl:col-4 lg:col-4 md:col-4">
      <app-custom-label texto="Valor de custo anterior (R$)">
      </app-custom-label>
      <app-numeric-input
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
        [control]="produtoVariacaoPrecoForm.controls.valorCustoAnterior"
        [readonly]="true"
      ></app-numeric-input>
    </div>
  </div> -->
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharProdutoVariacaoPrecoModal()"
      label="Cancelar"
      [text]="true"
    >
    </p-button>
    <p-button
      (click)="salvarProdutoVariacaoPreco()"
      label="Salvar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>
<p-dialog
  *ngIf="produtoVariacaoEstoqueForm"
  [header]="produtoVariacaoEstoqueModalTitulo"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarProdutoVariacaoEstoqueModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="produtoVariacaoEstoqueForm"
  (onHide)="fecharProdutoVariacaoEstoqueModal()"
>
  <div class="formgrid grid">
    <!-- #region EMPRESA ESTOQUE -->
    <!-- <div class="field col-12 xl:col-8 lg:col-8 md:col-8 md:col-8 sm:col-6">
    <app-custom-label texto="Empresa" [obrigatorio]="true">
    </app-custom-label>
    <app-dropdown-input
      optionLabel="nomeFantasia"
      optionValue="id"
      [control]="produtoVariacaoEstoqueForm.controls.empresaId"
      [options]="empresas"
      [showFilter]="true"
      [readonly]="empresaUnica"
    ></app-dropdown-input>
  </div> -->
    <!-- #endregion -->

  <!-- #region AREA ESTOQUE -->
  <div class="field col-12 xl:col-4 lg:col-4 md:col-4 md:col-4 sm:col-6">
    <app-custom-label texto="Área de estoque" [obrigatorio]="true">
    </app-custom-label>
    <app-dropdown-input
      optionLabel="descricao"
      optionValue="id"
      [control]="produtoVariacaoEstoqueForm.controls.areaEstoqueId"
      [options]="areasEstoque"
      [showFilter]="true"
    ></app-dropdown-input>
  </div>
  <!-- #endregion -->
</div>
<div class="formgrid grid">
  <!-- #region ESTOQUE MINIMO -->
  <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
    <app-custom-label texto="Estoque mínimo"></app-custom-label>
    <app-numeric-input
      [placeholder]="quantidadePlaceholder"
      [options]="quantidadeMask"
      [control]="produtoVariacaoEstoqueForm.controls.minimo"
    ></app-numeric-input>
  </div>
  <!-- #endregion -->

  <!-- #region ESTOQUE ATUAL -->
  <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
    <app-custom-label texto="Estoque atual"></app-custom-label>
    <app-numeric-input
      [placeholder]="quantidadePlaceholder"
      [options]="quantidadeMask"
      [control]="produtoVariacaoEstoqueForm.controls.atual"
    ></app-numeric-input>
  </div>
  <!-- #endregion -->

  <!-- #region ESTOQUE MAXIMO -->
  <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
    <app-custom-label texto="Estoque máximo"></app-custom-label>
    <app-numeric-input
      [placeholder]="quantidadePlaceholder"
      [options]="quantidadeMask"
      [control]="produtoVariacaoEstoqueForm.controls.maximo"
    ></app-numeric-input>
  </div>
  <!-- #endregion -->

  <!-- #region PERCENTUAL DE PERDA -->
  <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
    <app-custom-label texto="Percentual de perda (%)"></app-custom-label>
    <app-numeric-input
      [placeholder]="porcentagemPlaceholder"
      [options]="porcentagemMask"
      [control]="produtoVariacaoEstoqueForm.controls.percentualPerda"
    ></app-numeric-input>
  </div>
  <!-- #endregion -->

  <!-- #region ESTOQUE CONGELADO -->
  <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
    <app-custom-label texto="Estoque congelado"></app-custom-label>
    <app-numeric-input
      [placeholder]="quantidadePlaceholder"
      [options]="quantidadeMask"
      [control]="produtoVariacaoEstoqueForm.controls.congelado"
    ></app-numeric-input>
  </div>
  <!-- #endregion -->

  <!-- #region ESTOQUE DIGITADO -->
  <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
    <app-custom-label texto="Estoque digitado"></app-custom-label>
    <app-numeric-input
      [placeholder]="quantidadePlaceholder"
      [options]="quantidadeMask"
      [control]="produtoVariacaoEstoqueForm.controls.digitado"
    ></app-numeric-input>
  </div>
  <!-- #endregion -->

  <!-- #region SKU -->
  <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
    <app-custom-label texto="SKU"></app-custom-label>
    <app-text-input
      [control]="produtoVariacaoEstoqueForm.controls.sku"
      [maxlength]="10"
    ></app-text-input>
  </div>
  <!-- #endregion -->
</div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharProdutoVariacaoEstoqueModal()"
      label="Cancelar"
      [text]="true"
    >
    </p-button>
    <p-button
      (click)="salvarProdutoVariacaoEstoque()"
      label="Salvar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>

<ng-template
  #acoesProdutoVariacaoPrecoTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Editar"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="abrirProdutoVariacaoPrecoModal(rowData, rowIndex)"
  ></p-button>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Excluir"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="abrirConfirmacaoExcluisaoProdutoVariacaoPreco(rowIndex)"
  ></p-button>
</ng-template>
<ng-template
  #acoesProdutoVariacaoEstoqueTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Editar"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="abrirProdutoVariacaoEstoqueModal(rowData, rowIndex)"
  ></p-button>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Excluir"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="abrirConfirmacaoExcluisaoProdutoVariacaoEstoque(rowData, rowIndex)"
  ></p-button>
</ng-template>
<ng-template
  #acoesProdutoVariacaoFotoTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Excluir"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="abrirConfirmacaoExcluisaoProdutoVariacaoFoto(rowIndex)"
  ></p-button>
</ng-template>
<ng-template #fotoTemp let-value="value" let-rowIndex="rowIndex">
  <div class="item">
    <div class="image-container">
      <img [src]="buscarProdutoVariacaoFoto(rowIndex)" class="image" />
    </div>
  </div>
</ng-template>
<ng-template #empresaTemp let-value="value">
  <span>{{ value | empresa }}</span>
</ng-template>
<ng-template #decimalTemp let-value="value">
  <span>{{ value | number : "1.2-2" : "pt-BR" }}</span>
</ng-template>
<ng-template #areaEstoqueTemp let-value="value">
  <span>{{ value | areaEstoque }}</span>
</ng-template>

<app-produto-variacao-referencia-cadastro-rapido-modal
  [mostrarModal]="mostrarProdutoVariacaoReferenciaCadastroRapidoModal"
  (aoCancelar)="fecharProdutoVariacaoReferenciaCadastroRapidoModal()"
>
</app-produto-variacao-referencia-cadastro-rapido-modal>
<app-produto-variacao-cor-cadastro-rapido-modal
  [mostrarModal]="mostrarProdutoVariacaoCorCadastroRapidoModal"
  (aoCancelar)="fecharProdutoVariacaoCorCadastroRapidoModal()"
>
</app-produto-variacao-cor-cadastro-rapido-modal>
<app-produto-variacao-tamanho-cadastro-rapido-modal
  [mostrarModal]="mostrarProdutoVariacaoTamanhoCadastroRapidoModal"
  (aoCancelar)="fecharProdutoVariacaoTamanhoCadastroRapidoModal()"
>
</app-produto-variacao-tamanho-cadastro-rapido-modal>
