export enum ParcelamentoTipo {
  Nenhum = 1,
  Manual = 2,
  Automatico = 3,
  Recorrencia = 4
}

export const ParcelamentoTipoLabels = new Map<number, string>([
  [ParcelamentoTipo.Nenhum, 'Não Parcelar'],
  [ParcelamentoTipo.Manual, 'Parcelamento Manual'],
  [ParcelamentoTipo.Automatico, 'Parcelamento Automático'],
  [ParcelamentoTipo.Recorrencia, 'Recorrencia Automática'],
]);
