import {Injectable} from '@angular/core';
import {CodigoFiscalService} from '@app/abstraction/data/services/fiscal/codigo-fiscal.service';
import {RegraFiscalService} from '@app/abstraction/data/services/fiscal/regra-fiscal.service';
import {RegraFiscalStore} from '@app/abstraction/data/stores/fiscal/regra-fiscal.store';
import {RegraFiscal} from '@app/abstraction/domain/entities/fiscal/regra-fiscal/regra-fiscal.entity';
import {CodigoFiscalFiltro} from '@app/abstraction/domain/interfaces/fiscal/codigo-fiscal/codigo-fiscal-filtro.interface';
import {RegraFiscalFiltro} from '@app/abstraction/domain/interfaces/fiscal/regra-fiscal/regra-fiscal-filtro.interface';

@Injectable({providedIn: 'root'})
export class RegraFiscalFacade {
  get regrasFiscais() {
    return this.store.regrasFiscais;
  }
  get regrasFiscais$() {
    return this.store.regrasFiscais$;
  }
  constructor(
      private store: RegraFiscalStore,
      private service: RegraFiscalService,
      private codigoFiscalService: CodigoFiscalService,
  ) {}
  async adicionar(regraFiscal: RegraFiscal) {
    const novoRegraFiscal = await this.service.adicionar(regraFiscal);
    if (novoRegraFiscal) {
      this.store.adicionar(novoRegraFiscal.toListagem());
    }
    return novoRegraFiscal;
  }
  async buscarRegrasFiscais(filtro?: RegraFiscalFiltro) {
    const regrasFiscais = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(regrasFiscais);
    return regrasFiscais;
  }
  async buscarCodigosFiscais(filtro?: CodigoFiscalFiltro) {
    return this.codigoFiscalService.buscarTodos(filtro);
  }
  alterar(regraFiscal: RegraFiscal) {
    return this.service.alterar(regraFiscal, regraFiscal.id);
  }
  buscarPorId(regraFiscalId: string) {
    return this.service.buscarPorId(regraFiscalId);
  }
  filtrar(filtro: string, devolucao: boolean) {
    this.store.filtrar(filtro, devolucao);
  }
  limparListaRegrasFiscais() {
    this.store.regrasFiscais = null;
    this.store.regrasFiscaisFiltrados = null;
  }
  selecionarPorDescricao(descricao: String) {
    return this.regrasFiscais?.find(
               (regraFiscal) => regraFiscal.descricao == descricao) as
        RegraFiscal;
  }
  selecionarPorCfop(cfop: String) {
    return this.regrasFiscais?.find(
               (regraFiscal) => regraFiscal.cfopTributado == cfop) as
        RegraFiscal;
  }
  selecionarPorCfopSt(cfopSt: String) {
    return this.regrasFiscais?.find(
               (regraFiscal) => regraFiscal.cfopSt == cfopSt) as RegraFiscal;
  }
}
