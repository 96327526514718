import '@app/shared/extensions/moment.extension';

import {FornecedorProduto} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor-produto.entity';
import {Pessoa} from '@app/abstraction/domain/entities/cadastros/pessoa/pessoa.entity';
import {LancamentoParcela} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import {FornecedorListagem} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-listagem.interface';
import * as moment from 'moment';

export class Fornecedor extends Pessoa {
  ativo: boolean;
  compraAutorizar: boolean;
  compraValorMinimo: number;
  id: string;
  observacao: string;
  produtos: FornecedorProduto[];
  lancamentosParcela: LancamentoParcela[];
  lastModifiedOn: Date;
  createdOn: Date;
  createdBy: string;
  nomeCreatedBy: string;
  planoConta3Id: string;
  sequencia: number;

  static from(json: any = {}): Fornecedor {
    const fornecedor = new Fornecedor();
    fornecedor.ativo = json.ativo;
    fornecedor.compraAutorizar = json.compraAutorizar;
    fornecedor.compraValorMinimo = json.compraValorMinimo;
    fornecedor.id = json.id;
    fornecedor.lancamentosParcela = json.lancamentosParcela;
    fornecedor.observacao = json.observacao?.trim();
    fornecedor.produtos =
        json.produtos?.map((x: any) => FornecedorProduto.from(x));
    fornecedor.lastModifiedOn = json.lastModifiedOn != null ?
        moment(json.lastModifiedOn).toDate() :
        null;
    fornecedor.createdOn =
        json.createdOn != null ? moment(json.createdOn).toDate() : null;
    fornecedor.createdBy = json.createdBy;
    fornecedor.pessoaFrom(json);
    return fornecedor;
  }
  toAPI() {
    this.pessoaTipo = Number(this.pessoaTipo);
    this.compraValorMinimo = Number(this.compraValorMinimo);
    this.preencherUfEnderecos();
    if (this.nascimento)
      this.nascimento = moment(this.nascimento).toLocalOnlyDate();
  }
  toListagem() {
    return <FornecedorListagem>{
      ativo: this.ativo,
      compraAutorizar: this.compraAutorizar,
      documento: this.documento,
      id: this.id,
      im: this.im,
      createdOn: this.createdOn,
      nomeFantasia: this.nomeFantasia,
      nomeRazaoSocial: this.nomeRazaoSocial,
      pessoaTipo: this.pessoaTipo,
      rgIe: this.rgIe,
      sequencia: this.sequencia,
    };
  }
  buscarProduto(codigoReferencias: string) {
    return this.produtos?.find(x => x.codigoReferencia === codigoReferencias);
  }
}
