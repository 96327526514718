import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Pessoa} from '@app/abstraction/domain/entities/cadastros/pessoa/pessoa.entity';
import {PessoaFiltro} from '@app/abstraction/domain/interfaces/cadastros/pessoa/pessoa-filtro.interface';
import {PessoaListagem} from '@app/abstraction/domain/interfaces/cadastros/pessoa/pessoa-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PessoaService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}

  buscarPorId(id: string) {
    const response$ =
        this.client.get<Pessoa>(`${API.PESSOA}/${id}`)
            .pipe(map((json: Pessoa) => json ? Pessoa.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPorDocumento(documento: string) {
    const response$ =
        this.client.get<Pessoa>(`${API.PESSOA}/documento/${documento}`)
            .pipe(map((json: Pessoa) => json ? Pessoa.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: PessoaFiltro) {
    const response$ = this.client.get<PessoaListagem[]>(
        this.geradorQueryString.gerar(`${API.PESSOA}`, filtro));
    return lastValueFrom(response$);
  }
}
