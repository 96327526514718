import {FormControl, FormGroup} from '@angular/forms';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {LancamentoPagamento} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-pagamento.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {LancamentoParcelaListagem} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-listagem.interface';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

export interface ILancamentoPagamentoForm {
  id: FormControl<string>;
  valor: FormControl<number>;
  valorTotal: FormControl<number>;
  valorMulta: FormControl<number>;
  valorJuros: FormControl<number>;
  valorOutrosAcrescimos: FormControl<number>;
  valorDesconto: FormControl<number>;
  dataPagamento: FormControl<Date|string>;
  observacao: FormControl<string>;
  contaBancariaId: FormControl<string>;
  formaPagamentoId: FormControl<string>;
}

export class LancamentoPagamentoForm extends BaseForm {
  form: FormGroup<ILancamentoPagamentoForm>;
  operacaoForm: OperacaoForm;
  mostarFormaPagamentoCadastroRapidoModal: boolean;
  mostrarAcrescimosDescontosModal: boolean;
  mostrarContaBancariaCadastroRapidoModal: boolean;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Novo pagamento' : 'Editar pagamento';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(
      private errorMessagesFacade?: ErrorMessageFacade,
      private toastrService?: ToastrService,
  ) {
    super();
  }
  monitorarValor() {
    this.form.controls.valor.valueChanges.subscribe(
        () => this.somarValorTotal());
  }
  somarValorTotal() {
    if (this.form) {
      const valorDesconto = Number(this.form.controls.valorDesconto.value);
      const valorJuros = Number(this.form.controls.valorJuros.value);
      const valorMulta = Number(this.form.controls.valorMulta.value);
      const valorOutrosAcrescimos =
          Number(this.form.controls.valorOutrosAcrescimos.value);
      const valor = Number(this.form.controls.valor.value);
      this.form.controls.valorTotal.setValue(
          valor + valorOutrosAcrescimos + valorMulta + valorJuros -
          valorDesconto);
    }
  }
  abrirContaBancariaCadastroRapidoModal() {
    this.mostrarContaBancariaCadastroRapidoModal = true;
  }
  abrirAcrescimosDescontosModal() {
    this.mostrarAcrescimosDescontosModal = true;
  }
  abrirFormaPagamentoCadastroRapidoModal() {
    this.mostarFormaPagamentoCadastroRapidoModal = true;
  }
  criarForm(pagamento?: LancamentoPagamento) {
    this.form = new FormGroup<ILancamentoPagamentoForm>({
      id: new FormControl<string>(pagamento?.id),
      valor: new FormControl<number>(
          pagamento?.valor,
          [
            CustomValidators.required('Valor pago é obrigatório'),
            CustomValidators.min(0.01, 'Valor pago deve ser maior que 0'),
          ]),
      valorTotal: new FormControl<number>(pagamento?.valorTotal),
      valorMulta: new FormControl<number>(pagamento?.valorMulta),
      valorJuros: new FormControl<number>(pagamento?.valorJuros),
      valorOutrosAcrescimos:
          new FormControl<number>(pagamento?.valorOutrosAcrescimos),
      valorDesconto: new FormControl<number>(pagamento?.valorDesconto),
      dataPagamento: new FormControl<Date|string>(
          pagamento?.dataPagamento ?? new Date(),
          [
            CustomValidators.required('Data do pagamento é obrigatória'),
            CustomValidators.data(
                'dd/MM/yyyy', 'Inserir data no formato: "dd/mm/aaaa"'),
          ]),
      observacao: new FormControl<string>(pagamento?.observacao),
      formaPagamentoId: new FormControl<string>(
          pagamento?.formaPagamentoId,
          CustomValidators.required('Forma de pagamento é obrigatória'),
          ),
      contaBancariaId: new FormControl<string>(
          pagamento?.contaBancariaId,
          CustomValidators.required('Conta bancária é obrigatória'),
          ),
    });
    return this.form;
  }
  fecharFormaPagamentoCadastroRapidoModal() {
    this.mostarFormaPagamentoCadastroRapidoModal = false;
  }
  fecharAcrescimosDescontosModal() {
    this.mostrarAcrescimosDescontosModal = false;
  }
  fecharContaBancariaCadastroRapidoModal() {
    this.mostrarContaBancariaCadastroRapidoModal = false;
  }
  isValid(parcela: LancamentoParcelaListagem, pagamento: LancamentoPagamento) {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    if (this.operacaoFormAdicionar) {
      if (this.form.controls.valor.value > parcela.valorPendente) {
        this.toastrService.warning('Valor pago ultapassa o valor pendente');
        return false;
      }
    } else {
      // Reverte valor pendente com o valor do pagamento
      const valorPendente = parcela.valorPendente + pagamento.valorTotal;
      if (this.form.controls.valor.value > valorPendente) {
        this.toastrService.warning('Valor pago ultapassa o valor pendente');
        return false;
      }
    }
    return true;
  }
  setarTipoFormulario(
      pagamento?: LancamentoPagamento,
      parcela?: LancamentoParcelaListagem,
  ) {
    if (pagamento) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(LancamentoPagamento.from(pagamento));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
      this.form.controls.valor.setValue(parcela?.valorPendente);
      this.form.controls.formaPagamentoId.setValue(parcela?.formaPagamentoId);
      this.form.controls.contaBancariaId.setValue(parcela?.contaBancariaId);
    }
  }
  preencherForm(pagamento: LancamentoPagamento) {
    pagamento.dataPagamento = moment(pagamento.dataPagamento).toDate();
    this.operacaoForm = OperacaoForm.Editar;
    this.form.patchValue(pagamento);
  }
  setarFormaPagamentoCadastrada(formaPagamento: FormaPagamento) {
    this.form.controls.formaPagamentoId.setValue(formaPagamento.id);
  }
  salvarAcrescimosDescontosModal() {
    this.somarValorTotal();
    this.fecharAcrescimosDescontosModal();
  }
  setarContaBancariaCadastrada(contaBancaria: ContaBancaria) {
    this.form.controls.contaBancariaId.setValue(contaBancaria.id);
  }
}
