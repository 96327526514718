<p-dialog
  header="Nova forma de pagamento"
  [style]="{ width: isDesktop ? '65%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharModal()"
  (onShow)="aoAbrirModal()"
>
  <p-tabView [scrollable]="true" *ngIf="formaPagamentoForm && formaPagamentoForm.form">
    <p-tabPanel header="Dados da forma de pagamento" [selected]="true">
      <div class="formgrid grid">
        <!-- #region DESCRICAO -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6">
          <app-custom-label texto="Descrição" [obrigatorio]="true">
          </app-custom-label>
          <app-text-input
            [autoFocus]="true"
            [control]="formaPagamentoForm.form.controls.descricao"
            [maxlength]="100"
          >
          </app-text-input>
        </div>
        <!-- #endregion -->

        <!-- #region CODIGO EXTERNO -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6">
          <app-custom-label texto="Código externo"></app-custom-label>
          <app-numeric-input
            [control]="formaPagamentoForm.form.controls.codigoExterno"
            [options]="inteiroMask"
          >
          </app-numeric-input>
        </div>
        <!-- #endregion -->

        <!-- #region TIPO DE PAGAMENTO NF-E -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6">
          <app-custom-label texto="Tipo de pagamento NF-e" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            [control]="formaPagamentoForm.form.controls.tipoPagamentoNfe"
            [options]="tiposPagamentoNfe"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region FORMAS DE PARCELAMENTO -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6">
          <app-custom-label
            texto="Forma de parcelamentos"
            [obrigatorio]="true"
            [cadastroRapido]="true"
            (aoClicarAdicionar)="abrirFormaParcelamentoCadastroRapidoModal()"
          >
          </app-custom-label>
          <app-multiselect-input
            optionValue="id"
            optionLabel="descricao"
            [control]="formaPagamentoForm.form.controls.formasParcelamento"
            [options]="formasParcelamento"
          ></app-multiselect-input>
        </div>
        <!-- #endregion -->

        <!-- #region TIPO DE OPERAÇÃO -->
        <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
          <app-custom-label texto="Tipo de operação"></app-custom-label>
          <app-dropdown-input
            [control]="formaPagamentoForm.form.controls.tipoOperacao"
            [options]="tiposOperacao"
            [showClear]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region PLANO DE CONTAS -->
        <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
          <app-custom-label texto="Plano de contas" [cadastroRapido]="true">
          </app-custom-label>
          <app-dropdown-input
            optionLabel="label"
            optionValue="value"
            [control]="formaPagamentoForm.form.controls.planoContaId"
            [options]="planosContas"
            [showClear]="true"
            [showFilter]="true"
            [group]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region CODIGO SAT -->
        <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6">
          <app-custom-label texto="Código SAT"></app-custom-label>
          <app-text-input
            [control]="formaPagamentoForm.form.controls.codigoSat"
            [maxlength]="3"
          ></app-text-input>
        </div>
        <!-- #endregion -->

        <!-- #region TECLA DE ATALHO -->
        <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
          <app-custom-label texto="Tecla de Atalho"></app-custom-label>
          <app-text-input
            [control]="formaPagamentoForm.form.controls.teclaAtalho"
            [maxlength]="20"
          ></app-text-input>
        </div>
        <!-- #endregion -->

        <!-- #region ORDEM -->
        <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6">
          <app-custom-label texto="Ordem"></app-custom-label>
          <app-numeric-input
            [control]="formaPagamentoForm.form.controls.ordem"
            [options]="inteiroMask"
            [maxlength]="5"
          >
          </app-numeric-input>
        </div>
        <!-- #endregion -->
      </div>
      <div class="formgrid grid">
        <!-- #region BAIXADO -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Baixado"
            controlName="baixado"
            [control]="formaPagamentoForm.form.controls.baixado"
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region PERMITE TROCO -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Permite troco"
            controlName="permiteTroco"
            [control]="formaPagamentoForm.form.controls.permiteTroco"
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region PERMITE MAIS DE UMA -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Permite mais de uma"
            controlName="permiteMaisDeUma"
            [control]="formaPagamentoForm.form.controls.permiteMaisDeUma"
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region GERA CONVENIO -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Gera convênio"
            controlName="geraConvenio"
            [control]="formaPagamentoForm.form.controls.geraConvenio"
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region GERA CONCILIACAO -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Gera conciliação"
            controlName="geraConciliacao"
            [control]="formaPagamentoForm.form.controls.geraConciliacao"
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region GERA MOVIMENTO FISCAL -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Gera movimento fiscal"
            controlName="geraMovimentoFiscal"
            [control]="formaPagamentoForm.form.controls.geraMovimentoFiscal"
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region SOLICITA IDENT. CLIENTE -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Solicita identificação cliente"
            controlName="solicitaIdentificacaoCliente"
            [control]="
              formaPagamentoForm.form.controls.solicitaIdentificacaoCliente
            "
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region EXIBE NO CONVENIO -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Exibe no convênio"
            controlName="exibeNoConvenio"
            [control]="formaPagamentoForm.form.controls.exibeNoConvenio"
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region EXIBE NO PEDIDO -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Exibe no pedido"
            controlName="exibeNoPedido"
            [control]="formaPagamentoForm.form.controls.exibeNoPedido"
          ></app-checkbox-input>
        </div>
        <!-- #endregion -->

        <!-- #region BOLETO -->
        <div class="col-12 xl:col-2 lg:col-4 md:col-6 sm:col-6 lg:mt-0 sm:mt-4">
          <app-checkbox-input
            label="Boleto"
            controlName="boleto"
            [control]="formaPagamentoForm.form.controls.boleto"
          >
          </app-checkbox-input>
        </div>
        <!-- #endregion -->
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
<app-forma-parcelamento-cadastro-rapido-modal
  *ngIf="formaPagamentoForm && formaPagamentoForm.form"
  [mostrarModal]="formaPagamentoForm.mostarFormaParcelamentoCadastroRapidoModal"
  (aoCancelar)="fecharFormaParcelamentoCadastroRapidoModal()"
></app-forma-parcelamento-cadastro-rapido-modal>
