import {Injectable} from '@angular/core';
import {UnidadeComercialListagem} from '@app/abstraction/domain/interfaces/produtos/unidade-comercial/unidade-comercial-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UnidadeComercialStore {
  private readonly _unidadesComerciais =
      new BehaviorSubject<UnidadeComercialListagem[]>(null);
  get unidadesComerciais(): UnidadeComercialListagem[] {
    return this._unidadesComerciais.getValue();
  }
  set unidadesComerciais(unidadesComerciais: UnidadeComercialListagem[]) {
    this._unidadesComerciais.next(unidadesComerciais);
  }
  get unidadesComerciais$() {
    return this._unidadesComerciais.asObservable();
  }
  adicionar(unidadeComercial: UnidadeComercialListagem) {
    if (!this.unidadesComerciais) {
      this.unidadesComerciais = [];
    }
    this.unidadesComerciais.push(unidadeComercial);
  }
  adicionarRange(unidadesComerciais: UnidadeComercialListagem[]) {
    if (!this.unidadesComerciais) {
      this.unidadesComerciais = [];
    }
    unidadesComerciais.forEach((unidadeComercial) => {
      if (!this.unidadesComerciais.some((c) => c.id === unidadeComercial.id)) {
        this.unidadesComerciais.push(unidadeComercial);
      }
    });
  }
}
