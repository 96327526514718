import {Injectable} from '@angular/core';
import {ProdutoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-listagem.interface';
import {ProdutoSimplificadoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-simplificado-listagem.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProdutoStore {
  private readonly _produtos = new BehaviorSubject<ProdutoListagem[]>(null);

  get produtos(): ProdutoListagem[] {
    return this._produtos.getValue();
  }
  set produtos(produtos: ProdutoListagem[]) {
    this._produtos.next(produtos);
  }
  get produtos$(): Observable<ProdutoListagem[]> {
    return this._produtos.asObservable();
  }

  private readonly _produtosSimplificados =
      new BehaviorSubject<ProdutoSimplificadoListagem[]>(null);

  get produtosSimplificados(): ProdutoSimplificadoListagem[] {
    return this._produtosSimplificados.getValue();
  }
  set produtosSimplificados(produtos: ProdutoSimplificadoListagem[]) {
    this._produtosSimplificados.next(produtos);
  }
  get produtosSimplificados$(): Observable<ProdutoSimplificadoListagem[]> {
    return this._produtosSimplificados.asObservable();
  }

  adicionarRange(produtos: ProdutoListagem[]) {
    if (!this.produtos) {
      this.produtos = [];
    }
    produtos.forEach((produto) => {
      this.produtos.push(produto);
    });
  }
  adicionarRangeSimplificados(produtosSimplificados:
                                  ProdutoSimplificadoListagem[]) {
    if (!this.produtosSimplificados) {
      this.produtosSimplificados = [];
    }
    produtosSimplificados.forEach((produto) => {
      this.produtosSimplificados.push(produto);
    });
  }
}
