import {FormControl, FormGroup} from '@angular/forms';
import {PlanoContasNivel1} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-1.entity';
import {PlanoContasNivel2} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-2.entity';
import {PlanoContasNivel3} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-3.entity';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface IPlanoContaForm {
  planoConta1Id: FormControl<string>;
  planoConta2Id: FormControl<string>;
  planoConta3Id: FormControl<string>;
  descricao: FormControl<string>;
  contaCodigo: FormControl<string>;
  contaContabil: FormControl<string>;
  ativo: FormControl<boolean>;
  id: FormControl<string>;
  codigoNatureza: FormControl<number>;
  tipoConta: FormControl<string>;
  despesa: FormControl<boolean>;
  planoContaGrupoId: FormControl<string>;
}

export class PlanoContaForm extends BaseForm {
  form: FormGroup<IPlanoContaForm>;
  operacaoForm: OperacaoForm;
  nivelPlanoContas = 1;
  mostrarPlanoContasCadastroRapidoModal = false;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Novo plano de contas' :
                                        'Editar plano de contas';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  get operacaoFormEditar() {
    return this.operacaoForm === OperacaoForm.Editar;
  }
  get nivel2Selecionado() {
    return this.nivelPlanoContas == 2;
  }
  get nivel3Selecionado() {
    return this.nivelPlanoContas == 3;
  }
  constructor(
      private errorMessagesFacade: ErrorMessageFacade,
      private planoContasFacade: PlanoContasFacade,
  ) {
    super();
  }
  private preencherForm(
      planoContas: PlanoContasNivel1|PlanoContasNivel2|PlanoContasNivel3,
      nivel: number) {
    switch (nivel) {
      case 2:
        this.form.controls.planoConta2Id.setValue(planoContas.id);
        break;
      case 3:
        this.form.controls.planoConta3Id.setValue(planoContas.id);
        break;
      default:
        this.form.controls.planoConta1Id.setValue(planoContas.id);
        break;
    }
    this.nivelPlanoContas = nivel;
    this.form.patchValue(planoContas as any);
  }
  private setarValidacoesNivel1() {
    this.removerValidator('planoConta1Id', this.form);
    this.removerValidator('planoConta2Id', this.form);
    this.removerValidator('planoConta3Id', this.form);
    this.removerValidator('contaContabil', this.form);
  }
  private setarValidacoesNivel2() {
    this.adicionarValidator(
        'planoConta1Id',
        [CustomValidators.required('Plano de contas nível 1 é obrigatório')],
        this.form);
    this.removerValidator('planoConta2Id', this.form);
    this.removerValidator('planoConta3Id', this.form);
    this.removerValidator('contaContabil', this.form);
  }
  private setarValidacoesNivel3() {
    this.removerValidator('planoConta1Id', this.form);
    this.adicionarValidator(
        'planoConta2Id',
        [CustomValidators.required('Plano de contas nível 2 é obrigatório')],
        this.form);
    this.removerValidator('planoConta3Id', this.form);
    this.adicionarValidator(
        'contaContabil',
        [CustomValidators.required('Conta contábil é obrigatório')], this.form);
  }
  async buscarPlanosContasPorNivel() {
    switch (this.nivelPlanoContas) {
      case 2:
        this.setarValidacoesNivel2();
        this.planoContasFacade.buscarPlanosContasNivel1();
        break;
      case 3:
        this.setarValidacoesNivel3();
        this.planoContasFacade.buscarPlanosContasNivel2();
        break;
      default:
        this.setarValidacoesNivel1();
        break;
    }
  }
  criarForm() {
    this.form = new FormGroup<IPlanoContaForm>({
      id: new FormControl(null),
      descricao: new FormControl(
          null, CustomValidators.required('Descrição é obrigatória')),
      contaCodigo: new FormControl(
          null, CustomValidators.required('Código da conta é obrigatório')),
      planoConta1Id: new FormControl(null),
      planoConta2Id: new FormControl(null),
      planoConta3Id: new FormControl(null),
      contaContabil: new FormControl(null),
      ativo: new FormControl(true),
      codigoNatureza: new FormControl(null),
      tipoConta: new FormControl(null),
      despesa: new FormControl(false),
      planoContaGrupoId: new FormControl(null),
    });
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(planoContas?: any, planoContasNivel?: any) {
    if (planoContas && planoContasNivel) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(planoContas, planoContasNivel);
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
  abrirPlanoContasCadastroRapidoModal() {
    this.mostrarPlanoContasCadastroRapidoModal = true;
  }
  fecharPlanoContasCadastroRapidoModal() {
    this.mostrarPlanoContasCadastroRapidoModal = false;
  }
  setarPlanoContasCadastrado(planoContas: any) {
    if (planoContas.planoConta2Id) {
      this.form.controls.planoConta2Id.setValue(planoContas.planoConta2Id);
    }
    if (planoContas.planoConta3Id) {
      this.form.controls.planoConta3Id.setValue(planoContas.id);
    }
  }
}
