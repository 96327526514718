import {Injectable} from '@angular/core';
import {ProdutoVariacaoCorService} from '@app/abstraction/data/services/produtos/produto-variacao-cor.service';
import {ProdutoVariacaoCorStore} from '@app/abstraction/data/stores/produtos/produto-variacao-cor.store';
import {ProdutoVariacaoCor} from '@app/abstraction/domain/entities/produtos/produto-variacao-cor/produto-variacao-cor.entity';
import {ProdutoVariacaoCorFiltro} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-cor/produto-variacao-cor-filtro.interface';

@Injectable({providedIn: 'root'})
export class ProdutoVariacaoCorFacade {
  get produtoVariacaoCores() {
    return this.store.produtoVariacaoCores;
  }
  get produtoVariacaoCores$() {
    return this.store.produtoVariacaoCores$;
  }
  constructor(
      private store: ProdutoVariacaoCorStore,
      private service: ProdutoVariacaoCorService) {}

  async adicionar(produtoVariacaoCor: ProdutoVariacaoCor) {
    const novoProdutoVariacaoCor =
        await this.service.adicionar(produtoVariacaoCor);
    if (novoProdutoVariacaoCor) {
      this.store.adicionar(novoProdutoVariacaoCor.toListagem());
    }
    return novoProdutoVariacaoCor;
  }
  async buscarProdutoVariacaoCores(filtro?: ProdutoVariacaoCorFiltro) {
    const produtoVariacaoCores = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(produtoVariacaoCores);
  }
  async atualizarSituacao(corId: string) {
    const produtoVariacaoCor =
        this.produtoVariacaoCores.find((p) => p.id === corId);
    const status =
        await this.service.atualizarSituacao(corId, !produtoVariacaoCor.ativo);
    produtoVariacaoCor.ativo = status;
    return status;
  }
  alterar(produtoVariacaoCor: ProdutoVariacaoCor) {
    return this.service.alterar(produtoVariacaoCor, produtoVariacaoCor.id);
  }
  buscarPorId(corId: string) {
    return this.service.buscarPorId(corId);
  }
  limparListaProdutoVariacaoCores() {
    this.store.produtoVariacaoCores = null;
  }
}
