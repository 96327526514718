class AtividadePrincipal {
  code: string;
  text: string;
}

class AtividadesSecundaria {
  code: string;
  text: string;
}
class QuadroDeSocio {
  informacoes: string;
}
class Result {
  get numeroCep() {
    return this.cep?.somenteNumeros();
  }
  abertura: string;
  atividade_principal: AtividadePrincipal;
  atividades_secundarias: AtividadesSecundaria[];
  bairro: string;
  capital_social: string;
  cep: string;
  complemento: string;
  data_situacao_especial: string;
  dt_situacao_cadastral: string;
  email: string;
  entidade_federativo_responsavel: string;
  fantasia: string;
  logradouro: string;
  motivo_situacao_cadastral: string;
  municipio: string;
  natureza_juridica: string;
  nome: string;
  numero: string;
  numero_de_inscricao: string;
  porte: string;
  quadro_de_socios: QuadroDeSocio[];
  quadro_socios: any[];
  situacao: string;
  situacao_especial: string;
  telefone: string;
  tipo: string;
  uf: string;
}

export class DadosCnpj {
  result: Result;
  return: string;
  status: boolean;
  retornarDataAbertura() {
    const dia = Number(this.result.abertura.split('/')[0]);
    const mes = Number(this.result.abertura.split('/')[1]);
    const ano = Number(this.result.abertura.split('/')[2]);
    return new Date(ano, mes - 1, dia);
  }
  static criarDadosCnpj(json: any = {}): DadosCnpj {
    const dadosCnpj = new DadosCnpj();
    dadosCnpj.result = json.result;
    dadosCnpj.return = json.return;
    dadosCnpj.status = json.status;
    return dadosCnpj;
  }
}
