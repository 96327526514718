import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {empresaSelecionada, usuarioLogado, usuarioNaoLogado} from '@app/shared/guards/auth/auth.guard';

import {AppLayoutComponent} from './modules/layout/app.layout.component';


export const APP_ROUTES: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
            import('./modules/home/home.module').then((m) => m.HomeModule),
      },
    ],
    canActivate: [usuarioLogado, empresaSelecionada],
  },
  {
    path: 'selecionar-empresa',
    loadChildren: () =>
        import('./modules/selecionar-empresa/selecionar-empresa.module')
            .then((m) => m.SelecionarEmpresaModule),
    canActivate: [usuarioLogado],
  },
  {
    path: 'auth',
    loadChildren: () =>
        import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [usuarioNaoLogado],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, {enableTracing: false})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
