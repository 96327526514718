import {Injectable} from '@angular/core';
import {ContaBancariaService} from '@app/abstraction/data/services/financeiro/conta-bancaria.service';
import {ContaBancariaStore} from '@app/abstraction/data/stores/financeiro/conta-bancaria.store';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {ContaBancariaFiltro} from '@app/abstraction/domain/interfaces/financeiro/conta-bancaria/conta-bancaria-filtro.interface';

@Injectable({providedIn: 'root'})
export class ContaBancariaFacade {
  get contasBancarias() {
    return this.store.contasBancarias;
  }
  get contasBancarias$() {
    return this.store.contasBancarias$;
  }
  constructor(
      private store: ContaBancariaStore,
      private service: ContaBancariaService) {}

  async adicionar(contaBancaria: ContaBancaria) {
    const novaContaBancaria = await this.service.adicionar(contaBancaria);
    if (novaContaBancaria) {
      this.store.adicionar(novaContaBancaria.toListagem());
    }
    return novaContaBancaria;
  }
  async atualizarSituacao(id: string) {
    const contaBancaria = this.contasBancarias.find((p) => p.id === id);
    const status =
        await this.service.atualizarSituacao(id, !contaBancaria.ativo);
    contaBancaria.ativo = status;
    return status;
  }
  async buscarContasBancarias(filtro?: ContaBancariaFiltro) {
    const contasBancarias = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(contasBancarias);
  }
  alterar(contaBancaria: ContaBancaria) {
    return this.service.alterar(contaBancaria, contaBancaria.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaContasBancarias() {
    this.store.contasBancarias = null;
  }
}
