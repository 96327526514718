import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NotaFiscalEvento} from '@app/abstraction/domain/entities/fiscal/nota-fiscal/evento.entity';
import {NotaFiscalItem} from '@app/abstraction/domain/entities/fiscal/nota-fiscal/item.entity';
import {NotaFiscal} from '@app/abstraction/domain/entities/fiscal/nota-fiscal/nota-fiscal.entity';
import {NotaFiscalRetorno} from '@app/abstraction/domain/entities/fiscal/nota-fiscal/retorno.entity';
import {CertificadoDigital} from '@app/abstraction/domain/interfaces/fiscal/certificado-digital/certificado-digital.interface';
import {NotaFiscalFiltro} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-filtro.interface';
import {NotaFiscalListagem} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-listagem.interface';
import {NotaFiscalResumoFiltro} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-resumo-filtro.interface';
import {NotaFiscalResumoListagem} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-resumo-listagem.interface';
import {NotaFiscalStatus} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-status.interface';
import {NotaFiscalVariacaoCustoRelatorio} from '@app/abstraction/domain/interfaces/relatorios/fiscal/variacao-custos/nota-fiscal-entrada-variacao-custo.interface';
import {API} from '@app/shared/constants/api.constant';
import {CodigosManifestacao} from '@app/shared/enums/codigos-manifestacao.enum';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotaFiscalService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}

  /* #region MANIFESTADOR */
  consultarNotasFiscaisManifestador() {
    const response$ = this.client.get(`${API.FISCAL_MANIFESTACOES_CONSULTAS}`);
    return lastValueFrom(response$);
  }
  confirmarNotaFiscal(chaveEletronica: string) {
    const response$ =
        this.client
            .post<NotaFiscalEvento>(
                `${API.FISCAL_MANIFESTACOES}/${chaveEletronica}`,
                {codigoManifestacao: CodigosManifestacao.Confirmar})
            .pipe(
                map((json: NotaFiscalEvento) =>
                        json ? NotaFiscalEvento.from(json) : null));
    return lastValueFrom(response$);
  }
  darCienciaNotaFiscal(chaveEletronica: string) {
    const response$ =
        this.client
            .post<NotaFiscalEvento>(
                `${API.FISCAL_MANIFESTACOES}/${chaveEletronica}`,
                {codigoManifestacao: CodigosManifestacao.DarCiencia})
            .pipe(
                map((json: NotaFiscalEvento) =>
                        json ? NotaFiscalEvento.from(json) : null));
    return lastValueFrom(response$);
  }
  desconhecerNotaFiscal(chaveEletronica: string) {
    const response$ =
        this.client
            .post<NotaFiscalEvento>(
                `${API.FISCAL_MANIFESTACOES}/${chaveEletronica}`,
                {codigoManifestacao: CodigosManifestacao.Desconhecer})
            .pipe(
                map((json: NotaFiscalEvento) =>
                        json ? NotaFiscalEvento.from(json) : null));
    return lastValueFrom(response$);
  }
  naoRealizarOperacao(chaveEletronica: string, justificativa: string) {
    const response$ =
        this.client
            .post<NotaFiscalEvento>(
                `${API.FISCAL_MANIFESTACOES}/${chaveEletronica}`, {
                  codigoManifestacao: CodigosManifestacao.OperacaoNaoRealizada,
                  justificativa
                })
            .pipe(
                map((json: NotaFiscalEvento) =>
                        json ? NotaFiscalEvento.from(json) : null));
    return lastValueFrom(response$);
  }
  /* #endregion */

  /* #region NOTA FISCAL ENTRADA */
  adicionarEntrada(notaFiscal: NotaFiscal) {
    const response$ =
        this.client.post<NotaFiscal>(API.NOTA_FISCAL_ENTRADA, notaFiscal)
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  alterarEntrada(notaFiscal: NotaFiscal) {
    const response$ =
        this.client
            .put<NotaFiscal>(
                `${API.NOTA_FISCAL_ENTRADA}/${notaFiscal.id}`, notaFiscal)
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodasDeEntrada(filtro?: NotaFiscalFiltro) {
    if (filtro?.numeroNotaFiscal) {
      filtro.numeroNotaFiscal = filtro?.numeroNotaFiscal?.toString() ?? '';
    }
    const response$ = this.client.get<NotaFiscalListagem[]>(
        this.geradorQueryString.gerar(API.NOTA_FISCAL_ENTRADA, filtro));
    return lastValueFrom(response$);
  }
  buscarEntradaPorId(id: string) {
    const response$ =
        this.client.get<NotaFiscal>(`${API.NOTA_FISCAL_ENTRADA}/${id}`)
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarEntradaPorChaveEletronica(chaveEletronica: string) {
    const response$ =
        this.client
            .get<NotaFiscal>(`${API.NOTA_FISCAL_ENTRADA}/chave-eletronica/${
                chaveEletronica}`)
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarNotasFiscaisResumos(filtro?: NotaFiscalResumoFiltro) {
    const response$ = this.client.get<NotaFiscalResumoListagem[]>(
        this.geradorQueryString.gerar(API.NOTA_FISCAL_ENTRADA_RESUMOS, filtro));
    return lastValueFrom(response$);
  }
  /* #endregion */

  /* #region NOTA FISCAL SAÍDA */
  adicionarSaida(notaFiscal: NotaFiscal) {
    const response$ =
        this.client.post<NotaFiscal>(API.NOTA_FISCAL_SAIDA, notaFiscal)
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  alterarSaida(notaFiscal: NotaFiscal) {
    const response$ =
        this.client
            .put<NotaFiscal>(
                `${API.NOTA_FISCAL_SAIDA}/${notaFiscal.id}`, notaFiscal)
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodasDeSaida(filtro?: NotaFiscalFiltro) {
    if (filtro?.numeroNotaFiscal) {
      filtro.numeroNotaFiscal = filtro?.numeroNotaFiscal?.toString() ?? '';
    }
    const response$ = this.client.get<NotaFiscalListagem[]>(
        this.geradorQueryString.gerar(API.NOTA_FISCAL_SAIDA, filtro));
    return lastValueFrom(response$);
  }
  buscarSaidaPorId(id: string) {
    const response$ =
        this.client.get<NotaFiscal>(`${API.NOTA_FISCAL_SAIDA}/${id}`)
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  excluirNotaFiscalSaida(id: string) {
    const response$ = this.client.delete(`${API.NOTA_FISCAL_SAIDA}/${id}`);
    return lastValueFrom(response$);
  }
  excluirNotaFiscalEntrada(id: string) {
    const response$ = this.client.delete(`${API.NOTA_FISCAL_ENTRADA}/${id}`);
    return lastValueFrom(response$);
  }
  cancelarNotaFiscalEntrada(id: string) {
    const response$ =
        this.client.put(`${API.NOTA_FISCAL_ENTRADA}/${id}/cancelamento`, null);
    return lastValueFrom(response$);
  }
  buscarRetornosSaida(id: string) {
    const response$ =
        this.client
            .get<NotaFiscalRetorno[]>(`${API.NOTA_FISCAL_SAIDA}/${id}/retornos`)
            .pipe(map((json: NotaFiscalRetorno[]) => {
              return json.map(
                  (item: NotaFiscalRetorno) => NotaFiscalRetorno.from(item));
            }));
    return lastValueFrom(response$);
  }
  buscarCancelamentosSaida(chaveEletronica: string) {
    const response$ =
        this.client
            .get<NotaFiscalEvento[]>(
                `${API.NOTA_FISCAL_SAIDA}/${chaveEletronica}/cancelamentos`)
            .pipe(map((json: NotaFiscalEvento[]) => {
              return json.map(
                  (item: NotaFiscalEvento) => NotaFiscalEvento.from(item));
            }));
    return lastValueFrom(response$);
  }
  buscarCartasCorrecaoSaida(chaveEletronica: string) {
    const response$ =
        this.client
            .get<NotaFiscalEvento[]>(
                `${API.NOTA_FISCAL_SAIDA}/${chaveEletronica}/carta-correcoes`)
            .pipe(map((json: NotaFiscalEvento[]) => {
              return json.map(
                  (item: NotaFiscalEvento) => NotaFiscalEvento.from(item));
            }));
    return lastValueFrom(response$);
  }
  preVisualizarNotaFiscalSaida(notaFiscal: NotaFiscal) {
    const response$ = this.client.post<string>(
        `${API.FISCAL_NOTA_FISCAL_PRE_VISUALIZACAO}`, notaFiscal);
    return lastValueFrom(response$);
  }
  enviarEmailNotaFiscalSaida(id: string, emailDestinatario: string) {
    const response$ = this.client.post(
        `${API.NOTA_FISCAL_SAIDA}/${id}/enviar`, emailDestinatario);
    return lastValueFrom(response$);
  }
  /* #endregion */

  /* #region OPERAÇÃO FISCAL ENTRADA */
  baixarNotaFiscal(
      chaveEletronica: string,
      dataHoraLocal: Date,
      codigoManifestacao: number,
  ) {
    const response$ =
        this.client
            .put<NotaFiscal>(
                `${API.FISCAL_OPERACOES_ENTRADAS}/${chaveEletronica}/baixar`,
                {codigoManifestacao, dataHoraLocal})
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  importarXml(xml: any) {
    const response$ =
        this.client
            .put<NotaFiscal>(`${API.FISCAL_OPERACOES_ENTRADAS}/importacao`, xml)
            .pipe(
                map((json: NotaFiscal) => json ? NotaFiscal.from(json) : null));
    return lastValueFrom(response$);
  }
  /* #endregion */

  /* #region OPERAÇÃO FISCAL SAÍDA */
  emitirNotaFiscal(notaFiscalId: string, dataHoraLocal: Date) {
    const response$ = this.client.post<NotaFiscalListagem>(
        `${API.FISCAL_OPERACOES_SAIDAS}/${notaFiscalId}/emissao`,
        {dataHoraLocal});
    return lastValueFrom(response$);
  }
  /* #endregion */

  /* #region OPERAÇÃO FISCAL */
  verificarStatusSefaz() {
    const response$ = this.client.get<NotaFiscalStatus>(
        `${API.FISCAL_OPERACOES}/${API.STATUS}`);
    return lastValueFrom(response$);
  }
  cancelarNotaFiscal(notaFiscalId: string, justificativa: string) {
    const response$ =
        this.client
            .post<NotaFiscalEvento>(
                `${API.FISCAL_OPERACOES}/${notaFiscalId}/cancelamento`,
                {justificativa})
            .pipe(
                map((json: NotaFiscalEvento) =>
                        json ? NotaFiscalEvento.from(json) : null));
    return lastValueFrom(response$);
  }
  corrigirNotaFiscal(notaFiscalId: string, justificativa: string) {
    const response$ =
        this.client
            .post<NotaFiscalEvento>(
                `${API.FISCAL_OPERACOES}/${notaFiscalId}/correcao`,
                {justificativa})
            .pipe(
                map((json: NotaFiscalEvento) =>
                        json ? NotaFiscalEvento.from(json) : null));
    return lastValueFrom(response$);
  }
  inutilizarNotaFiscal(notaFiscalId: string, justificativa: string) {
    const response$ =
        this.client
            .post<NotaFiscalEvento>(
                `${API.FISCAL_OPERACOES}/${notaFiscalId}/inutilizacao`,
                {justificativa})
            .pipe(
                map((json: NotaFiscalEvento) =>
                        json ? NotaFiscalEvento.from(json) : null));
    return lastValueFrom(response$);
  }
  /* #endregion */
  consultarTempoRestante() {
    const response$ =
        this.client.get<any>(API.FISCAL_MANIFESTACOES_CONSULTAS_TEMPO_RESTANTE);
    return lastValueFrom(response$);
  }
  salvarCertificadoDigital(certificado: CertificadoDigital) {
    const response$ = this.client.put<any>(
        `${API.FISCAL_EMPRESAS}/${certificado.empresaId}/${API.CERTIFICADOS}`,
        certificado);
    return lastValueFrom(response$);
  }
  buscarRelatorioVariacaoCusto(id: string, empresas: string[]) {
    const response$ = this.client.get<NotaFiscalVariacaoCustoRelatorio>(
        this.geradorQueryString.gerar(
            `${API.RELATORIOS_FISCAL_ENTRADAS}/${id}/variacao-custo`,
            {empresas: empresas}),
    );
    return lastValueFrom(response$);
  }
  buscarItensNotaFiscal(id: string) {
    const response$ = this.client.get<NotaFiscalItem[]>(
        `${API.NOTA_FISCAL_ENTRADA}/${id}/itens`);
    return lastValueFrom(response$);
  }
  buscarTotaisEntrada(filtro: NotaFiscalFiltro) {
    if (filtro?.numeroNotaFiscal) {
      filtro.numeroNotaFiscal = filtro?.numeroNotaFiscal?.toString() ?? '';
    }
    const response$ = this.client.get<number>(this.geradorQueryString.gerar(
        API.RELATORIOS_FISCAL_ENTRADAS_TOTAIS, filtro));
    return lastValueFrom(response$);
  }
}