<p-dialog
  header="Nova conta bancária"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharModal()"
  (onShow)="aoAbrirModal()"
  [(visible)]="mostrarModal"
>
  <app-conta-bancaria-shared-form #formComponent>
  </app-conta-bancaria-shared-form>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
