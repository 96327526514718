import {EmpresaUsuario} from '@app/abstraction/domain/entities/shared/auth/empresa-usuario.entity';
import {UsuarioListagem} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-listagem.interface';


export class Usuario {
  ativo: boolean;
  email: string;
  id: string;
  perfilId: string;
  nome: string;
  senha: string;
  urlRegistro: string;
  modulos: string[];
  telas: string[];
  dashboards: string[];
  empresas: EmpresaUsuario[];

  static from(json: any = {}) {
    const usuario = new Usuario();
    usuario.ativo = json.ativo;
    usuario.email = json.email?.trim();
    usuario.empresas = json.empresas;
    usuario.id = json.id;
    usuario.modulos = json.modulos;
    usuario.perfilId = json.perfilId;
    usuario.nome = json.nome?.trim();
    usuario.senha = json.senha?.trim();
    usuario.telas = json.telas;
    usuario.urlRegistro = json.urlRegistro?.trim();
    usuario.dashboards = json.dashboards;
    return usuario;
  }
  toListagem() {
    return <UsuarioListagem> {
      id: this.id, nome: this.nome, email: this.email, ativo: this.ativo,
    }
  }
}
