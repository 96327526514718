<div class="cartao-contato" [ngClass]="{ principal: contato.principal }">
  <div class="icone"><i class="pi pi-user"></i></div>
  <div class="info">
    <div class="flex justify-content-end">
      <p-tag
        [value]="contato.ativo ? 'Ativo' : 'Inativo'"
        [severity]="contato.ativo ? 'success' : 'danger'"
      ></p-tag>
    </div>
    <div class="flex flex-column">
      <h5>{{ contato.nome }}</h5>
      <span>Email: {{ contato.email }}</span>
      <span>Telefone 1: {{ contato.fone1 | fone }}</span>
      <span>Telefone 2: {{ contato.fone2 | fone }}</span>
    </div>
  </div>
  <div class="flex justify-content-end barra-opcoes cursor-pointer w-full">
    <i
      class="pi pi-pencil mr-2"
      tooltipPosition="top"
      pTooltip="Editar contato"
      (click)="editarClicado()"
    ></i>
    <i
      class="pi pi-star"
      *ngIf="contato.principal"
      tooltipPosition="top"
      pTooltip="Contato principal"
    ></i>
  </div>
</div>
