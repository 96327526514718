import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {NaturezaReceitaFacade} from '@app/abstraction/domain/facades/cadastros/natureza-receita/natureza-receita.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-natureza-receita-busca-rapida',
  templateUrl: './natureza-receita-busca-rapida.component.html',
})
export class NaturezaReceitaBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('cstTemp', {static: true}) cstTemp: TemplateRef<any>;

  @Input() mostrarModal: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  filtro: number;
  itemSelecionado: any;
  get dados$() {
    return this.naturezaReceitaFacade.naturezasReceita$;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private naturezaReceitaFacade: NaturezaReceitaFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarNaturezasReceitaComFiltro() {
    this.naturezaReceitaFacade.buscarNaturezasReceita({
      pagina: this.pagina,
      limite: this.limite,
      codigo: this.filtro,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'naturezaReceitaCodigo',
        name: 'Código',
        type: 'string',
      },
      {
        field: 'descricao',
        name: 'Descrição',
        type: 'string',
      },
      {
        field: 'cstPisCofinsSaida',
        name: 'CST PIS/COFINS Saída',
        type: 'string',

        cellTemplate: this.cstTemp,
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro = null;
    this.naturezaReceitaFacade.limparListaNaturezasReceita();
    this.buscarNaturezasReceitaComFiltro();
  }
  buscarNaturezasReceitaProximaPagina() {
    this.pagina++;
    this.buscarNaturezasReceitaComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.naturezaReceitaFacade.limparListaNaturezasReceita();
    this.buscarNaturezasReceitaComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
    this.naturezaReceitaFacade.limparListaNaturezasReceita();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.naturezaReceitaFacade.limparListaNaturezasReceita();
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
}
