import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {IContaBancariaBoletoCarteiraForm} from '@app/abstraction/domain/forms/financeiro/conta-bancaria.form';
import {CarteiraListagem} from '@app/abstraction/domain/interfaces/financeiro/carteira/carteira-listagem.interface';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-conta-bancaria-boleto-carteira-modal',
  templateUrl: './conta-bancaria-boleto-carteira-modal.component.html',
})
export class ContaBancariaBoletoCarteiraModalComponent extends FormBaseComponent
    implements OnInit {
  @Input() carteiras: CarteiraListagem[];
  @Input() form: FormGroup<IContaBancariaBoletoCarteiraForm>;
  @Input() mostrarModal: boolean;
  @Input() titulo: string;
  @Output() aoCancelar = new EventEmitter();
  @Output()
  aoSalvar = new EventEmitter<FormGroup<IContaBancariaBoletoCarteiraForm>>();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get valorPlaceholder() {
    return this.mascaraService.placeholder(this.valorDecimais);
  }

  constructor(
      private deviceDetectorFacade: DeviceDetectorFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  fecharModal() {
    this.aoCancelar.emit();
  }

  ngOnInit() {
    this.montarDropdownCnab();
  }

  salvar() {
    this.aoSalvar.emit(this.form);
  }
}
