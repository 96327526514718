<p-dialog
  [header]="
    'Lista de variações do produto - ' + produtoVinculoSelecionado?.descricao
  "
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="true"
  (onShow)="aoAbrir()"
  (onHide)="fecharModal()"
  contentStyleClass="overlay-y-hidden"
>
  <div class="grid" *ngIf="mostrarBotaoCadastroVariacao">
    <div class="col">
      <p-button
        type="button"
        styleClass="btn-five"
        label="Nova variação"
        tooltipPosition="bottom"
        pTooltip="Nova variação"
        icon="pi pi-plus"
        (click)="abrirProdutoVariacaoCadastroRapidoModal()"
      ></p-button>
    </div>
  </div>
  <app-table
    [selecteds]="[itemSelecionado]"
    (aoSelecionar)="setarItemSelecionado($event)"
    [columns]="colunas"
    [rows]="dados"
    [selectable]="true"
    selectionMode="single"
    >
  </app-table>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>
<ng-template #situacaoTemp let-value="value">
  <p-tag
    *ngIf="value"
    styleClass="mr-2"
    severity="success"
    value="Ativo"
  ></p-tag>
  <p-tag
    *ngIf="!value"
    styleClass="mr-2"
    severity="danger"
    value="Inativo"
  ></p-tag>
</ng-template>

<app-produto-variacao-cadastro-rapido-modal
  *ngIf="mostrarBotaoCadastroVariacao"
  [mostrarModal]="mostrarProdutoVariacaoCadastroRapidoModal"
  (aoCancelar)="fecharProdutoVariacaoCadastroRapidoModal()"
  (aoSalvar)="selecionarProdutoVariacaoSalvo($event)"
  [variacao]="produtoRecebidoVariacao"
>
</app-produto-variacao-cadastro-rapido-modal>

<ng-template #produtoTipoTemp let-value="value">
  <span>{{ value | enumLabel: produtoTipoLabels }}</span>
</ng-template>
<ng-template #tamanhoTemp let-value="value">
  <span>{{ value | tamanho }}</span>
</ng-template>
<ng-template #referenciaTemp let-value="value">
  <span>{{ value | referencia }}</span>
</ng-template>
<ng-template #corTemp let-value="value">
  <div class="flex">
    <div
      class="mr-5 caixa-cor"
      [style.background]="value | cor: 'corCodigo'"
    ></div>
    <span>{{ value | cor }}</span>
  </div>
</ng-template>
