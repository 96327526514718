import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Grupo} from '@app/abstraction/domain/entities/produtos/grupo/grupo.entity';
import {GrupoFiltro} from '@app/abstraction/domain/interfaces/produtos/grupo/grupo-filtro.interface';
import {GrupoListagem} from '@app/abstraction/domain/interfaces/produtos/grupo/grupo-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GrupoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Grupo) {
    const response$ =
        this.client.post<Grupo>(API.GRUPO, dados)
            .pipe(map((json: Grupo) => json ? Grupo.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Grupo, id: string) {
    const response$ =
        this.client.put<Grupo>(`${API.GRUPO}/${id}`, dados)
            .pipe(map((json: Grupo) => json ? Grupo.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ =
        this.client.put<boolean>(`${API.GRUPO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Grupo>(`${API.GRUPO}/${id}`)
            .pipe(map((json: Grupo) => json ? Grupo.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: GrupoFiltro) {
    const response$ = this.client.get<GrupoListagem[]>(
        this.geradorQueryString.gerar(`${API.GRUPO}`, filtro));
    return lastValueFrom(response$);
  }
}
