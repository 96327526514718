import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-list-tile',
  templateUrl: './list-tile.component.html',
  styleUrls: ['./list-tile.component.scss']
})
export class ListTileComponent {
  @Output() aoClicar = new EventEmitter();
  constructor() {}

  clicar() {
    this.aoClicar.emit();
  }
}
