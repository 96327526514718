export class FornecedorProduto {
  codigoReferencia: string;
  id: string;
  fornecedorId: string;
  produtoVariacaoId: string;
  variacaoId: string;
  produtoDescricao: string;
  produtoCodigoBarras: string;
  fornecedorNome: string;
  fornecedorDocumento: string;

  static from(json: any = {}) {
    const fornecedorProduto = new FornecedorProduto();
    fornecedorProduto.codigoReferencia = json.codigoReferencia;
    fornecedorProduto.id = json.id;
    fornecedorProduto.fornecedorId = json.fornecedorId;
    fornecedorProduto.produtoVariacaoId = json.produtoVariacaoId;
    fornecedorProduto.variacaoId = json.produtoVariacaoId;
    fornecedorProduto.produtoDescricao = json.produtoDescricao?.trim();
    fornecedorProduto.produtoCodigoBarras = json.produtoCodigoBarras?.trim();
    fornecedorProduto.fornecedorNome = json.fornecedorNome?.trim();
    fornecedorProduto.fornecedorDocumento = json.fornecedorDocumento?.trim();
    return fornecedorProduto;
  }
}
