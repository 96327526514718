<p-dialog
  header="Nova marca"
  [style]="{ width: isDesktop ? '30%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="marcaForm.form"
  (onHide)="fecharModal()"
  (onShow)="this.aoAbrirModal()"
>
  <div class="formgrid grid">
    <!-- #region DESCRICAO -->
    <div class="field col-12">
      <app-custom-label texto="Descrição" [obrigatorio]="true">
      </app-custom-label>
      <app-text-input
        [control]="marcaForm.form.controls.descricao"
        [maxlength]="60"
      ></app-text-input>
    </div>
    <!-- #endregion -->
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
