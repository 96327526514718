import {FormControl, FormGroup} from '@angular/forms';
import {Grupo} from '@app/abstraction/domain/entities/produtos/grupo/grupo.entity';
import {Secao} from '@app/abstraction/domain/entities/produtos/secao/secao.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface IGrupoForm {
  id: FormControl<string>;
  descricao: FormControl<string>;
  secaoId: FormControl<string>;
  ativo: FormControl<boolean>;
}

export class GrupoForm extends BaseForm {
  form: FormGroup<IGrupoForm>;
  operacaoForm: OperacaoForm;
  mostrarSecaoCadastroRapidoModal: boolean;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Novo grupo' : 'Editar grupo';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(private errorMessagesFacade: ErrorMessageFacade) {
    super();
  }
  private preencherForm(value: Grupo) {
    this.form.patchValue(value);
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  criarForm() {
    this.form = new FormGroup({
      id: new FormControl(null),
      descricao: new FormControl(
          null,
          [
            CustomValidators.required('Descrição é obrigatório'),
            CustomValidators.maxLength(60, 'Tamanho máximo: 60 caracteres'),
          ]),
      secaoId: new FormControl(
          null, CustomValidators.required('Seção é obrigatória')),
      ativo: new FormControl(true),
    });
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(Grupo.from(value));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
  abrirSecaoCadastroRapidoModal() {
    this.mostrarSecaoCadastroRapidoModal = true;
  }
  fecharSecaoCadastroRapidoModal() {
    this.mostrarSecaoCadastroRapidoModal = false;
  }
  setarSecaoCadastrada(secao: Secao) {
    this.form.controls.secaoId.setValue(secao.id);
  }
}
