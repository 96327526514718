export enum IntervaloLancamentoTipo {
  Meses = 1,
  Dias,
  FormaParcelamento,
}

export const IntervaloLancamentoTipoLabels =
    new Map<IntervaloLancamentoTipo, string>([
      [IntervaloLancamentoTipo.Meses, 'Meses'],
      [IntervaloLancamentoTipo.Dias, 'Dias'],
      [IntervaloLancamentoTipo.FormaParcelamento, 'Forma de parcelamento'],
    ]);
