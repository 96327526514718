import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

import {BaseComponent} from './base.component';

export class FormBaseComponent extends BaseComponent {
  constructor(
      public confirmacaoService: ConfirmationService,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
}
