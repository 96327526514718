import {FormControl, FormGroup} from '@angular/forms';
import {ProdutoVariacaoPreco} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-preco.entity';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';


export interface IProdutoVariacaoPrecoForm {
  id: FormControl<string>;
  variacaoId: FormControl<string>;
  empresaId: FormControl<string>;
  valorCompra: FormControl<number>;
  valorCusto: FormControl<number>;
  formulaCusto: FormControl<number>;
  valorPmz: FormControl<number>;
  formulaPmz: FormControl<number>;
  valorVenda1: FormControl<number>;
  formulaVenda: FormControl<number>;
  valorVenda2: FormControl<number>;
  valorVenda3: FormControl<number>;
  valorVenda4: FormControl<number>;
  valorCompraAnterior: FormControl<number>;
  valorCustoAnterior: FormControl<number>;
  valorVenda1Anterior: FormControl<number>;
  valorVenda2Anterior: FormControl<number>;
  valorVenda3Anterior: FormControl<number>;
  valorVenda4Anterior: FormControl<number>;
  margem1Fixa: FormControl<number>;
  margem2Fixa: FormControl<number>;
  margem3Fixa: FormControl<number>;
  margem4Fixa: FormControl<number>;
  margem1Praticada: FormControl<number>;
  margem2Praticada: FormControl<number>;
  margem3Praticada: FormControl<number>;
  margem4Praticada: FormControl<number>;
}

export class ProdutoVariacaoPrecoForm {
  static criarForm(variacaoPreco: ProdutoVariacaoPreco, empresaId?: string) {
    return new FormGroup<IProdutoVariacaoPrecoForm>({
      id: new FormControl(variacaoPreco?.id),
      variacaoId: new FormControl(variacaoPreco?.variacaoId),
      empresaId: new FormControl(
          variacaoPreco?.empresaId ?? empresaId,
          CustomValidators.required('Empresa é obrigatória'),
          ),
      valorCompra: new FormControl(variacaoPreco?.valorCompra),
      valorCusto: new FormControl(variacaoPreco?.valorCusto),
      formulaCusto: new FormControl(variacaoPreco?.formulaCusto),
      valorPmz: new FormControl(variacaoPreco?.valorPmz),
      formulaPmz: new FormControl(variacaoPreco?.formulaPmz),
      valorVenda1: new FormControl(
          variacaoPreco?.valorVenda1,
          CustomValidators.required('Valor de venda 1 é obrigatório'),
          ),
      formulaVenda: new FormControl(variacaoPreco?.formulaVenda),
      valorVenda2: new FormControl(variacaoPreco?.valorVenda2),
      valorVenda3: new FormControl(variacaoPreco?.valorVenda3),
      valorVenda4: new FormControl(variacaoPreco?.valorVenda4),
      valorCompraAnterior: new FormControl(variacaoPreco?.valorCompraAnterior),
      valorCustoAnterior: new FormControl(variacaoPreco?.valorCustoAnterior),
      valorVenda1Anterior: new FormControl(variacaoPreco?.valorVenda1Anterior),
      valorVenda2Anterior: new FormControl(variacaoPreco?.valorVenda2Anterior),
      valorVenda3Anterior: new FormControl(variacaoPreco?.valorVenda3Anterior),
      valorVenda4Anterior: new FormControl(variacaoPreco?.valorVenda4Anterior),
      margem1Fixa: new FormControl(variacaoPreco?.margem1Fixa),
      margem2Fixa: new FormControl(variacaoPreco?.margem2Fixa),
      margem3Fixa: new FormControl(variacaoPreco?.margem3Fixa),
      margem4Fixa: new FormControl(variacaoPreco?.margem4Fixa),
      margem1Praticada: new FormControl(variacaoPreco?.margem1Praticada),
      margem2Praticada: new FormControl(variacaoPreco?.margem2Praticada),
      margem3Praticada: new FormControl(variacaoPreco?.margem3Praticada),
      margem4Praticada: new FormControl(variacaoPreco?.margem4Praticada),
    });
  }
}
