<div class="list-tile" (click)="clicar()">
  <div class="list-tile-leading">
    <ng-content select="[leading]"></ng-content>
  </div>
  <div class="list-tile-content">
    <div class="list-tile-title">
      <ng-content select="[title]"></ng-content>
    </div>
    <div class="list-tile-subtitle">
      <ng-content select="[subtitle]"></ng-content>
    </div>
  </div>
  <div class="list-tile-trailing">
    <ng-content select="[tag]"></ng-content>
    <ng-content select="[trailing]"></ng-content>
  </div>
</div>
