import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {NotaFiscalFacade} from '@app/abstraction/domain/facades/fiscal/nota-fiscal/nota-fiscal.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {NotaFiscalFiltro} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {NFeFinalidade, NFeFinalidadeLabels} from '@app/shared/enums/nfe-finalidade.enum';
import {NFeEntradaStatusLabels, NFeStatus} from '@app/shared/enums/nfe-status.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-nota-fiscal-entrada-busca-rapida',
  templateUrl: './nota-fiscal-entrada-busca-rapida.component.html',
})
export class NotaFiscalEntradaBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('documentoTemp', {static: true}) documentoTemp: TemplateRef<any>;

  @ViewChild('valorDecimalTemp', {static: true})
  valorDecimalTemp: TemplateRef<any>;
  @ViewChild('dataHoraTemp', {static: true}) dataHoraTemp: TemplateRef<any>;
  @ViewChild('nfeFinalidadeTemp', {static: true})
  nfeFinalidadeTemp: TemplateRef<any>;
  @ViewChild('statusNotaFiscalTemp', {static: true})
  statusNotaFiscalTemp: TemplateRef<any>;

  @Input() mostrarModal: boolean;
  @Input() finalidade: NFeFinalidade;
  @Input() selecionarDocumentosIguais: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();
  @Input() filtro = <NotaFiscalFiltro>{};

  colunas: TableColumn[];

  itensSelecionados: any;

  get modoSelecao() {
    return this.finalidade == NFeFinalidade.Devolucao ? 'multiple' : 'single';
  }
  get selectable() {
    return this.finalidade != NFeFinalidade.Devolucao;
  }
  get checkboxable() {
    return this.finalidade == NFeFinalidade.Devolucao;
  }
  get dados() {
    return this.notaFiscalFacade.notasFiscaisEntrada;
  }
  get nfeEntradaSituacaoLabels() {
    return NFeEntradaStatusLabels;
  }
  get nfeFinalidadeLabels() {
    return NFeFinalidadeLabels;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  constructor(
      private notaFiscalFacade: NotaFiscalFacade,
      private parametroFacade: ParametroFacade,
      private pessoaFacade: PessoaFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarNotasFiscaisEntradaComFiltro() {
    this.notaFiscalFacade.buscarNotasFiscaisEntrada({
      pagina: this.pagina,
      limite: this.limite,
      finalidade: NFeFinalidade.Normal,
      emitente: this.filtro.emitente,
      situacao: this.filtro.situacao,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'numeroNotaFiscal',
        name: 'Número',
        cellClass: 'text-right',
        headerClass: 'text-right',
      },
      {
        field: 'serie',
        name: 'Série',
        cellClass: 'text-right',
        headerClass: 'text-right',
      },
      {
        field: 'emitenteRazaoSocial',
        name: 'Nome/Razão social do emitente',
      },
      {
        field: 'emitenteDocumento',
        name: 'Documento do emitente',
        cellTemplate: this.documentoTemp,
        hidden: true,
      },
      {
        field: 'chaveEletronica',
        name: 'Chave eletrônica',
      },
      {
        field: 'finalidade',
        name: 'Finalidade',
        cellTemplate: this.nfeFinalidadeTemp,
      },
      {
        field: 'valorNotaFiscal',
        name: 'Valor da Nota',
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.valorDecimalTemp,
      },
      {
        field: 'situacao',
        name: 'Situacao',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.statusNotaFiscalTemp,
      },
      {
        field: 'dataEmissao',
        name: 'Data/Hora Emissão',
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.dataHoraTemp,
      },
    ];
  }
  async abrirModal() {
    this.iniciarPaginacao();
    this.notaFiscalFacade.limparListaNotasFiscaisEntrada();
    await this.pessoaFacade.buscarPessoas({
      propriedadeOrdem: 'nomeRazaoSocial',
      direcao: 'asc',
    });
    this.buscarNotasFiscaisEntradaComFiltro();
  }
  buscarNotasFiscaisEntradaProximaPagina() {
    this.pagina++;
    this.buscarNotasFiscaisEntradaComFiltro();
  }
  buscarStyleTagStatus(situacao: NFeStatus) {
    switch (situacao) {
      case NFeStatus.Lancada:
        return 'success';
      case NFeStatus.Cancelada:
        return 'danger';
      default:
        return 'warning';
    }
  }
  filtrarComTimeout = (emitente: string) => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar(emitente);
    }, this.timeoutFiltro);
  };
  filtrar(emitente: string) {
    this.iniciarPaginacao();
    this.notaFiscalFacade.limparListaNotasFiscaisEntrada();
    this.filtro.emitente = emitente ?? this.filtro.emitente;

    this.buscarNotasFiscaisEntradaComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itensSelecionados = null;
    this.notaFiscalFacade.limparListaNotasFiscaisEntrada();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    if (this.itensSelecionados) {
      if (this.selecionarDocumentosIguais &&
          (this.itensSelecionados as Array<any>)
              .some(
                  (item: any) => item.emitenteDocumento !=
                      this.itensSelecionados[0].emitenteDocumento)) {
        this.errorMessagesFacade.mostrarMensagens(
            'As notas selecionadas devem ser de mesmo emitente.');
      } else {
        const notas = this.itensSelecionados.length ? this.itensSelecionados :
                                                      [this.itensSelecionados];
        this.aoSelecionar.emit(notas);
        this.itensSelecionados = null;
        this.limparPaginacao();
      }
    }
  }
  setarItemSelecionado(evento: any) {
    this.itensSelecionados = evento;
  }
}
