import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() texto: string;
  @Input() valor: number;
  @Input() porcentagem: number;
  @Input() porcentagemArredondada: number;
  @Input() altura: number;
  @Input() mostrarValorLadoTexto: boolean;
  @Input() mostrarPorcentagemLadoTexto: boolean;
}
