import {Pipe, PipeTransform} from '@angular/core';
import {ProdutoVariacaoReferenciaFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-referencia/produto-variacao-referencia.facade';
import {ProdutoVariacaoReferenciaListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-referencia/produto-variacao-referencia-listagem.interface';

@Pipe({
  name: 'referencia',
})
export class ReferenciaPipe implements PipeTransform {
  constructor(private produtoVariacaoReferenciaFacade:
                  ProdutoVariacaoReferenciaFacade) {}
  transform(referenciaId: string, campo: string = 'descricao'): any {
    if (referenciaId) {
      const produtoVariacaoReferencia =
          this.produtoVariacaoReferenciaFacade.produtoVariacaoReferencias?.find(
              (produtoVariacaoReferencia: ProdutoVariacaoReferenciaListagem) =>
                  produtoVariacaoReferencia.id == referenciaId) as any;
      return produtoVariacaoReferencia?.[campo];
    }
    return '';
  }
}
