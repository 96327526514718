<p-dialog
  *ngIf="form"
  [header]="titulo"
  [style]="{ width: isDesktop ? '60%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="form"
  (onHide)="fecharModal()"
>
  <div class="formgrid grid">
    <!-- #region TIPO DE ENDEREÇO -->
    <div class="field col-12 md:col-3">
      <app-custom-label texto="Tipo de Endereço"></app-custom-label>
      <app-dropdown-input
        [options]="enderecoTipos"
        [control]="form.controls?.enderecoTipoId"
        optionLabel="descricao"
        optionValue="enderecoTipoId">
      </app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region CEP -->
    <div class="field col-12 md:col-3">
      <app-custom-label texto="CEP"></app-custom-label>
      <app-text-input
        iconButton="pi pi-search"
        [control]="form.controls?.cep"
        [maxlength]="9"
        [maxlengthCount]="8"
        [lengthOnlyNumber]="true"
        [showButton]="true"
        [mask]="mascaraCep"
        (inputKeydown)="consultarCepComEnter($event)"
        (buttonOnClick)="consultarCepModal()">
      </app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region LOGRADOURO -->
    <div class="field col-12 md:col-4">
      <app-custom-label texto="Logradouro"></app-custom-label>
      <app-text-input
        [control]="form.controls?.logradouro"
        [maxlength]="60">
      </app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region NÚMERO -->
    <div class="field col-12 md:col-2">
      <app-custom-label texto="Número"></app-custom-label>
      <app-text-input
        [maxlength]="10"
        #numeroModal
        [control]="form.controls?.numero">
      </app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region BAIRRO -->
    <div class="field col-12 md:col-4">
      <app-custom-label texto="Bairro"></app-custom-label>
      <app-text-input
        [control]="form.controls?.bairro"
        [maxlength]="60">
      </app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region CIDADE -->
    <div class="field col-12 md:col-4">
      <app-custom-label texto="Cidade"></app-custom-label>
      <app-text-input
        [control]="form.controls?.cidade"
        [maxlength]="60">
      </app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region ESTADO -->
    <div class="field col-12 md:col-4">
      <app-custom-label texto="Estado"></app-custom-label>
      <app-dropdown-input
        [options]="estadosIbge"
        [control]="form.controls?.ufIbge"
        optionLabel="texto"
        optionValue="valor">
      </app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region CIDADE -->
    <div class="field col-12 md:col-12">
      <app-custom-label texto="Complemento"></app-custom-label>
      <app-text-input
        [control]="form.controls?.complemento"
        [maxlength]="60">
      </app-text-input>
    </div>
    <!-- #endregion -->
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
