import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Transportadora} from '@app/abstraction/domain/entities/cadastros/transportadora/transportadora.entity';
import {PlanoContasNivel3} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-3.entity';
import {Contato} from '@app/abstraction/domain/entities/shared/contato.entity';
import {Endereco} from '@app/abstraction/domain/entities/shared/endereco.entity';
import {FuncionarioFacade} from '@app/abstraction/domain/facades/cadastros/funcionario/funcionario.facade';
import {TransportadoraFacade} from '@app/abstraction/domain/facades/cadastros/transportadora/transportadora.facade';
import {UsuarioFacade} from '@app/abstraction/domain/facades/cadastros/usuario/usuario.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {ApiServicesFacade} from '@app/abstraction/domain/facades/shared/api-services/api-services.facade';
import {CepFacade} from '@app/abstraction/domain/facades/shared/cep/cep.facade';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {TransportadoraForm} from '@app/abstraction/domain/forms/cadastros/transportadora.form';
import {IContatoForm} from '@app/abstraction/domain/forms/shared/contato.form';
import {IEnderecoForm} from '@app/abstraction/domain/forms/shared/endereco.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {SituacaoLancamentoLabels} from '@app/shared/enums/situacao-lancamento.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';


@Component({
  selector: 'app-transportadora-shared-form',
  templateUrl: './transportadora-shared-form.component.html',
  styleUrls: ['./transportadora-shared-form.component.scss']
})
export class TransportadoraSharedFormComponent extends FormBaseComponent
    implements OnInit {
  @ViewChild('situacaoLancamentoParcelaTemp', {static: true})
  situacaoLancamentoParcelaTemp: TemplateRef<any>;
  @ViewChild('lancamentosParcelaAcoesTemp', {static: true})
  lancamentosParcelaAcoesTemp: TemplateRef<any>;
  @ViewChild('dataTemp', {static: true}) dataTemp: TemplateRef<any>;
  @ViewChild('despesaTemp', {static: true}) despesaTemp: TemplateRef<any>;
  @ViewChild('valorTemp', {static: true}) valorTemp: TemplateRef<any>;
  transportadoraForm: TransportadoraForm;
  colunasTabelaLancamentoParcela: TableColumn[];
  get planosContas3() {
    return this.planoContaFacade.planosContasNivel3;
  }
  get pessoaJuridica() {
    return this.transportadoraForm.pessoaJuridica;
  }
  get operacaoFormEnderecoAdicionar() {
    return this.transportadoraForm.operacaoFormEnderecoAdicionar;
  }
  get contatos() {
    return this.transportadoraForm.contatos;
  }
  get enderecos() {
    return this.transportadoraForm.enderecos;
  }
  get documentoObrigatorio() {
    return this.parametroFacade.documentoObrigatorio;
  }
  get maxlength() {
    return this.transportadoraForm.maxlength;
  }
  get maxlengthCount() {
    return this.transportadoraForm.maxlengthCount;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get situacaoLancamentoLabels() {
    return SituacaoLancamentoLabels;
  }
  get funcionarios() {
    return this.funcionarioFacade.funcionarios;
  }
  get tituloTabPanelRegistrosFinanceiros() {
    return 'Registros Financeiros';
  }
  constructor(
      private apiServicesCoreFacade: ApiServicesFacade,
      private enderecoTipoFacade: EnderecoTipoFacade,
      private pessoaFacade: PessoaFacade,
      private transportadoraFacade: TransportadoraFacade,
      private usuarioFacade: UsuarioFacade,
      private planoContaFacade: PlanoContasFacade,
      private funcionarioFacade: FuncionarioFacade,
      private router: Router,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      private cepFacade: CepFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private montarColunasLancamentoParcela() {
    this.colunasTabelaLancamentoParcela = [
      {
        field: 'sequencia',
        name: 'Código',
      },
      {
        field: 'despesa',
        name: 'Tipo',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.despesaTemp,
      },
      {
        field: 'dataVencimento',
        name: 'Vencimento',
        headerClass: 'text-center',
        cellClass: 'text-center',
        cellTemplate: this.dataTemp,
      },
      {
        field: 'valorTotal',
        name: 'Previsto',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.valorTemp,
      },
      {
        field: 'valorTotalPago',
        name: 'Realizado',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.valorTemp,
      },
      {
        field: 'situacao',
        name: 'Situação',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.situacaoLancamentoParcelaTemp,
      },
      {
        field: 'planoConta',
        name: 'Plano de Conta',
      },
      {
        field: 'numeroDocumento',
        name: 'Documento',
      },
      {
        field: 'contaBancaria',
        name: 'Conta Bancária',
      },
      {
        field: '',
        name: '',
        cellClass: 'text-center',
        width: 80,
        cellTemplate: this.lancamentosParcelaAcoesTemp,
      },
    ];
  }
  async consultarDocumentoValido() {
    this.transportadoraForm.consultarDocumentoValido();
  }
  async setarPlanoContasCadastrado(planoConta3: PlanoContasNivel3) {
    if (planoConta3.id && planoConta3.planoConta2Id) {
      this.transportadoraForm.setarPlanoContasCadastrado(planoConta3.id);
    }
  }
  abrirContatoModal(contato?: Contato, index?: number) {
    this.transportadoraForm.abrirContatoModal(contato, index);
  }
  abrirEnderecoModal(endereco?: Endereco, index?: number) {
    this.transportadoraForm.abrirEnderecoModal(endereco, index);
  }
  abrirPlanoContasCadastroRapidoModal() {
    this.transportadoraForm.abrirPlanoContasCadastroRapidoModal();
  }
  criarForm() {
    this.transportadoraForm = new TransportadoraForm(
        this.apiServicesCoreFacade, this.enderecoTipoFacade,
        this.errorMessagesFacade, this.pessoaFacade, this.parametroFacade,
        this.transportadoraFacade, this.usuarioFacade, this.toastrService,
        this.cepFacade);
    this.transportadoraForm.criarForm();
    this.transportadoraForm.monitorarRegimeTributario();
    this.transportadoraForm.monitorarPessoaTipo();
    this.transportadoraForm.monitorarFormulario();
    this.transportadoraForm.aplicarValidacoesPorParametros(
        this.documentoObrigatorio);
  }
  fecharPlanoContasCadastroRapidoModal() {
    this.transportadoraForm.fecharPlanoContasCadastroRapidoModal();
  }
  buscarMascaraDoDocumento() {
    return this.mascaraService.documento(
        this.transportadoraForm.pessoaTipoSelecionada);
  }
  consultarDocumentoValidoComEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.consultarDocumentoValido();
    }
  }
  fecharContatoModal() {
    this.transportadoraForm.fecharContatoModal();
  }
  fecharEnderecoModal() {
    this.transportadoraForm.fecharEnderecoModal();
  }
  ngOnInit() {
    this.montarDropdownPessoaTipo();
    this.montarDropdownRegimeTributario();
    this.montarDropdownAtividadeTipo();
    this.montarDropdownIeIndicador();
    this.montarDropdownEstadosIbge();
    this.montarColunasLancamentoParcela();
    this.criarForm();
  }
  preencherNomeFantasia(event: any) {
    this.transportadoraForm.preencherNomeFantasia(event.target.value as string);
  }
  preencherObjeto() {
    const transportadora =
        Transportadora.from(this.transportadoraForm.form.value);
    transportadora.toAPI();
    transportadora.id ??= this.transportadoraForm.pessoaId;
    return transportadora;
  }
  recuperaDadosTab(event: any) {
    if (this.transportadoraForm.operacaoFormEditar) {
      if (event.originalEvent.currentTarget.textContent ===
          this.tituloTabPanelRegistrosFinanceiros) {
        this.transportadoraForm.buscarRegistrosFinanceiros();
      }
    }
  }
  salvarContato(form: FormGroup<IContatoForm>) {
    this.transportadoraForm.salvarContato(form);
  }
  salvarEndereco(form: FormGroup<IEnderecoForm>) {
    this.transportadoraForm.salvarEndereco(form);
  }
  verLancamentoParcela(row: any) {
    if (row.despesa)
      this.router.navigate(['contas-pagar/editar', row.lancamentoParcelaId]);
    else
      this.router.navigate(['contas-receber/editar', row.lancamentoParcelaId]);
  }
}
