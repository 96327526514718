import {UnidadeComercialListagem} from '@app/abstraction/domain/interfaces/produtos/unidade-comercial/unidade-comercial-listagem.interface';
export class UnidadeComercial {
  ativo: boolean;
  descricao: string;
  sigla: string;
  id: string;

  static from(value: any) {
    const unidadeComercial = new UnidadeComercial();
    unidadeComercial.id = value.id;
    unidadeComercial.descricao = value.descricao?.trim();
    unidadeComercial.sigla = value.sigla?.trim();
    unidadeComercial.ativo = value.ativo;
    return unidadeComercial;
  }

  toListagem() {
    return <UnidadeComercialListagem> {
      ativo: this.ativo, descricao: this.descricao, id: this.id,
          sigla: this.sigla,
    }
  }
}
