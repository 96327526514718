export enum ModalidadeBaseCalculoST {
  PrecoTabeladoMaximoSugerido,
  ListaNegativa,
  ListaPositiva,
  ListaNeutra,
  MargemValorAgregado,
  Pauta,
}

export const ModalidadeBaseCalculoSTLabels = new Map<number, string>([
  [
    ModalidadeBaseCalculoST.PrecoTabeladoMaximoSugerido,
    'PREÇO TABELADO OU MÁXIMO SUGERIDO',
  ],
  [ModalidadeBaseCalculoST.ListaNegativa, 'LISTA NEGATIVA (R$)'],
  [ModalidadeBaseCalculoST.ListaPositiva, 'LISTA POSITIVA (R$)'],
  [ModalidadeBaseCalculoST.ListaNeutra, 'LISTA NEUTRA (R$)'],
  [ModalidadeBaseCalculoST.MargemValorAgregado, 'MARGEM VALOR AGREGADO (%)'],
  [ModalidadeBaseCalculoST.Pauta, 'PAUTA (R$)'],
]);
