import {GrupoListagem} from '@app/abstraction/domain/interfaces/produtos/grupo/grupo-listagem.interface';
export class Grupo {
  ativo: boolean;
  descricao: string;
  id: string;
  secaoId: string;
  descricaoSecao: string;

  static from(value: any) {
    const grupo = new Grupo();
    grupo.ativo = value.ativo;
    grupo.descricao = value.descricao?.trim();
    grupo.id = value.id;
    grupo.secaoId = value.secaoId;
    return grupo;
  }
  toListagem() {
    return <GrupoListagem>{
      ativo: this.ativo,
      descricao: this.descricao,
      id: this.id,
      secaoId: this.secaoId,
      descricaoSecao: this.descricaoSecao,
    };
  }
}
