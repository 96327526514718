import 'jspdf-autotable';

import {Injectable} from '@angular/core';
import {Empresa} from '@app/abstraction/domain/entities/cadastros/empresa/empresa.entity';
import {AjusteManual} from '@app/abstraction/domain/entities/estoque/ajuste-manual/ajuste-manual.entity';
import {TransferenciaEntreEstoques} from '@app/abstraction/domain/entities/estoque/transferencia-entre-estoques/transferencia-entre-estoques.entity';
import {Endereco} from '@app/abstraction/domain/entities/shared/endereco.entity';
import {Pedido} from '@app/abstraction/domain/entities/vendas/pedido/pedido.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {AreaEstoqueFacade} from '@app/abstraction/domain/facades/estoque/area-estoque/area-estoque.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {AreaEstoquePipe} from '@app/shared/pipes/area-estoque/area-estoque.pipe';
import {CustomCurrencyPipe} from '@app/shared/pipes/custom-currency/custom-currency.pipe';
import {jsPDF} from 'jspdf';
import {UserOptions} from 'jspdf-autotable';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class PdfService {
  constructor(
      private authFacade: AuthFacade, private empresaFacade: EmpresaFacade,
      private pessoaFacade: PessoaFacade,
      private parametroFacade: ParametroFacade,
      private areaEstoqueFacade: AreaEstoqueFacade,
      private currencyPipe: CustomCurrencyPipe) {}
  get casasDecimaisQuantidade() {
    return this.parametroFacade
        .quantidadeCasasDecimaisCamposQuantidadeRelatorio;
  }
  get casasDecimaisValor() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValorRelatorio;
  }
  get casasDecimaisPorcentagem() {
    return this.parametroFacade
        .quantidadeCasasDecimaisCamposPorcentagemRelatorio;
  }

  private formatarCep(cep: string) {
    if (!cep) return '';
    return `${cep.slice(0, 5)}-${cep.slice(5, 8)}`;
  }
  private formatarEndereco(endereco: Endereco) {
    if (!endereco) return '';
    return `${endereco.logradouro}, ${endereco.numero} - ${endereco.bairro}, ${
        endereco.cidade} - ${endereco.uf}, ${this.formatarCep(endereco.cep)}`;
  }
  private formatarTelefone(fone: string) {
    if (!fone) return '';
    if (fone.length < 11) return fone;
    if (fone.length === 11)
      return `(${fone.slice(0, 2)}) ${fone.slice(2, 7)}-${fone.slice(7, 11)}`;
    return `(${fone.slice(0, 2)}) ${fone.slice(2, 6)}-${fone.slice(6, 10)}`;
  }
  /* #region PDF PEDIDO */
  private async montarDadosClientePedido(pedido: Pedido, doc: jsPDF) {
    let cliente = await this.pessoaFacade.buscarPorId(pedido.pessoaId);
    let formasPagamento =
        pedido.pagamentos?.map(p => p.descricaoFormaPagamento).join(', ');

    doc.setFontSize(12)
        .setFont(undefined, 'bold')
        .text('Dados do Cliente', 250, 80);

    doc.rect(15, 90, 565, 62);
    doc.setFontSize(9).setFont(undefined, 'bold');
    doc.text('Cliente:', 40, 105);
    doc.text('Telefone:', 33, 115);
    doc.text('Endereço:', 29, 125);
    doc.text('Cidade:', 40, 135);
    doc.text('Vendedor:', 28, 145);
    doc.text('Bairro:', 311, 105);
    doc.text('CEP:', 319, 115);
    doc.text('E-mail:', 311, 125);
    doc.text('Formas de Pagamento:', 242, 135);

    doc.setFontSize(9).setFont(undefined, 'normal');
    doc.text(cliente.nomeRazaoSocial, 82, 105);
    doc.text(
        !cliente.endereco ?
            '' :
            `${cliente.endereco.logradouro}, ${cliente.endereco.numero}`,
        82, 115);
    doc.text(
        !cliente.endereco ?
            '' :
            `${cliente.endereco.cidade} - ${cliente.endereco.uf}`,
        82, 125);
    doc.text(pedido.vendedorRazaoSocial ?? '', 82, 135);
    doc.text(
        !cliente.contato ? '' : this.formatarTelefone(cliente.contato.fone1),
        350, 105);
    doc.text(!cliente.endereco ? '' : cliente.endereco.bairro, 350, 115);
    doc.text(
        this.formatarCep(!cliente.endereco ? '' : cliente.endereco.cep), 350,
        125);
    doc.text(cliente.emailFaturamento ?? '', 350, 125);
    doc.text(formasPagamento, 350, 135)
  }
  private async montarTabelaItensPedido(pedido: Pedido, doc: jsPDF) {
    let empresa =
        await this.empresaFacade.buscarPorId(this.authFacade.empresaLogada.id);
    const colunasItens = [
      ['Descrição', 'Qtd.', 'Valor Unitário (R$)', 'Valor Total (R$)'],
    ];
    const itens = pedido.itens.map((item) => {
      return [
        item.descricaoComplementarItem,
        this.currencyPipe.transform(
            item.quantidade, this.casasDecimaisQuantidade, ' '),
        this.currencyPipe.transform(
            item.valorUnitario, this.casasDecimaisValor, ' '),
        this.currencyPipe.transform(
            item.valorProduto, this.casasDecimaisValor, ' '),
      ];
    });
    const columnStyles = {
      0: {halign: 'left'},
      1: {halign: 'right'},
      2: {halign: 'right'},
      3: {halign: 'right'},
    };
    doc.setFontSize(12)
        .setFont(undefined, 'bold')
        .text('Itens do Pedido', 250, 235);
    // Gera tabela de itens
    (doc as any).autoTable(<UserOptions>{
      head: colunasItens,
      body: itens,
      columnStyles: columnStyles,
      theme: 'striped',
      headStyles: {fillColor: [10, 10, 10]},
      styles: {
        fontSize: 9,
        cellPadding: 5,
        valign: 'middle',
        overflow: 'linebreak',
      },
      margin: {top: 20, left: 15, right: 15, bottom: 60},
      didDrawPage: (page: any) => {
        this.montarCabecalhoPedido(empresa, page, doc, pedido);
      },
    });
  }
  private montarCabecalhoPedido(
      empresa: Empresa, page: any, doc: jsPDF, pedido: Pedido) {
    let marginAlignRight = 580;
    let contemLogo = empresa.empresaLogoBase64;
    let marginLeft = page.settings.margin.left + (contemLogo ? 50 : 0);
    if (contemLogo) {
      doc.addImage(
          empresa.empresaLogoBase64, 'png', page.settings.margin.left, 30, 50,
          50);
    }
    this.montarDadosEmpresaPedido(doc, empresa, marginLeft);
    this.montarDetalhesPedido(doc, pedido, marginAlignRight);
  }
  private montarDadosEmpresaPedido(
      doc: jsPDF, empresa: Empresa, marginLeft: number) {
    doc.setFontSize(9);
    doc.text(empresa.nomeRazaoSocial, marginLeft, 25);
    doc.text(
        `${empresa.pessoaTipo == 1 ? 'CPF' : 'CNPJ'}:${empresa.documento}`,
        marginLeft, 35);
    doc.text(this.formatarEndereco(empresa.endereco), marginLeft, 45);
  }
  private montarDetalhesPedido(
      doc: jsPDF, pedido: Pedido, marginAlignRight: number) {
    doc.setFontSize(9);
    doc.text(`Pedido Nº: ${pedido.codigo}`, marginAlignRight, 25, {
      align: 'right',
    });
    doc.text(
        `Data emissão: ${moment().format('DD/MM/yyyy')}`, marginAlignRight, 35,
        {align: 'right'});
  }
  private montarTabelaTotaisPedido(pedido: Pedido, doc: jsPDF) {
    const colunasTotais = [
      [
        'Total Acrésc. Item (R$)',
        'Total Desc. Item (R$)',
        'Total Acrésc. Rat. (R$)',
        'Total Desc. Rat. (R$)',
        'Subtotal (R$)',
        'Total (R$)',
      ],
    ];
    const columnStyles = {
      0: {halign: 'right'},
      1: {halign: 'right'},
      2: {halign: 'right'},
      3: {halign: 'right'},
      4: {halign: 'right'},
      5: {halign: 'right'},
    };
    const totais = [
      [
        this.currencyPipe.transform(
            pedido.totalAcrescimoItem, this.casasDecimaisValor, ' '),
        this.currencyPipe.transform(
            pedido.totalDescontoItem, this.casasDecimaisValor, ' '),
        this.currencyPipe.transform(
            pedido.totalAcrescimoRateado, this.casasDecimaisValor, ' '),
        this.currencyPipe.transform(
            pedido.totalDescontoRateado, this.casasDecimaisValor, ' '),
        this.currencyPipe.transform(
            pedido.subTotal, this.casasDecimaisValor, ' '),
        this.currencyPipe.transform(
            pedido.totalLiquido, this.casasDecimaisValor, ' '),
      ],
    ];
    doc.setFontSize(12)
        .setFont(undefined, 'bold')
        .text('Totais do Pedido', 250, 170);
    // Gera tabela de totais
    (doc as any).autoTable(<UserOptions>{
      head: colunasTotais,
      body: totais,
      columnStyles: columnStyles,
      theme: 'striped',
      headStyles: {fillColor: [10, 10, 10]},
      styles: {
        fontSize: 9,
        cellPadding: 5,
        valign: 'middle',
        overflow: 'linebreak',
      },
      margin: {
        top: 180,
        left: 15,
        right: 15,
        bottom: 110,
      },
    });
  }
  async gerarPdfPedido(pedido: Pedido) {
    const doc = new jsPDF('p', 'pt', 'a4');
    await this.montarDadosClientePedido(pedido, doc);
    this.montarTabelaTotaisPedido(pedido, doc);
    await this.montarTabelaItensPedido(pedido, doc);
    doc.setProperties({title: `Pedido_${pedido.codigo}`});
    window.open(doc.output('bloburl'));  // Abre o relatório em uma nova aba
  }
  /* #endregion */

  /* #region PDF AJUSTE MANUAL */

  private async montarTabelaAjusteManual(
      ajusteManual: AjusteManual, doc: jsPDF) {
    let empresa =
        await this.empresaFacade.buscarPorId(this.authFacade.empresaLogada.id);

    const colunasItens = [
      ['Código Barras', 'Produto', 'Área estoque', 'Tipo Operação', 'Qtd.'],
    ];

    const itens = ajusteManual.itens.map((item) => {
      return [
        item.codigoBarras,
        item.produto,
        item.areaEstoqueDescricao,
        item.tipoOperacao,
        this.currencyPipe.transform(
            item.quantidade, this.casasDecimaisQuantidade, ' '),
      ];
    });

    doc.setFontSize(12)
        .setFont(undefined, 'bold')
        .text('Itens do Ajuste Manual', 240, 70);
    // Gera tabela de itens
    (doc as any).autoTable(<UserOptions>{
      head: colunasItens,
      body: itens,
      theme: 'striped',
      headStyles: {fillColor: [10, 10, 10]},
      styles: {
        fontSize: 8,
        cellPadding: 5,
        valign: 'middle',
        overflow: 'linebreak',
      },
      margin: {top: 80, left: 15, right: 15, bottom: 50},
      didDrawPage: (page: any) => {
        this.montarCabecalhoAjusteManual(empresa, page, doc, ajusteManual);
      },
    });
  }
  private montarDadosEmpresaAjusteManual(
      doc: jsPDF, empresa: Empresa, marginLeft: number) {
    doc.setFontSize(9).setFont(undefined, 'bold');
    doc.text(empresa.nomeRazaoSocial, marginLeft, 25);
    doc.text(
        `${empresa.pessoaTipo == 1 ? 'CPF' : 'CNPJ'}: ${empresa.documento}`,
        marginLeft, 35);
    doc.text(this.formatarEndereco(empresa.endereco), marginLeft, 45);
  }
  private montarDetalhesAjusteManual(
      doc: jsPDF, ajusteManual: AjusteManual, marginAlignRight: number) {
    doc.setFontSize(9).setFont(undefined, 'bold');
    doc.text(`Motivo: ${ajusteManual.historico}`, marginAlignRight, 25, {
      align: 'right',
    });
    const dataFormato = 'DD/MM/yyyy [às] HH:mm:ss';
    doc.text(
        `Data/Hora: ${
            moment(ajusteManual.dataHora).local().format(dataFormato)}`,
        marginAlignRight, 35, {align: 'right'});
    doc.text(
        `Tipo de ajuste: ${ajusteManual.tipoAjusteDescricao}`, marginAlignRight,
        45, {align: 'right'});
  }
  private montarCabecalhoAjusteManual(
      empresa: Empresa, page: any, doc: jsPDF, ajusteManual: AjusteManual) {
    let marginAlignRight = 580;
    let contemLogo = empresa.empresaLogoBase64;
    let marginLeft = page.settings.margin.left + (contemLogo ? 50 : 0);

    if (contemLogo) {
      doc.addImage(
          empresa.empresaLogoBase64, 'png', page.settings.margin.left, 30, 50,
          50);
    }

    this.montarDadosEmpresaAjusteManual(doc, empresa, marginLeft);
    this.montarDetalhesAjusteManual(doc, ajusteManual, marginAlignRight);
  }
  async gerarPdfAjusteManual(ajusteManual: AjusteManual) {
    const doc = new jsPDF('p', 'pt', 'a4');
    await this.montarTabelaAjusteManual(ajusteManual, doc);
    doc.setProperties({
      title: `AjusteManual_${ajusteManual.codigoAgrupamento}`,
    });
    window.open(doc.output('bloburl'));  // Abre o relatório em uma nova aba
  }
  /* #endregion */

  /* #region PDF TRANSFERENCIA ENTRE ESTOQUES */

  private async montarTabelaTransferenciaEntreEstoques(
      transferenciaEntreEstoques: TransferenciaEntreEstoques, doc: jsPDF) {
    let empresa =
        await this.empresaFacade.buscarPorId(this.authFacade.empresaLogada.id);

    const areaEstoquePipe = new AreaEstoquePipe(this.areaEstoqueFacade);
    const colunasItens = [
      [
        'Código', 'Código de Barras', 'Produto', 'Área de Origem',
        'Área de Destino', 'Qtd.'
      ],
    ];

    const itens = transferenciaEntreEstoques.itens.map((item) => {
      return [
        item.codigo,
        item.codigoBarras,
        item.produtoDescricaoCompleta,
        areaEstoquePipe.transform(item.areaEstoqueOrigemId),
        areaEstoquePipe.transform(item.areaEstoqueDestinoId),
        this.currencyPipe.transform(
            item.quantidade, this.casasDecimaisQuantidade, ' '),
      ];
    });

    const columnStyles = {
      0: {halign: 'left'},
      7: {halign: 'right'},
    };

    doc.setFontSize(12)
        .setFont(undefined, 'bold')
        .text('Itens da Transferência Entre Estoques', 240, 70);
    // Gera tabela de itens
    (doc as any).autoTable(<UserOptions>{
      head: colunasItens,
      body: itens,
      columnStyles: columnStyles,
      theme: 'striped',
      headStyles: {fillColor: [10, 10, 10]},
      styles: {
        fontSize: 8,
        cellPadding: 5,
        overflow: 'linebreak',
      },
      margin: {top: 80, left: 15, right: 15, bottom: 50},
      didDrawPage: (page: any) => {
        this.montarCabecalhoTransferenciaEntreEstoques(
            empresa, page, doc, transferenciaEntreEstoques);
      },
    });
  }
  private montarDadosEmpresaTransferenciaEntreEstoques(
      doc: jsPDF, empresa: Empresa, marginLeft: number) {
    doc.setFontSize(9).setFont(undefined, 'bold');
    doc.text(empresa.nomeRazaoSocial, marginLeft, 25);
    doc.text(
        `${empresa.pessoaTipo == 1 ? 'CPF' : 'CNPJ'}: ${empresa.documento}`,
        marginLeft, 35);
    doc.text(this.formatarEndereco(empresa.endereco), marginLeft, 45);
  }
  private montarDetalhesTransferenciaEntreEstoques(
      doc: jsPDF, transferenciaEntreEstoques: TransferenciaEntreEstoques,
      marginAlignRight: number) {
    doc.setFontSize(9).setFont(undefined, 'bold');
    if (transferenciaEntreEstoques.observacao) {
      doc.text(
          `Observação: ${transferenciaEntreEstoques.observacao}`,
          marginAlignRight, 25, {align: 'right'});
    }
    const dataFormato = 'DD/MM/yyyy [às] HH:mm:ss';
    doc.text(
        `Data/Hora: ${
            moment(transferenciaEntreEstoques.dataHora)
                .local()
                .format(dataFormato)}`,
        marginAlignRight, 35, {align: 'right'});
  }
  private montarCabecalhoTransferenciaEntreEstoques(
      empresa: Empresa, page: any, doc: jsPDF,
      transferenciaEntreEstoques: TransferenciaEntreEstoques) {
    let marginAlignRight = 580;
    let contemLogo = empresa.empresaLogoBase64;
    let marginLeft = page.settings.margin.left + (contemLogo ? 50 : 0);

    if (contemLogo) {
      doc.addImage(
          empresa.empresaLogoBase64, 'png', page.settings.margin.left, 30, 50,
          50);
    }

    this.montarDadosEmpresaTransferenciaEntreEstoques(doc, empresa, marginLeft);
    this.montarDetalhesTransferenciaEntreEstoques(
        doc, transferenciaEntreEstoques, marginAlignRight);
  }
  async gerarPdfTransferenciaEntreEstoques(transferenciaEntreEstoques:
                                               TransferenciaEntreEstoques) {
    await this.areaEstoqueFacade.buscarAreasEstoque();

    const doc = new jsPDF('p', 'pt', 'a4');
    await this.montarTabelaTransferenciaEntreEstoques(
        transferenciaEntreEstoques, doc);
    doc.setProperties({
      title: `TransferenciaEntreEstoques_${new Date().getMilliseconds()}`,
    });
    window.open(doc.output('bloburl'));  // Abre o relatório em uma nova aba
  }
  /* #endregion */
}
