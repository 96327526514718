import {Injectable} from '@angular/core';
import {TabelaNutricionalService} from '@app/abstraction/data/services/produtos/tabela-nutricional.service';
import {TabelaNutricionalStore} from '@app/abstraction/data/stores/produtos/tabela-nutricional.store';
import {TabelaNutricional} from '@app/abstraction/domain/entities/produtos/tabela-nutricional/tabela-nutricional.entity';
import {TabelaNutricionalFiltro} from '@app/abstraction/domain/interfaces/produtos/tabela-nutricional/tabela-nutricional-filtro.interface';

@Injectable({providedIn: 'root'})
export class TabelaNutricionalFacade {
  get tabelasNutricionais() {
    return this.store.tabelasNutricionais;
  }
  get tabelasNutricionais$() {
    return this.store.tabelasNutricionais$;
  }
  constructor(
      private store: TabelaNutricionalStore,
      private service: TabelaNutricionalService) {}

  async adicionar(tabelaNutricional: TabelaNutricional) {
    const novaTabelaNutricional =
        await this.service.adicionar(tabelaNutricional);
    if (novaTabelaNutricional) {
      this.store.adicionar(novaTabelaNutricional.toListagem());
    }
    return novaTabelaNutricional;
  }
  async buscarTabelasNutricionais(filtro?: TabelaNutricionalFiltro) {
    const tabelasNutricionais = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(tabelasNutricionais);
  }
  async atualizarSituacao(id: string) {
    const tabelaNutricional = this.tabelasNutricionais.find((p) => p.id === id);
    const status =
        await this.service.atualizarSituacao(id, !tabelaNutricional.ativo);
    tabelaNutricional.ativo = status;
    return status;
  }
  alterar(tabelaNutricional: TabelaNutricional) {
    return this.service.alterar(tabelaNutricional, tabelaNutricional.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaTabelasNutricionais() {
    this.store.tabelasNutricionais = null;
  }
}
