import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {ContaBancariaFacade} from '@app/abstraction/domain/facades/financeiro/conta-bancaria/conta-bancaria.facade';
import {FormaPagamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-pagamento/forma-pagamento.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ILancamentoPagamentoMassaForm, LancamentoPagamentoMassaForm} from '@app/abstraction/domain/forms/financeiro/lancamento/lancamento-pagamento-massa.form';
import {LancamentoParcelaListagem} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-listagem.interface';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-contas-pagamento-massa-modal',
  templateUrl: './contas-pagamento-massa-modal.component.html',
})
export class ContasPagamentoMassaModalComponent extends FormBaseComponent
    implements OnInit {
  @Input() parcelas: LancamentoParcelaListagem[];
  @Input() mostrarModal: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output()
  aoSalvar = new EventEmitter<FormGroup<ILancamentoPagamentoMassaForm>>();

  pagamentoMassaForm: LancamentoPagamentoMassaForm;

  get titulo() {
    return this.pagamentoMassaForm.titulo;
  }
  get formasPagamento() {
    return this.formaPagamentoFacade.formasPagamento?.filter(
        (fp) => fp.exibeNoConvenio &&
            fp.empresas?.some((e) => e === this.parcelas?.first()?.empresaId));
  }
  get contasBancarias() {
    return this.contaBancariaFacade.contasBancarias?.filter(
        (cb) => cb.empresaId === this.parcelas?.first()?.empresaId);
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  constructor(
      private formaPagamentoFacade: FormaPagamentoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private contaBancariaFacade: ContaBancariaFacade,
      private parametroFacade: ParametroFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private criarForm() {
    this.pagamentoMassaForm = new LancamentoPagamentoMassaForm(
        this.errorMessagesFacade,
    );
    this.pagamentoMassaForm.criarForm();
    this.pagamentoMassaForm.setarTipoFormulario(this.parcelas);
  }
  async aoAbrirModal() {
    this.criarForm();
  }
  abrirContaBancariaCadastroRapidoModal() {
    this.pagamentoMassaForm.abrirContaBancariaCadastroRapidoModal();
  }
  abrirFormaPagamentoCadastroRapidoModal() {
    this.pagamentoMassaForm.abrirFormaPagamentoCadastroRapidoModal();
  }
  fecharFormaPagamentoCadastroRapidoModal() {
    this.pagamentoMassaForm.fecharFormaPagamentoCadastroRapidoModal();
  }
  fecharModal() {
    this.pagamentoMassaForm.form.reset();
    this.aoCancelar.emit();
  }
  fecharContaBancariaCadastroRapidoModal() {
    this.pagamentoMassaForm.fecharContaBancariaCadastroRapidoModal();
  }
  ngOnInit() {
    this.criarForm();
  }
  setarFormaPagamentoCadastrada(formaPagamento: FormaPagamento) {
    this.pagamentoMassaForm.setarFormaPagamentoCadastrada(formaPagamento);
  }
  salvar() {
    try {
      this.saveLoading = true;

      if (!(this.pagamentoMassaForm.isValid())) {
        this.saveLoading = false;
        return;
      }

      this.aoSalvar.emit(this.pagamentoMassaForm.form);
      this.saveLoading = false;

    } catch (error) {
      this.toastrService.error('Erro ao quitar lançamentos.');
      this.saveLoading = false;
    }
  }
  setarContaBancariaCadastrada(contaBancaria: ContaBancaria) {
    this.pagamentoMassaForm.setarContaBancariaCadastrada(contaBancaria);
  }
}
