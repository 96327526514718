import {Injectable} from '@angular/core';
import {EnderecoTipoService} from '@app/abstraction/data/services/shared/endereco-tipo.service';
import {EnderecoTipoStore} from '@app/abstraction/data/stores/shared/endereco-tipo.store';
import {EnderecoTipoListagem} from '@app/abstraction/domain/interfaces/shared/endereco-tipo-listagem.interface';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class EnderecoTipoFacade {
  constructor(
      private service: EnderecoTipoService, private store: EnderecoTipoStore) {}
  get enderecoTipos(): EnderecoTipoListagem[] {
    return this.store.enderecoTipos;
  }
  get enderecoTipos$(): Observable<EnderecoTipoListagem[]> {
    return this.store.enderecoTipos$;
  }
  async buscarEnderecoTipos() {
    const enderecoTipos = await this.service.buscarTodos();
    this.store.adicionarRange(enderecoTipos);
  }
  limparListaEnderecoTipos() {
    this.store.enderecoTipos = null;
  }
}
