import {Injectable} from '@angular/core';
import {CestService} from '@app/abstraction/data/services/fiscal/cest.service';
import {CestStore} from '@app/abstraction/data/stores/fiscal/cest.store';
import {CestFiltro} from '@app/abstraction/domain/interfaces/fiscal/cest/cest-filtro.interface';
import {CestListagem} from '@app/abstraction/domain/interfaces/fiscal/cest/cest-listagem.interface';

@Injectable({providedIn: 'root'})
export class CestFacade {
  get cests() {
    return this.store.cests;
  }
  get cests$() {
    return this.store.cests$;
  }
  constructor(private store: CestStore, private service: CestService) {}
  async buscarCests(filtro?: CestFiltro) {
    const cests = (await this.service.buscarTodos(filtro)) as CestListagem[];
    this.store.adicionarRange(cests);
  }
  limparListaCests() {
    this.store.cests = null;
  }
}
