import {Injectable} from '@angular/core';
import {UnidadeComercialCadastroService} from '@app/abstraction/data/services/produtos/unidade-comercial.service';
import {UnidadeComercialStore} from '@app/abstraction/data/stores/produtos/unidade-comercial.store';
import {UnidadeComercial} from '@app/abstraction/domain/entities/produtos/unidade-comercial/unidade-comercial.entity';
import {UnidadeComercialFiltro} from '@app/abstraction/domain/interfaces/produtos/unidade-comercial/unidade-comercial-filtro.interface';

@Injectable({providedIn: 'root'})
export class UnidadeComercialFacade {
  get unidadePadrao() {
    return this.store.unidadesComerciais?.find(
        (p) => p.descricao.toLowerCase() === 'unidade');
  }
  get unidadesComerciais() {
    return this.store.unidadesComerciais;
  }
  get unidadesComerciais$() {
    return this.store.unidadesComerciais$;
  }
  constructor(
      private store: UnidadeComercialStore,
      private service: UnidadeComercialCadastroService) {}
  async adicionar(unidadeComercial: UnidadeComercial) {
    const novaUnidadeComercial = await this.service.adicionar(unidadeComercial);
    if (novaUnidadeComercial) {
      this.store.adicionar(novaUnidadeComercial.toListagem());
    }
    return novaUnidadeComercial;
  }
  async buscarUnidadesComerciais(filtro?: UnidadeComercialFiltro) {
    const unidadesComerciais = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(unidadesComerciais);
  }
  async atualizarSituacao(id: string) {
    const unidadeComercial = this.unidadesComerciais.find((p) => p.id === id);
    const status =
        await this.service.atualizarSituacao(id, !unidadeComercial.ativo);
    unidadeComercial.ativo = status;
    return status;
  }
  alterar(unidadeComercial: UnidadeComercial) {
    return this.service.alterar(unidadeComercial, unidadeComercial.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaUnidadesComerciais() {
    this.store.unidadesComerciais = null;
  }
}
