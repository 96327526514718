export enum ModeloDFe {
  NFe = 55,
  MDFe = 58,
  NFCe = 65,
}

export const ModeloDFeLabels = new Map<number, string>([
  [ModeloDFe.NFe, 'NF-e'],
  [ModeloDFe.MDFe, 'MDF-e'],
  [ModeloDFe.NFCe, 'NFC-e'],
]);
