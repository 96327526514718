/* eslint-disable @angular-eslint/no-output-on-prefix */
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-calendar-input-ngmodel',
  templateUrl: './calendar-input-ngmodel.component.html',
  styleUrls: ['./calendar-input-ngmodel.component.scss']
})
export class CalendarInputNgModelComponent {
  @Input() readonly: boolean;
  @Input() placeholder: string = 'dd/mm/aaaa';
  @Input() dateFormat: string = 'dd/mm/yy';
  @Input() showSeconds: boolean;
  @Input() showTime: boolean;
  @Input() showClear: boolean;
  @Input() showIcon: boolean;
  @Input() showOnFocus: boolean;
  @Input() yearNavigator: boolean;
  @Input() keepInvalid: boolean;
  @Input() monthNavigator: boolean;
  @Input() model: any;
  @Input() modelOptions: any;
  @Input() view: 'date'|'month'|'year' = 'date';
  @Input() showCalendarMask: boolean = true;
  @Output() modelChange = new EventEmitter();
  @Output() onInput = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
  onNgModelChange(value: any) {
    this.model = value;
    this.modelChange.emit(this.model);
  }
  onInputChange(event: any) {
    this.onInput.emit(event);
  }
}
