import {AreaEstoqueListagem} from '@app/abstraction/domain/interfaces/estoque/area-estoque/area-estoque-listagem.interface';

export class AreaEstoque {
  id: string;
  ativo: boolean;
  descricao: string;
  principal: boolean;
  empresas: string[];

  static from(json: any = {}) {
    const areaEstoque = new AreaEstoque();
    areaEstoque.id = json.id;
    areaEstoque.ativo = json.ativo;
    areaEstoque.descricao = json.descricao?.trim();
    areaEstoque.principal = json.principal;
    areaEstoque.empresas = json.empresas;
    return areaEstoque;
  }
  toListagem() {
    return <AreaEstoqueListagem>{
      id: this.id,
      descricao: this.descricao,
      principal: this.principal,
      ativo: this.ativo,
    };
  }
}
