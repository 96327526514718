import '@app/shared/extensions/moment.extension';

import {EmpresaListagem} from '@app/abstraction/domain/interfaces/cadastros/empresa/empresa-listagem.interface';
import * as moment from 'moment';

import {Pessoa} from '../pessoa/pessoa.entity';

import {EmpresaCertificado} from './empresa-certificado.entity';

export class Empresa extends Pessoa {
  aliquotaPadraoCofins: number;
  aliquotaPadraoPis: number;
  aliquotaPadraoSimplesNacional: number;
  ativo: boolean;
  certificado: EmpresaCertificado;
  cfopPadraoSt: number;
  cfopPadraoTributado: number;
  id: string;
  empresaLogo: string;
  empresaLogoBase64: string;
  nfceCsc: string;
  nfceIdToken: number;
  planoId: string;
  ncmPadrao: string;
  cestPadrao: string;
  superUsuario: string;
  emailSuperUsuario: string;
  matrizId: string;
  informacoesComplementares: string;
  informacoesComplementaresFisco: string;

  static from(json: any = {}): Empresa {
    const empresa = new Empresa();
    empresa.aliquotaPadraoCofins = json.aliquotaPadraoCofins;
    empresa.aliquotaPadraoPis = json.aliquotaPadraoPis;
    empresa.aliquotaPadraoSimplesNacional = json.aliquotaPadraoSimplesNacional;
    empresa.ativo = json.ativo;
    empresa.certificado = EmpresaCertificado.from(json.certificado);
    empresa.cfopPadraoSt = json.cfopPadraoSt;
    empresa.cfopPadraoTributado = json.cfopPadraoTributado;
    empresa.id = json.id;
    empresa.empresaLogo = json.empresaLogo?.trim();
    empresa.empresaLogoBase64 = json.empresaLogoBase64?.trim();
    empresa.nfceCsc = json.nfceCsc?.trim();
    empresa.nfceIdToken = json.nfceIdToken;
    empresa.planoId = json.planoId;
    empresa.matrizId = json.matrizId;
    empresa.superUsuario = json.superUsuario?.trim();
    empresa.ncmPadrao = json.ncmPadrao?.trim();
    empresa.cestPadrao = json.cestPadrao?.trim();
    empresa.emailSuperUsuario = json.emailSuperUsuario?.trim();
    empresa.informacoesComplementares = json.informacoesComplementares?.trim();
    empresa.informacoesComplementaresFisco =
        json.informacoesComplementaresFisco?.trim();
    empresa.pessoaFrom(json);
    return empresa;
  }
  toAPI(
      _logoCortada: string,
      _certificadoDigitalBase64: string,
      _ncmCodigo: string,
  ) {
    this.certificado.base64 = _certificadoDigitalBase64;

    if (_logoCortada?.includes('base64')) this.empresaLogoBase64 = _logoCortada;

    if (_ncmCodigo) this.ncmPadrao = _ncmCodigo;

    if (this.abertura) this.abertura = moment(this.abertura).toLocalOnlyDate();
  }
  toListagem() {
    return <EmpresaListagem>{
      atividadeTipo: this.atividadeTipo,
      ativo: this.ativo,
      id: this.id,
      documento: this.documento,
      ieIndicador: this.ieIndicador,
      im: this.im,
      nascimento: this.nascimento,
      nomeFantasia: this.nomeFantasia,
      nomeRazaoSocial: this.nomeRazaoSocial,
      pessoaTipo: this.pessoaTipo,
      regimeTributario: this.regimeTributario,
      rgIe: this.rgIe,
      superUsuario: this.superUsuario,
      emailSuperUsuario: this.emailSuperUsuario,
    };
  }
}
