import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Notificacao} from '@app/abstraction/domain/interfaces/shared/notificacao.interface';
import {API} from '@app/shared/constants/api.constant';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotificacaoService {
  constructor(
      private client: HttpClient,
  ) {}

  buscarNotificacoes() {
    const response$ = this.client.get<Notificacao[]>(API.NOTIFICACOES);
    return lastValueFrom(response$);
  }
  marcarComoLidas() {
    const response$ = this.client.put(API.NOTIFICACOES_LIDO, null);
    return lastValueFrom(response$);
  }
}
