class Result {
  comprovante_emitido: string;
  comprovante_emitido_data: string;
  data_inscricao: string;
  data_nascimento: string;
  digito_verificador: string;
  nome_da_pf: string;
  numero_de_cpf: string;
  situacao_cadastral: string;
}

export class DadosCpf {
  result: Result;
  return: string;
  status: boolean;

  retornarDataNascimento() {
    const dia = Number(this.result.data_nascimento.split('/')[0]);
    const mes = Number(this.result.data_nascimento.split('/')[1]);
    const ano = Number(this.result.data_nascimento.split('/')[2]);
    return new Date(ano, mes - 1, dia);
  }
  static criarDadosCpf(json: any = {}): DadosCpf {
    const dadosCpf = new DadosCpf();
    dadosCpf.result = json.result;
    dadosCpf.return = json.return;
    dadosCpf.status = json.status;
    return dadosCpf;
  }
}
