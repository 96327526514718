<ng-container *ngIf="control">
  <div class="p-inputgroup">
    <app-numeric-input
      [control]="control"
      [placeholder]="placeholder"
      [options]="options"
      class="w-full textgroup-input"
    />
    <span class="p-inputgroup-addon">{{ addon }}</span>
  </div>
</ng-container>
