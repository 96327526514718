import {Pipe, PipeTransform} from '@angular/core';
import {AdministradoraFacade} from '@app/abstraction/domain/facades/financeiro/administradora/administradora.facade';
import {AdministradoraListagem} from '@app/abstraction/domain/interfaces/financeiro/administradora/administradora-listagem.interface';

@Pipe({
  name: 'administradora',
})
export class AdministradoraPipe implements PipeTransform {
  constructor(private administradoraFacade: AdministradoraFacade) {}
  transform(id: string, campo: string = 'nome'): any {
    if (id) {
      const administradora = this.administradoraFacade.administradoras?.find(
                                 (administradora: AdministradoraListagem) =>
                                     administradora.id == id) as any;
      return administradora?.[campo];
    }
    return '';
  }
}
