<!-- <p-sidebar
  (onShow)="adicionarTituloMenuNotificacoes()"
  (onHide)="fecharNotificacoes()"
  [(visible)]="mostrarNotificacoes"
  position="right"
  styleClass="notificacoes"
>
  <div class="notificacoes-content">
    <div *ngFor="let notificacao of usuarioNotificacoes$ | async">
      <div
        class="notificacao-item"
        *ngIf="!notificacao.executado && !notificacao.removido"
      >
        <span class="notificacao-titulo"
          >{{ notificacao.titulo }}
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-plain p-button-text"
            (click)="removerNotificacao(notificacao)"
          ></button>
        </span>
        <span class="notificacao-mensagem">{{ notificacao.mensagem }}</span>
        <div class="notificacao-footer">
          <span class="notificacao-tempo">{{
            notificacao.dataHoraRecebido | dateTimeAgo
          }}</span>
          <p-button
            class="notificacao-botao"
            styleClass="btn-five"
            *ngIf="
              verificarNotificacaoTipoExecutavel(notificacao.notificacaoTipo)
            "
            [label]="
              verificarExecutando(notificacao.id) ? '' : notificacao.acaoLabel
            "
            (onClick)="executarMetodo()"
            [loading]="verificarExecutando(notificacao.id)"
          >
          </p-button>
        </div>
      </div>
    </div>
  </div>
</p-sidebar> -->

<!-- (onShow)="adicionarTituloMenuNotificacoes()" -->
<p-sidebar
  (onHide)="fecharNotificacoes()"
  [(visible)]="mostrarNotificacoes"
  position="right"
  styleClass="notificacoes"
>
  <ng-template pTemplate="header">Notificações</ng-template>

  <div class="notificacoes-content">
    <div *ngFor="let notificacao of notificacoes">
      <div class="notificacao-item">
        <span class="notificacao-titulo"
          >{{ notificacao.titulo }}
          <p-badge
            *ngIf="notificacao.show"
            [value]="notificacao.quantidade"
          ></p-badge>
        </span>
        <span class="notificacao-mensagem">{{ notificacao.texto }}</span>
        <div class="notificacao-footer">
          <p-tag *ngIf="!notificacao.dataHoraLeitura" styleClass="mt-auto"
            >Nova</p-tag
          >
          <span class="notificacao-tempo ml-auto mt-4 mb-0">{{
            notificacao.dataHoraCriacao | dateTimeAgo
          }}</span>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
