import { Injectable } from '@angular/core';
import { TransportadoraService } from '@app/abstraction/data/services/cadastros/transportadora.service';
import { TransportadoraStore } from '@app/abstraction/data/stores/cadastros/transportadora.store';
import { Transportadora } from '@app/abstraction/domain/entities/cadastros/transportadora/transportadora.entity';
import { TransportadoraFiltro } from '@app/abstraction/domain/interfaces/cadastros/transportadora/transportadora-filtro.interface';

@Injectable({ providedIn: 'root' })
export class TransportadoraFacade {
  get transportadoras() {
    return this.store.transportadoras;
  }
  get transportadoras$() {
    return this.store.transportadoras$;
  }
  constructor(
    private store: TransportadoraStore,
    private service: TransportadoraService
  ) {}
  async atualizarSituacao(id: string) {
    const transportadora = this.transportadoras.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(
      id,
      !transportadora.ativo
    );
    transportadora.ativo = status;
    return status;
  }
  async adicionar(transportadora: Transportadora) {
    const novoTransportadora = (await this.service.adicionar(
      transportadora
    )) as Transportadora;
    if (novoTransportadora) {
      this.store.adicionar(novoTransportadora);
    }
    return novoTransportadora;
  }
  async buscarTransportadoras(filtro?: TransportadoraFiltro) {
    const transportadoras = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(transportadoras);
  }
  async buscarPorDocumento(documento: string) {
    const transportadoras = await this.service.buscarTodos({
      documento: documento,
    });
    return transportadoras.first();
  }
  async buscarRegistrosFinanceiros(id: string) {
    var registrosFinanceiros = await this.service.buscarRegistrosFinanceiros(
      id
    );
    return registrosFinanceiros;
  }
  alterar(transportadora: Transportadora) {
    return this.service.alterar(transportadora, transportadora.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  excluir(id: string) {
    return this.service.excluir(id);
  }
  limparListaTransportadoras() {
    this.store.transportadoras = null;
  }
}
