import {Component, EventEmitter, Input, Output} from '@angular/core';

import {CheckboxGrupo} from './interface/checkbox-grupo.interface';
import {CheckboxItem} from './interface/checkbox-item.interface';

@Component({
  selector: 'app-checkbox-grupo',
  templateUrl: './checkbox-grupo.component.html',
  styleUrls: ['./checkbox-grupo.component.scss'],
})
export class CheckboxGrupoComponent {
  _itensSelecionados: string[] = [];
  @Input() grupos: CheckboxGrupo[];
  @Input() gruposSelecionados: string[];
  @Input()
  set itensSelecionados(itens: string[]) {
    this._itensSelecionados = itens;
    this.verificarTodosSelecionados();
  }
  get itensSelecionados(): string[] {
    return this._itensSelecionados;
  }
  @Output() gruposSelecionadosChange = new EventEmitter<string[]>();
  @Output() itensSelecionadosChange = new EventEmitter<string[]>();

  private emitirGruposSelecionados(grupo: CheckboxGrupo) {
    if (grupo.filhos.every(this.todosDesselecionados) &&
        this.gruposSelecionados?.includes(grupo.valor)) {
      const index = this.gruposSelecionados?.indexOf(grupo.valor);
      this.gruposSelecionados?.splice(index, 1);
    } else if (!this.gruposSelecionados?.includes(grupo.valor)) {
      this.gruposSelecionados?.push(grupo.valor);
    }
    this.gruposSelecionadosChange.emit(this.gruposSelecionados);
  }
  private todosDesselecionados(item: CheckboxItem) {
    return !item.selecionado;
  }
  private verificarTodosSelecionados() {
    this.grupos?.forEach((grupo) => {
      grupo.todosSelecionados = grupo.filhos.every(
          (item) => this.itensSelecionados.includes(item.valor));
    });
  }
  emitirSelecionados(grupo: CheckboxGrupo, item: CheckboxItem) {
    item.selecionado = !item.selecionado;
    this.itensSelecionadosChange.emit(this.itensSelecionados);
    this.verificarTodosSelecionados();
    this.emitirGruposSelecionados(grupo);
  }
  toggleTodosSelecionados(grupo: CheckboxGrupo) {
    grupo.todosSelecionados = !grupo.todosSelecionados;

    grupo.filhos.forEach((item) => {
      item.selecionado = grupo.todosSelecionados;
    });

    if (grupo.todosSelecionados) {
      grupo.filhos.forEach((item) => {
        if (!this.itensSelecionados.includes(item.valor)) {
          this.itensSelecionados.push(item.valor);
        }
      });
    } else {
      grupo.filhos.forEach((item) => {
        const index = this.itensSelecionados.indexOf(item.valor);
        this.itensSelecionados.splice(index, 1);
      });
    }
    this.itensSelecionados = [...this.itensSelecionados];
    this.itensSelecionadosChange.emit(this.itensSelecionados);
    this.emitirGruposSelecionados(grupo);
  }
}
