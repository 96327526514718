import {PessoaTipo} from '@app/shared/enums/pessoa-tipo.enum';

import {Endereco} from './endereco.entity';

export class NotaFiscalEmitente {
  pessoaTipo: PessoaTipo;
  crt: number;
  documento: string;
  email: string;
  endereco: Endereco;
  ie: string;
  nomeFantasia: string;
  razaoSocial: string;

  static from(json: any = {}): NotaFiscalEmitente {
    const emitente = new NotaFiscalEmitente();
    emitente.pessoaTipo = json.pessoaTipo;
    emitente.crt = json.crt;
    emitente.documento = json.documento?.somenteNumeros()?.trim();
    emitente.email = json.email?.trim();
    emitente.endereco = Endereco.from(json.endereco);
    emitente.ie = json.ie?.trim();
    emitente.nomeFantasia = json.nomeFantasia?.trim();
    emitente.razaoSocial = json.razaoSocial?.trim();

    return emitente;
  }

  preencherPessoaTipo() {
    this.pessoaTipo = this.documento.length <= 11 ? 1 : 2;
  }
}
