import {FormGroup} from '@angular/forms';
import {fromEvent, Subscription} from 'rxjs';

export class BaseForm {
  formModificado: boolean;
  monitorJanela: Subscription;
  monitorarFormulario(form: FormGroup<any>) {
    form.valueChanges.subscribe(() => {
      this.formModificado = form.dirty;
    });
    this.monitorJanela =
        fromEvent(window, 'beforeunload').subscribe((event) => {
          this.formModificado && (event.returnValue = false);
        });
  }
  finalizarMonitoramentoFormulario() {
    this.formModificado = false;
    this.monitorJanela?.unsubscribe();
  }
  adicionarValidator(campo: string, validators: any, form: FormGroup<any>) {
    form.get(campo).setValidators(validators);
    form.get(campo).updateValueAndValidity();
  }
  removerValidator(campo: string, form: FormGroup<any>) {
    form.get(campo).clearValidators();
    form.get(campo).updateValueAndValidity();
  }
}