export enum TipoEstoque {
  EstoquePositivo = 1,
  EstoqueNegativo,
  Divergencia,
  EstoqueZerado,
}

export const TipoEstoqueLabels = new Map<number, string>([
  [TipoEstoque.EstoquePositivo, 'Estoque Positivo'],
  [TipoEstoque.EstoqueNegativo, 'Estoque Negativo'],
  [TipoEstoque.Divergencia, 'Divergência'],
  [TipoEstoque.EstoqueZerado, 'Estoque Zerado'],
]);
