import {Injectable} from '@angular/core';
import {MarcaListagem} from '@app/abstraction/domain/interfaces/produtos/marca/marca-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MarcaStore {
  private readonly _marcas = new BehaviorSubject<MarcaListagem[]>(null);
  get marcas(): MarcaListagem[] {
    return this._marcas.getValue();
  }
  set marcas(marcas: MarcaListagem[]) {
    this._marcas.next(marcas);
  }
  get marcas$() {
    return this._marcas.asObservable();
  }
  adicionar(marca: MarcaListagem) {
    if (!this.marcas) {
      this.marcas = [];
    }
    this.marcas.push(marca);
  }
  adicionarRange(marcas: MarcaListagem[]) {
    if (!this.marcas) {
      this.marcas = [];
    }
    marcas.forEach((marca) => {
      if (!this.marcas.some((c) => c.id === marca.id)) {
        this.marcas.push(marca);
      }
    });
  }
}
