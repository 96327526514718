import {FormControl, FormGroup} from '@angular/forms';
import {Secao} from '@app/abstraction/domain/entities/produtos/secao/secao.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface ISecaoForm {
  id: FormControl<string>;
  descricao: FormControl<string>;
  ativo: FormControl<boolean>;
}

export class SecaoForm extends BaseForm {
  form: FormGroup<ISecaoForm>;
  operacaoForm: OperacaoForm;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova seção' : 'Editar seção';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(private errorMessagesFacade: ErrorMessageFacade) {
    super();
  }
  private preencherForm(value: Secao) {
    this.form.patchValue(value);
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  criarForm() {
    this.form = new FormGroup({
      id: new FormControl(null),
      descricao: new FormControl(
          null, CustomValidators.required('Descrição é obrigatória')),
      ativo: new FormControl(true),
    });
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(Secao.from(value));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
}
