export enum NFeFinalidade {
  Normal = 1,
  Ajuste = 2,
  Complementar = 3,
  Devolucao = 4,
}

export const NFeFinalidadeLabels = new Map<number, string>([
  [NFeFinalidade.Normal, 'NORMAL'],
  [NFeFinalidade.Ajuste, 'AJUSTE'],
  [NFeFinalidade.Complementar, 'COMPLEMENTAR'],
  [NFeFinalidade.Devolucao, 'DEVOLUÇÃO'],
]);
