import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {ContaBancariaFiltro} from '@app/abstraction/domain/interfaces/financeiro/conta-bancaria/conta-bancaria-filtro.interface';
import {ContaBancariaListagem} from '@app/abstraction/domain/interfaces/financeiro/conta-bancaria/conta-bancaria-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ContaBancariaService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: ContaBancaria) {
    const response$ =
        this.client.post<ContaBancaria>(API.FINANCEIRO_CONTA_BANCARIA, dados)
            .pipe(
                map((json: ContaBancaria) =>
                        (json ? ContaBancaria.from(json) : null)));
    return lastValueFrom(response$);
  }
  alterar(dados: ContaBancaria, id: string) {
    const response$ =
        this.client
            .put<ContaBancaria>(`${API.FINANCEIRO_CONTA_BANCARIA}/${id}`, dados)
            .pipe(
                map((json: ContaBancaria) =>
                        (json ? ContaBancaria.from(json) : null)));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.FINANCEIRO_CONTA_BANCARIA}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<ContaBancaria>(`${API.FINANCEIRO_CONTA_BANCARIA}/${id}`)
            .pipe(
                map((json: ContaBancaria) =>
                        (json ? ContaBancaria.from(json) : null)));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: ContaBancariaFiltro) {
    const response$ =
        this.client.get<ContaBancariaListagem[]>(this.geradorQueryString.gerar(
            `${API.FINANCEIRO_CONTA_BANCARIA}`, filtro));
    return lastValueFrom(response$);
  }
}
