import {Injectable} from '@angular/core';
import {EnderecoTipoListagem} from '@app/abstraction/domain/interfaces/shared/endereco-tipo-listagem.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class EnderecoTipoStore {
  private readonly _enderecoTipos =
      new BehaviorSubject<EnderecoTipoListagem[]>(null);
  get enderecoTipos() {
    return this._enderecoTipos.getValue();
  }
  set enderecoTipos(enderecoTipos: EnderecoTipoListagem[]) {
    this._enderecoTipos.next(enderecoTipos);
  }
  get enderecoTipos$(): Observable<EnderecoTipoListagem[]> {
    return this._enderecoTipos.asObservable();
  }
  adicionarRange(enderecoTipos: EnderecoTipoListagem[]) {
    if (!this.enderecoTipos) {
      this.enderecoTipos = [];
    }

    enderecoTipos.forEach((enderecoTipo) => {
      if (!this.enderecoTipos.some(
              (c) => c.enderecoTipoId === enderecoTipo.enderecoTipoId)) {
        this.enderecoTipos.push(enderecoTipo);
      }
    });
  }
}
