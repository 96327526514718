import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Transportadora} from '@app/abstraction/domain/entities/cadastros/transportadora/transportadora.entity';
import {FuncionarioFacade} from '@app/abstraction/domain/facades/cadastros/funcionario/funcionario.facade';
import {TransportadoraFacade} from '@app/abstraction/domain/facades/cadastros/transportadora/transportadora.facade';
import {ContaBancariaFacade} from '@app/abstraction/domain/facades/financeiro/conta-bancaria/conta-bancaria.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {TransportadoraSharedFormComponent} from '@app/shared/components/shared-forms/transportadora-shared-form/transportadora-shared-form.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-transportadora-cadastro-rapido-modal',
  templateUrl: './transportadora-cadastro-rapido-modal.component.html',
  styleUrls: ['./transportadora-cadastro-rapido-modal.component.scss']
})
export class TransportadoraCadastroRapidoModalComponent extends
    FormBaseComponent implements AfterViewInit {
  @ViewChild('formComponent') formComponent: TransportadoraSharedFormComponent;

  @Input() mostrarModal: boolean;
  @Input() transportadora: Transportadora;
  @Output() aoSalvar = new EventEmitter<Transportadora>();
  @Output() aoCancelar = new EventEmitter();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private transportadoraFacade: TransportadoraFacade,
      private enderecoTipoFacade: EnderecoTipoFacade,
      private planoContasFacade: PlanoContasFacade,
      private funcionarioFacade: FuncionarioFacade,
      private contaBancariaFacade: ContaBancariaFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarTransportadora(transportadora: Transportadora) {
    return this.transportadoraFacade.adicionar(transportadora).catch((e) => {
      this.saveLoading = false;
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(transportadora: Transportadora) {
    this.toastrService.success(`Transportadora cadastrado com sucesso`);
    this.aoSalvar.emit(transportadora);
    this.aoFecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!(await this.formComponent.transportadoraForm.isValid())) {
        this.saveLoading = false;
        return;
      }

      const transportadora = this.formComponent.preencherObjeto();

      const resultado = await this.adicionarTransportadora(transportadora);

      if (resultado) this.mostrarSucessoAoSalvar(resultado);
    } catch (error) {
      this.toastrService.error('Erro ao salvar transportadora.');
      this.saveLoading = false;
    }
  }
  async ngAfterViewInit() {
    this.formComponent.criarForm();
  }
  async aoAbrirModal() {
    this.formComponent.criarForm();
    Promise
        .all([
          this.enderecoTipoFacade.buscarEnderecoTipos(),
          this.contaBancariaFacade.buscarContasBancarias(),
          await this.planoContasFacade.buscarPlanosContasNivel3({ativo: true}),
          await this.funcionarioFacade.buscarFuncionarios({ativo: true}),
        ])
        .then(() => {
          this.formComponent.transportadoraForm.setarTipoFormulario(
              this.transportadora);
        });
  }
  aoFecharModal() {
    this.contaBancariaFacade.limparListaContasBancarias();
    this.enderecoTipoFacade.limparListaEnderecoTipos();
    this.planoContasFacade.limparListaPlanosContasNivel2();
    this.planoContasFacade.limparListaPlanosContasNivel3();
    this.aoCancelar.emit();
  }
}
