import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appCalendarMask]',
})
export class CalendarMaskDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}
  @Input() showCalendarMask: boolean = true;

  ngAfterViewInit() {
    if (!this.showCalendarMask) return;
    const input = this.elementRef.nativeElement.querySelector('input');
    input.addEventListener('keydown', (event: any) => {
      if (event.key === 'Backspace') return;
      if (event.key === 'Tab') return;
      if (event.key === 'Control') return;
      if (this.isCharacter(event.key) && !event.ctrlKey) {
        event.preventDefault();
        return;
      };
      if (this.isCharacter(event.key) && event.ctrlKey) return;
      if (event.currentTarget.value.length == 10 && this.allSelected(event)) {
        event.currentTarget.value = '';
        return;
      }
      if (event.currentTarget.value.length == 10 && !this.allSelected(event)) {
        event.currentTarget.value = event.currentTarget.value.slice(0, 10);
        event.preventDefault();
        return;
      }
      if ((event.currentTarget.value.length === 2 ||
           event.currentTarget.value.length === 5)) {
        event.currentTarget.value += '/';
      }
    });
  }
  private allSelected(event: any) {
    const textLength = event.currentTarget.value.length;
    const selectionStart = event.currentTarget.selectionStart;
    const selectionEnd = event.currentTarget.selectionEnd;
    return selectionStart === 0 && selectionEnd === textLength;
  }
  private isCharacter(key: any) {
    return key.length === 1 && key.match(/[a-z]/i);
  }
}
