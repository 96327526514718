import { Pipe, PipeTransform } from '@angular/core';
import { ProdutoCstsIpi } from '@app/shared/constants/produto-csts-ipi.constant';

@Pipe({
  name: 'produtoCstIpiDescricao',
})
export class ProdutoCstIpiDescricaoPipe implements PipeTransform {
  transform(valor: any): any {
    if (valor) {
      const cst = ProdutoCstsIpi.find((e) => e.codigo === valor.toString());
      if (cst) {
        return `${cst.codigo} - ${cst.descricao}`;
      }
    }
    return '';
  }
}
