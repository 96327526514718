import {Pipe, PipeTransform} from '@angular/core';
import {SubgrupoFacade} from '@app/abstraction/domain/facades/produtos/subgrupo/subgrupo.facade';
import {SubgrupoListagem} from '@app/abstraction/domain/interfaces/produtos/subgrupo/subgrupo-listagem.interface';

@Pipe({
  name: 'subgrupo',
})
export class SubgrupoPipe implements PipeTransform {
  constructor(private subgrupoFacade: SubgrupoFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const subgrupo =
          this.subgrupoFacade.subgrupos.find(
              (subgrupo: SubgrupoListagem) => subgrupo.id == id) as any;
      return subgrupo?.[campo];
    }
    return '';
  }
}
