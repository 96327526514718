import { Pipe, PipeTransform } from '@angular/core';
import { ProdutoCcsApuradas } from '@app/shared/constants/produto-ccs-apuradas.constant';

@Pipe({
  name: 'produtoCcsApuradaDescricao',
})
export class ProdutoCcsApuradaDescricaoPipe implements PipeTransform {
  transform(valor: any): any {
    if (valor) {
      const ccs = ProdutoCcsApuradas.find((e) => e.codigo === valor.toString());
      if (ccs) {
        return `${ccs.codigo} - ${ccs.descricao}`;
      }
    }
    return '';
  }
}
