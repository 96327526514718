export enum ConsultaDataPeriodo {
  Personalizado = 1,
  Hoje,
  Ontem,
  Amanha,
  EstaSemana,
  MesAtual,
  MesPassado,
  ProximoMes,
}

export const ConsultaDataPeriodoLabels = new Map<number, string>([
  [ConsultaDataPeriodo.Personalizado, 'Personalizado'],
  [ConsultaDataPeriodo.Hoje, 'Hoje'],
  [ConsultaDataPeriodo.Ontem, 'Ontem'],
  [ConsultaDataPeriodo.Amanha, 'Amanhã'],
  [ConsultaDataPeriodo.EstaSemana, 'Esta semana'],
  [ConsultaDataPeriodo.MesPassado, 'Mês passado'],
  [ConsultaDataPeriodo.MesAtual, 'Mês atual'],
  [ConsultaDataPeriodo.ProximoMes, 'Próximo mês'],
]);
