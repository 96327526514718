<p-autoComplete
  #contentInput
  styleClass=" inputfield w-full"
  inputStyleClass="w-full"
  appUppercase
  appAutoFocus
  [forceSelection]="forceSelection"
  [appendTo]="appendTo"
  [field]="field"
  [showClear]="showClear"
  [autofocus]="autoFocus"
  [panelStyle]="panelStyle"
  [emptyMessage]="emptyMessage"
  [scrollHeight]="scrollHeight"
  [suggestions]="sugestoes"
  [maxlength]="maxlength"
  [showEmptyMessage]="true"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [(ngModel)]="model"
  [ngClass]="{
    readonly: readonly,
    'text-right': textAlign === 'right',
    'text-center': textAlign === 'center',
    'text-left': textAlign === 'left'
  }"
  (completeMethod)="completeMethodEvent($event)"
  (onSelect)="onSelectEvent($event)"
>
  <ng-template let-item pTemplate="item">
    <ng-container
      *ngIf="template"
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
    <span *ngIf="!template">{{ item[field] }}</span>
  </ng-template>
</p-autoComplete>
