<p-orderList [value]="lista" [listStyle]="{'max-height':'30rem'}" [dragdrop]="true" [filterBy]="filtrarPor"
  [filterPlaceholder]="filtroPlaceholder" controlsPosition="right" (onReorder)="setarNovaOrdem()"
  [(selection)]="itensSelecionados" [metaKeySelection]="false">
  <ng-template let-imagem pTemplate="item">
    <div class="item">
      <div class="image-container">
        <img [src]="imagem.fotoBase64" [alt]="imagem.fotoNome" class="image" />
      </div>
      <div class="list-detail">
        <h5 class="mb-2">{{imagem.fotoNome}} - Ordem: {{imagem.ordem}}</h5>
      </div>
    </div>
  </ng-template>
</p-orderList>
<div class="list-action">
  <p-button type="button" styleClass="btn-five" tooltipPosition="bottom" pTooltip="Remover" icon="pi pi-trash"
    (onClick)="removerItem()">
  </p-button>
</div>
