<p-dialog
  header="Novo Cliente"
  [style]="{ width: isDesktop ? '80%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="aoFecharModal()"
  (onShow)="aoAbrirModal()"
>
  <app-cliente-shared-form #formComponent></app-cliente-shared-form>

  <ng-template pTemplate="footer">
    <p-button
      (click)="aoFecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
