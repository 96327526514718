import {FormControl, FormGroup} from '@angular/forms';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {PlanoContasNivel2} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-2.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {FormaParcelamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-parcelamento/forma-parcelamento.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {FormaPagamentoTipoOperacao} from '@app/shared/enums/forma-pagamento-tipo-operacao.enum';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface IFormaPagamentoForm {
  id: FormControl<string>;
  descricao: FormControl<string>;
  codigoExterno: FormControl<number>;
  tipoPagamentoNfe: FormControl<number>;
  tipoOperacao: FormControl<number>;
  formasParcelamento: FormControl<string[]>;
  empresas: FormControl<string[]>;
  baixado: FormControl<boolean>;
  ativo: FormControl<boolean>;
  planoContaId: FormControl<string>;
  permiteTroco: FormControl<boolean>;
  permiteMaisDeUma: FormControl<boolean>;
  geraConvenio: FormControl<boolean>;
  geraConciliacao: FormControl<boolean>;
  geraMovimentoFiscal: FormControl<boolean>;
  solicitaIdentificacaoCliente: FormControl<boolean>;
  exibeNoConvenio: FormControl<boolean>;
  exibeNoPedido: FormControl<boolean>;
  exibeNoPdvMobile: FormControl<boolean>;
  boleto: FormControl<boolean>;
  ordem: FormControl<number>;
  teclaAtalho: FormControl<string>;
  codigoSat: FormControl<string>;
  contaBancariaId: FormControl<string>;
  prazoCompensacao: FormControl<number>;
}

export class FormaPagamentoForm extends BaseForm {
  form: FormGroup<IFormaPagamentoForm>;
  operacaoForm: OperacaoForm;
  mostarFormaParcelamentoCadastroRapidoModal: boolean;
  mostarPlanoContaCadastroRapidoModal: boolean;
  mostrarContaBancariaCadastroRapidoModal: boolean;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova forma de pagamento' :
                                        'Editar forma de pagamento';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }

  get contaBancariaPadrao() {
    return this.parametroFacade.contaBancariaPadrao;
  }
  get planoContasPadrao() {
    return this.parametroFacade.planoContasPadrao;
  }
  constructor(
      private errorMessagesFacade: ErrorMessageFacade,
      private authFacade: AuthFacade,
      private formaParcelamentoFacade: FormaParcelamentoFacade,
      private parametroFacade: ParametroFacade,

  ) {
    super();
  }
  private preencherForm(formaPagamento: FormaPagamento) {
    this.form.patchValue(formaPagamento);
  }
  criarForm() {
    this.form = new FormGroup<IFormaPagamentoForm>({
      descricao: new FormControl(
          null, CustomValidators.required('Descrição é obrigatória')),
      codigoExterno: new FormControl(null),
      tipoPagamentoNfe: new FormControl(
          null,
          CustomValidators.required('Tipo de pagamento NF-e é obrigatório')),
      tipoOperacao: new FormControl(null),
      formasParcelamento: new FormControl(
          null,
          CustomValidators.required('Forma de parcelamento é obrigatório')),
      empresas: new FormControl(
          null, CustomValidators.required('Empresa é obrigatório')),
      id: new FormControl(null),
      baixado: new FormControl(false),
      ativo: new FormControl(true),
      planoContaId: new FormControl(null),
      permiteTroco: new FormControl(false),
      permiteMaisDeUma: new FormControl(false),
      geraConvenio: new FormControl(false),
      geraConciliacao: new FormControl(false),
      geraMovimentoFiscal: new FormControl(false),
      solicitaIdentificacaoCliente: new FormControl(false),
      exibeNoConvenio: new FormControl(false),
      exibeNoPedido: new FormControl(false),
      exibeNoPdvMobile: new FormControl(false),
      boleto: new FormControl(false),
      ordem: new FormControl(null),
      teclaAtalho: new FormControl(null),
      codigoSat: new FormControl(
          null, CustomValidators.maxLength(3, 'Tamanho máximo: 3 caracteres')),
      contaBancariaId: new FormControl(null),
      prazoCompensacao: new FormControl(null)
    });
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(FormaPagamento.from(value));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
      this.setarValoresPadroes();
    }
  }
  setarValoresPadroes() {
    const aVista = this.formaParcelamentoFacade.formasParcelamento
                       ?.filter(fp => fp.quantidadeParcela === 1)
                       ?.first();

    const matriz =
        this.authFacade.grupoLogado.empresas.filter(e => e.id === e.matrizId)
            .first();
    this.form.controls.empresas.setValue([matriz?.id]);
    this.form.controls.formasParcelamento.setValue([aVista?.id]);
    this.form.controls.tipoOperacao.setValue(
        FormaPagamentoTipoOperacao.Entrada);
    this.form.controls.planoContaId.setValue(this.planoContasPadrao);
    this.form.controls.contaBancariaId.setValue(this.contaBancariaPadrao);
    this.form.controls.baixado.setValue(true);
  }
  abrirPlanoContaCadastroRapidoModal() {
    this.mostarPlanoContaCadastroRapidoModal = true;
  }
  abrirFormaParcelamentoCadastroRapidoModal() {
    this.mostarFormaParcelamentoCadastroRapidoModal = true;
  }
  fecharFormaParcelamentoCadastroRapidoModal() {
    this.mostarFormaParcelamentoCadastroRapidoModal = false;
  }
  fecharPlanoContaCadastroRapidoModal() {
    this.mostarPlanoContaCadastroRapidoModal = false;
  }
  abrirContaBancariaCadastroRapidoModal() {
    this.mostrarContaBancariaCadastroRapidoModal = true;
  }
  fecharContaBancariaCadastroRapidoModal() {
    this.mostrarContaBancariaCadastroRapidoModal = false;
  }
  setarPlanoContasCadastrado(planoContas: PlanoContasNivel2) {
    if (planoContas.id) {
      this.form.controls.planoContaId.setValue(planoContas.id);
    }
  }
  setarContaBancariaCadastrada(contaBancaria: ContaBancaria) {
    this.form.controls.contaBancariaId.setValue(contaBancaria.id);
  }
}
