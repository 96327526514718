import {Injectable} from '@angular/core';
import {TipoAjusteService} from '@app/abstraction/data/services/estoque/tipo-ajuste.service';
import {TipoAjusteStore} from '@app/abstraction/data/stores/estoque/tipo-ajuste.store';
import {TipoAjuste} from '@app/abstraction/domain/entities/estoque/tipo-ajuste/tipo-ajuste.entity';
import {TipoAjusteFiltro} from '@app/abstraction/domain/interfaces/estoque/tipo-ajuste/tipo-ajuste-filtro.interface';

@Injectable({providedIn: 'root'})
export class TipoAjusteFacade {
  get tiposAjuste() {
    return this.store.tiposAjuste;
  }
  get tiposAjuste$() {
    return this.store.tiposAjuste$;
  }
  constructor(
      private store: TipoAjusteStore, private service: TipoAjusteService) {}
  async atualizarSituacao(id: string) {
    const tipoAjuste = this.tiposAjuste.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !tipoAjuste.ativo);
    tipoAjuste.ativo = status;
    return status;
  }
  async adicionar(tipoAjuste: TipoAjuste) {
    const novaTipoAjuste = await this.service.adicionar(tipoAjuste);
    if (novaTipoAjuste) {
      this.store.adicionar(novaTipoAjuste.toListagem());
    }
    return novaTipoAjuste;
  }
  async buscarTiposAjuste(filtro?: TipoAjusteFiltro) {
    const tipoAjustes = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(tipoAjustes);
  }
  alterar(tipoAjuste: TipoAjuste) {
    return this.service.alterar(tipoAjuste, tipoAjuste.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaTiposAjuste() {
    this.store.tiposAjuste = null;
  }
}
