import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProdutoVariacaoCor} from '@app/abstraction/domain/entities/produtos/produto-variacao-cor/produto-variacao-cor.entity';
import {ProdutoVariacaoCorFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-cor/produto-variacao-cor.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ProdutoVariacaoCorForm} from '@app/abstraction/domain/forms/produtos/produto/produto-variacao-cor.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-produto-variacao-cor-cadastro-rapido-modal',
  templateUrl: './produto-variacao-cor-cadastro-rapido-modal.component.html',
})
export class ProdutoVariacaoCorCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<ProdutoVariacaoCor>();
  @Output() aoCancelar = new EventEmitter();

  corForm: ProdutoVariacaoCorForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }

  constructor(
      private produtoVariacaoCorFacade: ProdutoVariacaoCorFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,

      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarProdutoVariacaoCor(produtoVariacaoCor:
                                                ProdutoVariacaoCor) {
    return this.produtoVariacaoCorFacade.adicionar(produtoVariacaoCor)
        .catch((e: any) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private criarForm() {
    this.corForm = new ProdutoVariacaoCorForm(this.errorMessagesFacade);
    this.corForm.criarForm();
    this.corForm.setarTipoFormulario();
  }
  private preencherObjeto() {
    return ProdutoVariacaoCor.from(this.corForm.form.value);
  }
  private mostrarSucessoAoSalvar(produtoVariacaoCor: ProdutoVariacaoCor) {
    this.toastrService.success(`Cor do produto cadastrada com sucesso`);
    this.aoSalvar.emit(produtoVariacaoCor);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.corForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const cor = this.preencherObjeto();
      const resultado = await this.adicionarProdutoVariacaoCor(cor)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar cor do produto.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  fecharModal() {
    this.corForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
  }
}
