import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TipoAjuste} from '@app/abstraction/domain/entities/estoque/tipo-ajuste/tipo-ajuste.entity';
import {TipoAjusteFiltro} from '@app/abstraction/domain/interfaces/estoque/tipo-ajuste/tipo-ajuste-filtro.interface';
import {TipoAjusteListagem} from '@app/abstraction/domain/interfaces/estoque/tipo-ajuste/tipo-ajuste-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TipoAjusteService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: TipoAjuste) {
    const response$ =
        this.client.post<TipoAjuste>(API.ESTOQUE_TIPOAJUSTE, dados)
            .pipe(
                map((json: TipoAjuste) => json ? TipoAjuste.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: TipoAjuste, id: string) {
    const response$ =
        this.client.put<TipoAjuste>(`${API.ESTOQUE_TIPOAJUSTE}/${id}`, dados)
            .pipe(
                map((json: TipoAjuste) => json ? TipoAjuste.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.ESTOQUE_TIPOAJUSTE}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<TipoAjuste>(`${API.ESTOQUE_TIPOAJUSTE}/${id}`)
            .pipe(
                map((json: TipoAjuste) => json ? TipoAjuste.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: TipoAjusteFiltro) {
    const response$ = this.client.get<TipoAjusteListagem[]>(
        this.geradorQueryString.gerar(`${API.ESTOQUE_TIPOAJUSTE}`, filtro));
    return lastValueFrom(response$);
  }
}
