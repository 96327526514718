import {Pipe, PipeTransform} from '@angular/core';
import {PerfilFacade} from '@app/abstraction/domain/facades/cadastros/perfil/perfil.facade';
import {PerfilListagem} from '@app/abstraction/domain/interfaces/cadastros/perfil/perfil-listagem.interface';

@Pipe({
  name: 'perfil',
})
export class PerfilPipe implements PipeTransform {
  constructor(private perfilCadastroFacade: PerfilFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const perfil = this.perfilCadastroFacade.perfis.find(
                         (perfil: PerfilListagem) => perfil.id == id) as any;

      return perfil?.[campo];
    }
    return '';
  }
}
