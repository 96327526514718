import {FormArray, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {FornecedorProduto} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor-produto.entity';
import {Fornecedor} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor.entity';
import {Pessoa} from '@app/abstraction/domain/entities/cadastros/pessoa/pessoa.entity';
import {LancamentoParcela} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import {Contato} from '@app/abstraction/domain/entities/shared/contato.entity';
import {Endereco} from '@app/abstraction/domain/entities/shared/endereco.entity';
import {FornecedorFacade} from '@app/abstraction/domain/facades/cadastros/fornecedor/fornecedor.facade';
import {UsuarioFacade} from '@app/abstraction/domain/facades/cadastros/usuario/usuario.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {ProdutoFacade} from '@app/abstraction/domain/facades/produtos/produto/produto.facade';
import {ApiServicesFacade} from '@app/abstraction/domain/facades/shared/api-services/api-services.facade';
import {CepFacade} from '@app/abstraction/domain/facades/shared/cep/cep.facade';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {PessoaFacade} from '@app/abstraction/domain/facades/shared/pessoa/pessoa.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {FornecedorProdutoForm} from '@app/abstraction/domain/forms/cadastros/fornecedor-produto.form';
import {ContatoForm, IContatoForm,} from '@app/abstraction/domain/forms/shared/contato.form';
import {EnderecoForm, IEnderecoForm,} from '@app/abstraction/domain/forms/shared/endereco.form';
import {ProdutoSimplificadoListagem} from '@app/abstraction/domain/interfaces/produtos/produto/produto-simplificado-listagem.interface';
import {Estados} from '@app/shared/constants/estados.constant';
import {AtividadeTipo} from '@app/shared/enums/atividade-tipo.enum';
import {IeIndicador} from '@app/shared/enums/ie-indicador.enum';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {PessoaTipo} from '@app/shared/enums/pessoa-tipo.enum';
import {RegimeTributario} from '@app/shared/enums/regimes-tributarios.enum';
import {DadosCnpj} from '@app/shared/models/cnpj-hub.entity';
import {DadosCpf} from '@app/shared/models/cpf-hub.entity';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';
import {ToastrService} from 'ngx-toastr';

import {IFornecedorProdutoForm} from './fornecedor-produto.form';

export interface IFornecedorForm {
  atividadeTipo: FormControl<AtividadeTipo>;
  documento: FormControl<string>;
  ieIndicador: FormControl<IeIndicador>;
  nomeFantasia: FormControl<string>;
  nomeRazaoSocial: FormControl<string>;
  pessoaTipo: FormControl<PessoaTipo>;
  regimeTributario: FormControl<RegimeTributario>;
  rgIe: FormControl<string>;
  ativo: FormControl<boolean>;
  id: FormControl<string>;
  observacao: FormControl<string>;
  compraAutorizar: FormControl<boolean>;
  compraValorMinimo: FormControl<number>;
  contatos: FormArray<FormGroup<IContatoForm>>;
  enderecos: FormArray<FormGroup<IEnderecoForm>>;
  produtos: FormArray<FormGroup<IFornecedorProdutoForm>>;
  createdOn: FormControl<Date>;
  lastModifiedOn: FormControl<Date>;
  createdBy: FormControl<string>;
  nomeCreatedBy: FormControl<string>;
  planoConta3Id: FormControl<string>;
  whatsApp: FormControl<string>;
  telefoneFixo: FormControl<string>;
  vendedorPadrao: FormControl<string>;
  emailGeral: FormControl<string>;
  emailFaturamento: FormControl<string>;
}

export class FornecedorForm extends BaseForm {
  form: FormGroup<IFornecedorForm>;
  contatoForm: FormGroup<IContatoForm>;
  enderecoForm: FormGroup<IEnderecoForm>;
  fornecedorProdutoForm: FormGroup<IFornecedorProdutoForm>;
  contatoEditado: any;
  contatoModalTitulo: string;
  enderecoEditado: any;
  enderecoModalTitulo: string;
  fornecedorCpfCnpj: string;
  labelCpfCnpj: string = 'CNPJ';
  labelNomeRazaoSocial: string = 'Razão social';
  labelRgIe: string = 'Inscrição estadual';
  mostrarContatoModal: boolean;
  mostrarEnderecoModal: boolean;
  mostrarProdutoVinculoModal: boolean;
  mostrarPlanoContasModal: boolean;
  operacaoForm: OperacaoForm;
  operacaoFormContato: OperacaoForm;
  operacaoFormEndereco: OperacaoForm;
  pessoaTipoSelecionada: PessoaTipo;
  pessoaId: string;
  mostrarProdutoBuscaRapidaModal: boolean;
  cpfReadonly: boolean;
  rgReadonly: boolean;
  pessoaTipo: number;
  lancamentosParcela: LancamentoParcela[];
  produtoVinculadoModalTitulo: string;
  operacaoFormProdutoVinculado: OperacaoForm;
  produtoVinculadoEditado: {index: number};

  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  get operacaoFormEditar() {
    return this.operacaoForm === OperacaoForm.Editar;
  }
  get titulo() {
    return this.operacaoFormAdicionar ? 'Novo fornecedor' : 'Editar fornecedor';
  }
  get pessoaJuridica() {
    return this.pessoaTipoSelecionada === PessoaTipo.Juridica;
  }
  get contatosFormArray() {
    return this.form.controls.contatos;
  }
  get enderecosFormArray() {
    return this.form.controls.enderecos;
  }
  get produtosFormArray() {
    return this.form.controls.produtos;
  }
  get contatos() {
    return this.form.controls.contatos.value as Contato[];
  }
  get enderecos() {
    return this.form.controls.enderecos.value as Endereco[];
  }
  get produtos() {
    return this.form.controls.produtos.value as [];
  }
  get operacaoFormEnderecoAdicionar() {
    return this.operacaoFormEndereco === OperacaoForm.Adicionar;
  }
  get mostrarDataCriacao() {
    return this.form.controls?.createdOn?.value;
  }
  get mostrarDataAlteracao() {
    return this.form.controls?.lastModifiedOn?.value;
  }
  get mostrarCriadoPor() {
    return this.form.controls?.createdBy?.value;
  }
  get contemUsuarioCriadoPor() {
    return this.form.controls?.nomeCreatedBy?.value?.length > 0;
  }
  get maxlength() {
    return this.form.controls.pessoaTipo.value == PessoaTipo.Juridica ? 18 : 14;
  }
  get maxlengthCount() {
    return this.form.controls.pessoaTipo.value == PessoaTipo.Juridica ? 14 : 11;
  }
  get isDirty() {
    return this.form.dirty;
  }
  constructor(
      private apiServicesCoreFacade: ApiServicesFacade,
      private enderecoTipoFacade: EnderecoTipoFacade,
      private fornecedorFacade: FornecedorFacade,
      private pessoaFacade: PessoaFacade, private produtoFacade: ProdutoFacade,
      private parametroFacade: ParametroFacade,
      private usuarioFacade: UsuarioFacade,
      private toastrService: ToastrService,
      private errorMessagesFacade: ErrorMessageFacade,
      private cepFacade: CepFacade) {
    super();
  }
  private async buscarDadosPorCnpj(documento: string) {
    const nomeRazaoSocial = this.form.controls.nomeRazaoSocial;
    const fantasia = this.form.controls.nomeFantasia;

    const dadosCnpj = DadosCnpj.criarDadosCnpj(
        await this.apiServicesCoreFacade.buscarCnpj(documento));

    if (dadosCnpj?.status) {
      dadosCnpj.result.nome = dadosCnpj.result.nome.substring(0, 60);
      dadosCnpj.result.fantasia = dadosCnpj.result.fantasia.substring(0, 60);

      nomeRazaoSocial.setValue(dadosCnpj.result.nome);
      fantasia.setValue(
          dadosCnpj.result.fantasia ? dadosCnpj.result.fantasia :
                                      dadosCnpj.result.nome);

      this.contatosFormArray.clear();

      let telefone = dadosCnpj.result.telefone?.somenteNumeros();

      if (telefone && telefone.length <= 11) {
        this.adicionarContatoFormGroup(<Contato>{
          email: dadosCnpj.result.email.toLowerCase(),
          ativo: true,
          principal: this.contatosFormArray.length <= 0,
          fone1: telefone,
          nome: dadosCnpj.result.nome.split(' ')[0],
        });
      }
      const endereco = await this.cepFacade.consultarCep(
          dadosCnpj.result.cep.somenteNumeros());

      this.enderecosFormArray.clear();
      this.enderecoTipoFacade.enderecoTipos.forEach((enderecoTipo) => {
        const ufIbge =
            Estados.find((e) => e.ufSigla === dadosCnpj.result.uf).ufIbge;

        this.adicionarEnderecoFormGroup(<any>{
          enderecoTipoId: enderecoTipo.enderecoTipoId,
          cep: dadosCnpj.result.cep?.somenteNumeros(),
          logradouro: dadosCnpj.result.logradouro?.toUpperCase(),
          numero: dadosCnpj.result.numero,
          bairro: dadosCnpj.result.bairro?.toUpperCase(),
          cidade: dadosCnpj.result.municipio?.toUpperCase(),
          cidadeIbge: Number(endereco.ibge),
          ufIbge: ufIbge,
          uf: dadosCnpj.result.uf,
          ativo: true,
          principal: this.enderecosFormArray.length <= 0,
        });
      });
    } else {
      this.toastrService.error('Informe um CNPJ válido');
    }
  }
  private async buscarDadosPorCpf(documento: string) {
    const nomeRazaoSocial = this.form.controls.nomeRazaoSocial;
    const dadosCpf = DadosCpf.criarDadosCpf(
        await this.apiServicesCoreFacade.buscarCpf(documento));
    if (dadosCpf?.status) {
      nomeRazaoSocial.setValue(dadosCpf.result.nome_da_pf?.toUpperCase());
      this.contatosFormArray.clear();
      this.enderecosFormArray.clear();
    } else {
      this.toastrService.error('Informe um CPF válido');
    }
  }
  private async verificarDocumentoExiste(
      documento: string, pessoaTipo: PessoaTipo) {
    if (documento && this.parametroFacade.validarDocumento) {
      let retorno: DadosCnpj|DadosCpf = pessoaTipo === PessoaTipo.Fisica ?
          await this.apiServicesCoreFacade.buscarCpf(documento) :
          await this.apiServicesCoreFacade.buscarCnpj(documento);

      return retorno?.status;
    }

    return Promise.resolve(true);
  }
  private adicionarFornecedorProdutoFormGroup(fornecedorProduto:
                                                  FornecedorProduto) {
    this.produtosFormArray.push(
        this.criarFornecedorProdutoFormGroup(fornecedorProduto));
  }
  private preencherFornecedorProduto() {
    return FornecedorProduto.from(this.fornecedorProdutoForm.value);
  }
  private aplicarValidacoesPessoaFisica() {
    let documentoValidators = <ValidatorFn[]>[];

    if (this.parametroFacade.validarDocumento) {
      documentoValidators.push(CustomValidators.documentoTamanhoMinimo(
          'Tamanho do documento é inválido'));
      documentoValidators.push(
          CustomValidators.documentoValido('Documento inválido'));
    }
    if (this.parametroFacade.documentoObrigatorio) {
      documentoValidators.push(
          CustomValidators.required('Documento é obrigatório'));
    }

    super.adicionarValidator('documento', documentoValidators, this.form);
    super.removerValidator('nomeFantasia', this.form);
  }
  private aplicarValidacoesPessoaJuridica() {
    let documentoValidators = <ValidatorFn[]>[];
    let nomeFantasiaValidators = <ValidatorFn[]>[
      CustomValidators.required('Nome fantasia é obrigatório'),
    ];

    if (this.parametroFacade.validarDocumento) {
      documentoValidators.push(CustomValidators.documentoTamanhoMinimo(
          'Tamanho do documento é inválido'));
      documentoValidators.push(
          CustomValidators.documentoValido('Documento inválido'));
    }
    if (this.parametroFacade.documentoObrigatorio) {
      documentoValidators.push(
          CustomValidators.required('Documento é obrigatório'));
    }

    super.adicionarValidator('documento', documentoValidators, this.form);
    super.adicionarValidator('nomeFantasia', nomeFantasiaValidators, this.form);
  }
  private aplicarValidacoesFornecedorProduto() {
    this.fornecedorProdutoForm.controls.produtoDescricao.addValidators(
        CustomValidators.required('Fornecedor é obrigatório'));
  }
  private adicionarProdutoFormGroup() {
    this.produtosFormArray.push(this.criarFornecedorProdutoFormGroup());
  }
  async buscarDadosPorDocumento(documento: string) {
    if (documento.length == 11) {
      this.buscarDadosPorCpf(documento);
    } else if (documento.length == 14) {
      this.buscarDadosPorCnpj(documento);
    }
  }
  async buscarRegistrosFinanceiros() {
    if (this?.lancamentosParcela?.length > 0) return;

    var registrosFinanceiros =
        await this.fornecedorFacade.buscarRegistrosFinanceiros(
            this.form.controls.id.value);
    this.lancamentosParcela = registrosFinanceiros;
  }
  async consultarDocumentoValido() {
    const documento = this.form.controls.documento.value?.somenteNumeros();

    if (!documento) return;
    if (documento.length != 11 && documento.length != 14) return;

    const fornecedor =
        await this.fornecedorFacade.buscarPorDocumento(documento);

    if (fornecedor) {
      this.toastrService.error('Fornecedor já cadastrado com este documento');
      return;
    }

    if (this.operacaoFormAdicionar) {
      const pessoa =
          await this.pessoaFacade.buscarPorDocumento(documento).catch(
              () => null);
      if (pessoa) {
        this.preencherPessoaExistente(Pessoa.from(pessoa));
        return;
      }
    }

    this.buscarDadosPorDocumento(documento);
  }
  async isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }

    if (this.form.controls.documento.value) {
      const documento = this.form.controls.documento.value?.somenteNumeros();

      const documentoExiste = await this.verificarDocumentoExiste(
          documento,
          this.form.controls.pessoaTipo.value,
      );

      if (!documentoExiste) {
        this.mostrarMensagemDocumentoInvalido();
        return false;
      }
      const fornecedorCadastrado =
          await this.fornecedorFacade.buscarPorDocumento(documento);

      if (fornecedorCadastrado &&
          documento !== this.fornecedorCpfCnpj?.somenteNumeros()) {
        this.mostrarMensagemFornecedorCadastrado();
        return false;
      }
    }
    return true;
  }
  async preencherForm(fornecedor: Fornecedor) {
    this.fornecedorCpfCnpj = fornecedor?.documento;
    if (fornecedor?.enderecos?.length > 0) {
      fornecedor.enderecos.orderByDescending((p) => p.principal);
      fornecedor.enderecos.forEach((e) => {
        this.adicionarEnderecoFormGroup(e);
      });
    }
    if (fornecedor?.contatos?.length > 0) {
      fornecedor.contatos.orderByDescending((p) => p.principal);
      fornecedor.contatos.forEach((c) => {
        this.adicionarContatoFormGroup(c);
      });
    }
    if (fornecedor?.produtos?.length > 0) {
      await Promise.all(fornecedor.produtos.map(
          async (fornecedorProduto: FornecedorProduto) => {
            this.adicionarProdutoFormGroup();
            const produto =
                await this.produtoFacade.buscarProdutoPorProdutoVariacaoId(
                    fornecedorProduto.produtoVariacaoId);
            if (produto) {
              fornecedorProduto.produtoDescricao = produto.descricao;
              fornecedorProduto.produtoCodigoBarras =
                  produto.variacoes.first().codigoBarras;
            }
          }));
    }
    if (fornecedor.createdBy) {
      let usuario =
          await this.usuarioFacade.buscarPorChave(fornecedor.createdBy);
      fornecedor.nomeCreatedBy = usuario.nome;
    }

    this.lancamentosParcela = fornecedor.lancamentosParcela;
    this.rgReadonly = fornecedor.rgIe?.length > 0;
    this.cpfReadonly = fornecedor.documento?.length > 0;

    this.form.patchValue(fornecedor as any);
  }
  adicionarEnderecoFormGroup(endereco?: Endereco) {
    this.enderecosFormArray.push(this.criarEnderecoFormGroup(endereco));
  }
  adicionarContatoFormGroup(contato?: Contato) {
    this.contatosFormArray.push(this.criarContatoFormGroup(contato));
  }
  criarForm() {
    this.form = new FormGroup<IFornecedorForm>({
      pessoaTipo: new FormControl(
          null, CustomValidators.required('Tipo de pessoa é obrigatório')),
      documento: new FormControl(null),
      nomeRazaoSocial: new FormControl(
          null, CustomValidators.required('Nome/Razão Social é obrigatório')),
      atividadeTipo: new FormControl(null),
      ativo: new FormControl(true),
      id: new FormControl(null),
      ieIndicador: new FormControl(null),
      nomeFantasia: new FormControl(null),
      observacao: new FormControl(null),
      regimeTributario: new FormControl(null),
      compraAutorizar: new FormControl(false),
      compraValorMinimo: new FormControl(null),
      rgIe: new FormControl(null),
      contatos: new FormArray([]),
      enderecos: new FormArray([]),
      produtos: new FormArray([]),
      createdOn: new FormControl(null),
      lastModifiedOn: new FormControl(null),
      createdBy: new FormControl(null),
      nomeCreatedBy: new FormControl(null),
      planoConta3Id: new FormControl(null),
      whatsApp: new FormControl(null),
      telefoneFixo: new FormControl(null),
      vendedorPadrao: new FormControl(null),
      emailGeral: new FormControl(null),
      emailFaturamento: new FormControl(null),
    });
  }
  criarContatoFormGroup(contato?: Contato, index?: number) {
    if (index >= 0) {
      this.operacaoFormContato = OperacaoForm.Editar;
      this.contatoEditado = {
        index: index,
        control: this.contatosFormArray.controls[index],
      };
    }
    return ContatoForm.criarForm(contato);
  }
  criarEnderecoFormGroup(endereco?: Endereco, index?: number) {
    if (index >= 0) {
      this.operacaoFormEndereco = OperacaoForm.Editar;
      this.enderecoEditado = {
        index: index,
        control: this.enderecosFormArray.controls[index],
      };
    }
    return EnderecoForm.from(endereco);
  }
  criarFornecedorProdutoFormGroup(
      fornecedorProduto?: FornecedorProduto, index?: number) {
    if (index >= 0) {
      this.operacaoFormProdutoVinculado = OperacaoForm.Editar;
      this.produtoVinculadoEditado = {index: index};
    }

    return FornecedorProdutoForm.criarForm(fornecedorProduto);
  }
  excluirFornecedorProduto(index: number) {
    this.produtosFormArray.removeAt(index);
    this.form.markAsDirty();
  }
  monitorarRegimeTributario() {
    this.form.controls.regimeTributario.valueChanges.subscribe(
        (item: number) => {
          if (item === 1) {
            this.form.controls.ieIndicador.setValue(
                IeIndicador.NaoContribuinte);
          }
        });
  }
  monitorarPessoaTipo() {
    this.form.controls.pessoaTipo.valueChanges.subscribe((pessoaTipo) => {
      // if (this.pessoaTipo >= 0 &&
      //     this.form.controls?.pessoaTipo?.value != this.pessoaTipo) {
      //   this.form.controls.documento.setValue('');
      // }
      if (pessoaTipo === PessoaTipo.Juridica) {
        this.pessoaTipoSelecionada = PessoaTipo.Juridica;
        this.labelCpfCnpj = 'CNPJ';
        this.labelRgIe = 'Inscrição estadual';
        this.labelNomeRazaoSocial = 'Razão social';
        this.aplicarValidacoesPessoaJuridica();
      } else {
        this.pessoaTipoSelecionada = PessoaTipo.Fisica;
        this.labelCpfCnpj = 'CPF';
        this.labelRgIe = 'RG';
        this.labelNomeRazaoSocial = 'Nome';
        this.form.controls.regimeTributario.setValue(null);
        this.form.controls.atividadeTipo.setValue(null);
        this.form.controls.ieIndicador.setValue(null);
        this.aplicarValidacoesPessoaFisica();
      }
      this.pessoaTipo = pessoaTipo;
    });
  }
  mostrarMensagemDocumentoInvalido() {
    this.toastrService.error('Informe um documento válido.');
  }
  preencherPessoaExistente(pessoa: Pessoa) {
    if (pessoa.enderecos) {
      this.enderecosFormArray.clear();
      pessoa.enderecos.orderByDescending((p) => p.principal);
      pessoa.enderecos.forEach((e) => {
        this.adicionarEnderecoFormGroup(e);
      });
    }
    if (pessoa.contatos) {
      this.contatosFormArray.clear();
      pessoa.contatos.orderByDescending((p) => p.principal);
      pessoa.contatos.forEach((c) => {
        this.adicionarContatoFormGroup(c);
      });
    }

    this.pessoaId = pessoa.id;
    this.form.patchValue(pessoa);
  }
  mostrarMensagemFornecedorCadastrado() {
    this.toastrService.error('Fornecedor já cadastrado com este documento');
  }
  setarPessoaJuridica() {
    this.form.controls.pessoaTipo.setValue(PessoaTipo.Juridica);
  }
  async setarTipoFormulario(fornecedor?: Fornecedor) {
    if (fornecedor) {
      this.operacaoForm = OperacaoForm.Editar;
      await this.preencherForm(Fornecedor.from(fornecedor));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
      this.setarPessoaJuridica();
    }
  }
  abrirContatoModal(contato?: Contato, index?: number) {
    this.contatoForm = this.criarContatoFormGroup(contato, index);
    if (contato) {
      this.contatoModalTitulo = 'Editar contato';
      this.operacaoFormContato = OperacaoForm.Editar;
    } else {
      this.contatoModalTitulo = 'Novo contato';
      this.operacaoFormContato = OperacaoForm.Adicionar;
    }
    this.mostrarContatoModal = true;
  }
  abrirEnderecoModal(endereco?: Endereco, index?: number) {
    this.enderecoForm = this.criarEnderecoFormGroup(endereco, index);
    if (endereco) {
      this.enderecoModalTitulo = 'Editar endereço';
      this.operacaoFormEndereco = OperacaoForm.Editar;
    } else {
      this.enderecoModalTitulo = 'Novo endereço';
      this.operacaoFormEndereco = OperacaoForm.Adicionar;
    }
    this.mostrarEnderecoModal = true;
  }
  abrirProdutoBuscaRapidaModal() {
    this.mostrarProdutoBuscaRapidaModal = true;
  }
  abrirFornecedorProdutoModal(
      fornecedorProduto?: FornecedorProduto, index?: number) {
    this.fornecedorProdutoForm =
        this.criarFornecedorProdutoFormGroup(fornecedorProduto, index);

    this.aplicarValidacoesFornecedorProduto();

    if (fornecedorProduto) {
      this.produtoVinculadoModalTitulo = 'Editar Produto';
      this.operacaoFormProdutoVinculado = OperacaoForm.Editar;
    } else {
      this.produtoVinculadoModalTitulo = 'Novo Produto';
      this.operacaoFormProdutoVinculado = OperacaoForm.Adicionar;
    }
    this.mostrarProdutoVinculoModal = true;
  }
  salvarFornecedorProduto() {
    if (this.fornecedorProdutoForm.valid) {
      const fornecedorProduto = this.preencherFornecedorProduto();
      if (this.operacaoFormProdutoVinculado === OperacaoForm.Adicionar) {
        this.adicionarFornecedorProdutoFormGroup(fornecedorProduto);
      } else {
        const index = this.produtoVinculadoEditado.index;
        this.produtosFormArray.removeAt(index);
        this.produtosFormArray.insert(
            index, this.criarFornecedorProdutoFormGroup(fornecedorProduto));
      }
      this.form.markAsDirty();
      this.fecharFornecedorProdutoModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(
          this.fornecedorProdutoForm);
    }
  }
  abrirPlanoContasCadastroRapidoModal() {
    this.mostrarPlanoContasModal = true;
  }
  fecharPlanoContasCadastroRapidoModal() {
    this.mostrarPlanoContasModal = false;
  }
  fecharEnderecoModal() {
    this.mostrarEnderecoModal = false;
  }
  fecharFornecedorProdutoModal() {
    this.mostrarProdutoVinculoModal = false;
  }
  fecharProdutoBuscaRapidaModal() {
    this.mostrarProdutoBuscaRapidaModal = false;
  }
  fecharContatoModal() {
    this.mostrarContatoModal = false;
  }
  salvarContato(form: FormGroup<IContatoForm>) {
    if (form.valid) {
      const contato = Contato.from(form.value);
      if (this.contatosFormArray.length == 0 ||
          this.contatoEditado?.index == 0) {
        contato.principal = true;
      }

      if (this.operacaoFormContato === OperacaoForm.Adicionar) {
        this.adicionarContatoFormGroup(contato);
      } else {
        const index = this.contatoEditado.index;
        this.contatosFormArray.removeAt(index);
        this.contatosFormArray.insert(
            index, this.criarContatoFormGroup(contato));
      }
      this.form.markAsDirty();
      this.fecharContatoModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(form);
    }
  }
  salvarEndereco(form: FormGroup<IEnderecoForm>) {
    const endereco = Endereco.from(form.value);

    if ((endereco.cep.length == 0 && endereco.logradouro.length == 0 &&
         endereco.numero.length == 0 && endereco.bairro.length == 0 &&
         endereco.cidade.length == 0 && !endereco.ufIbge) ||
        !endereco.enderecoTipoId)
      this.toastrService.warning(
          'Selecione o tipo de endereço e preencha algum outro campo para salvar!');
    else {
      if (this.enderecosFormArray.length == 0 ||
          this.enderecoEditado?.index == 0) {
        endereco.principal = true;
      }

      if (endereco.ufIbge)
        endereco.uf = Estados.find((p) => p.ufIbge === endereco.ufIbge).ufSigla;

      if (this.operacaoFormEndereco === OperacaoForm.Adicionar) {
        if (this.enderecoTipoFacade.enderecoTipos
                .filter((p) => p.enderecoTipoId === endereco.enderecoTipoId)
                .length <= 0) {
          this.enderecoTipoFacade.enderecoTipos.forEach(
              (enderecoTipo, index) => {
                endereco.enderecoTipoId = enderecoTipo.enderecoTipoId;
                if (index > 0) {
                  endereco.principal = false;
                }
                this.adicionarEnderecoFormGroup(endereco);
              });
        } else {
          this.adicionarEnderecoFormGroup(endereco);
        }
      } else {
        const index = this.enderecoEditado.index;
        this.enderecosFormArray.removeAt(index);
        this.enderecosFormArray.insert(
            index, this.criarEnderecoFormGroup(endereco));
      }
      this.form.markAsDirty();
      this.fecharEnderecoModal();
    }
  }
  setarProdutoSelecionado(produtoSelecionado: ProdutoSimplificadoListagem[]) {
    if (produtoSelecionado?.length) {
      this.fornecedorProdutoForm.controls.produtoVariacaoId.setValue(
          produtoSelecionado.first().variacaoId);
      this.fornecedorProdutoForm.controls.produtoDescricao.setValue(
          produtoSelecionado.first().descricaoCompleta);
      this.fornecedorProdutoForm.controls.produtoCodigoBarras.setValue(
          produtoSelecionado.first().codigoBarras);

      this.fecharProdutoBuscaRapidaModal();
    }
  }
  preencherNomeFantasia(nomeFantasia: string) {
    if (this.pessoaJuridica)
      this.form.controls.nomeFantasia.setValue(nomeFantasia);
  }
  setarPlanoContasCadastrado(id: string) {
    this.form.controls.planoConta3Id.setValue(id);
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
}
