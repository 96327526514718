import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-checkbox-input-ngmodel',
  templateUrl: './checkbox-input-ngmodel.component.html',
  styleUrls: ['./checkbox-input-ngmodel.component.scss']
})
export class CheckboxInputNgModelComponent {
  @Input() model: any;
  @Input() modelOptions: any;
  @Input() for: string;
  @Input() readonly: boolean;
  @Input() label: string;
  @Output() modelChange = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;

  onNgModelChange(event: any) {
    this.modelChange.emit(event);
  }
}
