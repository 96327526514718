<p-tabView [scrollable]="true" data-cy="tabview-dados-cadastrais">
  <p-tabPanel header="Dados Cadastrais">
    <div class="formgrid grid">
      <!-- #region CODIGO NCM -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6">
        <app-custom-label texto="Código NCM" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          mask="00000000"
          [autoFocus]="ncmForm?.operacaoFormAdicionar"
          [readonly]="ncmForm?.operacaoFormEditar"
          [control]="ncmForm?.form.controls.ncmCodigo"
          [maxlength]="8"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region EXCECAO TIPI -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6">
        <app-custom-label texto="Exceção TIPI" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [maxlength]="2"
          [readonly]="ncmForm?.operacaoFormEditar"
          [control]="ncmForm?.form.controls.ncmExcecaoTipi"
        ></app-text-input>
      </div>
      <!-- #endregion -->
    </div>

    <p-tabView [scrollable]="true" data-cy="tabview-tributacao">
      <p-tabPanel header="Tributação por estado">
        <div class="flex flex-wrap justify-content-between mb-2">
          <p-button
            type="button"
            styleClass="btn-five"
            label="Nova tributação"
            tooltipPosition="bottom"
            pTooltip="Nova tributação"
            data-cy="btn-nova-tributacao"
            icon="pi pi-plus"
            (onClick)="abrirNcmUfModal()"
          ></p-button>
          <p-multiSelect
            *ngIf="colunasNcmUfOcultas.length > 0"
            styleClass=" inputfield w-full ml-auto"
            [options]="colunasNcmUfOcultas"
            optionLabel="name"
            selectedItemsLabel="{0} itens selecionados"
            placeholder="Mostrar colunas"
            emptyMessage="Nenhum item encontrado"
            emptyFilterMessage="Nenhum item encontrado"
            (onChange)="mostraOcultarColunaNcmUf($event)"
          >
          </p-multiSelect>
        </div>
        <div class="borda-tabela">
          <app-table [columns]="colunasNcmUf" [rows]="ncmUf">
            <ng-template
              #acoesNcmUfTemp
              let-rowData="rowData"
              let-rowIndex="rowIndex"
            >
              <p-button
                tooltipPosition="bottom"
                pTooltip="Editar"
                icon="pi pi-pencil"
                (onClick)="abrirNcmUfModal(rowData, rowIndex)"
                [text]="true"
                [plain]="true"
              ></p-button>
              <p-button
                tooltipPosition="bottom"
                pTooltip="Excluir"
                icon="pi pi-trash"
                (onClick)="abrirConfirmacaoExcluisaoNcmUf(rowIndex)"
                [text]="true"
                [plain]="true"
              ></p-button>
            </ng-template>
          </app-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="ICMS">
        <div class="flex flex-wrap justify-content-between mb-2">
          <p-button
            type="button"
            styleClass="btn-five"
            label="Nova tributação de ICMS"
            tooltipPosition="bottom"
            pTooltip="Nova tributação de ICMS"
            icon="pi pi-plus"
            data-cy="btn-nova-tributacao-icms"
            (onClick)="abrirNcmIcmsModal()"
          ></p-button>
          <p-multiSelect
            *ngIf="colunasNcmIcmsOcultas.length > 0"
            styleClass=" inputfield w-full ml-auto"
            [options]="colunasNcmIcmsOcultas"
            optionLabel="name"
            selectedItemsLabel="{0} itens selecionados"
            placeholder="Mostrar colunas"
            emptyMessage="Nenhum item encontrado"
            emptyFilterMessage="Nenhum item encontrado"
            (onChange)="mostraOcultarColunaNcmIcms($event)"
          >
          </p-multiSelect>
        </div>
        <div class="borda-tabela">
          <app-table [columns]="colunasNcmIcms" [rows]="ncmIcms">
            <ng-template
              #acoesNcmIcmsTemp
              let-rowData="rowData"
              let-rowIndex="rowIndex"
            >
              <p-button
                tooltipPosition="bottom"
                pTooltip="Editar"
                icon="pi pi-pencil"
                (onClick)="abrirNcmIcmsModal(rowData, rowIndex)"
                [text]="true"
                [plain]="true"
              ></p-button>
              <p-button
                tooltipPosition="bottom"
                pTooltip="Excluir"
                icon="pi pi-trash"
                (onClick)="abrirConfirmacaoExcluisaoNcmIcms(rowIndex)"
                [text]="true"
                [plain]="true"
              ></p-button>
            </ng-template>
          </app-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="PIS/COFINS/IPI">
        <p-accordion [multiple]="true" data-cy="accordion-pis-cofins-ipi">
          <p-accordionTab header="PIS/COFINS (Entrada - Saída)">
            <div class="formgrid grid">
              <!-- #region TIPO PIS/COFINS -->
              <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                <app-custom-label texto="Tipo PIS/COFINS"></app-custom-label>
                <app-dropdown-input
                  [options]="produtoTiposPisCofins"
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls
                      .pisCofinsTipo
                  "
                ></app-dropdown-input>
              </div>
              <!-- #endregion -->

              <!-- #region PIS ALIQUOTA -->
              <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                <app-custom-label texto="PIS Alíquota (%)"></app-custom-label>
                <app-numeric-input
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls.pisAliquota
                  "
                  [placeholder]="porcentagemPlaceholder"
                  [options]="porcentagemMask"
                >
                </app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region COFINS ALIQUOTA -->
              <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                <app-custom-label texto="COFINS Alíquota (%)">
                </app-custom-label>
                <app-numeric-input
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls
                      .cofinsAliquota
                  "
                  [placeholder]="porcentagemPlaceholder"
                  [options]="porcentagemMask"
                >
                </app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region NATUREZA RECEITA -->
              <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
                <app-custom-label
                  texto="Natureza da receita"
                  (aoClicarAdicionar)="
                    abrirNaturezaReceitaCadastroRapidoModal()
                  "
                >
                </app-custom-label>
                <app-numeric-input
                  [maxlength]="10"
                  [options]="inteiroMask"
                  iconButton="pi pi-search"
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls
                      .naturezaReceitaCodigo
                  "
                  [showButton]="true"
                  (buttonOnClick)="abrirNaturezaReceitaBuscaRapidaModal()"
                >
                </app-numeric-input>
              </div>
              <!-- #endregion -->

              <!-- #region CST PIS/COFINS ENTRADA -->
              <div class="field col-12">
                <app-custom-label texto="CST PIS/COFINS Entrada">
                </app-custom-label>
                <app-dropdown-input
                  [options]="produtoCstsPisCofinsEntrada"
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls
                      .pisCofinsCstEntrada
                  "
                  [showFilter]="true"
                  [showClear]="true"
                >
                </app-dropdown-input>
              </div>
              <!-- #endregion -->

              <!-- #region CST PIS/COFINS SAIDA -->
              <div class="field col-12">
                <app-custom-label texto="CST PIS/COFINS Saida">
                </app-custom-label>
                <app-dropdown-input
                  [options]="produtoCstsPisCofinsSaida"
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls
                      .pisCofinsCstSaida
                  "
                  [showFilter]="true"
                  [showClear]="true"
                >
                </app-dropdown-input>
              </div>
              <!-- #endregion -->

              <!-- #region CCS APURADA -->
              <div class="field col-12">
                <app-custom-label texto="CCS Apurada" [obrigatorio]="true">
                </app-custom-label>
                <app-dropdown-input
                  [options]="produtoCcsApuradas"
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls.ccsApurada
                  "
                  [showFilter]="true"
                  [showClear]="true"
                >
                </app-dropdown-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
          <p-accordionTab header="IPI">
            <div class="formgrid grid">
              <!-- #region ALIQUOTA IPI -->
              <div class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6">
                <app-custom-label texto="Alíquota IPI (%)" [obrigatorio]="true">
                </app-custom-label>
                <app-numeric-input
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls.ipiAliquota
                  "
                  [placeholder]="porcentagemPlaceholder"
                  [options]="porcentagemMask"
                >
                </app-numeric-input>
              </div>
              <!-- #endregion -->
            </div>
            <div class="formgrid grid">
              <!-- #region CST ENTRADA IPI -->
              <div class="field col-12">
                <app-custom-label texto="CST Entrada IPI" [obrigatorio]="true">
                </app-custom-label>
                <app-dropdown-input
                  [options]="produtoCstsIpiEntrada"
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls
                      .ipiCstEntrada
                  "
                  [showFilter]="true"
                  [showClear]="true"
                >
                </app-dropdown-input>
              </div>
              <!-- #endregion -->

              <!-- #region CST SAIDA IPI -->
              <div class="field col-12">
                <app-custom-label texto="CST Saida IPI" [obrigatorio]="true">
                </app-custom-label>
                <app-dropdown-input
                  [options]="produtoCstsIpiSaida"
                  [control]="
                    ncmForm?.form.controls.ncmPisCofinsIpi.controls.ipiCstSaida
                  "
                  [showFilter]="true"
                  [showClear]="true"
                >
                </app-dropdown-input>
              </div>
              <!-- #endregion -->
            </div>
          </p-accordionTab>
        </p-accordion>
      </p-tabPanel>
    </p-tabView>
  </p-tabPanel>
</p-tabView>

<p-dialog
  *ngIf="ncmForm?.ncmUfForm"
  [header]="ncmForm?.ncmUfModalTitulo"
  [style]="{ width: isDesktop ? '60%' : '90%' }"
  [(visible)]="ncmForm.mostrarNcmUfModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharNcmUfModal()"
>
  <div class="formgrid grid">
    <!-- #region ESTADO -->
    <div class="field col-12 xl:col-3 md:col-6">
      <app-custom-label texto="Estado" [obrigatorio]="true"></app-custom-label>
      <app-dropdown-input
        [options]="estadosUf"
        [control]="ncmForm?.ncmUfForm.controls.uf"
        [showFilter]="true"
      >
      </app-dropdown-input>
    </div>
    <!-- #endregion -->
  </div>
  <div class="formgrid grid">
    <!-- #region IMPORTADOS -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
      <app-custom-label texto="Importados (%)"></app-custom-label>
      <app-numeric-input
        [control]="ncmForm?.ncmUfForm.controls.importadosFederal"
        [placeholder]="porcentagemPlaceholder"
        [options]="porcentagemMask"
      >
      </app-numeric-input>
    </div>
    <!-- #endregion -->

    <!-- #region NACIONAL -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
      <app-custom-label texto="Nacional (%)"></app-custom-label>
      <app-numeric-input
        [control]="ncmForm?.ncmUfForm.controls.nacionalFederal"
        [placeholder]="porcentagemPlaceholder"
        [options]="porcentagemMask"
      >
      </app-numeric-input>
    </div>
    <!-- #endregion -->

    <!-- #region ESTADUAL -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
      <app-custom-label texto="Estadual (%)"></app-custom-label>
      <app-numeric-input
        [control]="ncmForm?.ncmUfForm.controls.estadual"
        [placeholder]="porcentagemPlaceholder"
        [options]="porcentagemMask"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->

    <!-- #region MUNICIPAL -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
      <app-custom-label texto="Municipal (%)"></app-custom-label>
      <app-numeric-input
        [control]="ncmForm?.ncmUfForm.controls.municipal"
        [placeholder]="porcentagemPlaceholder"
        [options]="porcentagemMask"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="fecharNcmUfModal()"
      label="Cancelar"
      [text]="true"
      data-cy="btn-cancelar-ncm-uf"
    ></p-button>
    <p-button
      (onClick)="salvarNcmUf()"
      label="Salvar"
      styleClass="btn-five"
      data-cy="btn-salvar-ncm-uf"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf="ncmForm?.ncmIcmsForm"
  [header]="ncmForm?.ncmIcmsModalTitulo"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="ncmForm.mostrarNcmIcmsModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharNcmIcmsModal()"
>
  <div class="formgrid grid">
    <!-- #region ESTADO -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
      <app-custom-label texto="Estado" [obrigatorio]="true"></app-custom-label>
      <app-dropdown-input
        [options]="estadosUf"
        [control]="ncmForm?.ncmIcmsForm.controls.uf"
        [showFilter]="true"
      ></app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region CEST -->
    <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
      <app-custom-label texto="CEST"></app-custom-label>
      <app-numeric-input
        [options]="inteiroMask"
        [maxlength]="7"
        iconButton="pi pi-search"
        [control]="ncmForm?.ncmIcmsForm.controls.cestCodigo"
        [showButton]="true"
        (buttonOnClick)="abrirCestBuscaRapidaModal()"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->
  </div>
  <p-accordion [multiple]="true">
    <p-accordionTab header="CST/CSOSN ICMS" [selected]="true">
      <div class="formgrid grid">
        <!-- #region ORIGEM PRODUTO -->
        <div class="field col-12 xl:col-6 lg:col-6">
          <app-custom-label texto="Origem Produto" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            [options]="produtoOrigens"
            [control]="ncmForm?.ncmIcmsForm.controls.origem"
            [showFilter]="true"
            [cortarTexto]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region PRODUTO/SERVIÇO -->
        <div class="field col-12 xl:col-6 lg:col-6">
          <app-custom-label texto="Produto/Serviço" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            [options]="produtosServicos"
            [control]="ncmForm?.ncmIcmsForm.controls.produtoServico"
            [showFilter]="true"
            [cortarTexto]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region  CST/CSOSN ENTRADA ICMS -->
        <div class="field col-12 xl:col-6 lg:col-6">
          <app-custom-label texto="CST/CSOSN Entrada ICMS" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            [options]="produtoCsts"
            [control]="ncmForm?.ncmIcmsForm.controls.icmsCstCsosnEntrada"
            [showFilter]="true"
            [cortarTexto]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->

        <!-- #region  CST/CSOSN SAIDA ICMS -->
        <div class="field col-12 xl:col-6 lg:col-6">
          <app-custom-label texto="CST/CSOSN Saída ICMS" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            [options]="produtoCsts"
            [control]="ncmForm?.ncmIcmsForm.controls.icmsCstCsosnSaida"
            [showFilter]="true"
            [cortarTexto]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->
      </div>
    </p-accordionTab>
    <p-accordionTab header="ICMS Venda (Saída)" [selected]="true">
      <div class="formgrid grid">
        <!-- #region ALIQUOTA ICMS SAIDA -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
          <app-custom-label texto="Alíquota ICMS (%)"></app-custom-label>
          <app-numeric-input
            [control]="ncmForm?.ncmIcmsForm.controls.icmsAliquotaSaida"
            [placeholder]="porcentagemPlaceholder"
            [options]="porcentagemMask"
          ></app-numeric-input>
        </div>
        <!-- #endregion -->

        <!-- #region REDUÇÃO ICMS SAIDA -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
          <app-custom-label texto="Redução ICMS (%)"></app-custom-label>
          <app-numeric-input
            [control]="ncmForm?.ncmIcmsForm.controls.icmsAliquotaSaidaReducao"
            [placeholder]="porcentagemPlaceholder"
            [options]="porcentagemMask"
          ></app-numeric-input>
        </div>
        <!-- #endregion -->

        <!-- #region ALIQUOTA FINAL SAIDA -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
          <app-custom-label texto="Alíquota Final (%)"></app-custom-label>
          <app-numeric-input
            [control]="ncmForm?.ncmIcmsForm.controls.icmsAliquotaSaidaFinal"
            [placeholder]="porcentagemPlaceholder"
            [options]="porcentagemMask"
          ></app-numeric-input>
        </div>
        <!-- #endregion -->
      </div>
    </p-accordionTab>
    <p-accordionTab header="ICMS Compra (Entrada)" [selected]="true">
      <div class="formgrid grid">
        <!-- #region ALIQUOTA ICMS ENTRADA -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
          <app-custom-label texto="Alíquota ICMS (%)"></app-custom-label>
          <app-numeric-input
            [control]="ncmForm?.ncmIcmsForm.controls.icmsAliquotaEntrada"
            [placeholder]="porcentagemPlaceholder"
            [options]="porcentagemMask"
          ></app-numeric-input>
        </div>
        <!-- #endregion -->

        <!-- #region REDUCAO ICMS ENTRADA -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
          <app-custom-label texto="Redução ICMS (%)"></app-custom-label>
          <app-numeric-input
            [control]="ncmForm?.ncmIcmsForm.controls.icmsAliquotaEntradaReducao"
            [placeholder]="porcentagemPlaceholder"
            [options]="porcentagemMask"
          ></app-numeric-input>
        </div>
        <!-- #endregion -->

        <!-- #region ALIQUOTA IVA -->
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
          <app-custom-label texto="Alíquota IVA (%)"></app-custom-label>
          <app-numeric-input
            [control]="ncmForm?.ncmIcmsForm.controls.icmsAliquotaEntradaIva"
            [placeholder]="porcentagemPlaceholder"
            [options]="porcentagemMask"
          ></app-numeric-input>
        </div>
        <!-- #endregion -->
      </div>
    </p-accordionTab>
  </p-accordion>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="fecharNcmIcmsModal()"
      label="Cancelar"
      [text]="true"
      data-cy="btn-cancelar-tributacao-icms"
    ></p-button>
    <p-button
      (onClick)="salvarNcmIcms()"
      label="Salvar"
      styleClass="btn-five"
      data-cy="btn-salvar-tributacao-icms"
    ></p-button>
  </ng-template>
</p-dialog>

<ng-template #decimalTemp let-value="value">
  <span>{{ value | customDecimal : porcentagemDecimais }}</span>
</ng-template>

<app-cest-busca-rapida
  [mostrarModal]="ncmForm?.mostrarCestBuscaRapidaModal"
  [ncmCodigo]="ncmCodigo"
  (aoCancelar)="fecharCestBuscaRapidaModal()"
  (aoSelecionar)="setarCestSelecionado($event)"
>
</app-cest-busca-rapida>
<app-natureza-receita-busca-rapida
  [mostrarModal]="ncmForm?.mostrarNaturezaReceitaBuscaRapidaModal"
  (aoCancelar)="fecharNaturezaReceitaBuscaRapidaModal()"
  (aoSelecionar)="setarNaturezaReceitaSelecionado($event)"
>
</app-natureza-receita-busca-rapida>
<app-natureza-receita-cadastro-rapido-modal
  [mostrarModal]="ncmForm?.mostrarNaturezaReceitaCadastroRapidoModal"
  (aoCancelar)="fecharNaturezaReceitaCadastroRapidoModal()"
>
</app-natureza-receita-cadastro-rapido-modal>

<p-confirmDialog
  rejectButtonStyleClass="p-button-text"
  key="confirmacao-tributacao"
  class="confirmacao-content"
  [style]="{ width: '35%' }"
></p-confirmDialog>
