import { ContaBancariaListagem } from '@app/abstraction/domain/interfaces/financeiro/conta-bancaria/conta-bancaria-listagem.interface';

import { ContaBancariaBoleto } from './conta-bancaria-boleto.entity';

export class ContaBancaria {
  id: string;
  empresaId: string;
  nome: string;
  banco: string;
  agencia: string;
  digitoAgencia: string;
  conta: string;
  digitoConta: string;
  ativo: boolean;
  emiteBoleto: boolean;
  saldo: number;
  contaBancariaBoleto: ContaBancariaBoleto;
  static from(json: any = {}) {
    const contaBancaria = new ContaBancaria();
    contaBancaria.id = json.id;
    contaBancaria.empresaId = json.empresaId;
    contaBancaria.nome = json.nome?.trim();
    contaBancaria.banco = json.banco?.trim();
    contaBancaria.agencia = json.agencia?.trim();
    contaBancaria.digitoAgencia = json.digitoAgencia?.trim();
    contaBancaria.conta = json.conta?.trim();
    contaBancaria.digitoConta = json.digitoConta?.trim();
    contaBancaria.ativo = json.ativo;
    contaBancaria.emiteBoleto = json.emiteBoleto;
    contaBancaria.saldo = json.saldo;

    if (json.contaBancariaBoleto) {
      contaBancaria.contaBancariaBoleto = ContaBancariaBoleto.from(
        json.contaBancariaBoleto
      );
    }

    return contaBancaria;
  }
  toListagem() {
    return <ContaBancariaListagem>{
      id: this.id,
      empresaId: this.empresaId,
      nome: this.nome,
      banco: this.banco,
      agencia: this.agencia,
      digitoAgencia: this.digitoAgencia,
      conta: this.conta,
      digitoConta: this.digitoConta,
      ativo: this.ativo,
      emiteBoleto: this.emiteBoleto,
      saldo: this.saldo,
    };
  }
}
