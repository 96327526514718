import {Injectable} from '@angular/core';
import {NotaFiscalService} from '@app/abstraction/data/services/fiscal/nota-fiscal.service';
import {NotaFiscalStore} from '@app/abstraction/data/stores/fiscal/nota-fiscal.store';
import {NotaFiscal} from '@app/abstraction/domain/entities/fiscal/nota-fiscal/nota-fiscal.entity';
import {NotaFiscalFiltro} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-filtro.interface';
import {NotaFiscalResumoFiltro} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-resumo-filtro.interface';
import {CodigosManifestacao} from '@app/shared/enums/codigos-manifestacao.enum';
import * as moment from 'moment';
import {Message, MessageService} from 'primeng/api';

@Injectable({providedIn: 'root'})
export class NotaFiscalFacade {
  get notasFiscaisEntrada() {
    return this.store.notasFiscaisEntrada;
  }
  get notasFiscaisSaida() {
    return this.store.notasFiscaisSaida;
  }
  get notasFiscaisResumos() {
    return this.store.notasFiscaisResumos;
  }
  get ultimaAtualizacao() {
    return moment(localStorage.getItem('last_att'));
  }
  constructor(
      private store: NotaFiscalStore,
      private service: NotaFiscalService,
      private messageService: MessageService,
  ) {}
  async buscarNotasFiscaisEntrada(filtro?: NotaFiscalFiltro) {
    const notasFiscais = await this.service.buscarTodasDeEntrada(filtro);
    this.store.adicionarRangeEntrada(notasFiscais);
    return notasFiscais;
  }
  async buscarTotaisNotasFiscaisEntrada(filtro?: NotaFiscalFiltro) {
    return this.service.buscarTotaisEntrada(filtro);
  }
  async buscarNotasFiscaisSaida(filtro?: NotaFiscalFiltro) {
    const notasFiscais = await this.service.buscarTodasDeSaida(filtro);
    this.store.adicionarRangeSaida(notasFiscais);
    return notasFiscais;
  }
  async buscarNotasFiscaisResumos(filtro?: NotaFiscalResumoFiltro) {
    const notasFiscaisResumos =
        await this.service.buscarNotasFiscaisResumos(filtro);
    if (notasFiscaisResumos) {
      this.store.adicionarRangeNotasFiscaisResumo(notasFiscaisResumos);
    }
    return notasFiscaisResumos;
  }
  async verificarStatusSefaz() {
    this.messageService.clear();
    const status = await this.service.verificarStatusSefaz();
    if (status?.status == 107) {
      this.messageService.add(
          <Message>{
            key: 'message-closable',
            severity: 'success',
            detail: 'Serviço fiscal em operação.',
          },
      );
    } else {
      this.messageService.add(
          <Message>{
            key: 'message-closable',
            severity: 'error',
            detail: 'Serviço fiscal indisponível.',
          },
      );
    }
  }
  adicionarEntrada(notaFiscal: NotaFiscal) {
    return this.service.adicionarEntrada(notaFiscal);
  }
  adicionarSaida(notaFiscal: NotaFiscal) {
    return this.service.adicionarSaida(notaFiscal);
  }
  alterarEntrada(notaFiscal: NotaFiscal) {
    return this.service.alterarEntrada(notaFiscal);
  }
  alterarSaida(notaFiscal: NotaFiscal) {
    return this.service.alterarSaida(notaFiscal);
  }
  buscarCartasCorrecaoSaida(chaveEletronica: string) {
    return this.service.buscarCartasCorrecaoSaida(chaveEletronica);
  }
  buscarCancelamentosSaida(chaveEletronica: string) {
    return this.service.buscarCancelamentosSaida(chaveEletronica);
  }
  buscarRetornosSaida(id: string) {
    return this.service.buscarRetornosSaida(id);
  }
  buscarEntradaPorId(id: string) {
    return this.service.buscarEntradaPorId(id);
  }
  buscarEntradaPorChaveEletronica(chaveEletronica: string) {
    return this.service.buscarEntradaPorChaveEletronica(chaveEletronica);
  }
  buscarSaidaPorId(id: string) {
    return this.service.buscarSaidaPorId(id);
  }
  corrigirNotaFiscalSaida(notaFiscalId: string, justificativa: string) {
    return this.service.corrigirNotaFiscal(notaFiscalId, justificativa);
  }
  confirmarNotaFiscal(chaveEletronica: string) {
    return this.service.confirmarNotaFiscal(chaveEletronica);
  }
  darCienciaNotaFiscal(chaveEletronica: string) {
    return this.service.darCienciaNotaFiscal(chaveEletronica);
  }
  desconhecerNotaFiscal(chaveEletronica: string) {
    return this.service.desconhecerNotaFiscal(chaveEletronica);
  }
  excluirNotaFiscalSaida(id: string) {
    return this.service.excluirNotaFiscalSaida(id);
  }
  excluirNotaFiscalEntrada(id: string) {
    return this.service.excluirNotaFiscalEntrada(id);
  }
  cancelarNotaFiscalEntrada(id: string) {
    return this.service.cancelarNotaFiscalEntrada(id);
  }
  naoRealizarOperacao(chaveEletronica: string, justificativa: string) {
    return this.service.naoRealizarOperacao(chaveEletronica, justificativa);
  }
  consultarNotasFiscaisManifestador() {
    return this.service.consultarNotasFiscaisManifestador();
  }
  cancelarNotaFiscal(notaFiscalId: string, justificativa: string) {
    return this.service.cancelarNotaFiscal(notaFiscalId, justificativa);
  }
  emitirNotaFiscal(notaFiscalId: string, dataHoraLocal: Date) {
    return this.service.emitirNotaFiscal(notaFiscalId, dataHoraLocal);
  }
  enviarEmailNotaFiscalSaida(id: string, emailDestinatario: string) {
    return this.service.enviarEmailNotaFiscalSaida(id, emailDestinatario);
  }
  limparListaNotasFiscaisEntrada() {
    this.store.notasFiscaisEntrada = null;
  }
  limparListaNotasFiscaisSaida() {
    this.store.notasFiscaisSaida = null;
  }
  limparListaNotasFiscaisResumos() {
    this.store.notasFiscaisResumos = null;
  }
  inutilizarNotaFiscal(notaFiscalId: string, justificativa: string) {
    return this.service.inutilizarNotaFiscal(notaFiscalId, justificativa);
  }
  // HELPERS
  limparMessageService() {
    this.messageService.clear();
  }
  mostrarIpi(ipiCst: string) {
    return ipiCst == '00' || ipiCst == '01' || ipiCst == '50' || ipiCst == '51';
  }
  mostrarPisCofins(pisCofinsCst: string) {
    return pisCofinsCst;
  }
  mostrarIcms(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '00':
      case '10':
      case '20':
      case '51':
      case '70':
      case '90':
      case '900':
        return true;
      default:
        return false;
    }
  }
  mostrarIcmsSt(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '10':
      case '30':
      case '70':
      case '90':
      case '201':
      case '202':
      case '203':
      case '900':
        return true;
      default:
        return false;
    }
  }
  mostrarIcmsFcp(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '00':
      case '10':
      case '20':
      case '70':
      case '90':
        return true;
      default:
        return false;
    }
  }
  mostrarIcmsStRetido(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '60':
      case '500':
        return true;
      default:
        return false;
    }
  }
  mostrarIcmsStFcp(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '10':
      case '70':
      case '90':
      case '201':
      case '202':
      case '203':
      case '900':
        return true;
      default:
        return false;
    }
  }
  mostrarIcmsStFcpRetido(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '60':
      case '500':
        return true;
      default:
        return false;
    }
  }
  mostrarDiferimento(icmsCstCsosn: string) {
    return icmsCstCsosn == '51';
  }
  mostrarDesoneracao(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '20':
      case '30':
      case '40':
      case '41':
      case '50':
      case '70':
      case '90':
        return true;
      default:
        return false;
    }
  }
  mostrarCampoIvaMva(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '10':
      case '30':
      case '70':
      case '90':
      case '201':
      case '202':
      case '203':
      case '900':
        return true;
      default:
        return false;
    }
  }
  mostrarCampoModalidadeIcms(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '00':
      case '10':
      case '20':
      case '70':
      case '90':
      case '900':
        return true;
      default:
        return false;
    }
  }
  mostrarCampoModalidadeSt(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '10':
      case '30':
      case '70':
      case '90':
      case '201':
      case '202':
      case '203':
      case '900':
        return true;
      default:
        return false;
    }
  }
  mostrarCampoReducaoIcms(icmsCstCsosn: string) {
    switch (icmsCstCsosn) {
      case '20':
      case '70':
      case '90':
      case '900':
        return true;
      default:
        return false;
    }
  }
  mostrarCampoReducaoIcmsSt(icmsCstCsosn: string): boolean {
    switch (icmsCstCsosn) {
      case '30':
      case '70':
      case '90':
      case '201':
      case '202':
      case '203':
      case '900':
        return true;
    }
    return false;
  }
  mostrarCampoValorOperacaoIcms(icmsCstCsosn: string) {
    return icmsCstCsosn == '51';
  }
  verificarCstST(cst: string) {
    return (
        cst == '10' || cst == '30' || cst == '60' || cst == '70' ||
        cst == '201' || cst == '202' || cst == '203' || cst == '500');
  }
  baixarNotaFiscal(chaveEletronica: string, {
    dataHoraLocal = new Date(),
    codigoManifestacao = CodigosManifestacao.DarCiencia,
  } = {}) {
    return this.service.baixarNotaFiscal(
        chaveEletronica, dataHoraLocal, codigoManifestacao);
  }
  preVisualizarNotaFiscal(notaFiscal: NotaFiscal) {
    return this.service.preVisualizarNotaFiscalSaida(notaFiscal);
  }
  importarXml(xml: any) {
    return this.service.importarXml(xml);
  }
  consultarTempoRestante() {
    return this.service.consultarTempoRestante();
  }
  salvarUltimaAtualizacao(ultimaAtualizacao: any) {
    localStorage.setItem('last_att', ultimaAtualizacao);
  }
  salvarCertificadoDigital(certificado: any) {
    return this.service.salvarCertificadoDigital(certificado);
  }
  buscarRelatorioVariacaoCusto(id: string, empresas: string[]) {
    return this.service.buscarRelatorioVariacaoCusto(id, empresas);
  }
  buscarItensNotaFiscal(id: string) {
    return this.service.buscarItensNotaFiscal(id);
  }
}
