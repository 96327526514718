/* eslint-disable @angular-eslint/no-output-on-prefix */
import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild,} from '@angular/core';

@Component({
  selector: 'app-auto-complete-input-ngmodel',
  templateUrl: './auto-complete-input-ngmodel.component.html',
  styleUrls: ['./auto-complete-input-ngmodel.component.scss'],
})
export class AutoCompleteInputNgModelComponent {
  @ContentChild('template') template: TemplateRef<any>;
  @Input() appendTo: string;
  @Input() autoFocus: boolean;
  @Input() emptyMessage: string = 'Nenhum item encontrado';
  @Input() field: string = 'descricao';
  @Input() maxlength: number;
  @Input() model: any;
  @Input() modelOptions: any;
  @Input() panelStyle: any = {'max-width': '500px'};
  @Input() placeholder: string = '';
  @Input() readonly: boolean;
  @Input() scrollHeight: string = '300px';
  @Input() showClear: boolean;
  @Input() sugestoes: any[];
  @Input() textAlign: string = 'left';  // left, right, center
  @Input() upperCase: boolean = true;
  @Input() forceSelection: boolean = false;
  @Output() completeMethod = new EventEmitter();
  @Output() inputBlur = new EventEmitter();
  @Output() modelChange = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  @Output() buttonOnClick = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
  onBlur(event: any) {
    this.inputBlur.emit(event);
  }
  onSelectEvent(event: any) {
    this.onSelect.emit(event.value);
  }
  completeMethodEvent(event: any) {
    this.completeMethod.emit(event);
  }
}
