import {CertificadoTipo} from '@app/shared/enums/certificado-tipo.enum';

export class EmpresaCertificado {
  tipo: CertificadoTipo;
  dataVencimento: Date;
  base64: string;
  senha: string;
  configurado: boolean;

  static from(json: any = {}): EmpresaCertificado {
    const empresaCertificado = new EmpresaCertificado();
    empresaCertificado.tipo = json?.tipo;
    empresaCertificado.dataVencimento = json?.dataVencimento;
    empresaCertificado.base64 = json?.base64;
    empresaCertificado.senha = json?.senha;
    empresaCertificado.configurado = json?.configurado;
    return empresaCertificado;
  }
}
