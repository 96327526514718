import {AbstractControl, ValidationErrors, ValidatorFn, Validators,} from '@angular/forms';
import {PessoaTipo} from '@app/shared/enums/pessoa-tipo.enum';
import {cnpj, cpf} from 'cpf-cnpj-validator';
import * as moment from 'moment';

const EMAIL_REGEXP = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export class CustomValidators {
  static certificadoDigitalObrigatorio(validationMessage?: string):
      ValidatorFn {
    return certificadoDigitalObrigatorioValidator(validationMessage);
  }
  static gtin(gtin: string, validationMessage?: string): ValidationErrors|null {
    return gtinValidator(gtin, validationMessage);
  }
  static documentoTamanhoMinimo(validationMessage?: string): ValidatorFn {
    return documentoTamanhoMinimoValidator(validationMessage);
  }
  static documentoValido(validationMessage?: string): ValidatorFn {
    return documentoValidoValidator(validationMessage);
  }
  static confirmacaoEmail(validationMessage?: string): ValidatorFn {
    return confirmacaoEmailValidator(validationMessage);
  }
  static confirmacaoSenha(
      controlName1: string, controlName2: string,
      validationMessage?: string): ValidatorFn {
    return confirmacaoSenhaValidator(
        controlName1, controlName2, validationMessage);
  }
  static camposDiferentes(
      controlName1: string, controlName2: string,
      validationMessage?: string): ValidatorFn {
    return camposDiferentesValidator(
        controlName1, controlName2, validationMessage);
  }
  static minor(value: number, validationMessage?: string): ValidatorFn {
    return minorValidator(value, validationMessage);
  }
  static equals(value: number, validationMessage?: string): ValidatorFn {
    return equalsValidator(value, validationMessage);
  }
  static major(value: number, validationMessage?: string): ValidatorFn {
    return majorValidator(value, validationMessage);
  }
  static compareSeMenorEntreCampos(
      controlName1: string, controlName2: string,
      validationMessage?: string): ValidatorFn {
    return compareSeMenorEntreCamposValidator(
        controlName1, controlName2, validationMessage);
  }
  static data(format = 'MM/dd/YYYY', validationMessage?: string): ValidatorFn {
    return dataValidator(format, validationMessage);
  }
  static required(validationMessage?: string): ValidatorFn {
    return requiredValidator(validationMessage);
  }
  static requiredTrue(validationMessage?: string): ValidatorFn {
    return requiredTrueValidator(validationMessage);
  }
  static email(validationMessage?: string): ValidatorFn {
    return emailValidator(validationMessage);
  }
  static equalsLength(equalsLength: number, validationMessage?: string):
      ValidatorFn {
    return equalsLengthValidator(equalsLength, validationMessage);
  }
  static minLength(minLength: number, validationMessage?: string): ValidatorFn {
    return minLengthValidator(minLength, validationMessage);
  }
  static maxLength(maxLength: number, validationMessage?: string): ValidatorFn {
    return maxLengthValidator(maxLength, validationMessage);
  }
  static minLengthArray(minLength: number, validationMessage?: string):
      ValidatorFn {
    return minLengthArrayValidator(minLength, validationMessage);
  }
  static maxLengthArray(maxLength: number, validationMessage?: string):
      ValidatorFn {
    return maxLengthArrayValidator(maxLength, validationMessage);
  }
  static min(min: number, validationMessage?: string): ValidatorFn {
    return minValidator(min, validationMessage);
  }
  static max(max: number, validationMessage?: string): ValidatorFn {
    return maxValidator(max, validationMessage);
  }
  static pattern(pattern: RegExp, validationMessage?: string): ValidatorFn {
    return patternValidator(pattern, validationMessage);
  }
}

function isEmptyInputValue(value: any): boolean {
  return (
      value == null ||
      ((typeof value === 'string' || Array.isArray(value)) &&
       value.length === 0));
}
function hasValidLength(value: any): boolean {
  return value != null && typeof value.length === 'number';
}
function certificadoDigitalObrigatorioValidator(validationMessage?: string):
    ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control && control?.parent) {
      const value = control.value;
      const parentValue = control.parent.get('certificadoDigitalTipo')?.value;

      if (value && parentValue === '1') {
        return {
          certificadoDigitalObrigatorio: {
            invalid: true,
            message: validationMessage ?? '',
          },
        };
      }
    }
    return null;
  };
}
function gtinValidator(
    gtin: string, validationMessage?: string): ValidationErrors|null {
  const chaveMultiplos = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
  const tamanhosPermitidos = [8, 12, 13, 14, 17, 18];

  if (gtin || tamanhosPermitidos.some((i) => i != gtin.length)) return null;

  const gtinLen = gtin.length;
  const gtinSemDigito = gtin.substring(0, gtinLen - 1);
  const gtinDigito = gtin.substring(gtinLen - 1, 1);

  let soma = 0;
  const multiplos =
      chaveMultiplos.slice(chaveMultiplos.length - gtinSemDigito.length);
  for (let i = 0; i < gtinSemDigito.length; i++) {
    const cint = Number(gtinSemDigito[i].toString());
    if (!cint) return null;
    soma += multiplos[i] * cint;
  }
  const mod = soma % 10;
  const digitoReal = mod == 0 ? 0 : 10 - mod;

  if (digitoReal.toString() != gtinDigito) {
    return {gtin: true, message: validationMessage};
  }

  return null;
}
function documentoTamanhoMinimoValidator(validationMessage?: string):
    ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control && control?.parent) {
      const pessoaTipo = control.parent.get('pessoaTipo').value;
      const documento = control.value ?? '';

      if (documento) {
        if (pessoaTipo === PessoaTipo.Juridica) {
          if (documento.length < 14) {
            return {
              documentoTamanhoMinimo: {message: validationMessage ?? ''},
            };
          }
        } else if (pessoaTipo === PessoaTipo.Fisica) {
          if (documento.length < 11) {
            return {
              documentoTamanhoMinimo: {message: validationMessage ?? ''},
            };
          }
        }
      }
    }
    return null;
  };
}
function documentoValidoValidator(validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control && control?.parent) {
      const pessoaTipo = control.parent.get('pessoaTipo').value;
      const documento = control.value ?? '';

      if (documento) {
        if (pessoaTipo === PessoaTipo.Juridica) {
          if (!cnpj.isValid(documento)) {
            return {documentoValido: {message: validationMessage ?? ''}};
          }
        } else if (pessoaTipo === PessoaTipo.Fisica) {
          if (!cpf.isValid(documento)) {
            return {documentoValido: {message: validationMessage ?? ''}};
          }
        }
      }
    }
    return null;
  };
}
function confirmacaoEmailValidator(validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control) {
      const email1 = control.get('email').value;
      const email2 = control.get('confirmacaoEmail').value;

      if (email1 !== email2) {
        return {confirmacaoEmail: {message: validationMessage ?? ''}};
      }
    }
    return null;
  };
}
function confirmacaoSenhaValidator(
    controlName1: string, controlName2: string,
    validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control) {
      const senha = control.get(controlName1);
      const confirmacaoSenha = control.get(controlName2);

      if (senha.value !== confirmacaoSenha.value) {
        const message = {message: validationMessage ?? ''};

        senha.errors ? senha.errors.confirmarSenha = message :
                       senha.setErrors({confirmarSenha: message});


        confirmacaoSenha.errors ?
            confirmacaoSenha.errors.confirmarSenha = message :
            confirmacaoSenha.setErrors({confirmarSenha: message});

        senha.setErrors(
            Object.keys(senha.errors).length > 0 ? senha.errors : null);
        confirmacaoSenha.setErrors(
            Object.keys(confirmacaoSenha.errors).length > 0 ?
                confirmacaoSenha.errors :
                null);
      } else {
        if (senha.errors?.confirmarSenha) {
          delete senha.errors.confirmarSenha;
        }

        if (confirmacaoSenha.errors?.confirmarSenha) {
          delete confirmacaoSenha.errors.confirmarSenha;
        }

        senha.setErrors(
            Object.keys(senha.errors).length > 0 ? senha.errors : null);
        confirmacaoSenha.setErrors(
            Object.keys(confirmacaoSenha.errors).length > 0 ?
                confirmacaoSenha.errors :
                null);
      }
    }
    return null;
  };
}
function camposDiferentesValidator(
    controlName1: string, controlName2: string,
    validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control) {
      const campo1 = control.get(controlName1);
      const campo2 = control.get(controlName2);

      if (campo1.value === campo2.value) {
        const message = {message: validationMessage ?? ''};
        campo1.setErrors({message});
        campo2.setErrors({message});
        return {message};
      } else {
        campo1.setErrors(null);
        campo2.setErrors(null);
      }
    }
    return null;
  };
}
function minorValidator(
    value: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    return Number(control.value) < value ?
        {minor: {message: validationMessage ?? ''}} :
        null;
  };
}
function equalsValidator(
    value: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    return Number(control.value) == value ?
        {equals: {message: validationMessage ?? ''}} :
        null;
  };
}
function majorValidator(
    value: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    return Number(control.value) > value ?
        {major: {message: validationMessage ?? ''}} :
        null;
  };
}
function compareSeMenorEntreCamposValidator(
    controlName1: string, controlName2: string,
    validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    const valor1 = Number(control.get(controlName1)?.value);
    const valor2 = Number(control.get(controlName2)?.value);
    return valor1 < valor2 ?
        {compareSeMenorEntreCampos: {message: validationMessage ?? ''}} :
        null;
  };
}
function dataValidator(
    format = 'dd/MM/YYYY', validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    const val = moment(control.value, format, true);

    if (control.value && !val.isValid()) {
      return {dataInvalida: {message: validationMessage ?? ''}};
    }

    return null;
  };
}
function requiredValidator(validationMessage: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    return isEmptyInputValue(control.value) ?
        {required: {message: validationMessage ?? ''}} :
        null;
  };
}
function requiredTrueValidator(validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    return control.value === true ?
        null :
        {required: {message: validationMessage ?? ''}};
  };
}
function emailValidator(validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    return EMAIL_REGEXP.test(control.value) ?
        null :
        {email: {message: validationMessage ?? ''}};
  };
}
function minValidator(min: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
    }
    const value = parseFloat(control.value);
    return !isNaN(value) && value < min ? {
      min: {
        min: min,
        actual: control.value,
        message: validationMessage ?? '',
      },
    } :
                                          null;
  };
}
function maxValidator(max: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
      return null;
    }
    const value = parseFloat(control.value);
    return !isNaN(value) && value > max ? {
      max: {
        max: max,
        actual: control.value,
        message: validationMessage ?? '',
      },
    } :
                                          null;
  };
}
function equalsLengthValidator(
    equalsLength: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
      return null;
    }

    return control.value.length != equalsLength ? {
      equalslength: {
        requiredLength: equalsLength,
        actualLength: control.value.length,
        message: validationMessage ?? '',
      },
    } :
                                                  null;
  };
}
function minLengthValidator(
    minLength: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
      return null;
    }

    return control.value.length < minLength ? {
      minlength: {
        requiredLength: minLength,
        actualLength: control.value.length,
        message: validationMessage ?? '',
      },
    } :
                                              null;
  };
}
function maxLengthValidator(
    maxLength: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    return hasValidLength(control.value) && length > maxLength ? {
      maxlength: {
        requiredLength: maxLength,
        actualLength: length,
        message: validationMessage ?? '',
      },
    } :
                                                                 null;
  };
}
function patternValidator(
    pattern: RegExp, validationMessage?: string): ValidatorFn {
  if (!pattern) return Validators.nullValidator;

  return (control: AbstractControl): ValidationErrors|null => {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    const value: string = control.value;
    return pattern.test(value) ? null : {
      pattern: {
        requiredPattern: pattern.toString(),
        actualValue: value,
        message: validationMessage ?? '',
      },
    };
  };
}
function minLengthArrayValidator(
    minLength: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control.value.length >= minLength) return null;

    return {
      minLengthArray: {
        message: validationMessage ?? '',
      },
    };
  };
}
function maxLengthArrayValidator(
    maxLength: number, validationMessage?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control.value.length <= maxLength) return null;

    return {
      maxLengthArray: {
        message: validationMessage ?? '',
      },
    };
  };
}
