import {Injectable} from '@angular/core';
import {FormArray, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class ErrorMessageFacade {
  constructor(private toastrService: ToastrService) {}
  private buscarRecursivaControlsInvalidos(formToInvestigate: FormGroup<any>|
                                           FormArray<any>): string[] {
    var invalidControls: any[] = [];
    let recursiveFunc = (form: UntypedFormGroup|UntypedFormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control.invalid) {
          if (control.errors) {
            Object.keys(control.errors).forEach((error) => {
              invalidControls.push({
                name: field,
                message: control.errors[error].message,
              });
            });
          }
        }
        if (control instanceof UntypedFormGroup ||
            control instanceof UntypedFormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    if (invalidControls.length > 3) {
      this.toastrService.warning(
          'Formulário inválido! Preencha todos os campos corretamente.');
    } else {
      invalidControls.forEach((erro) => {
        this.toastrService.warning(erro.message);
      });
    }
    invalidControls.forEach((erro) => {
      console.log(`${erro.name}: ${erro.message}`);
    });
    return invalidControls;
  }
  private errosValidacaoAPI(e: any, toastMethod: string) {
    const erros = e?.error?.errors ?? e?.error;
    if (erros instanceof Array) {
      erros.forEach((erro: any) => {
        if (erro.errors) {
          erro.errors.forEach((err: any) => {
            this.mostrarToast(err.message, toastMethod);
          });
        } else {
          this.mostrarToast(erro.errorMessage, toastMethod);
        }
      });
    } else {
      if (erros as string) {
        this.mostrarToast(erros, toastMethod);
      } else {
        let keys = Object.keys(erros);
        keys.forEach((key) => {
          if (erros[key] instanceof Array) {
            erros[key].forEach((element: any) => {
              this.mostrarToast(`${element}`, toastMethod);
            });
          } else {
            this.mostrarToast(`${erros[key]}`, toastMethod);
          }
        });
      }
    }
  }
  private mostrarToast(e: string, toastMethod: string) {
    switch (toastMethod) {
      case 'warning':
        this.toastrService.warning(e);
        break;
      case 'info':
        this.toastrService.info(e);
        break;
      case 'error':
      default:
        this.toastrService.error(e);
        break;
    }
  }
  private status(e: any) {
    if (e.status === 405) {
      this.toastrService.error('Método não implementado. (ERRO: 405)');
    }
  }
  private setarCamposInvalidos(formGroup: UntypedFormGroup|FormArray) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof UntypedFormGroup) {
        this.setarCamposInvalidos(control);
      }
    });
  }
  private unknownError() {
    this.toastrService.error(
        `Erro interno: não foi possível executar esta ação`);
  }
  mostrarMensagens(e: any, toastMethod = 'warning'): null {
    if (e?.statusText === 'Unknown Error') {
      this.unknownError();
    } else if (e?.error) {
      this.errosValidacaoAPI(e, toastMethod);
    } else if (e?.error?.errors) {
      this.errosValidacaoAPI(e, toastMethod);
    } else if (e?.status) {
      this.status(e);
    } else if (typeof e == 'string') {
      this.mostrarToast(e, toastMethod);
    }
    return null;
  }
  mostrarCamposInvalidos(form: UntypedFormGroup|FormGroup<any>|FormArray|any) {
    this.buscarRecursivaControlsInvalidos(form);
    this.setarCamposInvalidos(form);
  }
}
