import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'dateTimeAgo',
})
export class DateTimeAgoPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(data: Date): any {
    const segundo_milisegundos = 1000;
    const minuto_milisegundos = segundo_milisegundos * 60;
    const hora_milisegundos = minuto_milisegundos * 60;
    const dia_milisegundos = hora_milisegundos * 24;
    const semana_milisegundos = dia_milisegundos * 7;
    data = new Date(data);

    const dataAtual_milisegundos = moment.now();
    const data_milisegundos = data.getTime();
    const diferenca_milisegundos = dataAtual_milisegundos - data_milisegundos;

    if (diferenca_milisegundos < minuto_milisegundos) {
      return 'Agora';
    } else if (
      diferenca_milisegundos > minuto_milisegundos &&
      diferenca_milisegundos < hora_milisegundos
    ) {
      const tempo = diferenca_milisegundos / minuto_milisegundos;
      if (Math.floor(tempo) === 1) {
        return `${Math.floor(tempo)} minuto`;
      } else {
        return `${Math.floor(tempo)} minutos`;
      }
    } else if (
      diferenca_milisegundos > hora_milisegundos &&
      diferenca_milisegundos < dia_milisegundos
    ) {
      const tempo = diferenca_milisegundos / hora_milisegundos;
      if (Math.floor(tempo) === 1) {
        return `${Math.floor(tempo)} hora`;
      } else {
        return `${Math.floor(tempo)} horas`;
      }
    } else if (
      diferenca_milisegundos > dia_milisegundos &&
      diferenca_milisegundos < semana_milisegundos
    ) {
      const tempo = diferenca_milisegundos / dia_milisegundos;
      if (Math.floor(tempo) === 1) {
        return `${Math.floor(tempo)} dia`;
      } else {
        return `${Math.floor(tempo)} dias`;
      }
    } else {
      return this.datePipe.transform(data, 'short');
    }
  }
}
