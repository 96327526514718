import {NFeDocumentoTipo} from '@app/shared/enums/nfe-documento-tipo.enum';
import {NFeFinalidade} from '@app/shared/enums/nfe-finalidade.enum';
import {NFeStatus} from '@app/shared/enums/nfe-status.enum';
import * as moment from 'moment';

import {NotaFiscalDestinatario} from './destinatario.entity';
import {NotaFiscalEmitente} from './emitente.entity';
import {NotaFiscalTotal} from './total.entity';

export class NotaFiscalCabecalho {
  // ENTRADA E SAÍDA
  chaveEletronica: string;
  consumidorFinal: boolean;
  dataEmissao: Date;
  dataSaida: Date;
  finalidade: NFeFinalidade;
  modelo: number;
  naturezaOperacao: string;
  numeroNotaFiscal: number;
  situacao: NFeStatus;

  // SOMENTE SAÍDA
  referencias: string[];
  serie: number;
  tipo: NFeDocumentoTipo;
  versao: string;
  destinatario: NotaFiscalDestinatario;
  emitente: NotaFiscalEmitente;
  total: NotaFiscalTotal;
  regraFiscalId: string;

  static from(json: any = {}): NotaFiscalCabecalho {
    const cabecalho = new NotaFiscalCabecalho();
    cabecalho.chaveEletronica = json.chaveEletronica;
    cabecalho.dataEmissao =
        json.dataEmissao != null ? moment(json.dataEmissao).toDate() : null;
    cabecalho.dataSaida =
        json.dataSaida != null ? moment(json.dataSaida).toDate() : null;
    if (json.destinatario) {
      cabecalho.destinatario = NotaFiscalDestinatario.from(json.destinatario);
    }
    if (json.emitente) {
      cabecalho.emitente = NotaFiscalEmitente.from(json.emitente);
    }
    cabecalho.finalidade = json.finalidade;
    cabecalho.tipo = json.tipo;
    cabecalho.modelo = json.modelo;
    cabecalho.naturezaOperacao = json.naturezaOperacao?.trim();
    cabecalho.numeroNotaFiscal = json.numeroNotaFiscal;
    cabecalho.consumidorFinal = json.consumidorFinal;
    cabecalho.serie = json.serie;
    cabecalho.total = NotaFiscalTotal.from(json.total);
    cabecalho.versao = json.versao?.trim();
    cabecalho.referencias = json.referencias;
    cabecalho.regraFiscalId = json.regraFiscalId;
    cabecalho.situacao = json.situacao;
    return cabecalho;
  }

  verificarDataSaidaEhDataMinima() {
    this.dataSaida = new Date(this.dataSaida);
    const minDate = new Date('0001-01-01T00:00:00').toDateString();
    const dataSaida = this.dataSaida.toDateString();

    if (dataSaida == minDate) {
      this.dataSaida = this.dataEmissao;
    }
  }
}
