import {Injectable} from '@angular/core';
import {Empresa} from '@app/abstraction/domain/entities/cadastros/empresa/empresa.entity';
import {GrupoEconomico} from '@app/abstraction/domain/entities/shared/auth/grupo-economico.entity';
import {UsuarioAuth} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-auth.interface';
import {UsuarioConfig} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-config.interface';
import {UsuarioTarefa} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-tarefa.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthStore {
  private readonly _empresaLogada = new BehaviorSubject<Empresa>(null);
  private readonly _grupoLogado = new BehaviorSubject<GrupoEconomico>(null);
  private readonly _usuarioLogado = new BehaviorSubject<UsuarioAuth>(null);
  private readonly _usuarioConfig = new BehaviorSubject<UsuarioConfig>(null);
  private readonly _usuarioTarefas = new BehaviorSubject<any[]>(null);

  /* #region  Getters Usuário Logado */
  get usuarioLogado(): UsuarioAuth {
    return this._usuarioLogado.getValue();
  }
  set usuarioLogado(usuarioLogado: UsuarioAuth) {
    this._usuarioLogado.next(usuarioLogado);
  }
  get usuarioLogado$() {
    return this._usuarioLogado.asObservable();
  }
  /* #endregion */

  /* #region  Getters Empresa Logada */
  get empresaLogada(): Empresa {
    return this._empresaLogada.getValue();
  }
  set empresaLogada(empresa: Empresa) {
    this._empresaLogada.next(empresa);
  }
  get _empresaLogada$(): Observable<Empresa> {
    return this._empresaLogada.asObservable();
  }
  /* #endregion */

  /* #region  Getters Grupo Economico Logado */
  get grupoLogado(): GrupoEconomico {
    return this._grupoLogado.getValue();
  }
  set grupoLogado(grupo: GrupoEconomico) {
    this._grupoLogado.next(grupo);
  }
  get _grupoLogado$(): Observable<GrupoEconomico> {
    return this._grupoLogado.asObservable();
  }
  /* #endregion */

  /* #region  Getters Usuário Config */
  get usuarioConfig(): UsuarioConfig {
    return this._usuarioConfig.getValue();
  }
  set usuarioConfig(usuarioConfig: UsuarioConfig) {
    this._usuarioConfig.next(usuarioConfig);
  }
  get usuarioConfig$() {
    return this._usuarioConfig.asObservable();
  }
  /* #endregion */

  /* #region  Getters Usuário Tarefas */
  get usuarioTarefas(): any[] {
    return this._usuarioTarefas.getValue();
  }
  set usuarioTarefas(tarefas: any[]) {
    this._usuarioTarefas.next(tarefas);
  }
  get usuarioTarefas$() {
    return this._usuarioTarefas.asObservable();
  }
  /* #endregion */

  private setarTarefas() {
    this._usuarioTarefas.next(this.usuarioTarefas);
  }
  adicionarTarefa(tarefa: UsuarioTarefa) {
    if (!this.usuarioTarefas) {
      this.usuarioTarefas = [];
    }
    this.usuarioTarefas = [...this.usuarioTarefas, tarefa];
  }
  alterarTarefa(tarefa: UsuarioTarefa) {
    const index = this.usuarioTarefas.findIndex(
        (e: UsuarioTarefa) => e.chave === tarefa.chave);
    this.usuarioTarefas[index] = tarefa;
    this.setarTarefas();
  }
  removerTarefa(tarefa: UsuarioTarefa) {
    const index = this.usuarioTarefas.indexOf(tarefa);
    this.usuarioTarefas.splice(index, 1);
    this.setarTarefas();
  }
}
