import {Injectable} from '@angular/core';
import {SubgrupoService} from '@app/abstraction/data/services/produtos/subgrupo.service';
import {SubgrupoStore} from '@app/abstraction/data/stores/produtos/subgrupo.store';
import {Subgrupo} from '@app/abstraction/domain/entities/produtos/subgrupo/subgrupo.entity';
import {SubgrupoFiltro} from '@app/abstraction/domain/interfaces/produtos/subgrupo/subgrupo-filtro.interface';

@Injectable({providedIn: 'root'})
export class SubgrupoFacade {
  get subgrupoPadrao() {
    return this.store.subgrupos?.find(
        (p) => p.descricao.toLowerCase() === 'geral');
  }
  get subgrupos() {
    return this.store.subgrupos;
  }
  get subgrupos$() {
    return this.store.subgrupos$;
  }
  constructor(private store: SubgrupoStore, private service: SubgrupoService) {}

  async adicionar(subgrupo: Subgrupo) {
    const novaSubgrupo = await this.service.adicionar(subgrupo);
    if (novaSubgrupo) {
      this.store.adicionar(novaSubgrupo.toListagem());
    }
    return novaSubgrupo;
  }
  async buscarPorGrupoId(filtro?: SubgrupoFiltro) {
    const subgrupos = await this.service.buscarTodos(filtro);
    if (subgrupos) {
      this.store.subgrupos = subgrupos;
    }
  }
  async buscarSubgrupos(filtro?: SubgrupoFiltro) {
    const subgrupos = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(subgrupos);
  }
  async atualizarSituacao(id: string) {
    const subgrupo = this.subgrupos.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !subgrupo.ativo);
    subgrupo.ativo = status;
    return status;
  }
  alterar(subgrupo: Subgrupo) {
    return this.service.alterar(subgrupo, subgrupo.id);
  }
  buscarPorId(grupoId: string) {
    return this.service.buscarPorId(grupoId);
  }
  limparListaSubgrupos() {
    this.store.subgrupos = null;
  }
}
