import '@app/shared/extensions/string.extension';

import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Setor} from '@app/abstraction/domain/entities/cadastros/setor/setor.entity';
import {ImpressoraFacade} from '@app/abstraction/domain/facades/cadastros/impressora/impressora.facade';
import {SetorFacade} from '@app/abstraction/domain/facades/cadastros/setor/setor.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {SetorSharedFormComponent} from '@app/shared/components/shared-forms/setor-shared-form/setor-shared-form.component';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-setor-cadastro-rapido-modal',
  templateUrl: './setor-cadastro-rapido-modal.component.html',
  styleUrls: ['./setor-cadastro-rapido-modal.component.scss'],
})
export class SetorCadastroRapidoModalComponent extends FormBaseComponent
    implements AfterViewInit {
  @ViewChild('formComponent') formComponent: SetorSharedFormComponent;
  @Input() mostrarModal: boolean;
  @Input() setor: Setor;
  @Output() aoSalvar = new EventEmitter<Setor>();
  @Output() aoCancelar = new EventEmitter();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private loadingService: NgxUiLoaderService,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private setorFacade: SetorFacade,
      private impressoraFacade: ImpressoraFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarSetor(novoSetor: Setor) {
    return this.setorFacade.adicionar(novoSetor).catch((e: any) => {
      this.loadingService.stop();
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(setor: Setor) {
    this.toastrService.success('Setor cadastrado com sucesso');
    this.aoSalvar.emit(setor);
    this.fecharModal();
    this.loadingService.stop();
  }
  async salvar() {
    try {
      this.loadingService.start();

      if (!this.formComponent.setorForm.isValid()) {
        this.loadingService.stop();
        return;
      }

      const setor = this.formComponent.preencherObjeto();

      const resultado = await this.adicionarSetor(setor)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar setor.');
      this.loadingService.stop();
    }
  }
  async aoAbrirModal() {
    this.formComponent.setorForm.criarForm();
    await Promise.all([this.impressoraFacade.buscarImpressoras({ativo: true})])
        .then(() => {
          this.formComponent.setorForm.setarTipoFormulario(this.setor);
        });
  }
  fecharModal() {
    this.aoCancelar.emit();
  }
  ngAfterViewInit() {
    this.formComponent.criarForm();
  }
}
