import {FormControl, FormGroup} from '@angular/forms';

export interface IProdutoFoodForm {
  taxaServico: FormControl<boolean>;
  rodizio: FormControl<boolean>;
  pesado: FormControl<boolean>;
  combo: FormControl<boolean>;
  complemento: FormControl<boolean>;
  controlado: FormControl<boolean>;
  semValor: FormControl<boolean>;
}

export class ProdutoFoodForm {
  static criarForm() {
    return new FormGroup<IProdutoFoodForm>({
      taxaServico: new FormControl(false),
      rodizio: new FormControl(false),
      pesado: new FormControl(false),
      combo: new FormControl(false),
      complemento: new FormControl(false),
      controlado: new FormControl(false),
      semValor: new FormControl(null),
    });
  }
}
