import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';


@Component({
  selector: 'app-bottom-progress-bar',
  templateUrl: './bottom-progress-bar.component.html',
  styleUrls: ['./bottom-progress-bar.component.scss'],
})
export class BottomProgressBarComponent implements AfterViewInit {
  @Input() mostrarProgresso: boolean;
  @Input() mostrarProgressoFormatado: boolean;
  @Input() mensagem: string;
  @Input() total: number;
  @Input() executados: number;

  get progresso(): number {
    return Number(((this.executados / this.total) * 100).toFixed(2));
  }
  get progressoFormatado() {
    return `${this.executados}/${this.total}`;
  }
  constructor(private el: ElementRef) {}
  private alinharComponenteCentro() {
    const nativeElement = this.el.nativeElement as HTMLElement;
    nativeElement.style.display = 'flex';
    nativeElement.style.justifyContent = 'center';
  }
  ngAfterViewInit() {
    this.alinharComponenteCentro();
  }
}
