<p-dialog
  *ngIf="form"
  [header]="titulo"
  [style]="{ width: isDesktop ? '60%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="form"
  (onHide)="fecharModal()"
>
  <div class="formgrid grid">
    <!-- #region CONVENIO -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label
        texto="Convênio"
        [obrigatorio]="true"
      ></app-custom-label>
      <app-text-input
        mask="0000000"
        textAlign="right"
        [maxlength]="7"
        [control]="form.controls.convenio"
      ></app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region DIGITO CONVENIO -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Dígito Convênio"></app-custom-label>
      <app-text-input
        mask="00"
        textAlign="right"
        [maxlength]="2"
        [control]="form.controls.digitoConvenio"
      ></app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region CODIGO CARTEIRA -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label
        texto="Código Carteira"
        [obrigatorio]="true"
      ></app-custom-label>
      <app-dropdown-input
        optionLabel="nome"
        optionValue="codigo"
        [control]="form.controls.codigoCarteira"
        [options]="carteiras"
        [showFilter]="true"
      ></app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region VARIACAO CARTEIRA -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Variação Carteira"></app-custom-label>
      <app-text-input
        textAlign="right"
        [maxlength]="10"
        [control]="form.controls.variacaoCarteira"
        [readonly]="true"
      ></app-text-input>
    </div>
    <!-- #endregion -->

    <!-- #region CNAB -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="CNAB" [obrigatorio]="true"></app-custom-label>
      <app-dropdown-input
        [control]="form.controls?.cnab"
        [options]="cnabs"
      ></app-dropdown-input>
    </div>
    <!-- #endregion -->

    <!-- #region TAXA -->
    <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
      <app-custom-label texto="Taxa (R$)"></app-custom-label>
      <app-numeric-input
        [control]="form.controls.taxa"
        [placeholder]="valorPlaceholder"
        [options]="valorMask"
      ></app-numeric-input>
    </div>
    <!-- #endregion -->

    <!-- #region SOMAR TAXA -->
    <div class="col-6 xl:col-2 md:col-3 mt-0 sm:mt-4">
      <app-checkbox-input
        label="Somar Taxa"
        controlName="somarTaxa"
        [control]="form.controls.somarTaxa"
      >
      </app-checkbox-input>
    </div>
    <!-- #endregion -->

    <!-- #region PADRAO -->
    <div class="col-6 xl:col-2 md:col-3 mt-0 sm:mt-4">
      <app-checkbox-input
        label="Padrão"
        controlName="padrao"
        [control]="form.controls.padrao"
      >
      </app-checkbox-input>
    </div>
    <!-- #endregion -->
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
