import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Marca} from '@app/abstraction/domain/entities/produtos/marca/marca.entity';
import {MarcaFacade} from '@app/abstraction/domain/facades/produtos/marca/marca.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {MarcaForm} from '@app/abstraction/domain/forms/produtos/marca.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-marca-cadastro-rapido-modal',
  templateUrl: './marca-cadastro-rapido-modal.component.html',
})
export class MarcaCadastroRapidoModalComponent extends FormBaseComponent
    implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<Marca>();
  @Output() aoCancelar = new EventEmitter();

  marcaForm: MarcaForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private marcaFacade: MarcaFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarMarca(marca: Marca) {
    return this.marcaFacade.adicionar(marca).catch((e: any) => {
      this.saveLoading = false;
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private preencherObjeto() {
    return Marca.from(this.marcaForm.form.value);
  }
  private mostrarSucessoAoSalvar(marca: Marca) {
    this.toastrService.success(`Marca cadastrada com sucesso`);
    this.aoSalvar.emit(marca);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.marcaForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const marca = this.preencherObjeto();

      const resultado = await this.adicionarMarca(marca)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar marca.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  criarForm() {
    this.marcaForm = new MarcaForm(this.errorMessagesFacade);
    this.marcaForm.criarForm();
    this.marcaForm.setarTipoFormulario();
  }
  fecharModal() {
    this.marcaForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
  }
}
