export enum TipoFuncionario {
  Nenhum,
  OperadorPdv,
  Supervisor,
  Vendedor,
  Gerente,
  Diretor,
}

export const TipoFuncionarioLabels = new Map<number, string>([
  [TipoFuncionario.OperadorPdv, 'Operador PDV'],
  [TipoFuncionario.Supervisor, 'Supervisor'],
  [TipoFuncionario.Vendedor, 'Vendedor'],
  [TipoFuncionario.Gerente, 'Gerente'],
  [TipoFuncionario.Diretor, 'Diretor'],
]);
