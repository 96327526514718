import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Cliente} from '@app/abstraction/domain/entities/cadastros/cliente/cliente.entity';
import {ClienteSituacaoFacade} from '@app/abstraction/domain/facades/cadastros/cliente-situacao/cliente-situacao.facade';
import {ClienteFacade} from '@app/abstraction/domain/facades/cadastros/cliente/cliente.facade';
import {FuncionarioFacade} from '@app/abstraction/domain/facades/cadastros/funcionario/funcionario.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ClienteSharedFormComponent} from '@app/shared/components/shared-forms/cliente-shared-form/cliente-shared-form.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-cliente-cadastro-rapido-modal',
  templateUrl: './cliente-cadastro-rapido-modal.component.html',
})
export class ClienteCadastroRapidoModalComponent extends FormBaseComponent
    implements AfterViewInit {
  @ViewChild('formComponent') formComponent: ClienteSharedFormComponent;

  @Input() mostrarModal: boolean;
  @Input() cliente: Cliente;
  @Output() aoSalvar = new EventEmitter<Cliente>();
  @Output() aoCancelar = new EventEmitter();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private clienteFacade: ClienteFacade,
      private funcionarioFacade: FuncionarioFacade,
      private enderecoTipoFacade: EnderecoTipoFacade,
      private clienteSituacaoFacade: ClienteSituacaoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private planoContaFacade: PlanoContasFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarCliente(cliente: Cliente) {
    return this.clienteFacade.adicionar(cliente).catch((e) => {
      this.saveLoading = false;
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(cliente: Cliente) {
    this.toastrService.success(`Cliente cadastrado com sucesso`);
    this.aoSalvar.emit(cliente);
    this.aoFecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!(await this.formComponent.clienteForm.isValid())) {
        this.saveLoading = false;
        return;
      }

      const cliente = this.formComponent.preencherObjeto();

      const resultado = await this.adicionarCliente(cliente);

      if (resultado) this.mostrarSucessoAoSalvar(resultado);
    } catch (error) {
      this.toastrService.error('Erro ao salvar cliente.');
      this.saveLoading = false;
    }
  }
  async ngAfterViewInit() {
    await this.funcionarioFacade.buscarFuncionarios();
    this.formComponent.criarForm();
  }
  async aoAbrirModal() {
    this.formComponent.criarForm();
    Promise
        .all([
          this.enderecoTipoFacade.buscarEnderecoTipos(),
          this.clienteSituacaoFacade.buscarClienteSituacoes({ativo: true}),
          await this.planoContaFacade.buscarPlanosContasNivel3({ativo: true}),
        ])
        .then(() => {
          this.formComponent.clienteForm.setarTipoFormulario();
        });
  }
  aoFecharModal() {
    this.clienteSituacaoFacade.limparListaClienteSituacoes();
    this.aoCancelar.emit();
  }
}
