import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Funcionario} from '@app/abstraction/domain/entities/cadastros/funcionario/funcionario.entity';
import {FuncionarioFiltro} from '@app/abstraction/domain/interfaces/cadastros/funcionario/funcionario-filtro.interface';
import {FuncionarioListagem} from '@app/abstraction/domain/interfaces/cadastros/funcionario/funcionario-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FuncionarioService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}
  adicionar(dados: Funcionario) {
    const response$ =
        this.client.post<Funcionario>(API.CADASTRO_FUNCIONARIO, dados)
            .pipe(map(
                (json: Funcionario) => json ? Funcionario.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Funcionario, id: string) {
    const response$ =
        this.client.put<Funcionario>(`${API.CADASTRO_FUNCIONARIO}/${id}`, dados)
            .pipe(map(
                (json: Funcionario) => json ? Funcionario.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.CADASTRO_FUNCIONARIO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Funcionario>(`${API.CADASTRO_FUNCIONARIO}/${id}`)
            .pipe(map(
                (json: Funcionario) => json ? Funcionario.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPorDocumento(documento: any) {
    const response$ =
        this.client
            .get<Funcionario>(
                `${API.CADASTRO_FUNCIONARIO_DOCUMENTO}/${documento}`)
            .pipe(map(
                (json: Funcionario) => json ? Funcionario.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPorUsuarioId(chave: string) {
    const response$ =
        this.client
            .get<Funcionario>(`${API.CADASTRO_FUNCIONARIO_USUARIO}/${chave}`)
            .pipe(map(
                (json: Funcionario) => json ? Funcionario.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: FuncionarioFiltro) {
    const response$ =
        this.client.get<FuncionarioListagem[]>(this.geradorQueryString.gerar(
            `${
                filtro.consultaSimples ? API.CADASTRO_FUNCIONARIO_SIMPLES :
                                         API.CADASTRO_FUNCIONARIO}`,
            filtro));
    return lastValueFrom(response$);
  }
  downloadPlanilha() {
    const response$ =
        this.client.get(`${API.CADASTRO_FUNCIONARIO_DOWNLOAD_PLANILHA}`, {
          responseType: 'blob',
        });
    return lastValueFrom(response$);
  }
  importarPlanilha(fileBase64: string) {
    const response$ = this.client.post<FuncionarioListagem[]>(
        `${API.CADASTRO_FUNCIONARIO_IMPORTAR_PLANILHA}`, fileBase64);
    return lastValueFrom(response$);
  }
}
