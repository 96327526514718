import {FormControl, FormGroup} from '@angular/forms';
import {ProdutoVariacaoEstoque} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-estoque.entity';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface IProdutoVariacaoEstoqueForm {
  id: FormControl<string>;
  variacaoId: FormControl<string>;
  // empresaId: FormControl<string>;
  areaEstoqueId: FormControl<string>;
  atual: FormControl<number>;
  minimo: FormControl<number>;
  maximo: FormControl<number>;
  percentualPerda: FormControl<number>;
  congelado: FormControl<number>;
  digitado: FormControl<number>;
  sku: FormControl<number>;
}

export class ProdutoVariacaoEstoqueForm {
  static criarForm(
      variacaoEstoque: ProdutoVariacaoEstoque, areaEstoqueId: string) {
    return new FormGroup<IProdutoVariacaoEstoqueForm>({
      id: new FormControl(variacaoEstoque?.id),
      variacaoId: new FormControl(variacaoEstoque?.variacaoId),
      areaEstoqueId: new FormControl(
          variacaoEstoque?.areaEstoqueId ?? areaEstoqueId,
          CustomValidators.required('Área de estoque é obrigatória'),
          ),
      atual: new FormControl(variacaoEstoque?.atual),
      minimo: new FormControl(variacaoEstoque?.minimo),
      maximo: new FormControl(variacaoEstoque?.maximo),
      percentualPerda: new FormControl(variacaoEstoque?.percentualPerda),
      congelado: new FormControl(variacaoEstoque?.congelado),
      digitado: new FormControl(variacaoEstoque?.digitado),
      sku: new FormControl(variacaoEstoque?.sku),
    });
  }
}
