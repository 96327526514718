<ng-template #decimalTemp let-value="value">
  <span>{{ value | customDecimal : valorDecimais }}</span>
</ng-template>
<ng-template #dataTemp let-value="value">
  <span>{{ value | date : dataFormatoPt }}</span>
</ng-template>
<ng-template #formaPagamentoDescricaoTemp let-value="value">
  <span>{{ value | formaPagamento }}</span>
</ng-template>
<ng-template
  #acoesPagamentosTemp
  pTemplate="body"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Editar"
    type="button"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="abrirLancamentoPagamentoModal(rowData, rowIndex)"
  ></p-button>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Excluir"
    type="button"
    icon="pi pi-trash"
    [text]="true"
    [plain]="true"
    (onClick)="abrirConfirmacaoExcluisaoPagamento(rowIndex)"
  ></p-button>
</ng-template>
<p-dialog
  [header]="titulo"
  [style]="{ width: isDesktop ? '60%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharModal()"
  (onShow)="aoAbrir()"
>
  <div class="grid">
    <div class="col-12 xl:col-8 lg:col-6">
      <p-button
        type="button"
        styleClass="btn-five"
        label="Novo pagamento"
        tooltipPosition="bottom"
        pTooltip="Novo pagamento"
        icon="pi pi-plus"
        (onClick)="abrirLancamentoPagamentoModal()"
      ></p-button>
    </div>
    <div class="info-grid col">
      <span class="text-primary font-bold">Total Pago</span>
      <h5 class="text-primary font-bold">
        {{ totalPago | customCurrency : valorDecimais }}
      </h5>
    </div>
    <div class="info-grid col">
      <span class="text-primary font-bold">Total Pendente</span>
      <h5 class="text-primary font-bold">
        {{ totalPendente | customCurrency : valorDecimais }}
      </h5>
    </div>
  </div>
  <app-table [columns]="colunasPagamentos" [rows]="pagamentos"> </app-table>

  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>

<app-contas-pagamento-parcial-modal
  [mostrarModal]="mostrarLancamentoPagamentoModal"
  [pagamento]="pagamentoSelecionado"
  [parcela]="parcelaSelecionada"
  (aoCancelar)="fecharLancamentoPagamentoModal()"
  (aoSalvar)="salvarPagamento($event)"
></app-contas-pagamento-parcial-modal>
