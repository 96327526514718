import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PlanoContasNivel1} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-1.entity';
import {PlanoContasNivel2} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-2.entity';
import {PlanoContasNivel3} from '@app/abstraction/domain/entities/financeiro/plano-contas/plano-contas-nivel-3.entity';
import {PlanoContasFiltro} from '@app/abstraction/domain/interfaces/financeiro/plano-contas/plano-contas-filtro.interface';
import {PlanoContasListagem} from '@app/abstraction/domain/interfaces/financeiro/plano-contas/plano-contas-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PlanoContasFinanceiroService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}

  adicionarPlanoContasNivel1(planoContas: PlanoContasNivel1) {
    const result$ =
        this.client
            .post<PlanoContasNivel1>(
                `${API.FINANCEIRO_PLANO_CONTA_PRIMEIRO_NIVEL}`, planoContas)
            .pipe(
                map((json: PlanoContasNivel1) =>
                        json ? PlanoContasNivel1.from(json) : null));
    return lastValueFrom(result$);
  }
  adicionarPlanoContasNivel2(planoContas: PlanoContasNivel2) {
    const result$ =
        this.client
            .post<PlanoContasNivel2>(
                `${API.FINANCEIRO_PLANO_CONTA}/${planoContas.planoConta1Id}/${
                    API.SEGUNDO_NIVEL}`,
                planoContas)
            .pipe(
                map((json: PlanoContasNivel2) =>
                        json ? PlanoContasNivel2.from(json) : null));
    return lastValueFrom(result$);
  }
  adicionarPlanoContasNivel3(planoContas: PlanoContasNivel3) {
    const result$ =
        this.client
            .post<PlanoContasNivel3>(
                `${API.FINANCEIRO_PLANO_CONTA}/${planoContas.planoConta2Id}/${
                    API.TERCEIRO_NIVEL}`,
                planoContas)
            .pipe(
                map((json: PlanoContasNivel3) =>
                        json ? PlanoContasNivel3.from(json) : null));
    return lastValueFrom(result$);
  }
  alterarPlanoContasNivel1(planoContas: PlanoContasNivel1, id: string) {
    const result$ =
        this.client
            .put<PlanoContasNivel1>(
                `${API.FINANCEIRO_PLANO_CONTA_PRIMEIRO_NIVEL}/${id}`,
                planoContas)
            .pipe(
                map((json: PlanoContasNivel1) =>
                        json ? PlanoContasNivel1.from(json) : null));
    return lastValueFrom(result$);
  }
  alterarPlanoContasNivel2(planoContas: PlanoContasNivel2, id: string) {
    const result$ =
        this.client
            .put<PlanoContasNivel2>(
                `${API.FINANCEIRO_PLANO_CONTA}/${planoContas.planoConta1Id}/${
                    API.SEGUNDO_NIVEL}/${id}`,
                planoContas)
            .pipe(
                map((json: PlanoContasNivel2) =>
                        json ? PlanoContasNivel2.from(json) : null));
    return lastValueFrom(result$);
  }
  alterarPlanoContasNivel3(planoContas: PlanoContasNivel3, id: string) {
    const result$ =
        this.client
            .put<PlanoContasNivel3>(
                `${API.FINANCEIRO_PLANO_CONTA}/${planoContas.planoConta2Id}/${
                    API.TERCEIRO_NIVEL}/${id}`,
                planoContas)
            .pipe(
                map((json: PlanoContasNivel3) =>
                        json ? PlanoContasNivel3.from(json) : null));
    return lastValueFrom(result$);
  }
  buscarTodosNivel1() {
    const planosContas$ = this.client.get<PlanoContasListagem[]>(
        `${API.FINANCEIRO_PLANO_CONTA_PRIMEIRO_NIVEL}`);
    return lastValueFrom(planosContas$);
  }
  buscarTodosNivel2(filtro?: PlanoContasFiltro) {
    const planosContas$ =
        this.client.get<PlanoContasListagem[]>(this.geradorQueryString.gerar(
            API.FINANCEIRO_PLANO_CONTA_SEGUNDO_NIVEL, filtro));
    return lastValueFrom(planosContas$);
  }
  buscarTodosNivel3(filtro?: PlanoContasFiltro) {
    const planosContas$ =
        this.client.get<PlanoContasListagem[]>(this.geradorQueryString.gerar(
            API.FINANCEIRO_PLANO_CONTA_TERCEIRO_NIVEL, filtro));
    return lastValueFrom(planosContas$);
  }
  buscarNivel1PorId(planoContasNivel1Id: string) {
    const planoContasNivel1$ =
        this.client
            .get<PlanoContasNivel1>(
                `${API.FINANCEIRO_PLANO_CONTA_PRIMEIRO_NIVEL}/${
                    planoContasNivel1Id}`)
            .pipe(
                map((json: PlanoContasNivel1) =>
                        json ? PlanoContasNivel1.from(json) : null));
    return lastValueFrom(planoContasNivel1$);
  }
  buscarNivel2PorId(planoContasNivel2Id: string) {
    const planoContasNivel2$ =
        this.client
            .get<PlanoContasNivel2>(
                `${API.FINANCEIRO_PLANO_CONTA_SEGUNDO_NIVEL}/${
                    planoContasNivel2Id}`)
            .pipe(
                map((json: PlanoContasNivel2) =>
                        json ? PlanoContasNivel2.from(json) : null));
    return lastValueFrom(planoContasNivel2$);
  }
  buscarNivel3PorId(planoContasNivel3Id: string) {
    const planoContasNivel3$ =
        this.client
            .get<PlanoContasNivel3>(
                `${API.FINANCEIRO_PLANO_CONTA_TERCEIRO_NIVEL}/${
                    planoContasNivel3Id}`)
            .pipe(
                map((json: PlanoContasNivel3) =>
                        json ? PlanoContasNivel3.from(json) : null));
    return lastValueFrom(planoContasNivel3$);
  }
}
