import {Injectable} from '@angular/core';
import {GrupoListagem} from '@app/abstraction/domain/interfaces/produtos/grupo/grupo-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GrupoStore {
  private readonly _grupos = new BehaviorSubject<GrupoListagem[]>(null);
  get grupos(): GrupoListagem[] {
    return this._grupos.getValue();
  }
  set grupos(grupos: GrupoListagem[]) {
    this._grupos.next(grupos);
  }
  get grupos$() {
    return this._grupos.asObservable();
  }
  adicionar(grupo: GrupoListagem) {
    if (!this.grupos) {
      this.grupos = [];
    }
    this.grupos.push(grupo);
  }
  adicionarRange(grupos: GrupoListagem[]) {
    if (!this.grupos) {
      this.grupos = [];
    }
    grupos.forEach((grupo) => {
      if (!this.grupos.some((c) => c.id === grupo.id)) {
        this.grupos.push(grupo);
      }
    });
  }
}
