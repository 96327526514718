import {FormControl, FormGroup} from '@angular/forms';

export interface INcmPisCofinsIpiForm {
  ccsApurada: FormControl<string>;
  cofinsAliquota: FormControl<string|number>;
  ipiAliquota: FormControl<string|number>;
  ipiCstEntrada: FormControl<string>;
  ipiCstSaida: FormControl<string>;
  naturezaReceitaCodigo: FormControl<number>;
  pisAliquota: FormControl<string|number>;
  pisCofinsCstEntrada: FormControl<string>;
  pisCofinsCstSaida: FormControl<string>;
  pisCofinsTipo: FormControl<string>;
}

export class NcmPisCofinsIpiForm {
  static criarForm() {
    return new FormGroup<INcmPisCofinsIpiForm>({
      ccsApurada: new FormControl(null),
      cofinsAliquota: new FormControl(0),
      ipiAliquota: new FormControl(0),
      ipiCstEntrada: new FormControl(null),
      ipiCstSaida: new FormControl(null),
      naturezaReceitaCodigo: new FormControl(null),
      pisAliquota: new FormControl(0),
      pisCofinsCstEntrada: new FormControl(null),
      pisCofinsCstSaida: new FormControl(null),
      pisCofinsTipo: new FormControl(null),
    });
  }
}
