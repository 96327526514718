import {Component, Input} from '@angular/core';
import {UsuarioFavorito} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-favorito.interface';

@Component({
  selector: 'app-widget-favoritos',
  templateUrl: './widget-favoritos.component.html',
  styleUrls: ['./widget-favoritos.component.scss'],
})
export class WidgetFavoritosComponent {
  @Input() favoritos: UsuarioFavorito[];
}
