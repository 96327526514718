<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img
      src="assets/imagens/{{
        layoutService.config().colorScheme === 'light'
          ? 'g3-logo-dark'
          : 'g3-logo-light'
      }}.png"
      alt="Logo"
    />
  </a>

  <button
    #menubutton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi pi-bars"></i>
  </button>

  <p-megaMenu
    class="layout-topbar-menu m-0"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }"
    [model]="favoritos"
    [style]="{ 'z-index': 1 }"
  ></p-megaMenu>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }"
  >
    <span class="font-bold">
      {{ empresaLogada.documento | documento }} -
      {{ empresaLogada.nomeFantasia }}
    </span>
  </div>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }"
  >
    <button class="p-link layout-topbar-button" (click)="menu.toggle($event)">
      <i class="pi pi-user"></i>
      <span>Perfil</span>
    </button>
  </div>
</div>

<p-tieredMenu #menu [model]="items" [popup]="true">
</p-tieredMenu>
