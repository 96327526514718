import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CodigoFiscalFiltro} from '@app/abstraction/domain/interfaces/fiscal/codigo-fiscal/codigo-fiscal-filtro.interface';
import {CodigoFiscalListagem} from '@app/abstraction/domain/interfaces/fiscal/codigo-fiscal/codigo-fiscal-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CodigoFiscalService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  buscarTodos(filtro?: CodigoFiscalFiltro) {
    const response$ = this.client.get<CodigoFiscalListagem[]>(
        this.geradorQueryString.gerar(`${API.CODIGO_FISCAL_MANAGER}`, filtro));
    return lastValueFrom(response$);
  }
}
