export enum PessoaTipo {
  Nenhum,
  Fisica,
  Juridica,
}

export const PessoaTipoLabels = new Map<number, string>([
  [PessoaTipo.Fisica, 'FÍSICA'],
  [PessoaTipo.Juridica, 'JURÍDICA'],
]);
