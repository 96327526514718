import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NaturezaReceita} from '@app/abstraction/domain/entities/cadastros/natureza-receita/natureza-receita.entity';
import {Cest} from '@app/abstraction/domain/entities/fiscal/cest/cest.entity';
import {NcmIcms} from '@app/abstraction/domain/entities/fiscal/ncm/ncm-icms.entity';
import {NcmUf} from '@app/abstraction/domain/entities/fiscal/ncm/ncm-uf.entity';
import {Ncm} from '@app/abstraction/domain/entities/fiscal/ncm/ncm.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {NcmForm} from '@app/abstraction/domain/forms/fiscal/ncm/ncm.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {CustomDialogService} from '@app/shared/services/custom-dialog/custom-dialog.service';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-ncm-shared-form',
  templateUrl: './ncm-shared-form.component.html',
  styleUrls: ['./ncm-shared-form.component.scss']
})
export class NcmSharedFormComponent extends FormBaseComponent implements
    OnInit {
  @ViewChild('situacaoTemp', {static: true}) situacaoTemp: TemplateRef<any>;
  @ViewChild('acoesNcmUfTemp', {static: true}) acoesNcmUfTemp: TemplateRef<any>;
  @ViewChild('acoesNcmIcmsTemp', {static: true})
  acoesNcmIcmsTemp: TemplateRef<any>;
  @ViewChild('decimalTemp', {static: true}) decimalTemp: TemplateRef<any>;

  @Output() aoSalvar = new EventEmitter();

  ncmForm: NcmForm;
  colunasNcmUf: TableColumn[];
  colunasNcmIcms: TableColumn[];
  colunasNcmUfOcultas: TableColumn[];
  colunasNcmIcmsOcultas: TableColumn[];
  get ncmUf() {
    return this.ncmForm?.ncmUf;
  }
  get ncmIcms() {
    return this.ncmForm?.ncmIcms;
  }
  get ncmCodigo() {
    return this.ncmForm?.ncmCodigo;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get porcentagemDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposPorcentagem;
  }
  get porcentagemMask() {
    return this.mascaraService.decimal(this.porcentagemDecimais);
  }
  get porcentagemPlaceholder() {
    return this.mascaraService.placeholder(this.porcentagemDecimais);
  }
  get inteiroMask() {
    return this.mascaraService.inteiro();
  }
  constructor(
      private authFacade: AuthFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      private dialogService: CustomDialogService,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private montarColunasTabelaNcmUf() {
    this.colunasNcmUf = [
      {
        field: 'uf',
        name: 'Estado',
      },
      {
        field: 'importadosFederal',
        name: 'Importados (%)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
      {
        field: 'nacionalFederal',
        name: 'Nacional (%)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
      {
        field: 'estadual',
        name: 'Estadual (%)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
      {
        field: 'municipal',
        name: 'Municipal (%)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
      {
        field: 'ncmUfId',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesNcmUfTemp,

        width: 150,
      },
    ];
    this.montarColunasOcultasNcmUf();
  }
  private montarColunasTabelaNcmIcms() {
    this.colunasNcmIcms = [
      {
        field: 'uf',
        name: 'Estado',
      },
      {
        field: 'icmsAliquotaEntrada',
        name: 'ICMS Entrada',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
      {
        field: 'icmsAliquotaSaida',
        name: 'ICMS Saída',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimalTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
      {
        field: 'icmsCstCsosnEntrada',
        name: 'CST Entrada',
        headerClass: 'text-right',
        cellClass: 'text-right',
        hidden: this.deviceDetectorFacade.isMobile,
      },
      {
        field: 'icmsCstCsosnSaida',
        name: 'CST Saída',
        headerClass: 'text-right',
        cellClass: 'text-right',
        hidden: this.deviceDetectorFacade.isMobile,
      },
      {
        field: 'id',
        name: '',
        cellClass: 'text-center',
        cellTemplate: this.acoesNcmIcmsTemp,

        width: 150,
      },
    ];
    this.montarColunasOcultasNcmIcms();
  }
  private montarColunasOcultasNcmUf() {
    this.colunasNcmUfOcultas = this.colunasNcmUf.filter((e) => e.hidden);
  }
  private montarColunasOcultasNcmIcms() {
    this.colunasNcmIcmsOcultas = this.colunasNcmIcms.filter((e) => e.hidden);
  }
  abrirConfirmacaoExcluisaoNcmUf(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.ncmForm.excluirNcmUf(index);
      },
    });
  }
  abrirConfirmacaoAtualizarProduto() {
    return this.dialogService.confirmAsync({
      key: 'confirmacao-tributacao',
      message:
          'Esse NCM já está sendo utilizado em um ou mais produtos. Deseja atualizar os produtos com essa nova tributação?',
      header: 'Atenção',
      acceptLabel: 'Atualizar',
      rejectLabel: 'Cancelar',
      icon: 'pi pi-exclamation-triangle mr-3',
    });
  }
  abrirConfirmacaoExcluisaoNcmIcms(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Tem certeza que deseja excluir este registro?',
      header: 'Atenção',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.ncmForm.excluirNcmIcms(index);
      },
    });
  }
  abrirNcmUfModal(ncmUf?: NcmUf, index?: number) {
    this.ncmForm.abrirNcmUfModal(ncmUf, index);
  }
  abrirNcmIcmsModal(ncmIcms?: NcmIcms, index?: number) {
    this.ncmForm.abrirNcmIcmsModal(ncmIcms, index);
  }
  abrirCestBuscaRapidaModal() {
    this.ncmForm.abrirCestBuscaRapidaModal();
  }
  abrirNaturezaReceitaBuscaRapidaModal() {
    this.ncmForm.abrirNaturezaReceitaBuscaRapidaModal();
  }
  abrirNaturezaReceitaCadastroRapidoModal() {
    this.ncmForm.abrirNaturezaReceitaCadastroRapidoModal();
  }
  fecharNcmUfModal() {
    this.ncmForm.fecharNcmUfModal();
  }
  fecharNcmIcmsModal() {
    this.ncmForm.fecharNcmIcmsModal();
  }
  fecharCestBuscaRapidaModal() {
    this.ncmForm.fecharCestBuscaRapidaModal();
  }
  fecharNaturezaReceitaBuscaRapidaModal() {
    this.ncmForm.fecharNaturezaReceitaBuscaRapidaModal();
  }
  fecharNaturezaReceitaCadastroRapidoModal() {
    this.ncmForm.fecharNaturezaReceitaCadastroRapidoModal();
  }
  mostraOcultarColunaNcmUf(evento: any) {
    if (evento.value.length === 0) {
      this.colunasNcmUfOcultas.forEach((colunaOculta) => {
        this.colunasNcmUf.find((e) => e.field === colunaOculta.field).hidden =
            true;
      });
    } else if (evento.value.length === this.colunasNcmUfOcultas.length) {
      this.colunasNcmUfOcultas.forEach((colunaOculta) => {
        this.colunasNcmUf.find((e) => e.field === colunaOculta.field).hidden =
            false;
      });
    } else {
      this.colunasNcmUf.find((e) => e.field === evento.itemValue.field).hidden =
          !evento.itemValue.hidden;
    }
  }
  mostraOcultarColunaNcmIcms(evento: any) {
    if (evento.value.length === 0) {
      this.colunasNcmIcmsOcultas.forEach((colunaOculta) => {
        this.colunasNcmIcms.find((e) => e.field === colunaOculta.field).hidden =
            true;
      });
    } else if (evento.value.length === this.colunasNcmIcmsOcultas.length) {
      this.colunasNcmIcmsOcultas.forEach((colunaOculta) => {
        this.colunasNcmIcms.find((e) => e.field === colunaOculta.field).hidden =
            false;
      });
    } else {
      this.colunasNcmIcms.find((e) => e.field === evento.itemValue.field)
          .hidden = !evento.itemValue.hidden;
    }
  }
  ngOnInit() {
    this.montarColunasTabelaNcmUf();
    this.montarColunasTabelaNcmIcms();
    this.montarDropdownEstadosUf();
    this.montarDropdownProdutosServicos();
    this.montarDropdownProdutoOrigensString();
    this.montarDropdownProdutoCstsPorRegimeTributario(
        this.authFacade.empresaLogada?.regimeTributario);
    this.montarDropdownProdutoTiposPisCofins();
    this.montarDropdownCstsPisCofinsEntrada();
    this.montarDropdownCstsPisCofinsSaida();
    this.montarDropdownProdutoCcsApuradas();
    this.montarDropdownCstsIpiEntrada();
    this.montarDropdownCstsIpiSaida();
  }
  criarForm() {
    this.ncmForm = new NcmForm(
        this.errorMessagesFacade,
        this.toastrService,
    );
    this.ncmForm.criarForm();
    this.ncmForm.monitorarFormulario();
  }
  preencherObjeto() {
    const ncm = Ncm.from(this.ncmForm.form.value);
    ncm.toAPI();
    return ncm;
  }
  salvarNcmUf() {
    this.ncmForm.salvarNcmUf();
  }
  salvarNcmIcms() {
    this.ncmForm.salvarNcmIcms();
  }
  setarCestSelecionado(cestSelecionado: Cest) {
    this.ncmForm.setarCestSelecionado(cestSelecionado);
  }
  setarNaturezaReceitaSelecionado(naturezaReceitaSelecionado: NaturezaReceita) {
    this.ncmForm.setarNaturezaReceitaSelecionado(naturezaReceitaSelecionado);
  }
}
