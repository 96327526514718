export enum SituacaoLancamento {
  Aberto = 1,
  Parcial,
  Baixado,
  Vencido,
}

export const SituacaoLancamentoLabels = new Map<number, string>([
  [SituacaoLancamento.Aberto, 'NÃO PAGO'],
  [SituacaoLancamento.Parcial, 'PARCIAL'],
  [SituacaoLancamento.Baixado, 'PAGO'],
  [SituacaoLancamento.Vencido, 'VENCIDO'],
]);
