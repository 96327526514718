import {FormaParcelamentoListagem} from '@app/abstraction/domain/interfaces/financeiro/forma-parcelamento/forma-parcelamento-listagem.interface';
export class FormaParcelamento {
  id: string;
  descricao: string;
  quantidadeParcela: number;
  regraIntervaloDias: number;
  ativo: boolean;

  static from(value: any) {
    const formaParcelamento = new FormaParcelamento();
    formaParcelamento.id = value.id;
    formaParcelamento.descricao = value.descricao;
    formaParcelamento.ativo = value.ativo;
    formaParcelamento.quantidadeParcela = value.quantidadeParcela;
    formaParcelamento.regraIntervaloDias = value.regraIntervaloDias;
    return formaParcelamento;
  }
  toListagem() {
    return <FormaParcelamentoListagem>{
      id: this.id,
      descricao: this.descricao?.trim(),
      ativo: this.ativo,
      quantidadeParcela: this.quantidadeParcela,
      regraIntervaloDias: this.regraIntervaloDias,
    };
  }
}
