import {Injectable} from '@angular/core';
import {FormaParcelamentoService} from '@app/abstraction/data/services/financeiro/forma-parcelamento.service';
import {FormaParcelamentoStore} from '@app/abstraction/data/stores/financeiro/forma-parcelamento.store';
import {FormaParcelamento} from '@app/abstraction/domain/entities/financeiro/forma-parcelamento/forma-parcelamento.entity';
import {FormaParcelamentoFiltro} from '@app/abstraction/domain/interfaces/financeiro/forma-parcelamento/forma-parcelamento-filtro.interface';

@Injectable({providedIn: 'root'})
export class FormaParcelamentoFacade {
  get formasParcelamento() {
    return this.store.formasParcelamento;
  }
  get formasParcelamento$() {
    return this.store.formasParcelamento$;
  }
  constructor(
      private store: FormaParcelamentoStore,
      private service: FormaParcelamentoService) {}
  async adicionar(formaParcelamento: FormaParcelamento) {
    const novaFormaParcelamento =
        await this.service.adicionar(formaParcelamento);
    if (novaFormaParcelamento) {
      this.store.adicionar(novaFormaParcelamento.toListagem());
    }
    return novaFormaParcelamento;
  }
  async atualizarSituacao(id: string) {
    const formaParcelamento = this.formasParcelamento.find((p) => p.id === id);
    const status =
        await this.service.atualizarSituacao(id, !formaParcelamento.ativo);
    formaParcelamento.ativo = status;
    return status;
  }
  async buscarFormasParcelamento(filtro?: FormaParcelamentoFiltro) {
    const formasParcelamento = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(formasParcelamento);
  }
  limparListaFormasParcelamento() {
    this.store.formasParcelamento = null;
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  alterar(formaParcelamento: FormaParcelamento) {
    return this.service.alterar(formaParcelamento, formaParcelamento.id);
  }
}
