import {Injectable} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService} from 'primeng/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Injectable({providedIn: 'root'})
export class CustomDialogService {
  ref: DynamicDialogRef|undefined;

  constructor(
      private dialogService: DialogService,
      private confirmationService: ConfirmationService,
      private loadingService: NgxUiLoaderService,
  ) {}

  open(component: any, {
    data = {},
    header = '',
    baseZIndex = 10000,
    closeOnEscape = false,
    onClose = (_value: any): any => {}
  } = {}) {
    this.loadingService.stop();
    this.ref = this.dialogService.open(component, <DynamicDialogConfig>{
      data,
      header,
      baseZIndex,
      closeOnEscape,
    });
    this.ref.onClose.subscribe(onClose);
    return this.ref;
  }
  confirmAsync({
    key = '',
    message = '',
    header = '',
    acceptLabel = 'Yes',
    rejectLabel = 'No',
    icon = '',
  } = {}) {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        key,
        message,
        header,
        acceptLabel,
        rejectLabel,
        icon,
        closeOnEscape: false,
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }
}
