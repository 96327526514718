import {Pipe, PipeTransform} from '@angular/core';
import {UsuarioFacade} from '@app/abstraction/domain/facades/cadastros/usuario/usuario.facade';
import {UsuarioListagem} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-listagem.interface';

@Pipe({
  name: 'usuario',
})
export class UsuarioPipe implements PipeTransform {
  constructor(private usuarioFacade: UsuarioFacade) {}
  transform(id: string, campo: string = 'nome'): any {
    if (id) {
      const usuario =
          this.usuarioFacade.usuarios.find(
              (usuario: UsuarioListagem) => usuario.id == id) as any;
      return usuario?.[campo];
    }
    return '';
  }
}
