import {Injectable} from '@angular/core';
import {CarteiraService} from '@app/abstraction/data/services/financeiro/carteira.service';
import {CarteiraStore} from '@app/abstraction/data/stores/financeiro/carteira.store';

@Injectable({providedIn: 'root'})
export class CarteiraFacade {
  get carteiras() {
    return this.store.carteiras;
  }
  get carteiras$() {
    return this.store.carteiras$;
  }
  constructor(private store: CarteiraStore, private service: CarteiraService) {}
  async buscarCarteiras() {
    const carteiras = await this.service.buscarTodas();
    this.store.adicionarRange(carteiras);
  }
  limparListaCarteiras() {
    this.store.carteiras = null;
  }
}
