import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/shared/constants/api.constant';
import { DadosCnpj } from '@app/shared/models/cnpj-hub.entity';
import { DadosCpf } from '@app/shared/models/cpf-hub.entity';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiServicesService {
  constructor(private client: HttpClient) {}
  buscarCpf(cpf: string) {
    const response$ = this.client.get<DadosCpf>(
      `${API.MANAGER_SERVICES_CPF}/${cpf}/0`
    );
    return lastValueFrom(response$);
  }
  buscarCnpj(cnpj: string) {
    const response$ = this.client.get<DadosCnpj>(
      `${API.MANAGER_SERVICES_CNPJ}/${cnpj}`
    );
    return lastValueFrom(response$);
  }
}
