import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UsuarioAuth} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-auth.interface';
import {UsuarioNotificacao} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-notificacao.interface';
import {RegistroSimples} from '@app/abstraction/domain/interfaces/shared/registro-simples.interface';
import {API} from '@app/shared/constants/api.constant';
import {lastValueFrom, map} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private client: HttpClient) {}

  alterarNotificacao(notificacao: UsuarioNotificacao) {
    let notificacoes =
        (JSON.parse(localStorage.getItem('usuarioNotificacoes')) as
         Array<UsuarioNotificacao>) ??
        Array<UsuarioNotificacao>();
    const index = notificacoes.findIndex((e) => e.chave === notificacao.chave);
    notificacoes[index] = notificacao;
    localStorage.setItem('usuarioNotificacoes', JSON.stringify(notificacoes));

    return notificacao;
  }
  buscarUsuarioLogado() {
    const response$ =
        this.client.get<UsuarioAuth>(`${API.AUTH}`)
            .pipe(map(
                (json: UsuarioAuth) => json ? UsuarioAuth.from(json) : null));
    return lastValueFrom(response$);
  }
  cadastrar(registro: RegistroSimples) {
    const response$ =
        this.client.post<RegistroSimples>(`${API.REGISTRO_SIMPLES}`, registro);
    return lastValueFrom(response$);
  }

  definirSenhaPrimeiroAcesso(id: string, senha: string) {
    const response$ =
        this.client.put<any>(`${API.AUTH_PRIMEIRO_ACESSO}/${id}`, senha);
    return lastValueFrom(response$);
  }
}
