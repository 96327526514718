<div *ngIf="!barChart" class="div-skeleton"><p-skeleton width="100%" height="380px" borderRadius="5px"></p-skeleton></div>
<div *ngIf="barChart">
  <div class="flex bar-chart-title">
    <div
      class="xl:col-3 lg:col-3 md:col-3 sm:col-3 button-chart-div"
      *ngIf="barChart.mostrarBotaoRapido"
    >
      <button
        pButton
        type="button"
        [innerText]="barChart.textoBotaoRapido"
        class="secundary-button button-chart"
        (click)="buttonRedirect()"
      >
        Visualizar Pedidos
      </button>
    </div>
    <div
      [ngClass]="{
        'xl:col-12 lg:col-12 md:col-12 sm:col-12': !barChart.mostrarBotaoRapido,
        'xl:col-6 lg:col-6 md:col-6 sm:col-6 center-line-title':
          barChart.mostrarBotaoRapido
      }"
    >
      <i class="pi pi-chart-bar"></i>
      {{ barChart.titulo }}
    </div>
  </div>

  <div class="grid flex">
    <div class="xl:col-12 lg:col-12 md:col-12 sm:col-12 bar-chart-adjust">
      <p-chart
        type="bar"
        [data]="barChart.basicData"
        [options]="barChart.options"
        (onDataSelect)="onBarClicked($event)"
      ></p-chart>
    </div>
  </div>
</div>
