<div class="p-inputgroup">
  <span
    class="w-full"
    [ngClass]="{
      'p-input-icon-right': showClear && model
    }"
  >
    <i
      class="pi pi-times cursor-pointer"
      *ngIf="showClear && model"
      (click)="clearInput()"
    ></i>
    <input
      #contentInput
      type="text"
      class="inputfield w-full"
      pInputText
      appAutoFocus
      appUppercase
      appFoneMask
      [autocomplete]="autocomplete"
      [upperCase]="upperCase"
      [foneMask]="foneMask"
      [placeholder]="placeholder"
      [autofocus]="autoFocus"
      [mask]="mask"
      [validation]="false"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [ngClass]="{
        readonly: readonly,
        'text-right': textAlign === 'right',
        'text-center': textAlign === 'center',
        'text-left': textAlign === 'left'
      }"
      [(ngModel)]="model"
      [ngModelOptions]="modelOptions"
      (ngModelChange)="onNgModelChange($event)"
      (blur)="onBlur($event)"
      (keydown)="onKeyDown($event)"
    />
    <p-button
      *ngIf="showButton"
      type="button"
      styleClass="btn-five"
      [icon]="iconButton"
      (onClick)="onClick()"
      [disabled]="disabledButton"
    >
    </p-button>
  </span>
  <span
    *ngIf="showColorPicker"
    class="p-inputgroup-addon cursor-pointer"
    [colorPicker]
    [cpOutputFormat]="cpOutputFormat"
    [style.background]="model"
    [cpPositionOffset]="cpPositionOffset"
    [cpPositionRelativeToArrow]="cpPositionRelativeToArrow"
    (colorPickerChange)="onColorPickerChange($event)"
  ></span>
</div>
