export class MedidaCaseiraUtilizadaConst {
  constructor(_codigo: number, _descricao: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
  }
  codigo: number;
  descricao: string;
}

export const MedidasCaseirasUtilizadas = [
  new MedidaCaseiraUtilizadaConst(0, 'COLHER(ES) DE SOPA'),
  new MedidaCaseiraUtilizadaConst(1, 'COLHER(ES) DE CAFE'),
  new MedidaCaseiraUtilizadaConst(2, 'COLHER(ES) DE CHA'),
  new MedidaCaseiraUtilizadaConst(3, 'XICARA(S)'),
  new MedidaCaseiraUtilizadaConst(4, 'DE XICARA(S)'),
  new MedidaCaseiraUtilizadaConst(5, 'UNIDADE(S)'),
  new MedidaCaseiraUtilizadaConst(6, 'PACOTE(S)'),
  new MedidaCaseiraUtilizadaConst(7, 'FATIA(S)'),
  new MedidaCaseiraUtilizadaConst(8, 'FATIA(S) FINA(S)'),
  new MedidaCaseiraUtilizadaConst(9, 'PEDACO(S)'),
  new MedidaCaseiraUtilizadaConst(10, 'FOLHA(S)'),
  new MedidaCaseiraUtilizadaConst(11, 'PAO(ES)'),
  new MedidaCaseiraUtilizadaConst(12, 'BISCOITO(S)'),
  new MedidaCaseiraUtilizadaConst(13, 'BISNAQUINHA(S)'),
  new MedidaCaseiraUtilizadaConst(14, 'DISCO(S)'),
  new MedidaCaseiraUtilizadaConst(15, 'COPO(S)'),
  new MedidaCaseiraUtilizadaConst(16, 'PORCAO(OES)'),
  new MedidaCaseiraUtilizadaConst(17, 'TABLETE(S)'),
  new MedidaCaseiraUtilizadaConst(18, 'SACHE(S)'),
  new MedidaCaseiraUtilizadaConst(19, 'ALMODEGA(S)'),
  new MedidaCaseiraUtilizadaConst(20, 'BIFE(S)'),
  new MedidaCaseiraUtilizadaConst(21, 'FILE(S)'),
  new MedidaCaseiraUtilizadaConst(22, 'CONCHA(S)'),
  new MedidaCaseiraUtilizadaConst(23, 'BALA(S)'),
  new MedidaCaseiraUtilizadaConst(24, 'PRATO(S) FUNDO(S)'),
  new MedidaCaseiraUtilizadaConst(25, 'PITADA(S)'),
  new MedidaCaseiraUtilizadaConst(26, 'LATA(S)'),
];
