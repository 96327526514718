import {AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {Fornecedor} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor.entity';
import {FornecedorFacade} from '@app/abstraction/domain/facades/cadastros/fornecedor/fornecedor.facade';
import {FuncionarioFacade} from '@app/abstraction/domain/facades/cadastros/funcionario/funcionario.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {FornecedorSharedFormComponent} from '@app/shared/components/shared-forms/fornecedor-shared-form/fornecedor-shared-form.component';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-fornecedor-cadastro-rapido-modal',
  templateUrl: './fornecedor-cadastro-rapido-modal.component.html',
})
export class FornecedorCadastroRapidoModalComponent extends FormBaseComponent
    implements AfterViewInit {
  @ViewChild('acoesTemp', {static: true}) acoesTemp: TemplateRef<any>;
  @ViewChild('formComponent') formComponent: FornecedorSharedFormComponent;

  @Input() mostrarModal: boolean;
  @Input() fornecedor: Fornecedor;
  @Output() aoSalvar = new EventEmitter<Fornecedor>();
  @Output() aoCancelar = new EventEmitter();


  constructor(
      private enderecoTipoFacade: EnderecoTipoFacade,
      private fornecedorFacade: FornecedorFacade,
      private funcionarioFacade: FuncionarioFacade,
      private loadingService: NgxUiLoaderService,
      private planoContasFacade: PlanoContasFacade,
      confirmacaoService: ConfirmationService,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarFornecedor(fornecedor: Fornecedor) {
    return this.fornecedorFacade.adicionar(fornecedor).catch((e: any) => {
      this.loadingService.stop();
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(fornecedor: Fornecedor) {
    this.toastrService.success(`Fornecedor cadastrado com sucesso`);
    this.aoSalvar.emit(fornecedor);
    this.aoFecharModal();
    this.loadingService.stop();
  }
  async salvar() {
    try {
      this.loadingService.start();

      if (!(await this.formComponent.fornecedorForm.isValid())) {
        this.loadingService.stop();
        return;
      }

      const fornecedor = this.formComponent.preencherObjeto();

      const resultado = await this.adicionarFornecedor(fornecedor);

      if (resultado) this.mostrarSucessoAoSalvar(resultado);
    } catch (error) {
      this.toastrService.error('Erro ao salvar fornecedor.');
      this.loadingService.stop();
    }
  }
  async ngAfterViewInit() {
    await this.funcionarioFacade.buscarFuncionarios();
    this.formComponent.criarForm();
  }
  async aoAbrirModal() {
    this.formComponent.criarForm();
    Promise
        .all([
          this.enderecoTipoFacade.buscarEnderecoTipos(),
          await this.planoContasFacade.buscarPlanosContasNivel3({ativo: true}),
        ])
        .then(() => {
          this.formComponent.fornecedorForm.setarTipoFormulario();
          this.formComponent.fornecedorForm.form.controls.documento.setValue(
              this.fornecedor?.documento);
          this.formComponent.fornecedorForm.consultarDocumentoValido();
        });
  }
  aoFecharModal() {
    this.formComponent.fornecedorForm.form.reset();
    this.aoCancelar.emit();
  }
}
