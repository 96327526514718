import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Ncm} from '@app/abstraction/domain/entities/fiscal/ncm/ncm.entity';
import {NcmFacade} from '@app/abstraction/domain/facades/fiscal/ncm/ncm.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {NcmSharedFormComponent} from '@app/shared/components/shared-forms/ncm-shared-form/ncm-shared-form.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-ncm-cadastro-rapido-modal',
  templateUrl: './ncm-cadastro-rapido-modal.component.html',
})
export class NcmCadastroRapidoModalComponent extends FormBaseComponent
    implements AfterViewInit {
  @ViewChild('formComponent') formComponent: NcmSharedFormComponent;
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<Ncm>();
  @Output() aoCancelar = new EventEmitter();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }

  constructor(
      private ncmFacade: NcmFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarNcm(ncm: Ncm) {
    return this.ncmFacade.adicionar(ncm).catch((e: any) => {
      this.saveLoading = false;
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(ncm: Ncm) {
    this.toastrService.success(`NCM cadastrada com sucesso`);
    this.aoSalvar.emit(ncm);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.formComponent.ncmForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const ncm = this.formComponent.preencherObjeto();

      const resultado = await this.adicionarNcm(ncm)

      if (resultado) this.mostrarSucessoAoSalvar(ncm);

    } catch (error) {
      this.toastrService.error('Erro ao salvar ncm.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.formComponent.criarForm();
  }
  fecharModal() {
    this.aoCancelar.emit();
  }
  ngAfterViewInit() {
    this.formComponent.criarForm();
  }
}
