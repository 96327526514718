import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FuncionarioFacade} from '@app/abstraction/domain/facades/cadastros/funcionario/funcionario.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FuncionarioFiltro} from '@app/abstraction/domain/interfaces/cadastros/funcionario/funcionario-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {FuncionarioTipo} from '@app/shared/enums/funcionario-tipo.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-funcionario-busca-rapida',
  templateUrl: './funcionario-busca-rapida.component.html',
})
export class FuncionarioBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('documentoTemp', {static: true}) documentoTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Input() funcionariosIdSelecionados: string[];
  @Input() header: string = 'Lista de funcionários';
  @Input() funcionarioTipo: FuncionarioTipo = FuncionarioTipo.Todos;
  @Input() filtro = <FuncionarioFiltro>{};
  @Input() multiSelect: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  get dados() {
    if (this.funcionariosIdSelecionados?.length) {
      return this.funcionarioFacade.funcionarios?.filter((funcionario) => {
        return !this.funcionariosIdSelecionados.some(
            (id) => id === funcionario.id);
      });
    }
    return this.funcionarioFacade.funcionarios;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get desabilitarBotaoSelecionar() {
    return !this.itensTabelaSelecionados;
  }
  constructor(
      private funcionarioFacade: FuncionarioFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarFuncionariosComFiltro() {
    this.funcionarioFacade.buscarFuncionarios({
      pagina: this.pagina,
      limite: this.limite,
      nome: this.filtro.nome,
      ativo: this.filtro.ativo,
      consultaSimples: this.filtro.consultaSimples,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'nomeRazaoSocial',
        name: 'Nome/Razão Social',
      },
      {
        field: 'nomeFantasia',
        name: 'Nome fantasia',
      },
      {
        field: 'documento',
        name: 'Documento',
        cellTemplate: this.documentoTemp,
      },
      {
        field: 'ativo',
        name: 'Situação',
        headerClass: 'text-center',
        cellClass: 'text-center',

        cellTemplate: this.situacaoTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao(1, 1000);
    this.filtro.nome = null;
    this.itensTabelaSelecionados = [];
    this.funcionarioFacade.limparListaFuncionarios();
    this.buscarFuncionariosComFiltro();
  }
  buscarFuncionariosProximaPagina() {
    this.pagina++;
    this.buscarFuncionariosComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.funcionarioFacade.limparListaFuncionarios();
    this.buscarFuncionariosComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itensTabelaSelecionados = [];
    this.funcionarioFacade.limparListaFuncionarios();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(
        Array.isArray(this.itensTabelaSelecionados) ?
            this.itensTabelaSelecionados :
            [this.itensTabelaSelecionados]);
    this.limparPaginacao();
  }
}
