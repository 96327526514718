export enum SituacaoDebitoConvenio {
  Pagos = 1,
  Receber,
  Vencidos,
}

export const SituacaoDebitoConvenioLabels = new Map<number, string>([
  [SituacaoDebitoConvenio.Pagos, 'Pagos'],
  [SituacaoDebitoConvenio.Receber, 'Receber'],
  [SituacaoDebitoConvenio.Vencidos, 'Vencidos'],
]);
