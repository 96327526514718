import {Component, ElementRef, Input, ViewChild,} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NgxCurrencyConfig} from 'ngx-currency';

import {BaseInput} from '../base/base-input';

@Component({
  selector: 'app-textgroup-input',
  templateUrl: './textgroup-input.component.html',
  styleUrls: ['./textgroup-input.component.scss'],
})
export class TextGroupInputComponent extends BaseInput {
  @Input() control: FormControl;
  @Input() addon: string;
  @Input()
  options: NgxCurrencyConfig = <NgxCurrencyConfig>{
    align: 'right',
    allowNegative: false,
    allowZero: true,
    decimal: ',',
    precision: 0,
    prefix: '',
    suffix: '',
    thousands: '.',
    nullable: true,
  };
  @Input() placeholder: string = '';

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
}
