<p-dialog
  class="lista-erros-dialog"
  [header]="titulo"
  [style]="{ width: isDesktop ? '40%' : '90%' }"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharModal()"
  [(visible)]="mostrarModal"
>
  <app-table [columns]="colunas" [rows]="erros"></app-table>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Fechar"
      styleClass="btn-five mt-4"
    ></p-button>
  </ng-template>
</p-dialog>
