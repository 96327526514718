export class NotaFiscalImposto {
  aliquotaCofins?: number;
  aliquotaDiferimento?: number;
  aliquotaIcms?: number;
  aliquotaIcmsFcp?: number;
  aliquotaIcmsST?: number;
  aliquotaIcmsSTFcp?: number;
  aliquotaIcmsSTFcpRetido?: number;
  aliquotaIpi?: number;
  aliquotaIvaMva?: number;
  aliquotaPis?: number;
  aliquotaReducaoIcms?: number;
  aliquotaReducaoIcmsST?: number;
  baseCalculoIcms?: number;
  baseCalculoIcmsFcp?: number;
  baseCalculoIcmsST?: number;
  baseCalculoIcmsSTFcp?: number;
  baseCalculoIcmsSTFcpRetido?: number;
  baseCalculoIcmsSTRetido?: number;
  baseCalculoIpi?: number;
  baseCalculoPisCofins?: number;
  icmsCstCsosn?: string;
  ipiCst?: string;
  modalidade?: number;
  modalidadeST?: number;
  motivoDesoneracaoIcms?: number;
  origem?: number;
  pisCofinsCst?: string;
  valorCofins?: number;
  valorIcms?: number;
  valorIcmsDesonerado?: number;
  valorIcmsDiferido?: number;
  valorIcmsFcp?: number;
  valorIcmsOperacao?: number;
  valorIcmsST?: number;
  valorIcmsSTFcp?: number;
  valorIcmsSTFcpRetido?: number;
  valorIcmsSTRetido?: number;
  valorIpi?: number;
  valorPis?: number;
  valorTotalTributo: number;

  static from(json: any = {}): NotaFiscalImposto {
    const imposto = new NotaFiscalImposto();
    imposto.aliquotaCofins = json.aliquotaCofins;
    imposto.aliquotaDiferimento = json.aliquotaDiferimento;
    imposto.aliquotaIcms = json.aliquotaIcms;
    imposto.aliquotaIcmsFcp = json.aliquotaIcmsFcp;
    imposto.aliquotaIcmsST = json.aliquotaIcmsST;
    imposto.aliquotaIcmsSTFcp = json.aliquotaIcmsSTFcp;
    imposto.aliquotaIcmsSTFcpRetido = json.aliquotaIcmsSTFcpRetido;
    imposto.aliquotaIpi = json.aliquotaIpi;
    imposto.aliquotaIvaMva = json.aliquotaIvaMva;
    imposto.aliquotaPis = json.aliquotaPis;
    imposto.aliquotaReducaoIcms = json.aliquotaReducaoIcms;
    imposto.aliquotaReducaoIcmsST = json.aliquotaReducaoIcmsST;
    imposto.baseCalculoIcms = json.baseCalculoIcms;
    imposto.baseCalculoIcmsFcp = json.baseCalculoIcmsFcp;
    imposto.baseCalculoIcmsST = json.baseCalculoIcmsST;
    imposto.baseCalculoIcmsSTFcp = json.baseCalculoIcmsSTFcp;
    imposto.baseCalculoIcmsSTFcpRetido = json.baseCalculoIcmsSTFcpRetido;
    imposto.baseCalculoIcmsSTRetido = json.baseCalculoIcmsSTRetido;
    imposto.baseCalculoIpi = json.baseCalculoIpi;
    imposto.baseCalculoPisCofins = json.baseCalculoPisCofins;
    imposto.icmsCstCsosn = json.icmsCstCsosn?.trim();
    imposto.ipiCst = json.ipiCst?.trim();
    imposto.modalidade = json.modalidade;
    imposto.modalidadeST = json.modalidadeST;
    imposto.motivoDesoneracaoIcms = json.motivoDesoneracaoIcms;
    imposto.origem = json.origem;
    imposto.pisCofinsCst = json.pisCofinsCst?.trim();
    imposto.valorCofins = json.valorCofins;
    imposto.valorIcms = json.valorIcms;
    imposto.valorIcmsDesonerado = json.valorIcmsDesonerado;
    imposto.valorIcmsDiferido = json.valorIcmsDiferido;
    imposto.valorIcmsFcp = json.valorIcmsFcp;
    imposto.valorIcmsOperacao = json.valorIcmsOperacao;
    imposto.valorIcmsST = json.valorIcmsST;
    imposto.valorIcmsSTFcp = json.valorIcmsSTFcp;
    imposto.valorIcmsSTFcpRetido = json.valorIcmsSTFcpRetido;
    imposto.valorIcmsSTRetido = json.valorIcmsSTRetido;
    imposto.valorIpi = json.valorIpi;
    imposto.valorPis = json.valorPis;
    imposto.valorTotalTributo = json.valorTotalTributo;
    return imposto;
  }
}
