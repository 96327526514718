import {Injectable} from '@angular/core';
import {FuncionarioListagem} from '@app/abstraction/domain/interfaces/cadastros/funcionario/funcionario-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FuncionarioStore {
  private readonly _funcionarios =
      new BehaviorSubject<FuncionarioListagem[]>(null);

  get funcionarios(): FuncionarioListagem[] {
    return this._funcionarios.getValue();
  }
  set funcionarios(funcionarios: FuncionarioListagem[]) {
    this._funcionarios.next(funcionarios);
  }
  get funcionarios$() {
    return this._funcionarios.asObservable();
  }

  adicionarRange(funcionarios: FuncionarioListagem[]) {
    if (!this.funcionarios) {
      this.funcionarios = [];
    }

    funcionarios.forEach((funcionario) => {
      if (!this.funcionarios.some((c) => c.id === funcionario.id)) {
        this.funcionarios.push(funcionario);
      }
    });
  }
}
