<p-toolbar styleClass="header" [ngClass]="{ 'header-fixed': empresaLogada }">
  <div class="p-toolbar-group-left">
    <a routerLink="" class="brand"
      ><img
        [attr.src]="
          mostrarLogoDark
            ? 'assets/imagens/g3-logo-dark.png'
            : 'assets/imagens/g3-logo-light.png'
        "
      /><strong>ERP</strong>Admin</a
    >
    <div *ngIf="!esconderMenu">
      <p-megaMenu *ngIf="mostrarFavoritosBarra" [model]="megaMenu"></p-megaMenu>
    </div>
  </div>
  <div class="p-toolbar-group-center">
    <span class="nome-empresa">
      {{ empresaLogada.documento | documento }} - {{ empresaLogada.nomeFantasia }}
    </span>
  </div>
  <div class="p-toolbar-group-right">
    <div class="acoes">
      <i
        pBadge
        class="pi pi-bell text-xl mr-2 botao-notificacoes cursor-pointer"
        *ngIf="mostrarNotificacoes"
        (click)="abrirNotificacoes()"
      ></i>
      <i
        *ngIf="!mostrarNotificacoes"
        class="pi pi-bell text-xl mr-2 botao-notificacoes cursor-pointer"
        (click)="abrirNotificacoes()"
      ></i>
      <p-button
        icon="pi pi-bars"
        class="p-button-lg"
        class="botao-notificacoes"
        [text]="true"
        [plain]="true"
        [rounded]="true"
        (onClick)="abrirMenuDireito()"
        *ngIf="esconderMenu"
      ></p-button>
      <div class="seletor-tema" *ngIf="!esconderMenu">
        <div
          class="tema-item"
          [ngClass]="[temaDescricao]"
          (click)="menuTema.toggle($event)"
        ></div>
      </div>
    </div>
    <div
      class="inline-flex cursor-pointer dropdown"
      (click)="menu.toggle($event)"
      *ngIf="!esconderMenu"
    >
      <p-avatar
        image="assets/imagens/default-avatar.png"
        styleClass="mr-2"
        shape="circle"
      ></p-avatar>
      <div class="menu">
        <span class="mr-1">{{ usuarioNome }}</span>
        <span class="caret"> <i class="pi pi-chevron-down"></i></span>
      </div>
    </div>
  </div>
</p-toolbar>
<p-tieredMenu #menu [model]="itens" [popup]="true"></p-tieredMenu>
<p-tieredMenu #menuTema [model]="temaItens" [popup]="true"></p-tieredMenu>
