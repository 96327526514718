import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {authConfig} from '@app/core/auth/config';
import {OAuthService, TokenResponse} from 'angular-oauth2-oidc';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';
import {from} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OAuthFacade {
  constructor(
      private oauthService: OAuthService,
      private authFacade: AuthFacade,
      private router: Router,
      private activatedRoute: ActivatedRoute,
  ) {}
  get isLoggedIn() {
    return this.oauthService.hasValidIdToken() ||
        this.oauthService.hasValidAccessToken();
  }
  async login(email: string, senha: string): Promise<TokenResponse> {
    if (!this.isLoggedIn) {
      return this.oauthService.fetchTokenUsingPasswordFlow(email, senha)
    }
    return null;
  }
  async logout(redirectToLogin: boolean = false) {
    this.oauthService.logOut(true);
    this.authFacade.removerEmpresaSelecionada();
    if (redirectToLogin) this.router.navigate(['/auth/login']);
  }
  async iniciarSSO() {
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    await this.oauthService.loadDiscoveryDocument();

    if (this.isLoggedIn) {
      const url = this.activatedRoute.snapshot.queryParams['returnUrl'];
      this.router.navigate(url ?? ['/dashboard']);
    } else if (this.router.url.includes('primeiro-acesso')) {
      return;
    } else {
      this.router.navigate(['/auth/login']);
    }
  }
  refreshToken() {
    return from(this.oauthService.refreshToken());
  }
}
