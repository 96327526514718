import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CestFiltro} from '@app/abstraction/domain/interfaces/fiscal/cest/cest-filtro.interface';
import {CestListagem} from '@app/abstraction/domain/interfaces/fiscal/cest/cest-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CestService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}
  buscarTodos(filtro?: CestFiltro) {
    const response$ = this.client.get<CestListagem[]>(
        this.geradorQueryString.gerar(`${API.CEST}`, filtro));
    return lastValueFrom(response$);
  }
}
