import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FornecedorFacade} from '@app/abstraction/domain/facades/cadastros/fornecedor/fornecedor.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FornecedorFiltro} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-fornecedor-busca-rapida',
  templateUrl: './fornecedor-busca-rapida.component.html',
})
export class FornecedorBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('documentoTemp', {static: true}) documentoTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  filtro = <FornecedorFiltro>{};
  itemSelecionado: any;
  mostrarFornecedorCadastroRapidoModal: boolean;
  get dados$() {
    return this.fornecedorFacade.fornecedores$;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private fornecedorFacade: FornecedorFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarFornecedoresComFiltro() {
    this.fornecedorFacade.buscarFornecedores({
      pagina: this.pagina,
      limite: this.limite,
      nome: this.filtro.nome,
      numeroTelefone: this.filtro.numeroTelefone,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'nomeRazaoSocial',
        name: 'Nome/Razão Social',
      },
      {
        field: 'nomeFantasia',
        name: 'Nome fantasia',
      },
      {
        field: 'documento',
        name: 'Documento',
        cellTemplate: this.documentoTemp,
      },
      {
        field: 'ativo',
        name: 'Situação',
        headerClass: 'text-center',
        cellClass: 'text-center',

        cellTemplate: this.situacaoTemp,
        hidden: this.deviceDetectorFacade.isMobile,
      },
    ];
  }
  abrirModal() {
    this.iniciarPaginacao();
    this.filtro.nome = null;
    this.fornecedorFacade.limparListaFornecedores();
    this.buscarFornecedoresComFiltro();
  }
  abrirFornecedorCadastroRapidoModal() {
    this.mostrarFornecedorCadastroRapidoModal = true;
  }
  buscarFornecedoresProximaPagina() {
    this.pagina++;
    this.buscarFornecedoresComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.fornecedorFacade.limparListaFornecedores();
    this.buscarFornecedoresComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itemSelecionado = null;
    this.fornecedorFacade.limparListaFornecedores();
    this.limparPaginacao();
  }
  fecharFornecedorCadastroRapidoModal() {
    this.mostrarFornecedorCadastroRapidoModal = false;
  }
  ngOnInit() {
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(this.itemSelecionado);
    this.itemSelecionado = null;
    this.limparPaginacao();
  }
  setarItemSelecionado(evento: any) {
    this.itemSelecionado = evento;
  }
  selecionarFornecedorCadastrado(evento: any) {
    this.setarItemSelecionado(evento);
    this.selecionar();
  }
}
