import {Injectable} from '@angular/core';
import {Cest} from '@app/abstraction/domain/entities/fiscal/cest/cest.entity';
import {CestListagem} from '@app/abstraction/domain/interfaces/fiscal/cest/cest-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CestStore {
  private readonly _cests = new BehaviorSubject<CestListagem[]>(null);

  get cests(): CestListagem[] {
    return this._cests.getValue();
  }
  set cests(cests: CestListagem[]) {
    this._cests.next(cests);
  }
  get cests$() {
    return this._cests.asObservable();
  }
  adicionar(cest: Cest) {
    if (!this.cests) {
      this.cests = [];
    }
    this.cests.push(cest);
  }
  adicionarRange(cests: CestListagem[]) {
    if (!this.cests) {
      this.cests = [];
    }

    cests.forEach((cest) => {
      if (!this.cests.some((c) => c.id === cest.id)) {
        this.cests.push(cest);
      }
    });
  }
}
