import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {OutputFormat} from 'ngx-color-picker';

@Component({
  selector: 'app-text-input-ngmodel',
  templateUrl: './text-input-ngmodel.component.html',
  styleUrls: ['./text-input-ngmodel.component.scss']
})
export class TextInputNgModelComponent {
  @Input() upperCase: boolean = true;
  @Input() formName: string;
  @Input() autoFocus: boolean;
  @Input() foneMask: boolean;
  @Input() textAlign: string = 'left';  // left, right, center
  @Input() mask: string;
  @Input() placeholder: string = '';
  @Input() readonly: boolean;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() showButton: boolean;
  @Input() iconButton: string;
  @Input() disabledButton: boolean;
  @Input() showColorPicker: boolean;
  @Input() cpOutputFormat: OutputFormat = 'hex';
  @Input() cpPositionOffset: string = '50%';
  @Input() cpPositionRelativeToArrow: boolean = true;
  @Input() model: any = '';
  @Input() modelOptions: any;
  @Input() showClear: boolean = false;
  @Input() autocomplete: string;
  @Output() buttonOnClick = new EventEmitter();
  @Output() inputBlur = new EventEmitter();
  @Output() inputKeydown = new EventEmitter();
  @Output() colorPickerChange = new EventEmitter();
  @Output() modelChange = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
  onClick() {
    this.buttonOnClick.emit();
  }
  onBlur(event: any) {
    this.inputBlur.emit(event);
  }
  onKeyDown(event: any) {
    this.inputKeydown.emit(event);
  }
  onColorPickerChange(event: any) {
    this.model = event;
    this.onNgModelChange(this.model);
    this.colorPickerChange.emit(event);
  }
  onNgModelChange(event: any) {
    this.modelChange.emit(event);
  }
  clearInput() {
    this.model = '';
    this.onNgModelChange(this.model);
  }
}
