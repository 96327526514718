import {Injectable} from '@angular/core';
import {PessoaService} from '@app/abstraction/data/services/shared/pessoa.service';
import {PessoaStore} from '@app/abstraction/data/stores/shared/pessoa.store';
import {PessoaFiltro} from '@app/abstraction/domain/interfaces/cadastros/pessoa/pessoa-filtro.interface';

@Injectable({providedIn: 'root'})
export class PessoaFacade {
  get pessoas() {
    return this.store.pessoas;
  }
  get pessoas$() {
    return this.store.pessoas$;
  }
  constructor(private store: PessoaStore, private service: PessoaService) {}
  async buscarPessoas(filtro?: PessoaFiltro) {
    const pessoas = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(pessoas);
    return pessoas;
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  buscarPorDocumento(documento: string) {
    return this.service.buscarPorDocumento(documento);
  }
  limparListaPessoas() {
    this.store.pessoas = null;
  }
}
