import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Familia} from '@app/abstraction/domain/entities/produtos/familia/familia.entity';
import {FamiliaFiltro} from '@app/abstraction/domain/interfaces/produtos/familia/familia-filtro.interface';
import {FamiliaListagem} from '@app/abstraction/domain/interfaces/produtos/familia/familia-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FamiliaService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Familia) {
    const response$ =
        this.client.post<Familia>(API.FAMILIA, dados)
            .pipe(map((json: Familia) => json ? Familia.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Familia, id: string) {
    const response$ =
        this.client.put<Familia>(`${API.FAMILIA}/${id}`, dados)
            .pipe(map((json: Familia) => json ? Familia.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.FAMILIA}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Familia>(`${API.FAMILIA}/${id}`)
            .pipe(map((json: Familia) => json ? Familia.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: FamiliaFiltro) {
    const response$ = this.client.get<FamiliaListagem[]>(
        this.geradorQueryString.gerar(`${API.FAMILIA}`, filtro));
    return lastValueFrom(response$);
  }
}
