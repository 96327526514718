<p-table
  [columns]="columns"
  responsiveLayout="scroll"
  [value]="value"
  styleClass="p-datatable-gridlines p-datatable-sm p-datatable-striped"
  dataKey="tabela-produtos-skeleton"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3rem" *ngIf="checkboxable">
        <p-skeleton width="1.3rem" height="1.3rem"></p-skeleton>
      </th>
      <ng-container *ngFor="let col of columns">
        <th>
          <p-skeleton height="1.3rem"></p-skeleton>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngIf="checkboxable">
        <p-skeleton width="1.3rem" height="1.3rem"></p-skeleton>
      </td>
      <td *ngFor="let col of columns">
        <p-skeleton height="1.3rem"></p-skeleton>
      </td>
    </tr>
  </ng-template>
</p-table>
