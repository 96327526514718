import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgxCurrencyConfig} from 'ngx-currency';

@Component({
  selector: 'app-numeric-input-ngmodel',
  templateUrl: './numeric-input-ngmodel.component.html',
  styleUrls: ['./numeric-input-ngmodel.component.scss']
})
export class NumericInputNgModelComponent {
  @Input() autoFocus: boolean;
  @Input() textAlign: string = 'right';  // left, right, center
  @Input() onlyNumber: boolean = false;
  @Input()
  options: NgxCurrencyConfig = <NgxCurrencyConfig>{
    align: 'right',
    allowNegative: false,
    allowZero: true,
    precision: 0,
    prefix: '',
    suffix: '',
    thousands: '',
    nullable: true,
  };
  @Input() placeholder: string = '';
  @Input() readonly: boolean;
  @Input() showButton: boolean;
  @Input() iconButton: string;
  @Input() buttonDisabled: boolean;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() model: any;
  @Input() modelOptions: any;
  @Output() buttonOnClick = new EventEmitter();
  @Output() inputBlur = new EventEmitter();
  @Output() inputKeydown = new EventEmitter();
  @Output() inputKeyup = new EventEmitter();
  @Output() modelChange = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;

  onClick() {
    this.buttonOnClick.emit();
  }
  onBlur(event: any) {
    this.inputBlur.emit(event);
  }
  onKeyDown(event: any) {
    this.inputKeydown.emit(event);
  }
  onKeyUp(event: any) {
    this.inputKeyup.emit(event);
  }
  onNgModelChange(event: any) {
    this.modelChange.emit(event);
  }
}
