import {Injectable} from '@angular/core';
import {BancoService} from '@app/abstraction/data/services/financeiro/banco.service';
import {BancoStore} from '@app/abstraction/data/stores/financeiro/banco.store';
import {Banco} from '@app/abstraction/domain/entities/financeiro/banco/banco.entity';
import {BancoFiltro} from '@app/abstraction/domain/interfaces/financeiro/banco/banco-filtro.interface';

@Injectable({providedIn: 'root'})
export class BancoFacade {
  get bancos() {
    return this.store.bancos;
  }
  get bancos$() {
    return this.store.bancos$;
  }
  constructor(private store: BancoStore, private service: BancoService) {}
  async atualizarSituacao(id: string) {
    const banco = this.bancos.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !banco.ativo);
    banco.ativo = status;
    return status;
  }
  async adicionar(banco: Banco) {
    const novoBanco = await this.service.adicionar(banco);
    if (novoBanco) {
      this.store.adicionar(novoBanco.toListagem());
    }
    return novoBanco;
  }
  async buscarBancos(filtro?: BancoFiltro) {
    const bancos = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(bancos);
  }
  alterar(banco: Banco) {
    return this.service.alterar(banco, banco.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaBancos() {
    this.store.bancos = null;
  }
}
