import {FormControl, FormGroup} from '@angular/forms';
import {Grupo} from '@app/abstraction/domain/entities/produtos/grupo/grupo.entity';
import {Subgrupo} from '@app/abstraction/domain/entities/produtos/subgrupo/subgrupo.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface ISubgrupoForm {
  id: FormControl<string>;
  descricao: FormControl<string>;
  grupoId: FormControl<string>;
  ativo: FormControl<boolean>;
}

export class SubgrupoForm extends BaseForm {
  form: FormGroup<ISubgrupoForm>;
  operacaoForm: OperacaoForm;
  mostrarGrupoCadastroRapidoModal: boolean;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Novo subgrupo' : 'Editar subgrupo';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(private errorMessagesFacade: ErrorMessageFacade) {
    super();
  }
  private preencherForm(value: Subgrupo) {
    this.form.patchValue(value);
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  criarForm() {
    this.form = new FormGroup({
      id: new FormControl(null),
      descricao: new FormControl(
          null, CustomValidators.required('Descrição é obrigatória')),
      grupoId: new FormControl(
          null, CustomValidators.required('Subgrupo é obrigatório')),
      ativo: new FormControl(true),
    });
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(Subgrupo.from(value));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
  abrirGrupoCadastroRapidoModal() {
    this.mostrarGrupoCadastroRapidoModal = true;
  }
  fecharGrupoCadastroRapidoModal() {
    this.mostrarGrupoCadastroRapidoModal = false;
  }
  setarGrupoCadastrado(grupo: Grupo) {
    this.form.controls.grupoId.setValue(grupo.id);
  }
}
