import {TabelaNutricionalListagem} from '@app/abstraction/domain/interfaces/produtos/tabela-nutricional/tabela-nutricional-listagem.interface';
export class TabelaNutricional {
  ativo: boolean;
  calcioQuantidadePorcao: number;
  calcioValorDiario: number;
  carboidratosQuantidadePorcao: number;
  carboidratosValorDiario: number;
  colesterolQuantidadePorcao: number;
  colesterolValorDiario: number;
  ferroQuantidadePorcao: number;
  ferroValorDiario: number;
  fibraAlimentarQuantidadePorcao: number;
  fibraAlimentarValorDiario: number;
  gorduraTransQuantidadePorcao: number;
  gorduraTransValorDiario: number;
  gordurasSaturadasQuantidadePorcao: number;
  gordurasSaturadasValorDiario: number;
  gordurasTotaisQuantidadePorcao: number;
  gordurasTotaisValorDiario: number;
  medidaCaseiraParteDecimal: number;
  medidaCaseiraParteInteira: number;
  medidaCaseiraUtilizada: number;
  porcaoDe: string;
  porcaoQuantidade: number;
  porcaoUnidade: number;
  proteinasQuantidadePorcao: number;
  proteinasValorDiario: number;
  sodioQuantidadePorcao: number;
  sodioValorDiario: number;
  id: string;
  valorEnergeticoQuantidadePorcao: number;
  valorEnergeticoValorDiario: number;

  static from(value: any) {
    const tabelaNutricional = new TabelaNutricional();
    tabelaNutricional.id = value.id;
    tabelaNutricional.ativo = value.ativo;
    tabelaNutricional.calcioQuantidadePorcao = value.calcioQuantidadePorcao;
    tabelaNutricional.calcioValorDiario = value.calcioValorDiario;
    tabelaNutricional.carboidratosQuantidadePorcao =
        value.carboidratosQuantidadePorcao;
    tabelaNutricional.carboidratosValorDiario = value.carboidratosValorDiario;
    tabelaNutricional.colesterolQuantidadePorcao =
        value.colesterolQuantidadePorcao;
    tabelaNutricional.colesterolValorDiario = value.colesterolValorDiario;
    tabelaNutricional.ferroQuantidadePorcao = value.ferroQuantidadePorcao;
    tabelaNutricional.ferroValorDiario = value.ferroValorDiario;
    tabelaNutricional.fibraAlimentarQuantidadePorcao =
        value.fibraAlimentarQuantidadePorcao;
    tabelaNutricional.fibraAlimentarValorDiario =
        value.fibraAlimentarValorDiario;
    tabelaNutricional.gorduraTransQuantidadePorcao =
        value.gorduraTransQuantidadePorcao;
    tabelaNutricional.gorduraTransValorDiario = value.gorduraTransValorDiario;
    tabelaNutricional.gordurasSaturadasQuantidadePorcao =
        value.gordurasSaturadasQuantidadePorcao;
    tabelaNutricional.gordurasSaturadasValorDiario =
        value.gordurasSaturadasValorDiario;
    tabelaNutricional.gordurasTotaisQuantidadePorcao =
        value.gordurasTotaisQuantidadePorcao;
    tabelaNutricional.gordurasTotaisValorDiario =
        value.gordurasTotaisValorDiario;
    tabelaNutricional.medidaCaseiraParteDecimal =
        value.medidaCaseiraParteDecimal;
    tabelaNutricional.medidaCaseiraParteInteira =
        value.medidaCaseiraParteInteira;
    tabelaNutricional.medidaCaseiraUtilizada = value.medidaCaseiraUtilizada;
    tabelaNutricional.porcaoDe = value.porcaoDe?.trim();
    tabelaNutricional.porcaoQuantidade = value.porcaoQuantidade;
    tabelaNutricional.porcaoUnidade = value.porcaoUnidade;
    tabelaNutricional.proteinasQuantidadePorcao =
        value.proteinasQuantidadePorcao;
    tabelaNutricional.proteinasValorDiario = value.proteinasValorDiario;
    tabelaNutricional.sodioQuantidadePorcao = value.sodioQuantidadePorcao;
    tabelaNutricional.sodioValorDiario = value.sodioValorDiario;
    tabelaNutricional.valorEnergeticoQuantidadePorcao =
        value.valorEnergeticoQuantidadePorcao;
    tabelaNutricional.valorEnergeticoValorDiario =
        value.valorEnergeticoValorDiario;
    return tabelaNutricional;
  }
  toAPI() {
    this.porcaoQuantidade = Number(this.porcaoQuantidade);
    this.porcaoUnidade = Number(this.porcaoUnidade);
    this.medidaCaseiraParteInteira = Number(this.medidaCaseiraParteInteira);
    this.medidaCaseiraParteDecimal = Number(this.medidaCaseiraParteDecimal);
    this.medidaCaseiraUtilizada = Number(this.medidaCaseiraUtilizada);
    this.valorEnergeticoQuantidadePorcao =
        Number(this.valorEnergeticoQuantidadePorcao);
    this.valorEnergeticoValorDiario = Number(this.valorEnergeticoValorDiario);
    this.proteinasQuantidadePorcao = Number(this.proteinasQuantidadePorcao);
    this.proteinasValorDiario = Number(this.proteinasValorDiario);
    this.gordurasSaturadasQuantidadePorcao =
        Number(this.gordurasSaturadasQuantidadePorcao);
    this.gordurasSaturadasValorDiario =
        Number(this.gordurasSaturadasValorDiario);
    this.fibraAlimentarQuantidadePorcao =
        Number(this.fibraAlimentarQuantidadePorcao);
    this.fibraAlimentarValorDiario = Number(this.fibraAlimentarValorDiario);
    this.ferroQuantidadePorcao = Number(this.ferroQuantidadePorcao);
    this.ferroValorDiario = Number(this.ferroValorDiario);
    this.gorduraTransQuantidadePorcao =
        Number(this.gorduraTransQuantidadePorcao);
    this.gorduraTransValorDiario = Number(this.gorduraTransValorDiario);
    this.carboidratosQuantidadePorcao =
        Number(this.carboidratosQuantidadePorcao);
    this.carboidratosValorDiario = Number(this.carboidratosValorDiario);
    this.gordurasTotaisQuantidadePorcao =
        Number(this.gordurasTotaisQuantidadePorcao);
    this.gordurasTotaisValorDiario = Number(this.gordurasTotaisValorDiario);
    this.colesterolQuantidadePorcao = Number(this.colesterolQuantidadePorcao);
    this.colesterolValorDiario = Number(this.colesterolValorDiario);
    this.calcioQuantidadePorcao = Number(this.calcioQuantidadePorcao);
    this.calcioValorDiario = Number(this.calcioValorDiario);
    this.sodioQuantidadePorcao = Number(this.sodioQuantidadePorcao);
    this.sodioValorDiario = Number(this.sodioValorDiario);
  }
  toListagem() {
    return <TabelaNutricionalListagem> {
      id: this.id, ativo: this.ativo, porcaoDe: this.porcaoDe,
    }
  }
}
