export class NcmUf {
  estadual: number;
  importadosFederal: number;
  municipal: number;
  nacionalFederal: number;
  ncmCodigo: string;
  ncmExcecaoTipi: number|string;
  ncmUfId: string;
  uf: string;

  static from(value: any) {
    const ncmUf = new NcmUf();
    ncmUf.estadual = value.estadual;
    ncmUf.importadosFederal = value.importadosFederal;
    ncmUf.municipal = value.municipal;
    ncmUf.nacionalFederal = value.nacionalFederal;
    ncmUf.ncmCodigo = value.ncmCodigo?.trim();
    ncmUf.ncmExcecaoTipi = value.ncmExcecaoTipi;
    ncmUf.ncmUfId = value.ncmUfId;
    ncmUf.uf = value.uf?.trim();
    return ncmUf;
  }
  toAPI() {
    this.ncmExcecaoTipi = Number(this.ncmExcecaoTipi);
    this.importadosFederal = Number(this.importadosFederal);
    this.nacionalFederal = Number(this.nacionalFederal);
    this.estadual = Number(this.estadual);
    this.municipal = Number(this.municipal);
  }
}
