import {Injectable} from '@angular/core';
import {AreaEstoqueListagem} from '@app/abstraction/domain/interfaces/estoque/area-estoque/area-estoque-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AreaEstoqueStore {
  private readonly _areasEstoque =
      new BehaviorSubject<AreaEstoqueListagem[]>(null);
  get areasEstoque(): AreaEstoqueListagem[] {
    return this._areasEstoque.getValue();
  }
  set areasEstoque(areaEstoques: AreaEstoqueListagem[]) {
    this._areasEstoque.next(areaEstoques);
  }
  get areasEstoque$() {
    return this._areasEstoque.asObservable();
  }

  adicionar(areaEstoque: AreaEstoqueListagem) {
    if (!this.areasEstoque) {
      this.areasEstoque = [];
    }
    this.areasEstoque.push(areaEstoque);
  }
  adicionarRange(areasEstoque: AreaEstoqueListagem[]) {
    if (!this.areasEstoque) {
      this.areasEstoque = [];
    }
    areasEstoque.forEach((areaEstoque) => {
      if (!this.areasEstoque.some((c) => c.id === areaEstoque.id)) {
        this.areasEstoque.push(areaEstoque);
      }
    });
  }
}
