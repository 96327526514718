import {FormaPagamentoListagem} from '@app/abstraction/domain/interfaces/financeiro/forma-pagamento/forma-pagamento-listagem.interface';
export class FormaPagamento {
  id: string;
  descricao: string;
  codigoExterno: number;
  tipoPagamentoNfe: number;
  tipoOperacao: number;
  formasParcelamento: string[];
  baixado: boolean;
  ativo: boolean;
  boleto: boolean;
  planoContaId: string;
  permiteTroco: boolean;
  permiteMaisDeUma: boolean;
  geraConvenio: boolean;
  geraConciliacao: boolean;
  geraMovimentoFiscal: boolean;
  solicitaIdentificacaoCliente: boolean;
  exibeNoConvenio: boolean;
  exibeNoPedido: boolean;
  exibeNoPdvMobile: boolean;
  ordem: number;
  teclaAtalho: string;
  codigoSat: string;
  contaBancariaId: string;
  prazoCompensacao: number;
  empresas: string[];

  static from(value: any) {
    const formaPagamento = new FormaPagamento();
    formaPagamento.id = value.id;
    formaPagamento.descricao = value.descricao?.trim();
    formaPagamento.codigoExterno = value.codigoExterno;
    formaPagamento.tipoPagamentoNfe = value.tipoPagamentoNfe;
    formaPagamento.tipoOperacao = value.tipoOperacao;
    formaPagamento.formasParcelamento = value.formasParcelamento;
    formaPagamento.baixado = value.baixado;
    formaPagamento.ativo = value.ativo;
    formaPagamento.planoContaId = value.planoContaId;
    formaPagamento.permiteTroco = value.permiteTroco;
    formaPagamento.permiteMaisDeUma = value.permiteMaisDeUma;
    formaPagamento.geraConvenio = value.geraConvenio;
    formaPagamento.geraConciliacao = value.geraConciliacao;
    formaPagamento.geraMovimentoFiscal = value.geraMovimentoFiscal;
    formaPagamento.solicitaIdentificacaoCliente =
        value.solicitaIdentificacaoCliente;
    formaPagamento.exibeNoConvenio = value.exibeNoConvenio;
    formaPagamento.exibeNoPedido = value.exibeNoPedido;
    formaPagamento.exibeNoPdvMobile = value.exibeNoPdvMobile;
    formaPagamento.ordem = value.ordem;
    formaPagamento.teclaAtalho = value.teclaAtalho?.trim();
    formaPagamento.codigoSat = value.codigoSat?.trim();
    formaPagamento.boleto = value.boleto;
    formaPagamento.contaBancariaId = value.contaBancariaId;
    formaPagamento.prazoCompensacao = value.prazoCompensacao;
    formaPagamento.empresas = value.empresas;
    return formaPagamento;
  }
  toListagem() {
    return <FormaPagamentoListagem>{
      id: this.id,
      descricao: this.descricao,
      codigoExterno: this.codigoExterno,
      tipoPagamentoNfe: this.tipoPagamentoNfe,
      tipoOperacao: this.tipoOperacao,
      boleto: this.boleto,
      ativo: this.ativo,
    };
  }
}
