import '@app/shared/extensions/moment.extension';

import { Pessoa } from '@app/abstraction/domain/entities/cadastros/pessoa/pessoa.entity';
import { LancamentoParcela } from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import { TransportadoraListagem } from '@app/abstraction/domain/interfaces/cadastros/transportadora/transportadora-listagem.interface';
import * as moment from 'moment';

export class Transportadora extends Pessoa {
  agencia: string;
  ativo: boolean;
  banco: string;
  condicaoPagamento: string;
  contaCorrente: string;
  id: string;
  observacao: string;
  lastModifiedOn: Date;
  createdOn: Date;
  createdBy: string;
  nomeCreatedBy: string;
  planoConta3Id: string;
  sequencia: number;
  lancamentosParcela: LancamentoParcela[];
  static from(json: any = {}): Transportadora {
    const transportadora = new Transportadora();
    transportadora.ativo = json.ativo;
    transportadora.id = json.id;
    transportadora.lancamentosParcela = json.lancamentosParcela;
    transportadora.observacao = json.observacao?.trim();
    transportadora.agencia = json.agencia?.trim() ?? '';
    transportadora.banco = json.banco?.trim() ?? '';
    transportadora.condicaoPagamento = json.condicaoPagamento?.trim() ?? '';
    transportadora.contaCorrente = json.contaCorrente?.trim() ?? '';
    transportadora.lastModifiedOn =
      json.lastModifiedOn != null ? moment(json.lastModifiedOn).toDate() : null;
    transportadora.createdOn =
      json.createdOn != null ? moment(json.createdOn).toDate() : null;
    transportadora.createdBy = json.createdBy?.trim();
    transportadora.pessoaFrom(json);
    return transportadora;
  }
  toAPI() {
    this.pessoaTipo = Number(this.pessoaTipo);
    if (this.nascimento)
      this.nascimento = moment(this.nascimento).toLocalOnlyDate();
  }
  toListagem() {
    return <TransportadoraListagem>{
      agencia: this.agencia,
      ativo: this.ativo,
      banco: this.banco,
      condicaoPagamento: this.condicaoPagamento,
      contaCorrente: this.contaCorrente,
      createdOn: this.createdOn,
      documento: this.documento,
      id: this.id,
      nascimento: this.nascimento,
      nomeFantasia: this.nomeFantasia,
      nomeRazaoSocial: this.nomeRazaoSocial,
      pessoaTipo: this.pessoaTipo,
      planoConta3Id: this.planoConta3Id,
      rgIe: this.rgIe,
      sequencia: this.sequencia,
    };
  }
}
