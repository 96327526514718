import {FormControl, FormGroup} from '@angular/forms';
import {TabelaNutricional} from '@app/abstraction/domain/entities/produtos/tabela-nutricional/tabela-nutricional.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface ITabelaNutricionalForm {
  ativo: FormControl<boolean>;
  calcioQuantidadePorcao: FormControl<number>;
  calcioValorDiario: FormControl<number>;
  carboidratosQuantidadePorcao: FormControl<number>;
  carboidratosValorDiario: FormControl<number>;
  colesterolQuantidadePorcao: FormControl<number>;
  colesterolValorDiario: FormControl<number>;
  ferroQuantidadePorcao: FormControl<number>;
  ferroValorDiario: FormControl<number>;
  fibraAlimentarQuantidadePorcao: FormControl<number>;
  fibraAlimentarValorDiario: FormControl<number>;
  gorduraTransQuantidadePorcao: FormControl<number>;
  gorduraTransValorDiario: FormControl<number>;
  gordurasSaturadasQuantidadePorcao: FormControl<number>;
  gordurasSaturadasValorDiario: FormControl<number>;
  gordurasTotaisQuantidadePorcao: FormControl<number>;
  gordurasTotaisValorDiario: FormControl<number>;
  medidaCaseiraParteDecimal: FormControl<number>;
  medidaCaseiraParteInteira: FormControl<number>;
  medidaCaseiraUtilizada: FormControl<number>;
  porcaoDe: FormControl<string>;
  porcaoQuantidade: FormControl<number>;
  porcaoUnidade: FormControl<number>;
  proteinasQuantidadePorcao: FormControl<number>;
  proteinasValorDiario: FormControl<number>;
  sodioQuantidadePorcao: FormControl<number>;
  sodioValorDiario: FormControl<number>;
  id: FormControl<string>;
  valorEnergeticoQuantidadePorcao: FormControl<number>;
  valorEnergeticoValorDiario: FormControl<number>;
}

export class TabelaNutricionalForm extends BaseForm {
  form: FormGroup<ITabelaNutricionalForm>;
  operacaoForm: OperacaoForm;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova tabela nutricional' :
                                        'Editar tabela nutricional';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(private errorMessagesFacade: ErrorMessageFacade) {
    super();
  }
  private preencherForm(value: TabelaNutricional) {
    this.form.patchValue(value);
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  criarForm() {
    this.form = new FormGroup({
      id: new FormControl(null),
      porcaoDe: new FormControl(
          null, CustomValidators.required('"Porção de" é obrigatório')),
      porcaoQuantidade: new FormControl(
          null,
          CustomValidators.required('Quantidade da porção é obrigatória')),
      porcaoUnidade: new FormControl(
          null, CustomValidators.required('Unidade da porção é obrigatória')),
      ativo: new FormControl(true),
      calcioQuantidadePorcao: new FormControl(null),
      calcioValorDiario: new FormControl(null),
      carboidratosQuantidadePorcao: new FormControl(null),
      carboidratosValorDiario: new FormControl(null),
      colesterolQuantidadePorcao: new FormControl(null),
      colesterolValorDiario: new FormControl(null),
      ferroQuantidadePorcao: new FormControl(null),
      ferroValorDiario: new FormControl(null),
      fibraAlimentarQuantidadePorcao: new FormControl(null),
      fibraAlimentarValorDiario: new FormControl(null),
      gorduraTransQuantidadePorcao: new FormControl(null),
      gorduraTransValorDiario: new FormControl(null),
      gordurasSaturadasQuantidadePorcao: new FormControl(null),
      gordurasSaturadasValorDiario: new FormControl(null),
      gordurasTotaisQuantidadePorcao: new FormControl(null),
      gordurasTotaisValorDiario: new FormControl(null),
      medidaCaseiraParteDecimal: new FormControl(null),
      medidaCaseiraParteInteira: new FormControl(null),
      medidaCaseiraUtilizada: new FormControl(null),
      proteinasQuantidadePorcao: new FormControl(null),
      proteinasValorDiario: new FormControl(null),
      sodioQuantidadePorcao: new FormControl(null),
      sodioValorDiario: new FormControl(null),
      valorEnergeticoQuantidadePorcao: new FormControl(null),
      valorEnergeticoValorDiario: new FormControl(null),
    });
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(TabelaNutricional.from(value));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
}
