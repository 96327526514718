import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TabelaNutricional} from '@app/abstraction/domain/entities/produtos/tabela-nutricional/tabela-nutricional.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {TabelaNutricionalFacade} from '@app/abstraction/domain/facades/produtos/tabela-nutricional/tabela-nutricional.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {TabelaNutricionalForm} from '@app/abstraction/domain/forms/produtos/tabela-nutricional.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-tabela-nutricional-cadastro-rapido-modal',
  templateUrl: './tabela-nutricional-cadastro-rapido-modal.component.html',
})
export class TabelaNutricionalCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<TabelaNutricional>();
  @Output() aoCancelar = new EventEmitter();

  tabelaNutricionalForm: TabelaNutricionalForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get quantidadeDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposQuantidade;
  }
  get quantidadeMask() {
    return this.mascaraService.decimal(this.quantidadeDecimais);
  }
  get quantidadePlaceholder() {
    return this.mascaraService.placeholder(this.quantidadeDecimais);
  }
  constructor(
      private tabelaNutricionalFacade: TabelaNutricionalFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarTabelaNutricional(tabelaNutricional:
                                               TabelaNutricional) {
    return this.tabelaNutricionalFacade.adicionar(tabelaNutricional)
        .catch((e: any) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private criarForm() {
    this.tabelaNutricionalForm = new TabelaNutricionalForm(
        this.errorMessagesFacade,
    );
    this.tabelaNutricionalForm.criarForm();
    this.tabelaNutricionalForm.setarTipoFormulario();
  }
  private preencherObjeto() {
    const tabelaNutricional =
        TabelaNutricional.from(this.tabelaNutricionalForm.form.value);
    tabelaNutricional.toAPI();
    return tabelaNutricional;
  }
  private mostrarSucessoAoSalvar(tabelaNutricional: TabelaNutricional) {
    this.toastrService.success(`Tabela nutricional cadastrada com sucesso`);
    this.aoSalvar.emit(tabelaNutricional);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.tabelaNutricionalForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const tabelaNutricional = this.preencherObjeto();
      const resultado = await this.adicionarTabelaNutricional(tabelaNutricional)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar tabela nutricional.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  fecharModal() {
    this.tabelaNutricionalForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
    this.montarDropdownUnidadePorcao();
    this.montarDropdownMedidaCaseiraParteDecimal();
    this.montarDropdownMedidaCaseiraUtilizada();
  }
}
