import { Pipe, PipeTransform } from '@angular/core';
import { AreaEstoqueListagem } from '@app/abstraction/domain/interfaces/estoque/area-estoque/area-estoque-listagem.interface';
import { AreaEstoqueFacade } from '@app/abstraction/domain/facades/estoque/area-estoque/area-estoque.facade';

@Pipe({
  name: 'areaEstoque',
})
export class AreaEstoquePipe implements PipeTransform {
  constructor(private areaEstoqueFacade: AreaEstoqueFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const areaEstoque = this.areaEstoqueFacade.areasEstoque?.find(
        (_areaEstoque: AreaEstoqueListagem) => _areaEstoque.id === id
      ) as any;
      return areaEstoque?.[campo];
    }
    return '';
  }
}
