<p-tabView *ngIf="clienteForm && clienteForm?.form" [scrollable]="true">
  <p-tabPanel header="Dados do Cliente">
    <div class="formgrid grid">
      <!-- #region TIPO DE PESSOA -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Tipo de pessoa" [obrigatorio]="true">
        </app-custom-label>
        <app-dropdown-input
          [options]="pessoaTipos"
          [control]="clienteForm.form.controls.pessoaTipo"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region CPF/CNPJ -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label
          [texto]="clienteForm.labelCpfCnpj"
          [obrigatorio]="documentoObrigatorio"
        >
        </app-custom-label>
        <app-text-input
          [maxlength]="maxlength"
          [maxlengthCount]="maxlengthCount"
          [lengthOnlyNumber]="true"
          [showButton]="true"
          textAlign="right"
          iconButton="pi pi-search"
          [autoFocus]="clienteForm.operacaoFormAdicionar"
          [control]="clienteForm.form.controls?.documento"
          [mask]="mascaraDocumento"
          (keypress)="consultarDocumentoValidoComEnter($event)"
          (buttonOnClick)="consultarDocumentoValido()"
          (inputBlur)="consultarDocumentoValido()"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region NOME -->
      <div
        class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12"
        *ngIf="!pessoaJuridica"
      >
        <app-custom-label
          [texto]="clienteForm.labelNomeRazaoSocial"
          [obrigatorio]="true"
        >
        </app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.nomeRazaoSocial"
          [maxlength]="60"
          [lengthOnlyNumber]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid" *ngIf="pessoaJuridica">
      <!-- #region RAZAO SOCIAL -->
      <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
        <app-custom-label
          [texto]="clienteForm.labelNomeRazaoSocial"
          [obrigatorio]="true"
        >
        </app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.nomeRazaoSocial"
          (inputBlur)="preencherNomeFantasia($event)"
          [maxlength]="60"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region NOME FANTASIA -->
      <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
        <app-custom-label texto="Nome fantasia" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.nomeFantasia"
          [maxlength]="60"
        ></app-text-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid">
      <!-- #region NASCIMENTO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label [texto]="clienteForm.labelNascimento">
        </app-custom-label>
        <app-calendar-input
          [showIcon]="true"
          [control]="clienteForm.form.controls?.nascimento"
        >
        </app-calendar-input>
      </div>
      <!-- #endregion -->

      <!-- #region REGIME TRIBUTÁRIO -->
      <div
        class="field col-12 xl:col-3 lg:col-4 md:col-4 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="Regime tributário"></app-custom-label>
        <app-dropdown-input
          [options]="regimesTributarios"
          [control]="clienteForm.form.controls.regimeTributario"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region TIPO DE ATIVIDADE -->
      <div
        class="field col-12 xl:col-3 lg:col-4 md:col-4 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="Tipo de atividade"></app-custom-label>
        <app-dropdown-input
          [options]="atividadeTipos"
          [control]="clienteForm.form.controls.atividadeTipo"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region IE INDICADOR -->
      <div
        class="field col-12 xl:col-3 lg:col-3 md:col-4 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="IE indicador"></app-custom-label>
        <app-dropdown-input
          [options]="ieIndicadores"
          [control]="clienteForm.form.controls.ieIndicador"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region RG/IE -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label [texto]="clienteForm.labelRgIe"></app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.rgIe"
          [maxlength]="20"
          textAlign="right"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region PLANO CONTA -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label
          texto="Plano de contas padrão"
          [cadastroRapido]="true"
          (aoClicarAdicionar)="abrirPlanoContasCadastroRapidoModal()"
        >
        </app-custom-label>
        <app-dropdown-input
          optionLabel="descricao"
          optionValue="id"
          [control]="clienteForm.form.controls?.planoConta3Id"
          [options]="planosContas3"
          [showFilter]="true"
          [showClear]="true"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region VENDEDOR PADRÃO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Vendedor Padrão"></app-custom-label>
        <app-dropdown-input
          optionLabel="nomeFantasia"
          optionValue="id"
          [control]="clienteForm.form.controls?.vendedorPadrao"
          [options]="funcionarios"
          [showFilter]="true"
          [showClear]="true"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region EMAIL GERAL -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Email Geral"></app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.emailGeral"
          [maxlength]="60"
          [upperCase]="false"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region EMAIL FATURAMENTO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Email de Faturamento"></app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.emailFaturamento"
          [maxlength]="60"
          [upperCase]="false"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region WHATSAPP -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6">
        <app-custom-label texto="WhatsApp"></app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.whatsApp"
          [foneMask]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region TELEFONE FIXO -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6">
        <app-custom-label texto="Telefone"></app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.telefoneFixo"
          [foneMask]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region ATIVO -->
      <div class="col-6 xl:col-2 md:col-3 mt-0 sm:mt-5">
        <app-checkbox-input
          label="Ativo"
          controlName="ativo"
          [control]="clienteForm?.form.controls.ativo"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid">
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6 ml-auto"
        *ngIf="clienteForm.mostrarDataCriacao"
      >
        <app-custom-label texto="Data de criação"></app-custom-label>
        <app-calendar-input
          [control]="clienteForm.form.controls?.createdOn"
          [readonly]="true"
          [showIcon]="false"
        ></app-calendar-input>
      </div>
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        *ngIf="clienteForm.mostrarDataAlteracao"
      >
        <app-custom-label texto="Data de alteração"></app-custom-label>
        <app-calendar-input
          [control]="clienteForm.form.controls?.lastModifiedOn"
          [readonly]="true"
          [showIcon]="false"
        ></app-calendar-input>
      </div>
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        *ngIf="clienteForm.mostrarCriadoPor"
      >
        <app-custom-label texto="Criado por"></app-custom-label>
        <app-text-input
          [control]="clienteForm.form.controls?.nomeCreatedBy"
          [readonly]="true"
        >
        </app-text-input>
      </div>
    </div>
    <p-tabView
      [scrollable]="true"
      data-cy="tabview-subdados-cliente"
      (onChange)="recuperaDadosTab($event)"
    >
      <p-tabPanel header="Endereços">
        <div class="grid">
          <div class="col">
            <p-button
              type="button"
              styleClass="btn-five"
              label="Novo endereço"
              tooltipPosition="bottom"
              pTooltip="Novo endereço"
              icon="pi pi-plus"
              (onClick)="abrirEnderecoModal()"
            ></p-button>
          </div>
        </div>
        <div class="grid">
          <div *ngIf="enderecos.length <= 0" class="col-12 text-center">
            <span>Nenhum endereço cadastrado</span>
          </div>
          <div
            class="col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
            *ngFor="let endereco of enderecos; let i = index"
          >
            <app-cartao-endereco
              [endereco]="endereco"
              (aoCliquarEmEditar)="abrirEnderecoModal(endereco, i)"
            >
            </app-cartao-endereco>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Contatos">
        <div class="grid">
          <div class="col">
            <p-button
              type="button"
              styleClass="btn-five"
              label="Novo contato"
              tooltipPosition="bottom"
              pTooltip="Novo contato"
              icon="pi pi-plus"
              (onClick)="abrirContatoModal()"
            ></p-button>
          </div>
        </div>
        <div class="grid">
          <div *ngIf="contatos.length <= 0" class="col-12 text-center">
            <span>Nenhum contato cadastrado</span>
          </div>
          <div
            class="col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
            *ngFor="let contato of contatos; let i = index"
          >
            <app-cartao-contato
              [contato]="contato"
              (aoCliquarEmEditar)="abrirContatoModal(contato, i)"
            >
            </app-cartao-contato>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Crédito">
        <div class="formgrid grid">
          <!-- #region SITUAÇÃO DO CLIENTE -->
          <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12">
            <app-custom-label
              texto="Situação do cliente"
              [cadastroRapido]="true"
              (aoClicarAdicionar)="abrirSituacaoModal()"
            >
            </app-custom-label>
            <app-dropdown-input
              optionLabel="descricao"
              optionValue="id"
              [options]="clienteSituacoes$ | async"
              [control]="clienteForm.form.controls.clienteSituacaoId"
              [showClear]="true"
              [showFilter]="true"
              filterBy="descricao"
            >
            </app-dropdown-input>
          </div>
          <!-- #endregion -->

          <!-- #region TIPO DE CLIENTE -->
          <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
            <app-custom-label texto="Tipo de cliente"></app-custom-label>
            <app-dropdown-input
              [options]="clienteTipos"
              [control]="clienteForm.form.controls.clienteTipo"
              [showClear]="true"
            >
            </app-dropdown-input>
          </div>
          <!-- #endregion -->
        </div>
        <div class="formgrid grid">
          <!-- #region LIMITE -->
          <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-4">
            <app-custom-label texto="Limite de Crédito"></app-custom-label>
            <app-numeric-input
              [control]="clienteForm.form.controls.limite"
              [placeholder]="valorPlaceholder"
              [options]="valorMask"
            >
            </app-numeric-input>
          </div>
          <!-- #endregion -->

          <!-- #region LIMITE UTILIZADO -->
          <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-4">
            <app-custom-label texto="Utilizado (R$)"></app-custom-label>
            <app-numeric-input
              [control]="clienteForm.form.controls.limiteUtilizado"
              [placeholder]="valorPlaceholder"
              [options]="valorMask"
              [readonly]="true"
            >
            </app-numeric-input>
          </div>
          <!-- #endregion -->

          <!-- #region SALDO DO LIMITE -->
          <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-4">
            <app-custom-label texto="Saldo (R$)"></app-custom-label>
            <app-numeric-input
              [control]="clienteForm.form.controls.limiteSaldo"
              [placeholder]="valorPlaceholder"
              [options]="valorMask"
              [readonly]="true"
            >
            </app-numeric-input>
          </div>
          <!-- #endregion -->
        </div>
        <div class="formgrid grid">
          <!-- #region DIA DO VENCIMENTO -->
          <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-12">
            <app-custom-label texto="Dia do vencimento"></app-custom-label>
            <app-dropdown-input
              [options]="numerico"
              [control]="clienteForm.form.controls.limiteDiaVencimento"
              [showClear]="true"
            >
            </app-dropdown-input>
          </div>
          <!-- #endregion -->
        </div>
        <div class="formgrid grid"></div>
        <div class="formgrid grid"></div>
      </p-tabPanel>
      <p-tabPanel
        [header]="tituloTabPanelPedidos"
        *ngIf="clienteForm.operacaoFormEditar"
      >
        <div class="formgrid grid">
          <div class="field col-12 xl:col-12 lg:col-12 md:col-12">
            <div class="borda-tabela">
              <app-table
                [skeletonRowsCount]="5"
                [columns]="colunasTabelaPedido"
                [rows]="clienteForm.pedidos"
                [paginator]="true"
                dataKey="id"
              >
              </app-table>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel
        [header]="tituloTabPanelRegistrosFinanceiros"
        *ngIf="clienteForm.operacaoFormEditar"
      >
        <div class="formgrid grid">
          <div class="field col-12 xl:col-12 lg:col-12 md:col-12">
            <div class="borda-tabela">
              <app-table
                [skeletonRowsCount]="4"
                [columns]="colunasTabelaLancamentoParcela"
                [rows]="clienteForm.lancamentosParcela"
                [paginator]="true"
                dataKey="id"
              ></app-table>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Dados adicionais">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-12 lg:col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12">
                <app-custom-label texto="Observações"> </app-custom-label>
                <app-textarea-input
                  [control]="clienteForm.form.controls.observacao"
                  [maxlength]="255"
                  [rows]="10"
                ></app-textarea-input>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </p-tabPanel>
  <p-confirmDialog
    rejectButtonStyleClass="p-button-text"
    key="confirmacao-cliente-form"
    [style]="{ width: '45%' }"
  ></p-confirmDialog>

  <app-contato-modal
    [form]="clienteForm?.contatoForm"
    [mostrarModal]="clienteForm?.mostrarContatoModal"
    [titulo]="clienteForm?.contatoModalTitulo"
    (aoCancelar)="fecharContatoModal()"
    (aoSalvar)="salvarContato($event)"
  ></app-contato-modal>

  <app-endereco-modal
    [form]="clienteForm?.enderecoForm"
    [mostrarModal]="clienteForm?.mostrarEnderecoModal"
    [mostrarItemTodosEnderecoTipos]="operacaoFormEnderecoAdicionar"
    [titulo]="clienteForm?.enderecoModalTitulo"
    (aoCancelar)="fecharEnderecoModal()"
    (aoSalvar)="salvarEndereco($event)"
  ></app-endereco-modal>

  <app-cliente-situacao-cadastro-rapido-modal
    [mostrarModal]="clienteForm?.mostrarSituacaoModal"
    (aoCancelar)="fecharSituacaoModal()"
    (aoSalvar)="setarClienteSituacaoCadastrada($event)"
  ></app-cliente-situacao-cadastro-rapido-modal>

  <app-plano-contas-cadastro-rapido-modal
    [mostrarModal]="clienteForm?.mostrarPlanoContasModal"
    (aoCancelar)="fecharPlanoContasCadastroRapidoModal()"
    (aoSalvar)="setarPlanoContasCadastrado($event)"
  ></app-plano-contas-cadastro-rapido-modal>
</p-tabView>

<ng-template #valorTemp let-value="value">
  <span>R${{ value | customDecimal : valorDecimais }}</span>
</ng-template>
<ng-template #situacaoPedidoTemp let-value="value">
  <span>{{ value | enumLabel : pedidoSituacaoLabels }}</span>
</ng-template>
<ng-template #dataTemp let-value="value">
  <span>{{ value | date : dataFormatoPt }}</span>
</ng-template>
<ng-template #pedidosAcoesTemp let-rowData="rowData" let-rowIndex="rowIndex">
  <p-button
    tooltipPosition="bottom"
    pTooltip="Visualizar Pedido"
    icon="pi pi-eye"
    [text]="true"
    [plain]="true"
    (onClick)="verPedido(rowData)"
  ></p-button>
</ng-template>

<ng-template #despesaTemp let-value="value">
  <span *ngIf="value">DESPESA</span>
  <span *ngIf="!value">RECEITA</span>
</ng-template>
<ng-template #situacaoLancamentoParcelaTemp let-value="value">
  <span>{{ value | enumLabel : situacaoLancamentoLabels }}</span>
</ng-template>
<ng-template
  #lancamentosParcelaAcoesTemp
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Visualizar Parcela"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="verLancamentoParcela(rowData)"
  ></p-button>
</ng-template>
