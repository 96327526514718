export enum ImpressoraTipoConexao {
  USB = 1,
  Rede = 2,
  Serial = 3,
}

export const ImpressoraTipoConexaoLabels =
    new Map<ImpressoraTipoConexao, string>([
      [ImpressoraTipoConexao.USB, 'USB'],
      [ImpressoraTipoConexao.Rede, 'Rede'],
      [ImpressoraTipoConexao.Serial, 'Serial'],
    ]);
