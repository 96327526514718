import {Injectable} from '@angular/core';
import {AdministradoraService} from '@app/abstraction/data/services/financeiro/administradora.service';
import {AdministradoraStore} from '@app/abstraction/data/stores/financeiro/administradora.store';
import {Administradora} from '@app/abstraction/domain/entities/financeiro/administradora/administradora.entity';
import {AdministradoraFiltro} from '@app/abstraction/domain/interfaces/financeiro/administradora/administradora-filtro.interface';

@Injectable({providedIn: 'root'})
export class AdministradoraFacade {
  get administradoras() {
    return this.store.administradoras;
  }
  get administradoras$() {
    return this.store.administradoras$;
  }
  constructor(
      private store: AdministradoraStore,
      private service: AdministradoraService) {}
  async atualizarSituacao(id: string) {
    const administradora = this.administradoras.find((p) => p.id === id);
    const status =
        await this.service.atualizarSituacao(id, !administradora.ativo);
    administradora.ativo = status;
    return status;
  }
  async adicionar(administradora: Administradora) {
    const novoAdministradora = await this.service.adicionar(administradora);
    if (novoAdministradora) {
      this.store.adicionar(novoAdministradora.toListagem());
    }
    return novoAdministradora;
  }
  async buscarAdministradoras(filtro?: AdministradoraFiltro) {
    const administradoras = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(administradoras);
  }

  alterar(administradora: Administradora) {
    return this.service.alterar(administradora, administradora.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaAdministradoras() {
    this.store.administradoras = null;
  }
}
