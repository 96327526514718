import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {NotificacaoService} from '@app/abstraction/data/services/shared/notificacao.service';
import {NotificacaoStore} from '@app/abstraction/data/stores/shared/notificacao.store';

@Injectable({providedIn: 'root'})
export class NotificacaoFacade {
  get notificacoes() {
    return this.store.notificacoes;
  }
  get notificacoesAntigas() {
    return this.store.notificacoesAntigas;
  }
  constructor(
      private notificacaoService: NotificacaoService,
      private store: NotificacaoStore,
  ) {}
  async buscarNotificacoes() {
    const notificacoes = await this.notificacaoService.buscarNotificacoes();
    if (notificacoes) this.store.notificacoes = notificacoes;
    return notificacoes;
  }
  atualizarNotificacoesAntigas() {
    this.store.notificacoesAntigas = Array.from(this.store.notificacoes);
  }
  async marcarComoLidas() {
    await this.notificacaoService.marcarComoLidas();
    this.buscarNotificacoes();
  }
}
