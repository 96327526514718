import { Component } from '@angular/core';

@Component({
  selector: 'app-campo-form-skeleton',
  templateUrl: './campo-form-skeleton.component.html',
  styleUrls: ['./campo-form-skeleton.component.scss'],
})
export class CampoFormSkeletonComponent {
  /**
   *
   */
}
