import {FormControl, FormGroup} from '@angular/forms';
import {ProdutoVariacaoFoto} from '@app/abstraction/domain/entities/produtos/produto/produto-variacao/produto-variacao-foto.entity';


export interface IProdutoVariacaoFotoForm {
  id: FormControl<string>;
  variacaoId: FormControl<string>;
  fotoNome: FormControl<string>;
  fotoBase64: FormControl<string>;
  caminho: FormControl<string>;
  ordem: FormControl<number>;
  ativo: FormControl<boolean>;
}

export class ProdutoVariacaoFotoForm {
  static criarForm(variacaoFoto: ProdutoVariacaoFoto) {
    return new FormGroup<IProdutoVariacaoFotoForm>({
      id: new FormControl(variacaoFoto?.id),
      variacaoId: new FormControl(variacaoFoto?.variacaoId),
      fotoNome: new FormControl(variacaoFoto?.fotoNome),
      fotoBase64: new FormControl(variacaoFoto?.fotoBase64),
      caminho: new FormControl(variacaoFoto?.caminho),
      ordem: new FormControl(variacaoFoto?.ordem),
      ativo: new FormControl(variacaoFoto?.ativo ?? true),
    });
  }
}
