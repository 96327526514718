import {Pipe, PipeTransform} from '@angular/core';
import {ClienteSituacaoFacade} from '@app/abstraction/domain/facades/cadastros/cliente-situacao/cliente-situacao.facade';
import {ClienteSituacaoListagem} from '@app/abstraction/domain/interfaces/cadastros/cliente-situacao/cliente-situacao-listagem.interface';

@Pipe({
  name: 'clienteSituacao',
})
export class ClienteSituacaoPipe implements PipeTransform {
  constructor(private facade: ClienteSituacaoFacade) {}
  transform(clienteSituacaoId: string, campo: string = 'descricao') {
    if (clienteSituacaoId) {
      const clienteSituacao = this.facade.clienteSituacoes?.find(
                                  (p: ClienteSituacaoListagem) =>
                                      p.id === clienteSituacaoId) as any;

      return clienteSituacao?.[campo];
    }
    return '';
  }
}
