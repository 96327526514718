import {DatePipe} from '@angular/common';
import {Component, EventEmitter, HostListener, Input, OnInit, Output,} from '@angular/core';
import {Router} from '@angular/router';
import {VersaoFacade} from '@app/abstraction/domain/facades/configuracoes/versao/versao.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {NotificacaoFacade} from '@app/abstraction/domain/facades/shared/notificacao/notificacao.facade';
import {UsuarioFavorito} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-favorito.interface';
import {VersaoListagem} from '@app/abstraction/domain/interfaces/configuracoes/versao/versao-listagem.interface';
import {Temas} from '@app/shared/constants/temas.constant';
import {FavoritoTipo} from '@app/shared/enums/favorito-tipo.enum';
import {MegaMenuItem, MenuItem} from 'primeng/api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() megaMenu: MegaMenuItem[];
  @Input() usuarioNome: string;
  @Input() usuarioEmail: string;
  @Output() aoClicarNotificacoes = new EventEmitter();
  @Output() aoClicarMenuDireito = new EventEmitter();
  @Output() aoClicarSair = new EventEmitter();
  @Output() aoSelecionarTema = new EventEmitter<number>();
  itens: MenuItem[];
  temaItens: MenuItem[];
  esconderMenu: boolean;
  versao: VersaoListagem;
  get empresaLogada() {
    return this.authFacade.empresaLogada;
  }
  get mostrarFavoritosBarra() {
    return (
        this.authFacade.usuarioFavoritos
            ?.filter((e) => e.tipo === FavoritoTipo.BarraFavoritos)
            .length > 0);
  }
  get temaDescricao() {
    if (this.authFacade.usuarioConfig) {
      return Temas.find((t) => t.id === this.authFacade.usuarioConfig.temaId)
          .descricao.replace('-theme', '');
    } else {
      return Temas.find((t) => t.id === '1').descricao.replace('-theme', '');
    }
  }
  get mostrarLogoDark() {
    return this.temaDescricao.includes('light');
  }
  get mostrarNotificacoes() {
    if (!this.notificacaoFacade.notificacoesAntigas) return false;
    return this.notificacaoFacade.notificacoesAntigas
        .filter((e) => !e.dataHoraLeitura)
        .length;
  }
  constructor(
      private datePipe: DatePipe,
      private authFacade: AuthFacade,
      private versaoFacade: VersaoFacade,
      private router: Router,
      private notificacaoFacade: NotificacaoFacade,
  ) {
    this.monitorarUsuarioLogado();
    this.monitorarUsuarioFavoritos();
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esconderMenu = window.innerWidth <= 600;
  }
  private agruparFavoritosPorModulos(favoritosBarra: UsuarioFavorito[]) {
    return favoritosBarra.reduce(function(r, a) {
      r[a.modulo] = r[a.modulo] || [];
      r[a.modulo].push(a);
      return r;
    }, Object.create(null));
  }
  private buscarFavoritosUsuarioLogado() {
    return this.authFacade.usuarioFavoritos.filter(
        (e) => e.tipo === FavoritoTipo.BarraFavoritos);
  }
  private criarMenuDrodown() {
    this.itens = [
      {
        label: 'Sair',
        icon: 'pi pi-fw pi-power-off',
        command: () => this.sair(),
      },
      {
        separator: true,
      },
      {
        label: `<span>Versão ${this.versao?.versaoCompleta}</span></br>
                <span class="data-atualizacao">(${
            this.datePipe.transform(
                this.versao?.dataLancamento, 'dd/MM/yyyy')})</span>`,
        disabled: true,
        escape: false,
        styleClass: 'flex justify-content-center text-center',
      },
    ];

    this.adicionaBotaoAlterarEmpresa();

    this.adicionaSeparator();

    if (this.authFacade.isSuperUsuario) this.adicionaSuperUsuario();

    this.adicionaEmailUsuario();
  }
  private criarMenuTema() {
    this.temaItens = [
      {
        label:
            '<div class="seletor-tema"><div class="tema-item gray"></div> Gray</div>',
        escape: false,
        command: () => this.selecionarTema(1),
      },
      {
        label:
            '<div class="seletor-tema"><div class="tema-item blue"></div> Blue</div>',
        escape: false,
        command: () => this.selecionarTema(2),
      },
      {
        label:
            '<div class="seletor-tema"><div class="tema-item light"></div> Light</div>',
        escape: false,
        command: () => this.selecionarTema(3),
      },
      // {
      //   label:
      //       '<div class="seletor-tema"><div class="tema-item dark"></div>
      //       Dark</div>',
      //   escape: false,
      //   command: () => this.selecionarTema(4),
      // },
    ];
  }
  private monitorarUsuarioLogado() {
    this.authFacade.usuarioLogado$.subscribe(() => {
      this.criarMenuDrodown();
    });
  }
  private monitorarUsuarioFavoritos() {
    this.authFacade.usuarioConfig$.subscribe(() => {
      this.montarMegaMenu();
    });
  }
  private montarMegaMenu() {
    if (this.authFacade.loggedIn) {
      const itens = <MegaMenuItem[]>[
        {
          label: 'Favoritos',
          icon: 'pi pi-fw pi-star-fill',
          items: this.montarFavoritos(),
        },
      ];
      this.megaMenu = itens;
    }
  }
  private montarFavoritos() {
    let favoritos = <MenuItem[][]>[];
    if (this.authFacade.usuarioFavoritos) {
      const favoritosUsuarioLogado = this.buscarFavoritosUsuarioLogado();
      const modulos = this.agruparFavoritosPorModulos(favoritosUsuarioLogado);
      const modulosChaves = Object.keys(modulos);

      modulosChaves.forEach((chave: any) => {
        const telas = modulos[chave];
        const items = <MenuItem[]>[];
        telas.forEach((tela: any) => {
          items.push(<MenuItem>{
            url: `/#${tela.link}`,
            label: tela.tela,
            target: '_self',
            icon: 'pi pi-angle-right',
          });
        });
        const modulo = <MenuItem[]>[
          {
            label: chave,
            items: items,
          },
        ];
        favoritos.push(modulo);
      });
    }
    return favoritos;
  }
  private redirecionarSelecionarEmpresa() {
    this.router.navigateByUrl('/selecionar-empresa');
  }
  adicionaSeparator() {
    this.itens.unshift({
      separator: true,
    });
  }
  adicionaSuperUsuario() {
    this.itens.unshift({
      badge: 'SUPER USUÁRIO',
      disabled: true,
      styleClass: 'flex justify-content-center menuitem-superuser',
    });
  }
  adicionaEmailUsuario() {
    this.itens.unshift({
      label: this.usuarioNome,
      disabled: true,
      styleClass: 'flex justify-content-center menuitem-email-text',
    });
  }
  abrirNotificacoes() {
    this.aoClicarNotificacoes.emit();
  }
  adicionaBotaoAlterarEmpresa() {
    if (this.authFacade.multiEmpresa)
      this.itens.unshift({
        label: 'Alterar empresa',
        icon: 'pi pi-fw pi-sort-alt',
        command: () => this.redirecionarSelecionarEmpresa(),
      });
  }
  abrirMenuDireito() {
    this.aoClicarMenuDireito.emit();
  }
  async buscarUltimaVersao() {
    this.versao = await this.versaoFacade.buscarUltimaVersao();
  }
  async ngOnInit() {
    await this.buscarUltimaVersao();
    this.criarMenuDrodown();
    this.criarMenuTema();
    this.onResize();
  }
  sair() {
    this.aoClicarSair.emit();
  }
  selecionarTema(temaId: number) {
    this.aoSelecionarTema.emit(temaId);
  }
}
