import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {BancoFacade} from '@app/abstraction/domain/facades/financeiro/banco/banco.facade';
import {CarteiraFacade} from '@app/abstraction/domain/facades/financeiro/carteira/carteira.facade';
import {ContaBancariaFacade} from '@app/abstraction/domain/facades/financeiro/conta-bancaria/conta-bancaria.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ContaBancariaSharedFormComponent} from '@app/shared/components/shared-forms/conta-bancaria-shared-form/conta-bancaria-shared-form.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-conta-bancaria-cadastro-rapido-modal',
  templateUrl: './conta-bancaria-cadastro-rapido-modal.component.html',
})
export class ContaBancariaCadastroRapidoModalComponent extends FormBaseComponent
    implements AfterViewInit {
  @ViewChild('formComponent') formComponent: ContaBancariaSharedFormComponent;
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<ContaBancaria>();
  @Output() aoCancelar = new EventEmitter();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private contaBancariaFacade: ContaBancariaFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private empresaFacade: EmpresaFacade,
      private bancoFacade: BancoFacade,
      private carteiraFacade: CarteiraFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarContaBancaria(contaBancaria: ContaBancaria) {
    return this.contaBancariaFacade.adicionar(contaBancaria).catch((e: any) => {
      this.saveLoading = false;
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private mostrarSucessoAoSalvar(contaBancaria: ContaBancaria) {
    this.toastrService.success(`Conta bancária cadastrado com sucesso`);
    this.aoSalvar.emit(contaBancaria);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.formComponent?.contaBancariaForm?.form.controls?.emiteBoleto
               ?.value) {
        this.formComponent.contaBancariaForm.form.value.contaBancariaBoleto =
            null;
      }

      if (!this.formComponent?.contaBancariaForm?.isValid()) {
        this.saveLoading = false;
        return;
      }

      let contaBancaria = this.formComponent?.preencherObjeto();

      const resultado = await this.adicionarContaBancaria(contaBancaria)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar conta bancária.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.formComponent?.criarForm();
  }
  fecharModal() {
    this.formComponent?.contaBancariaForm?.form.reset();
    this.aoCancelar.emit();
  }
  ngAfterViewInit() {
    this.formComponent?.criarForm();
    this.empresaFacade.buscarEmpresas();
    this.carteiraFacade.buscarCarteiras();
    this.bancoFacade.buscarBancos({completo: true});
  }
}
