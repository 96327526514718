import {Injectable} from '@angular/core';
import {FormaParcelamentoListagem} from '@app/abstraction/domain/interfaces/financeiro/forma-parcelamento/forma-parcelamento-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FormaParcelamentoStore {
  private readonly _formasParcelamento =
      new BehaviorSubject<FormaParcelamentoListagem[]>(null);

  get formasParcelamento(): FormaParcelamentoListagem[] {
    return this._formasParcelamento.getValue();
  }
  set formasParcelamento(formasParcelamento: FormaParcelamentoListagem[]) {
    this._formasParcelamento.next(formasParcelamento);
  }
  get formasParcelamento$() {
    return this._formasParcelamento.asObservable();
  }
  adicionar(formaParcelamento: FormaParcelamentoListagem) {
    if (!this.formasParcelamento) {
      this.formasParcelamento = [];
    }
    this.formasParcelamento.push(formaParcelamento);
  }
  adicionarRange(formasParcelamento: FormaParcelamentoListagem[]) {
    if (!this.formasParcelamento) {
      this.formasParcelamento = [];
    }
    formasParcelamento.forEach((formaParcelamento) => {
      if (!this.formasParcelamento.some((c) => c.id === formaParcelamento.id)) {
        this.formasParcelamento.push(formaParcelamento);
      }
    });
  }
}
