import {Injectable} from '@angular/core';
import {EmpresaUsuario} from '@app/abstraction/domain/entities/shared/auth/empresa-usuario.entity';
import {EmpresaListagem} from '@app/abstraction/domain/interfaces/cadastros/empresa/empresa-listagem.interface';
import {BehaviorSubject, Observable} from 'rxjs';

import {EmpresaChaveIntegracaoListagem} from '../../../domain/interfaces/cadastros/empresa/empresa-chave-integracao.interface';

@Injectable({providedIn: 'root'})
export class EmpresaStore {
  private readonly _empresas = new BehaviorSubject<EmpresaListagem[]>(null);
  private readonly _empresasUsuarioLogado =
      new BehaviorSubject<EmpresaUsuario[]>(null);

  private readonly _chavesIntegracao =
      new BehaviorSubject<EmpresaChaveIntegracaoListagem[]>(null);
  get empresas(): EmpresaListagem[] {
    return this._empresas.getValue();
  }
  set empresas(empresas: EmpresaListagem[]) {
    this._empresas.next(empresas);
  }
  get empresas$(): Observable<EmpresaListagem[]> {
    return this._empresas.asObservable();
  }
  get empresasUsuarioLogado(): EmpresaUsuario[] {
    return this._empresasUsuarioLogado.getValue();
  }
  set empresasUsuarioLogado(empresasUsuarioLogado: EmpresaUsuario[]) {
    this._empresasUsuarioLogado.next(empresasUsuarioLogado);
  }
  get _chavesIntegracao$(): Observable<EmpresaChaveIntegracaoListagem[]> {
    return this._chavesIntegracao.asObservable();
  }
  get chavesIntegracao(): EmpresaChaveIntegracaoListagem[] {
    return this._chavesIntegracao.getValue();
  }
  set chavesIntegracao(chavesIntegracao: EmpresaChaveIntegracaoListagem[]) {
    this._chavesIntegracao.next(chavesIntegracao);
  }

  adicionarRange(empresas: EmpresaListagem[]) {
    if (!this.empresas) {
      this.empresas = [];
    }
    empresas.forEach((empresa) => {
      if (!this.empresas.some((c) => c.id === empresa.id)) {
        this.empresas.push(empresa);
      }
    });
  }
  adicionarEmpresasUsuarioLogadoRange(empresas: EmpresaUsuario[]) {
    if (!this.empresasUsuarioLogado) {
      this.empresasUsuarioLogado = [];
    }
    empresas.forEach((empresa) => {
      if (!this.empresasUsuarioLogado.some((c) => c.id === empresa.id)) {
        this.empresasUsuarioLogado.push(empresa);
      }
    });
  }
  adicionarChavesIntegracaoRange(chaves: EmpresaChaveIntegracaoListagem[]) {
    if (!this.chavesIntegracao) {
      this.chavesIntegracao = [];
    }
    chaves.forEach((chave) => {
      if (!this.chavesIntegracao.some((c) => c.id === chave.id)) {
        this.chavesIntegracao.push(chave);
      }
    });
  }
}
