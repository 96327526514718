import {EmpresaUsuario} from './empresa-usuario.entity';

export class GrupoEconomico {
  documento: string;
  nomeFantasia: string;
  razaoSocial: string;
  empresas: EmpresaUsuario[];

  get quantidadeEmpresasTag(): string {
    return `${this.empresas.length} empresa${
        this.empresas.length > 1 ? 's' : ''}`;
  }

  static from(json: any = {}): GrupoEconomico {
    const grupo = new GrupoEconomico();
    grupo.documento = json.documento;
    grupo.nomeFantasia = json.nomeFantasia;
    grupo.razaoSocial = json.razaoSocial;
    grupo.empresas = json.empresas?.map(EmpresaUsuario.from);
    return grupo;
  }
}
