<ng-template #documentoTemp let-value="value">
  <span>{{ value | documento }}</span>
</ng-template>
<ng-template #nfeFinalidadeTemp let-value="value">
  <span>{{ value | enumLabel : nfeFinalidadeLabels }}</span>
</ng-template>
<ng-template #statusNotaFiscalTemp let-rowData="rowData">
  <p-tag
    styleClass="mr-2"
    [severity]="buscarStyleTagStatus(rowData.situacao)"
    [value]="rowData.situacao | enumLabel : nfeSaidaSituacaoLabels"
    [rounded]="true"
  ></p-tag>
</ng-template>
<p-dialog
  header="Lista de notas fiscais de saída"
  [style]="{ width: '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
    (scrolled)="buscarNotasFiscaisSaidaProximaPagina()"
  >
    <div class="formgrid grid mt-1">
      <div class="field col-12 md:col-4">
        <app-text-input-ngmodel
          iconButton="pi pi-search"
          placeholder="Buscar por CPF/CNPJ"
          [mask]="mascaraDocumento"
          (modelChange)="filtrarComTimeout()"
          [(model)]="filtro"
        ></app-text-input-ngmodel>
      </div>
    </div>
    <app-table
      (aoSelecionar)="setarItemSelecionado($event)"
      [columns]="colunas"
      [rows]="dados"
      [selectable]="selectable"
      [selectionMode]="modoSelecao"
      [checkboxable]="checkboxable"
      [selecteds]="itensSelecionados"
    >
    </app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>
<ng-template #situacaoTemp let-value="value">
  <p-tag
    *ngIf="value"
    styleClass="mr-2"
    severity="success"
    value="Ativo"
  ></p-tag>
  <p-tag
    *ngIf="!value"
    styleClass="mr-2"
    severity="danger"
    value="Inativo"
  ></p-tag>
</ng-template>
