import {Injectable} from '@angular/core';
import {Pessoa} from '@app/abstraction/domain/entities/cadastros/pessoa/pessoa.entity';
import {PessoaListagem} from '@app/abstraction/domain/interfaces/cadastros/pessoa/pessoa-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PessoaStore {
  private readonly _pessoas = new BehaviorSubject<PessoaListagem[]>(null);

  get pessoas(): PessoaListagem[] {
    return this._pessoas.getValue();
  }
  set pessoas(pessoas: PessoaListagem[]) {
    this._pessoas.next(pessoas);
  }
  get pessoas$() {
    return this._pessoas.asObservable();
  }

  adicionar(pessoa: Pessoa) {
    if (!this.pessoas) {
      this.pessoas = [];
    }
    if (!this.pessoas.some((e) => e.id === pessoa.id)) {
      this.pessoas.push(pessoa);
    }
  }
  adicionarRange(pessoas: PessoaListagem[]) {
    if (!this.pessoas) {
      this.pessoas = [];
    }

    pessoas.forEach((pessoa) => {
      if (!this.pessoas.some((e) => e.id === pessoa.id)) {
        this.pessoas.push(pessoa);
      }
    });
  }
}
