import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ChaveIntegracao} from '@app/abstraction/domain/entities/cadastros/chave-integracao/chave-integracao.entity';
import {Empresa} from '@app/abstraction/domain/entities/cadastros/empresa/empresa.entity';
import {EmpresaUsuario} from '@app/abstraction/domain/entities/shared/auth/empresa-usuario.entity';
import {EmpresaDisponibilidade} from '@app/abstraction/domain/interfaces/cadastros/empresa/empresa-disponibilidade.interface';
import {EmpresaFiltro} from '@app/abstraction/domain/interfaces/cadastros/empresa/empresa-filtro.interface';
import {EmpresaListagem} from '@app/abstraction/domain/interfaces/cadastros/empresa/empresa-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

import {EmpresaChaveIntegracaoListagem} from '../../../domain/interfaces/cadastros/empresa/empresa-chave-integracao.interface';

@Injectable({
  providedIn: 'root',
})
export class EmpresaService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}
  adicionar(dados: Empresa) {
    const response$ =
        this.client.post<Empresa>(API.CADASTRO_EMPRESA, dados)
            .pipe(map((json: Empresa) => json ? Empresa.from(json) : null));
    return lastValueFrom(response$);
  }
  adicionarChaveIntegracao(chaveIntegracao: ChaveIntegracao) {
    const response$ =
        this.client.post<ChaveIntegracao>(API.MANAGER_APIKEY, chaveIntegracao)
            .pipe(
                map((json: ChaveIntegracao) =>
                        json ? ChaveIntegracao.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Empresa, id: string) {
    const response$ =
        this.client.put<Empresa>(`${API.CADASTRO_EMPRESA}/${id}`, dados)
            .pipe(map((json: Empresa) => json ? Empresa.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPorId(chave: string) {
    const response$ =
        this.client.get<Empresa>(`${API.CADASTRO_EMPRESA}/${chave}`)
            .pipe(map((json: Empresa) => json ? Empresa.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPorIdNoManager(chave: string) {
    const response$ =
        this.client.get<Empresa>(`${API.MANAGER_EMPRESAS}/${chave}`)
            .pipe(map((json: Empresa) => json ? Empresa.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: EmpresaFiltro) {
    const response$ = this.client.get<EmpresaListagem[]>(
        this.geradorQueryString.gerar(`${API.CADASTRO_EMPRESA}`, filtro));
    return lastValueFrom(response$);
  }
  buscarEmpresasUsuarioLogado(matrizId: string) {
    const response$ =
        this.client.get<EmpresaUsuario[]>(this.geradorQueryString.gerar(
            `${API.MANAGER_EMPRESAS}`, {matrizId, ativo: true}));
    return lastValueFrom(response$);
  }
  verificarDisponibilidadeManager(documento: string) {
    const response$ = this.client.get<EmpresaDisponibilidade>(
        `${API.MANAGER_EMPRESAS_DISPONIBILIDADE}/${documento}`);
    return lastValueFrom(response$);
  }
  buscarPorDocumentoNoManager(documento: string) {
    const response$ = this.client.get<Empresa>(
        `${API.MANAGER_EMPRESAS}/documento/${documento}`);
    return lastValueFrom(response$);
  }
  buscarChavesIntegracao() {
    const response$ = this.client.get<EmpresaChaveIntegracaoListagem[]>(
        `${API.MANAGER_APIKEY}`);
    return lastValueFrom(response$);
  }
  removerChaveIntegracao(id: string) {
    const response$ = this.client.delete<EmpresaChaveIntegracaoListagem>(
        `${API.MANAGER_APIKEY}?id=${id}`);
    return lastValueFrom(response$);
  }
}
