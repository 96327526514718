import {Acao} from './acao.entity';

export class Tela {
  acao: Acao;
  descricao: string;
  rota: string;
  id: string;

  static from(json: any = {}): Tela {
    const tela = new Tela();
    tela.acao = Acao.from(json.acao)
    tela.descricao = json.descricao;
    tela.rota = json.rota;
    tela.id = json.id;
    return tela;
  }
}
