import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ParametroFiltro} from '@app/abstraction/domain/interfaces/configuracoes/parametro/parametro-filtro.interface';
import {ParametroListagem} from '@app/abstraction/domain/interfaces/configuracoes/parametro/parametro-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ParametroService {
  constructor(
      private geradorQueryStringHelper: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}

  buscarParametrosEmpresaLogada(filtro?: ParametroFiltro) {
    const response$ = this.client.get<ParametroListagem[]>(
        this.geradorQueryStringHelper.gerar(
            API.CONFIGURACOES_PARAMETROS, filtro));
    return lastValueFrom(response$);
  }
  salvarParametro(chave: string, valor: string, empresaId: string) {
    const response$ = this.client.put<ParametroListagem>(
        `${API.CONFIGURACOES_PARAMETROS}/${chave}?valor=${valor}&empresaId=${
            empresaId}`,
        null);
    return lastValueFrom(response$);
  }
}
