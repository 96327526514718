import {Pipe, PipeTransform} from '@angular/core';
import {CarteiraFacade} from '@app/abstraction/domain/facades/financeiro/carteira/carteira.facade';
import {CarteiraListagem} from '@app/abstraction/domain/interfaces/financeiro/carteira/carteira-listagem.interface';

@Pipe({
  name: 'carteira',
})
export class CarteiraPipe implements PipeTransform {
  constructor(private carteiraFacade: CarteiraFacade) {}
  transform(codigo: string, campo: string = 'nome'): any {
    if (codigo) {
      const carteira =
          this.carteiraFacade.carteiras?.find(
              (carteira: CarteiraListagem) => carteira.codigo == codigo) as any;
      return carteira?.[campo];
    }
    return '';
  }
}
