
import {NFeStatus} from '@app/shared/enums/nfe-status.enum';
import * as moment from 'moment';

import {NotaFiscalCabecalho} from './cabecalho.entity';
import {NotaFiscalCobranca} from './cobranca.entity';
import {NotaFiscalEvento} from './evento.entity';
import {NotaFiscalFrete} from './frete.entity';
import {NotaFiscalItem} from './item.entity';
import {NotaFiscalPagamento} from './pagamento.entity';
import {NotaFiscalRetorno} from './retorno.entity';

export class NotaFiscal {
  id: string;
  cabecalho: NotaFiscalCabecalho;
  cobranca: NotaFiscalCobranca;
  frete: NotaFiscalFrete;
  entradaManual: boolean;
  caminhoPdf: string;
  caminhoXml: string;
  origemId: string;
  origemDocumento: string;
  movimentaEstoque: boolean;
  geraFinanceiro: boolean;
  atualizaPrecoParaTodos: boolean;
  informacaoComplementar: string;
  informacaoAdicionalFisco: string;
  itens: NotaFiscalItem[];
  pagamentos: NotaFiscalPagamento[];
  retornos: NotaFiscalRetorno[];
  cartasCorrecao: NotaFiscalEvento[];
  cancelamentos: NotaFiscalEvento[];


  static from(json: any = {}): NotaFiscal {
    const notaFiscal = new NotaFiscal();
    notaFiscal.id = json.id;
    notaFiscal.caminhoPdf = json.caminhoPdf?.trim();
    notaFiscal.caminhoXml = json.caminhoXml?.trim();
    notaFiscal.origemId = json.origemId;
    notaFiscal.origemDocumento = json.origemDocumento;
    notaFiscal.movimentaEstoque = json.movimentaEstoque;
    notaFiscal.atualizaPrecoParaTodos = json.atualizaPrecoParaTodos;
    notaFiscal.geraFinanceiro = json.geraFinanceiro;
    notaFiscal.informacaoComplementar = json.informacaoComplementar?.trim();
    notaFiscal.informacaoAdicionalFisco = json.informacaoAdicionalFisco?.trim();
    notaFiscal.entradaManual = json.entradaManual;

    if (json.cabecalho) {
      notaFiscal.cabecalho = NotaFiscalCabecalho.from(json.cabecalho);
    }
    if (json.cobranca) {
      notaFiscal.cobranca = NotaFiscalCobranca.from(json.cobranca);
    }
    if (json.frete) {
      notaFiscal.frete = NotaFiscalFrete.from(json.frete);
    }
    if (json.retornos?.length) {
      notaFiscal.retornos =
          json.retornos?.map(
              (r: NotaFiscalRetorno) => NotaFiscalRetorno.from(r)) ??
          [];
    }
    if (json.cartasCorrecao?.length) {
      notaFiscal.cartasCorrecao =
          json.cartasCorrecao?.map(
              (e: NotaFiscalEvento) => NotaFiscalEvento.from(e)) ??
          [];
    }
    if (json.cancelamentos?.length) {
      notaFiscal.cancelamentos =
          json.cancelamentos?.map(
              (e: NotaFiscalEvento) => NotaFiscalEvento.from(e)) ??
          [];
    }
    if (json.itens?.length) {
      notaFiscal.itens =
          json.itens?.map((i: NotaFiscalItem) => NotaFiscalItem.from(i)) ?? [];
    }
    if (json.pagamentos?.length) {
      notaFiscal.pagamentos =
          json.pagamentos?.map(
              (p: NotaFiscalPagamento) => NotaFiscalPagamento.from(p)) ??
          [];
    }
    return notaFiscal;
  }
  toAPI() {
    if (this.cabecalho) {
      this.cabecalho.destinatario.endereco.preencherUfIbge();
      this.cabecalho.destinatario.endereco.cidadeIbge =
          Number(this.cabecalho.destinatario.endereco.cidadeIbge);
      this.cabecalho.naturezaOperacao = this.cabecalho.naturezaOperacao?.trim();
      this.cabecalho.total.baseCalculoIcms =
          Number(this.cabecalho.total.baseCalculoIcms);
      this.cabecalho.total.valorIcms = Number(this.cabecalho.total.valorIcms);
      this.cabecalho.total.valorIcmsDesonerado =
          Number(this.cabecalho.total.valorIcmsDesonerado);
      this.cabecalho.total.valorFcpUfDestinatario =
          Number(this.cabecalho.total.valorFcpUfDestinatario);
      this.cabecalho.total.valorIcmsUfDestinatario =
          Number(this.cabecalho.total.valorIcmsUfDestinatario);
      this.cabecalho.total.valorIcmsUfRemet =
          Number(this.cabecalho.total.valorIcmsUfRemet);
      this.cabecalho.total.valorFcp = Number(this.cabecalho.total.valorFcp);
      this.cabecalho.total.valorFcp = Number(this.cabecalho.total.valorFcp);
      this.cabecalho.total.valorFcp = Number(this.cabecalho.total.valorFcp);
      this.cabecalho.total.baseCalculoST =
          Number(this.cabecalho.total.baseCalculoST);
      this.cabecalho.total.valorIcmsST =
          Number(this.cabecalho.total.valorIcmsST);
      this.cabecalho.total.valorFcpST = Number(this.cabecalho.total.valorFcpST);
      this.cabecalho.total.valorFcpSubstituicaoRetido =
          Number(this.cabecalho.total.valorFcpSubstituicaoRetido);
      this.cabecalho.total.valorProduto =
          Number(this.cabecalho.total.valorProduto);
      this.cabecalho.total.valorFrete = Number(this.cabecalho.total.valorFrete);
      this.cabecalho.total.valorSeguro =
          Number(this.cabecalho.total.valorSeguro);
      this.cabecalho.total.valorDesconto =
          Number(this.cabecalho.total.valorDesconto);
      this.cabecalho.total.valorIsento =
          Number(this.cabecalho.total.valorIsento);
      this.cabecalho.total.valorIpi = Number(this.cabecalho.total.valorIpi);
      this.cabecalho.total.valorIpiDevolvido =
          Number(this.cabecalho.total.valorIpiDevolvido);
      this.cabecalho.total.valorPis = Number(this.cabecalho.total.valorPis);
      this.cabecalho.total.valorCofins =
          Number(this.cabecalho.total.valorCofins);
      this.cabecalho.total.valorOutros =
          Number(this.cabecalho.total.valorOutros);
      this.cabecalho.total.valorNotaFiscal =
          Number(this.cabecalho.total.valorNotaFiscal);
      this.cabecalho.total.valorTributos =
          Number(this.cabecalho.total.valorTributos);

      this.cobranca.valorDesconto = Number(this.cobranca.valorDesconto);
      this.cobranca.valorLiquido = Number(this.cobranca.valorLiquido);
      this.cobranca.valorOriginal = Number(this.cobranca.valorOriginal);

      if (this.cobranca.duplicatas?.length > 0) {
        this.cobranca.duplicatas.forEach((duplicata) => {
          duplicata.valor = Number(duplicata.valor);
          duplicata.dataVencimento =
              moment(duplicata.dataVencimento).toLocalOnlyDate();
        });
      }
    }
    if (this.frete) {
      this.frete.valorServico = Number(this.frete.valorServico);
      this.frete.baseCalculo = Number(this.frete.baseCalculo);
      this.frete.aliquota = Number(this.frete.aliquota);
      this.frete.valorIcmsRetido = Number(this.frete.valorIcmsRetido);
      if (!this.frete.veiculo?.placa || !this.frete.veiculo?.rntc ||
          !this.frete.veiculo?.uf) {
        this.frete.veiculo = null;
      }
    }
    if (this.itens?.length > 0) {
      this.itens.forEach((itemNota) => {
        itemNota.cfopEntrada = Number(itemNota.cfopEntrada);
        itemNota.cfopNota = Number(itemNota.cfopNota);
        itemNota.valorDesconto = Number(itemNota.valorDesconto);
        itemNota.valorFrete = Number(itemNota.valorFrete);
        itemNota.valorOutro = Number(itemNota.valorOutro);
        itemNota.valorProduto = Number(itemNota.valorProduto);
        itemNota.valorSeguro = Number(itemNota.valorSeguro);
        itemNota.valorUnitario = Number(itemNota.valorUnitario);
        itemNota.quantidadeTributada = Number(itemNota.quantidadeTributada);
        itemNota.quantidade = Number(itemNota.quantidade);

        itemNota.valorUnitarioTributado =
            Number(itemNota.valorUnitarioTributado);

        itemNota.imposto.origem = Number(itemNota.imposto.origem ?? 0);
        itemNota.imposto.aliquotaCofins =
            Number(itemNota.imposto.aliquotaCofins);
        itemNota.imposto.aliquotaDiferimento =
            Number(itemNota.imposto.aliquotaDiferimento);
        itemNota.imposto.aliquotaIcmsST =
            Number(itemNota.imposto.aliquotaIcmsST);
        itemNota.imposto.aliquotaIvaMva =
            Number(itemNota.imposto.aliquotaIvaMva);
        itemNota.imposto.aliquotaReducaoIcms =
            Number(itemNota.imposto.aliquotaReducaoIcms);
        itemNota.imposto.aliquotaReducaoIcmsST =
            Number(itemNota.imposto.aliquotaReducaoIcmsST);
        itemNota.imposto.baseCalculoIcms =
            Number(itemNota.imposto.baseCalculoIcms);
        itemNota.imposto.baseCalculoIcmsST =
            Number(itemNota.imposto.baseCalculoIcmsST);
        itemNota.imposto.baseCalculoIcmsSTRetido =
            Number(itemNota.imposto.baseCalculoIcmsSTRetido);
        itemNota.imposto.baseCalculoIpi =
            Number(itemNota.imposto.baseCalculoIpi);
        itemNota.imposto.baseCalculoPisCofins =
            Number(itemNota.imposto.baseCalculoPisCofins);
        itemNota.imposto.valorIcmsDesonerado =
            Number(itemNota.imposto.valorIcmsDesonerado);
        itemNota.imposto.valorIcmsDiferido =
            Number(itemNota.imposto.valorIcmsDiferido);
        itemNota.imposto.valorIcmsOperacao =
            Number(itemNota.imposto.valorIcmsOperacao);
        itemNota.imposto.valorIcmsSTRetido =
            Number(itemNota.imposto.valorIcmsSTRetido);
        itemNota.imposto.valorTotalTributo =
            Number(itemNota.imposto.valorTotalTributo);

        itemNota.imposto.aliquotaIcmsFcp = null;
        itemNota.imposto.aliquotaIcmsSTFcp = null;
        itemNota.imposto.aliquotaIcmsSTFcpRetido = null;
        itemNota.imposto.valorIcmsFcp = null;
        itemNota.imposto.valorIcmsSTFcp = null;
        itemNota.imposto.valorIcmsSTFcpRetido = null;
        itemNota.imposto.baseCalculoIcmsFcp = null;
        itemNota.imposto.baseCalculoIcmsSTFcp = null;
        itemNota.imposto.baseCalculoIcmsSTFcpRetido = null;
        itemNota.imposto.aliquotaIcms = Number(itemNota.imposto.aliquotaIcms);
        itemNota.imposto.aliquotaIpi = Number(itemNota.imposto.aliquotaIpi);
        itemNota.imposto.aliquotaPis = Number(itemNota.imposto.aliquotaPis);
        itemNota.imposto.modalidade = Number(itemNota.imposto.modalidade);
        itemNota.imposto.modalidadeST = Number(itemNota.imposto.modalidadeST);
        itemNota.imposto.valorCofins = Number(itemNota.imposto.valorCofins);
        itemNota.imposto.valorIcms = Number(itemNota.imposto.valorIcms);
        itemNota.imposto.valorIpi = Number(itemNota.imposto.valorIpi);
        itemNota.imposto.valorPis = Number(itemNota.imposto.valorPis);
        itemNota.imposto.valorIcmsST = Number(itemNota.imposto.valorIcmsST);
      });
    }
  }
  confirmarEntrada(confirmarEntrada: boolean) {
    if (confirmarEntrada) {
      this.cabecalho.situacao = NFeStatus.Lancada;
    } else {
      this.geraFinanceiro = false;
      this.movimentaEstoque = false;
      this.atualizaPrecoParaTodos = false;
      this.cabecalho.situacao = NFeStatus.Pendente;
    }
  }
}
