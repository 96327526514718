import {Injectable} from '@angular/core';
import {FamiliaListagem} from '@app/abstraction/domain/interfaces/produtos/familia/familia-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FamiliaStore {
  private readonly _familias = new BehaviorSubject<FamiliaListagem[]>(null);
  get familias(): FamiliaListagem[] {
    return this._familias.getValue();
  }
  set familias(familias: FamiliaListagem[]) {
    this._familias.next(familias);
  }
  get familias$() {
    return this._familias.asObservable();
  }

  adicionar(familia: FamiliaListagem) {
    if (!this.familias) {
      this.familias = [];
    }
    this.familias.push(familia);
  }
  adicionarRange(familias: FamiliaListagem[]) {
    if (!this.familias) {
      this.familias = [];
    }
    familias.forEach((familia) => {
      if (!this.familias.some((c) => c.id === familia.id)) {
        this.familias.push(familia);
      }
    });
  }
}
