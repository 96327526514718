declare interface Array<T> {
  orderBy(keySelector: (key: T) => any): Array<T>;
  orderByDescending(keySelector: (key: T) => any): Array<T>;
  sum(keySelector: (key: T) => any): number;
  first(): T;
  last(): T;
  groupBy<TKey>(keySelector: (key: T) => TKey):
      Array<{key: TKey; items: Array<T>}>;
  addRange(items: Array<T>): void;
  remove(item: T): void;
  removeAt(index: number): void;
  removeWhere(predicate: (item: T) => boolean): void;
  insertAt(index: number, item: T): void;
  clear(): void;
  someEquals<TValue>(selector: (item: T) => TValue): boolean;
  removeDuplicados<U>(selector: (item: T) => U): T[];
}
Array.prototype.orderBy = function<T>(keySelector: (key: T) => any) {
  return this.sort(
      (a, b) => keySelector(a) > keySelector(b) ? 1 :
          keySelector(b) > keySelector(a)       ? -1 :
                                                  0);
};
Array.prototype.orderByDescending = function<T>(keySelector: (key: T) => any) {
  return this.sort(
      (a, b) => keySelector(b) > keySelector(a) ? 1 :
          keySelector(a) > keySelector(b)       ? -1 :
                                                  0);
};
Array.prototype.sum = function<T>(keySelector: (key: T) => any) {
  return this.length > 0 ? this.map(keySelector).reduce((a, b) => a + b) : 0;
};
Array.prototype.first = function() {
  if (this && this.length > 0) {
    return this[0];
  }
  return null;
};
Array.prototype.last = function() {
  if (this && this.length > 0) {
    const length = this.length;
    return this[length - 1];
  }
  return null;
};
Array.prototype.groupBy = function<T, TKey>(keySelector: (key: T) => TKey) {
  return this.reduce((groups, item) => {
    const key = keySelector(item);
    const group = groups.find((g: any) => g.key === key);
    if (group) {
      group.items.push(item);
    } else {
      groups.push({key, items: [item]});
    }
    return groups;
  }, []);
};
Array.prototype.addRange = function<T>(items: Array<T>): void {
  this.push(...items);
};
Array.prototype.remove = function<T>(item: T): void {
  const index = this.indexOf(item);
  if (index > -1) {
    this.splice(index, 1);
  }
};
Array.prototype.removeAt = function(index: number): void {
  if (index > -1) {
    this.splice(index, 1);
  }
};
Array.prototype.removeWhere = function<T>(predicate: (item: T) => boolean):
    void {
  for (let i = this.length - 1; i >= 0; i--) {
    if (predicate(this[i])) {
      this.splice(i, 1);
    }
  }
};
Array.prototype.insertAt = function<T>(index: number, item: T): void {
  this.splice(index, 0, item);
};
Array.prototype.clear = function(): void {
  this.splice(0, this.length);
};
Array.prototype.someEquals = function<T, TValue>(selector: (item: T) => TValue):
    boolean {
  if (this.length === 0) return true;
  const primeiroValor = selector(this[0]);
  return this.every(objeto => selector(objeto) === primeiroValor);
};
Array.prototype.removeDuplicados = function<T, U>(selector: (item: T) => U):
    T[] {
  const mapaDeValores: any = {};
  const resultado: T[] = [];

  for (const item of this) {
    const valorDoSelector = selector(item);

    if (!mapaDeValores[valorDoSelector]) {
      mapaDeValores[valorDoSelector] = true;
      resultado.push(item);
    }
  }

  return resultado;
};