import {Injectable} from '@angular/core';
import {ProdutoVariacaoCorListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-cor/produto-variacao-cor-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProdutoVariacaoCorStore {
  private readonly _produtoVariacaoCores =
      new BehaviorSubject<ProdutoVariacaoCorListagem[]>(null);

  get produtoVariacaoCores(): ProdutoVariacaoCorListagem[] {
    return this._produtoVariacaoCores.getValue();
  }
  set produtoVariacaoCores(produtoVariacaoCores: ProdutoVariacaoCorListagem[]) {
    this._produtoVariacaoCores.next(produtoVariacaoCores);
  }
  get produtoVariacaoCores$() {
    return this._produtoVariacaoCores.asObservable();
  }
  adicionar(produtoVariacaoCor: ProdutoVariacaoCorListagem) {
    if (!this.produtoVariacaoCores) {
      this.produtoVariacaoCores = [];
    }
    this.produtoVariacaoCores.push(produtoVariacaoCor);
  }
  adicionarRange(produtoVariacaoCores: ProdutoVariacaoCorListagem[]) {
    if (!this.produtoVariacaoCores) {
      this.produtoVariacaoCores = [];
    }
    produtoVariacaoCores.forEach((produtoVariacaoCor) => {
      if (!this.produtoVariacaoCores.some(
              (c) => c.id === produtoVariacaoCor.id)) {
        this.produtoVariacaoCores.push(produtoVariacaoCor);
      }
    });
  }
}
