import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {IContatoForm} from '@app/abstraction/domain/forms/shared/contato.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-contato-modal',
  templateUrl: './contato-modal.component.html',
})
export class ContatoModalComponent extends FormBaseComponent {
  @Input() emailObrigatorio: boolean;
  @Input() form: FormGroup<IContatoForm>;
  @Input() mostrarModal: boolean;
  @Input() titulo: string;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSalvar = new EventEmitter<FormGroup<IContatoForm>>();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }

  constructor(
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }

  fecharModal() {
    this.aoCancelar.emit();
  }
  salvar() {
    this.aoSalvar.emit(this.form);
  }
}
