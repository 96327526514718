<div class="container" *ngIf="grupos">
  <p-tabView [scrollable]="true">
    <p-tabPanel [header]="grupo.texto" *ngFor="let grupo of grupos">
      <div class="formgrid grid">
        <div class="field col-12 xl:col-4 lg:col-4 md:col-6 mt-1">
          <app-checkbox-input-ngmodel
            label="Todos"
            [for]="grupo.valor"
            [readonly]="grupo.bloqueado"
            [model]="grupo.todosSelecionados"
            (modelChange)="toggleTodosSelecionados(grupo)"
          ></app-checkbox-input-ngmodel>
        </div>
      </div>

      <div class="formgrid grid">
        <div
          class="field col-12 xl:col-4 lg:col-4 md:col-6"
          *ngFor="let item of grupo.filhos; let i = index"
        >
          <div class="grupo-item" [ngClass]="{ 'item-filhos': item.filhos }">
            <div class="field-checkbox">
              <p-checkbox
                [name]="item.texto"
                [value]="item.valor"
                [inputId]="item.valor"
                [disabled]="grupo.bloqueado"
                [(ngModel)]="itensSelecionados"
                (onChange)="emitirSelecionados(grupo, item)"
              ></p-checkbox>
              <label [for]="item.valor">{{ item.texto }}</label>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
