import {Injectable} from '@angular/core';
import {NotaFiscalListagem} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-listagem.interface';
import {NotaFiscalResumoListagem} from '@app/abstraction/domain/interfaces/fiscal/nota-fiscal/nota-fiscal-resumo-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotaFiscalStore {
  private readonly _notasFiscaisEntrada =
      new BehaviorSubject<NotaFiscalListagem[]>(null);
  private readonly _notasFiscaisSaida =
      new BehaviorSubject<NotaFiscalListagem[]>(null);
  private readonly _notasFiscaisResumos =
      new BehaviorSubject<NotaFiscalResumoListagem[]>(null);

  get notasFiscaisEntrada(): NotaFiscalListagem[] {
    return this._notasFiscaisEntrada.getValue();
  }
  set notasFiscaisEntrada(notasFiscais: NotaFiscalListagem[]) {
    this._notasFiscaisEntrada.next(notasFiscais);
  }
  get notasFiscaisEntrada$() {
    return this._notasFiscaisEntrada.asObservable();
  }
  get notasFiscaisSaida(): NotaFiscalListagem[] {
    return this._notasFiscaisSaida.getValue();
  }
  set notasFiscaisSaida(notasFiscais: NotaFiscalListagem[]) {
    this._notasFiscaisSaida.next(notasFiscais);
  }
  get notasFiscaisSaida$() {
    return this._notasFiscaisSaida.asObservable();
  }
  get notasFiscaisResumos(): NotaFiscalResumoListagem[] {
    return this._notasFiscaisResumos.getValue();
  }
  set notasFiscaisResumos(resumos: NotaFiscalResumoListagem[]) {
    this._notasFiscaisResumos.next(resumos);
  }
  get notasFiscaisResumos$() {
    return this._notasFiscaisResumos.asObservable();
  }

  adicionarRangeEntrada(notasFiscaisEntrada: NotaFiscalListagem[]) {
    if (!this.notasFiscaisEntrada) {
      this.notasFiscaisEntrada = [];
    }

    notasFiscaisEntrada.forEach((notaFiscalEntrada: NotaFiscalListagem) => {
      if (!this.notasFiscaisEntrada.some(
              (c: NotaFiscalListagem) => c.id == notaFiscalEntrada.id)) {
        this.notasFiscaisEntrada.push(notaFiscalEntrada);
      }
    });
  }
  adicionarRangeSaida(notasFiscaisSaida: NotaFiscalListagem[]) {
    if (!this.notasFiscaisSaida) {
      this.notasFiscaisSaida = [];
    }

    notasFiscaisSaida.forEach((notaFiscalSaida: NotaFiscalListagem) => {
      if (!this.notasFiscaisSaida.some(
              (c: NotaFiscalListagem) => c.id == notaFiscalSaida.id)) {
        this.notasFiscaisSaida.push(notaFiscalSaida);
      }
    });
  }
  adicionarRangeNotasFiscaisResumo(notasFiscaisResumos:
                                       NotaFiscalResumoListagem[]) {
    if (!this.notasFiscaisResumos) {
      this.notasFiscaisResumos = [];
    }

    notasFiscaisResumos.forEach((notaFiscal: NotaFiscalResumoListagem) => {
      if (!this.notasFiscaisResumos.some(
              (c: NotaFiscalResumoListagem) =>
                  c.chaveEletronica == notaFiscal.chaveEletronica)) {
        this.notasFiscaisResumos.push(notaFiscal);
      }
    });
  }
}
