import {Injectable} from '@angular/core';
import {BancoListagem} from '@app/abstraction/domain/interfaces/financeiro/banco/banco-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BancoStore {
  private readonly _bancos = new BehaviorSubject<BancoListagem[]>(null);
  get bancos(): BancoListagem[] {
    return this._bancos.getValue();
  }
  set bancos(bancos: BancoListagem[]) {
    this._bancos.next(bancos);
  }
  get bancos$() {
    return this._bancos.asObservable();
  }

  adicionar(banco: BancoListagem) {
    if (!this.bancos) {
      this.bancos = [];
    }
    this.bancos.push(banco);
  }
  adicionarRange(bancos: BancoListagem[]) {
    if (!this.bancos) {
      this.bancos = [];
    }
    bancos.forEach((banco) => {
      if (!this.bancos.some((c) => c.codigo === banco.codigo)) {
        this.bancos.push(banco);
      }
    });
  }
}
