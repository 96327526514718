<ng-container>
  <div class="p-inputgroup">
    <p-button
      type="button"
      icon="pi pi-minus"
      styleClass="btn-five"
      (onClick)="decrementar()"
      [disabled]="buttonDisabled"
    >
    </p-button>
    <input
      class=" inputfield w-full"
      pInputText
      appAutoFocus
      currencyMask
      [options]="options"
      [autofocus]="autoFocus"
      [readonly]="readonly"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      [minlength]="minlength"
      [ngClass]="{
        readonly: readonly,
        'text-right': textAlign === 'right',
        'text-center': textAlign === 'center',
        'text-left': textAlign === 'left'
      }"
      [ngModelOptions]="modelOptions"
      (blur)="onBlur($event)"
      (keydown)="onKeyDown($event)"
      (ngModelChange)="onNgModelChange($event)"
      [(ngModel)]="model"
    />
    <p-button
      type="button"
      icon="pi pi-plus"
      styleClass="btn-five"
      (onClick)="incrementar()"
      [disabled]="buttonDisabled"
    >
    </p-button>
  </div>
</ng-container>
