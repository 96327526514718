import {FormControl, FormGroup} from '@angular/forms';
import {NcmIcms} from '@app/abstraction/domain/entities/fiscal/ncm/ncm-icms.entity';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface INcmIcmsForm {
  cestCodigo: FormControl<string>;
  icmsAliquotaEntrada: FormControl<string|number>;
  icmsAliquotaEntradaIva: FormControl<string|number>;
  icmsAliquotaEntradaReducao: FormControl<string|number>;
  icmsAliquotaSaida: FormControl<string|number>;
  icmsAliquotaSaidaFinal: FormControl<string|number>;
  icmsAliquotaSaidaReducao: FormControl<string|number>;
  icmsCstCsosnEntrada: FormControl<string>;
  icmsCstCsosnSaida: FormControl<string>;
  ncmCodigo: FormControl<string>;
  ncmIcmsId: FormControl<string>;
  origem: FormControl<string>;
  produtoServico: FormControl<string>;
  uf: FormControl<string>;
}

export class NcmIcmsForm {
  static criarForm(ncmIcms: NcmIcms) {
    return new FormGroup<INcmIcmsForm>({
      cestCodigo: new FormControl(ncmIcms?.cestCodigo),
      origem: new FormControl(
          ncmIcms?.origem,
          CustomValidators.required('Origem Produto é obrigatório'),
          ),
      produtoServico: new FormControl(
          ncmIcms?.produtoServico,
          CustomValidators.required('Produto/Serviço é obrigatório'),
          ),
      uf: new FormControl(
          ncmIcms?.uf,
          CustomValidators.required('Estado é obrigatório'),
          ),
      icmsCstCsosnEntrada: new FormControl(
          ncmIcms?.icmsCstCsosnEntrada,
          CustomValidators.required('CST/CSOSN Entrada ICMS é obrigatório'),
          ),
      icmsCstCsosnSaida: new FormControl(
          ncmIcms?.icmsCstCsosnSaida,
          CustomValidators.required('CST/CSOSN Saída ICMS é obrigatório'),
          ),
      icmsAliquotaEntrada: new FormControl(ncmIcms?.icmsAliquotaEntrada),
      icmsAliquotaEntradaIva: new FormControl(ncmIcms?.icmsAliquotaEntradaIva),
      icmsAliquotaEntradaReducao:
          new FormControl(ncmIcms?.icmsAliquotaEntradaReducao),
      icmsAliquotaSaida: new FormControl(ncmIcms?.icmsAliquotaSaida),
      icmsAliquotaSaidaFinal: new FormControl(ncmIcms?.icmsAliquotaSaidaFinal),
      icmsAliquotaSaidaReducao:
          new FormControl(ncmIcms?.icmsAliquotaSaidaReducao),
      ncmCodigo: new FormControl(ncmIcms?.ncmCodigo),
      ncmIcmsId: new FormControl(ncmIcms?.ncmIcmsId),
    });
  }
}
