import {FormControl, FormGroup} from '@angular/forms';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

import {Setor} from '../../entities/cadastros/setor/setor.entity';

export interface ISetorForm {
  id: FormControl<string>;
  descricao: FormControl<string>;
  impressoraId: FormControl<string>;
  exibirValor: FormControl<boolean>;
  quantidadeVia: FormControl<number>;
  quantidadeCasaDecimal: FormControl<number>;
  codigoExterno: FormControl<string>;
  ativo: FormControl<boolean>;
}

export class SetorForm extends BaseForm {
  form: FormGroup<ISetorForm>;
  operacaoForm: OperacaoForm;
  mostrarImpressoraCadastroRapidoModal: boolean;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova Setor' : 'Editar Setor';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(private errorMessagesFacade: ErrorMessageFacade) {
    super();
  }
  async isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }

    return true;
  }
  async preencherForm(setor: Setor) {
    this.form.patchValue(setor);
  }
  criarForm() {
    this.form = new FormGroup<ISetorForm>({
      id: new FormControl<string>(null),
      descricao: new FormControl<string>(
          null, [CustomValidators.required('Descrição é obrigatória')]),
      impressoraId: new FormControl<string>(
          null, [CustomValidators.required('Impressora é obrigatória')]),
      exibirValor: new FormControl<boolean>(true),
      quantidadeVia: new FormControl<number>(
          1,
          [
            CustomValidators.min(1, 'Quantidade de vias deve ser maior que 0'),
            CustomValidators.required('Quantidade de vias é obrigatória')
          ]),
      quantidadeCasaDecimal: new FormControl<number>(
          2,
          [
            CustomValidators.min(
                0, 'Quantidade de casas decimais deve ser maior ou igual a 0'),
            CustomValidators.required(
                'Quantidade de casas decimais é obrigatória')
          ]),
      codigoExterno: new FormControl<string>(null),
      ativo: new FormControl<boolean>(true),
    });
  }
  setarTipoFormulario(setor?: Setor) {
    if (setor) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(Setor.from(setor));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
  abrirImpressoraCadastroRapidoModal() {
    this.mostrarImpressoraCadastroRapidoModal = true;
  }
  fecharImpressoraCadastroRapidoModal() {
    this.mostrarImpressoraCadastroRapidoModal = false;
  }
}
