import { Injectable } from '@angular/core';
import { ContaBancariaListagem } from '@app/abstraction/domain/interfaces/financeiro/conta-bancaria/conta-bancaria-listagem.interface';
import { ExtratoBancarioListagem } from '@app/abstraction/domain/interfaces/financeiro/extrato-bancario/extrato-bancario-listagem.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContaBancariaStore {
  private readonly _contasBancarias = new BehaviorSubject<
    ContaBancariaListagem[]
  >(null);
  get contasBancarias(): ContaBancariaListagem[] {
    return this._contasBancarias.getValue();
  }
  set contasBancarias(contasBancarias: ContaBancariaListagem[]) {
    this._contasBancarias.next(contasBancarias);
  }
  get contasBancarias$(): Observable<ContaBancariaListagem[]> {
    return this._contasBancarias.asObservable();
  }
  adicionar(centroCusto: ContaBancariaListagem) {
    if (!this.contasBancarias) {
      this.contasBancarias = [];
    }
    this.contasBancarias.push(centroCusto);
  }
  adicionarRange(contasBancarias: ContaBancariaListagem[]) {
    if (!this.contasBancarias) {
      this.contasBancarias = [];
    }
    contasBancarias.forEach((contaBancaria) => {
      if (!this.contasBancarias.some((c) => c.id === contaBancaria.id)) {
        this.contasBancarias.push(contaBancaria);
      }
    });
  }

  private readonly _extratosBancarios = new BehaviorSubject<
    ExtratoBancarioListagem[]
  >(null);
  get extratosBancarios(): ExtratoBancarioListagem[] {
    return this._extratosBancarios.getValue();
  }
  set extratosBancarios(extratosBancarios: ExtratoBancarioListagem[]) {
    this._extratosBancarios.next(extratosBancarios);
  }
  get extratosBancarios$(): Observable<ExtratoBancarioListagem[]> {
    return this._extratosBancarios.asObservable();
  }
  adicionarExtratoBancario(centroCusto: ExtratoBancarioListagem) {
    if (!this.extratosBancarios) {
      this.extratosBancarios = [];
    }
    this.extratosBancarios.push(centroCusto);
  }
  adicionarRangeExtratoBancario(extratosBancarios: ExtratoBancarioListagem[]) {
    if (!this.extratosBancarios) {
      this.extratosBancarios = [];
    }
    extratosBancarios.forEach((extratoBancario) => {
      if (!this.extratosBancarios.some((c) => c.id === extratoBancario.id)) {
        this.extratosBancarios.push(extratoBancario);
      }
    });
  }
}
