export enum NFeStatus {
  Pendente = 0,
  EmProcessamento = 1,
  Emitida = 2,
  Inutilizada = 3,
  Cancelada = 4,
  Lancada = 5,
  Rejeitada = 6
}

export const NFeStatusLabels = new Map<number, string>([
  [NFeStatus.Pendente, 'Pendente'],
  [NFeStatus.EmProcessamento, 'Em processamento'],
  [NFeStatus.Emitida, 'Emitida'],
  [NFeStatus.Inutilizada, 'Inutilizada'],
  [NFeStatus.Cancelada, 'Cancelada'],
  [NFeStatus.Lancada, 'Entrada'],
  [NFeStatus.Rejeitada, 'Rejeitada'],
]);

export const NFeSaidaStatusLabels = new Map<number, string>([
  [NFeStatus.Pendente, 'Pendente'],
  [NFeStatus.EmProcessamento, 'Em processamento'],
  [NFeStatus.Emitida, 'Emitida'],
  [NFeStatus.Inutilizada, 'Inutilizada'],
  [NFeStatus.Cancelada, 'Cancelada'],
  [NFeStatus.Rejeitada, 'Rejeitada'],
]);

export const NFeEntradaStatusLabels = new Map<number, string>([
  [NFeStatus.Pendente, 'Pendente'], [NFeStatus.Lancada, 'Lançada'],
  [NFeStatus.Cancelada, 'Cancelada']
]);
