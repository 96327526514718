import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Banco} from '@app/abstraction/domain/entities/financeiro/banco/banco.entity';
import {BancoFiltro} from '@app/abstraction/domain/interfaces/financeiro/banco/banco-filtro.interface';
import {BancoListagem} from '@app/abstraction/domain/interfaces/financeiro/banco/banco-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BancoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Banco) {
    const response$ =
        this.client.post<Banco>(API.FINANCEIRO_BANCO, dados)
            .pipe(map((json: Banco) => json ? Banco.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Banco, id: string) {
    const response$ =
        this.client.put<Banco>(`${API.FINANCEIRO_BANCO}/${id}`, dados)
            .pipe(map((json: Banco) => json ? Banco.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.FINANCEIRO_BANCO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Banco>(`${API.FINANCEIRO_BANCO}/${id}`)
            .pipe(map((json: Banco) => json ? Banco.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: BancoFiltro) {
    const response$ = this.client.get<BancoListagem[]>(
        this.geradorQueryString.gerar(`${API.FINANCEIRO_BANCO}`, filtro));
    return lastValueFrom(response$);
  }
}
