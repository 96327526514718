export enum Adquirente {
  Nenhum,
  Pagseguro = 'PagSeguro',
  Stone = 'Stone',
}

export const AdquirenteLabels = new Map<any, string>([
  [Adquirente.Pagseguro, 'PAGSEGURO'],
  [Adquirente.Stone, 'STONE'],
]);
