<div class="progress-bar-container" [ngClass]="{ show: mostrarProgresso }">
  <p-progressBar
    styleClass="mb-2"
    [value]="progresso"
    [style]="{ height: '20px' }"
  ></p-progressBar>
  <div class="legendas">
    <span>{{ mensagem }}</span>
    <span class="pl-2 float-right" *ngIf="mostrarProgressoFormatado">{{ progressoFormatado }}</span>
  </div>
</div>
