import {Cnab} from '@app/shared/enums/cnab.enum';

export class ContaBancariaBoletoCarteira {
  padrao: boolean;
  convenio: string;
  taxa: number;
  somarTaxa: boolean;
  codigoCarteira: string;
  digitoConvenio: string;
  variacaoCarteira: string;
  cnab: Cnab;

  static from(json: any = {}) {
    const contaBoletoCarteira = new ContaBancariaBoletoCarteira();
    contaBoletoCarteira.padrao = json.padrao;
    contaBoletoCarteira.convenio = json.convenio?.trim();
    contaBoletoCarteira.taxa = json?.taxa ?? 0;
    contaBoletoCarteira.somarTaxa = json.somarTaxa;
    contaBoletoCarteira.codigoCarteira = json.codigoCarteira?.trim();
    contaBoletoCarteira.digitoConvenio = json.digitoConvenio?.trim();
    contaBoletoCarteira.variacaoCarteira = json.variacaoCarteira?.trim();
    contaBoletoCarteira.cnab = json.cnab;
    return contaBoletoCarteira;
  }
}
