import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgxCurrencyConfig} from 'ngx-currency';


@Component({
  selector: 'app-step-numeric-input',
  templateUrl: './step-numeric-input.component.html',
  styleUrls: ['./step-numeric-input.component.scss']
})
export class StepNumericInputComponent {
  @Input() autoFocus: boolean;
  @Input() textAlign: string = 'center';  // left, right, center
  @Input() model: number;
  @Input() modelOptions: any;
  @Input()
  options: NgxCurrencyConfig = <NgxCurrencyConfig>{
    align: 'right',
    allowNegative: true,
    allowZero: true,
    decimal: ',',
    precision: 0,
    prefix: '',
    suffix: '',
    thousands: '.',
    nullable: true,
  };
  @Input() placeholder: string = '';
  @Input() readonly: boolean;
  @Input() buttonDisabled: boolean;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() steps: number = 1;
  @Output() modelChange = new EventEmitter();
  @Output() inputBlur = new EventEmitter();
  @Output() inputKeydown = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;

  onBlur(event: any) {
    this.inputBlur.emit(event);
  }
  onKeyDown(event: any) {
    this.inputKeydown.emit(event);
  }
  onNgModelChange(event: any) {
    this.modelChange.emit(event);
  }
  incrementar() {
    this.model += this.steps;
    this.modelChange.emit(this.model);
  }
  decrementar() {
    if (!this.options.allowNegative && this.model == 0) return;
    this.model -= this.steps;
    this.modelChange.emit(this.model);
  }
}
