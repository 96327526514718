import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NaturezaReceita} from '@app/abstraction/domain/entities/cadastros/natureza-receita/natureza-receita.entity';
import {NaturezaReceitaFiltro} from '@app/abstraction/domain/interfaces/cadastros/natureza-receita/natureza-receita-filtro.interface';
import {NaturezaReceitaListagem} from '@app/abstraction/domain/interfaces/cadastros/natureza-receita/natureza-receita-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NaturezaReceitaService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: NaturezaReceita) {
    const response$ =
        this.client.post<NaturezaReceita>(API.NATUREZA_RECEITA, dados)
            .pipe(
                map((json: NaturezaReceita) =>
                        json ? NaturezaReceita.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: NaturezaReceita, id: string) {
    const response$ =
        this.client.put<NaturezaReceita>(`${API.NATUREZA_RECEITA}/${id}`, dados)
            .pipe(
                map((json: NaturezaReceita) =>
                        json ? NaturezaReceita.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<NaturezaReceita>(`${API.NATUREZA_RECEITA}/${id}`)
            .pipe(
                map((json: NaturezaReceita) =>
                        json ? NaturezaReceita.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: NaturezaReceitaFiltro) {
    const response$ = this.client.get<NaturezaReceitaListagem[]>(
        this.geradorQueryString.gerar(`${API.NATUREZA_RECEITA}`, filtro));
    return lastValueFrom(response$);
  }
}
