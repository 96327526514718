import {Injectable} from '@angular/core';
import {ProdutoVariacaoTamanhoListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-tamanho/produto-variacao-tamanho-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProdutoVariacaoTamanhoStore {
  private readonly _produtoVariacaoTamanhos =
      new BehaviorSubject<ProdutoVariacaoTamanhoListagem[]>(null);

  get produtoVariacaoTamanhos(): ProdutoVariacaoTamanhoListagem[] {
    return this._produtoVariacaoTamanhos.getValue();
  }
  set produtoVariacaoTamanhos(produtoVariacaoTamanhos:
                                  ProdutoVariacaoTamanhoListagem[]) {
    this._produtoVariacaoTamanhos.next(produtoVariacaoTamanhos);
  }
  get produtoVariacaoTamanhos$() {
    return this._produtoVariacaoTamanhos.asObservable();
  }
  adicionar(produtoVariacaoTamanho: ProdutoVariacaoTamanhoListagem) {
    if (!this.produtoVariacaoTamanhos) {
      this.produtoVariacaoTamanhos = [];
    }
    this.produtoVariacaoTamanhos.push(produtoVariacaoTamanho);
  }
  adicionarRange(produtoVariacaoTamanhos: ProdutoVariacaoTamanhoListagem[]) {
    if (!this.produtoVariacaoTamanhos) {
      this.produtoVariacaoTamanhos = [];
    }
    produtoVariacaoTamanhos.forEach((produtoVariacaoTamanho) => {
      if (!this.produtoVariacaoTamanhos.some(
              (c) => c.id === produtoVariacaoTamanho.id)) {
        this.produtoVariacaoTamanhos.push(produtoVariacaoTamanho);
      }
    });
  }
}
