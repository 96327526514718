export enum Contexto {
  Geral = 1,
  FrontEnd,
  BackEnd
}

export const ContextoLabels = new Map<number, string>([
  [Contexto.Geral, 'Geral'],
  [Contexto.FrontEnd, 'FrontEnd'],
  [Contexto.BackEnd, 'BackEnd'],
]);