export enum SituacaoConta {
  Aberto,
  Parcial,
  Baixado,
  Vencido,
}

export const SituacaoContaLabels = new Map<number, string>([
  [SituacaoConta.Aberto, 'NÃO PAGO'],
  [SituacaoConta.Parcial, 'PARCIAL'],
  [SituacaoConta.Baixado, 'PAGO'],
  [SituacaoConta.Vencido, 'VENCIDO'],
]);
