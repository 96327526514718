import * as moment from 'moment';

export class NotaFiscalRetorno {
  status: number;
  motivo: string;
  numeroRecibo: string;
  numeroProtocolo: string;
  ultimoNsu: number;
  dataHora: Date;

  static from(json: any = {}): NotaFiscalRetorno {
    const retorno = new NotaFiscalRetorno();
    retorno.status = json.status;
    retorno.motivo = json.motivo?.trim();
    retorno.numeroRecibo = json.numeroRecibo?.trim();
    retorno.numeroProtocolo = json.numeroProtocolo?.trim();
    retorno.ultimoNsu = json.ultimoNsu;
    retorno.dataHora =
        json.dataHora != null ? moment(json.dataHora).toDate() : null;
    return retorno;
  }
}
