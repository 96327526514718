import { Component, Input } from '@angular/core';
import { Widget } from './interfaces/widget';

@Component({
  selector: 'app-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent {
  @Input() widgets: Widget[];
  @Input() skeletonCount: number;

  abrirLinkNovaAba(widget: Widget) {
    if (widget.abrirLinkNovaAba) {
      window.open(widget.linkNovaAba);
    }
  }
}
