import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OAuthFacade} from '@app/abstraction/domain/facades/shared/o-auth/o-auth.facade';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private oauthFacade: OAuthFacade) {}
  intercept(request: HttpRequest<any>, next: HttpHandler):
      Observable<HttpEvent<Object>> {
    return next.handle(request).pipe(catchError((http: HttpErrorResponse) => {
      if (http.status === 400) {
        if (http.error.error === 'invalid_grant' &&
            http.error.error_description === 'invalid_username_or_password') {
          return throwError(() => 'Usuário ou senha incorretos');
        } else if (http.error.error === 'invalid_grant') {
          this.oauthFacade.logout(true);
        }
      } else if (http.status === 401) {
        if (http.error?.error === 'invalid_token') {
          return this.handle401Error(request, next);
        }
      }

      return throwError(() => http);
    }));
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.oauthFacade.refreshToken().pipe(
        switchMap((token) => {
          const apiReq = request.clone({
            headers: request.headers.set(
                'authorization', 'Bearer ' + token.access_token)
          })
          return next.handle(apiReq);
        }),
        catchError(() => {
          this.oauthFacade.logout(true);
          return next.handle(request);
        }));
  }
}