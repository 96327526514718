import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-header-primeiro-acesso',
  templateUrl: './header-primeiro-acesso.component.html',
  styleUrls: ['./header-primeiro-acesso.component.scss'],
})
export class HeaderPrimeiroAcessoComponent implements OnInit {
  @Input() usuarioNome: string;
  @Output() aoClicarSair = new EventEmitter();
  displayDropdown: boolean;
  items: MenuItem[];

  ngOnInit() {
    this.items = [
      {
        label: 'Sair',
        icon: 'pi pi-fw pi-power-off',
        command: () => this.sair(),
      },
    ];
  }
  toggleDropdownItems() {
    this.displayDropdown = !this.displayDropdown;
  }
  sair() {
    this.aoClicarSair.emit();
  }
}
