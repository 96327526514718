import {Injectable} from '@angular/core';
import {PerfilListagem} from '@app/abstraction/domain/interfaces/cadastros/perfil/perfil-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PerfilStore {
  private readonly _perfis = new BehaviorSubject<PerfilListagem[]>(null);

  get perfis(): PerfilListagem[] {
    return this._perfis.getValue();
  }
  set perfis(perfis: PerfilListagem[]) {
    this._perfis.next(perfis);
  }
  get perfis$() {
    return this._perfis.asObservable();
  }
  adicionarRange(perfis: PerfilListagem[]) {
    if (!this.perfis) {
      this.perfis = [];
    }
    perfis.forEach((perfil: PerfilListagem) => {
      if (!this.perfis.some((c: PerfilListagem) => c.id === perfil.id)) {
        this.perfis.push(perfil);
      }
    });
  }
}
