<p-dialog
  header="Nova impressora"
  [style]="{ width: isDesktop ? '80%' : '90%' }"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharModal()"
  (onShow)="aoAbrirModal()"
  [(visible)]="mostrarModal"
>
  <app-impressora-shared-form #formComponent></app-impressora-shared-form>

  <ng-template pTemplate="footer">
    <p-button (click)="fecharModal()" label="Cancelar" [text]="true"></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>
