import {FornecedorProduto} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor-produto.entity';

import {ProdutoVariacaoDimensionamento} from './produto-variacao-dimensionamento.entity';
import {ProdutoVariacaoEstoque} from './produto-variacao-estoque.entity';
import {ProdutoVariacaoFoto} from './produto-variacao-foto.entity';
import {ProdutoVariacaoPreco} from './produto-variacao-preco.entity';

export class ProdutoVariacao {
  ativo: boolean;
  codigo: number;
  codigoBarras: string;
  corId: string;
  id: string;
  produtoId: string;
  referenciaId: string;
  tamanhoId: string;
  sku: string;
  estoques: ProdutoVariacaoEstoque[];
  fotos: ProdutoVariacaoFoto[];
  precos: ProdutoVariacaoPreco[];
  fornecedores: FornecedorProduto[];
  dimensionamento: ProdutoVariacaoDimensionamento;
  eanRelacionados: string[];
  informacaoAdicional: string;
  static criarProdutoVariacao(value: any) {
    const variacao = new ProdutoVariacao();
    variacao.ativo = value.ativo;
    variacao.codigo = value.codigo;
    variacao.codigoBarras = value.codigoBarras?.trim();
    variacao.produtoId = value.produtoId;
    variacao.corId = value.corId;
    variacao.estoques = value.estoques;
    variacao.fotos = value.fotos;
    variacao.id = value.id;
    variacao.precos = value.precos;
    variacao.referenciaId = value.referenciaId;
    variacao.tamanhoId = value.tamanhoId;
    variacao.sku = value.sku;
    variacao.fornecedores = value.fornecedores;
    variacao.eanRelacionados = value.eanRelacionados;
    variacao.informacaoAdicional = value.informacaoAdicional;
    variacao.dimensionamento = value.dimensionamento;
    return variacao;
  }
}
