export enum TipoPdv {
  Nenhum,
  Smartphone = 'Smartphone',
  SmartPOS = 'SmartPos',
}

export const TipoPdvLabels = new Map<TipoPdv, string>([
  [TipoPdv.Smartphone, 'SMARTPHONE'],
  [TipoPdv.SmartPOS, 'SMARTPOS'],
]);
