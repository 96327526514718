import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UsuarioPermissoes} from '@app/abstraction/domain/entities/cadastros/usuario/usuario-permissoes.entity';
import {Usuario} from '@app/abstraction/domain/entities/cadastros/usuario/usuario.entity';
import {UsuarioFiltro} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-filtro.interface';
import {UsuarioListagem} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UsuarioService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Usuario) {
    const response$ =
        this.client.post<Usuario>(API.USUARIO, dados)
            .pipe(map((json: Usuario) => json ? Usuario.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Usuario, id: string) {
    const response$ =
        this.client.put<Usuario>(`${API.USUARIO}/${id}`, dados)
            .pipe(map((json: Usuario) => json ? Usuario.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.USUARIO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPermissoesPorChaveUsuario(chave: string) {
    const response$ = this.client.get<UsuarioPermissoes>(
        `${API.USUARIO}/${chave}/${API.PERMISSAO}`);
    return lastValueFrom(response$);
  }
  buscarPorChave(chave: string) {
    const response$ =
        this.client.get<Usuario>(`${API.USUARIO}/${chave}`)
            .pipe(map((json: Usuario) => json ? Usuario.from(json) : null));
    return lastValueFrom(response$);
  }
  verificarSenhaDefinida(email: string) {
    const response$ = this.client.get<boolean>(`${API.USUARIO}/email/${email}`);
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: UsuarioFiltro) {
    const response$ = this.client.get<UsuarioListagem[]>(
        this.geradorQueryString.gerar(API.USUARIO, filtro));
    return lastValueFrom(response$);
  }
  enviarConvite(usuario: Usuario) {
    const response$ =
        this.client.post<Usuario>(`${API.USUARIO_CONVITE}`, usuario);
    return lastValueFrom(response$);
  }
}