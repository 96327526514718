import {NaturezaReceitaListagem} from '@app/abstraction/domain/interfaces/cadastros/natureza-receita/natureza-receita-listagem.interface';
export class NaturezaReceita {
  cstPisCofinsSaida: string;
  descricao: string;
  naturezaReceitaCodigo: number;
  id: string;
  static from(value: any) {
    const naturezaReceita = new NaturezaReceita();
    naturezaReceita.cstPisCofinsSaida = value.cstPisCofinsSaida?.trim();
    naturezaReceita.descricao = value.descricao?.trim();
    naturezaReceita.naturezaReceitaCodigo = value.naturezaReceitaCodigo;
    naturezaReceita.id = value.id;
    return naturezaReceita;
  }
  toListagem() {
    return <NaturezaReceitaListagem> {
      id: this.id, descricao: this.descricao,
          naturezaReceitaCodigo: this.naturezaReceitaCodigo,
          cstPisCofinsSaida: this.cstPisCofinsSaida
    }
  }
}
