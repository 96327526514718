<app-table-skeleton *ngIf="!rows" [rowsCount]="10"> </app-table-skeleton>

<p-table
  *ngIf="rows"
  [dataKey]="dataKey"
  [editMode]="editMode"
  responsiveLayout="scroll"
  [columns]="columns"
  [value]="rows"
  [paginator]="false"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3rem" *ngIf="expandable"></th>
      <ng-container *ngFor="let col of columns">
        <th [style.width.px]="col.width" [class]="col.headerClass">
          <ng-container *ngIf="!col.headerTemplate">
            {{ col.name }}
          </ng-container>
          <ng-container *ngIf="col.headerTemplate">
            <ng-template [ngTemplateOutlet]="col.headerTemplate"></ng-template>
          </ng-container>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
    let-editing="editing"
    let-index="rowIndex"
    let-expanded="expanded"
  >
    <tr [pEditableRow]="rowData">
      <td *ngIf="expandable">
        <p-button
          [pRowToggler]="rowData"
          [text]="true"
          [plain]="true"
          [rounded]="true"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></p-button>
      </td>
      <td *ngFor="let col of columns" [class]="col.cellClass">
        <ng-container *ngIf="col.isEditableActionsColumn">
          <td style="text-align: center">
            <p-button
              *ngIf="!editing"
              pInitEditableRow
              icon="pi pi-pencil"
              (onClick)="onRowEditInit(rowData)"
              [text]="true"
              [rounded]="true"
            ></p-button>
            <p-button
              *ngIf="editing"
              pSaveEditableRow
              icon="pi pi-check"
              (onClick)="onRowEditSave(rowData)"
              styleClass="mr-2"
              [text]="true"
              [rounded]="true"
            ></p-button>
            <p-button
              *ngIf="editing"
              pCancelEditableRow
              icon="pi pi-times"
              (onClick)="onRowEditCancel(rowData, index)"
              [text]="true"
              [rounded]="true"
            ></p-button>
          </td>
        </ng-container>

        <ng-container *ngIf="!col.isEditableActionsColumn">
          <ng-container *ngIf="col.isEditable">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'bool', rowData.lista)">
                  <p-inputSwitch
                    [(ngModel)]="rowData[col.field]"
                  ></p-inputSwitch>
                </ng-container>
                <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'int', rowData.lista)">
                  <p-inputNumber
                    [(ngModel)]="rowData[col.field]"
                    inputStyleClass="text-right"
                  ></p-inputNumber>
                </ng-container>
                <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'string', rowData.lista)">
                  <input
                    pInputText
                    [(ngModel)]="rowData[col.field]"
                    inputStyleClass=" inputfield w-full"
                  />
                </ng-container>
                <ng-container *ngIf="rowData.lista === true">
                  <ng-template
                    *ngTemplateOutlet="
                      dropdownParametroTemp;
                      context: { disabled: false, rowData, col: col.field }
                    "
                  >
                  </ng-template>
                </ng-container>
              </ng-template>
              <ng-template pTemplate="output">
                <ng-container *ngIf="!col.cellTemplate">
                  <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'bool', rowData.lista)">
                    <p-inputSwitch
                      [(ngModel)]="rowData[col.field]"
                      [readonly]="true"
                    ></p-inputSwitch>
                  </ng-container>
                  <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'int', rowData.lista)">
                    <p-inputNumber
                      [(ngModel)]="rowData[col.field]"
                      [readonly]="true"
                      inputStyleClass="text-right"
                    ></p-inputNumber>
                  </ng-container>
                  <ng-container
                    *ngIf="compararTipoCampo(rowData.tipo, 'string', rowData.lista)"
                  >
                    <input
                      pInputText
                      [(ngModel)]="rowData[col.field]"
                      [readonly]="true"
                      inputStyleClass=" inputfield w-full"
                    />
                  </ng-container>
                  <ng-container *ngIf="rowData.lista === true">
                    <ng-template
                      *ngTemplateOutlet="
                        dropdownParametroTemp;
                        context: { disabled: true, rowData, col: col.field }
                      "
                    >
                    </ng-template>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="col.cellTemplate">
                  <ng-template
                    [ngTemplateOutlet]="col.cellTemplate"
                    [ngTemplateOutletContext]="{
                      value: rowData[col.field],
                      rowIndex: rowIndex,
                      rowData: rowData
                    }"
                  >
                  </ng-template>
                </ng-container>
              </ng-template>
            </p-cellEditor>
          </ng-container>
          <ng-container *ngIf="!col.isEditable">
            <ng-container *ngIf="!col.cellTemplate">
              {{ rowData[col.field] }}
            </ng-container>
            <ng-container *ngIf="col.cellTemplate">
              <ng-template
                [ngTemplateOutlet]="col.cellTemplate"
                [ngTemplateOutletContext]="{
                  value: rowData[col.field],
                  rowIndex: rowIndex,
                  rowData: rowData
                }"
              >
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-rowData>
    <tr>
      <td [attr.colspan]="colspan">
        <ng-container
          *ngTemplateOutlet="rowExpanded; context: { rowData: rowData }"
        ></ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="colspan" class="text-center">
        Nenhum item encontrado
      </td>
    </tr>
  </ng-template>
</p-table>
