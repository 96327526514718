import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Administradora} from '@app/abstraction/domain/entities/financeiro/administradora/administradora.entity';
import {AdministradoraFiltro} from '@app/abstraction/domain/interfaces/financeiro/administradora/administradora-filtro.interface';
import {AdministradoraListagem} from '@app/abstraction/domain/interfaces/financeiro/administradora/administradora-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AdministradoraService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Administradora) {
    const response$ =
        this.client.post<Administradora>(API.FINANCEIRO_ADMINISTRADORA, dados)
            .pipe(
                map((json: Administradora) =>
                        json ? Administradora.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Administradora, id: string) {
    const response$ = this.client
                          .put<Administradora>(
                              `${API.FINANCEIRO_ADMINISTRADORA}/${id}`, dados)
                          .pipe(
                              map((json: Administradora) =>
                                      json ? Administradora.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.FINANCEIRO_ADMINISTRADORA}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client
            .get<Administradora>(`${API.FINANCEIRO_ADMINISTRADORA}/${id}`)
            .pipe(
                map((json: Administradora) =>
                        json ? Administradora.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: AdministradoraFiltro) {
    const response$ =
        this.client.get<AdministradoraListagem[]>(this.geradorQueryString.gerar(
            `${API.FINANCEIRO_ADMINISTRADORA}`, filtro));
    return lastValueFrom(response$);
  }
}
