import {Pipe, PipeTransform} from '@angular/core';
import {FornecedorFacade} from '@app/abstraction/domain/facades/cadastros/fornecedor/fornecedor.facade';
import {FornecedorListagem} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-listagem.interface';

@Pipe({
  name: 'fornecedor',
})
export class FornecedorPipe implements PipeTransform {
  constructor(private fornecedorFacade: FornecedorFacade) {}
  transform(id: string, campo: string = 'nomeFantasia'): any {
    if (id) {
      const fornecedor =
          this.fornecedorFacade.fornecedores?.find(
              (fornecedor: FornecedorListagem) => fornecedor.id == id) as any;
      return fornecedor?.[campo];
    }
    return '';
  }
}
