import {Estados} from '@app/shared/constants/estados.constant';

export class Endereco {
  bairro: string;
  cep: string;
  cidade: string;
  cidadeIbge: number;
  complemento: string;
  logradouro: string;
  numero: string;
  telefone: string;
  uf: string;
  ufIbge: number;

  static from(json: any = {}): Endereco {
    const endereco = new Endereco();
    endereco.bairro = json.bairro;
    endereco.cep = json.cep?.somenteNumeros()?.trim();
    endereco.cidade = json.cidade?.trim();
    endereco.cidadeIbge = json.cidadeIbge;
    endereco.complemento = json.complemento?.trim();
    endereco.logradouro = json.logradouro?.trim();
    endereco.numero = json.numero?.trim();
    endereco.telefone = json.telefone?.trim();
    endereco.uf = json.uf?.trim();
    endereco.ufIbge = json.ufIbge;

    return endereco;
  }

  preencherUfIbge() {
    if (!this.ufIbge && this.uf) {
      this.ufIbge = Estados.find((e) => e.ufSigla === this.uf).ufIbge;
    }
    if (this.ufIbge && !this.uf) {
      this.uf = Estados.find((e) => e.ufIbge === this.ufIbge).ufSigla;
    }

    this.ufIbge = Number(this.ufIbge);
  }
}
