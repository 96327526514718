import {Injectable} from '@angular/core';
import {PlanoContaGruposService} from '@app/abstraction/data/services/financeiro/plano-conta-grupos.service';
import {PlanoContaGruposStore} from '@app/abstraction/data/stores/financeiro/plano-conta-grupos.store';

@Injectable({providedIn: 'root'})
export class PlanoContaGruposFacade {
  get planoContaGrupos() {
    return this.store.planoContaGrupos;
  }
  get planoContaGrupos$() {
    return this.store.planoContaGrupos$;
  }

  constructor(
      private store: PlanoContaGruposStore,
      private service: PlanoContaGruposService) {}
  async buscarPlanoContaGrupos() {
    const planoContaGrupos = await this.service.buscarTodos();
    this.store.adicionarRange(planoContaGrupos);
  }
  limparListaPlanoContaGrupos() {
    this.store.planoContaGrupos = null;
  }
}
