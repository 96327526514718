import {Injectable} from '@angular/core';
import {TabelaNutricionalListagem} from '@app/abstraction/domain/interfaces/produtos/tabela-nutricional/tabela-nutricional-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TabelaNutricionalStore {
  private readonly _tabelasNutricionais =
      new BehaviorSubject<TabelaNutricionalListagem[]>(null);
  get tabelasNutricionais(): TabelaNutricionalListagem[] {
    return this._tabelasNutricionais.getValue();
  }
  set tabelasNutricionais(tabelasNutricionais: TabelaNutricionalListagem[]) {
    this._tabelasNutricionais.next(tabelasNutricionais);
  }
  get tabelasNutricionais$() {
    return this._tabelasNutricionais.asObservable();
  }
  adicionar(tabelaNutricional: TabelaNutricionalListagem) {
    if (!this.tabelasNutricionais) {
      this.tabelasNutricionais = [];
    }
    this.tabelasNutricionais.push(tabelaNutricional);
  }
  adicionarRange(tabelasNutricionais: TabelaNutricionalListagem[]) {
    if (!this.tabelasNutricionais) {
      this.tabelasNutricionais = [];
    }
    tabelasNutricionais.forEach((tabelaNutricional) => {
      if (!this.tabelasNutricionais.some(
              (c) => c.id === tabelaNutricional.id)) {
        this.tabelasNutricionais.push(tabelaNutricional);
      }
    });
  }
}
