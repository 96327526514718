export enum DebitoConvenioConsultaPorData {
  PedidoEfetuado = 1,
  Vencimento,
  Pagamento,
}

export const DebitoConvenioConsultaPorDataLabels = new Map<number, string>([
  [DebitoConvenioConsultaPorData.PedidoEfetuado, 'Data Pedido Efetuado'],
  [DebitoConvenioConsultaPorData.Vencimento, 'Data Vencimento'],
  [DebitoConvenioConsultaPorData.Pagamento, 'Data Pagamento'],
]);
