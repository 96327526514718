export enum Mes {
  Janeiro = 1,
  Fevereiro,
  Marco,
  Abril,
  Maio,
  Junho,
  Julho,
  Agosto,
  Setembro,
  Outubro,
  Novembro,
  Dezembro,
}

export enum MesAbreviado {
  Janeiro = 'Jan.',
  Fevereiro = 'Fev.',
  Março = 'Mar.',
  Abril = 'Abr.',
  Maio = 'Mai.',
  Junho = 'Jun.',
  Julho = 'Jul.',
  Agosto = 'Ago.',
  Setembro = 'Set.',
  Outubro = 'Out.',
  Novembro = 'Nov.',
  Dezembro = 'Dez.',
}

export const MesLabels = new Map<number, string>([
  [Mes.Janeiro, 'Janeiro'],
  [Mes.Fevereiro, 'Fevereiro'],
  [Mes.Marco, 'Março'],
  [Mes.Abril, 'Abril'],
  [Mes.Maio, 'Maio'],
  [Mes.Junho, 'Junho'],
  [Mes.Julho, 'Julho'],
  [Mes.Agosto, 'Agosto'],
  [Mes.Setembro, 'Setembro'],
  [Mes.Outubro, 'Outubro'],
  [Mes.Novembro, 'Novembro'],
  [Mes.Dezembro, 'Dezembro'],
]);
