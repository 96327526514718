import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Plano } from './interfaces/plano.interface';

@Component({
  selector: 'app-tabela-planos',
  templateUrl: './tabela-planos.component.html',
  styleUrls: ['./tabela-planos.component.scss'],
})
export class TabelaPlanosComponent {
  @Input() planos: Plano[];
  @Output() aoAlterar = new EventEmitter<Plano>();

  selecionar(plano: Plano) {
    this.planos.forEach((p) => {
      p.selecionado = false;
    });
    plano.selecionado = true;
    this.aoAlterar.emit(plano);
  }
}
