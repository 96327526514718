export class ProdutoTipoPisCofinsConst {
  constructor(_codigo: string, _descricao: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
  }
  codigo: string;
  descricao: string;
}

export const ProdutoTiposPisCofins = [
  new ProdutoTipoPisCofinsConst('I', 'ISENTO'),
  new ProdutoTipoPisCofinsConst('M', 'MONOFASICO'),
  new ProdutoTipoPisCofinsConst('N', 'NAO INCIDENTE'),
  new ProdutoTipoPisCofinsConst('S', 'SUBSTITUTO'),
  new ProdutoTipoPisCofinsConst('T', 'TRIBUTADO'),
];
