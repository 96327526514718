<ng-template #decimalTemp let-value="value">
  <span>{{ value | customDecimal : valorDecimais }}</span>
</ng-template>
<ng-template #dataTemp let-value="value">
  <span>{{ value | localeDate }}</span>
</ng-template>
<ng-template #situacaoManifestacaoTemp let-value="value">
  <p-tag
    [severity]="buscarCorTagSituacaoNotaFiscal(value)"
    [value]="buscarDescricaoTagSituacaoNotaFiscal(value)"
  ></p-tag>
</ng-template>
<ng-template #situacaoNFeTemp let-value="value" let-rowData="rowData">
  <p-tag
    *ngIf="value !== null"
    styleClass="mr-2"
    [severity]="buscarStyleTagStatusNFe(rowData.situacao)"
    [value]="rowData.situacao | enumLabel : nfeEntradaSituacaoLabels"
  ></p-tag>
</ng-template>
<ng-template #documentoTemp let-value="value">
  <span>{{ value | documento }}</span>
</ng-template>

<p-dialog
  header="Manifestador"
  [style]="{ width: '95%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
    (scrolled)="buscarNotasFiscaisResumosProximaPagina()"
  >
    <div class="formgrid grid mt-1">
      <div class="field col-12 md:col-4">
        <app-text-input-ngmodel
          iconButton="pi pi-search"
          placeholder="Buscar por Chave Eletrônica"
          (modelChange)="filtrarComTimeout()"
          [(model)]="filtro.chaveEletronica"
        ></app-text-input-ngmodel>
      </div>

      <p-button
        styleClass="mr-2 btn-two"
        (onClick)="toggleBuscaAvancada()"
        icon="pi pi-filter-fill"
      ></p-button>
    </div>

    <app-table
      [columns]="colunas"
      [rows]="dados"
      [selectable]="true"
      [selecteds]="itensSelecionados"
      (aoSelecionar)="setarItemSelecionado($event)"
    ></app-table>
  </div>

  <ng-template pTemplate="footer">
    <p-button (click)="fecharModal()" label="Fechar" [text]="true"></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
      [disabled]="!itensSelecionados"
    ></p-button>
  </ng-template>

  <p-sidebar
    [(visible)]="mostrarBuscaAvancada"
    [modal]="false"
    [closeOnEscape]="false"
    styleClass="h-30rem"
    position="bottom"
  >
    <ng-template pTemplate="header">Busca Avançada</ng-template>
    <ng-template pTemplate="content">
      <div class="grid mt-4" style="z-index: 1">
        <div class="col-12 lg:col-8">
          <div class="formgrid grid">
            <div class="field col-6 xl:col-9 lg:col-9 md:col-6 sm:col-6">
              <app-custom-label texto="Empresas"></app-custom-label>
              <app-multiselect-input-ngmodel
                optionLabel="nomeFantasia"
                optionValue="id"
                [options]="empresas"
                [showClear]="true"
                [(model)]="filtro.empresas"
              ></app-multiselect-input-ngmodel>
            </div>
            <div class="field col-6 xl:col-3 lg:col-3 md:col-6 sm:col-6">
              <app-custom-label texto="CPF/CNPJ"></app-custom-label>
              <app-text-input-ngmodel
                textAlign="right"
                [(model)]="filtro.documento"
                [mask]="mascaraDocumento"
              ></app-text-input-ngmodel>
            </div>
            <div class="field col-6 xl:col-4 lg:col-4 md:col-6 sm:col-6">
              <app-custom-label texto="Nome Emitente"></app-custom-label>
              <app-text-input-ngmodel
                [(model)]="filtro.nome"
              ></app-text-input-ngmodel>
            </div>
            <div class="field col-6 xl:col-3 lg:col-3 md:col-6 sm:col-6">
              <app-custom-label texto="Valor (R$)"></app-custom-label>
              <app-numeric-input-ngmodel
                [options]="valorMask"
                [placeholder]="valorPlaceholder"
                [(model)]="filtro.valor"
              ></app-numeric-input-ngmodel>
            </div>
            <div class="field col-6 xl:col-5 lg:col-5 md:col-6 sm:col-6">
              <app-custom-label texto="Situação"></app-custom-label>
              <app-dropdown-input-ngmodel
                [options]="situacaoCodigoManifetacao"
                [showClear]="true"
                [(model)]="filtro.codigoManifestacao"
              ></app-dropdown-input-ngmodel>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-4 bloco-datas">
          <div class="card m-2">
            <div class="formgrid grid">
              <div class="field col-12">
                <app-custom-label texto="Período"></app-custom-label>
                <app-dropdown-input-ngmodel
                  [options]="consultaDataPeriodos"
                  [(model)]="filtro.consultaDataPeriodo"
                  (modelChange)="setarDatas($event)"
                ></app-dropdown-input-ngmodel>
              </div>
              <div class="field col-6">
                <app-custom-label texto="Data Início"></app-custom-label>
                <app-calendar-input-ngmodel
                  [showIcon]="liberarDatas"
                  [readonly]="!liberarDatas"
                  [(model)]="filtro.dataInicio"
                  (modelChange)="setarValorDataInicio($event)"
                ></app-calendar-input-ngmodel>
              </div>
              <div class="field col-6">
                <app-custom-label texto="Data Fim"></app-custom-label>
                <app-calendar-input-ngmodel
                  [showIcon]="liberarDatas"
                  [readonly]="!liberarDatas"
                  [(model)]="filtro.dataFim"
                  (modelChange)="setarValorDataFim($event)"
                ></app-calendar-input-ngmodel>
              </div>
              <div class="field col-12">
                <app-custom-label texto="Filtrar por data:"></app-custom-label>
                <app-dropdown-input-ngmodel
                  [options]="nfeConsultaPorData"
                  [(model)]="filtro.consultaPorData"
                ></app-dropdown-input-ngmodel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="grid">
        <div class="col-12 text-right">
          <p-button styleClass="m-1 btn-two" (onClick)="limparCampos()">
            Limpar campos
          </p-button>
          <p-button styleClass="m-1 btn-one" (onClick)="buscarComFiltro()">
            Buscar
          </p-button>
        </div>
      </div>
    </ng-template>
  </p-sidebar>
</p-dialog>

<ng-template #situacaoTemp let-value="value">
  <p-tag
    *ngIf="value"
    styleClass="mr-2"
    severity="success"
    value="Ativo"
  ></p-tag>
  <p-tag
    *ngIf="!value"
    styleClass="mr-2"
    severity="danger"
    value="Inativo"
  ></p-tag>
</ng-template>
