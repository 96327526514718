<div class="formgrid grid mt-1">
  <div class="field col-12">
    <p-button
      label="Imprimir Pedido"
      pTooltip="Imprimir Pedido"
      tooltipPosition="bottom"
      icon="pi pi-print"
      styleClass="btn-five w-full"
      (onClick)="imprimir()"
    ></p-button>
  </div>
  <!-- <div class="field col-12 md:col-6">
    <p-button
      label="Enviar por E-mail"
      pTooltip="Enviar por E-mail"
      tooltipPosition="bottom"
      icon="pi pi-envelope"
      styleClass="btn-five w-full"
      (onClick)="enviarPorEmail()"
    ></p-button>
  </div> -->
</div>
