import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Banco} from '@app/abstraction/domain/entities/financeiro/banco/banco.entity';
import {ContaBancariaBoletoCarteira} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria-boleto-carteira.entity';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {BancoFacade} from '@app/abstraction/domain/facades/financeiro/banco/banco.facade';
import {CarteiraFacade} from '@app/abstraction/domain/facades/financeiro/carteira/carteira.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ContaBancariaForm, IContaBancariaBoletoCarteiraForm,} from '@app/abstraction/domain/forms/financeiro/conta-bancaria.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-conta-bancaria-shared-form',
  templateUrl: './conta-bancaria-shared-form.component.html',
  styleUrls: ['./conta-bancaria-shared-form.component.scss'],
})
export class ContaBancariaSharedFormComponent extends FormBaseComponent {
  contaBancariaForm?: ContaBancariaForm;
  get bancos$() {
    return this.bancoFacade.bancos$;
  }
  get contaBancariaBoletoForm() {
    return this.contaBancariaForm?.contaBancariaBoletoForm;
  }
  get contasBoletoCarteira() {
    return this.contaBancariaForm?.contasBoletoCarteira;
  }
  get emiteBoleto() {
    return this.contaBancariaForm?.emiteBoleto;
  }
  get bancoEmiteBoleto() {
    return this.contaBancariaForm?.bancoEmiteBoleto;
  }
  get empresas() {
    return this.authFacade.grupoLogado.empresas;
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get valorPlaceholder() {
    return this.mascaraService.placeholder(this.valorDecimais);
  }
  get porcentagemMask() {
    return this.mascaraService.decimal(this.porcentagemDecimais);
  }
  get porcentagemDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposPorcentagem;
  }
  get porcentagemPlaceholder() {
    return this.mascaraService.placeholder(this.porcentagemDecimais);
  }
  get carteiras() {
    return this.contaBancariaForm?.carteiras;
  }
  get inteiroMask() {
    return this.mascaraService.inteiro();
  }
  constructor(
      private carteiraFacade: CarteiraFacade, private bancoFacade: BancoFacade,
      private authFacade: AuthFacade, private empresaFacade: EmpresaFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade, toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  criarForm() {
    this.contaBancariaForm = new ContaBancariaForm(
        this.carteiraFacade, this.bancoFacade, this.authFacade,
        this.empresaFacade, this.errorMessagesFacade, this.toastrService);
    this.contaBancariaForm?.criarForm();
    this.contaBancariaForm?.monitorarFormulario();
    this.contaBancariaForm?.monitorarBanco();
    this.contaBancariaForm?.setarTipoFormulario(false);
  }
  preencherObjeto() {
    return ContaBancaria.from(this.contaBancariaForm?.form.value);
  }
  abrirBancoCadastroRapidoModal() {
    this.contaBancariaForm?.abrirBancoCadastroRapidoModal();
  }
  abrirContaBancariaBoletoCarteiraModal(
      contaBoletoCarteira?: ContaBancariaBoletoCarteira, index?: number) {
    this.contaBancariaForm?.abrirContaBancariaBoletoCarteiraModal(
        contaBoletoCarteira, index);
  }
  aplicarValidacaoBoleto(value: any) {
    this.contaBancariaForm?.aplicarValidacaoBoleto(value.checked);
  }
  fecharBancoCadastroRapidoModal() {
    this.contaBancariaForm?.fecharBancoCadastroRapidoModal();
  }
  fecharContaBancariaBoletoCarteiraModal() {
    this.contaBancariaForm?.fecharContaBancariaBoletoCarteiraModal();
  }
  removerContaBancariaBoletoCarteiraModal(index: number) {
    this.confirmacaoService.confirm({
      key: 'confirmacao-form',
      message: 'Deseja realmente remover essa carteira ?',
      header: 'Atenção',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.contaBancariaForm?.removerContaBancariaBoletoCarteiraModal(index);
      },
    });
  }
  salvarContaBancariaBoletoCarteira(
      form: FormGroup<IContaBancariaBoletoCarteiraForm>) {
    this.contaBancariaForm?.salvarContaBancariaBoletoCarteira(form);
  }
  setarBancoCadastrado(banco: Banco) {
    this.contaBancariaForm?.setarBancoCadastrado(banco.codigo);
  }
}
