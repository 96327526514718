import { Contato } from '@app/abstraction/domain/entities/shared/contato.entity';
import { Endereco } from '@app/abstraction/domain/entities/shared/endereco.entity';
import { Estados } from '@app/shared/constants/estados.constant';
import { AtividadeTipo } from '@app/shared/enums/atividade-tipo.enum';
import { IeIndicador } from '@app/shared/enums/ie-indicador.enum';
import { PessoaTipo } from '@app/shared/enums/pessoa-tipo.enum';
import { RegimeTributario } from '@app/shared/enums/regimes-tributarios.enum';

export class Pessoa {
  atividadeTipo: AtividadeTipo;
  contatos: Contato[];
  documento: string;
  enderecos: Endereco[];
  ieIndicador: IeIndicador;
  im: string;
  nascimento: Date | string;
  abertura: Date | string;
  nomeFantasia: string;
  nomeRazaoSocial: string;
  id: string;
  pessoaTipo: PessoaTipo;
  regimeTributario: RegimeTributario;
  rgIe: string;
  planoConta3Id: string;
  whatsApp: string;
  telefoneFixo: string;
  emailGeral: string;
  vendedorPadrao: string;
  emailFaturamento: string;

  get contato() {
    if (this.contatos?.length <= 0) return null;
    return Contato.from(this.contatos?.find((p: Contato) => p.principal));
  }
  get endereco() {
    if (this.enderecos?.length <= 0) return null;
    return Endereco.from(this.enderecos?.find((p: Endereco) => p.principal));
  }
  protected preencherUfEnderecos() {
    this.enderecos.forEach((endereco) => {
      endereco.uf = Estados.find(
        (estado) => estado?.ufIbge === endereco?.ufIbge
      )?.ufSigla;
    });
  }
  static from(json: any = {}): Pessoa {
    const pessoa = new Pessoa();
    pessoa.atividadeTipo = json.atividadeTipo;
    pessoa.documento = json.documento?.somenteNumeros()?.trim();
    pessoa.ieIndicador = json.ieIndicador;
    pessoa.im = json.im?.trim();
    pessoa.nascimento = json.nascimento;
    pessoa.abertura = json.abertura;
    pessoa.nomeFantasia = json.nomeFantasia?.trim();
    pessoa.nomeRazaoSocial = json.nomeRazaoSocial?.trim();
    pessoa.id = json.id;
    pessoa.pessoaTipo = json.pessoaTipo;
    pessoa.regimeTributario = json.regimeTributario;
    pessoa.rgIe = json.rgIe?.trim();
    pessoa.planoConta3Id = json.planoConta3Id;
    pessoa.whatsApp = json.whatsApp;
    pessoa.telefoneFixo = json.telefoneFixo;
    pessoa.emailGeral = json.emailGeral;
    pessoa.vendedorPadrao = json.vendedorPadrao;
    pessoa.emailFaturamento = json.emailFaturamento;
    pessoa.contatos = json.contatos?.map((c: Contato) => Contato.from(c)) ?? [];
    pessoa.enderecos =
      json.enderecos?.map((e: Endereco) => Endereco.from(e)) ?? [];

    return pessoa;
  }
  pessoaFrom(json: any = {}) {
    this.atividadeTipo = json.atividadeTipo;
    this.documento = json.documento?.somenteNumeros()?.trim();
    this.ieIndicador = json.ieIndicador;
    this.im = json.im?.trim();
    this.nascimento = json.nascimento;
    this.abertura = json.abertura;
    this.nomeFantasia =
      json.nomeFantasia?.length > 0
        ? json.nomeFantasia?.trim()
        : json.nomeRazaoSocial?.trim();
    this.nomeRazaoSocial = json.nomeRazaoSocial?.trim();
    this.id = json.id;
    this.pessoaTipo = json.pessoaTipo;
    this.regimeTributario = json.regimeTributario;
    this.rgIe = json.rgIe?.trim();
    this.planoConta3Id = json.planoConta3Id;
    this.whatsApp = json.whatsApp;
    this.telefoneFixo = json.telefoneFixo;
    this.emailGeral = json.emailGeral;
    this.vendedorPadrao = json.vendedorPadrao;
    this.emailFaturamento = json.emailFaturamento;
    this.contatos = json.contatos?.map((c: Contato) => Contato.from(c)) ?? [];
    this.enderecos =
      json.enderecos?.map((e: Endereco) => Endereco.from(e)) ?? [];
  }
}
