import {AbstractControl, FormArray} from '@angular/forms';

declare module '@angular/forms' {
  interface FormArray {
    removeWhere(
        predicate: (control: AbstractControl, index?: number) => boolean): void;
  }
}

FormArray.prototype.removeWhere = function(
    predicate: (control: AbstractControl, index?: number) => boolean): void {
  let i = this.controls.length;
  while (i--) {
    if (predicate(this.controls[i], i)) {
      this.removeAt(i);
    }
  }
};