import {Injectable} from '@angular/core';
import {SecaoListagem} from '@app/abstraction/domain/interfaces/produtos/secao/secao-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SecaoStore {
  private readonly _secoes = new BehaviorSubject<SecaoListagem[]>(null);

  get secoes(): SecaoListagem[] {
    return this._secoes.getValue();
  }
  set secoes(secoes: SecaoListagem[]) {
    this._secoes.next(secoes);
  }
  get secoes$() {
    return this._secoes.asObservable();
  }
  adicionar(secao: SecaoListagem) {
    if (!this.secoes) {
      this.secoes = [];
    }
    this.secoes.push(secao);
  }
  adicionarRange(secoes: SecaoListagem[]) {
    if (!this.secoes) {
      this.secoes = [];
    }
    secoes.forEach((secao) => {
      if (!this.secoes.some((c) => c.id === secao.id)) {
        this.secoes.push(secao);
      }
    });
  }
}
