import {Pipe, PipeTransform} from '@angular/core';
import {FamiliaFacade} from '@app/abstraction/domain/facades/produtos/familia/familia.facade';

@Pipe({
  name: 'familia',
})
export class FamiliaPipe implements PipeTransform {
  constructor(private familiaFacade: FamiliaFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const familia = this.familiaFacade.familias.find(
                          (familia) => familia.id == id) as any;
      return familia?.[campo];
    }
    return '';
  }
}
