import {Injectable} from '@angular/core';
import {EmpresaService} from '@app/abstraction/data/services/cadastros/empresa.service';
import {EmpresaStore} from '@app/abstraction/data/stores/cadastros/empresa.store';
import {ChaveIntegracao} from '@app/abstraction/domain/entities/cadastros/chave-integracao/chave-integracao.entity';
import {Empresa} from '@app/abstraction/domain/entities/cadastros/empresa/empresa.entity';
import {EmpresaFiltro} from '@app/abstraction/domain/interfaces/cadastros/empresa/empresa-filtro.interface';

@Injectable({providedIn: 'root'})
export class EmpresaFacade {
  get empresas() {
    return this.store.empresas;
  }
  get empresas$() {
    return this.store.empresas$;
  }
  get chavesIntegracao() {
    return this.store.chavesIntegracao;
  }
  constructor(
      private service: EmpresaService,
      private store: EmpresaStore,
  ) {}
  async buscarEmpresas(filtro?: EmpresaFiltro) {
    const empresas = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(empresas);
  }
  async buscarEmpresasUsuarioLogado(matrizId: string) {
    const empresas = await this.service.buscarEmpresasUsuarioLogado(matrizId);
    this.store.adicionarEmpresasUsuarioLogadoRange(empresas);
    return empresas;
  }
  async buscarChavesIntegracao() {
    const chaves = await this.service.buscarChavesIntegracao();
    this.store.adicionarChavesIntegracaoRange(chaves);
  }
  adicionar(empresa: Empresa) {
    return this.service.adicionar(empresa);
  }
  alterar(empresa: Empresa, chave: string) {
    return this.service.alterar(empresa, chave);
  }
  buscarPorId(chave: string) {
    return this.service.buscarPorId(chave);
  }
  buscarPorIdNoManager(chave: string) {
    return this.service.buscarPorIdNoManager(chave);
  }
  verificarDisponibilidade(documento: string) {
    return this.service.verificarDisponibilidadeManager(documento);
  }
  buscarPorDocumentoNoManager(documento: string) {
    return this.service.buscarPorDocumentoNoManager(documento);
  }
  limparListaEmpresas() {
    this.store.empresas = null;
  }
  limparListaEmpresasUsuarioLogado() {
    this.store.empresasUsuarioLogado = null;
  }
  limparListaChavesIntegracao() {
    this.store.chavesIntegracao = null;
  }
  adicionarChaveIntegracao(chaveIntegracao: ChaveIntegracao) {
    return this.service.adicionarChaveIntegracao(chaveIntegracao);
  }
  removerChaveIntegracao(id: string) {
    return this.service.removerChaveIntegracao(id);
  }
}
