import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VersaoListagem } from '@app/abstraction/domain/interfaces/configuracoes/versao/versao-listagem.interface';
import { API } from '@app/shared/constants/api.constant';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersaoService {
  constructor(private client: HttpClient) {}
  buscarTodas() {
    const response$ = this.client.get<VersaoListagem[]>(`${API.VERSAO}`);
    return lastValueFrom(response$);
  }

  buscarUltimaVersao() {
    const response$ = this.client.get<VersaoListagem>(
      `${API.VERSAO}/ultima-versao`
    );
    return lastValueFrom(response$);
  }
}
