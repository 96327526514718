<p-dialog
  header="Lista de pessoas"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <div class="grid">
    <div class="col">
      <p-button
        type="button"
        styleClass="btn-five"
        label="Nova Pessoa"
        tooltipPosition="bottom"
        pTooltip="Nova Pessoa"
        icon="pi pi-plus"
        (click)="abrirTipoPessoaCadastroModal()"
      ></p-button>
    </div>
  </div>
  <div
    class="infinit-scroll-dialog"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [scrollWindow]="false"
    [infiniteScrollThrottle]="0"
    (scrolled)="buscarPessoasProximaPagina()"
  >
    <div class="formgrid grid mt-1">
      <div class="field col-12 md:col-6">
        <app-text-input-ngmodel
          placeholder="Buscar por nome/razão social"
          (inputKeydown)="filtrarComTimeout()"
          [(model)]="filtro.nome"
        ></app-text-input-ngmodel>
      </div>
    </div>
    <app-table
      (aoSelecionar)="setarItemSelecionado($event)"
      [columns]="colunas"
      [rows]="dados$ | async"
      [selectable]="true"
      selectionMode="single"
      [selecteds]="itemSelecionado"
    >
    </app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
</p-dialog>
<ng-template #documentoTemp let-value="value">
  <span>{{ value | documento }}</span>
</ng-template>
<ng-template #situacaoTemp let-value="value">
  <p-tag
    *ngIf="value"
    styleClass="mr-2"
    severity="success"
    value="Ativo"
  ></p-tag>
  <p-tag
    *ngIf="!value"
    styleClass="mr-2"
    severity="danger"
    value="Inativo"
  ></p-tag>
</ng-template>

<app-cliente-cadastro-rapido-modal
  [mostrarModal]="mostrarClienteCadastroRapido"
  (aoSalvar)="setarPessoaCadastrada($event)"
  (aoCancelar)="fecharClienteCadastroRapidoModal()"
></app-cliente-cadastro-rapido-modal>

<app-fornecedor-cadastro-rapido-modal
  [mostrarModal]="mostrarFornecedorCadastroRapido"
  (aoSalvar)="setarPessoaCadastrada($event)"
  (aoCancelar)="fecharFornecedorCadastroRapidoModal()"
></app-fornecedor-cadastro-rapido-modal>

<app-seletor-tipo-pessoa-cadastro
  [mostrarModal]="mostrarTipoPessoaCadastroModal"
  (aoSelecionar)="abrirCadastroPessoaSelecionada($event)"
  (aoFechar)="fecharTipoPessoaCadastroModal()"
></app-seletor-tipo-pessoa-cadastro>
