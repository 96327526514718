import {FormArray, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {ContaBancariaBoletoCarteira} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria-boleto-carteira.entity';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {BancoFacade} from '@app/abstraction/domain/facades/financeiro/banco/banco.facade';
import {CarteiraFacade} from '@app/abstraction/domain/facades/financeiro/carteira/carteira.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {Cnab} from '@app/shared/enums/cnab.enum';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';
import {ToastrService} from 'ngx-toastr';

export interface IContaBancariaForm {
  id: FormControl<string>;
  empresaId: FormControl<string>;
  nome: FormControl<string>;
  banco: FormControl<string>;
  agencia: FormControl<string>;
  digitoAgencia: FormControl<string>;
  conta: FormControl<string>;
  digitoConta: FormControl<string>;
  ativo: FormControl<boolean>;
  emiteBoleto: FormControl<boolean>;
  saldo: FormControl<number>;
  contaBancariaBoleto: FormGroup<IContaBancariaBoletoForm>;
}
export interface IContaBancariaBoletoForm {
  nossoNumeroInicial: FormControl<number>;
  codigoTransmissao: FormControl<string>;
  jurosMensais: FormControl<number>;
  multa: FormControl<number>;
  descontoVencimento: FormControl<number>;
  comprovanteRecebimento: FormControl<boolean>;
  instrucaoPagamentoVencimento: FormControl<string>;
  bancoProtesto: FormControl<boolean>;
  bancoDevolucao: FormControl<boolean>;
  contaBancariaBoletoCarteira:
      FormArray<FormGroup<IContaBancariaBoletoCarteiraForm>>;
}
export interface IContaBancariaBoletoCarteiraForm {
  convenio: FormControl<string>;
  digitoConvenio: FormControl<string>;
  variacaoCarteira: FormControl<string>;
  taxa: FormControl<number>;
  somarTaxa: FormControl<boolean>;
  padrao: FormControl<boolean>;
  codigoCarteira: FormControl<string>;
  cnab: FormControl<Cnab>;
}
export class ContaBancariaBoletoCarteiraForm {
  static criarForm(contaBoletoCarteira?: ContaBancariaBoletoCarteira) {
    return new FormGroup<IContaBancariaBoletoCarteiraForm>({
      convenio: new FormControl(
          contaBoletoCarteira?.convenio,
          [
            CustomValidators.required('Convênio é obrigatório'),
          ]),
      taxa: new FormControl(contaBoletoCarteira?.taxa ?? 0),
      somarTaxa: new FormControl(contaBoletoCarteira?.somarTaxa ?? false),
      padrao: new FormControl(contaBoletoCarteira?.padrao ?? false),
      codigoCarteira: new FormControl(
          contaBoletoCarteira?.codigoCarteira,
          [
            CustomValidators.required('Código da carteira é obrigatório'),
            CustomValidators.maxLength(
                20, 'Código da carteira tem limite de 20 dígitos'),
          ]),
      digitoConvenio: new FormControl(
          contaBoletoCarteira?.digitoConvenio,
          CustomValidators.maxLength(
              2, 'Dígito do convênio tem limite de 2 dígitos')),
      variacaoCarteira: new FormControl(
          contaBoletoCarteira?.variacaoCarteira,
          CustomValidators.maxLength(
              10, 'Variação carteira tem limite de 10 dígitos')),
      cnab: new FormControl(
          contaBoletoCarteira?.cnab,
          CustomValidators.required('Cnab é obrigatório')),
    });
  }
}
export class ContaBancariaForm extends BaseForm {
  form: FormGroup<IContaBancariaForm>;
  contaBoletoCarteiraForm: FormGroup<IContaBancariaBoletoCarteiraForm>;
  operacaoForm: OperacaoForm;
  contaBancariaBoletoCarteiraModalTitulo: string;
  mostrarBancoCadastroRapidoModal: boolean;
  mostrarContaBancariaBoletoCarteiraModal: boolean;
  operacaoFormBoletoCarteira: OperacaoForm;
  contaBoletoCarteiraEditada: any;
  bancoEmiteBoleto: boolean;
  get contaBancariaBoletoForm() {
    return this.form.controls.contaBancariaBoleto;
  }
  get contasBoletoCarteira() {
    return this.contaBancariaBoletoForm?.controls.contaBancariaBoletoCarteira
               .value as ContaBancariaBoletoCarteira[];
  }
  get contaBoletoCarteiraFormArray() {
    return this.contaBancariaBoletoForm?.controls.contaBancariaBoletoCarteira;
  }
  get emiteBoleto() {
    return this.form.controls?.emiteBoleto?.value;
  }
  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova conta bancária' :
                                        'Editar conta bancária';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  get carteiras() {
    return this.carteiraFacade?.carteiras?.filter(
        (x) => x.numeroBanco == this.form.controls?.banco?.value);
  }
  constructor(
      private carteiraFacade: CarteiraFacade, private bancoFacade?: BancoFacade,
      private authFacade?: AuthFacade, private empresaFacade?: EmpresaFacade,
      private errorMessagesFacade?: ErrorMessageFacade,
      private toastrService?: ToastrService) {
    super();
  }
  private adicionarContaBoletoCarteiraFormGroup(
      contaBoletoCarteira?: ContaBancariaBoletoCarteira) {
    this.contaBoletoCarteiraFormArray.push(
        this.criarContaBoletoCarteiraFormGroup(contaBoletoCarteira));
  }
  private criarContaBoletoCarteiraFormGroup(
      contaBoletoCarteira?: ContaBancariaBoletoCarteira, index?: number) {
    if (index >= 0) {
      this.operacaoFormBoletoCarteira = OperacaoForm.Editar;
      this.contaBoletoCarteiraEditada = {
        index: index,
        control: this.contaBoletoCarteiraFormArray.controls[index],
      };
    }
    let formBoletoCarteira =
        ContaBancariaBoletoCarteiraForm.criarForm(contaBoletoCarteira);

    this.monitorarCodigoCarteira(formBoletoCarteira);

    return formBoletoCarteira;
  }
  private preencherForm(contaBancaria: ContaBancaria) {
    let contaBoletoCarteira =
        contaBancaria?.contaBancariaBoleto?.contaBancariaBoletoCarteira;

    if (contaBoletoCarteira) {
      this.contaBoletoCarteiraFormArray.clear();
      contaBoletoCarteira.orderByDescending((p) => p.padrao);
      contaBoletoCarteira.forEach((c) => {
        this.adicionarContaBoletoCarteiraFormGroup(c);
      });
    }

    this.form.patchValue(contaBancaria);
  }
  private setarPrimeiroBanco() {
    this.form.controls.banco.setValue(this.bancoFacade.bancos?.first().codigo);
  }
  private setarEmpresaSelecionada() {
    let empresaId = this.empresaFacade?.empresas
                        ?.find((p) => p.id == this.authFacade.empresaLogada.id)
                        ?.id;
    this.form.controls.empresaId.setValue(empresaId);
  }
  abrirBancoCadastroRapidoModal() {
    this.mostrarBancoCadastroRapidoModal = true;
  }
  abrirContaBancariaBoletoCarteiraModal(
      contaBoletoCarteira?: ContaBancariaBoletoCarteira, index?: number) {
    this.contaBoletoCarteiraForm =
        this.criarContaBoletoCarteiraFormGroup(contaBoletoCarteira, index);
    if (contaBoletoCarteira) {
      this.contaBancariaBoletoCarteiraModalTitulo = 'Editar Carteira';
      this.operacaoFormBoletoCarteira = OperacaoForm.Editar;
    } else {
      this.contaBancariaBoletoCarteiraModalTitulo = 'Nova Carteira';
      this.operacaoFormBoletoCarteira = OperacaoForm.Adicionar;
    }
    this.mostrarContaBancariaBoletoCarteiraModal = true;
  }
  adicionarValidacaoBoleto() {
    let codigoTransmissao = <ValidatorFn[]>[
      CustomValidators.maxLength(
          20, 'Código de transmissão tem um máximo de 20 caracteres'),
    ];
    let instrucaoPagamentoVencimento = <ValidatorFn[]>[
      CustomValidators.maxLength(
          200, 'Instrução de vencimento tem limite de 200 caracteres'),
    ];
    let contaBancariaBoletoCarteira = <ValidatorFn[]>[
      CustomValidators.minLengthArray(
          1, 'É necessário cadastrar ao menos uma carteira'),
    ];

    super.adicionarValidator(
        'codigoTransmissao', codigoTransmissao, this.contaBancariaBoletoForm);
    super.adicionarValidator(
        'instrucaoPagamentoVencimento', instrucaoPagamentoVencimento,
        this.contaBancariaBoletoForm);
    super.adicionarValidator(
        'contaBancariaBoletoCarteira', contaBancariaBoletoCarteira,
        this.contaBancariaBoletoForm);
  }
  aplicarValidacaoBoleto(value: boolean) {
    if (value)
      this.adicionarValidacaoBoleto();
    else
      this.removerValidacaoBoleto();
  }
  criarForm() {
    this.form = new FormGroup<IContaBancariaForm>({
      id: new FormControl(null),
      empresaId: new FormControl(
          null, CustomValidators.required('Empresa é obrigatória')),
      nome: new FormControl(
          null,
          [
            CustomValidators.required('Nome é obrigatório'),
            CustomValidators.maxLength(150, 'Tamanho máximo: 150 caracteres'),
          ]),
      banco: new FormControl(
          null,
          [
            CustomValidators.required('Banco é obrigatório'),
            CustomValidators.maxLength(5, 'Tamanho máximo: 5 caracteres'),
          ]),
      agencia: new FormControl(
          null,
          [
            CustomValidators.required('Agência é obrigatória'),
            CustomValidators.maxLength(4, 'Tamanho máximo: 4 caracteres'),
          ]),
      digitoAgencia: new FormControl(
          null,
          [
            CustomValidators.required('Dígito da agência é obrigatório'),
            CustomValidators.maxLength(2, 'Tamanho máximo: 2 caracteres'),
          ]),
      conta: new FormControl(
          null,
          [
            CustomValidators.required('Conta é obrigatória'),
            CustomValidators.maxLength(8, 'Tamanho máximo: 8 caracteres'),
          ]),
      digitoConta: new FormControl(
          null,
          [
            CustomValidators.required('Dígito da conta é obrigatório'),
            CustomValidators.maxLength(2, 'Tamanho máximo: 2 caracteres'),
          ]),
      saldo: new FormControl(
          0,
          [
            CustomValidators.required(
                'É necessário informar o saldo inicial da conta!'),
          ]),
      ativo: new FormControl(true),
      emiteBoleto: new FormControl(false),
      contaBancariaBoleto: new FormGroup<IContaBancariaBoletoForm>({
        nossoNumeroInicial: new FormControl(null),
        codigoTransmissao: new FormControl(null),
        jurosMensais: new FormControl(0),
        multa: new FormControl(0),
        descontoVencimento: new FormControl(0),
        instrucaoPagamentoVencimento: new FormControl(null),
        comprovanteRecebimento: new FormControl(false),
        bancoProtesto: new FormControl(false),
        bancoDevolucao: new FormControl(false),
        contaBancariaBoletoCarteira: new FormArray([]),
      }),
    });
    return this.form;
  }
  fecharBancoCadastroRapidoModal() {
    this.mostrarBancoCadastroRapidoModal = false;
  }
  fecharContaBancariaBoletoCarteiraModal() {
    this.mostrarContaBancariaBoletoCarteiraModal = false;
    this.contaBoletoCarteiraEditada = null;
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  monitorarBanco() {
    this.form.controls.banco.valueChanges.subscribe((novoValor) => {
      let banco = this.bancoFacade.bancos?.find((p) => p.codigo === novoValor);
      this.bancoEmiteBoleto = banco.emiteBoleto;

      if (!banco.emiteBoleto) {
        this.form.controls.emiteBoleto.setValue(false);
      }
    });
  }
  monitorarCodigoCarteira(formBoletoCarteira:
                              FormGroup<IContaBancariaBoletoCarteiraForm>) {
    formBoletoCarteira?.controls?.codigoCarteira?.valueChanges.subscribe(
        (novoValor) => {
          if (novoValor) {
            var carteira = this.carteiras.filter((p) => p.codigo == novoValor);

            if (carteira?.length == 1 && carteira?.first()?.variacao) {
              formBoletoCarteira.controls.variacaoCarteira.setValue(
                  carteira.first().variacao);
            } else {
              formBoletoCarteira.controls.variacaoCarteira.setValue('');
            }
          }
        });
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  removerContaBancariaBoletoCarteiraModal(index: number) {
    this.contaBoletoCarteiraFormArray.removeAt(index);

    if (this.contaBoletoCarteiraFormArray?.length >= 1 &&
        !this.contaBoletoCarteiraFormArray.value.some((p) => p.padrao)) {
      const contaBoletoCarteira = ContaBancariaBoletoCarteira.from(
          this.contaBoletoCarteiraFormArray?.value[0]);

      contaBoletoCarteira.padrao = true;
      this.toastrService.success(
          'Carteira definida como padrão automaticamente');

      this.contaBoletoCarteiraFormArray.removeAt(0);
      this.contaBoletoCarteiraFormArray.insert(
          0, this.criarContaBoletoCarteiraFormGroup(contaBoletoCarteira));
    }
  }
  removerValidacaoBoleto() {
    super.removerValidator('nossoNumeroInicial', this.contaBancariaBoletoForm);
    super.removerValidator('codigoTransmissao', this.contaBancariaBoletoForm);
    super.removerValidator('jurosMensais', this.contaBancariaBoletoForm);
    super.removerValidator('multa', this.contaBancariaBoletoForm);
    super.removerValidator('descontoVencimento', this.contaBancariaBoletoForm);
    super.removerValidator(
        'instrucaoPagamentoVencimento', this.contaBancariaBoletoForm);
    super.removerValidator(
        'contaBancariaBoletoCarteira', this.contaBancariaBoletoForm);
  }
  salvarContaBancariaBoletoCarteira(
      form: FormGroup<IContaBancariaBoletoCarteiraForm>) {
    if (form.valid) {
      const contaBoletoCarteira = ContaBancariaBoletoCarteira.from(form.value);

      if (!this.contaBoletoCarteiraFormArray.value.some((p) => p.padrao)) {
        if (!contaBoletoCarteira.padrao)
          this.toastrService.success(
              'Carteira definida como padrão automaticamente');
        contaBoletoCarteira.padrao = true;
      } else if (
          this.contaBoletoCarteiraFormArray.length > 0 &&
          contaBoletoCarteira.padrao) {
        let index =
            this.contaBoletoCarteiraFormArray.value.findIndex((p) => p.padrao);
        let contaBoletoCarteiraPadrao = ContaBancariaBoletoCarteira.from(
            this.contaBoletoCarteiraFormArray?.value[index]);

        contaBoletoCarteiraPadrao.padrao = false;

        this.contaBoletoCarteiraFormArray.removeAt(index);
        this.contaBoletoCarteiraFormArray.insert(
            index,
            this.criarContaBoletoCarteiraFormGroup(contaBoletoCarteiraPadrao));
      }

      if (this.operacaoFormBoletoCarteira === OperacaoForm.Adicionar) {
        this.adicionarContaBoletoCarteiraFormGroup(contaBoletoCarteira);
      } else {
        const index = this.contaBoletoCarteiraEditada.index;
        this.contaBoletoCarteiraFormArray.removeAt(index);

        if (!this.contaBoletoCarteiraFormArray.value.some((p) => p.padrao) &&
            !contaBoletoCarteira.padrao) {
          contaBoletoCarteira.padrao = true;
          this.toastrService.success(
              'Carteira definida como padrão automaticamente');
        }

        this.contaBoletoCarteiraFormArray.insert(
            index, this.criarContaBoletoCarteiraFormGroup(contaBoletoCarteira));
      }
      this.fecharContaBancariaBoletoCarteiraModal();
    } else {
      this.errorMessagesFacade.mostrarCamposInvalidos(form);
    }
  }
  setarBancoCadastrado(codigo: string) {
    this.form.controls.banco.setValue(codigo);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(ContaBancaria.from(value));
      this.aplicarValidacaoBoleto(value.emiteBoleto);
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
      this.setarPrimeiroBanco();
      this.setarEmpresaSelecionada();
    }
  }
}
