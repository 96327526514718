<ng-container *ngIf="control">
  <div class="p-inputgroup">
    <input
      #contentInput
      type="text"
      class="inputfield w-full"
      pInputText
      appAutoFocus
      appUppercase
      appFoneMask
      [upperCase]="upperCase"
      [foneMask]="foneMask"
      [onlyCellPhoneMask]="onlyCellPhoneMask"
      [onlyPhoneMask]="onlyPhoneMask"
      [placeholder]="placeholder"
      [autofocus]="autoFocus"
      [formControl]="control"
      [mask]="mask"
      [autocomplete]="autocomplete"
      [validation]="false"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [ngClass]="{
        readonly: readonly,
        'ng-invalid ng-dirty': hasErrors,
        'text-right': textAlign === 'right',
        'text-center': textAlign === 'center',
        'text-left': textAlign === 'left',
        'w-full': fullWidth
      }"
      (blur)="onBlur($event)"
      (keydown)="onKeyDown($event)"
    />
    <p-button
      *ngIf="showButton"
      type="button"
      styleClass="btn-five btn-input-text-icon"
      [icon]="iconButton"
      (onClick)="onClick()"
      [disabled]="disabledButton"
      [pTooltip]="buttonTooltipText"
      [tooltipPosition]="buttonTooltipPosition"
    >
    </p-button>
    <span
      *ngIf="showColorPicker"
      class="p-inputgroup-addon cursor-pointer"
      [colorPicker]
      [cpOutputFormat]="cpOutputFormat"
      [style.background]="control.value"
      [cpPositionOffset]="cpPositionOffset"
      [cpPositionRelativeToArrow]="cpPositionRelativeToArrow"
      (colorPickerChange)="onColorPickerChange($event)"
    ></span>
  </div>
  <small
    style="float: right"
    *ngIf="maxlength"
    [ngClass]="{
      'p-error': hasErrors
    }"
    >{{ controlLength ?? 0 }}/{{ contador.toString() }}
  </small>
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</ng-container>
