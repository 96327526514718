import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {Produto} from '@app/abstraction/domain/entities/produtos/produto/produto.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {ProdutoFacade} from '@app/abstraction/domain/facades/produtos/produto/produto.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ProdutoFiltro} from '@app/abstraction/domain/interfaces/produtos/produto/produto-filtro.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {ProdutoTipoLabels} from '@app/shared/enums/produto-tipo.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-produto-busca-rapida',
  templateUrl: './produto-busca-rapida.component.html',
})
export class ProdutoBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('secaoTemp', {static: true}) secaoTemp: TemplateRef<any>;
  @ViewChild('decimal2Temp', {static: true}) decimal2Temp: TemplateRef<any>;
  @ViewChild('produtoTipoTemp', {static: true})
  produtoTipoTemp: TemplateRef<any>;
  @ViewChild('marcaTemp', {static: true}) marcaTemp: TemplateRef<any>;
  @Input() empresas: string[];
  @Input() areaEstoqueId: string;
  @Input() mostrarModal: boolean;
  @Input() produto: Produto;
  @Input() mostrarBotaoCadastro: boolean;
  @Input() multiSelect: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  filtro = <ProdutoFiltro>{};
  mostrarProdutoCadastroRapidoModal: boolean;
  get dados() {
    return this.produtoFacade.produtosSimplificados;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get produtoTipoLabels() {
    return ProdutoTipoLabels;
  }
  get desabilitarBotaoSelecionar() {
    return !this.itensTabelaSelecionados;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  constructor(
      private produtoFacade: ProdutoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private parametroFacade: ParametroFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarProdutosComFiltro() {
    this.esconderColunaEstoqueAtual();

    this.produtoFacade.buscarProdutosSimplificados({
      pagina: this.pagina,
      limite: this.limite,
      descricao: this.filtro.descricao,
      ativo: true,
      empresas: this.empresas,
      areaEstoqueId: this.areaEstoqueId,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'codigoBarras',
        name: 'Código de barras',
      },
      {
        field: 'descricaoCompleta',
        name: 'Descrição',
      },
      {
        field: 'marcaId',
        name: 'Marca',
        cellTemplate: this.marcaTemp,
        hidden: true,
      },
      {
        field: 'descricaoSecao',
        name: 'Seção',
        cellTemplate: this.secaoTemp,
      },
      {
        field: 'estoqueAtual',
        name: 'Estoque Atual',
      },
      {
        field: 'produtoTipo',
        name: 'Tipo de produto',
        cellTemplate: this.produtoTipoTemp,
      },
      {
        field: 'venda',
        name: 'Valor de venda 1',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.decimal2Temp,
      },
    ];
  }
  async ngOnInit() {
    this.montarColunas();
  }
  async selecionar() {
    this.aoSelecionar.emit(
        Array.isArray(this.itensTabelaSelecionados) ?
            this.itensTabelaSelecionados :
            [this.itensTabelaSelecionados]);
  }
  buscarProdutos() {
    this.iniciarPaginacao();
    this.filtro = <ProdutoFiltro>{};
    this.itensTabelaSelecionados = [];
    this.produtoFacade.limparListaProdutosSimplificados();
    this.buscarProdutosComFiltro();
  }
  buscarProdutosProximaPagina() {
    this.pagina++;
    this.buscarProdutosComFiltro();
  }
  esconderColunaEstoqueAtual() {
    this.colunas.find((p) => p.field == 'estoqueAtual').hidden =
        !this.areaEstoqueId;
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.produtoFacade.limparListaProdutosSimplificados();
    this.buscarProdutosComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itensTabelaSelecionados = [];
    this.produtoFacade.limparListaProdutosSimplificados();
    this.limparPaginacao();
  }
  abrirProdutoCadastroRapidoModal() {
    this.mostrarProdutoCadastroRapidoModal = true;
  }
  fecharProdutoCadastroRapidoModal() {
    this.mostrarProdutoCadastroRapidoModal = false;
  }
}
