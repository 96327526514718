import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Perfil} from '@app/abstraction/domain/entities/cadastros/perfil/perfil';
import {PerfilFiltro} from '@app/abstraction/domain/interfaces/cadastros/perfil/perfil-filtro.interface';
import {PerfilListagem} from '@app/abstraction/domain/interfaces/cadastros/perfil/perfil-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PerfilService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Perfil) {
    const response$ =
        this.client.post<Perfil>(API.CADASTROS_PERFIL, dados)
            .pipe(map((json: Perfil) => json ? Perfil.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Perfil, id: string) {
    const response$ =
        this.client.put<Perfil>(`${API.CADASTROS_PERFIL}/${id}`, dados)
            .pipe(map((json: Perfil) => json ? Perfil.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.CADASTROS_PERFIL}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Perfil>(`${API.CADASTROS_PERFIL}/${id}`)
            .pipe(map((json: Perfil) => json ? Perfil.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: PerfilFiltro) {
    const response$ = this.client.get<PerfilListagem[]>(
        this.geradorQueryString.gerar(`${API.CADASTROS_PERFIL}`, filtro));
    return lastValueFrom(response$);
  }
}
