import {Injectable} from '@angular/core';
import {TipoAjusteListagem} from '@app/abstraction/domain/interfaces/estoque/tipo-ajuste/tipo-ajuste-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TipoAjusteStore {
  private readonly _tiposAjuste =
      new BehaviorSubject<TipoAjusteListagem[]>(null);
  get tiposAjuste(): TipoAjusteListagem[] {
    return this._tiposAjuste.getValue();
  }
  set tiposAjuste(tiposAjuste: TipoAjusteListagem[]) {
    this._tiposAjuste.next(tiposAjuste);
  }
  get tiposAjuste$() {
    return this._tiposAjuste.asObservable();
  }

  adicionar(tipoAjuste: TipoAjusteListagem) {
    if (!this.tiposAjuste) {
      this.tiposAjuste = [];
    }
    this.tiposAjuste.push(tipoAjuste);
  }
  adicionarRange(tiposAjuste: TipoAjusteListagem[]) {
    if (!this.tiposAjuste) {
      this.tiposAjuste = [];
    }
    tiposAjuste.forEach((tipoAjuste) => {
      if (!this.tiposAjuste.some((c) => c.id === tipoAjuste.id)) {
        this.tiposAjuste.push(tipoAjuste);
      }
    });
  }
}
