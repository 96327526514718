import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {LancamentoPagamento} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-pagamento.entity';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {ContaBancariaFacade} from '@app/abstraction/domain/facades/financeiro/conta-bancaria/conta-bancaria.facade';
import {FormaPagamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-pagamento/forma-pagamento.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ILancamentoPagamentoForm, LancamentoPagamentoForm} from '@app/abstraction/domain/forms/financeiro/lancamento/lancamento-pagamento.form';
import {LancamentoParcelaListagem} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-listagem.interface';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {NumericInputComponent} from '@app/shared/components/dumb/form-inputs/numeric-input/numeric-input.component';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-contas-pagamento-parcial-modal',
  templateUrl: './contas-pagamento-parcial-modal.component.html',
})
export class ContasPagamentoParcialModalComponent extends FormBaseComponent
    implements OnInit {
  @ViewChild('valor') valor: NumericInputComponent;
  @Input() mostrarModal: boolean;
  @Input() pagamento: LancamentoPagamento;
  @Input() parcela: LancamentoParcelaListagem;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSalvar = new EventEmitter<FormGroup<ILancamentoPagamentoForm>>();

  pagamentoForm: LancamentoPagamentoForm;

  get titulo() {
    return this.pagamentoForm.titulo;
  }
  get formasPagamento() {
    return this.formaPagamentoFacade.formasPagamento?.filter(
        p => p.empresas?.some(p => p === this.parcela?.empresaId) &&
            p.exibeNoConvenio);
  }
  get contasBancarias() {
    return this.contaBancariaFacade.contasBancarias?.filter(
        p => p.empresaId === this.parcela?.empresaId);
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais);
  }
  get valorPlaceholder() {
    return this.mascaraService.placeholder(this.valorDecimais);
  }
  constructor(
      private formaPagamentoFacade: FormaPagamentoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private contaBancariaFacade: ContaBancariaFacade,
      private mascaraService: MascaraService,
      private parametroFacade: ParametroFacade,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private criarForm() {
    this.pagamentoForm = new LancamentoPagamentoForm(
        this.errorMessagesFacade,
        this.toastrService,
    );
    this.verificarSePagamentoConvenio();
    this.pagamentoForm.criarForm();
    this.pagamentoForm.setarTipoFormulario(this.pagamento, this.parcela);
    this.pagamentoForm.monitorarValor();
    this.pagamentoForm.somarValorTotal();
  }
  private verificarSePagamentoConvenio() {
    if (!(this.formasPagamento?.find(
            p => p.id === this.parcela?.formaPagamentoId)) &&
        this.parcela) {
      this.parcela.formaPagamentoId = null;
    }
  }
  async aoAbrirModal() {
    this.criarForm();
    this.selecionarCampoValor();
  }
  abrirContaBancariaCadastroRapidoModal() {
    this.pagamentoForm.abrirContaBancariaCadastroRapidoModal();
  }
  abrirAcrescimosDescontosModal() {
    this.pagamentoForm.abrirAcrescimosDescontosModal();
  }
  abrirFormaPagamentoCadastroRapidoModal() {
    this.pagamentoForm.abrirFormaPagamentoCadastroRapidoModal();
  }
  fecharFormaPagamentoCadastroRapidoModal() {
    this.pagamentoForm.fecharFormaPagamentoCadastroRapidoModal();
  }
  fecharAcrescimosDescontosModal() {
    this.pagamentoForm.fecharAcrescimosDescontosModal();
  }
  fecharModal() {
    this.pagamentoForm.form.reset();
    this.aoCancelar.emit();
  }
  fecharContaBancariaCadastroRapidoModal() {
    this.pagamentoForm.fecharContaBancariaCadastroRapidoModal();
  }
  ngOnInit() {
    this.criarForm();
  }
  selecionarCampoValor() {
    setTimeout(() => {
      const inputValor =
          <HTMLInputElement>this.valor.contentInput.nativeElement;
      inputValor.select();
    }, 100);
  }
  setarFormaPagamentoCadastrada(formaPagamento: FormaPagamento) {
    this.pagamentoForm.setarFormaPagamentoCadastrada(formaPagamento);
  }
  salvarAcrescimosDescontosModal() {
    this.pagamentoForm.salvarAcrescimosDescontosModal();
  }
  salvar() {
    try {
      this.saveLoading = true;

      if (!(this.pagamentoForm.isValid(this.parcela, this.pagamento))) {
        this.saveLoading = false;
        return;
      }

      this.aoSalvar.emit(this.pagamentoForm.form);
      this.saveLoading = false;

    } catch (error) {
      this.toastrService.error('Erro ao adicionar pagamento.');
      this.saveLoading = false;
    }
  }
  setarContaBancariaCadastrada(contaBancaria: ContaBancaria) {
    this.pagamentoForm.setarContaBancariaCadastrada(contaBancaria);
  }
}
