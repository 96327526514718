import {FamiliaListagem} from '@app/abstraction/domain/interfaces/produtos/familia/familia-listagem.interface';
export class Familia {
  ativo: boolean;
  descricao: string;
  id: string;

  static from(value: any) {
    const familia = new Familia();
    familia.ativo = value.ativo;
    familia.descricao = value.descricao?.trim();
    familia.id = value.id;
    return familia;
  }
  toListagem() {
    return <FamiliaListagem>{
      id: this.id,
      descricao: this.descricao,
      ativo: this.ativo,
    };
  }
}
