<div class="grid flex justify-content-center">
  <div
    *ngFor="let plano of planos"
    class="flex justify-content-center flex-wrap seletor-empresa mt-6"
  >
    <div
      class="tabela-planos"
      [ngClass]="{ selected: plano.selecionado }"
      (click)="selecionar(plano)"
      [attr.data-cy]="'card-seletor-plano-' + plano.planoId"
    >
      <div class="tabela-planos-header">
        <h3 class="heading">{{ plano.titulo }}</h3>
        <span class="flat-value">
          <span class="currency">{{ plano.moeda }}</span> {{ plano.valor }}
          <span class="month" *ngIf="plano.periodo">/{{ plano.periodo }}</span>
        </span>
      </div>
      <div class="flat-content">
        <ul>
          <li *ngFor="let item of plano.itens">{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
