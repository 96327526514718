import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {FormaParcelamento} from '@app/abstraction/domain/entities/financeiro/forma-parcelamento/forma-parcelamento.entity';
import {FormaPagamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-pagamento/forma-pagamento.facade';
import {FormaParcelamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-parcelamento/forma-parcelamento.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {FormaParcelamentoForm} from '@app/abstraction/domain/forms/financeiro/forma-parcelamento.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-forma-parcelamento-cadastro-rapido-modal',
  templateUrl: './forma-parcelamento-cadastro-rapido-modal.component.html',
})
export class FormaParcelamentoCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Input() formaPagamentoId: string;
  @Output() aoSalvar = new EventEmitter<FormaParcelamento>();
  @Output() aoCancelar = new EventEmitter();

  formaParcelamentoForm: FormaParcelamentoForm;
  get titulo() {
    return this.formaParcelamentoForm.titulo;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get inteiroMask() {
    return this.mascaraService.inteiro();
  }
  constructor(
      private formaPagamentoFacade: FormaPagamentoFacade,
      private formaParcelamentoFacade: FormaParcelamentoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private mascaraService: MascaraService,
      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarFormaParcelamento(formaParcelamento:
                                               FormaParcelamento) {
    return this.formaParcelamentoFacade.adicionar(formaParcelamento)
        .catch((e: any) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private async atualizarFormaPagamento(formaParcelamento: FormaParcelamento) {
    const formaPagamento = await this.formaPagamentoFacade.buscarPorId(
                               this.formaPagamentoId) as FormaPagamento;
    if (formaPagamento) {
      formaPagamento.formasParcelamento.push(formaParcelamento.id);
      await this.formaPagamentoFacade.alterar(formaPagamento);
    }
  }
  private preencherObjeto() {
    return FormaParcelamento.from(this.formaParcelamentoForm.form.value);
  }
  private mostrarSucessoAoSalvar(formaParcelamento: FormaParcelamento) {
    this.toastrService.success(`Forma de parcelamento cadastrada com sucesso`);
    this.aoSalvar.emit(formaParcelamento);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      const formaParcelamento = this.preencherObjeto();

      if (!this.formaParcelamentoForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const resultado = await this.adicionarFormaParcelamento(formaParcelamento)

      if (resultado) this.finalizarOperacoes(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar forma de pagamento.');
      this.saveLoading = false;
    }
  }
  private async finalizarOperacoes(formaParcelamento: FormaParcelamento) {
    if (this.formaPagamentoId)
      await this.atualizarFormaPagamento(formaParcelamento);

    this.mostrarSucessoAoSalvar(formaParcelamento);
  }

  aoAbrirModal() {
    this.criarForm();
  }
  criarForm() {
    this.formaParcelamentoForm =
        new FormaParcelamentoForm(this.errorMessagesFacade);
    this.formaParcelamentoForm.criarForm();
    this.formaParcelamentoForm.setarTipoFormulario();
  }
  fecharModal() {
    this.formaParcelamentoForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
  }
}
