<p-tabView
  *ngIf="contaBancariaForm && contaBancariaForm?.form"
  [scrollable]="true"
>
  <p-tabPanel header="Dados da conta bancária" [selected]="true">
    <div class="formgrid grid">
      <!-- #region NOME -->
      <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
        <app-custom-label texto="Nome da Conta" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [autoFocus]="true"
          [maxlength]="150"
          [control]="contaBancariaForm?.form.controls.nome"
        >
        </app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region EMPRESA -->
      <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
        <app-custom-label texto="Empresa" [obrigatorio]="true">
        </app-custom-label>
        <app-dropdown-input
          optionLabel="nomeFantasia"
          optionValue="id"
          [control]="contaBancariaForm?.form.controls.empresaId"
          [options]="empresas"
          [showFilter]="true"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region BANCO -->
      <div class="field col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
        <app-custom-label
          [obrigatorio]="true"
          texto="Banco"
          [cadastroRapido]="true"
          (aoClicarAdicionar)="abrirBancoCadastroRapidoModal()"
        >
        </app-custom-label>
        <app-dropdown-input
          optionLabel="descricao"
          optionValue="codigo"
          [control]="contaBancariaForm?.form.controls.banco"
          [options]="bancos$ | async"
          [showFilter]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region AGENCIA -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6">
        <app-custom-label texto="Agência" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          mask="0000"
          textAlign="right"
          [maxlength]="4"
          [control]="contaBancariaForm?.form.controls.agencia"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region DIGITO AGENCIA -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6">
        <app-custom-label texto="Dgt. agência" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          textAlign="right"
          [maxlength]="2"
          [control]="contaBancariaForm?.form.controls.digitoAgencia"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region CONTA -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6">
        <app-custom-label texto="Conta" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          mask="00000000"
          textAlign="right"
          [maxlength]="8"
          [control]="contaBancariaForm?.form.controls.conta"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region DIGITO CONTA -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6">
        <app-custom-label texto="Dgt. conta" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          textAlign="right"
          [maxlength]="2"
          [control]="contaBancariaForm?.form.controls.digitoConta"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region SALDO -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6">
        <app-custom-label texto="Saldo (R$)" [obrigatorio]="true">
        </app-custom-label>
        <app-numeric-input
          [control]="contaBancariaForm.form.controls.saldo"
          [options]="valorMask"
          [readonly]="!contaBancariaForm.operacaoFormAdicionar"
          [placeholder]="valorPlaceholder"
        ></app-numeric-input>
      </div>
      <!-- #endregion -->

      <!-- #region EMITE BOLETO -->
      <div
        class="col-6 xl:col-2 md:col-3 mt-0 sm:mt-5"
        *ngIf="bancoEmiteBoleto"
      >
        <app-checkbox-input
          label="Emite boleto"
          controlName="emiteBoleto"
          [control]="contaBancariaForm?.form.controls.emiteBoleto"
          (valueChange)="aplicarValidacaoBoleto($event)"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->
    </div>
  </p-tabPanel>
  <p-tabPanel header="Boleto" *ngIf="emiteBoleto">
    <div class="formgrid grid">
      <!-- #region NOSSO NUMERO -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6">
        <app-custom-label texto="Nosso número"></app-custom-label>
        <app-numeric-input
          [control]="contaBancariaBoletoForm.controls.nossoNumeroInicial"
          [options]="inteiroMask"
        ></app-numeric-input>
      </div>
      <!-- #endregion -->

      <!-- #region CODIGO TRANSMISSAO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
        <app-custom-label texto="Código de transmissão"></app-custom-label>
        <app-text-input
          mask="00000000000000000000"
          textAlign="right"
          [maxlength]="20"
          [control]="contaBancariaBoletoForm.controls.codigoTransmissao"
        >
        </app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region JUROS MENSAIS -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6">
        <app-custom-label texto="Juros mensais (%)"></app-custom-label>
        <app-numeric-input
          [control]="contaBancariaBoletoForm.controls.jurosMensais"
          [placeholder]="porcentagemPlaceholder"
          [options]="porcentagemMask"
        ></app-numeric-input>
      </div>
      <!-- #endregion -->

      <!-- #region MULTA -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6">
        <app-custom-label texto="Multa (%)"></app-custom-label>
        <app-numeric-input
          [control]="contaBancariaBoletoForm.controls.multa"
          [placeholder]="porcentagemPlaceholder"
          [options]="porcentagemMask"
        ></app-numeric-input>
      </div>
      <!-- #endregion -->

      <!-- #region DESCONTO VENCIMENTO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-3 sm:col-6">
        <app-custom-label
          texto="Desconto até o vencimento (%)"
        ></app-custom-label>
        <app-numeric-input
          [control]="contaBancariaBoletoForm.controls.descontoVencimento"
          [placeholder]="valorPlaceholder"
          [options]="valorMask"
        ></app-numeric-input>
      </div>
      <!-- #endregion -->

      <!-- #region INSTRUCAO PAGAMENTO VENCIMENTO -->
      <div class="field col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12">
        <app-custom-label
          texto="Instrução de vencimento do pagamento"
        ></app-custom-label>
        <app-textarea-input
          [rows]="2"
          [control]="
            contaBancariaBoletoForm.controls.instrucaoPagamentoVencimento
          "
          [maxlength]="200"
        ></app-textarea-input>
      </div>
      <!-- #endregion -->

      <!-- #region COMPROVANTE RECEBIMENTO -->
      <div class="col-6 xl:col-2 lg:col-2 md:col-2 sm:col-2">
        <app-checkbox-input
          label="Comprovante de recebimento"
          controlName="comprovanteRecebimento"
          [control]="contaBancariaBoletoForm.controls.comprovanteRecebimento"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->

      <!-- #region BANCO PROTESTO -->
      <div class="col-6 xl:col-2 lg:col-2 md:col-2 sm:col-2">
        <app-checkbox-input
          label="Banco protesto"
          controlName="bancoProtesto"
          [control]="contaBancariaBoletoForm.controls.bancoProtesto"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->

      <!-- #region BANCO DEVOLUCAO -->
      <div class="col-6 xl:col-2 lg:col-2 md:col-2 sm:col-2">
        <app-checkbox-input
          label="Banco devolução"
          controlName="bancoDevolucao"
          [control]="contaBancariaBoletoForm.controls.bancoDevolucao"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->
    </div>
  </p-tabPanel>
  <p-tabPanel header="Carteiras" *ngIf="emiteBoleto">
    <div class="grid">
      <div class="col">
        <p-button
          type="button"
          styleClass="btn-five"
          label="Nova carteira"
          tooltipPosition="bottom"
          pTooltip="Nova carteira"
          icon="pi pi-plus"
          (onClick)="abrirContaBancariaBoletoCarteiraModal()"
        ></p-button>
      </div>
    </div>
    <div class="grid">
      <div *ngIf="contasBoletoCarteira.length <= 0" class="col-12 text-center">
        <span>Nenhuma carteira cadastrada</span>
      </div>
      <div
        class="col-12 xl:col-3 lg:col-4 md:col-6 sm:col-12"
        *ngFor="let contaBoletoCarteira of contasBoletoCarteira; let i = index"
      >
        <app-cartao-conta-boleto-carteira
          [contaBoletoCarteira]="contaBoletoCarteira"
          (aoClicarEmEditar)="
            abrirContaBancariaBoletoCarteiraModal(contaBoletoCarteira, i)
          "
          (aoClicarEmExcluir)="removerContaBancariaBoletoCarteiraModal(i)"
        >
        </app-cartao-conta-boleto-carteira>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>

<app-banco-cadastro-rapido-modal
  [mostrarModal]="contaBancariaForm?.mostrarBancoCadastroRapidoModal"
  (aoCancelar)="fecharBancoCadastroRapidoModal()"
  (aoSalvar)="setarBancoCadastrado($event)"
></app-banco-cadastro-rapido-modal>

<app-conta-bancaria-boleto-carteira-modal
  [form]="contaBancariaForm?.contaBoletoCarteiraForm"
  [mostrarModal]="contaBancariaForm?.mostrarContaBancariaBoletoCarteiraModal"
  [titulo]="contaBancariaForm?.contaBancariaBoletoCarteiraModalTitulo"
  [carteiras]="carteiras"
  (aoCancelar)="fecharContaBancariaBoletoCarteiraModal()"
  (aoSalvar)="salvarContaBancariaBoletoCarteira($event)"
></app-conta-bancaria-boleto-carteira-modal>
