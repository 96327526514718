import { Injectable } from '@angular/core';
import { ApiServicesService } from '@app/abstraction/data/services/shared/api-services.service';

@Injectable({ providedIn: 'root' })
export class ApiServicesFacade {
  constructor(private service: ApiServicesService) {}
  buscarCpf(cpf: string) {
    return this.service.buscarCpf(cpf);
  }
  buscarCnpj(cnpj: string) {
    return this.service.buscarCnpj(cnpj);
  }
}
