<div class="p-inputgroup">
  <input
    #contentInput
    class=" inputfield w-full"
    pInputText
    appAutoFocus
    currencyMask
    [options]="options"
    [autofocus]="autoFocus"
    [ngModelOptions]="modelOptions"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [maxlength]="maxlength"
    [minlength]="minlength"
    [ngClass]="{
      readonly: readonly,
      'text-right': textAlign === 'right',
      'text-center': textAlign === 'center',
      'text-left': textAlign === 'left'
    }"
    (ngModelChange)="onNgModelChange($event)"
    (blur)="onBlur($event)"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    [(ngModel)]="model"
  />
  <p-button
    *ngIf="showButton"
    type="button"
    styleClass="btn-five"
    [icon]="iconButton"
    (onClick)="onClick()"
    [disabled]="buttonDisabled"
  >
  </p-button>
</div>
