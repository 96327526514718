<p-dialog
  header="Selecione um tipo de pessoa"
  [style]="{ width: isDesktop ? '50%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onHide)="fecharModal()"
>
<div class="formgrid grid mt-1">
  <div class="field col-12 md:col-6">
    <p-button
    (click)="selecionarTipoPessoa('cliente')"
    label="Cliente"
    styleClass="btn-five w-full"
  ></p-button>
  </div>
  <div class="field col-12 md:col-6">
    <p-button
    (click)="selecionarTipoPessoa('fornecedor')"
    label="Fornecedor"
    styleClass="btn-five w-full"
  ></p-button>
  </div>
  <!-- <div class="field col-12 md:col-3">
    <p-button
    (click)="selecionarTipoPessoa('funcionario')"
    label="Funcionário"
    styleClass="btn-five w-full"
  ></p-button>
  </div>
  <div class="field col-12 md:col-3">
    <p-button
    (click)="selecionarTipoPessoa('transportadora')"
    label="Transportadora"
    styleClass="btn-five w-full"
  ></p-button>
  </div> -->
</div>

</p-dialog>

