<p-calendar
  #contentInput
  appCalendarMask
  appendTo="body"
  yearRange="1950:2050"
  styleClass=" inputfield w-full"
  [showCalendarMask]="showCalendarMask"
  [view]="view"
  [placeholder]="placeholder"
  [dateFormat]="dateFormat"
  [yearNavigator]="yearNavigator"
  [monthNavigator]="monthNavigator"
  [keepInvalid]="keepInvalid"
  [showClear]="showClear"
  [showOnFocus]="showOnFocus"
  [showIcon]="showIcon"
  [showTime]="showTime"
  [showSeconds]="showSeconds"
  [readonlyInput]="readonly"
  [ngClass]="{
    'readonly': readonly,
  }"
  [ngModelOptions]="modelOptions"
  (ngModelChange)="onNgModelChange($event)"
  (keydown)="onInputChange($event)"
  [(ngModel)]="model"
></p-calendar>
