interface String {
  isNotNumber(): boolean;
  removerCaracteresEspeciais(): string;
  removerZerosEsquerda(): string;
  somenteNumeros(): string;
  somenteLetras(): string;
  padLeft(padChar: string, size: number): string;
  isJsonDateTime(): boolean;
  removeAcentos(): string;
}
String.prototype.isNotNumber = function() {
  return isNaN(Number(this));
};
String.prototype.removerCaracteresEspeciais = function() {
  return this?.toString().replace(/[^a-z0-9]/gi, '');
};
String.prototype.removerZerosEsquerda = function() {
  return this?.toString().replace(/^(0+)(\d)/g, '$2');
};
String.prototype.somenteNumeros = function() {
  return this?.toString().replace(/\D/g, '');
};
String.prototype.somenteLetras = function() {
  return this?.toString().replace(/[^a-zA-Z]/g, '');
};
String.prototype.padLeft = function(padChar: string, size: number) {
  let s = this + '';
  while (s.length < size) s = padChar + s;
  return s;
};
String.prototype.isJsonDateTime = function isJsonDate(): boolean {
  const utcPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
  const utc1Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1}Z$/;
  const utc2Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{2}Z$/;
  const utc3Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  const utc4Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{4}Z$/;
  const localPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

  return localPattern.test(this as string) || utcPattern.test(this as string) ||
      utc1Pattern.test(this as string) || utc2Pattern.test(this as string) ||
      utc3Pattern.test(this as string) || utc4Pattern.test(this as string);
};
String.prototype.removeAcentos = function() {
  return this?.toString().normalize('NFD').replace(
      /[\u0300-\u036f|\u00b4|\u0060|\u005e|\u007e]/g, '');
};