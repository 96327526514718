import '@app/shared/extensions/moment.extension';


export class LancamentoPagamento {
  id: string;
  formaPagamentoId: string;
  contaBancariaId: string;
  valor: number;
  valorTotal: number;
  valorMulta: number;
  valorJuros: number;
  valorOutrosAcrescimos: number;
  valorDesconto: number;
  dataPagamento: Date|string;
  observacao: string;

  static from(json: any = {}) {
    const lancamentoPagamento = new LancamentoPagamento();
    lancamentoPagamento.id = json.id;
    lancamentoPagamento.valor = json.valor ?? 0;
    lancamentoPagamento.valorTotal = json.valorTotal ?? 0;
    lancamentoPagamento.valorMulta = json.valorMulta ?? 0;
    lancamentoPagamento.valorJuros = json.valorJuros ?? 0;
    lancamentoPagamento.valorOutrosAcrescimos = json.valorOutrosAcrescimos ?? 0;
    lancamentoPagamento.valorDesconto = json.valorDesconto ?? 0;
    lancamentoPagamento.dataPagamento = json.dataPagamento;
    lancamentoPagamento.observacao = json.observacao?.trim();
    lancamentoPagamento.formaPagamentoId = json.formaPagamentoId;
    lancamentoPagamento.contaBancariaId = json.contaBancariaId;
    return lancamentoPagamento;
  }
}
