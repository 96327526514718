import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Secao} from '@app/abstraction/domain/entities/produtos/secao/secao.entity';
import {SecaoFiltro} from '@app/abstraction/domain/interfaces/produtos/secao/secao-filtro.interface';
import {SecaoListagem} from '@app/abstraction/domain/interfaces/produtos/secao/secao-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SecaoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Secao) {
    const response$ =
        this.client.post<Secao>(API.SECAO, dados)
            .pipe(map((json: Secao) => json ? Secao.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Secao, id: string) {
    const response$ =
        this.client.put<Secao>(`${API.SECAO}/${id}`, dados)
            .pipe(map((json: Secao) => json ? Secao.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ =
        this.client.put<boolean>(`${API.SECAO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Secao>(`${API.SECAO}/${id}`)
            .pipe(map((json: Secao) => json ? Secao.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: SecaoFiltro) {
    const response$ = this.client.get<SecaoListagem[]>(
        this.geradorQueryString.gerar(API.SECAO, filtro));
    return lastValueFrom(response$);
  }
}
