export class NotaFiscalEvento {
  chaveEletronica: string;
  justificativa: string;
  sequenciaEvento: number;
  status: number;
  motivo: string;
  xml: string;

  static from(json: any = {}): NotaFiscalEvento {
    const notaFiscalEvento = new NotaFiscalEvento();
    notaFiscalEvento.chaveEletronica = json.chaveEletronica;
    notaFiscalEvento.justificativa = json.justificativa;
    notaFiscalEvento.sequenciaEvento = json.sequenciaEvento;
    notaFiscalEvento.status = json.status;
    notaFiscalEvento.motivo = json.motivo;
    notaFiscalEvento.xml = json.xml;
    return notaFiscalEvento;
  }
}
