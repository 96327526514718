import { RegimeTributario } from '@app/shared/enums/regimes-tributarios.enum';

export class ProdutoCstConst {
  constructor(
    _codigo: string,
    _descricao: string,
    _regimeTributario: RegimeTributario
  ) {
    this.descricao = _descricao;
    this.codigo = _codigo;
    this.regimeTributario = _regimeTributario;
  }
  codigo: string;
  descricao: string;
  regimeTributario: RegimeTributario;
}

export const ProdutoCsts = [
  new ProdutoCstConst(
    '00',
    'TRIBUTADA INTEGRALMENTE',
    RegimeTributario.RegimeNormal
  ),
  new ProdutoCstConst(
    '10',
    'TRIBUTADA E COM COBRANCA DO ICMS POR SUBST.TRIB.',
    RegimeTributario.RegimeNormal
  ),
  new ProdutoCstConst(
    '20',
    'COM REDUCAO DE BASE DE CALCULO',
    RegimeTributario.RegimeNormal
  ),
  new ProdutoCstConst(
    '30',
    'ISENTA OU NAO TRIB. E COM COBR. ICMS POR SUBST.TRIB.',
    RegimeTributario.RegimeNormal
  ),
  new ProdutoCstConst('40', 'ISENTA', RegimeTributario.RegimeNormal),
  new ProdutoCstConst('41', 'NAO TRIBUTADA', RegimeTributario.RegimeNormal),
  new ProdutoCstConst('50', 'SUSPENSAO', RegimeTributario.RegimeNormal),
  new ProdutoCstConst('51', 'DIFERIMENTO', RegimeTributario.RegimeNormal),
  new ProdutoCstConst(
    '60',
    'ICMS COBRADO ANT. POR SUBST. TRIB.',
    RegimeTributario.RegimeNormal
  ),
  new ProdutoCstConst(
    '70',
    'COM RED. BASE DE CALC. E COBR. ICMS POR SUBST.TRIB.',
    RegimeTributario.RegimeNormal
  ),
  new ProdutoCstConst('90', 'OUTRAS', RegimeTributario.RegimeNormal),
  new ProdutoCstConst(
    '101',
    'TRIBUTADA PELO SIMPLES NACIONAL COM OU SEM PERMISSAO DE CREDITO',
    RegimeTributario.SimplesNacional
  ),
  new ProdutoCstConst(
    '102',
    'TRIBUTADA PELO SIMPLES NACIONAL SEM PERMISSAO DE CREDITO',
    RegimeTributario.SimplesNacional
  ),
  new ProdutoCstConst(
    '103',
    'ISENCAO DO ICMS NO SIMPLES NACIONAL PARA FAIXA DE RECEITA BRUTA',
    RegimeTributario.SimplesNacional
  ),
  new ProdutoCstConst(
    '201',
    'TRIBUTADA PELO SIMPLES NACIONAL COM OU SEM PERMISSAO DE CREDITO E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA',
    RegimeTributario.SimplesNacional
  ),
  new ProdutoCstConst(
    '202',
    'TRIBUTADA PELO SIMPLES NACIONAL SEM PERMISSÃO DE CRÉDITO E COM COBRANÇA DO ICMS POR ST',
    RegimeTributario.SimplesNacional
  ),
  new ProdutoCstConst(
    '203',
    'ISENCAO DO ICMS NO SIMPLES NACIONAL PARA FAIXA DE RECEITA BRUTA E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA',
    RegimeTributario.SimplesNacional
  ),
  new ProdutoCstConst('300', 'IMUNE', RegimeTributario.SimplesNacional),
  new ProdutoCstConst(
    '400',
    'NAO TRIBUTADA PELO SIMPLES NACIONAL',
    RegimeTributario.SimplesNacional
  ),
  new ProdutoCstConst(
    '500',
    'ICMS COBRADO ANTERIORMENTE POR SUBSTITUICAO TRIBUTARIA (SUBSTITUIDO) OU POR ANTECIPACAO',
    RegimeTributario.SimplesNacional
  ),
  new ProdutoCstConst('900', 'OUTROS', RegimeTributario.SimplesNacional),
];
