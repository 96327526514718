import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {FormaPagamentoFiltro} from '@app/abstraction/domain/interfaces/financeiro/forma-pagamento/forma-pagamento-filtro.interface';
import {FormaPagamentoListagem} from '@app/abstraction/domain/interfaces/financeiro/forma-pagamento/forma-pagamento-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';


@Injectable({providedIn: 'root'})
export class FormaPagamentoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: FormaPagamento) {
    const response$ =
        this.client.post<FormaPagamento>(API.FINANCEIRO_FORMA_PAGAMENTO, dados)
            .pipe(
                map((json: FormaPagamento) =>
                        json ? FormaPagamento.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: FormaPagamento, id: string) {
    const response$ = this.client
                          .put<FormaPagamento>(
                              `${API.FINANCEIRO_FORMA_PAGAMENTO}/${id}`, dados)
                          .pipe(
                              map((json: FormaPagamento) =>
                                      json ? FormaPagamento.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.FINANCEIRO_FORMA_PAGAMENTO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client
            .get<FormaPagamento>(`${API.FINANCEIRO_FORMA_PAGAMENTO}/${id}`)
            .pipe(
                map((json: FormaPagamento) =>
                        json ? FormaPagamento.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: FormaPagamentoFiltro) {
    const response$ = this.client.get<FormaPagamentoListagem[]>(
        this.geradorQueryString.gerar(API.FINANCEIRO_FORMA_PAGAMENTO, filtro));
    return lastValueFrom(response$);
  }
}
