import {BancoListagem} from '@app/abstraction/domain/interfaces/financeiro/banco/banco-listagem.interface';

/**
 *
 *
 * @class Banco
 * @implements {BancoListagem}
 *
 */
export class Banco implements BancoListagem {
  ativo: boolean;
  id: string;
  codigo: string;
  descricao: string;
  emiteBoleto: boolean;

  /**
   * Descrição do método from
   *
   * @static
   * @param {any} json
   * @return {Banco}
   * @memberof Banco
   */
  static from(json: any = {}): Banco {
    const banco = new Banco();
    banco.id = json.id;
    banco.ativo = json.ativo;
    banco.codigo = json.codigo?.trim();
    banco.descricao = json.descricao?.trim();
    banco.emiteBoleto = json.emiteBoleto;
    return banco;
  }
  toListagem(): BancoListagem {
    return <BancoListagem>{
      id: this.id,
      descricao: this.descricao,
      codigo: this.codigo,
      ativo: this.ativo,
      emiteBoleto: this.emiteBoleto
    };
  }
}
