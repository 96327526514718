import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {UsuarioFavorito} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-favorito.interface';
import {BaseComponent} from '@app/shared/components/base/base.component';
import {IconPickerTypes} from '@app/shared/components/dumb/icon-picker/enums/icon-picker-types.enum';
import {piIcons} from '@app/shared/components/dumb/icon-picker/icons/pi';
import {IconGroup} from '@app/shared/components/dumb/icon-picker/interfaces/icon-group.interface';
import {IconPickerOptions} from '@app/shared/components/dumb/icon-picker/interfaces/icon-picker-options.interface';
import {FavoritoTipo} from '@app/shared/enums/favorito-tipo.enum';
import {Guid} from 'guid-typescript';
import {ToastrService} from 'ngx-toastr';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-titulo',
  templateUrl: './titulo.component.html',
  styleUrls: ['./titulo.component.scss'],
})
export class TituloComponent extends BaseComponent implements OnInit {
  @Input() mostrarBotaoFavorito: boolean;
  @Input() titulo: string;
  @Input() styleClass: string;

  breadcrumbs: MenuItem[] = [];
  selecionado: any;
  form: UntypedFormGroup;
  iconPickerOpcoes = <IconPickerOptions>{
    inputStyleClass: ' inputfield w-full p-inputtext p-component',
    searchable: true,
    type: IconPickerTypes.Block,
    iconsGroups: this.montarListaIcones(),
  };
  get tipoMenuDashboard() {
    return Number(this.form.controls.tipo.value) === FavoritoTipo.MenuDashboard;
  }
  get modulo() {
    return this.activatedRoute.snapshot.data.modulo;
  }
  constructor(
      private fb: UntypedFormBuilder,
      private authFacade: AuthFacade,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
    this.montarBreadcrumbs();
  }
  private montarBreadcrumbs() {
    const data = this.activatedRoute.snapshot.data;
    if (data?.modulo) {
      this.breadcrumbs.push({
        label: data.modulo,
      });
    }
    if (data?.tela) {
      this.breadcrumbs.push({
        label: data.tela,
        routerLink: [data.rota],
      });
    }
    if (data?.permissao) {
      this.breadcrumbs.push({
        label: data.permissao,
      });
    }
  }
  private montarListaIcones() {
    return [
      <IconGroup>{
        groupName: 'PrimeIcons',
        icons: piIcons,
      },
    ];
  }
  private verificarFavoritos() {
    if (this.authFacade.usuarioFavoritos) {
      this.selecionado = this.authFacade.usuarioFavoritos.find(
          (e) => e.link === this.router.url);
    }
  }
  private criarForm() {
    this.form = this.fb.group({
      chave: [Guid.create().toString()],
      tela: [this.titulo],
      modulo: [this.modulo],
      tipo: [FavoritoTipo.BarraFavoritos],
      link: [this.router.url],
      icone: [null],
    });
  }
  private verificarFavoritosDashboardCompleto() {
    return (
        this.authFacade.usuarioFavoritos
            ?.filter((favorito) => favorito.tipo === FavoritoTipo.MenuDashboard)
            .length === 6);
  }
  open() {
    this.criarForm();
  }
  adicionar() {
    this.criarForm();
    const favorito = Object.assign({}, this.form.value as UsuarioFavorito);
    favorito.tipo = Number(favorito.tipo);
    if (favorito.tipo === 0 && this.verificarFavoritosDashboardCompleto()) {
      this.toastrService.warning(
          'Limite de favoritos no dashboard já foi atingido. (Máx. 6)');
    } else {
      this.authFacade.adicionarFavorito(favorito);
      this.selecionado = !this.selecionado;
      this.toastrService.success('Item adicionado com sucesso!!');
    }
  }
  ngOnInit() {
    this.criarForm();
    this.verificarFavoritos();
  }
  remover() {
    if (this.authFacade.usuarioFavoritos) {
      const favorito = this.authFacade.usuarioFavoritos?.find(
          (e) => e.link === this.router.url);
      this.authFacade.removerFavorito(favorito);
      this.selecionado = !this.selecionado;
      this.toastrService.success('Item removido com sucesso!!');
    }
  }
}
