import '@app/shared/extensions/moment.extension';

import {SituacaoLancamento} from '@app/shared/enums/situacao-lancamento.enum';
import * as moment from 'moment';

import {LancamentoPagamento} from './lancamento-pagamento.entity';

export class LancamentoParcela {
  parcelaId: string;
  pessoaId: string;
  planoConta3Id: string;
  lancamentoId: string;
  empresaId: string;
  contaBancariaId: string;
  formaPagamentoId: string;
  formaParcelamentoId: string;
  numeroParcela: number;
  dataEmissao: Date|string;
  dataEntrada: Date|string;
  dataVencimento: Date|string;
  dataVencimentoOriginal: Date|string;
  valorDocumento: number;
  valorMulta: number;
  valorJuros: number;
  valorOutrosAcrescimos: number;
  valorDesconto: number;
  observacao: string;
  boletoCodigoBarras: string;
  boletoLinhaDigitavel: string;
  pagamentos: LancamentoPagamento[];
  numeroDocumento: string;
  situacao: SituacaoLancamento;
  contaBancaria: string;
  planoConta: string;
  get valorPendente() {
    const valorPago = this.pagamentos.reduce((acc, cur) => acc + cur.valor, 0);
    return this.valorDocumento + this.valorMulta + this.valorJuros +
        this.valorOutrosAcrescimos - this.valorDesconto - valorPago;
  }
  static from(json: any = {}) {
    const lancamentoParcela = new LancamentoParcela();
    lancamentoParcela.parcelaId = json.parcelaId;
    lancamentoParcela.pessoaId = json.pessoaId;
    lancamentoParcela.planoConta3Id = json.planoConta3Id;
    lancamentoParcela.lancamentoId = json.lancamentoId;
    lancamentoParcela.formaPagamentoId = json.formaPagamentoId;
    lancamentoParcela.formaParcelamentoId = json.formaParcelamentoId;
    lancamentoParcela.empresaId = json.empresaId;
    lancamentoParcela.contaBancariaId = json.contaBancariaId;
    lancamentoParcela.numeroParcela = json.numeroParcela;
    lancamentoParcela.dataVencimento = json.dataVencimento;
    lancamentoParcela.dataVencimentoOriginal = json.dataVencimentoOriginal;
    lancamentoParcela.dataEmissao = json.dataEmissao;
    lancamentoParcela.dataEntrada = json.dataEntrada;
    lancamentoParcela.valorDocumento = json.valorDocumento;
    lancamentoParcela.valorMulta = json.valorMulta;
    lancamentoParcela.valorJuros = json.valorJuros;
    lancamentoParcela.valorOutrosAcrescimos = json.valorOutrosAcrescimos;
    lancamentoParcela.valorDesconto = json.valorDesconto;
    lancamentoParcela.observacao = json.observacao?.trim();
    lancamentoParcela.boletoCodigoBarras = json.boletoCodigoBarras?.trim();
    lancamentoParcela.boletoLinhaDigitavel = json.boletoLinhaDigitavel?.trim();
    lancamentoParcela.situacao = json.situacao;
    lancamentoParcela.contaBancaria = json.contaBancaria;
    lancamentoParcela.planoConta = json.planoConta;
    lancamentoParcela.pagamentos =
        json.pagamentos?.map((p: any) => LancamentoPagamento.from(p));
    lancamentoParcela.numeroDocumento = json.numeroDocumento;
    return lancamentoParcela;
  }
  toAPI() {
    this.dataEmissao = moment(this.dataEmissao).toLocalOnlyDate();
    this.dataEntrada = moment(this.dataEntrada).toLocalOnlyDate();
    this.dataVencimento = moment(this.dataVencimento).toLocalOnlyDate();
    this.dataVencimentoOriginal =
        moment(this.dataVencimentoOriginal).toLocalOnlyDate();
  }
}
