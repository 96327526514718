import {Injectable} from '@angular/core';
import {FormaPagamentoListagem} from '@app/abstraction/domain/interfaces/financeiro/forma-pagamento/forma-pagamento-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FormaPagamentoStore {
  private readonly _formasPagamento =
      new BehaviorSubject<FormaPagamentoListagem[]>(null);

  get formasPagamento(): FormaPagamentoListagem[] {
    return this._formasPagamento.getValue();
  }
  set formasPagamento(formasPagamento: FormaPagamentoListagem[]) {
    this._formasPagamento.next(formasPagamento);
  }
  get formasPagamento$() {
    return this._formasPagamento.asObservable();
  }
  adicionar(formaPagamento: FormaPagamentoListagem) {
    if (!this.formasPagamento) {
      this.formasPagamento = [];
    }
    this.formasPagamento.push(formaPagamento);
  }
  adicionarRange(formasPagamento: FormaPagamentoListagem[]) {
    if (!this.formasPagamento) {
      this.formasPagamento = [];
    }
    formasPagamento.forEach((formaPagamento) => {
      if (!this.formasPagamento.some((c) => c.id === formaPagamento.id)) {
        this.formasPagamento.push(formaPagamento);
      }
    });
  }
}
