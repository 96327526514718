/* eslint-disable @angular-eslint/no-output-on-prefix */
import {Component, EventEmitter, Input, Output} from '@angular/core';

import {BarChart} from './interfaces/bar-chart';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent {
  @Input() barChart: BarChart;
  @Output() onButtonClick = new EventEmitter();
  @Output() onBarClick = new EventEmitter();

  onBarClicked(event: any) {
    this.onBarClick.emit(event);
  }

  buttonRedirect() {
    this.onButtonClick.emit(this.barChart.linkBotaoRapido);
  }
}
