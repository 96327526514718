export class TipoPagamentoNfeConst {
  constructor(codigo: number, descricao: string) {
    this.codigo = codigo;
    this.descricao = descricao;
  }
  codigo: number;
  descricao: string;
}

export const TiposPagamentoNfe = [
  new TipoPagamentoNfeConst(1, 'Dinheiro'),
  new TipoPagamentoNfeConst(2, 'Cheque'),
  new TipoPagamentoNfeConst(3, 'Cartão de Crédito'),
  new TipoPagamentoNfeConst(4, 'Cartão de Débito'),
  new TipoPagamentoNfeConst(5, 'Crédito de Loja'),
  new TipoPagamentoNfeConst(10, 'Vale Alimentação'),
  new TipoPagamentoNfeConst(11, 'Vale Refeição'),
  new TipoPagamentoNfeConst(12, 'Vale Presente'),
  new TipoPagamentoNfeConst(13, 'Vale Combustível'),
  new TipoPagamentoNfeConst(14, 'Duplicata Mercanti'),
  new TipoPagamentoNfeConst(15, 'Boleto Bancário'),
  new TipoPagamentoNfeConst(16, 'Depósito Bancário'),
  new TipoPagamentoNfeConst(17, 'Pagamento Instantâneo (PIX)'),
  new TipoPagamentoNfeConst(18, 'Transferência bancária, Carteira Digital'),
  new TipoPagamentoNfeConst(
    19,
    'Programa de fidelidade, Cashback, Crédito Virtual'
  ),
  new TipoPagamentoNfeConst(90, 'Sem Pagamento'),
  new TipoPagamentoNfeConst(99, 'Outros'),
];
