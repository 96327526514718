import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Cest} from '@app/abstraction/domain/entities/fiscal/cest/cest.entity';
import {Ncm} from '@app/abstraction/domain/entities/fiscal/ncm/ncm.entity';
import {NcmFiltro} from '@app/abstraction/domain/interfaces/fiscal/ncm/ncm-filtro.interface';
import {NcmListagem} from '@app/abstraction/domain/interfaces/fiscal/ncm/ncm-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NcmService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: Ncm) {
    const response$ =
        this.client.post<Ncm>(API.FISCAL_NCM, dados)
            .pipe(map((json: Ncm) => json ? Ncm.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarTributacaoProdutos(ncmCodigo: string, ncmExcecao: string) {
    const response$ = this.client.put(
        `${API.FISCAL_NCM}/${ncmCodigo}/${ncmExcecao}/atualiza-produtos`, null);
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: NcmFiltro) {
    const response$ = this.client.get<NcmListagem[]>(
        this.geradorQueryString.gerar(`${API.FISCAL_NCM}`, filtro));
    return lastValueFrom(response$);
  }
  alterar(ncm: Ncm, ncmCodigo: string) {
    const response$ =
        this.client.put<Ncm>(`${API.FISCAL_NCM}/${ncmCodigo}`, ncm)
            .pipe(map((json: Ncm) => json ? Ncm.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarPorNcmCodigoUf(
      ncmCodigo: string, uf: string, ncmExcecaoTipi?: number|string) {
    const response$ = this.client.get<Ncm>(`${API.FISCAL_NCM}/${ncmCodigo}/${
        uf}${ncmExcecaoTipi ? `?tipi=${ncmExcecaoTipi}` : ''}`);
    return lastValueFrom(response$);
  }
  buscarCestPorNcmCodigo(ncmCodigo: string) {
    const response$ =
        this.client.get<Cest[]>(`${API.FISCAL_NCM}/${ncmCodigo}/cest`);
    return lastValueFrom(response$);
  }
}
