import {Pipe, PipeTransform} from '@angular/core';
import {ProdutoVariacaoCorFacade} from '@app/abstraction/domain/facades/produtos/produto-variacao-cor/produto-variacao-cor.facade';
import {ProdutoVariacaoCorListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-cor/produto-variacao-cor-listagem.interface';

@Pipe({
  name: 'cor',
})
export class CorPipe implements PipeTransform {
  constructor(private produtoVariacaoCorFacade: ProdutoVariacaoCorFacade) {}
  transform(corId: string, campo: string = 'corCodigo') {
    if (corId) {
      const produtoVariacaoCor =
          this.produtoVariacaoCorFacade.produtoVariacaoCores?.find(
              (e: ProdutoVariacaoCorListagem) => e.id === corId) as any;
      return produtoVariacaoCor?.[campo];
    }
    return '';
  }
}
