import {Injectable} from '@angular/core';
import {FornecedorService} from '@app/abstraction/data/services/cadastros/fornecedor.service';
import {FornecedorStore} from '@app/abstraction/data/stores/cadastros/fornecedor.store';
import {Fornecedor} from '@app/abstraction/domain/entities/cadastros/fornecedor/fornecedor.entity';
import {FornecedorFiltro} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-filtro.interface';

@Injectable({providedIn: 'root'})
export class FornecedorFacade {
  get fornecedores() {
    return this.store.fornecedores;
  }
  get fornecedores$() {
    return this.store.fornecedores$;
  }
  constructor(
      private store: FornecedorStore, private service: FornecedorService) {}

  async adicionar(fornecedor: Fornecedor) {
    const novoFornecedor = await this.service.adicionar(fornecedor);
    if (this.fornecedores) {
      this.store.adicionar(novoFornecedor.toListagem());
    }
    return novoFornecedor;
  }
  async atualizarSituacao(id: string) {
    const fornecedor = this.fornecedores.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !fornecedor.ativo);
    fornecedor.ativo = status;
    return status;
  }
  async buscarFornecedores(filtro?: FornecedorFiltro) {
    const fornecedores = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(fornecedores);
  }
  async buscarRegistrosFinanceiros(id: string) {
    var registrosFinanceiros =
        await this.service.buscarRegistrosFinanceiros(id);
    return registrosFinanceiros;
  }
  alterar(fornecedor: Fornecedor) {
    return this.service.alterar(fornecedor, fornecedor.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  buscarPorDocumento(documento: string) {
    return this.service.buscarPorDocumento(documento);
  }
  excluir(id: string) {
    return this.service.excluir(id);
  }
  verificarFornecedorCadastrado(documento: string) {
    return this.buscarPorDocumento(documento);
  }
  vincularProdutoAoFornecedor(
      produtoVariacaoId: string,
      codigoReferencia: any,
      documento: any,
  ) {
    return this.service.vincularProdutoAoFornecedor(
        documento, produtoVariacaoId, codigoReferencia);
  }
  limparListaFornecedores() {
    this.store.fornecedores = null;
  }
}
