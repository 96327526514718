export enum CodigoNatureza {
  Nenhum,
  ContasAtivo,
  ContasPassivo,
  PatrimonioLiquido,
  ContasResultado,
  ContasCompensacao,
  Outras,
}

export const CodigoNaturezaLabels = new Map<number, string>([
  [CodigoNatureza.ContasAtivo, 'CONTAS DE ATIVO'],
  [CodigoNatureza.ContasPassivo, 'CONTAS DE PASSIVO'],
  [CodigoNatureza.PatrimonioLiquido, 'PATRIMÔNIO LÍQUIDO'],
  [CodigoNatureza.ContasResultado, 'CONTAS DE RESULTADO'],
  [CodigoNatureza.ContasCompensacao, 'CONTAS DE COMPENSAÇÃO'],
  [CodigoNatureza.Outras, 'OUTRAS'],
]);
