import {Component} from '@angular/core';
import {PdfService} from '@app/shared/services/pdf/pdf.service';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';


@Component({
  templateUrl: './pedido-opcoes.component.html',
  styleUrls: ['./pedido-opcoes.component.scss']
})
export class PedidoOpcoesComponent {
  constructor(
      private config: DynamicDialogConfig,
      private pdfService: PdfService,
  ) {}

  imprimir() {
    const pedido = this.config.data?.pedido;
    this.pdfService.gerarPdfPedido(pedido);
  }
}
