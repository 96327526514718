import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';

@Component({
  selector: 'app-seletor-tipo-pessoa-cadastro',
  templateUrl: './seletor-tipo-pessoa-cadastro.component.html',
  styleUrls: ['./seletor-tipo-pessoa-cadastro.component.scss']
})
export class SeletorTipoPessoaCadastroComponent {
  @Input() mostrarModal: boolean;
  @Output() aoSelecionar = new EventEmitter<string>();
  @Output() aoFechar = new EventEmitter();
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private deviceDetectorFacade: DeviceDetectorFacade,
  ) {}
  selecionarTipoPessoa(tipoPessoa: string) {
    this.aoSelecionar.emit(tipoPessoa);
  }
  fecharModal() {
    this.aoFechar.emit();
  }
}
