import {PlanoContasListagem} from '@app/abstraction/domain/interfaces/financeiro/plano-contas/plano-contas-listagem.interface';

export class PlanoContasNivel3 {
  ativo: boolean;
  codigoNatureza: number;
  contaCodigo: number;
  contaContabil: string;
  descricao: string;
  despesa: boolean;
  id: string;
  planoConta1Id: string;
  planoConta2Id: string;
  planoContaGrupoId: string;
  tipoConta: number;

  static from(json: any = {}) {
    const planoContasNivel3 = new PlanoContasNivel3();
    planoContasNivel3.ativo = json.ativo;
    planoContasNivel3.contaCodigo = json.contaCodigo;
    planoContasNivel3.contaContabil = json.contaContabil?.trim();
    planoContasNivel3.descricao = json.descricao?.trim();
    planoContasNivel3.planoConta1Id = json.planoConta1Id;
    planoContasNivel3.planoConta2Id = json.planoConta2Id;
    planoContasNivel3.id = json.id;
    planoContasNivel3.codigoNatureza = json.codigoNatureza;
    planoContasNivel3.tipoConta = json.tipoConta;
    planoContasNivel3.despesa = json.despesa;
    planoContasNivel3.planoContaGrupoId = json.planoContaGrupoId;
    return planoContasNivel3;
  }
  toListagem() {
    return <PlanoContasListagem>{
      ativo: this.ativo,
      codigoNatureza: this.codigoNatureza,
      contaContabil: this.contaContabil,
      contaCodigo: this.contaCodigo,
      descricao: this.descricao,
      despesa: this.despesa,
      id: this.id,
      planoConta1Id: this.planoConta1Id,
      planoConta2Id: this.planoConta2Id,
      planoContaGrupoId: this.planoContaGrupoId,
      tipoConta: this.tipoConta,
    };
  }
}
