<p-tabView
  *ngIf="transportadoraForm && transportadoraForm?.form"
  [scrollable]="true"
>
  <p-tabPanel header="Dados da transportadora">
    <div class="formgrid grid">
      <!-- #region TIPO DE PESSOA -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Tipo de pessoa" [obrigatorio]="true">
        </app-custom-label>
        <app-dropdown-input
          [options]="pessoaTipos"
          [control]="transportadoraForm.form.controls?.pessoaTipo"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region CPF/CNPJ -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label
          [texto]="transportadoraForm.labelCpfCnpj"
          [obrigatorio]="documentoObrigatorio"
        >
        </app-custom-label>
        <app-text-input
          [maxlength]="maxlength"
          [maxlengthCount]="maxlengthCount"
          [lengthOnlyNumber]="true"
          textAlign="right"
          iconButton="pi pi-search"
          [control]="transportadoraForm.form.controls?.documento"
          [autoFocus]="transportadoraForm.operacaoFormAdicionar"
          [showButton]="true"
          [mask]="mascaraDocumento"
          (keypress)="consultarDocumentoValidoComEnter($event)"
          (buttonOnClick)="consultarDocumentoValido()"
          (inputBlur)="consultarDocumentoValido()"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region NOME -->
      <div
        class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12"
        *ngIf="!pessoaJuridica"
      >
        <app-custom-label
          [texto]="transportadoraForm.labelNomeRazaoSocial"
          [obrigatorio]="true"
        >
        </app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.nomeRazaoSocial"
          [maxlength]="60"
        ></app-text-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid" *ngIf="pessoaJuridica">
      <!-- #region RAZAO SOCIAL -->
      <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
        <app-custom-label
          [texto]="transportadoraForm.labelNomeRazaoSocial"
          [obrigatorio]="true"
        >
        </app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.nomeRazaoSocial"
          [maxlength]="60"
          (inputBlur)="preencherNomeFantasia($event)"
        ></app-text-input>
      </div>
      <!-- #endregion -->
      <!-- #region NOME FANTASIA -->
      <div class="field col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
        <app-custom-label texto="Nome fantasia" [obrigatorio]="true">
        </app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.nomeFantasia"
          [maxlength]="60"
        ></app-text-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid">
      <!-- #region NASCIMENTO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label [texto]="transportadoraForm.labelNascimento">
        </app-custom-label>
        <app-calendar-input
          [showIcon]="true"
          [control]="transportadoraForm.form.controls?.nascimento"
        ></app-calendar-input>
      </div>
      <!-- #endregion -->
      <!-- #region REGIME TRIBUTARIO -->
      <div
        class="field col-12 xl:col-3 lg:col-4 md:col-6 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="Regime tributário"></app-custom-label>
        <app-dropdown-input
          [options]="regimesTributarios"
          [control]="transportadoraForm.form.controls.regimeTributario"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region TIPO DE ATIVIDADE -->
      <div
        class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="Tipo de atividade"></app-custom-label>
        <app-dropdown-input
          [options]="atividadeTipos"
          [control]="transportadoraForm.form.controls?.atividadeTipo"
          [showClear]="true"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region IE INDICADOR -->
      <div
        class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6"
        *ngIf="pessoaJuridica"
      >
        <app-custom-label texto="IE indicador"></app-custom-label>
        <app-dropdown-input
          [options]="ieIndicadores"
          [control]="transportadoraForm.form.controls?.ieIndicador"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->
      <!-- #region RG/IE -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label [texto]="transportadoraForm.labelRgIe">
        </app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.rgIe"
          [maxlength]="20"
        >
        </app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region PLANO CONTA -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label
          texto="Plano de contas padrão"
          [cadastroRapido]="true"
          (aoClicarAdicionar)="abrirPlanoContasCadastroRapidoModal()"
        >
        </app-custom-label>
        <app-dropdown-input
          optionLabel="descricao"
          optionValue="id"
          [control]="transportadoraForm.form.controls?.planoConta3Id"
          [options]="planosContas3"
          [showFilter]="true"
          [showClear]="true"
        >
        </app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region VENDEDOR PADRÃO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Vendedor Padrão"></app-custom-label>
        <app-dropdown-input
          optionLabel="nomeFantasia"
          optionValue="id"
          [control]="transportadoraForm.form.controls?.vendedorPadrao"
          [options]="funcionarios"
          [showFilter]="true"
          [showClear]="true"
        ></app-dropdown-input>
      </div>
      <!-- #endregion -->

      <!-- #region EMAIL GERAL -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Email Geral"></app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.emailGeral"
          [maxlength]="60"
          [upperCase]="false"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region EMAIL FATURAMENTO -->
      <div class="field col-12 xl:col-3 lg:col-3 md:col-6 sm:col-6">
        <app-custom-label texto="Email de Faturamento"></app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.emailFaturamento"
          [maxlength]="60"
          [upperCase]="false"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region WHATSAPP -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6">
        <app-custom-label texto="WhatsApp"></app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.whatsApp"
          [foneMask]="true"
          [maxlength]="15"
          [maxlengthCount]="11"
          [lengthOnlyNumber]="true"
          [onlyCellPhoneMask]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region TELEFONE FIXO -->
      <div class="field col-12 xl:col-2 lg:col-2 md:col-6 sm:col-6">
        <app-custom-label texto="Telefone"></app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.telefoneFixo"
          [foneMask]="true"
          [maxlength]="15"
          [maxlengthCount]="11"
          [lengthOnlyNumber]="true"
          [onlyPhoneMask]="true"
        ></app-text-input>
      </div>
      <!-- #endregion -->

      <!-- #region ATIVO -->
      <div class="col-6 xl:col-2 md:col-3 mt-0 sm:mt-5">
        <app-checkbox-input
          label="Ativo"
          controlName="ativo"
          [control]="transportadoraForm?.form.controls.ativo"
        >
        </app-checkbox-input>
      </div>
      <!-- #endregion -->
    </div>
    <div class="formgrid grid">
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6 ml-auto"
        *ngIf="transportadoraForm.mostrarDataCriacao"
      >
        <app-custom-label texto="Data de criação"></app-custom-label>
        <app-calendar-input
          [control]="transportadoraForm.form.controls?.createdOn"
          [readonly]="true"
        ></app-calendar-input>
      </div>
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        *ngIf="transportadoraForm.mostrarDataAlteracao"
      >
        <app-custom-label texto="Data de alteração"></app-custom-label>
        <app-calendar-input
          [control]="transportadoraForm.form.controls?.lastModifiedOn"
          [readonly]="true"
        ></app-calendar-input>
      </div>
      <div
        class="field col-12 xl:col-2 lg:col-2 md:col-2 sm:col-6"
        *ngIf="transportadoraForm.mostrarCriadoPor"
      >
        <app-custom-label texto="Criado por"></app-custom-label>
        <app-text-input
          [control]="transportadoraForm.form.controls?.nomeCreatedBy"
          [readonly]="true"
        >
        </app-text-input>
      </div>
    </div>
    <p-tabView
      [scrollable]="true"
      data-cy="tabview-subdados-transportadora"
      (onChange)="recuperaDadosTab($event)"
    >
      <p-tabPanel header="Endereços">
        <div class="grid">
          <div class="col">
            <p-button
              type="button"
              styleClass="btn-five"
              label="Novo endereço"
              tooltipPosition="bottom"
              pTooltip="Novo endereço"
              icon="pi pi-plus"
              (onClick)="abrirEnderecoModal()"
            ></p-button>
          </div>
        </div>
        <div class="grid">
          <div
            class="col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
            *ngFor="let endereco of enderecos; let i = index"
          >
            <app-cartao-endereco
              [endereco]="endereco"
              (aoCliquarEmEditar)="abrirEnderecoModal(endereco, i)"
            >
            </app-cartao-endereco>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Contatos">
        <div class="grid">
          <div class="col">
            <p-button
              type="button"
              styleClass="btn-five"
              label="Novo contato"
              tooltipPosition="bottom"
              pTooltip="Novo contato"
              icon="pi pi-plus"
              (onClick)="abrirContatoModal()"
            ></p-button>
          </div>
        </div>
        <div class="grid">
          <div
            class="col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12"
            *ngFor="let contato of contatos; let i = index"
          >
            <app-cartao-contato
              [contato]="contato"
              (aoCliquarEmEditar)="abrirContatoModal(contato, i)"
            >
            </app-cartao-contato>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Dados bancários">
        <div class="formgrid grid">
          <!-- #region BANCO -->
          <div
            class="field col-12 xl:col-3 lg:col-3 md:col-1 md:col-3 sm:col-6"
          >
            <app-custom-label texto="Banco"></app-custom-label>
            <app-text-input
              [control]="transportadoraForm.form.controls?.banco"
              [maxlength]="20"
            ></app-text-input>
          </div>
          <!-- #endregion -->
          <!-- #region AGENCIA -->
          <div
            class="field col-12 xl:col-3 lg:col-3 md:col-1 md:col-3 sm:col-6"
          >
            <app-custom-label texto="Agência"></app-custom-label>
            <app-text-input
              [control]="transportadoraForm.form.controls?.agencia"
              [maxlength]="20"
            ></app-text-input>
          </div>
          <!-- #endregion -->
          <!-- #region CONTA CORRENTE -->
          <div
            class="field col-12 xl:col-3 lg:col-3 md:col-1 md:col-3 sm:col-6"
          >
            <app-custom-label texto="Conta Corrente"></app-custom-label>
            <app-text-input
              [control]="transportadoraForm.form.controls?.contaCorrente"
              [maxlength]="20"
            ></app-text-input>
          </div>
          <!-- #endregion -->
          <!-- #region CONDIÇOES PAGAMENTO -->
          <div
            class="field col-12 xl:col-3 lg:col-3 md:col-1 md:col-3 sm:col-6"
          >
            <app-custom-label texto="Condição de Pagamento"> </app-custom-label>
            <app-text-input
              [control]="transportadoraForm.form.controls?.condicaoPagamento"
              [maxlength]="20"
            ></app-text-input>
          </div>
          <!-- #endregion -->
        </div>
      </p-tabPanel>
      <p-tabPanel
        [header]="tituloTabPanelRegistrosFinanceiros"
        *ngIf="transportadoraForm.operacaoFormEditar"
      >
        <div class="formgrid grid">
          <div class="field col-12 xl:col-12 lg:col-12 md:col-12">
            <div class="borda-tabela">
              <app-table
                [columns]="colunasTabelaLancamentoParcela"
                [rows]="transportadoraForm.lancamentosParcela"
                [paginator]="true"
                dataKey="id"
              ></app-table>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Dados adicionais">
        <div class="formgrid grid">
          <div class="field col-12 xl:col-12 lg:col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12">
                <app-custom-label texto="Observações"> </app-custom-label>
                <app-textarea-input
                  [control]="transportadoraForm.form.controls?.observacao"
                  [maxlength]="255"
                  [rows]="10"
                ></app-textarea-input>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </p-tabPanel>

  <p-confirmDialog
    rejectButtonStyleClass="p-button-text"
    key="confirmacao-transportadora-form"
    [style]="{ width: '45%' }"
  ></p-confirmDialog>

  <app-contato-modal
    [form]="transportadoraForm.contatoForm"
    [mostrarModal]="transportadoraForm.mostrarContatoModal"
    [titulo]="transportadoraForm.contatoModalTitulo"
    (aoCancelar)="fecharContatoModal()"
    (aoSalvar)="salvarContato($event)"
  >
  </app-contato-modal>

  <app-endereco-modal
    [form]="transportadoraForm.enderecoForm"
    [mostrarModal]="transportadoraForm.mostrarEnderecoModal"
    [mostrarItemTodosEnderecoTipos]="operacaoFormEnderecoAdicionar"
    [titulo]="transportadoraForm.enderecoModalTitulo"
    (aoCancelar)="fecharEnderecoModal()"
    (aoSalvar)="salvarEndereco($event)"
  >
  </app-endereco-modal>

  <app-plano-contas-cadastro-rapido-modal
    [mostrarModal]="transportadoraForm.mostrarPlanoContasModal"
    (aoCancelar)="fecharPlanoContasCadastroRapidoModal()"
    (aoSalvar)="setarPlanoContasCadastrado($event)"
  ></app-plano-contas-cadastro-rapido-modal>
</p-tabView>

<ng-template #despesaTemp let-value="value">
  <span *ngIf="value">DESPESA</span>
  <span *ngIf="!value">RECEITA</span>
</ng-template>
<ng-template #dataTemp let-value="value">
  <span>{{ value | date : dataFormatoPt }}</span>
</ng-template>
<ng-template #valorTemp let-value="value">
  <span>R${{ value | customDecimal : valorDecimais }}</span>
</ng-template>
<ng-template #situacaoLancamentoParcelaTemp let-value="value">
  <span>{{ value | enumLabel : situacaoLancamentoLabels }}</span>
</ng-template>
<ng-template
  #lancamentosParcelaAcoesTemp
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <p-button
    tooltipPosition="bottom"
    pTooltip="Visualizar Parcela"
    icon="pi pi-pencil"
    [text]="true"
    [plain]="true"
    (onClick)="verLancamentoParcela(rowData)"
  ></p-button>
</ng-template>
