import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-imagem-order-list',
  templateUrl: './imagem-order-list.component.html',
  styleUrls: ['./imagem-order-list.component.scss'],
})
export class ImagemOrderListComponent {
  @Input() filtrarPor: string;
  @Input() filtroPlaceholder: string;
  @Input() lista: any[];
  @Input() titulo: string;
  @Output() aoRemover = new EventEmitter<any[]>();
  @Output() aoReordenar = new EventEmitter<any[]>();
  itensSelecionados: any[];

  setarNovaOrdem() {
    this.lista.forEach((item: any, index: number) => {
      item.ordem = index;
    });
    this.aoReordenar.emit(this.lista);
  }
  removerItem() {
    this.aoRemover.emit(this.itensSelecionados);
  }
}
