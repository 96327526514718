import {FormControl, FormGroup} from '@angular/forms';
import {ProdutoVariacaoCor} from '@app/abstraction/domain/entities/produtos/produto-variacao-cor/produto-variacao-cor.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface IProdutoVariacaoCorForm {
  id: FormControl<string>;
  descricao: FormControl<string>;
  corCodigo: FormControl<string>;
  ativo: FormControl<boolean>;
}

export class ProdutoVariacaoCorForm extends BaseForm {
  form: FormGroup<IProdutoVariacaoCorForm>;
  operacaoForm: OperacaoForm;

  get titulo() {
    return this.operacaoFormAdicionar ? 'Nova cor do produto' :
                                        'Editar cor do produto';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(private errorMessagesFacade: ErrorMessageFacade) {
    super();
  }
  private preencherForm(value: ProdutoVariacaoCor) {
    this.form.patchValue(value);
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  criarForm() {
    this.form = new FormGroup<IProdutoVariacaoCorForm>({
      id: new FormControl(null),
      descricao: new FormControl(
          null,
          [
            CustomValidators.required('Descrição é obrigatória'),
            CustomValidators.maxLength(60, 'Tamanho máximo: 60 caracteres')
          ],
          ),
      corCodigo: new FormControl(
          null,
          CustomValidators.required('Cor é obrigatório'),
          ),
      ativo: new FormControl(true),
    });
  }
  monitorarFormulario() {
    super.monitorarFormulario(this.form);
  }
  setarTipoFormulario(value?: any) {
    if (value) {
      this.operacaoForm = OperacaoForm.Editar;
      this.preencherForm(ProdutoVariacaoCor.from(value));
    } else {
      this.operacaoForm = OperacaoForm.Adicionar;
    }
  }
  alterarCorForm(cor: string) {
    this.form.controls.corCodigo.setValue(cor);
  }
}
