import {Injectable} from '@angular/core';
import {FornecedorListagem} from '@app/abstraction/domain/interfaces/cadastros/fornecedor/fornecedor-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FornecedorStore {
  private readonly _fornecedores =
      new BehaviorSubject<FornecedorListagem[]>(null);

  get fornecedores(): FornecedorListagem[] {
    return this._fornecedores.getValue();
  }
  set fornecedores(fornecedores: FornecedorListagem[]) {
    this._fornecedores.next(fornecedores);
  }
  get fornecedores$() {
    return this._fornecedores.asObservable();
  }
  adicionar(fornecedor: FornecedorListagem) {
    if (!this.fornecedores) {
      this.fornecedores = [];
    }
    this.fornecedores.push(fornecedor);
  }
  adicionarRange(fornecedores: FornecedorListagem[]) {
    if (!this.fornecedores) {
      this.fornecedores = [];
    }

    fornecedores.forEach((fornecedor) => {
      if (!this.fornecedores.some((c) => c.id === fornecedor.id)) {
        this.fornecedores.push(fornecedor);
      }
    });
  }
}
