<p-dialog
  header="Novo subgrupo"
  [style]="{ width: isDesktop ? '50%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="subgrupoForm.form"
  (onHide)="fecharModal()"
  (onShow)="aoAbrirModal()"
>
  <p-tabView [scrollable]="true" [formGroup]="subgrupoForm.form">
    <p-tabPanel header="Dados do subgrupo" [selected]="true">
      <div class="formgrid grid">
        <!-- #region DESCRICAO -->
        <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
          <app-custom-label texto="Descrição" [obrigatorio]="true">
          </app-custom-label>
          <app-text-input
            [autoFocus]="true"
            [control]="subgrupoForm.form.controls?.descricao"
            [maxlength]="60"
          ></app-text-input>
        </div>
        <!-- #endregion -->

        <!-- #region GRUPO -->
        <div class="field col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
          <app-custom-label texto="Grupo" [obrigatorio]="true">
          </app-custom-label>
          <app-dropdown-input
            optionLabel="descricao"
            optionValue="id"
            [control]="subgrupoForm.form.controls?.grupoId"
            [options]="grupos$ | async"
            [showFilter]="true"
          ></app-dropdown-input>
        </div>
        <!-- #endregion -->
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
      [disabled]="saveLoading"
    ></p-button>
    <p-button
      (click)="salvar()"
      label="Salvar"
      styleClass="btn-five"
      [loading]="saveLoading"
    ></p-button>
  </ng-template>
</p-dialog>
