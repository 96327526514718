import {Pipe, PipeTransform} from '@angular/core';
import {FormaPagamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-pagamento/forma-pagamento.facade';
import {FormaPagamentoListagem} from '@app/abstraction/domain/interfaces/financeiro/forma-pagamento/forma-pagamento-listagem.interface';

@Pipe({
  name: 'formaPagamento',
})
export class FormaPagamentoPipe implements PipeTransform {
  constructor(private facade: FormaPagamentoFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const formaPagamento = this.facade.formasPagamento?.find(
                                 (formaPagamento: FormaPagamentoListagem) =>
                                     formaPagamento.id == id) as any;
      return formaPagamento?.[campo];
    }
    return '';
  }
}
