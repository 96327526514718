import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente } from '@app/abstraction/domain/entities/cadastros/cliente/cliente.entity';
import { LancamentoParcela } from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import { Pedido } from '@app/abstraction/domain/entities/vendas/pedido/pedido.entity';
import { ClienteFiltro } from '@app/abstraction/domain/interfaces/cadastros/cliente/cliente-filtro.interface';
import { ClienteListagem } from '@app/abstraction/domain/interfaces/cadastros/cliente/cliente-listagem.interface';
import { API } from '@app/shared/constants/api.constant';
import { GeradorQueryStringHelper } from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import { lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClienteService {
  constructor(
    private geradorQueryString: GeradorQueryStringHelper,
    private client: HttpClient
  ) {}
  adicionar(dados: Cliente) {
    const response$ = this.client
      .post<Cliente>(API.CADASTRO_CLIENTE, dados)
      .pipe(map((json: Cliente) => (json ? Cliente.from(json) : null)));
    return lastValueFrom(response$);
  }
  alterar(dados: Cliente, id: string) {
    const response$ = this.client
      .put<Cliente>(`${API.CADASTRO_CLIENTE}/${id}`, dados)
      .pipe(map((json: Cliente) => (json ? Cliente.from(json) : null)));
    return lastValueFrom(response$);
  }
  excluir(id: string) {
    const response$ = this.client.delete<boolean>(
      `${API.CADASTRO_CLIENTE}/${id}`
    );
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
      `${API.CADASTRO_CLIENTE}/${id}/${API.STATUS}`,
      situacao
    );
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ = this.client
      .get<Cliente>(`${API.CADASTRO_CLIENTE}/${id}`)
      .pipe(map((json: Cliente) => (json ? Cliente.from(json) : null)));
    return lastValueFrom(response$);
  }
  buscarPorDocumento(documento: string) {
    const response$ = this.client
      .get<Cliente>(`${API.CADASTRO_CLIENTE_DOCUMENTO}/${documento}`)
      .pipe(map((json: Cliente) => (json ? Cliente.from(json) : null)));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: ClienteFiltro) {
    const response$ = this.client.get<ClienteListagem[]>(
      this.geradorQueryString.gerar(`${API.CADASTRO_CLIENTE}`, filtro)
    );
    return lastValueFrom(response$);
  }
  buscarTotalClientes() {
    const response$ = this.client.get<number>(
      `${API.CADASTRO_CLIENTE_QUANTIDADE}`
    );
    return lastValueFrom(response$);
  }
  buscarPedidos(id: string) {
    const response$ = this.client.get<Pedido[]>(
      `${API.CADASTRO_CLIENTE}/${id}/pedidos`
    );
    return lastValueFrom(response$);
  }
  buscarRegistrosFinanceiros(id: string) {
    const response$ = this.client.get<LancamentoParcela[]>(
      `${API.CADASTRO_CLIENTE}/${id}/registros-financeiros`
    );
    return lastValueFrom(response$);
  }
  downloadPlanilha() {
    const response$ = this.client.get(
      `${API.CADASTRO_CLIENTE_DOWNLOAD_PLANILHA}`,
      {
        responseType: 'blob',
      }
    );
    return lastValueFrom(response$);
  }
  importarPlanilha(fileBase64: string) {
    const response$ = this.client.post<ClienteListagem[]>(
      `${API.CADASTRO_CLIENTE_IMPORTAR_PLANILHA}`,
      fileBase64
    );
    return lastValueFrom(response$);
  }
}
