import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LancamentoPagamentoMassa} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-pagamento-massa.entity';
import {LancamentoPagamento} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-pagamento.entity';
import {LancamentoParcela} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento-parcela.entity';
import {Lancamento} from '@app/abstraction/domain/entities/financeiro/lancamento/lancamento.entity';
import {BoletoListagem} from '@app/abstraction/domain/interfaces/financeiro/boleto/boleto-listagem.interface';
import {LancamentoParcelaEstornarMassa} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-estornar-massa.interface';
import {LancamentoParcelaFiltro} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-filtro.interface';
import {LancamentoParcelaListagem} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';


@Injectable({providedIn: 'root'})
export class LancamentoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}
  adicionarLancamento(lancamento: Lancamento) {
    const response$ =
        this.client
            .post<LancamentoParcela>(`${API.FINANCEIRO_LANCAMENTO}`, lancamento)
            .pipe(
                map((json: LancamentoParcela) =>
                        json ? LancamentoParcela.from(json) : null));
    return lastValueFrom(response$);
  }
  alterarParcela(parcela: LancamentoParcela) {
    const response$ =
        this.client
            .put<LancamentoParcela>(
                `${API.FINANCEIRO_LANCAMENTO_PARCELAS}/${parcela.parcelaId}`,
                parcela)
            .pipe(
                map((json: LancamentoParcela) =>
                        json ? LancamentoParcela.from(json) : null));
    ;
    return lastValueFrom(response$);
  }
  adicionarPagamento(
      parcelaId: string,
      lancamentoPagarPagamento: LancamentoPagamento,
  ) {
    const response$ =
        this.client
            .post<LancamentoPagamento>(
                `${API.FINANCEIRO_LANCAMENTO_PARCELAS}/${parcelaId}/pagamentos`,
                [lancamentoPagarPagamento])
            .pipe(
                map((json: LancamentoPagamento) =>
                        json ? LancamentoPagamento.from(json) : null));
    return lastValueFrom(response$);
  }
  adicionarListaLancamentos(
      parcelaId: string, pagamentos: LancamentoPagamento[]) {
    const response$ = this.client.post<LancamentoPagamento[]>(
        `${API.FINANCEIRO_LANCAMENTO_PARCELAS}/${parcelaId}/pagamentos`,
        pagamentos);
    return lastValueFrom(response$);
  }
  adicionarPagamentosMassa(pagamentoMassa: LancamentoPagamentoMassa) {
    const response$ = this.client.post<boolean>(
        `${API.FINANCEIRO_LANCAMENTO_PAGAMENTOS}`, pagamentoMassa);
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: LancamentoParcelaFiltro) {
    const response$ = this.client.get<LancamentoParcelaListagem[]>(
        this.geradorQueryString.gerar(
            API.FINANCEIRO_LANCAMENTO_PARCELAS, filtro));
    return lastValueFrom(response$);
  }
  buscarParcelaPorId(id: string) {
    const response$ = this.client
                          .get<LancamentoParcela>(
                              `${API.FINANCEIRO_LANCAMENTO_PARCELAS}/${id}`)
                          .pipe(map(
                              (json: LancamentoParcela) =>
                                  json ? LancamentoParcela.from(json) : null));
    return lastValueFrom(response$);
  }
  estornarPagamentosParcelas(estorno: LancamentoParcelaEstornarMassa) {
    const response$ = this.client.put<boolean>(
        `${API.FINANCEIRO_LANCAMENTO_PARCELAS_ESTORNAR}`, estorno);
    return lastValueFrom(response$);
  }
  excluirParcela(parcelaId: string) {
    const response$ = this.client.delete<boolean>(
        `${API.FINANCEIRO_LANCAMENTO_PARCELAS}/${parcelaId}`);
    return lastValueFrom(response$);
  }
  downloadPlanilhaContasReceber() {
    const response$ = this.client.get(
        `${API.FINANCEIRO_LANCAMENTO_CONTAS_RECEBER_DOWNLOAD_PLANILHA}`, {
          responseType: 'blob',
        });
    return lastValueFrom(response$);
  }
  importarPlanilhaContasReceber(fileBase64: string) {
    const response$ = this.client.post<LancamentoParcelaListagem[]>(
        `${API.FINANCEIRO_LANCAMENTO_CONTAS_RECEBER_IMPORTAR_PLANILHA}`,
        fileBase64);
    return lastValueFrom(response$);
  }
  downloadPlanilhaContasPagar() {
    const response$ = this.client.get(
        `${API.FINANCEIRO_LANCAMENTO_CONTAS_PAGAR_DOWNLOAD_PLANILHA}`, {
          responseType: 'blob',
        });
    return lastValueFrom(response$);
  }
  importarPlanilhaContasPagar(fileBase64: string) {
    const response$ = this.client.post<LancamentoParcelaListagem[]>(
        `${API.FINANCEIRO_LANCAMENTO_CONTAS_PAGAR_IMPORTAR_PLANILHA}`,
        fileBase64);
    return lastValueFrom(response$);
  }
  verificarExisteBoletoPorParcelaId(parcelaId: string) {
    const response$ = this.client.get<BoletoListagem>(
        `${API.FINANCEIRO_LANCAMENTO_PARCELAS}/${parcelaId}/boletos`);
    return lastValueFrom(response$);
  }
}
