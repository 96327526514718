import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {Erro} from '@app/shared/interfaces/shared/erro.interface';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-lista-erros',
  templateUrl: './lista-erros.component.html',
  styleUrls: ['./lista-erros.component.scss']
})
export class ListaErrosComponent extends ListagemBaseComponent implements
    OnInit {
  @Input() erros: Erro[];
  @Input() titulo: string;
  @Input() mostrarModal: boolean;
  @Output() mostrarModalChange = new EventEmitter<boolean>();
  @Output() errosChange = new EventEmitter<Erro[]>();

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  constructor(
      private deviceDetectorFacade: DeviceDetectorFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private montarColunasTabela() {
    this.colunas = [
      {
        field: 'mensagem',
        name: 'Mensagem',
        headerClass: 'text-center',
        cellClass: 'text-center',
      },
    ];
  }
  fecharModal() {
    this.mostrarModalChange.emit(false);
    this.errosChange.emit([]);
  }
  ngOnInit() {
    this.montarColunasTabela();
  }
}
