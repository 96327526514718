import {Injectable} from '@angular/core';
import {UsuarioListagem} from '@app/abstraction/domain/interfaces/cadastros/usuario/usuario-listagem.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UsuarioStore {
  private readonly _usuarios = new BehaviorSubject<UsuarioListagem[]>(null);

  get usuarios(): UsuarioListagem[] {
    return this._usuarios.getValue();
  }
  set usuarios(usuarios: UsuarioListagem[]) {
    this._usuarios.next(usuarios);
  }
  get usuarios$(): Observable<UsuarioListagem[]> {
    return this._usuarios.asObservable();
  }
  adicionarRange(usuarios: UsuarioListagem[]) {
    if (!this.usuarios) {
      this.usuarios = [];
    }
    usuarios.forEach((usuario) => {
      if (!this.usuarios.some((c) => c.id === usuario.id)) {
        this.usuarios.push(usuario);
      }
    });
  }
}
