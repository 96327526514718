import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import * as moment from 'moment';

@Injectable()
export class DateSerializationInterceptor implements HttpInterceptor {
  private serializeDates(data: any): any {
    if (data === null) return data;

    if (typeof data === 'number') return data;

    if (typeof data === 'boolean') return data;

    if (typeof data === 'string' && !data.isJsonDateTime()) return data;

    if (typeof data === 'string' && data.isJsonDateTime()) {
      return moment(data as any).toUtcOffset();
    }

    if (Array.isArray(data)) {
      return data.map(item => this.serializeDates(item));
    }

    const newObj: any = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        newObj[key] = this.serializeDates(data[key]);
      }
    }
    return newObj;
  }
  private isJson(request: HttpRequest<any>) {
    return (request.body as string).startsWith('\{') ||
        (request.body as string).startsWith('\[');
  }
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (!request.url.startsWith(environment.apiBaseUrl))
      return next.handle(request);

    if (request.body && this.isJson(request)) {
      const convertedBody = this.serializeDates(JSON.parse(request.body));
      const clonedRequest = request.clone({body: convertedBody});

      return next.handle(clonedRequest);
    }
    return next.handle(request);
  }
}
