import {MarcaListagem} from '@app/abstraction/domain/interfaces/produtos/marca/marca-listagem.interface';
export class Marca {
  ativo: boolean;
  descricao: string;
  id: string;

  static from(value: any) {
    const marca = new Marca();
    marca.ativo = value.ativo;
    marca.descricao = value.descricao;
    marca.id = value.id;
    return marca;
  }
  toListagem() {
    return <MarcaListagem>{
      ativo: this.ativo,
      descricao: this.descricao,
      id: this.id
    };
  }
}
