import {TipoAjusteListagem} from '@app/abstraction/domain/interfaces/estoque/tipo-ajuste/tipo-ajuste-listagem.interface';

export class TipoAjuste {
  id: string;
  ativo: boolean;
  descricao: string;
  movimentaEstoque: boolean;
  movimentaComposicao: boolean;

  static from(json: any = {}) {
    const tipoAjuste = new TipoAjuste();
    tipoAjuste.id = json.id;
    tipoAjuste.ativo = json.ativo;
    tipoAjuste.descricao = json.descricao?.trim();
    tipoAjuste.movimentaEstoque = json.movimentaEstoque;
    tipoAjuste.movimentaComposicao = json.movimentaComposicao;
    return tipoAjuste;
  }
  toListagem() {
    return <TipoAjusteListagem>{
      id: this.id,
      descricao: this.descricao,
      ativo: this.ativo,
      movimentaEstoque: this.movimentaEstoque,
      movimentaComposicao: this.movimentaComposicao,
    };
  }
}
