import '@app/shared/extensions/moment.extension';

import { Pessoa } from '@app/abstraction/domain/entities/cadastros/pessoa/pessoa.entity';
import { FuncionarioListagem } from '@app/abstraction/domain/interfaces/cadastros/funcionario/funcionario-listagem.interface';
import * as moment from 'moment';

export class Funcionario extends Pessoa {
  ativo: boolean;
  comissaoPercentual: number;
  descontoPercentual: number;
  id: string;
  liberarAcimaLimitePercentual: number;
  liberarDiasAposVencimento: number;
  matricula: string;
  usuarioId: string;
  loginPdv: string;
  senhaPdv: string;
  lastModifiedOn: Date;
  createdOn: Date;
  createdBy: string;
  nomeCreatedBy: string;
  sequencia: number;
  tipo: number;

  static from(json: any = {}): Funcionario {
    const funcionario = new Funcionario();
    funcionario.ativo = json.ativo;
    funcionario.comissaoPercentual = json.comissaoPercentual;
    funcionario.descontoPercentual = json.descontoPercentual;
    funcionario.id = json.id;
    funcionario.liberarAcimaLimitePercentual =
      json.liberarAcimaLimitePercentual;
    funcionario.liberarDiasAposVencimento = json.liberarDiasAposVencimento;
    funcionario.matricula = json.matricula?.trim();
    funcionario.usuarioId = json.usuarioId;
    funcionario.loginPdv = json.loginPdv?.trim();
    funcionario.senhaPdv = json.senhaPdv?.trim();
    funcionario.lastModifiedOn =
      json.lastModifiedOn != null ? moment(json.lastModifiedOn).toDate() : null;
    funcionario.createdOn =
      json.createdOn != null ? moment(json.createdOn).toDate() : null;
    funcionario.createdBy = json.createdBy?.trim();
    funcionario.tipo = json.tipo;
    funcionario.pessoaFrom(json);
    return funcionario;
  }
  toAPI() {
    this.nomeFantasia = this.nomeRazaoSocial;
    this.comissaoPercentual = Number(this.comissaoPercentual);
    this.descontoPercentual = Number(this.descontoPercentual);
    this.liberarAcimaLimitePercentual = Number(
      this.liberarAcimaLimitePercentual
    );
    this.liberarDiasAposVencimento = Number(this.liberarDiasAposVencimento);
    this.preencherUfEnderecos();
    if (this.nascimento)
      this.nascimento = moment(this.nascimento).toLocalOnlyDate();
  }
  toListagem() {
    return <FuncionarioListagem>{
      ativo: this.ativo,
      documento: this.documento,
      id: this.id,
      createdOn: this.createdOn,
      nomeRazaoSocial: this.nomeRazaoSocial,
      rgIe: this.rgIe,
      sequencia: this.sequencia,
    };
  }
}
