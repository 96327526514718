export enum NFeModalidadeFrete {
  PorContaDoEmitente = 0,
  PorContaDoDestinatario = 1,
  PorContaDeTerceiros = 2,
  SemFrete = 9,
}

export const NFeModalidadeFreteLabels = new Map<number, string>([
  [NFeModalidadeFrete.PorContaDoEmitente, 'Por conta do emitente'],
  [NFeModalidadeFrete.PorContaDoDestinatario, 'Por conta do destinatário'],
  [NFeModalidadeFrete.PorContaDeTerceiros, 'Por conta de terceiros'],
  [NFeModalidadeFrete.SemFrete, 'Sem frete'],
]);