import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ParametroFacade} from '@app/abstraction/domain/facades/configuracoes/parametro/parametro.facade';
import {FormaPagamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-pagamento/forma-pagamento.facade';
import {LancamentoFacade} from '@app/abstraction/domain/facades/financeiro/lancamento/lancamento.facade';
import {PlanoContasFacade} from '@app/abstraction/domain/facades/financeiro/plano-contas/plano-contas.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {LancamentoParcelaFiltro} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-filtro.interface';
import {LancamentoParcelaListagem} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-listagem.interface';
import {ListagemBaseComponent} from '@app/shared/components/base/listagem-base.component';
import {ConsultaDataPeriodo} from '@app/shared/enums/consulta-data-tipo.enum';
import {SituacaoLancamento, SituacaoLancamentoLabels} from '@app/shared/enums/situacao-lancamento.enum';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {MascaraService} from '@app/shared/services/mascara/mascara.service';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-lancamento-parcela-busca-rapida',
  templateUrl: './lancamento-parcela-busca-rapida.component.html',
})
export class LancamentoParcelaBuscaRapidaComponent extends ListagemBaseComponent
    implements OnInit {
  @ViewChild('valorTemp', {static: true}) valorTemp: TemplateRef<any>;
  @ViewChild('dataTemp', {static: true}) dataTemp: TemplateRef<any>;
  @ViewChild('situacaoLancamentoTemp', {static: true})
  situacaoLancamentoTemp: TemplateRef<any>;
  @Input() mostrarModal: boolean;
  @Input() filtro = <LancamentoParcelaFiltro>{};
  @Input() multiSelect: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSelecionar = new EventEmitter<any>();

  colunas: TableColumn[];
  itensSelecionados: any;
  mostrarBuscaAvancada: any;
  inicio: Date|string;
  fim: Date|string;
  planosContas = <any[]>[];
  filtroStateKey = 'filtro_lancamentos_pagar';
  situacaoLancamentoLabels = SituacaoLancamentoLabels;

  get dados$() {
    return this.filtro.despesa ? this.lancamentoFacade.lancamentosPagar$ :
                                 this.lancamentoFacade.lancamentosReceber$;
  }
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get titulo() {
    return this.filtro.despesa ? 'Contas à Pagar' : 'Contas à Receber';
  }
  get valorDecimais() {
    return this.parametroFacade.quantidadeCasasDecimaisCamposValor;
  }
  get valorMask() {
    return this.mascaraService.decimal(this.valorDecimais, false);
  }
  get empresas() {
    return this.authFacade.grupoLogado?.empresas;
  }
  get formasPagamento$() {
    return this.formaPagamentoFacade.formasPagamento$;
  }
  get liberarDatas() {
    return this.filtro.consultaDataPeriodo == ConsultaDataPeriodo.Personalizado;
  }
  constructor(
      private lancamentoFacade: LancamentoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private parametroFacade: ParametroFacade,
      private mascaraService: MascaraService,
      private authFacade: AuthFacade,
      private formaPagamentoFacade: FormaPagamentoFacade,
      private planoContasFacade: PlanoContasFacade,
      toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(toastrService, errorMessagesFacade);
  }
  private buscarLancamentoParcelasComFiltro() {
    this.filtro.despesa ? this.buscarParcelasPagar() :
                          this.buscarParcelasReceber();
  }
  private retornarIntervalosDataFiltro() {
    let inicio = moment(this.inicio).startOf('day').toDate();
    let fim = moment(this.fim).endOf('day').toDate();

    return {inicio, fim};
  }
  private buscarParcelasReceber() {
    const {inicio, fim} = this.retornarIntervalosDataFiltro();
    return this.lancamentoFacade.buscarParcelasReceber({
      pagina: this.pagina,
      limite: this.limite,
      numeroDocumento: this.filtro.numeroDocumento,
      pessoaDocumento: this.filtro.pessoaDocumento,
      pessoaId: this.filtro.pessoaId,
      dataVencimentoInicio: inicio,
      dataVencimentoFim: fim,
      nome: this.filtro.nome,
      valor: this.filtro.valor,
      planoContaId: this.filtro.planoContaId,
      situacao: this.filtro.situacao,
      formaPagamentoId: this.filtro.formaPagamentoId,
      empresas: this.filtro.empresas,
    });
  }
  private buscarParcelasPagar() {
    const {inicio, fim} = this.retornarIntervalosDataFiltro();
    return this.lancamentoFacade.buscarParcelasPagar({
      pagina: this.pagina,
      limite: this.limite,
      numeroDocumento: this.filtro.numeroDocumento,
      pessoaDocumento: this.filtro.pessoaDocumento,
      pessoaId: this.filtro.pessoaId,
      dataVencimentoInicio: inicio,
      dataVencimentoFim: fim,
      nome: this.filtro.nome,
      valor: this.filtro.valor,
      planoContaId: this.filtro.planoContaId,
      situacao: this.filtro.situacao,
      formaPagamentoId: this.filtro.formaPagamentoId,
      empresas: this.filtro.empresas,
    });
  }
  private montarColunas() {
    this.colunas = [
      {
        field: 'situacao',
        name: 'Situação',
        cellClass: 'text-center',
        headerClass: 'text-center',
        cellTemplate: this.situacaoLancamentoTemp,
        width: 100,
      },
      {
        field: 'numeroDocumento',
        name: 'Nº do documento',
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        field: 'pessoaNome',
        name: 'Nome/Razão Social',
      },
      {
        field: 'dataVencimento',
        name: 'Data de vencimento',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.dataTemp,
      },
      {
        field: 'valorTotal',
        name: 'Valor (R$)',
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellTemplate: this.valorTemp,
      },
    ];
  }
  private montarDropdownPlanosConta() {
    this.planosContas = [];
    this.planoContasFacade.planosContasNivel2.forEach((nivel2) => {
      const niveis3 = this.planoContasFacade.planosContasNivel3.filter(
          (nivel3) => nivel3.planoConta2Id === nivel2.id && nivel3.despesa);
      if (niveis3.length === 0) return;
      this.planosContas.push({
        label: nivel2.descricao,
        value: nivel2.id,
        items: niveis3?.map((nivel3) => {
          return {
            label: `${nivel3.contaContabil} - ${nivel3.descricao}`,
            value: nivel3.id,
          };
        }),
      });
    });
  }
  private setarFiltroInicial() {
    let empresasId: string[] = [];
    this.empresas.forEach((empresa: any) => {
      empresasId.push(empresa.id);
    });

    this.filtro = <LancamentoParcelaFiltro>{
      consultaDataPeriodo:
          this.filtro.consultaDataPeriodo ?? ConsultaDataPeriodo.MesAtual,
      dataInicio: this.filtro.dataInicio ?? moment().startOf('month').toDate(),
      dataFim: this.filtro.dataFim ?? moment().endOf('month').toDate(),
      empresas: empresasId,
      dataVencimentoFim: this.filtro.dataVencimentoFim,
      dataVencimentoInicio: this.filtro.dataVencimentoInicio,
      numeroDocumento: this.filtro.numeroDocumento,
      pessoaDocumento: this.filtro.pessoaDocumento,
      pessoaId: this.filtro.pessoaId,
      nome: this.filtro.nome,
      valor: this.filtro.valor,
      planoContaId: this.filtro.planoContaId,
      situacao: this.filtro.situacao,
      formaPagamentoId: this.filtro.formaPagamentoId,
      direcao: this.filtro.direcao,
      despesa: this.filtro.despesa,
      propriedadeOrdem: this.filtro.propriedadeOrdem,
    };

    this.setarValoresDatas(this.filtro.dataInicio, this.filtro.dataFim);
  }
  abrirModal() {
    this.itensSelecionados = null;
    this.lancamentoFacade.limparListaLancamentosPagar();
    this.lancamentoFacade.limparListaLancamentosReceber();
    this.iniciarPaginacao();
    this.setarFiltroInicial();
    this.montarDropdownPlanosConta();
    this.buscarLancamentoParcelasComFiltro();
  }
  buscarLancamentoParcelasProximaPagina() {
    this.pagina++;
    this.buscarLancamentoParcelasComFiltro();
  }
  filtrarComTimeout = () => {
    clearTimeout(this.timerFiltro);
    this.timerFiltro = setTimeout(() => {
      this.filtrar();
    }, this.timeoutFiltro);
  };
  filtrar() {
    this.iniciarPaginacao();
    this.lancamentoFacade.limparListaLancamentosPagar();
    this.lancamentoFacade.limparListaLancamentosReceber();
    this.buscarLancamentoParcelasComFiltro();
  }
  fecharModal() {
    this.aoCancelar.emit();
    this.itensSelecionados = null;
    this.itensTabelaSelecionados = null;
    this.lancamentoFacade.limparListaLancamentosPagar();
    this.lancamentoFacade.limparListaLancamentosReceber();
    this.limparPaginacao();
  }
  ngOnInit() {
    this.montarDropdownConsultaDataPeriodos();
    this.montarDropdownSituacaoLancamento();
    this.montarColunas();
  }
  selecionar() {
    this.aoSelecionar.emit(
        Array.isArray(this.itensTabelaSelecionados) ?
            this.itensTabelaSelecionados :
            [this.itensTabelaSelecionados]);
    this.itensSelecionados = null;
    this.limparPaginacao();
  }
  setarValorDataInicio(value: any) {
    this.inicio = !value ? null : moment(value).toLocaleString();
  }
  setarValorDataFim(value: any) {
    this.fim = !value ? null : moment(value).toLocaleString();
  }
  setarValoresDatas(inicio: any, fim: any) {
    this.setarValorDataInicio(inicio);
    this.setarValorDataFim(fim);
  }
  setarDatas(evento: ConsultaDataPeriodo = ConsultaDataPeriodo.MesAtual) {
    const datas = this.setarPeriodoConsultaFiltro(evento);
    this.filtro.dataInicio = datas.inicio;
    this.filtro.dataFim = datas.fim;

    this.setarValoresDatas(datas.inicio, datas.fim);
  }
  limparCampos() {
    const despesa = this.filtro.despesa;
    this.iniciarPaginacao();
    this.lancamentoFacade.limparListaLancamentosPagar();
    this.lancamentoFacade.limparListaLancamentosReceber();
    this.limparFiltro(this.filtro);
    this.filtro.despesa = despesa;
    this.setarFiltroInicial();
    this.buscarComFiltro();
  }
  buscarComFiltro() {
    this.filtrar();
    this.toggleBuscaAvancada();
  }
  toggleBuscaAvancada() {
    this.mostrarBuscaAvancada = !this.mostrarBuscaAvancada;
  }
  buscarDescricaoSituacaoLancamento(lancamento: LancamentoParcelaListagem) {
    const dataVencimento = moment(lancamento.dataVencimento);
    if ((lancamento.situacao == SituacaoLancamento.Aberto ||
         lancamento.situacao == SituacaoLancamento.Parcial) &&
        moment().isAfter(dataVencimento, 'date')) {
      return this.situacaoLancamentoLabels.get(SituacaoLancamento.Vencido);
    } else if (lancamento.situacao == SituacaoLancamento.Aberto) {
      return this.situacaoLancamentoLabels.get(SituacaoLancamento.Aberto);
    } else if (lancamento.situacao == SituacaoLancamento.Parcial) {
      return this.situacaoLancamentoLabels.get(SituacaoLancamento.Parcial);
    } else {
      return this.situacaoLancamentoLabels.get(SituacaoLancamento.Baixado);
    }
  }
  buscarCorSituacaoLancamento(lancamento: LancamentoParcelaListagem) {
    const dataVencimento = moment(lancamento.dataVencimento);
    if ((lancamento.situacao == SituacaoLancamento.Aberto ||
         lancamento.situacao == SituacaoLancamento.Parcial) &&
        moment().isAfter(dataVencimento, 'date')) {
      return 'danger';
    } else if (lancamento.situacao == SituacaoLancamento.Aberto) {
      return 'success';
    } else if (lancamento.situacao == SituacaoLancamento.Parcial) {
      return 'warning';
    } else {
      return 'info';
    }
  }
}
