import {Injectable} from '@angular/core';
import {Ncm} from '@app/abstraction/domain/entities/fiscal/ncm/ncm.entity';
import {NcmListagem} from '@app/abstraction/domain/interfaces/fiscal/ncm/ncm-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NcmStore {
  private readonly _ncms = new BehaviorSubject<NcmListagem[]>(null);

  get ncms(): NcmListagem[] {
    return this._ncms.getValue();
  }
  set ncms(ncms: NcmListagem[]) {
    this._ncms.next(ncms);
  }
  get ncms$() {
    return this._ncms.asObservable();
  }
  adicionar(ncm: NcmListagem) {
    if (!this.ncms) {
      this.ncms = [];
    }
    if (!this.ncms.some(
            (e) => e.ncmCodigo === ncm.ncmCodigo &&
                e.ncmExcecaoTipi == ncm.ncmExcecaoTipi)) {
      this.ncms.push(ncm);
    }
  }
  adicionarRangeNcms(ncms: NcmListagem[]|Ncm[]) {
    if (!this.ncms) {
      this.ncms = [];
    }

    ncms.forEach((ncm) => {
      if (!this.ncms.some(
              (e) => e.ncmCodigo === ncm.ncmCodigo &&
                  e.ncmExcecaoTipi == ncm.ncmExcecaoTipi)) {
        this.ncms.push(ncm);
      }
    });
  }
}
