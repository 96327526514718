  <div class="field-checkbox">
    <p-checkbox
      #contentInput
      [inputId]="for"
      [binary]="true"
      [readonly]="readonly"
      [disabled]="readonly"
      [ngModelOptions]="modelOptions"
      [(ngModel)]="model"
      (ngModelChange)="onNgModelChange($event)"
    ></p-checkbox>
    <label [for]="for"> {{ label }}</label>
  </div>
