import {Injectable} from '@angular/core';
import {Transportadora} from '@app/abstraction/domain/entities/cadastros/transportadora/transportadora.entity';
import {TransportadoraListagem} from '@app/abstraction/domain/interfaces/cadastros/transportadora/transportadora-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TransportadoraStore {
  private readonly _transportadoras =
      new BehaviorSubject<TransportadoraListagem[]>(null);
  get transportadoras(): TransportadoraListagem[] {
    return this._transportadoras.getValue();
  }
  set transportadoras(transportadoras: TransportadoraListagem[]) {
    this._transportadoras.next(transportadoras);
  }
  get transportadoras$() {
    return this._transportadoras.asObservable();
  }

  adicionar(transportadora: Transportadora) {
    if (!this.transportadoras) {
      this.transportadoras = [];
    }
    this.transportadoras.push(transportadora);
  }
  adicionarRange(transportadoras: TransportadoraListagem[]) {
    if (!this.transportadoras) {
      this.transportadoras = [];
    }
    transportadoras.forEach((transportadora) => {
      if (!this.transportadoras.some((c) => c.id === transportadora.id)) {
        this.transportadoras.push(transportadora);
      }
    });
  }
}
