import {Injectable} from '@angular/core';
import {SetorListagem} from '@app/abstraction/domain/interfaces/cadastros/setor/setor-listagem.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SetorStore {
  private readonly _setores = new BehaviorSubject<SetorListagem[]>(null);

  get setores(): SetorListagem[] {
    return this._setores.getValue();
  }
  set setores(setores: SetorListagem[]) {
    this._setores.next(setores);
  }
  get setores$(): Observable<SetorListagem[]> {
    return this._setores.asObservable();
  }
  adicionar(novoSetor: SetorListagem) {
    if (!this.setores) {
      this.setores = [];
    }
    this.setores.push(novoSetor);
  }
  adicionarRange(setores: SetorListagem[]) {
    if (!this.setores) {
      this.setores = [];
    }
    setores.forEach((cliente) => {
      if (!this.setores.some((c) => c.id === cliente.id)) {
        this.setores.push(cliente);
      }
    });
  }
}
