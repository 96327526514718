import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ProdutoVariacaoReferencia} from '@app/abstraction/domain/entities/produtos/produto-variacao-referencia/produto-variacao-referencia.entity';
import {ProdutoVariacaoReferenciaFiltro} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-referencia/produto-variacao-referencia-filtro.interface';
import {ProdutoVariacaoReferenciaListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-referencia/produto-variacao-referencia-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProdutoVariacaoReferenciaService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: ProdutoVariacaoReferencia) {
    const response$ =
        this.client
            .post<ProdutoVariacaoReferencia>(
                API.PRODUTO_VARIACAO_REFERENCIA, dados)
            .pipe(
                map((json: ProdutoVariacaoReferencia) =>
                        json ? ProdutoVariacaoReferencia.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: ProdutoVariacaoReferencia, id: string) {
    const response$ =
        this.client
            .put<ProdutoVariacaoReferencia>(
                `${API.PRODUTO_VARIACAO_REFERENCIA}/${id}`, dados)
            .pipe(
                map((json: ProdutoVariacaoReferencia) =>
                        json ? ProdutoVariacaoReferencia.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.PRODUTO_VARIACAO_REFERENCIA}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client
            .get<ProdutoVariacaoReferencia>(
                `${API.PRODUTO_VARIACAO_REFERENCIA}/${id}`)
            .pipe(
                map((json: ProdutoVariacaoReferencia) =>
                        json ? ProdutoVariacaoReferencia.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: ProdutoVariacaoReferenciaFiltro) {
    const response$ = this.client.get<ProdutoVariacaoReferenciaListagem[]>(
        this.geradorQueryString.gerar(API.PRODUTO_VARIACAO_REFERENCIA, filtro));
    return lastValueFrom(response$);
  }
}
