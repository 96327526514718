export class MedidaCaseiraParteDecimalConst {
  constructor(_codigo: number, _descricao: string) {
    this.descricao = _descricao;
    this.codigo = _codigo;
  }
  codigo: number;
  descricao: string;
}

export const MedidasCaseirasParteDecimal = [
  new MedidaCaseiraParteDecimalConst(0, 'PARA 0'),
  new MedidaCaseiraParteDecimalConst(1, 'PARA 1/4'),
  new MedidaCaseiraParteDecimalConst(2, 'PARA 1/3'),
  new MedidaCaseiraParteDecimalConst(3, 'PARA 1/2'),
  new MedidaCaseiraParteDecimalConst(4, 'PARA 2/3'),
  new MedidaCaseiraParteDecimalConst(5, 'PARA 3/4'),
];
