import { Pipe, PipeTransform } from '@angular/core';
import { TiposPagamentoNfe } from '../../constants/tipos-pagamento-nfe.constant';

@Pipe({
  name: 'tipoPagamentoNfeDescricao',
})
export class TipoPagamentoNfeDescricaoPipe implements PipeTransform {
  transform(valor: number): any {
    if (valor) {
      const tipoPagamentoNfe = TiposPagamentoNfe.find((e) => e.codigo == valor);
      if (tipoPagamentoNfe) {
        return `${tipoPagamentoNfe.codigo} - ${tipoPagamentoNfe.descricao}`;
      }
    }
    return '';
  }
}
