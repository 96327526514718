import {FormControl, FormGroup} from '@angular/forms';
import {ContaBancaria} from '@app/abstraction/domain/entities/financeiro/conta-bancaria/conta-bancaria.entity';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {BaseForm} from '@app/abstraction/domain/forms/base/base.form';
import {LancamentoParcelaListagem} from '@app/abstraction/domain/interfaces/financeiro/lancamento/lancamento-parcela-listagem.interface';
import {OperacaoForm} from '@app/shared/enums/operacao-form.enum';
import {CustomValidators} from '@app/shared/validators/custom-validators.validator';

export interface ILancamentoPagamentoMassaForm {
  parcelasIds: FormControl<string[]>;
  formaPagamentoId: FormControl<string>;
  dataPagamento: FormControl<Date|string>;
  contaBancariaId: FormControl<string>;
}

export class LancamentoPagamentoMassaForm extends BaseForm {
  form: FormGroup<ILancamentoPagamentoMassaForm>;
  operacaoForm: OperacaoForm;
  mostarFormaPagamentoCadastroRapidoModal: boolean;
  mostrarContaBancariaCadastroRapidoModal: boolean;

  get titulo() {
    return 'Quitar Lançamentos Selecionados';
  }
  get operacaoFormAdicionar() {
    return this.operacaoForm === OperacaoForm.Adicionar;
  }
  constructor(private errorMessagesFacade?: ErrorMessageFacade) {
    super();
  }
  abrirContaBancariaCadastroRapidoModal() {
    this.mostrarContaBancariaCadastroRapidoModal = true;
  }
  abrirFormaPagamentoCadastroRapidoModal() {
    this.mostarFormaPagamentoCadastroRapidoModal = true;
  }
  criarForm() {
    this.form = new FormGroup<ILancamentoPagamentoMassaForm>({
      parcelasIds: new FormControl<string[]>([]),
      dataPagamento: new FormControl<Date|string>(
          new Date(),
          [
            CustomValidators.required('Data do pagamento é obrigatória'),
            CustomValidators.data(
                'dd/MM/yyyy', 'Inserir data no formato: "dd/mm/aaaa"'),
          ]),
      formaPagamentoId: new FormControl<string>(
          null,
          CustomValidators.required('Forma de pagamento é obrigatória'),
          ),
      contaBancariaId: new FormControl<string>(
          null,
          CustomValidators.required('Conta bancária é obrigatória'),
          ),
    });
    return this.form;
  }
  fecharFormaPagamentoCadastroRapidoModal() {
    this.mostarFormaPagamentoCadastroRapidoModal = false;
  }
  fecharContaBancariaCadastroRapidoModal() {
    this.mostrarContaBancariaCadastroRapidoModal = false;
  }
  isValid() {
    if (!this.form.valid) {
      this.errorMessagesFacade.mostrarCamposInvalidos(this.form);
      return false;
    }
    return true;
  }
  setarTipoFormulario(parcelas?: LancamentoParcelaListagem[]) {
    this.operacaoForm = OperacaoForm.Adicionar;
    this.preencherForm(parcelas);
  }
  preencherForm(parcelas: LancamentoParcelaListagem[]) {
    this.form.controls.formaPagamentoId.setValue(
        parcelas?.first()?.formaPagamentoId);
    this.form.controls.contaBancariaId.setValue(
        parcelas?.first()?.contaBancariaId);
    this.form.controls.parcelasIds.setValue(
        parcelas?.map((parcela) => parcela.parcelaId));
  }
  setarFormaPagamentoCadastrada(formaPagamento: FormaPagamento) {
    this.form.controls.formaPagamentoId.setValue(formaPagamento.id);
  }
  setarContaBancariaCadastrada(contaBancaria: ContaBancaria) {
    this.form.controls.contaBancariaId.setValue(contaBancaria.id);
  }
}
