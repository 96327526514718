import {ProdutoVariacaoReferenciaListagem} from '@app/abstraction/domain/interfaces/produtos/produto-variacao-referencia/produto-variacao-referencia-listagem.interface';
export class ProdutoVariacaoReferencia {
  ativo: boolean;
  descricao: string;
  id: string;
  static from(json: any = {}) {
    const produtoVariacaoReferencia = new ProdutoVariacaoReferencia();
    produtoVariacaoReferencia.id = json.id;
    produtoVariacaoReferencia.descricao = json.descricao?.trim();
    produtoVariacaoReferencia.ativo = json.ativo;
    return produtoVariacaoReferencia;
  }
  toListagem() {
    return <ProdutoVariacaoReferenciaListagem>{
      descricao: this.descricao,
      id: this.id,
      ativo: this.ativo,
    };
  }
}
