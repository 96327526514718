import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnderecoTipoListagem} from '@app/abstraction/domain/interfaces/shared/endereco-tipo-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnderecoTipoService {
  constructor(private client: HttpClient) {}
  buscarTodos() {
    const response$ =
        this.client.get<EnderecoTipoListagem[]>(`${API.ENDERECO_TIPOS}`);
    return lastValueFrom(response$);
  }
}
