import {Injectable} from '@angular/core';
import {FormaPagamentoService} from '@app/abstraction/data/services/financeiro/forma-pagamento.service';
import {FormaPagamentoStore} from '@app/abstraction/data/stores/financeiro/forma-pagamento.store';
import {FormaPagamento} from '@app/abstraction/domain/entities/financeiro/forma-pagamento/forma-pagamento.entity';
import {FormaPagamentoFiltro} from '@app/abstraction/domain/interfaces/financeiro/forma-pagamento/forma-pagamento-filtro.interface';

@Injectable({providedIn: 'root'})
export class FormaPagamentoFacade {
  get formasPagamento() {
    return this.store.formasPagamento;
  }
  get formasPagamento$() {
    return this.store.formasPagamento$;
  }
  constructor(
      private store: FormaPagamentoStore,
      private service: FormaPagamentoService,
  ) {}

  async adicionar(formaPagamento: FormaPagamento) {
    const novaFormaPagamento = await this.service.adicionar(formaPagamento);
    if (novaFormaPagamento) {
      this.store.adicionar(novaFormaPagamento.toListagem());
    }
    return novaFormaPagamento;
  }
  async alterar(formaPagamento: FormaPagamento) {
    return this.service.alterar(formaPagamento, formaPagamento.id);
  }
  async atualizarSituacao(id: string) {
    const formaPagamento = this.formasPagamento.find((p) => p.id === id);
    const status =
        await this.service.atualizarSituacao(id, !formaPagamento.ativo);
    formaPagamento.ativo = status;
    return status;
  }
  async buscarFormasPagamento(filtro?: FormaPagamentoFiltro) {
    const formasPagamento = await this.service.buscarTodos(filtro);
    this.store.adicionarRange(formasPagamento);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  limparListaFormasPagamento() {
    this.store.formasPagamento = null;
  }
}
