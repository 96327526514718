export class NotaFiscalTotal {
  baseCalculoIcms: number;
  valorIcms: number;
  valorIcmsDesonerado: number;
  valorFcpUfDestinatario: number;
  valorIcmsUfDestinatario: number;
  valorIcmsUfRemet: number;
  valorFcp: number;
  baseCalculoST: number;
  valorIcmsST: number;
  valorFcpST: number;
  valorFcpSubstituicaoRetido: number;
  valorProduto: number;
  valorFrete: number;
  valorSeguro: number;
  valorDesconto: number;
  valorIsento: number;
  valorIpi: number;
  valorIpiDevolvido: number;
  valorPis: number;
  valorCofins: number;
  valorOutros: number;
  valorNotaFiscal: number;
  valorTributos: number;

  static from(json: any = {}): NotaFiscalTotal {
    const total = new NotaFiscalTotal();
    total.baseCalculoIcms = json.baseCalculoIcms;
    total.valorIcms = json.valorIcms;
    total.valorIcmsDesonerado = json.valorIcmsDesonerado;
    total.valorFcpUfDestinatario = json.valorFcpUfDestinatario;
    total.valorIcmsUfDestinatario = json.valorIcmsUfDestinatario;
    total.valorIcmsUfRemet = json.valorIcmsUfRemet;
    total.valorFcp = json.valorFcp;
    total.baseCalculoST = json.baseCalculoST;
    total.valorIcmsST = json.valorIcmsST;
    total.valorFcpST = json.valorFcpST;
    total.valorFcpSubstituicaoRetido = json.valorFcpSubstituicaoRetido;
    total.valorProduto = json.valorProduto;
    total.valorFrete = json.valorFrete;
    total.valorSeguro = json.valorSeguro;
    total.valorDesconto = json.valorDesconto;
    total.valorIsento = json.valorIsento;
    total.valorIpi = json.valorIpi;
    total.valorIpiDevolvido = json.valorIpiDevolvido;
    total.valorPis = json.valorPis;
    total.valorCofins = json.valorCofins;
    total.valorOutros = json.valorOutros;
    total.valorNotaFiscal = json.valorNotaFiscal;
    total.valorTributos = json.valorTributos;
    return total;
  }
}
