import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FormaParcelamento} from '@app/abstraction/domain/entities/financeiro/forma-parcelamento/forma-parcelamento.entity';
import {FormaParcelamentoFiltro} from '@app/abstraction/domain/interfaces/financeiro/forma-parcelamento/forma-parcelamento-filtro.interface';
import {FormaParcelamentoListagem} from '@app/abstraction/domain/interfaces/financeiro/forma-parcelamento/forma-parcelamento-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';


@Injectable({providedIn: 'root'})
export class FormaParcelamentoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient) {}
  adicionar(dados: FormaParcelamento) {
    const response$ =
        this.client
            .post<FormaParcelamento>(API.FINANCEIRO_FORMA_PARCELAMENTO, dados)
            .pipe(
                map((json: FormaParcelamento) =>
                        json ? FormaParcelamento.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: FormaParcelamento, id: string) {
    const response$ =
        this.client
            .put<FormaParcelamento>(
                `${API.FINANCEIRO_FORMA_PARCELAMENTO}/${id}`, dados)
            .pipe(
                map((json: FormaParcelamento) =>
                        json ? FormaParcelamento.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ = this.client.put<boolean>(
        `${API.FINANCEIRO_FORMA_PARCELAMENTO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ = this.client
                          .get<FormaParcelamento>(
                              `${API.FINANCEIRO_FORMA_PARCELAMENTO}/${id}`)
                          .pipe(map(
                              (json: FormaParcelamento) =>
                                  json ? FormaParcelamento.from(json) : null));
    return lastValueFrom(response$);
  }
  buscarTodos(filtro?: FormaParcelamentoFiltro) {
    const response$ = this.client.get<FormaParcelamentoListagem[]>(
        this.geradorQueryString.gerar(
            API.FINANCEIRO_FORMA_PARCELAMENTO, filtro));
    return lastValueFrom(response$);
  }
}
