import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TipoAjuste} from '@app/abstraction/domain/entities/estoque/tipo-ajuste/tipo-ajuste.entity';
import {TipoAjusteFacade} from '@app/abstraction/domain/facades/estoque/tipo-ajuste/tipo-ajuste.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {TipoAjusteForm} from '@app/abstraction/domain/forms/estoque/tipo-ajuste.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-tipo-ajuste-cadastro-rapido-modal',
  templateUrl: './tipo-ajuste-cadastro-rapido-modal.component.html',
})
export class TipoAjusteCadastroRapidoModalComponent extends FormBaseComponent
    implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<TipoAjuste>();
  @Output() aoCancelar = new EventEmitter();

  tipoAjusteForm: TipoAjusteForm;
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }

  constructor(
      private tipoAjusteFacade: TipoAjusteFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,

      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarTipoAjuste(tipoAjuste: TipoAjuste) {
    return this.tipoAjusteFacade.adicionar(tipoAjuste).catch((e: any) => {
      this.saveLoading = false;
      return this.errorMessagesFacade.mostrarMensagens(e);
    });
  }
  private criarForm() {
    this.tipoAjusteForm = new TipoAjusteForm(this.errorMessagesFacade);
    this.tipoAjusteForm.criarForm();
  }
  private preencherObjeto() {
    return TipoAjuste.from(this.tipoAjusteForm.form.value);
  }
  private mostrarSucessoAoSalvar(tipoAjuste: TipoAjuste) {
    this.toastrService.success(`Tipo de ajuste cadastrado com sucesso`);
    this.aoSalvar.emit(tipoAjuste);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.tipoAjusteForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const tipoAjuste = this.preencherObjeto();

      const resultado = await this.adicionarTipoAjuste(tipoAjuste)

      if (resultado) this.mostrarSucessoAoSalvar(tipoAjuste);

    } catch (error) {
      this.toastrService.error('Erro ao salvar tipo de ajuste.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  fecharModal() {
    this.tipoAjusteForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
  }
}
