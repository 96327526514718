import {Pipe, PipeTransform} from '@angular/core';
import {FormaParcelamentoFacade} from '@app/abstraction/domain/facades/financeiro/forma-parcelamento/forma-parcelamento.facade';
import {FormaParcelamentoListagem} from '@app/abstraction/domain/interfaces/financeiro/forma-parcelamento/forma-parcelamento-listagem.interface';

@Pipe({
  name: 'formaParcelamento',
})
export class FormaParcelamentoPipe implements PipeTransform {
  constructor(private formaParcelamentoFacade: FormaParcelamentoFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const formaParcelamento =
          this.formaParcelamentoFacade.formasParcelamento?.find(
              (formaParcelamento: FormaParcelamentoListagem) =>
                  formaParcelamento.id == id) as any;
      return formaParcelamento?.[campo];
    }
    return '';
  }
}
