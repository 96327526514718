export enum TipoConta {
  Normal = 1,
  Agrupamento,
  Negociacao,
  Bordero,
}

export const TipoContaLabels = new Map<number, string>([
  [TipoConta.Normal, 'NORMAL'],
  [TipoConta.Agrupamento, 'AGRUPAMENTO'],
  [TipoConta.Negociacao, 'NEGOCIAÇÃO'],
  [TipoConta.Bordero, 'BORDERÔ'],
]);
