import {Pipe, PipeTransform} from '@angular/core';
import {EmpresaFacade} from '@app/abstraction/domain/facades/cadastros/empresa/empresa.facade';
import {AuthFacade} from '@app/abstraction/domain/facades/shared/auth/auth.facade';

@Pipe({
  name: 'empresa',
})
export class EmpresaPipe implements PipeTransform {
  constructor(
      private empresaFacade: EmpresaFacade,
      private authFacade: AuthFacade,
  ) {}
  transform(
      empresaId: string, campo: string = 'nomeFantasia',
      empresasUsuarioLogado: boolean = false): any {
    if (empresaId) {
      const empresas = empresasUsuarioLogado ?
          this.authFacade.grupoLogado.empresas as any :
          this.empresaFacade.empresas as any;

      const empresa = empresas.find((empresa: any) => empresa.id === empresaId);
      return empresa?.[campo];
    }
    return '';
  }
}
