import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UnidadeComercial} from '@app/abstraction/domain/entities/produtos/unidade-comercial/unidade-comercial.entity';
import {UnidadeComercialFacade} from '@app/abstraction/domain/facades/produtos/unidade-comercial/unidade-comercial.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector/device-detector.facate';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message/error-message.facade';
import {UnidadeComercialForm} from '@app/abstraction/domain/forms/produtos/unidade-comercial.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-unidade-comercial-cadastro-rapido-modal',
  templateUrl: './unidade-comercial-cadastro-rapido-modal.component.html',
})
export class UnidadeComercialCadastroRapidoModalComponent extends
    FormBaseComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() aoSalvar = new EventEmitter<UnidadeComercial>();
  @Output() aoCancelar = new EventEmitter();

  unidadeComercialForm: UnidadeComercialForm;

  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }

  constructor(
      private unidadeComercialFacade: UnidadeComercialFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,

      toastrService: ToastrService,
      confirmacaoService: ConfirmationService,
      errorMessagesFacade: ErrorMessageFacade,
  ) {
    super(confirmacaoService, toastrService, errorMessagesFacade);
  }
  private async adicionarUnidadeComercial(unidadeComercial: UnidadeComercial) {
    return this.unidadeComercialFacade.adicionar(unidadeComercial)
        .catch((e: any) => {
          this.saveLoading = false;
          return this.errorMessagesFacade.mostrarMensagens(e);
        });
  }
  private criarForm() {
    this.unidadeComercialForm = new UnidadeComercialForm(
        this.errorMessagesFacade,
    );
    this.unidadeComercialForm.criarForm();
    this.unidadeComercialForm.setarTipoFormulario();
  }
  private preencherObjeto() {
    return UnidadeComercial.from(this.unidadeComercialForm.form.value);
  }
  private mostrarSucessoAoSalvar(unidadeComercial: UnidadeComercial) {
    this.toastrService.success(`Unidade comercial cadastrada com sucesso`);
    this.aoSalvar.emit(unidadeComercial);
    this.fecharModal();
    this.saveLoading = false;
  }
  async salvar() {
    try {
      this.saveLoading = true;

      if (!this.unidadeComercialForm.isValid()) {
        this.saveLoading = false;
        return;
      }

      const unidadeComercial = this.preencherObjeto();
      const resultado = await this.adicionarUnidadeComercial(unidadeComercial)

      if (resultado) this.mostrarSucessoAoSalvar(resultado);

    } catch (error) {
      this.toastrService.error('Erro ao salvar unidade comercial.');
      this.saveLoading = false;
    }
  }
  aoAbrirModal() {
    this.criarForm();
  }
  fecharModal() {
    this.unidadeComercialForm.form.reset();
    this.aoCancelar.emit();
  }
  ngOnInit() {
    this.criarForm();
  }
}
