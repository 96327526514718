<p-dialog
  header="Lista de endereços"
  [style]="{ width: isDesktop ? '70%' : '90%' }"
  [(visible)]="mostrarModal"
  [modal]="true"
  [baseZIndex]="1"
  [draggable]="false"
  [resizable]="false"
  (onShow)="abrirModal()"
  (onHide)="fecharModal()"
>
  <div class="infinit-scroll-dialog">
    <app-table
    (aoSelecionar)="setarItemSelecionado($event)"
      [columns]="colunas"
      [selecteds]="itemSelecionado"
      [rows]="dados"
      [selectable]="true"
      selectionMode="single"
    >
    </app-table>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (click)="fecharModal()"
      label="Cancelar"
      [text]="true"
    ></p-button>
    <p-button
      (click)="selecionar()"
      label="Selecionar"
      styleClass="btn-five"
    ></p-button>
  </ng-template>
  <ng-template #cepTemp let-value="value">
    <span>{{ value | cep }}</span>
  </ng-template>
  <ng-template #enderecoTipoTemp let-value="value">
    <span>{{ value | enderecoTipo }}</span>
  </ng-template>
  <ng-template #situacaoTemp let-value="value">
    <p-tag
      *ngIf="value"
      styleClass="mr-2"
      severity="success"
      value="Ativo"
    ></p-tag>
    <p-tag
      *ngIf="!value"
      styleClass="mr-2"
      severity="danger"
      value="Inativo"
    ></p-tag>
  </ng-template>
</p-dialog>
