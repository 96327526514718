import {SubgrupoListagem} from '@app/abstraction/domain/interfaces/produtos/subgrupo/subgrupo-listagem.interface';
export class Subgrupo {
  ativo: boolean;
  descricao: string;
  descricaoGrupo: string;
  grupoId: string;
  id: string;

  static from(value: any) {
    const subgrupo = new Subgrupo();
    subgrupo.id = value.id;
    subgrupo.descricao = value.descricao?.trim();
    subgrupo.grupoId = value.grupoId;
    subgrupo.ativo = value.ativo;
    return subgrupo;
  }
  toListagem() {
    return <SubgrupoListagem>{
      id: this.id,
      descricao: this.descricao,
      grupoId: this.grupoId,
      descricaoGrupo: this.descricaoGrupo,
      ativo: this.ativo,
    };
  }
}
