import {Injectable} from '@angular/core';
import {Notificacao} from '@app/abstraction/domain/interfaces/shared/notificacao.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotificacaoStore {
  private readonly _notificacoes = new BehaviorSubject<Notificacao[]>(null);
  private readonly _notificacoesAntigas =
      new BehaviorSubject<Notificacao[]>(null);

  get notificacoes(): Notificacao[] {
    return this._notificacoes.getValue();
  }
  set notificacoes(notificacoes: Notificacao[]) {
    this._notificacoes.next(notificacoes);
  }
  get notificacoes$() {
    return this._notificacoes.asObservable();
  }

  get notificacoesAntigas(): Notificacao[] {
    return this._notificacoesAntigas.getValue();
  }
  set notificacoesAntigas(notificacoesAntigas: Notificacao[]) {
    this._notificacoesAntigas.next(notificacoesAntigas);
  }
  get notificacoesAntigas$() {
    return this._notificacoes.asObservable();
  }
}